import {
  Card,
  Row,
  Col,
  Steps,
  Spin,
  Alert
} from 'antd'
import React, {useEffect, useState} from 'react';
import {ShipmentDetailsForm, ShipmentDetailsFormTitle} from "./components/singleOrder/ShipmentDetailsForm";
import {ConsigneeDetailsForm, ConsigneeDetailsFormTitle} from './components/singleOrder/ConsigneeDetailsForm';
import {ItemDetailsForm, ItemDetailsFormTitle} from "./components/singleOrder/ItemDetailsForm";
import {PriceEstimateForm, PriceEstimateFormTitle} from "./components/singleOrder/PriceEstimateForm";
import './styles/ShipmentPage.css';
import '../dashboard/styles/HomePage.css';
import {Link} from "react-router-dom";
import {CaretLeftOutlined} from '@ant-design/icons'
import { useQuery } from "react-query";
import {useAuth} from "../auth/services/authContext";
import { fetchOrderFromSS, FetchOrderResponse, fetchOrderRate, FetchOrderRateResponse } from './services/privateApi';
import { useParams } from 'react-router-dom';
import {SingleOrderContextProvider} from "../orders/services/singleOrderContext";
import { countryCallingCodes, countryCodes } from '../commons/utils/constants';
import { pickupDateHasExpired } from './helpers';
import { useLanguage } from '../languages/Language';

const {Step} = Steps;

const removeCountryPrefix = (country_code: any, phone_no: string) => {
  if(phone_no.startsWith('+')){
    const code = countryCallingCodes[country_code]
    return phone_no.replace(code, '')
  }

  return phone_no
}

const OrderForm = () => {
  const authToken = useAuth().user.authToken;
  const orderID = useParams<any>()
  const [current, setCurrent] = useState(3);
  const [progress, setProgress] = useState(0);
  const setCurrentState = (val: number) => {
    setCurrent(val);
  };

  const getSingleOrderDataQuery = useQuery(['getSingleOrderData', orderID.id], async () => {
    return fetchOrderFromSS(authToken, orderID.id)
  }, {cacheTime: 0})

  const getOrderRateDataQuery = useQuery(['getOrderRateData', orderID.id], async () => {
    return fetchOrderRate(authToken, orderID.id)
  }, {cacheTime: 0})

  const [pickupExpired, setPickupDateExpired] = useState<boolean>(pickupDateHasExpired(getSingleOrderDataQuery.data?.pickup_date));
  const setPickupDateExpiredCallback = (val:boolean) => {
    setPickupDateExpired(val)
  }
  const orderFormat = (value: FetchOrderResponse, rate: FetchOrderRateResponse) => {
    return {
      id: value.id,
      tracking_no: value.tracking_no,
      consignee_name: value.consignee_name,
      custom_tn: value.janio_order_id,
      consignee_address: value.consignee_address,
      consignee_country_code: countryCodes[value.consignee_country],
      consignee_state: value.consignee_state,
      consignee_city: value.consignee_city,
      consignee_number: removeCountryPrefix(
        countryCodes[value.consignee_country],
        value.consignee_number
      ),
      consignee_postal: value.consignee_postal,
      consignee_email: value.consignee_email,
      consignee_id: value.consignee_id,
      consignee_id_type: value.consignee_id_type,
      dropoff_vendor_point_id: Number(value.dropoff_vendor_point_id),
      dropoff_vendor_name: value.dropoff_vendor_name,
      pickup_contact_name: value.pickup_contact_name,
      pickup_contact_number: value.pickup_contact_number,
      pickup_address: value.pickup_address,
      pickup_state: value.pickup_state,
      pickup_city: value.pickup_city,
      pickup_province: value.pickup_province,
      pickup_postal: value.pickup_postal,
      pickup_country_code: countryCodes[value.pickup_country],
      pickup_date: value.pickup_date,
      billing_contact_name: value.billing_contact_name,
      billing_contact_number: value.billing_contact_number,
      billing_address: value.billing_address,
      billing_state: value.billing_state,
      billing_city: value.billing_city,
      billing_postal: value.billing_postal,
      billing_country_code: value.billing_country  === 'Singapore' ? 'SG' : 'ID',
      order_length: value.order_length,
      order_width: value.order_width,
      order_height: value.order_height,
      order_weight: value.order_weight,
      payment_type: value.payment_type,
      cod_amount: Number(value.cod_amount) || 0,
      incoterm: value.incoterm?value.incoterm:'DDP',
      cod_currency: value.cod_currency,
      service_type: value.service_type,
      pickup_location: value.pickup_location,
      billing_location: value.billing_location,
      use_pickup_as_shipping: value.pickup_location === value.billing_location,
      payment_status: value.payment_status,
      amount: null,
      currency: rate.currency,
      amount_to_pay: rate.amount_to_pay,
      gst_amount: rate.gst_amount,
      is_tax_payable: rate.is_tax_payable,
      total: rate.total,
      total_duties: rate.total_duties,
      shipping_charge: rate.shipping_charge,
      total_taxes: rate.total_taxes,
      charge_id: rate.charge_id,
      pickup_charge: rate.pickup_charge,
      items: value.items.map(item => ({
        item_desc: item.item_desc,
        item_category: item.item_category,
        item_quantity: item.item_quantity,
        item_price_currency: item.item_price_currency,
        item_price_value: item.item_price_value,
        item_sku: item.item_sku,
        gst_ovr: item.gst_ovr,
        gst_collected: item.gst_collected === true ? 'true' : 'false',
        product_id: item.product_id,
        item_product_url: item.item_product_url
      })),
    }
  }

  const steps = [
    {
      title: <ShipmentDetailsFormTitle current={current} setCurrentState={setCurrentState} dataFilled={1} />,
      content: <ShipmentDetailsForm current={current} setCurrentState={setCurrentState} dataFilled={1} setPickupDateExpiredCallback={setPickupDateExpiredCallback}/>,
    },
    {
      title: <ConsigneeDetailsFormTitle current={current} setCurrentState={setCurrentState} dataFilled={2}/>,
      content: <ConsigneeDetailsForm current={current} setCurrentState={setCurrentState} dataFilled={2}/>,
    },
    {
      title: <ItemDetailsFormTitle current={current} setCurrentState={setCurrentState} dataFilled={3}/>,
      content: <ItemDetailsForm current={current} setCurrentState={setCurrentState} dataFilled={3}/>,
    },
    {
      title: <PriceEstimateFormTitle current={current} setCurrentState={setCurrentState} />,
      content: <PriceEstimateForm current={current} setCurrentState={setCurrentState} isEditing/>,
    },
  ];

  useEffect(() => {
    if (current > progress) {
      setProgress(current);
    }
  }, [current, progress]);

  return (
    getSingleOrderDataQuery.data && getOrderRateDataQuery.data?
      <SingleOrderContextProvider order={orderFormat(getSingleOrderDataQuery.data, getOrderRateDataQuery.data)}>
        {pickupExpired ?  <Alert
          className="custom-alert"
          message="Please change the following:"
          description={
            <ul style={{paddingLeft:16}}>
              <li key={1}>You are updating the order from the past. Please select <strong>Pickup Date</strong> again</li>
            </ul>
          }
          type="warning"
          showIcon
        /> : null}
        <Steps direction="vertical" current={current} className={"form-progress-bar"}>
          {steps.map((item, index) => (
            <Step key={index} title={item.title}
              description={(current >= index || progress >= index) ? item.content : ''}/>
          ))}
        </Steps>
      </SingleOrderContextProvider> : <Spin style={{'position': 'absolute','left': '50%'}} />
  )
};


export const PayOrder = () => {
  let { getText } = useLanguage();
  return (
    <Row align="middle" justify="center">
      <Col span={16} className={'order-form-container'}>
        <Link to={"/orders/create"}>
          <p className="go-back-btn"><CaretLeftOutlined/> {getText('Create shipment')} </p>
        </Link>
        <Card className={'form-card'}>
          <OrderForm/>
        </Card>
      </Col>
    </Row>
  )
};