import { Button, Col, Row, Statistic, Table, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import DownloadModal from './DownloadModal';
import Filters from './Filters';
import Invoice from '../interfaces/Invoice';
import { parseAmount, parseInvoiceAmount } from '../utils/parsing';
import { formatDateOnly } from '../../commons/utils/utilizer';

const { Text } = Typography;

interface Props {
  data: Invoice[],
  currency: string,
  currencies: string[]
};

const InvoicesPaidTab = (props: Props) => {
  const { data, currency: defaultCurrency, currencies } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const [invoice, setInvoice] = useState<Invoice>();
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [currency, setCurrency] = useState<string>(defaultCurrency);
  const [amountPaid, setAmountPaid] = useState<string>('0.00');

  const columns = [
    {
      title: 'Invoice Date',
      dataIndex: 'date',
      key: 'date',
      width: 130,
      render: (date: string) => formatDateOnly(date) || '-'
    },
    {
      title: 'Invoice Number',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      width: 160,
      render: (invoiceNumber: string) => <Text strong>{invoiceNumber}</Text>
    },
    {
      title: 'Currency',
      dataIndex: 'currencyCode',
      key: 'currencyCode',
      width: 130,
    },
    {
      title: 'Invoice Amount',
      dataIndex: 'total',
      key: 'total',
      width: 130,
      render: (total: number, data: Invoice) => <Text strong>{parseInvoiceAmount(total, data.type, data.currencyCode)}</Text>
    },
    {
      title: 'Invoice & Document',
      dataIndex: 'hasAttachments',
      key: 'hasAttachments',
      width: 130,
      render: (hasAttachments: boolean, data: Invoice) => (
        !hasAttachments && data.isOverpayment
          ? <Tooltip title="Attachment Not Available"><Button type="primary" disabled>Download</Button></Tooltip>
          : <Button type="primary" onClick={() => handleDownload(data)}>Download</Button>
      )
    },
  ];

  const handleDownload = (invoice: Invoice) => {
    setInvoice(invoice);
    setVisible(true);
  };

  useEffect(() => {
    if (!defaultCurrency) {
      return;
    }

    setCurrency(defaultCurrency);
  }, [defaultCurrency]);

  useEffect(() => {
    if (!data.length || !currency) {
      return;
    }

    const currencyData = data.filter(({ currencyCode }) => currencyCode === currency);
    const invoices = currencyData.filter(({ type }) => type === 'INVOICE');

    let amountPaid = 0;

    for (const invoice of invoices) {
      amountPaid += invoice.total;
    }

    setAmountPaid(parseAmount(amountPaid, currency));
    setInvoices(currencyData);
  }, [data, currency]);

  return (
    <Col span={24}>
      { invoice && <DownloadModal tab='Invoices Paid' invoice={invoice} visible={visible} setVisible={setVisible} /> }
      <Col span={24}>
        <Row gutter={[24, 16]}>
          <Col span={20}><Statistic title="Total Amount Paid" value={amountPaid} /></Col>
          <Col span={4}><Filters currency={currency} currencies={currencies} setCurrency={setCurrency} /></Col>
        </Row>
      </Col>
      <Col span={24}>
        <Table
          dataSource={invoices}
          columns={columns}
          scroll={{ x: 900 }}
        />
      </Col>
    </Col>
  )
};

export default InvoicesPaidTab;