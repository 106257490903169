import {useEffect, useState} from 'react';
import {Row, Col, Card, Alert, Button, Select, Form as F, Spin, message, Popconfirm,Steps} from 'antd'
import {FormInput, FormSelect, CheckBox} from '../orders/components/Forms';
import {useAuth} from "../auth/services/authContext";
import {Formik, FieldArray, Form as FormikForm} from 'formik';
import {useMutation, useQuery } from "react-query";
import { useLanguage } from '../languages/Language';
import * as Yup from 'yup';
import './styles/shipmentRules.css'
import { CaretLeftOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useParams, useHistory } from "react-router-dom";
import { createShipmentRulesInB2C, getShipmentRuleDataFromB2C, updateShipmentRuleDatainB2C } from "../orders/services/privateApi";
import { errData } from './ServiceTypeRule'

const {Step} = Steps;
const {Option} = Select;
// interface ParcelFormSubmit {
//   action_type: string,
//   rule_name: string,
//   rule_source_fields: [
//     {
//       source_field_name: string,
//       operator: string,
//       matches_to_value: string
//     }
//   ],
//   rule_target_fields: [
//     {
//       target_name: string,
//       target_value: string
//     }
//   ]
// }
// interface ParcelDimensionRule {
//   type: string,
//   status: string,
//   amount: string,
//   currency: string,
//   created_at: string,
//   updated_at: string,
//   transaction_id: string
// }

const itemConditions = [
  {
    key: 'draft_order.order_weight',
    value: "Item weight is"
  },
  {
    key: "item_qty",
    value: "Item quantity is"
  }
]

const itemOperators = [
  {
    key: '=',
    value: "Equals"
  },
  {
    key: "<",
    value: "Less than"
  },
  {
    key: ">",
    value: "Greater than"
  }
]

const ItemForm = ({selectedCondition, remove, index}: {selectedCondition: string, remove: () => any, index: number}) => {
  let { getText } = useLanguage();
  return (
    <Row gutter={14} style={{position: 'relative'}}>
      {
        index > 0 && <Col span={24}><h3 className={'rule-condition-separator'}>OR</h3></Col>
      }

      <Col span={6} className={'shipment-spacer-mb label-break'}>
        <FormSelect name={`items.[${index}].item_condition`} label={getText('Condition')} wrapperClass="no-margin-item" required >
          {
            itemConditions.map((item: any) => {
              return <Option key={item.key} value={item.key}>{item.value}</Option>
            })
          }
        </FormSelect>
      </Col>

      {/* Item Operators */}
      <Col span={6} className={'shipment-spacer-mb label-break'}>
        <FormSelect name={`items.[${index}].item_operator`} label={getText('Operator')} wrapperClass="no-margin-item" required
        >
          {
            itemOperators.map((item: any) => {
              return <Option key={item.key} value={item.key}>{item.value}</Option>
            })
          }
        </FormSelect>
      </Col>

      {
        selectedCondition === 'draft_order.order_weight' ?
          <Col span={12} className={'shipment-spacer-mb label-break'}>
            <FormInput name={`items.[${index}].item_value`} label={getText('Weight (kg)')} required inputProps={{suffix: 'kg', type: 'number'}} wrapperClass="no-margin-item" />
          </Col> :
          <Col span={12} className={'shipment-spacer-mb label-break'}>
            <FormInput name={`items.[${index}].item_value`} label={getText('Quantity')} inputProps={{type: 'number'}} required wrapperClass="no-margin-item" />
          </Col>
      }

      {index > 0 &&
        <div className={"button-block delete-rule-btn"} >
          <Button type="primary" onClick={remove} className={"no-border-btn shipment-btn-center"} style={{background: 'none'}}>
            {getText('Delete Condition')}
          </Button>
        </div>
      }

    </Row>
  )
}

const validationSchema = (getText: any) => {
  return (
    Yup.object().shape({
      rule_name: Yup.string().required('Please enter rule name').max(50, 'Maximum 50 characters are allowed'),

      items: Yup.array().of(Yup.object().shape({
        item_condition: Yup.string().nullable().required(getText('Please select condition')),
        item_operator: Yup.string().nullable().required(getText('Please select operator')),
        // item_value: Yup.string().nullable().required(getText('Item Value is required')),

        item_value: Yup.number().nullable().required(getText('Value is required')).test(
          (val: any, context: any) => {
            if (context.parent.item_condition === 'item_qty') {
              if(!Number.isInteger(val)){
                return context.createError({
                  path: context.path,
                  message: getText('Item Quantity cannot be a decimal value')
                })
              }
            }
            if(val < 0){
              return context.createError({
                path: context.path,
                message: getText('Item value must be greater than 0')
              })
            }
            return true
          }
        ),

      })),

      order_length: Yup.number().nullable().required('Please enter the length').test(
        'Is positive?',
        'length must be greater than 0',
        (value: any) => value > 0
      ),
      order_width: Yup.number().nullable().required(getText('Please enter the width')).test(
        'Is positive?',
        'width must be greater than 0',
        (value: any) => value > 0
      ),
      order_height: Yup.number().nullable().required(getText('Please enter the height')).test(
        'Is positive?',
        'height must be greater than 0',
        (value: any) => value > 0
      ),
    })
  )
}

export const ParcelDimensionRule = (props: any) => {
  const ruleParams = useParams<any>()
  const history = useHistory()

  let { getText } = useLanguage();
  const [isEditFlag, setIsEditFlag] = useState<boolean>(false)
  const auth = useAuth();

  useEffect(() => {
    let isEdit = window.location.href.indexOf('edit') > -1

    if (isEdit) {
      setIsEditFlag(true)
    }
  }, [])

  const getShipmentData = useQuery(['getShipmentParcelRuleData'], () => {
    return getShipmentRuleDataFromB2C(auth.user.secret_key, ruleParams.id)
  }, {enabled : isEditFlag})


  const emptyInitialValue = {
    rule_name: '',
    items: [
      {
        item_condition: '',
        item_operator: '',
        item_value: ''
      }
    ],
    order_length: '',
    order_width: '',
    order_height: '',
    order_weight: '',
    apply_on_existing_order: false
  }

  const fetchedInitialValue = () => {
    if(isEditFlag && (getShipmentData.data && getShipmentData.data.rule_name.length > 0  && getShipmentData.data?.action_type === 'DIMENSIONS')) {
      return {
        rule_name: getShipmentData.data.rule_name,
        items: getShipmentData.data.rule_source_fields.map((item: any) => {
          return {
            item_condition: item.source_field_name,
            item_operator: item.operator,
            item_value: item.matches_to_value
          }
        }),
        order_length: getShipmentData.data.rule_target_fields[0].target_value,
        order_width: getShipmentData.data.rule_target_fields[1].target_value,
        order_height: getShipmentData.data.rule_target_fields[2].target_value,
        order_weight: getShipmentData.data.rule_target_fields[3] ? getShipmentData.data.rule_target_fields[3].target_value : '',
        apply_on_existing_order: getShipmentData.data.apply_on_existing_order
      }
    }
    return emptyInitialValue
  }

  const initialValue = fetchedInitialValue()

  const submitOrder:any = useMutation(async (req:any) => {
    if(isEditFlag) {
      return updateShipmentRuleDatainB2C(auth.user.secret_key, req, ruleParams.id)
    }
    return createShipmentRulesInB2C(auth.user.secret_key, req);
  },  {
    onSuccess: (val) => {
      let ruleSuccess = <span>Rule id <strong>{val.id}</strong> is {isEditFlag ? 'Updated': 'Created'} Succesfully</span>
      message.success(ruleSuccess)
      history.goBack()
    },
    onError: (error:any) => {
      errData(error.response.data)
    },
  });

  const onFormikSubmit = (values: any) => {
    let rulesSourceFieldArray = values.items.map((item: any) => {
      return {
        source_field_name: item.item_condition,
        operator: item.item_operator,
        matches_to_value: item.item_value
      }
    })

    let rulesTargetFieldArrayWithoutWeight = [
      {
        target_name: 'draft_order.order_length',
        target_value: values.order_length
      },
      {
        target_name: 'draft_order.order_width',
        target_value: values.order_width
      },
      {
        target_name: 'draft_order.order_height',
        target_value: values.order_height
      }
    ]

    let rulesTargetFieldArrayWithWeight = [
      {
        target_name: 'draft_order.order_length',
        target_value: values.order_length
      },
      {
        target_name: 'draft_order.order_width',
        target_value: values.order_width
      },
      {
        target_name: 'draft_order.order_height',
        target_value: values.order_height
      },
      {
        target_name: 'draft_order.order_weight',
        target_value: values.order_weight
      }
    ]
    let request = {
      action_type: "DIMENSIONS",
      rule_name: values.rule_name,
      rule_source_fields: rulesSourceFieldArray,
      rule_target_fields: values.order_weight !== '' ? rulesTargetFieldArrayWithWeight : rulesTargetFieldArrayWithoutWeight,
      apply_on_existing_order: values.apply_on_existing_order
    }

    submitOrder.mutate(request)
  }

  const empty_item = {
    item_condition: '',
    item_operator: '',
    item_value: '',
    item_qty: ''
  }

  if(isEditFlag && getShipmentData.isFetching) {
    return (
      <div className="spinner-centre">
        <Spin />
      </div>
    )
  }

  if(isEditFlag && (getShipmentData.data && getShipmentData.data?.action_type !== 'DIMENSIONS')) {
    return (
      <Row align="middle" justify="center">
        <Col span={14} className="bulk-payment-error">
          <Alert
            message="Error while fetching Rule"
            description={'No such rule exist'}
            type="error"
            showIcon
          />
        </Col>
      </Row>

    )
  }

  return (
    <>
      <Row align="middle" justify="center">
        <Col span="14" className={"rule-header"}>{getText('Parcel Dimension Rule')}</Col>
        <Col span="14" className="go-back-btn">
          {
            !isEditFlag ?
              <p className="go-back-btn" style={{cursor: 'pointer'}}>
                <Popconfirm
                  title={<div className="delete-card">{getText('All your progress in this session will be Lost!')}</div>}
                  onConfirm={() => history.goBack()}
                  onCancel={() => null}
                  okText={getText('Quit')}
                  cancelText={getText('Cancel')}
                  icon={<QuestionCircleOutlined style={{ color: 'red' } }/>}
                >
                  <a href="#"><CaretLeftOutlined/> {getText('Go back')} </a>
                </Popconfirm>
              </p> :
              <p className="go-back-btn" onClick={() => history.goBack()} style={{cursor: 'pointer'}}><CaretLeftOutlined/> {getText('Go back')} </p>
          }
        </Col>
      </Row>
      <Formik
        initialValues={initialValue}
        enableReinitialize= {true}
        onSubmit={onFormikSubmit}
        validateOnChange={true}
        validationSchema={validationSchema(getText)}
      >
        { formik => {
          return (

            (
              <Row align="middle" justify="center">
                <Col span="14">
                  <FormikForm>
                    <Steps direction="vertical">
                      <Step status={"process"}
                        title={<p className={"mb-5"}>{getText('What is the name for this parcel dimension type rule?')}</p>}
                        description={
                          <Card className={'card-separator'}>
                            <Row gutter={14}>
                              <Col span={12} className={'label-break'}>
                                <FormInput name="rule_name" label={getText('Rule Name')} required wrapperClass="no-margin-item mb-15" />
                              </Col>
                            </Row>
                          </Card>
                        }
                      />
                      <Step status={"process"}
                        title={<p className={"mb-5"}>{getText('What is the condition?')}</p>}
                        description={
                          <Card className={'card-separator card-bg-color-when'}>
                            <F.Item label={getText('When')} className={"single-order-form-title"}></F.Item>
                            <FieldArray name="items"
                              render={(arrayHelpers) => {
                                return (
                                  (
                                    <>
                                      {formik.values.items.map((item: any, index: number) => {
                                        return (
                                          <div key={index} >
                                            <ItemForm index={index} remove={() => arrayHelpers.remove(index)} selectedCondition={formik.values.items[index].item_condition} />
                                            {/* {index > 0 && <Divider/>} */}
                                          </div>
                                        )
                                      })}

                                      <F.Item className={'reverse-shipment-spacer-mt add-condition-btn-align'}>
                                        <Button className={'outline-btn'}
                                          onClick={() => arrayHelpers.push(empty_item)}
                                        >
                                          {getText('+ Add Condition')}
                                        </Button>
                                      </F.Item>
                                    </>
                                  )
                                )
                              }}
                            />
                          </Card>
                        }
                      />
                      <Step status={"process"}
                        title={<p className={"mb-5"}>{getText('What are the action to apply?')}</p>}
                        description={
                          <Card className={'card-separator card-bg-color-then'}>
                            <F.Item label={getText('Then')} className={"single-order-form-title"}></F.Item>
                            <Row gutter={14}>
                              <Col span={6} className={'shipment-spacer-mb'}>
                                <FormInput name="order_length" label={getText('Length (cm)')} inputProps={{suffix: 'cm', type: 'number'}} required wrapperClass="no-margin-item" />
                              </Col>

                              <Col span={6} className={'shipment-spacer-mb'}>
                                <FormInput name="order_width" label={getText('Width (cm)')} inputProps={{suffix: 'cm', type: 'number'}} required wrapperClass="no-margin-item" />
                              </Col>

                              <Col span={6} className={'shipment-spacer-mb'}>
                                <FormInput name="order_height" label={getText('Height (cm)')} inputProps={{suffix: 'cm', type: 'number'}} required wrapperClass="no-margin-item" />
                              </Col>

                              <Col span={6} className={'shipment-spacer-mb'}>
                                <FormInput name="order_weight" label={getText('Weight (optional)')} inputProps={{suffix: 'kg', type: 'number'}} wrapperClass="no-margin-item" />
                              </Col>
                            </Row>

                            <Col>
                              <CheckBox name="apply_on_existing_order" label={''}
                              >{getText('Apply to new and existing shipments')}</CheckBox>
                            </Col>

                            <div className={"button-block create-rule-btn"}>
                              <Button htmlType="submit" type="primary" className={"blue-button"} loading={submitOrder.isLoading}>
                                {isEditFlag ? getText('Update Rule') : getText('Create Rule')}
                              </Button>
                            </div>
                          </Card>
                        }
                      />
                    </Steps>
                  </FormikForm>

                </Col>
              </Row>
            )
          )}
        }
      </Formik>
    </>

  )
}
