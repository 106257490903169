import { useEffect, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { sendOtpRequest, verifyOtp, fetchMaskedTracking_no } from "./services";
import { useParams, useHistory } from "react-router";
import { message, Row, Col, Card, Button, Spin, Alert, Form, Input, Image } from "antd";
import janio_logo from "../assets/images/janio_logo.svg";

interface OtpSessionidInterface {
  otp_session_id: string
}

const RESEND_TIME_SECONDS = 30

export const OtpVerification = () => {
  const location: {tracking_number: string} = useParams()
  const history = useHistory()

  const [resendTimeLeft, setResendTimeLeft] = useState(RESEND_TIME_SECONDS)
  const [sendOtp, setSendOtp] = useState<boolean>(false)
  const [otpSessionid, setOtpSessionid] = useState<string>('')
  const [otp4digitNumber, setOtp4digitNumber] = useState<number>(0)

  const fetchMaskedTracking_no_data = useQuery(['fetchMaskedTN'], async () => {
    return fetchMaskedTracking_no(location.tracking_number)
  },{cacheTime:0, retry: false})

  useEffect(() => {
    if (resendTimeLeft > 0) {
      const timer = setTimeout(() => setResendTimeLeft(resendTimeLeft - 1), 1000)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [resendTimeLeft])

  const sendOtpFn = useMutation(async () => {
    let param = {
      tracking_no: location.tracking_number
    }
    return sendOtpRequest(param)
  } ,  {
    onSuccess: (val: OtpSessionidInterface) => {
      setSendOtp(true)
      setResendTimeLeft(RESEND_TIME_SECONDS)
      setOtpSessionid(val.otp_session_id)
    },
    onError: (error: any) => {
      if(error.response.data) {
        message.error(error.response.data.message)
      }else {
        message.error('We are facing some Issues, Please try after sometime.')
      }
      
    },
  });

  const verifyOtpFn = useMutation(async () => {
    let param = {
      otp_code: otp4digitNumber,
      otp_session_id: otpSessionid
    }
    return verifyOtp(param)
  } ,  {
    onSuccess: (val: OtpSessionidInterface) => {
      history.push(`/rts/confirmation/${location.tracking_number}`)
    },
    onError: (error: any) => {
      message.error('Invalid Otp Code')
      // if(error.response.data) {
      //   message.error(error.response.data.message)
      // }else {
      //   message.error('We are facing some Issues, Please try after sometime.')
      // }
      
    },
  });

  if(fetchMaskedTracking_no_data.isLoading) {
    return <Spin className="center-align-spin-otp" />
  }

  if(fetchMaskedTracking_no_data.isError) {
    let errorData:any = fetchMaskedTracking_no_data?.error
    if(errorData) {
      return <Row align="middle" justify="center" style={{marginTop: '30px'}}>
        <Col span={12} className={'auth-form-container'}>
          <div className="logo" style={{textAlign: 'center', marginTop: '30px'}}>
            <a href="#">
              <Image preview={false} src={janio_logo} className={"site-logo"}/>
            </a>
          </div>
          <Card style={{minWidth: '380px', borderTop: 'none'}}>
            <Alert 
              type="error" message={<span style={{fontSize: '12px'}}>Error</span>}
              description={<span style={{fontSize: '12px'}}>{errorData.response.data.message}</span>} 
            />
          </Card>
          
        </Col>
      </Row>
    }
  }
  return (
    <div className="App">
      {/* <h1>{DashboardHeaderMapping[headingName]!}</h1> */}
      <>
        <Row align="middle" justify="center" style={{marginTop: '30px'}}>
          <Col span={12} className={'auth-form-container'}>
            <div className="logo" style={{textAlign: 'center', marginTop: '30px'}}>
              <a href="#">
                <Image preview={false} src={janio_logo} className={"site-logo"}/>
              </a>
            </div>
            <Card style={{minWidth: '380px', borderTop: 'none'}}>
              <h3 style={{color: 'rgb(5, 5, 147)', fontSize:'14px', fontWeight: 'bold', textAlign: 'center', marginTop: '-20px'}}>Janio Return Order Confirmation</h3>
              <p style={{fontSize: '11px', color: 'rgba(0, 0, 0, 0.65)', marginTop: '20px'}}>Before proceeding to schedule your pickup, we need to verify your identity.</p>

              {sendOtp &&
                <Form
                  onFinish={(val) => {
                    // register.mutate(val);
                  }}
                  layout={'vertical'}
                  requiredMark={true}

                >
                  <p style={{fontSize: '11px', color: 'rgba(0, 0, 0, 0.65)', marginTop: '20px'}}>Please key in OTP sent to {fetchMaskedTracking_no_data.data?.masked_returns_pickup_contact_number}.</p>
                  <Form.Item 
                    name="otp_code"
                    // rules={[{ required: true, message: 'Please enter your email address'}, { type: 'number' }]} validateFirst>
                    rules={[{ required: true, max: 4}]}
                  >
                    <Input type="number" value={otp4digitNumber} onChange={(e: any) => setOtp4digitNumber(e.target.value)} />
                  </Form.Item>

                  <Button 
                    type="primary" 
                    style={{margin: '5px 0 20px 0', width: '100%', minHeight: '40px', fontSize: '11px'}}
                    disabled={(resendTimeLeft > 0)
                      // || requesting || verifying
                    }
                    onClick={() => {sendOtpFn.mutate(); setOtp4digitNumber(0)}}
                  >
                    Resend {<span> ({resendTimeLeft})</span>}
                  </Button>

                  <Button
                    disabled={otp4digitNumber === 0 && (otp4digitNumber.toString().length < 4 || otp4digitNumber.toString().length > 4)}
                    type="primary" 
                    style={{margin: '5px 0 20px 0', width: '100%', minHeight: '40px', fontSize: '11px'}}
                    onClick={() => verifyOtpFn.mutate()}
                  >
                    Next
                  </Button>
                </Form>
              }

              {!sendOtp && <Button type="primary" style={{margin: '20px 0', width: '100%', minHeight: '40px', fontSize: '11px'}}
                onClick={() => sendOtpFn.mutate()}
              >
                Send Otp to {fetchMaskedTracking_no_data.data?.masked_returns_pickup_contact_number}
              </Button>}

              {/* <p style={{fontSize: '11px', color: 'rgba(0, 0, 0, 0.65)'}}>
                Have queries? Contact us at <a href={'mailto:clientsupport@janio.asia'} style={{color: '#050593', fontWeight: 'bold'}}>clientsupport@janio.asia</a>
              </p> */}
            </Card>
          </Col>
        </Row>
      </>
    </div>
  )
}