import {
  Card,
  Row,
  Col,
  Image,
} from 'antd'
import '../styles/UserSettings.css';
import {Link} from "react-router-dom";
import address_icon from '../../assets/images/address_icon.svg'
import profile_icon from '../../assets/images/profile_icon.svg'
import printer_icon from '../../assets/images/printer_icon.svg'
import notifications from '../../assets/images/notifications.svg'
import multi_account from '../../assets/images/multi_account.png'
import { useLanguage } from '../../languages/Language';
import { useAuth } from "../../auth/services/authContext";
const { Meta } = Card;

const UserSettingsContent = () => {
  let { getText } = useLanguage();
  const auth = useAuth();
  return (
    <>
      <Row gutter={[20, 20]} className={'shipment-container'}>
        <Col span={6}>
          <Link to={'/settings/profile'}>
            <Card
              hoverable
              className={'option-card user-setting-card-container'}
              cover={<Image preview={false} src={profile_icon} className={"user-setting-logos profile"}/>}>
              <Meta title={getText('Profile')} className={'custom-card-meta cards-settings-page'}/>
            </Card>
          </Link>
        </Col>
        <Col span={6}>
          <Link to={'/settings/locations'}>
            <Card
              hoverable
              className={'option-card user-setting-card-container'}
              cover={<Image preview={false} src={address_icon} className={"user-setting-logos address"}/>}>
              <Meta title={getText('Manage Address')} className={'custom-card-meta cards-settings-page'}/>
            </Card>
          </Link>
        </Col>
        <Col span={6}>
          <Link to={'/settings/print_label_size'}>
            <Card
              hoverable
              className={'option-card user-setting-card-container'}
              cover={<Image preview={false} src={printer_icon} className={"user-setting-logos address"}/>}>
              <Meta title={'Shipping Label'} className={'custom-card-meta cards-settings-page'}/>
            </Card>
          </Link>
        </Col>
        
        {
          auth.user.payment_type === 'PayOnMonthlyInvoice' && 
            <Col span={6}>
              <Link to={auth.user.account_type === 'Master Account' ? '/settings/manage_accounts' : '/settings/sub_account_view'}>
                <Card
                  hoverable
                  className={'option-card user-setting-card-container'}
                  cover={<Image preview={false} src={multi_account} className={"user-setting-logos address"}/>}>
                  <Meta title={getText('Manage Account')} className={'custom-card-meta cards-settings-page'}/>
                </Card>
              </Link>
            </Col>
        }

        <Col span={6}>
          <Link to={'/settings/notifications'}>
            <Card
              hoverable
              className={'option-card user-setting-card-container'}
              cover={<Image preview={false} src={notifications} className={"user-setting-logos address"}/>}>
              <Meta title={'Notifications'} className={'custom-card-meta cards-settings-page'}/>
            </Card>
          </Link>
        </Col>
        
      </Row>
    </>
  )
};

// <Col span={6}>
//   <Link to={'#'}>
//     <Card
//       hoverable
//       className={'option-card user-setting-card-container'}
//       cover={<Image preview={false} src={label_icon} className={"user-setting-logos label"}/>}>
//       <Meta title="Label Preference" className={'custom-card-meta'}/>
//     </Card>
//   </Link>
// </Col>

export const UserSettings = () =>  {
  return (
    <Row align="middle" justify="center">
      <Col span={24} className={'form-container'}>
        <Card className="user-setting-card-wrapper">
          <UserSettingsContent />
        </Card>
      </Col>
    </Row>
  )
};
