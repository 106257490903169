import {Select, Space, Button, message, Col, Row, DatePicker, Modal, Form, Input, Tooltip} from 'antd';
import { CSVLink } from "react-csv";
import {OrderStatusListForAllStatus} from '../../commons/utils/constants'
import {useEffect, useState} from 'react';
import {
  FileProtectOutlined, SearchOutlined
} from '@ant-design/icons';
import { useQuery, useMutation } from "react-query";
import {useAuth} from "../../auth/services/authContext";
import {getAllCountries_from_b2c } from "../../orders/services/privateApi";
import { getOrderDataForReportsb2c } from "../services";
import moment, {Moment} from 'moment';
import { AnalyticsButton } from '../../commons/components/AnalyticsButton/AnalyticsButton';
import { UserClicksOnReportShare } from '../../commons/mixpanel';
const {Option} = Select;
const { RangePicker } = DatePicker;

interface Filters {
  startDate?: string | null,
  endDate?: string | null,
  pickupCountryString?: Array<string>,
  destinationCountryString?: Array<string>,
  searchQuery?: string,
  orderStatusString?: Array<string>
}

interface OpenDropdowns {
  openPickup: boolean,
  openDestination: boolean,
  openTracking: boolean
}

const formatInitialDate = (type: string) => {
  if(type === 'start') {
    return  moment().subtract(1, 'months').startOf('day').format()
  } else {
    return moment().add(1, 'day').endOf('day').format();
  }
}

const formatDate = (date:Moment, type?: string) => {
  if(type === 'start') {
    return  moment(date).startOf('day').format()
  } else {
    return moment(date).endOf('day').format();
  }
}

const ReportsFilters = (props:Filters) => {
  const auth = useAuth();

  const [filterObject, setFilterObject] = useState<Filters>({
    startDate: formatInitialDate('start'),
    endDate: formatInitialDate('end'),
    pickupCountryString: [],
    destinationCountryString: [],
    searchQuery: "",
    orderStatusString: []
  })

  const [openDropdown, setOpenDropdown] = useState<OpenDropdowns>({
    openPickup: false,
    openDestination: false,
    openTracking: false
  })

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCSVModalVisible, setIsCSVModalVisible] = useState(false);
  const [isgatewayTimeout, setIsgatewayTimeout] = useState(false);
  const [email, setEmail] = useState<string>('')
  const [iscsvdownload, setIscsvDownload] = useState<boolean>(false)

  const onChangeHandler = (value: any, type: string) => {
    if(type === 'order_status') {
      setFilterObject((prevState) => (
        {
          ...prevState, 
          orderStatusString: value
        })
      );
    }
    if(type === 'pickup') {
      setFilterObject((prevState) => (
        {
          ...prevState, 
          pickupCountryString: value
        })
      );
    }
    if(type === 'destination') {
      setFilterObject((prevState) => (
        {
          ...prevState, 
          destinationCountryString: value
        })
      );
    }
    if(type === 'tracking_no') {
      setFilterObject((prevState) => (
        {
          ...prevState,
          searchQuery: value
        })
      );
    }
    // if(type === 'service_type'){
    //   setServiceType(value)
    // }
  }

  const getDataForCsvDownload = useMutation(async () => {
    return getOrderDataForReportsb2c(auth.user.secret_key, {
      "tracking_nos": filterObject.searchQuery,
      "public_status_v2_shipper": filterObject.orderStatusString?.toString(),
      "pickup_country": filterObject.pickupCountryString?.toString(),
      "consignee_country": filterObject.destinationCountryString?.toString(),
      "date_from": filterObject.startDate,
      "date_to": filterObject.endDate
      // "service_type": props.serviceTypeString,
    })
  },  {
    onSuccess: (val) => {
      resetFilters()
      setIsCSVModalVisible(false)
      setIscsvDownload(true)
    },
    onError: (error: any) => {
      if(error.response.status === 504) {
        setIsgatewayTimeout(true)
      }
      message.error('We are unable to process with your request, please contact our customer support', 10);
    },
  })
  const sendCsvViaEmail = useMutation(async () => {
    return getOrderDataForReportsb2c(auth.user.secret_key, {
      "tracking_nos": filterObject.searchQuery,
      "public_status_v2_shipper": filterObject.orderStatusString?.toString(),
      "pickup_country": filterObject.pickupCountryString?.toString(),
      "consignee_country": filterObject.destinationCountryString?.toString(),
      "date_from": filterObject.startDate,
      "date_to": filterObject.endDate,
      "report": true,
      "emails": email,
      // "service_type": props.serviceTypeString,
    })
  },  {
    onSuccess: (val) => {
      setIsModalVisible(false);
      message.success('We have sent your orders report to your email. You should receive it in few seconds.', 10)
      resetFilters()
    },
    onError: (error) => {
      setIsModalVisible(false);
      message.error('We are unable to process with your request, please contact our customer support', 10);
    },
  })

  const openModal = () => {
    setIsModalVisible(true)
  }

  const generateCSV = () => {
    getDataForCsvDownload.mutate()
    setIsCSVModalVisible(true)

    return false
  }
  
  const getAllCountriesData = useQuery('getCountriesData', () => {
    return getAllCountries_from_b2c()
  })

  const resetFilters = () => {
    setFilterObject({
      startDate: formatInitialDate('start'),
      endDate: formatInitialDate('end'),
      pickupCountryString: [],
      destinationCountryString: [],
      searchQuery: "",
      orderStatusString: []
    })
  }
  
  const onChange = (data: any) => {
    if(data) {
      let startDate = formatDate(data[0], 'start')
      let endDate = formatDate(data[1], 'end')

      setFilterObject((prevState) => (
        {
          ...prevState,
          startDate: startDate,
          endDate: endDate
        })
      );
    } else {
      setFilterObject((prevState) => (
        {
          ...prevState,
          startDate: '',
          endDate: ''
        })
      );
    }
  }
  useEffect(() => {
    if(getDataForCsvDownload?.data?.length > 0) {
      if(document.getElementById('janio_csv')) {
        let a = document.getElementById('janio_csv')
        a?.click()
        setIscsvDownload(false)
      }
    }
  }, [getDataForCsvDownload])
  
  const onFinish = (values: any) => {
    setEmail(values?.email)
    sendCsvViaEmail.mutate()
  };

  if(filterObject?.pickupCountryString!.length > 0 || filterObject?.destinationCountryString!.length > 0 || filterObject?.orderStatusString!.length > 0 || filterObject?.searchQuery!.length > 0) {

  }
  return (
    <>
      {getDataForCsvDownload?.data?.length > 0 && iscsvdownload && <CSVLink
        data={getDataForCsvDownload.data}
        className={"download-csv-btn"}
        style={{height: '10px', width: '30px', visibility: 'hidden'}}
        id='janio_csv'
      />}
      <Row gutter={[10,10]}>
        {/*Date*/}
        <Col span={6} className='reports-filter-option'>
          <span>Date of Creation</span>
          <div style={{ paddingTop: '8px'}}><RangePicker
            style={{width: '100%'}}
            value={[filterObject.startDate ? moment(filterObject.startDate) : moment().subtract(1, 'months').startOf('days'), filterObject.endDate ? moment(filterObject.endDate) :  moment().endOf('day')]}
            onFocus={() => {
              setOpenDropdown({
                openPickup: false,
                openDestination: false,
                openTracking: false
              })}
            }
            // showTime
            onChange={onChange}
            // allowClear={false}
          /></div>
        </Col>

        {/*Pickup Country*/}
        <Col span={6} className='reports-filter-option'>
          <span>Pickup Country</span>
          <Select
            mode="multiple"
            onChange={(value: any) => onChangeHandler(value, 'pickup')}
            onFocus={() => {
              setOpenDropdown({
                openPickup: true,
                openDestination: false,
                openTracking: false
              })}
            }
            onBlur={()=>{setOpenDropdown({openPickup: false, openDestination: false, openTracking: false})}}
            className={"select-wrapper"}
            open={openDropdown.openPickup}
            showArrow
            allowClear
            value={filterObject.pickupCountryString}
            style={{ width: '100%', paddingTop: '8px'}}
            placeholder="Pickup Country"
            maxTagCount={1}
          >
            {
              getAllCountriesData && getAllCountriesData.data?.map((item: any) => {
                return <Option key={item} value={item}>{item}</Option>
              })
            }
          </Select>
        </Col>

        {/*Destination Country*/}
        <Col span={6} className='reports-filter-option'>
          <span>Destination Country</span>
          <Select
            mode="multiple"
            onChange={(value: any) => onChangeHandler(value, 'destination')}
            onFocus={() => {
              setOpenDropdown({
                openPickup: false,
                openDestination: true,
                openTracking: false
              })}
            }
            onBlur={()=>{setOpenDropdown({openPickup: false, openDestination: false, openTracking: false})}}
            className={"select-wrapper"}
            open={openDropdown.openDestination}
            showArrow
            allowClear
            value={filterObject.destinationCountryString}
            style={{ width: '100%', paddingTop: '8px'}}
            placeholder="Destination Country"
            maxTagCount={1}
          >
            {
              getAllCountriesData && getAllCountriesData.data?.map((item: any) => {
                return <Option key={item} value={item}>{item}</Option>
              })
            }
          </Select>
        </Col>
        <Col span={6} className='reports-filter-option'>
          <span>Tracking number</span>
          <Space direction="vertical"  style={{width: '100%', paddingTop: '8px'}}>
            <Tooltip title={<><span>Search multiple Tracking numbers(maximum 10 TNs) in the format</span> <strong>TN1 TN2 TN3</strong></>} placement="topLeft">
              <Input id="searchId" placeholder={'Tracking number(s)'} value={filterObject.searchQuery} onChange={(event) => onChangeHandler(event.target.value, 'tracking_no')} suffix={<SearchOutlined style={{fontSize: '18px', color: '#808080'}}/>} />
            </Tooltip>
          </Space>
        </Col>
      </Row>
      <Row gutter={[10,10]} className='row-margin-bottom'>
        {/*Order Status*/}
        <Col span={6} className='reports-filter-option'>
          <span>Order Status</span>
          <Select
            mode="multiple"
            onChange={(value: any) => onChangeHandler(value, 'order_status')}
            onFocus={() => {
              setOpenDropdown({
                openPickup: false,
                openDestination: false,
                openTracking: true
              })}
            }
            dropdownClassName={'custom-font'}
            className={"select-wrapper"}
            allowClear
            showArrow
            open={openDropdown.openTracking}
            onBlur={()=>{setOpenDropdown({openPickup: false, openDestination: false, openTracking: false})}}
            value={filterObject.orderStatusString}
            style={{ width: '100%', paddingTop: '8px'}}
            placeholder="Order Status"
            maxTagCount={1}
          >
            {
              Object.entries(OrderStatusListForAllStatus).map(([id, status]) => {
                return (
                  <Option key={id} value={id}>{status}</Option>
                )
              })
            }
          </Select>
        </Col>
        {/* <Col span={6}>
          <AnalyticsButton 
            type='default'
            // disabled={!props.hasData}
            onButtonClick={openModal} 
            mixpanelAnalytics={() => UserClicksOnReportShare('Send Report via Email')} 
            style={{width: '100%'}}
          >Send Report via Email
          </AnalyticsButton>
        </Col>
        <Col span={6}>
          <AnalyticsButton 
            type='primary'
            // disabled={!props.hasData}
            onButtonClick={generateCSV} 
            loading={getDataForCsvDownload.isLoading}
            mixpanelAnalytics={() => UserClicksOnReportShare('Download Report')} 
            style={{width: '100%'}}
          >Download Report
          </AnalyticsButton>
        </Col> */}
        
      </Row>
      
      <Row justify='end'>
        <Col span={3} style={{marginRight: '10px'}}>
          <Button type='default' onClick={() => resetFilters()} style={{width: '100%'}}>Reset</Button>
        </Col>
        <AnalyticsButton 
          type='default'
          // disabled={!props.hasData}
          onButtonClick={openModal} 
          mixpanelAnalytics={() => UserClicksOnReportShare('Send Report via Email')} 
          style={{marginRight: '10px', minWidth: '140px'}}
        >Send Report via Email
        </AnalyticsButton>

        <AnalyticsButton 
          type='primary'
          // disabled={!props.hasData}
          onButtonClick={generateCSV} 
          loading={getDataForCsvDownload.isLoading}
          mixpanelAnalytics={() => UserClicksOnReportShare('Download Report')} 
          style={{minWidth: "165px"}}
        >Download Report
        </AnalyticsButton>
      </Row>

      <Modal 
        title={
          isgatewayTimeout ?
            <Row align='middle'>
              <Col span={24} className={'modal-wait'}>
                <FileProtectOutlined />
                <p>The file is too big to be downloaded right now, please select <strong>Send via email</strong>, and we will email to you the report once it is ready!</p>
              </Col>
            </Row> :
            <Row align='middle'>
              <Col span={24} className={'modal-wait'}>
                <FileProtectOutlined />
                <p>Please wait while we are downloading your CSV</p>
              </Col>
            </Row>
        }
        visible={isCSVModalVisible} 
        footer={null}
        centered
        onCancel={() => setIsCSVModalVisible(false)}
        closable={false}
        wrapClassName={'report-modal-wrapper'}
        width={500}
      >
      </Modal>

      <Modal 
        title={
          <Form
            onFinish={onFinish}
            style={{ maxWidth: 500 }}
            className={"modal-form"}
          >
            <Form.Item name={'email'} label="Email" rules={[{ type: 'email', required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item className='button-inside-modal'>
              <Button type="primary" htmlType="submit" loading={sendCsvViaEmail.isLoading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        }
        visible={isModalVisible} 
        footer={null}
        centered
        onCancel={() => setIsModalVisible(false)}
        wrapClassName={'report-modal-wrapper'}
        width={500}
      >
      </Modal>
    </> 
  )
};

export default ReportsFilters