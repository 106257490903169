import {Select, Space, Button, Input} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import {useState} from 'react';
import { useQuery } from "react-query";
import { getAllCountries_from_b2c } from "../../orders/services/privateApi";
const {Option} = Select;

interface ShopifyFilterInterface {
    country: string | null;
    setCountry(val: string):void;
    resetCommonSearch(): void;
    status: string 
    setSearchQuery(val: string):void 
    setStatus(val: string):void
    searchQuery?: string | number
}
const ShopifyFilters = (props: ShopifyFilterInterface) => {
  const [shipperOrderIDs, setShipperOrderIds] = useState<any>('');
  const [destinationCountryCode, setDestinationCountryCode] = useState<any>([]);
  const [open, setOpen] = useState(false)
  
  const onDestinationCountryClear = () => {
    setDestinationCountryCode(undefined)
    props.setCountry('')
  }

  const getDestinationCountriesData = useQuery('getDestinationCountriesData', () => {
    return getAllCountries_from_b2c()
  })
  
  const onChangeHandler = (value: any, type: string) => {
    if(type === 'shipper_order_id') {
      setShipperOrderIds(value)
    }
    if(type === 'destination') {
      setDestinationCountryCode(value)
    }
  }

  const resetFilters = () => {
    setDestinationCountryCode([])
    props.setCountry('')
    props.setSearchQuery('')
    setShipperOrderIds('')
  }

  const searchButton = () => {
    if(shipperOrderIDs){
      let values =  shipperOrderIDs.replace(/\s*,\s*/g, ",");
      props.setSearchQuery(values)
    }
    if(destinationCountryCode){
      const value = destinationCountryCode.join(',')
      props.setCountry(value)
    }
  }

  return (
    <>
      <div>
        <Space direction="horizontal">
          <Input id="searchId" placeholder={"Shopify Order Id"} value={shipperOrderIDs} onChange={(event) => onChangeHandler(event.target.value, 'shipper_order_id')} suffix={<SearchOutlined style={{fontSize: '18px', color: '#808080'}}/>}/>
          {/*Consignee Country*/}
          <Select
            mode="multiple"
            onChange={(value: any) => onChangeHandler(value, 'destination')}
            onFocus={() => {
              return (
                setOpen(true)
              )}
            }
            onClear={()=>{onDestinationCountryClear()}}
            defaultOpen={open}
            showArrow
            allowClear
            value={destinationCountryCode}
            style={{ width: '200px' }}
            maxTagCount={1}
            placeholder="Consignee Country"
          >
            {
              getDestinationCountriesData && getDestinationCountriesData.data?.map((item: any) => {
                return <Option key={item} value={item}>{item}</Option>
              })
            }
          </Select>
          <Button type='primary' onClick={() => searchButton()}>Search</Button>
          <Button type='default' onClick={() => resetFilters()}>Reset</Button>
        </Space>
      </div>
    </>
  )
};

export default ShopifyFilters