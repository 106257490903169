import { Col } from 'antd';
import PrintLabelHeader from '../ShipmentStatusType/PrintLabelHeader';
import PrintPodHeader from '../ShipmentStatusType/PrintPodHeader';
import { OrderDetailData_from_b2c } from '../services';

interface PropsType {
  selectedOrders: Array<OrderDetailData_from_b2c>
  orderStatus?: string
}
const ShowBothBulkDownloadHeaders = (props: PropsType) => {
  return (
    <>
      <div className="main-print-container" style={{padding: '0 22px'}}>
        <div className="print-content" style={{height: '60px', justifyContent: 'unset'}}>
        </div>
        <div className="arrow-down" style={{marginLeft: '8px'}}></div>
      </div>

      <Col span={18} style={{zIndex: 6, display: 'flex', justifyContent: 'left'}}>
        <div>
          <div style={{color: '#fff', paddingLeft: '9px', lineHeight: '43px'}}>
            {props.selectedOrders.length} Shipments selected
          </div>
        </div>
        <div style={{display: 'inherit', alignItems: 'center'}}>
          <PrintLabelHeader selectedOrders={props.selectedOrders} isDisplayingInBulk={true} />
        </div>
        <div style={{display: 'inherit', alignItems: 'center'}}>
          <PrintPodHeader selectedOrders={props.selectedOrders}  isDisplayingInBulk={true} />
        </div>
      </Col>
    </>
  )
};

export default ShowBothBulkDownloadHeaders
