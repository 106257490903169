import {GoogleMap, Marker, InfoWindow, useJsApiLoader, Autocomplete} from '@react-google-maps/api'
import { useState,useRef } from 'react';
import {Button, Card, List, Input} from 'antd';
import {ArrowRightOutlined, SearchOutlined} from '@ant-design/icons';
import {formatOpeningHours} from '../commons/utils/utilizer';
import './GoogleMaps.css';

const libraries:any = ["places"];

interface PropsType {
  setSelectedDropOffPoint: (val: any) => void,
  dropOffLocations: any
}

export const GoogleMaps = (props:PropsType) => {
  const [selectedDropOffPoint, setSelectedDropOffPoint] = useState<any>();
  const mapRef = useRef<google.maps.Map | undefined>(undefined);
  const autoCompleteRef = useRef<google.maps.places.Autocomplete | undefined>(undefined)
  const [selectedAutoCompleteAddress, setSelectedAutoCompleteAddress] = useState();
  const {isLoaded} = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyCzjX5o43T4wWKXzimnY1Qb94y2Vys-y1w',
    libraries
  })

  const center = {
    lat: 1.3414944,
    lng: 103.8141409
  }

  const containerStyle:any = {
    width: "620px",
    height: "500px",
  };

  const handleMarkerClick = (val:any) => {
    setSelectedDropOffPoint(val)
  }

  const handleInfoWindowClose = () => {
    setSelectedDropOffPoint(undefined)
  }

  const handleDropOffItemsClick = (val: any) => {
    props.setSelectedDropOffPoint(val)
  }

  const onPlaceChange = () => {
    if (autoCompleteRef.current) {
      const selectedPlace:any = autoCompleteRef.current.getPlace();
      const selectedAddress = selectedPlace?.name;
      setSelectedAutoCompleteAddress(selectedAddress)
      const location:google.maps.LatLngLiteral = {
        lat: selectedPlace?.geometry?.location?.lat(),
        lng: selectedPlace?.geometry?.location?.lng(),
      }
      const map:google.maps.Map | undefined = mapRef.current;
      map?.setCenter(location);
    }
  }

  const onInputChange = (event:any) => {
    setSelectedAutoCompleteAddress(event.target.value)
  }

  const onMapLoad = (map:google.maps.Map) => {
    if(props.dropOffLocations?.data?.dropoff_locations){
      const bounds = calculateMapBounds(props.dropOffLocations.data.dropoff_locations);
      map.fitBounds(bounds);
    }
    mapRef.current = map;
  }

  const onAutoCompleteLoad =(autocomplete:google.maps.places.Autocomplete) => {
    autoCompleteRef.current = autocomplete;
  }

  const calculateMapBounds = (dropOffLocations:any) => {
    const bounds = new google.maps.LatLngBounds();
    dropOffLocations?.map((dropOffPoint:any) =>{
      const locationPosition = new google.maps.LatLng(dropOffPoint.latitude,dropOffPoint.longitude);
      return bounds.extend(locationPosition);
    })
    return bounds
  }

  if(!isLoaded){
    return <p>Map is loading...</p>
  }

  return (
    <div className="parent-drop-off-container">
      <div className="map-container">
        <GoogleMap
          mapContainerStyle={containerStyle}
          options={{
            fullscreenControl: false,
            mapTypeControl: false,
            clickableIcons: false,
          }}
          center={center}
          zoom={12}
          onLoad={onMapLoad}
        >
          <Autocomplete onLoad={onAutoCompleteLoad} onPlaceChanged={onPlaceChange}>
            <div className="maps-autocomplete">
              <Input value={selectedAutoCompleteAddress} onChange={onInputChange} placeholder={"Search by address and postal code"} prefix={<SearchOutlined style={{fontSize: '18px', color: '#808080'}}/>} size="large" style={{marginTop: 15}}/>
            </div>
          </Autocomplete>

          {props.dropOffLocations.data?.dropoff_locations?.map((dropOffPoint:any, i:any) => (
            <Marker
              key={i}
              position={{
                lat: dropOffPoint.latitude,
                lng: dropOffPoint.longitude
              }}
              onClick={()=>handleMarkerClick(dropOffPoint)}
            />
          ))}
          {(selectedDropOffPoint) &&
            <InfoWindow
              position={{
                lat: selectedDropOffPoint.latitude,
                lng: selectedDropOffPoint.longitude
              }}
              options={{ pixelOffset: new google.maps.Size(0, -40) }}
              onCloseClick={()=>handleInfoWindowClose()}
            >
              <div className="drop-off-address-container">
                <p className="address-name">{selectedDropOffPoint.name}</p>
                <div className="drop-off-address">
                  <p>{selectedDropOffPoint.address}</p>
                  <p>{selectedDropOffPoint.country} {selectedDropOffPoint.postal}</p>
                  <p>{formatOpeningHours(selectedDropOffPoint.opening_hours).join(', ')}</p>
                </div>
                <div>
                  <Button onClick={(e:any) => handleDropOffItemsClick(selectedDropOffPoint)} style={{width:'100%',borderRadius:'0px'}} type="primary" className="drop-off-button">Drop-Off items here</Button>
                </div>
              </div>
            </InfoWindow>
          }
        </GoogleMap>
      </div>
      <div className="address-dropdown">
        <Card title={<strong>Drop-off Locations</strong>} bordered={false}>
          <div className="drop-off-address-list">
            <List
              dataSource={props.dropOffLocations.data?.dropoff_locations}
              renderItem={(dropOffPoint:any) => {
                return (
                  <List.Item>
                    <div className={'pudo-list'}>
                      <strong>{dropOffPoint.name}</strong>
                      <p>{dropOffPoint.address}</p>
                      <p>{dropOffPoint.country} {dropOffPoint.postal}</p>
                      {dropOffPoint.opening_hours&&(
                        <p>{formatOpeningHours(dropOffPoint.opening_hours)?.join(', ')}</p>
                      )
                      }
                      <Button className={"outline-btn"} style={{marginTop:'10px'}} type="primary" onClick={(e:any) => handleDropOffItemsClick(dropOffPoint)}>
                        {'Drop-off items here'}
                        <ArrowRightOutlined />
                      </Button>
                    </div>
                  </List.Item>
                );}} />
          </div>
        </Card>
      </div>
    </div>
  )
}