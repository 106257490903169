import {
  Form as F,
  Button,
  Col,
  Row,
  Select,
  Divider,
  Card,
  message,
  Modal,
  Alert,
  Space,
  Spin 
} from 'antd';
import {useQuery} from "react-query";
import '../../styles/SingleOrderForm.css';
import React, {useEffect, useState, useRef} from "react";
import {useMutation} from "react-query";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {Formik, FieldArray, Form, FormikHelpers, Field } from 'formik';
import {useAuth} from "../../../auth/services/authContext";
import {useSingleOrder, Item, OrderError, combineFieldsErrors, formatItemErrors} from "../../services/singleOrderContext";
import {itemCategories, countryCurrencies, mostUsedCurrencies,  GoodsListSheetIds, countries, contactCustomerService,countryCodeCurrencies} from "../../../commons/utils/constants";
import {FormInput, FormSelect, RadioGroup, TitleErrorMarker} from '../Forms';
import {parseErrors} from '../../helpers';
import { useHistory , useParams } from 'react-router-dom';
import * as Yup from 'yup';
import {SingleOrderCreate, UserCreatedDraftOrder} from "../../../commons/mixpanel"
import {validateVolumetricWeight} from "../../../commons/utils/validator";
import {useRouteLeavingGuard} from "../../../commons/components/RouteGuard/routeGuardContext";
import {findWordInString} from '../../../commons/utils/utilizer'
import { useLanguage } from '../../../languages/Language';
import { fetchExtraUserData } from '../../services/privateApi'
import { shipperprofileFromB2C } from '../../../auth/services/authApi';

const { confirm } = Modal;
const { Option, OptGroup } = Select;
interface PropsType {
  current: number,
  setCurrentState: (val:number)=> void,
  dataFilled:number,
  isMarketplaceOrder?: boolean,
}

interface OrderProps {
  items: Item[]
  order_weight: string | null
  order_length: string | null
  order_width: string | null
  order_height: string | null
  isCod?: string
  cod_currency?: string
  cod_amount?: number | null
  incoterm?: string
  custom_tn?: any
}

const ItemForm = ({isDomestic, remove, index, contextData}: {isDomestic: boolean, remove: () => any, index: number, contextData: any}) => {
  let { getText } = useLanguage();
  return (
    <>
      <FormInput name={`items.[${index}].item_desc`} required label={getText('Item Description')}/>

      <Row gutter={14}>
        <Col span={12}>
          <FormSelect name={`items.[${index}].item_category`} required label={getText('Item Category')} placeholder={'Please select item category'}>
            {itemCategories.map(item => (
              <Option key={item} value={item}>{item}</Option>
            ))}
          </FormSelect>
        </Col>

        <Col span={12}>
          <Row gutter={14}>
            <Col span={8}>
              <FormInput name={`items.[${index}].item_quantity`} required label={getText('Quantity')} inputProps={{type: 'number', min: 1, step:1}}/>
            </Col>
            <Col span={8}>
              <FormSelect name={`items.[${index}].item_price_currency`} required label={getText('Currency')} showSearch={"true"}>
                <>
                  <OptGroup label="Most Used">
                    {mostUsedCurrencies.map(item => (
                      <Option key={item} value={item}>{item}</Option>
                    ))}
                  </OptGroup>
                  <OptGroup label="All">
                    {countryCurrencies.map(item => (
                      <Option key={item} value={item}>{item}</Option>
                    ))}
                  </OptGroup>
                </>
                {/* {countryCurrencies.map(item => (
                  
                  // <Option key={item} value={item}>{item}</Option>
                ))} */}
              </FormSelect>
            </Col>
            <Col span={8}>
              <FormInput name={`items.[${index}].item_price_value`} required label={<label className='text-wrap' title={'Per Item Value'}>{getText('Per Item Value')}</label>} inputProps={{type: 'number', min: 1, step:0.01}} />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={14}>
        <Col span={12}>
          <FormInput name={`items.[${index}].product_id`} label={getText('Product ID (Optional)')}/>
        </Col>
        <Col span={12}>
          <FormInput name={`items.[${index}].item_product_url`} label={getText('Product URL (Optional)')}/>
        </Col>
      </Row>

      <Row gutter={14}>
        <Col span={12}>
          <FormInput name={`items.[${index}].item_sku`} label={getText('Item SKU (Optional)')}/>
        </Col>

        {
          ((contextData.singleOrder.consignee_country_code === 'SG' && contextData.singleOrder.pickup_country_code === 'SG' && contextData.formContext.service_type === 'CC_LM') || (contextData.singleOrder.pickup_country_code !== 'SG' && contextData.singleOrder.consignee_country_code === 'SG')) && 
          <Col span={12}>
            <Row gutter={24}>
              <Col span={12}>
                <FormInput name={`items.[${index}].gst_ovr`} label={<label className='text-wrap' title={'GST/OVR (Optional)'}>GST/OVR (Optional)</label>}/>
              </Col>
              <Col span={12} id={'gst-wrapper'}>
                <div id="gst-radio-group" className='text-wrap' title={'GST Collected? (Optional)'}>GST Collected? (Optional)</div>
                <div role="group" aria-labelledby="gst-radio-group" className='gst-radio-wrapper'>
                  <Col span={24}>
                    <Row>
                      <Col span={12}>
                        <label>
                          <Field type="radio" name={`items.[${index}].gst_collected`} value={'false'} />
                          No
                        </label>
                      </Col>
                      <Col span={12}>
                        <label>
                          <Field type="radio" name={`items.[${index}].gst_collected`} value={'true'} />
                          Yes
                        </label>
                      </Col>
                    </Row>
                  </Col>
                </div>
              </Col>
            </Row>
          </Col>
        }
      </Row>

      {index > 0 && <div className={"button-block"}>
        <Button type="primary" onClick={remove} className={"no-border-btn"}>
          {getText('Remove item')}
        </Button>
      </div>
      }
    </>)
}

const empty_item = {
  item_desc: '',
  item_category: '',
  item_quantity: '',
  item_price_currency: '',
  item_price_value: '',
  product_id: '',
  item_sku: '',
  item_product_url: '',
  gst_ovr: '',
  gst_collected: false
}

const parseOrderContextError = (error: OrderError) => ({
  items: error.items,
  order_weight: error.order_weight,
  order_length: error.order_length,
  order_height: error.order_height,
  order_width: error.order_width,
  custom_tn: error.custom_tn
})

const validationSchema = (getText:any, client_billing: string) => {
  return Yup.object().shape({
    order_weight: Yup.string().nullable().required(getText('Weight is required')).test(
      'volumetric_weight',
      (val: any, context: any) => {
        const result = validateVolumetricWeight({weight:val, length:context.parent.order_length, width:context.parent.order_width, height:context.parent.order_height}, client_billing);
        if(result && result === 'weight'){
          message.error(<p>{getText("Your parcel exceeds our volumetric weight. Please reach out to us at")} <a href={'mailto:clientsupport@janio.asia'}>clientsupport@janio.asia</a> {getText("should you wish to ship with us.")}</p>, 5);
          return context.createError({
            path: context.path,
            message: getText('Exceeded volumetric weight')
          })
        }
        return true
      }
    ),
    order_length: Yup.string().nullable().required(getText('Length is required')).test(
      'volumetric_weight',
      (val: any, context: any) => {
        const result = validateVolumetricWeight({weight:context.parent.order_weight, length:val, width:context.parent.order_width, height:context.parent.order_height}, client_billing);
        if(result && result === 'volumetric weight'){
          message.error(<p>{getText("Your parcel exceeds our volumetric weight. Please reach out to us at")} <a href={'mailto:clientsupport@janio.asia'}>clientsupport@janio.asia</a> {getText("should you wish to ship with us.")}"</p>, 5);
          return context.createError({
            path: context.path,
            message: getText('Exceeded volumetric weight')
          })
        }
        return true
      }
    ),
    order_width: Yup.string().nullable().required(getText('Width is required')).test(
      'volumetric_weight',
      (val: any, context: any) => {
        const result = validateVolumetricWeight({weight:context.parent.order_weight, length:context.parent.order_length, width:val, height:context.parent.order_height}, client_billing);
        if(result && result === 'volumetric weight'){
          return context.createError({
            path: context.path,
            message: getText('Exceeded volumetric weight')
          })
        }
        return true
      }
    ),
    order_height: Yup.string().nullable().required(getText('Height is required')).test(
      'volumetric_weight',
      (val: any, context: any) => {
        const result = validateVolumetricWeight({weight:context.parent.order_weight, length:context.parent.order_length, width:context.parent.order_width, height:val}, client_billing);
        if(result && result === 'volumetric weight'){
          return context.createError({
            path: context.path,
            message: getText('Exceeded volumetric weight')
          })
        }
        return true
      }
    ),
    custom_tn: Yup.string().nullable().notRequired().when(
      ['custom_tn'], {
        is: (value: any) => value?.length,
        then: (rule) => {
          return rule.matches(
            /^[a-zA-Z0-9-]*$/,
            'Custom Tracking numbers must be alphanumeric (a-z, A-Z, 0-9, hyphen(-))'
          )
        }
      }
    ).max(30),
    items: Yup.array().of(Yup.object().shape({
      item_desc: Yup.string().nullable().required(getText('Item Description is required')),
      item_category: Yup.string().nullable().required(getText('Item Category is required')),
      item_quantity: Yup.number().integer('Quantity must be an Integer').nullable().required(getText('Item Quantity is required')),
      // item_price_currency: Yup.string().nullable().required(getText('Required')),
      item_price_currency: Yup.string().nullable().required(getText('Required')).test(
        'item_price_currency',
        (val: any, context: any) => {
          if(!mostUsedCurrencies.includes(val)){
            return context.createError({
              path: context.path,
              message: `${val} is currently not a supported currency`
            })
          }
          return true
        }
      ),
      item_price_value: Yup.string().nullable().required(getText('Required')),
      item_product_url: Yup.string().nullable().max(200,'Cannot be more than 200 characters'),
      gst_ovr: Yup.string().nullable().max(200,'Cannot be more than 200 characters'),
      gst_collected: Yup.boolean()
    })),
  }, 
  // cyclic dependency
  [['custom_tn', 'custom_tn']])
}

export const ItemDetailsForm = (props:PropsType) =>{
  let { getText } = useLanguage();
  const auth = useAuth();
  const orderID = useParams<any>()
  const singleOrderContext = useSingleOrder();
  const history = useHistory()

  const isOnlyDduAvailable = () => {
    let wholeOrderData = singleOrderContext.singleOrder
    let paymentMode = auth.user.payment_type === 'PayOnMonthlyInvoice'
    if(paymentMode && (wholeOrderData.pickup_country_code === 'TH' && wholeOrderData.consignee_country_code === 'US')) {
      return true
    }
    if(paymentMode && (wholeOrderData.pickup_country_code === 'TH' && wholeOrderData.consignee_country_code === 'AU')) {
      return true
    }
    if(paymentMode && wholeOrderData.consignee_country_code === 'VN') {
      return true
    }
    return false
  }

  const getShipperProfile = useQuery('shipperProfileDetails', () =>{
    return shipperprofileFromB2C(auth.user.secret_key)
  }, {cacheTime: 0});

  
  const routeContext = useRouteLeavingGuard();
  const {current, setCurrentState, dataFilled} = props;
  const [orderData, setOrderData] = useState<OrderProps | null>(singleOrderContext.singleOrder);
  const [codDduResponse, setCodDduResponse] = useState<any>([]);
  const isDomestic = singleOrderContext.singleOrder.consignee_country_code === singleOrderContext.singleOrder.pickup_country_code;
  const consigneeCurrency = countryCodeCurrencies[singleOrderContext.singleOrder.consignee_country_code]
  const initialValues: any = {
    items: singleOrderContext.singleOrder.items,
    order_weight: singleOrderContext.singleOrder.order_weight,
    order_length: singleOrderContext.singleOrder.order_length,
    order_height: singleOrderContext.singleOrder.order_height,
    order_width: singleOrderContext.singleOrder.order_width,
    incoterm: singleOrderContext.singleOrder.incoterm === 'DDU' ? 'DDU':'DDP',
    cod_currency: consigneeCurrency,
    cod_amount: singleOrderContext.singleOrder.cod_amount,
    isCod: singleOrderContext.singleOrder.payment_type === 'cod'?'true':'false',
    custom_tn: singleOrderContext.singleOrder.custom_tn
  }
  
  const ItemDetailsFormRef: any = useRef();
  const resetFormikValues = () => {
    if (ItemDetailsFormRef.current) {
      ItemDetailsFormRef.current.setFieldValue('isCod', 'false')
      ItemDetailsFormRef.current.setFieldValue('cod_currency', '')
      ItemDetailsFormRef.current.setFieldValue('cod_amount', null)

      if(isOnlyDduAvailable()) {
        ItemDetailsFormRef.current.setFieldValue('incoterm', 'DDU')
      } else {
        ItemDetailsFormRef.current.setFieldValue('incoterm', 'DDP')
      }
    }
  }
  const [showModal, setShowModal] = useState<boolean>(false);
  const {initialErrors, initialTouched, emptyError, hasError} = parseErrors(singleOrderContext.errors, parseOrderContextError)
  const submitOrder = useMutation(async (args: any) => {
    return props.isMarketplaceOrder
      ? singleOrderContext.submitMarketplaceOrder(
        auth.user.authToken,
        singleOrderContext.singleOrder.id!
      )
      : singleOrderContext.submitSingleOrder(auth.user.authToken);
  });

  const fetchExtraOrderDetails= useMutation(async () => {
    let params = {
      request: [{
        "pickup_country": singleOrderContext.singleOrder.pickup_country_code!,
        "consignee_country": singleOrderContext.singleOrder.consignee_country_code!
      }]
    }
    return fetchExtraUserData(auth.user.authToken, params);
  },
  {
    onSuccess: (val:any) => {
      setCodDduResponse(val);
    },
    onError: (error:any) => {
      console.log("error" +error)
    },
  }
  );

  useEffect(() => {
    resetFormikValues()
    fetchExtraOrderDetails.mutate()
  }, [singleOrderContext.singleOrder.consignee_country_code]) // eslint-disable-line react-hooks/exhaustive-deps

  const errData = (errors: any) => {
    let errorsList: Array<any> = [errors];
    if(errors.hasOwnProperty("items")){
      
      const itemErrors = formatItemErrors(errors)
      delete errors["items"];
      itemErrors.map((itemError:any) => {
        return errorsList.push(itemError);
      })
    }
    let errorList = <ul style={{textAlign: 'left'}}>
      {
        errorsList.map((error) =>
          Object.values(error).map((val:any, index: number) => {
            return <li key={index} style={{fontSize: '12px',color: '#4f4a4a', fontWeight: 600}}>{(Object.keys(error)[index]).replace(/_/g, ' ')}: {val} </li>
          })
        )
      }
    </ul>
    return message.error(
      <span>Error: Please correct the following details and try again. {errorList}</span>,
      10
    )
  }

  const updateError = (errors:any) => {
    if (findWordInString(errors, getText('Order cannot be updated anymore'))) {
      Modal.info({
        title: getText('Cannot Edit Shipment'),
        content: (
          <div>
            <p style={{fontSize: '12px', color: '#616060', marginBottom: '-10px'}}>
              {getText("The shipment has been picked up or is already in delivery. Should you wish to cancel it, please contact us at")} {contactCustomerService()}
            </p>
          </div>
        ),
        className:'update-error-modal',
        onOk() {},
      });
    } else {
      message.error(
        <span>Something went wrong, please contact {contactCustomerService()} </span>,
        10
      )
    }
  }

  const getCalculatedAmount = useMutation(async (value:any) => {
    return singleOrderContext.CheckOrderPaymentStatus(auth.user.authToken, orderID.id)
  })

  const submiteUpdatedOrder = useMutation(async (value:any) => {
    return singleOrderContext.UpdateSingleOrder(auth.user.authToken, singleOrderContext.singleOrder.id!)
  })

  const editOrderStatus = () => {
    let paymentStatuses = ['PAID','ADDITIONAL_PAYMENT_REQUIRED','UNPAID'] ;
    return paymentStatuses.includes(singleOrderContext.singleOrder.payment_status);
  }

  const submitForm = (values: OrderProps, helpers: FormikHelpers<OrderProps>) => {
    setOrderData({...values});

    let Items = values.items.map((item: any, index: any) => {
      let removeObject = {
        ...item
      }
      delete removeObject.gst_collected
      delete removeObject.gst_ovr
      return {
        'gst_collected': item.gst_collected === '' ? false : item.gst_collected === 'true' ? true : false,
        'gst_ovr': item.gst_ovr === '' ? null : item.gst_ovr,
        ...removeObject
      }
    })

    setShowModal(true);
    singleOrderContext.updateSingleOrderData({
      order_weight: values.order_weight,
      order_length: values.order_length,
      order_width: values.order_width,
      order_height: values.order_height,
      payment_type: values.isCod === 'true'?'cod':'prepaid',
      cod_currency: consigneeCurrency,
      cod_amount: values.isCod === 'true'?values.cod_amount:null,
      incoterm:values.incoterm,
      items: Items,
      custom_tn: values.custom_tn,
    });
    singleOrderContext.setErrors({
      ...emptyError
    })
    
    const handleError = (error: any) => {
      if(error.response) {
        singleOrderContext.setErrors(error.response.data)
        const combined = combineFieldsErrors(error.response.data)
        singleOrderContext.setErrors(combined);

        const parsedError = parseOrderContextError(combined)
        helpers.setErrors(parsedError)

        if(error.response.data['non_field_errors']){
          let errors = error.response.data['non_field_errors'].join(',');
          message.error(getText('Failed to create shipment, please try again:')  + errors, 10);
        }
        else{
          errData(error.response.data)
        }
      }
      else{
        message.error(
          <span>Failed to create shipment, please contact {contactCustomerService()}, {error.message} </span>,
          10
        );
      }
    }

    if(singleOrderContext.singleOrder.payment_status === 'PAID' || singleOrderContext.singleOrder.payment_status ===  'ADDITIONAL_PAYMENT_REQUIRED' || singleOrderContext.singleOrder.payment_status === 'MONTHLY_BILLING_PAYMENT_PENDING') {
      getCalculatedAmount.mutate({}, {
        onSuccess: (val: any) => {
          if (val.price.amount_to_pay > 0) {
            singleOrderContext.updateSingleOrderData({
              "custom_tn": singleOrderContext.singleOrder.custom_tn + 'REVISED'
            })
            openConfirmationDialog(val)
          } else {
            submiteUpdatedOrder.mutate({}, {
              onSuccess: (values: any) => {
                SingleOrderCreate('shippingCharges')
                UserCreatedDraftOrder('single')
                singleOrderContext.updateSingleOrderData({
                  "id": values.order.id,
                  "amount": values.rate.total,
                  "currency": values.rate.currency,
                  "amount_to_pay": values.rate.amount_to_pay,
                  "payment_status": values.order.payment_status,
                  "gst_amount": values.rate.gst_amount,
                  "total_duties": values.rate.total_duties,
                  "total_taxes": values.rate.total_taxes,
                  "is_tax_payable": values.rate.is_tax_payable,
                  "charge_id": values.rate.charge_id,
                  "total": values.rate.total,
                  "pickup_charge": values.rate.pickup_charge
                })
                if(dataFilled === 0) {
                  routeContext.setShouldBlockNavigation(false);
                }
                setCurrentState(3);
              },
              onError: (error: any) => {
                // need to properly handle error
                updateError(error.response.data.error)
              }
            });
          }
        },
        onError: handleError
      });
    }else if(singleOrderContext.singleOrder.payment_status === 'UNPAID') {
      submiteUpdatedOrder.mutate({}, {
        onSuccess: (values: any) => {
          SingleOrderCreate('shippingCharges')
          UserCreatedDraftOrder('single')
          singleOrderContext.updateSingleOrderData({
            "id": values.order.id,
            "amount": values.rate.total,
            "currency": values.rate.currency,
            "amount_to_pay": values.rate.amount_to_pay,
            "payment_status": values.order.payment_status,
            "gst_amount": values.rate.gst_amount,
            "total_duties": values.rate.total_duties,
            "total_taxes": values.rate.total_taxes,
            "total": values.rate.total,
            "is_tax_payable": values.rate.is_tax_payable,
            "charge_id": values.rate.charge_id,
            "account_charges": values.account_charges.length > 0 ? values.account_charges : []
          })
          if(dataFilled === 0) {
            routeContext.setShouldBlockNavigation(false);
          }
          setCurrentState(3);
        },
        onError: handleError
      });
    } else {
      submitOrder.mutate({}, {
        onSuccess: (response: any) => {
          if (!props.isMarketplaceOrder) {
            SingleOrderCreate('shippingCharges')
            UserCreatedDraftOrder('single')
            singleOrderContext.updateSingleOrderData({
              "id": response.order.id,
              "amount": response.rate.total,
              "currency": response.rate.currency,
              "amount_to_pay": response.rate.amount_to_pay,
              "payment_status": response.order.payment_status,
              "gst_amount": response.rate.gst_amount,
              "total_duties": response.rate.total_duties,
              "total": response.rate.total,
              "total_taxes": response.rate.total_taxes,
              "is_tax_payable": response.rate.is_tax_payable,
              "charge_id": response.rate.charge_id,
              "account_charges": response.account_charges.length > 0 ? response.account_charges : []
            })
          } else {
            singleOrderContext.updateSingleOrderData({
              "order_id": response.order_id,
            })
          }
          
          if(dataFilled === 0) {
            routeContext.setShouldBlockNavigation(false);
          }
          
          setCurrentState(3);
        },
        onError: handleError
      });
    }
  };
  const openConfirmationDialog = (val:any) => {
    confirm({
      className: 'confirm-dialog custom-confirm-modal',
      title: auth.user.hide_price_estimation ? 
        <p>Making these changes might result in some additional charge and slight change in custom tracking number(if entered).{getText("Would you like to proceed?")}</p> 
        : 
        <p>{getText("Making these changes will result in additional charge of")} <span>{val.price.currency} {val.price.amount_to_pay}</span>  and slight change in custom tracking number(if entered). {getText("Would you like to proceed?")}</p>,
      icon: <ExclamationCircleOutlined />,
      content: auth.user.hide_price_estimation ? 
        'Click OK to cancel the previous order and create this one.'
        :
        getText('Click OK to cancel the previous order and pay for this one.'),
      onOk() {
        submiteUpdatedOrder.mutate({}, {
          onSuccess: (values: any) => {
            SingleOrderCreate('shippingCharges')
            UserCreatedDraftOrder('single')
            singleOrderContext.updateSingleOrderData({
              "id": values.order.id,
              "amount": values.rate.total,
              "currency": values.rate.currency,
              "amount_to_pay": values.rate.amount_to_pay,
              "payment_status": values.order.payment_status,
              "gst_amount": values.rate.gst_amount,
              "total_duties": values.rate.total_duties,
              "total_taxes": values.rate.total_taxes,
              "total": values.rate.total,
              "is_tax_payable": values.rate.is_tax_payable,
              "charge_id": values.rate.charge_id,
              "account_charges": values.account_charges.length > 0 ? values.account_charges : []
            })
            if(dataFilled === 0) {
              routeContext.setShouldBlockNavigation(false);
            }
            setCurrentState(3);
          },
          onError: (error: any) => {
            updateError(error.response.data.error)
          }
        });
      },
      onCancel() {},
    });
  }
  const draftOrderId = singleOrderContext.singleOrder && singleOrderContext.singleOrder.order_id;
  const renderConvertSuccessModal = props.isMarketplaceOrder &&
    draftOrderId && (
    <Modal
      width={650}
      visible={showModal}
      closable={true}
      // onCancel={() => closeModal(draftOrderId)}
      onCancel={() =>  history.push(`/orders/pay/shopify/single/${draftOrderId}`)}
      className="shopify-success"
      footer={[
        <Space>
          <Button type="primary" key="1" href={`/orders/integrated-shipments`}>
            {getText("Update other shipments")}
          </Button>
          <Button type="primary" key="2" href={`/orders/pay/shopify/single/${draftOrderId}`}>
            {getText("Proceed to summary")}
          </Button>
        </Space>,
      ]}
    >
      <div className="convert-success-msg">
        <p style={{fontWeight:'bold'}}>
          {getText("Update successfully")}
        </p>
        <p>
          {getText(
            "You have successfully updated the missing information. What would like to do next?"
          )}
          .
        </p>
      </div>
    </Modal>
  );

  const isCodOptions = [
    { key: 'Yes', value: 'true'},
    { key: 'No', value: 'false' }
  ]

  const disableCustomTrackingNumberField = () => {
    if(singleOrderContext.singleOrder.payment_status === 'PAID' || singleOrderContext.singleOrder.payment_status ===  'ADDITIONAL_PAYMENT_REQUIRED' || singleOrderContext.singleOrder.payment_status === 'MONTHLY_BILLING_PAYMENT_PENDING') {
      return true
    }
    return false
  }

  const incotermRadioOptions = [
    { key: 'DDP (You will pay for customs, duties and taxes)', value: 'DDP'},
    { key: 'DDU (Customer will pay for customs, duties and taxes)', value: 'DDU'}
  ]

  const incotermRadioOptionsOnlyDduOption = [
    { key: 'DDU (Customer will pay for customs, duties and taxes)', value: 'DDU'}
  ]
  return (
    <>
      {renderConvertSuccessModal}
      {current < 3 && <div ref={r => {ItemDetailsFormRef.current = ItemDetailsFormRef.current ? ItemDetailsFormRef.current : r; }}>
        <Formik
          innerRef={ItemDetailsFormRef}
          validationSchema={validationSchema(getText, auth.user.payment_type)}
          initialValues={initialValues}
          initialTouched={initialTouched}
          onSubmit={submitForm}
          validateOnChange={true}
          isInitialValid={editOrderStatus()}
          initialErrors={initialErrors}>
          { formik => (
            <div className={"single-order-form-container ant-form-vertical"}>
              <Form >
                <Alert
                  message={<p style={{marginBottom:0}}>{getText('Please take note that you cannot ship')} <a href={`https://docs.google.com/spreadsheets/d/1SWRmJPRDXLcS2h6skwaLF_jJTcVTnkcUxP7QK6qCUX8/edit#gid=${GoodsListSheetIds[singleOrderContext.singleOrder.consignee_country_code]}`} style={{fontWeight: "bold",color: '#050593',
                    textDecoration: 'underline'}} target={"_blank"} rel="noreferrer">{getText('these')}</a> {getText('prohibited and restricted goods to')} <strong>{countries[singleOrderContext.singleOrder.consignee_country_code]}</strong>.</p>}
                  type="info"
                  showIcon
                  style={{marginBottom:24}}
                />
                <FieldArray name="items"
                  render={arrayHelpers => (
                    <>
                      {formik.values.items.map((item, index) => (
                        <div key={index}>
                          <F.Item label={<TitleErrorMarker text={`${getText('Item')} ${index+1}`} hasError={hasError} />} className={"single-order-form-title"}></F.Item>
                          <ItemForm isDomestic={isDomestic} index={index} remove={() => arrayHelpers.remove(index)} contextData={singleOrderContext} />
                          {index > 0 && <Divider/>}
                        </div>
                      ))}

                      <F.Item>
                        <Button className={'outline-btn'} onClick={() => arrayHelpers.push(empty_item)} disabled={((!formik.isValid && formik.errors.hasOwnProperty("items"))) ? true: false}>
                          {getText('Add another item')}
                        </Button>
                      </F.Item>
                    </>
                  )} />

                <Divider/>
                <F.Item label={getText('Parcel Details')} className={"single-order-form-title"}>
                </F.Item>
                <F.Item label={getText('Make sure weight and dimensions of your box/package are correctly declared else shipment will be put on hold till repayment is made or it will be disposed.')} className={"form-label-info"}>
                </F.Item>

                <Row gutter={14}>
                  <Col span={7}>
                    <FormInput name="order_weight" required label={getText('Weight (kg)')} inputProps={{type: 'number', min: 0.1, step:0.01}}/>
                  </Col>
                  <Col span={5}>
                    <FormInput name="order_length" required label={getText('Length (cm)')} inputProps={{type: 'number', min: 1, step:0.01}}/>
                  </Col>
                  <Col span={1} style={{display: 'flex', justifyContent: 'center'}}>
                    <div className={"cross-mark"}>x</div>
                  </Col>
                  <Col span={5}>
                    <FormInput name="order_width" required label={getText('Width (cm)')} inputProps={{type: 'number', min: 1, step:0.01}}/>
                  </Col>
                  <Col span={1} style={{display: 'flex', justifyContent: 'center'}}>
                    <div className={"cross-mark"}>x</div>
                  </Col>
                  <Col span={5}>
                    <FormInput name="order_height" required label={getText('Height (cm)')} inputProps={{type: 'number', min: 1, step:0.01}}/>
                  </Col>
                </Row>

                {getShipperProfile.data?.allow_custom_tracking_no &&
                  <Row gutter={14}>
                    <Col span={12}>
                      <FormInput name="custom_tn" label={'Custom Tracking Number (optional)'} inputProps={{disabled: disableCustomTrackingNumberField()}} />
                    </Col>      
                  </Row>
                }

                {
                  fetchExtraOrderDetails.isLoading ? 
                    <div style={{minHeight: '100px'}}><Spin style={{marginTop: '30px'}} /></div> :
                    <>
                      {
                        (codDduResponse && codDduResponse[0]?.is_cod_enabled) &&
                        <Row>
                          <Col span={24} style={{marginTop: '10px'}}>
                            <RadioGroup
                              label={'Is this a Cash On Delivery (COD)?'}
                              name={'isCod'}
                              options={isCodOptions}
                              visualisation={'buttons'}
                              disabled={false}
                            />
                          
                            {countryCurrencies.map(item => {
                              return <Option key={item} value={item}>{item}</Option>
                            })}
                          </Col>
                        
                          {
                            formik.values.isCod === 'true' &&
                          <>
                            <Col span={24} style={{marginTop: '20px'}}>Cash to collect from consignee</Col>
                            <Col span={24}>
                              <Row>
                                <Col span={4}>
                                  <FormSelect name={'cod_currency'} label={''} showSearch={"true"} disabled={true} defaultValue={consigneeCurrency}>
                                    {countryCurrencies.map(item => (
                                      <Option key={item} value={item}>{item}</Option>
                                    ))}
                                  </FormSelect>
                                </Col>
                                <Col span={6}>
                                  <FormInput name={'cod_amount'} label={''} inputProps={{type: 'number', min: 0}}/>
                                </Col>
                              </Row>
                            </Col>
                          </>
                          }
                        </Row>
                      }

                      {
                        ((codDduResponse && codDduResponse[0]?.is_ddu_enabled) || isOnlyDduAvailable()) &&
                      <Row>
                        <Col span={24} style={formik.values.isCod === 'true' ? {marginTop: '0px'} : {marginTop: '30px'}}>
                          <RadioGroup
                            label={'Incoterm'}
                            name={'incoterm'}
                            options={isOnlyDduAvailable() ? incotermRadioOptionsOnlyDduOption : incotermRadioOptions}
                            visualisation='dots'
                            disabled={false}
                          />
                        </Col>
                      </Row>
                      }
                    </>
                }
                
                {current === 2 && (
                  <div className={"button-block"}>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className={"blue-button"}
                      loading={submitOrder.isLoading || submiteUpdatedOrder.isLoading || getCalculatedAmount.isLoading} >
                      {props.isMarketplaceOrder ? getText('Save & Ship with Janio') : getText('Save & Continue')}
                    </Button>
                  </div>
                )}
              </Form>
            </div>
          )}
        </Formik>
      </div>}
      <>
        {((orderData && current > 2) || dataFilled === 3) && <Card title={<TitleErrorMarker text={getText('Item & Parcel Overview')} hasError={hasError} />} className={"form-summary-container"}>
          {initialValues.items && orderData?.items.map((order,index) => {
            return <p key={index} style={{margin: 0}}> {`${index+1}. ${order.item_desc}`}</p>
          })}
          <p className={'gray-text'}>{initialValues.order_weight} kg,{initialValues.order_length}x{initialValues.order_width}x{initialValues.order_height} cm</p>
        </Card>}
      </>
    </>
  )
};

export const ItemDetailsFormTitle = (props:PropsType) =>{
  let { getText } = useLanguage();
  const {current, setCurrentState} = props;
  return (
    <div className={"title-block"}>
      <p className={'step-title'}>{getText('What are you delivering?')}</p>
      {current>2 ? <p onClick={()=> setCurrentState(2)} className={"edit-text"}>{getText('Edit details')}</p>:''}
    </div>
  )
};