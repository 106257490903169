import {
  Card,
  Row,
  Col,
  Breadcrumb
} from 'antd'
import {Link} from "react-router-dom";
import {UserNotificationsForm} from "../components/UserNotificationsForm";
import { useLanguage } from '../../languages/Language';

export const Notifications = () =>  {
  let { getText } = useLanguage();
  return (
    <>
      <Breadcrumb className="bread-crumbs">
        <Breadcrumb.Item>
          <Link to={`/settings`} >
            <span style={{border: 'none',background: 'none'}} >
              {getText('Settings')}
            </span>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item><strong>{'Notifications'}</strong></Breadcrumb.Item>
      </Breadcrumb>
      
      <Row align="middle" justify="center" style={{marginTop: 50}}>
        <Col span={16} className={'auth-form-container'}>
          <Card>
            <UserNotificationsForm />
          </Card>
        </Col>
      </Row>
    </>
  )
};
