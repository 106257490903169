import { Button, Col, Row, Statistic, Table, Tag, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import DownloadModal from './DownloadModal';
import Filters from './Filters';
import Invoice from '../interfaces/Invoice';
import { calculateOutstandingBalance } from '../utils/generating';
import { parseAmount, parseBalance, parseCreditedAmountCreditNote, parseCreditedAmountInvoice, parseInvoiceAmount, parseOverdue } from '../utils/parsing';
import { formatDateOnly } from '../../commons/utils/utilizer';

interface Props {
  data: Invoice[],
  currency: string,
  currencies: string[]
};

const { Text } = Typography;

const BalanceTab = (props: Props) => {
  const { data, currency: defaultCurrency, currencies } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const [invoice, setInvoice] = useState<Invoice>();
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [currency, setCurrency] = useState<string>(defaultCurrency);
  const [numberInvoiceNotDue, setNumberInvoiceNotDue] = useState<number>(0);
  const [numberInvoiceOverdue, setNumberInvoiceOverdue] = useState<number>(0);
  const [amountNotDue, setAmountNotDue] = useState<string>('0.00');
  const [amountOverdue, setAmountOverdue] = useState<string>('0.00');
  const [overstandingBalance, setOverstandingBalance] = useState<string>('0.00');

  const columns = [
    {
      title: 'Invoice Date',
      dataIndex: 'date',
      key: 'date',
      width: 130,
      render: (date: string) => formatDateOnly(date) || '-'
    },
    {
      title: 'Invoice Number',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      width: 160,
      render: (invoiceNumber: string) => <Text strong>{invoiceNumber}</Text>
    },
    {
      title: 'Number of Days Overdue',
      dataIndex: 'dueDate',
      key: 'overdue',
      width: 190,
      align: 'center',
      render: (dueDate: string | undefined) => dueDate ? handleOverdue(dueDate) : ''
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      width: 130,
      render: (dueDate: string | undefined) => dueDate ? formatDateOnly(dueDate) : ''
    },
    {
      title: 'Currency',
      dataIndex: 'currencyCode',
      key: 'currencyCode',
      width: 130,
    },
    {
      title: 'Invoice Amount',
      dataIndex: 'total',
      key: 'total',
      width: 130,
      render: (total: number, data: Invoice) => <Text strong>{parseInvoiceAmount(total, data.type, data.currencyCode)}</Text>
    },
    {
      title: 'Credited Amount',
      dataIndex: 'amountCredited',
      key: 'amountCredited',
      width: 140,
      render: (amountCredited: number | undefined, data: Invoice) => data.type === 'INVOICE'
        ? parseCreditedAmountInvoice(amountCredited || 0, data.amountPaid || 0, data.currencyCode)
        : parseCreditedAmountCreditNote(data.total, data.remainingCredit || 0, data.currencyCode)
    },
    {
      title: 'Balance',
      dataIndex: 'amountDue',
      key: 'amountDue',
      width: 130,
      render: (amountDue: number | undefined, data: Invoice) => parseBalance(amountDue || 0, data.remainingCredit || 0, data.type, data.currencyCode)
    },
    {
      title: 'Invoice & Document',
      dataIndex: 'hasAttachments',
      key: 'hasAttachments',
      width: 160,
      render: (hasAttachments: boolean, data: Invoice) => (
        !hasAttachments && data.isOverpayment
          ? <Tooltip title="Attachment Not Available"><Button type="primary" disabled>Download</Button></Tooltip>
          : <Button type="primary" onClick={() => handleDownload(data)}>Download</Button>
      )
    },
  ];

  const handleOverdue = (dueDate: string) => {
    const overdue = parseOverdue(dueDate);
    if (overdue > 0) {
      return (<Tag style={{ fontSize: '14px', borderRadius: '10px' }} color="orange">Overdue {overdue} days</Tag>);
    }

    if (overdue === 0) {
      return (<Tag style={{ fontSize: '14px', borderRadius: '10px' }} color="blue">Overdue 0 day</Tag>);
    }

    return '';
  };

  const handleDownload = (invoice: Invoice) => {
    setInvoice(invoice);
    setVisible(true);
  };

  useEffect(() => {
    if (!defaultCurrency) {
      return;
    }

    setCurrency(defaultCurrency);
  }, [defaultCurrency]);


  useEffect(() => {
    if (!data.length || !currency) {
      return;
    }

    const currencyData = data.filter(({ currencyCode }) => currencyCode === currency);
    const invoices = currencyData.filter(({ type }) => type === 'INVOICE');

    let numberInvoiceNotDue = 0;
    let numberInvoiceOverdue = 0;
    let amountNotDue = 0;
    let amountOverdue = 0;

    for (const invoice of invoices) {
      if (parseOverdue(invoice.dueDate || '') > 0) {
        numberInvoiceOverdue++;
        amountOverdue += invoice.amountDue || 0;
      } else {
        numberInvoiceNotDue++;
        amountNotDue += invoice.amountDue || 0;
      }
    }

    setNumberInvoiceNotDue(numberInvoiceNotDue);
    setNumberInvoiceOverdue(numberInvoiceOverdue);
    setAmountNotDue(parseAmount(amountNotDue, currency));
    setAmountOverdue(parseAmount(amountOverdue, currency));
    setOverstandingBalance(calculateOutstandingBalance(currencyData, currency));
    setInvoices(currencyData);
  }, [data, currency]);

  return (
    <Col span={24}>
      { invoice && <DownloadModal tab='Balance' invoice={invoice} visible={visible} setVisible={setVisible} /> }
      <Col span={24}>
        <Row gutter={[24, 16]}>
          <Col span={4}><Statistic title="Number of Invoice (Current)" value={numberInvoiceNotDue} /></Col>
          <Col span={4}><Statistic title="Number of Invoice (Overdue)" value={numberInvoiceOverdue} /></Col>
          <Col span={4}><Statistic title="Amount (Current)" value={amountNotDue} /></Col>
          <Col span={4}><Statistic title="Amount (Overdue)" value={amountOverdue} /></Col>
          <Col span={4}><Statistic title="Outstanding Balance" value={overstandingBalance} valueStyle={{ color: overstandingBalance === '0.00' ? '#3f8600' : '#cf1322' }} /></Col>
          <Col span={4}><Filters currency={currency} currencies={currencies} setCurrency={setCurrency} /></Col>
        </Row>
      </Col>
      <Col span={24}>
        <Table
          dataSource={invoices}
          // @ts-ignore
          columns={columns}
          scroll={{ x: 1200 }}
        />
      </Col>
    </Col>
  )
};

export default BalanceTab;