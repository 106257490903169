import React, { useEffect, useState } from 'react'
import {Row, Col, Card, Steps} from 'antd';
import {CaretLeftOutlined} from '@ant-design/icons'
import { DisputeType, DisputeTypeTitle } from './Components/DisputeType';
import { AddSupportingDocsTitle, AddSupportingDocs } from './Components/AddSupportingDocs';
import { DisputeContextProvider } from './disputeContext';
import { Link } from 'react-router-dom';
// import {ClaimTypeSelectionTitle, ClaimsTypeSelection} from './Component/ClaimsTypeSelection';
// import { ClaimFormTitle, ClaimsForm } from './Component/ClaimsForm';
// import { ClaimSubTypeSelectionTitle, ClaimsSubTypeSelection } from './Component/ClaimsSubTypeSelection';

const {Step} = Steps;
const DisputesPage = () => {
  const [current, setCurrent] = useState(0)
  const [progress, setProgress] = useState(0)
  
  // const [claimType, setClaimType] = useState<string>('')
  // const [missingOrderType, setMissingOrderType] = useState<string>('')
  // const [damagedOrderType, setDamagedOrderType] = useState<string>('')

  // const location = useLocation();
  // const {tracking_no, order_id}: any = location.state

  const steps = [
    {
      title: 
        <DisputeTypeTitle 
          current={current} 
          setCurrent={setCurrent}
        />,
      content: 
        <DisputeType 
          // claimType={claimType}
          // setClaimType={setClaimType} 
          current={current}
          setCurrent={setCurrent} 
          // setMissingOrderType={setMissingOrderType} 
          // setDamagedOrderType={setDamagedOrderType} 
        />
    },
    {
      title: 
        <AddSupportingDocsTitle />,
      content: 
        <AddSupportingDocs
          // missingOrderType={missingOrderType} 
          // setMissingOrderType={setMissingOrderType} 
          // damagedOrderType={damagedOrderType} 
          // setDamagedOrderType={setDamagedOrderType} 
          // current={current} 
          // setCurrent={setCurrent} 
          // claimType={claimType}
        />
    }
    // ,
    // {
    //   title: <ClaimFormTitle/>,
    //   content: <ClaimsForm 
    //     claimType={claimType} 
    //     claimSubType={missingOrderType? missingOrderType : damagedOrderType? damagedOrderType : 'weight_dispute' }
    //     tracking_no={tracking_no} 
    //     order_id={order_id}/>
    // }
  ]

  
  useEffect(() => {
    if (current > progress) {
      setProgress(current);
    }
  }, [current, progress]);

  return (
    <>
      <Steps direction='vertical' current={current}>
        {
          steps.map((item, index) =>( 
            <Step 
              key={index} 
              title={item.title} 
              description={(current >= index || progress >= index)? item.content: ''} 
            /> 
          ))
        }
      </Steps>
    </>
  )
}


const DisputesPageWrapper = () => {
  return (
    <Row align='middle' justify='center'>
      <Col span={24} className={'claim-container'}>
        <Link to={'/claims'}><p><CaretLeftOutlined/>Disputes</p></Link>
        <Card className='claim-card'>
          <DisputeContextProvider>
            <DisputesPage/>
          </DisputeContextProvider>
        </Card>
      </Col>
    </Row>
  )
}

export default DisputesPageWrapper