import moment from 'moment';

/**
 * Get cache data in local storage
 * @param {string} key Key item in local storage
 * @returns
 */
export const getCacheData = (key: string) => {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = moment().valueOf();

  if (now > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value || item;
};

/**
 * Store cache data in local storage till end of day by default
 * @param {string} key Key item of the value
 * @param {any} value Data to store in local storage
 * @param {number} duration Expiration duration in minute (optional)
 */
export const storeCacheData = (key: string, value: any, duration?: number) => {
  const item = {
    value,
    expiry:  duration ? moment().add(duration * 60000).valueOf() : moment().endOf('day').valueOf()
  };

  localStorage.setItem(key, JSON.stringify(item));
};
