import {Button,Modal,message,Space} from 'antd';
import {useState} from "react";
import {useMutation} from "react-query";
import {downloadBulkOrderPODs } from '../services';
import { useLanguage } from '../../languages/Language';
import { InfoCircleOutlined } from '@ant-design/icons';
import {OrderDetailData_from_b2c} from '../services';
import { useAuth } from '../../auth/services/authContext';
import { UserClickedePODDOwnload } from '../../commons/mixpanel';

interface Props {
  selectedOrders: Array<OrderDetailData_from_b2c>
  isDisplayingInBulk?: boolean
}

interface PodResponseData {
  pod_key: string
  pod_url_s3: string
}

const PrintPodHeader = (props:Props) => {
  let { getText } = useLanguage();
  let auth = useAuth()
  const [showModal, setShowModal] = useState<boolean>(false);
  
  const closeModal = () => {
    setShowModal(false)
  };
  let trackningsNos = props.selectedOrders.filter((item: OrderDetailData_from_b2c) => item.epod_url).map((order:OrderDetailData_from_b2c) => order.tracking_no)

  let invalidLabelOrderIds = props.selectedOrders.filter((value:OrderDetailData_from_b2c) => value.epod_url == null).map((record:OrderDetailData_from_b2c) => record.tracking_no)

  const getPDFData = useMutation(async () => {
    // if(auth.user.authToken === 'e338b185c4018ee60db449dddd0286b4f80df078') {
    //   return getBulkOrderLabelPDF(orderIds,auth.user.authToken)
    // }
    return downloadBulkOrderPODs(trackningsNos, auth.user.secret_key)
  }, {
    onSuccess: (response:any) => {
      setShowModal(false)
      try{
        generatePDF(response)
        // setShowModal(false)
      }
      catch(error){
        setShowModal(false)
        message.error(
          <span style={{display: 'block'}}>We apologize for the inconvenience. Please try again 10 mins later. If the problem persists, contact us at {<a href={'mailto:clientsupport@janio.asia'} style={{color: '#050593'}}>clientsupport@janio.asia</a>} to report the error and get assistance in resolving the issue.</span>
        )
      }
    },
    onError: (error: any) => {
      setShowModal(false)
      if(error.response?.data!){
        message.error(
          <span style={{fontSize: '15px'}}>{JSON.stringify(error.response?.data)}</span>
        )
      }
      else{
        message.error(
          <>
            <span style={{display: 'block'}}>We apologize for the inconvenience. Please try again 10 mins later. If the problem persists, contact us at {<a href={'mailto:clientsupport@janio.asia'} style={{color: '#050593'}}>clientsupport@janio.asia</a>} to report the error and get assistance in resolving the issue.</span>
          </>
          , 15
        )
      }
    }
  })

  const onClickPrintLabels = () => {
    getPDFData.mutate();
    setShowModal(false)
  }

  const generatePDF = (data:PodResponseData) => {
    window.open(data.pod_url_s3)
  }

  const PrintLabelModal = () =>{
    return(
      <>
        <Modal title={`Download ${props.selectedOrders.length} PODs`}
          width={700}
          visible={showModal}
          onCancel={closeModal}
          className={'modal-right-footer'}
          footer={[
            <Button onClick={closeModal}>{getText("Cancel")}</Button>,
            <Button type="primary" onClick={onClickPrintLabels} loading={getPDFData.isLoading}>{"Download POD"}</Button>
          ]}>
          <p style={{marginBottom: 0}}>{"Do you want to download the selected shipment PODs?"}</p>
        </Modal>
      </>

    )
  };
  
  const LabelPrintFailedModal = () => {
    return Modal.confirm({
      // title: `Shipment label cannot be printed for below`,
      icon: null,
      width: 700,
      okText: trackningsNos.length > 0 ? "Continue" : "Ok",
      cancelText: "Cancel",
      cancelButtonProps: {style: {display : trackningsNos.length > 0 ? 'inlineBlock' : 'none'}},
      // okButtonProps: {loading: getPDFData.isLoading},
      content: (
        <>
          <div style={{marginTop: 30}}>
            {
              invalidLabelOrderIds.length > 0 &&
              <div style={{border: '1px solid #eeeaea', padding: '0 10px', marginTop: '-40px'}}>
                {trackningsNos.length > 0 ?
                  <h3 style={{fontSize: '16px'}}><InfoCircleOutlined style={{color:'#00BFFF', marginRight: '5px'}}/>All PODs will be downloaded, except for the below Tracking Number (TN):</h3> 
                  : 
                  <h3 style={{fontSize: '16px'}}><InfoCircleOutlined style={{color:'#00BFFF', marginRight: '5px'}}/>PODs cannot be downloaded for below tracking numbers because either the order is not Delivered or the PODs hasn't been generated by our system yet.</h3>
                }
                {
                  invalidLabelOrderIds.map((id:any) =>
                  {
                    return <p style={{fontSize: '14px', marginBottom: 0}}>- {id}</p>
                  }
                  )
                }
                <Space/>
                {trackningsNos.length > 0 ?
                  <div style={{marginTop: 25}}>
                    <p>To resolve the issue, please contact us at {<a href={'mailto:clientsupport@janio.asia'} style={{color: '#050593'}}>clientsupport@janio.asia</a>} and provide the affected TN along with the reason for the error. Our Merchant Support team will promptly assist you in obtaining the missing POD and addressing any concerns.</p>
                  </div>
                  :
                  <div style={{marginTop: 25}}>
                    <p>Please try again 10 mins later. If the issue persists, contact us at {<a href={'mailto:clientsupport@janio.asia'} style={{color: '#050593'}}>clientsupport@janio.asia</a>}  for updates on the availability of the PODs.</p>
                    
                  </div>
                }
              </div>
            }
          </div>
        </>
      ),
      onOk: trackningsNos.length > 0 ? onClickPrintLabels : () => {},
      onCancel() {}
    });
  }

  const MaximumLimitModal = () => {
    Modal.info({
      title: <h3 style={{marginLeft: '12px', fontSize: '15px', fontWeight: 'bold'}}>Exceeded Maximum Number of Files</h3>,
      icon: null,
      width: 700,
      content: (
        <>
          <div style={{marginTop: 10}}>
            {
              props.selectedOrders.length > 0 &&
              <div style={{border: '1px solid #eeeaea', padding: '0 10px', marginTop: '-55px'}}>
                <div style={{marginTop: 45}}>
                  <p>Please download the PODs in batches of 10 files or less to avoid exceeding the limit. If you require further assistance, contact us at {<a href={'mailto:clientsupport@janio.asia'} style={{color: '#050593'}}>clientsupport@janio.asia</a>} for immediate assistance and resolution.</p>
                  
                </div>
              </div>
            }
          </div>
        </>
      ),
      onOk() {},
    },
    );
  }

  const onClick = (tab: string) => {
    UserClickedePODDOwnload('Bulk', tab)
    if(props.selectedOrders.length > 10) {
      return MaximumLimitModal()
    }
    if(invalidLabelOrderIds.length > 0) {
      return LabelPrintFailedModal()
    }
    else{
      setShowModal(true);
    }
  }
  
  if(props.isDisplayingInBulk && props.selectedOrders.length >0) {
    return (
      <>
        <div style={{background: 'rgb(255, 255, 255)', textAlign: 'center', width: '124px', marginLeft: '15px'}}>
          <Button className="button-label" type="text" onClick={() => onClick('All') } style={{color: '#050593', fontWeight: 'bold'}}>{"Download POD"}</Button>
          {showModal && <PrintLabelModal />}
        </div>
      </>
    )
  }
  return (
    <>
      {(props.selectedOrders.length >0) ?
        <div className="main-print-container" style={{padding: '0 22px'}}>
          <div className="print-content" style={{height: '60px', justifyContent: 'unset'}}>
            <div>
              {props.selectedOrders.length} {getText("Shipments selected")}
            </div>
            <div style={{color: '#050593', background: '#fff', marginLeft: '35px', fontWeight: 600, minWidth: '125px', textAlign: 'center'}}>
              <Button className="button-label" type="text" onClick={() => onClick('Delivered')} style={{color: '#050593', fontWeight: 'bold'}}>{"Download POD"}</Button>
              {showModal && <PrintLabelModal/>}
            </div>
          </div>
          <div className="arrow-down" style={{marginLeft: '8px'}}></div>
        </div>
        : <></>}
    </>
  )
};

export default PrintPodHeader
