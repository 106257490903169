import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Card, Row, Col, Button, Table, Tabs, Modal, Image} from "antd";
import { useQuery } from "react-query";
import { Tip3Viewed } from '../commons/mixpanel'
import { useLanguage } from "../languages/Language";
import { getDateInReadableFormat } from "../commons/utils/utilizer";
import { getApiIntegrationHistoryb2c } from "../orders/services/privateApi";
import { useAuth } from "../auth/services/authContext";
import {Tips} from "../commons/components/Tips/Tips";
import "./styles/IntegrationsList.page.css";
import shopify_logo from "../assets/images/shopify_logo.svg";
import magento_logo from "../assets/images/magento_logo.svg";
import woocommerce_logo from "../assets/images/woocommerce_logo.svg";

const { TabPane } = Tabs;

export const IntegrationsListPage = () => {
  
  const defaultParam = window.location.href.includes('apiintegration') ? 'apiintegration' : 'stores'
  const auth = useAuth();
  let { getText } = useLanguage();
  const location:any = useLocation()
  const history = useHistory()
  let prevPathName= location.state?.from;
  const [activeKey, setActiveKey] = useState(defaultParam)
  const [limitStores, setLimitStores] = useState(1);
  const [offsetStores, setOffsetStores] = useState(10);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showTip, setShowTip] = useState((prevPathName==="/orders/create" || prevPathName==="/orders")?true:false);
  const secretKey = auth.user.secret_key
  const setShowTipCallback = (val:boolean) => {
    setShowTip(val);
  }
  const onTipNextButtonClick = () => {
    history.push({
      pathname: '/orders',
      state: {
        from: location.pathname
      }
    })
  }
  const onTipPrevButtonClick = () => {
    history.push({
      pathname: "/orders/create",
      state: {
        from: location.pathname
      }
    })
  }
  const storeIntegrationHistoryQuery = useQuery(
    ["getStoreIntegrationHistory"],
    async () => {
      return getApiIntegrationHistoryb2c(secretKey, {
        page: limitStores,
        page_size: offsetStores,
      });
    }, {cacheTime: 0}
  );

  // const storeIntegrationHistoryQuery = useQuery(
  //   ["getStoreIntegrationHistory", limitStores, offsetStores],
  //   async () => {
  //     return getIntegrationHistory(authToken, {
  //       page: limitStores,
  //       page_size: offsetStores,
  //     });
  //   }, {cacheTime: 0}
  // );

  // const deleteIntegrationStore = useMutation(async (uuid: string) => {return  await deleteStore(auth.user.authToken, uuid)},
  //   {
  //     onSuccess: (val) => {
  //       message.success('Deleted the store successfully');
  //       storeIntegrationHistoryQuery.refetch();

  //     },
  //     onError: (val: any) => {
  //       if(val.response.data.error){
  //         const errors = val.response.data.error;
  //         let errorMessage: string='';
  //         errors.forEach((error: string) => {
  //           errorMessage += error + '. '
  //         });
  //         message.error(errorMessage)
  //       }else{
  //         message.error('Failed to delete the store, please try later');
  //       }       
  //     },
  //   });

  // const editIntegrationStore = useMutation(async (uuid: string) => {return  await editStore(auth.user.authToken, uuid, storeKey)},
  //   {
  //     onSuccess: (val) => {
  //       message.success('Store updated successfully');
  //       setEditModal(false);
  //       storeIntegrationHistoryQuery.refetch();
  //     },
  //     onError: (val: any) => {
  //       if(val.response.data.error){
  //         const errors = val.response.data.error
  //         let errorMessage = '';
  //         errors.forEach((error: string) => {
  //           errorMessage += error + '. '
  //         });
  //         message.error(errorMessage)
  //       }else{
  //         message.error('Failed to update the store, please try later');
  //       }       
  //     },
  //   });

  // const deleteConfirm = (uuid: string, type: string, name: string) => {
  //   confirm({
  //     title: `Do you Want to delete ${name} ${type} store?`,
  //     okText: 'Yes',
  //     onOk() {
  //       deleteIntegrationStore.mutate(uuid)
  //     },
  //     onCancel() {},
  //   });
  // }; 
  
  const callback = (key: string) => {
    setActiveKey(key)
    
    const queryParams = location.search.includes('?active')

    if (queryParams) {
      history.replace({
        search: '',
      })
    }

    if(key === 'stores') {
      setLimitStores(1)
      setOffsetStores(10)
    }
  }

  const columns = [
    {
      title: "Platform",
      dataIndex: "channel_type",
      key: "channel_type",
      render: (channel_type: string) => {
        return (
          <>
            <span>{channel_type.charAt(0).toUpperCase() + channel_type.slice(1)}</span>
          </>
        );
      },
    },
    {
      title: "Shop Name",
      dataIndex: "shop_name",
      key: "shop_name",
    },
    {
      title: "Shop Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Added On",
      dataIndex: "created_on",
      key: "created_on",
      render: (created_on: string) => {
        return getDateInReadableFormat(created_on);
      },
    },
    // {
    //   title: 'Action',
    //   key: 'action',
    //   render: (_, record) => (
    //     (record.type === 'MAGENTO' || record.type=== 'WOOCOMMERCE') && 
    //     <>
    //       <Button onClick={() =>{setStoreData(record); setEditModal(true)}} type="link" style={{right: 15}}>Edit</Button>
    //       <Button onClick={() => deleteConfirm(record.uuid, record.type, record.name)} type="link" style={{right: 15}}>Delete</Button>
    //     </>
    //   ),
    // },
  ];

  const onSwitchPage = (page: any, pageSize: any) => {
    if(activeKey === 'stores') {
      setLimitStores(page);
      setOffsetStores((page - 1) * pageSize);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // const showModal = () => {
  //   setIsModalVisible(true);
  // };

  return (
    <>
      <h1 className="ant-page-header-heading-title page-title">
        {getText("Integrations")}
      </h1>
      <div style={{position: 'relative'}}>
        <Row justify="end" gutter={8} style={{position: 'absolute', right: 0, bottom: 0}}>
          <Col style={{zIndex: 1}}>
            <a href={"https://janio-asia.notion.site/Shopify-Store-Integration-with-Janio-Setup-Guide-cbf1549538b34bb684f9111c4f53094d"} style={{display: 'block', background: '#fff', border: '1px solid #050593', color: '#050593', height: '32px', width: '135px', textAlign: 'center', lineHeight: '29px'}} target="_blank" rel="noreferrer">View Tutorials</a>
            {/* <Button
              type="primary"
              onClick={showModal}
              className="outline-btn add-integration-btn"
            >
              {"View Tutorials"}
            </Button> */}
          </Col>
          <Col style={{zIndex: 1}}>
            <Tips visible={showTip} step={'3'} placement={'bottomRight'} hidePreviousText={false} onPrevClick={onTipPrevButtonClick} onNextClick={onTipNextButtonClick} onExitTour={setShowTipCallback} contentMessage={"Save time and skip the manual input of details by integrating your store or adding api integration with Janio Asia"} mixpanelEvent={Tip3Viewed}>
              <Link to={"/integrations/add"}>
                <Button
                  type="primary"
                  onClick={() => false}
                  className="add-integration-btn"
                >
                  Add Integrations
                </Button>
              </Link>
            </Tips>
          </Col>
        </Row>
      </div>
      <Row gutter={16}>
        <Col span={24}>
          <Card>
            <Tabs defaultActiveKey={defaultParam} onChange={callback} className="tabs-wrapper">
              <TabPane tab="Stores Integration" key="stores">
                <Table
                  rowClassName="table_body"
                  rowKey="id"
                  dataSource={
                    storeIntegrationHistoryQuery.data
                      ? storeIntegrationHistoryQuery.data.results
                      : []
                  }
                  columns={columns}
                  loading={storeIntegrationHistoryQuery.isLoading}
                  pagination={{
                    onChange: onSwitchPage,
                    total: storeIntegrationHistoryQuery.data
                      ? storeIntegrationHistoryQuery.data.count
                      : 0,
                    pageSize: 10,
                    hideOnSinglePage: true
                  }}
                />
              </TabPane>
            </Tabs>
          </Card>
          <Modal 
            title={'View Store Integration Tutorial'} 
            visible={isModalVisible} 
            onCancel={handleCancel} 
            footer={null} 
            width={650} 
            centered>
            <Col span={24} className={"form-container"}>
              <Card className="user-setting-card-wrapper" style={{background: '#fff', border: 'none'}}>
                <Row gutter={8}>
                  <Col span={8}>
                    <a href={"https://janio-asia.notion.site/Shopify-Store-Integration-with-Janio-Setup-Guide-cbf1549538b34bb684f9111c4f53094d"} style={{display: 'block'}} target="_blank" rel="noreferrer">
                      <Card
                        hoverable
                        className={"option-card user-setting-card-container shopify_logo-container"}
                        style={{border: '1px solid rgb(248 239 239)', boxShadow: 'none'}}
                        cover={
                          <Image
                            preview={false}
                            src={shopify_logo}
                            className={"shopify_logo"}
                            style={{width: '110px', marginTop: '24px'}}
                          />
                        }
                      >
                        <Card.Meta title="Shopify" className={"custom-card-meta"} />
                      </Card>
                    </a>
                  </Col>
                  <Col span={8}>
                    <a href={"https://janio-asia.notion.site/Magento-Store-Integration-with-Janio-Setup-Guide-295f0cc7c83c46ae9c932a36f98c0586"} style={{display: 'block'}} target="_blank" rel="noreferrer">
                      <Card
                        hoverable
                        className={"option-card user-setting-card-container shopify_logo-container"}
                        style={{border: '1px solid rgb(248 239 239)', boxShadow: 'none'}}
                        cover={
                          <Image
                            preview={false}
                            src={magento_logo}
                            className={"magento_logo"}
                            style={{width: '50px', marginTop: '8px'}}
                          />
                        }
                      >
                        <Card.Meta title="Magento" className={"custom-card-meta"} />
                      </Card>
                    </a>
                  </Col>
                  <Col span={8}>
                    <a href={"https://janio-asia.notion.site/WooCommerce-Store-Integration-with-Janio-Setup-Guide-33ec732275b547719ef965290f98bf2d"} style={{display: 'block'}} target="_blank" rel="noreferrer">
                      <Card
                        hoverable
                        className={"option-card user-setting-card-container shopify_logo-container"}
                        style={{border: '1px solid rgb(248 239 239)', boxShadow: 'none'}}
                        cover={
                          <Image
                            preview={false}
                            src={woocommerce_logo}
                            className={"woocommerce_logo"}
                            style={{width: '85px', marginTop: '16px'}}
                          />
                        }
                      >
                        <Card.Meta title="WooCommerce" className={"custom-card-meta"} />
                      </Card>
                    </a>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Modal>
          {/* <Modal
            title={`${storeData.type} Integration`}
            width={600}
            closable={true}
            bodyStyle={{ height:'200px'}}
            onCancel={() => setEditModal(false)}
            visible={editModal}
            className={'modal-right-footer'}
            footer={[
              <div>
                <div>
                  <Button className={'modal-button'} type="primary" onClick={() => editIntegrationStore.mutate(storeData.uuid)} disabled={storeKey? false: true} loading={editIntegrationStore.isLoading}>
                    {'Update'}
                  </Button>
                </div>
              </div>,
            ]}>
            <div className={'magento-content'}>
              <div>
                <span>Store Key</span>
                <Input value={storeKey} onChange={(e)=> setStoreKey(e.target.value)}/>
              </div>
              <div>
                <span>Store URL</span>
                <Input value={storeData.domain} disabled={true}/>
              </div>
              <div>
                <span>Store Type</span>
                <Input value={storeData.type} disabled={true}/>
              </div>
            </div>                 
          </Modal> */}
        </Col>
      </Row>
    </>
  );
};
