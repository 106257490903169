import { Form, Button, Col, Row, Checkbox, Spin, Alert, message } from 'antd';
import { ReloadOutlined } from '@ant-design/icons'
import {useState} from 'react';
import { useHistory } from "react-router-dom";
import {useMutation} from "react-query";
import {contactCustomerService} from "../../../commons/utils/constants";
import { useLanguage } from '../../../languages/Language';
import {useAuth} from '../../../auth/services/authContext';
import { v4 as uuidv4 } from 'uuid';
import { makePayment} from "../../services/privateApi";
import {UserCreatedOrderSuccessfully, UserViewedOrderSuccessPage} from "../../../commons/mixpanel"

export const PayFromJanioCredits = ({walletDetail, orderData, orderIsStillValid, reloadComponent, orderType, bulkOrderChargeIds,promoCode}: any) => {
  let { getText } = useLanguage();
  const auth = useAuth();
  let history = useHistory();
  let walletBalance = walletDetail.data?.wallet_balance
  
  const [checked, setChecked] = useState(false);

  // Pay For Order API Handler
  const orderCharge:any = useMutation(async (token:string) => {
    let params:any = {
      charge_ids: orderType === 'SINGLE' ? orderData.charge_ids : bulkOrderChargeIds,
      transaction_id: uuidv4(),
      payment_type: 'JANIO_CREDIT',
      discount_code: promoCode
    }
    return makePayment(token, params);
  },  {
    onSuccess: (val) => {
      if(orderType === 'SINGLE'){
        UserCreatedOrderSuccessfully('single')
      }
      else{
        UserCreatedOrderSuccessfully('bulk')
      }
      UserViewedOrderSuccessPage()
      history.push('/orders/success')
    },
    onError: (error: { response: { data: { error: string } } }) => {
      let errorResponse: string = error.response.data.error
      message.error(<span><strong>{errorResponse}</strong>. Failed to create shipment.</span>);
    },
  });

  // Pay For order Button Handler
  const handleOrderCharge = () => {
    orderCharge.mutate(auth.user.authToken);
  }

  // Common JSX for both cases:
  // 1. When wallet balance is sufficient.
  // 2. When a Topup recharge is Required.
  const renderOrderCommonDetails = () => {
    return (
      <>
        {walletBalance < (orderData!.amountToPay) ?
          <Alert
            message={getText('Not enough balance. Please top-up your janio credit')}
            type="warning"
            showIcon
            style={{margin: '25px 0 13px 0'}}
          /> :
          <Alert
            message="You have enough balance to create your shipment"
            type="info"
            showIcon
            style={{margin: '25px 0 13px 0'}}
          />
        }
        <Row gutter={14}>
          <Col span={24}>
            <p className="wallet_brief" style={{marginTop: '8px', fontWeight: 600}}>
              {getText('Current Janio Credit Balance')}:<span> {walletDetail.data?.currency} {walletDetail.data?.wallet_balance.toFixed(2)}
              </span>
            </p>
          </Col>
          <Col span={24} style={{marginTop: '-8px'}}>
            <Form.Item>
              <Button style={{border: 'none', boxShadow: 'none', fontSize: '12px', paddingLeft: '0px', height: '20px'}}
                onClick={reloadComponent}
              >
                <ReloadOutlined style={{marginRight: '-5px'}} />{getText('Refresh Janio Credit')}
              </Button>
            </Form.Item>
          </Col>

          {
            !orderIsStillValid &&
              <Col span={24} style={{'margin': '-20px 0px 20px -7px'}}>
                <Col style={{color:"#ff4d4f"}}>{getText('You are updating the order from the past. Please select pickup date again.')}</Col>
              </Col>
          }
        </Row>
      </>
    )
  }

  if(walletDetail.isLoading) {
    return <Spin />
  }
  if(walletDetail.isError) {
    let error:any = walletDetail.error

    return (
      <div className="wallet-detail-fetch-error">
        <Alert
          message={getText("Error while fetching janio credit details")}
          description={<span>{getText("Something went wrong. Try refreshing the janio credit, if problem persists, please contact")} {contactCustomerService()}, {error.message} </span>}
          type="error"
          showIcon
        />
      </div>
    )
  }

  return (
    <>
      {renderOrderCommonDetails()}
      <div style={{marginTop:'20px'}}>
        <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)}>
          {getText('I agree to pay')} <b>{getText('additional courier charges')}</b> {getText('in case shipment details (weight, dimensions, item category, pickup and destination address) are found to be inaccurate.')}
        </Checkbox>
        {!checked ? <Col style={{color:"#ff4d4f"}}>{getText('Please accept agreement to proceed.')}</Col>: ''}
        <div style={{marginTop: '20px'}}>
          <Button type="primary"
            className="blue-button"
            onClick={handleOrderCharge}
            loading={orderCharge.isLoading}
            disabled={!(checked && orderIsStillValid && (walletBalance >= (orderData!.amountToPay + (orderData!.pickup_charge ? orderData!.pickup_charge : 0))))}>
            {getText('Create Shipment')}
          </Button>
        </div>
      </div>
    </>
  )
}