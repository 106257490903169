import {Prompt, useHistory} from "react-router-dom";
import {Button, Modal} from "antd";
import React, {useEffect} from "react";
import {useRouteLeavingGuard} from "./routeGuardContext";
import { useLanguage } from '../../../languages/Language';

export const RouteLeavingGuard = () =>{
  let { getText } = useLanguage()
  const routeContext = useRouteLeavingGuard();
  const history = useHistory();
  const handleConfirmNavigationClick = () => {
    routeContext.setShowAlert(false);
    routeContext.setConfirmedNavigation(true);
  };
  const closeModal = () => {
    routeContext.setShowAlert(false);
  };
  useEffect(() => {
    if ((routeContext.confirmedNavigation && routeContext.lastLocation)) {
      history.push(routeContext.lastLocation?.pathname);
    }
  }, [history, routeContext.confirmedNavigation, routeContext.lastLocation]);
  return(
    <>
      <Prompt when={true} message={routeContext.handleBlockedNavigation} />
      <Modal title={getText('Leave single shipment creation')}
        width={700}
        visible={routeContext.showAlert}
        onOk={handleConfirmNavigationClick}
        onCancel={closeModal}
        className={'modal-right-footer'}
        footer={[
          <Button onClick={closeModal}>{getText('Cancel')}</Button>,
          <Button type="primary" className={"blue-button"} onClick={handleConfirmNavigationClick}>{getText('Leave')}</Button>
        ]}>
        <p style={{marginBottom: 0}}>{getText('If you leave now, all the work that you’ve done so far will be lost.')}</p>
      </Modal>
    </>
  )
};
