import { Table, Button, Tag, Menu, Dropdown} from 'antd';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import {getDateInReadableFormat } from "../../commons/utils/utilizer";
import { useAuth } from '../../auth/services/authContext';
import { useLanguage } from '../../languages/Language';
import PayBulkOrderHeader from './PayBulkOrderHeader';
import {OrderDetailData} from '../../ManageShipments/services';
import {
  MoreOutlined
} from '@ant-design/icons'


export const ReadyToShip = (props:any) => {
  const auth = useAuth()
  let { getText } = useLanguage();
  const [selectedOrders, setSelectedOrders] = useState<Array<OrderDetailData>>([]);
  const [pageSize, setPageSize] = useState(10);
  const [selectedRowArr, setSelectedRowArray] = useState([])
  let preSelectedData = JSON.parse(localStorage.getItem('bulkPaymentOperationItems')!)

  const setStates = (orders:any,selectedRowArr:any ) => {
    setSelectedOrders(orders)
    setSelectedRowArray(selectedRowArr)
  }
  useEffect(() => {
    if(preSelectedData && preSelectedData.length > 0) {
      let orders = props.data?.results?.filter((order:any) => {
        return preSelectedData.indexOf(order?.id) > -1
      })
      setStates(orders,preSelectedData)
    }
  },[props.data]) //eslint-disable-line react-hooks/exhaustive-deps

  const menu = (key: any, data:any) => {
    return (
      <Menu>
        <Menu.Item key="0">
          <Link to={`/orders/marketplace/pay/single/${data.id}`} >
            <Button block style={{border: 'none',background: 'none',textAlign: 'left'}} >
              {getText('View shipment info')}
            </Button>
          </Link>
        </Menu.Item>
      </Menu>
    );
  }

  let columns = [
    {
      title: getText('Order Type'),
      dataIndex: ['channel','channel_type'],
      key: 'channel_type',
    },
    {
      title: 'Store Name',
      dataIndex: ['channel','channel_name'],
      key: 'channel_name',
    },
    {
      title: getText('Order ID'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Shipment Order Id',
      dataIndex: 'shipper_order_id',
      key: 'shipper_order_id  ',
    },
    // {
    //   title: getText('Created On'),
    //   dataIndex: 'created_on',
    //   key: 'created_on',
    //   render: (created_on: any) => {
    //     return getDateInReadableFormat(created_on)
    //   }
    // },
    // {
    //   title: 'Pickup Country',
    //   dataIndex: 'pickup_country',
    //   key: 'pickup_country',
    //   render: (pickup_country: any) => {
    //     return pickup_country
    //   }
    // },
    {
      title: getText('Consignee Country'),
      dataIndex: 'consignee_country',
      key: 'consignee_country',
      render: (consignee_country: any) => {
        return consignee_country
      }
    },
    // {
    //   title: 'Price Estimate',
    //   dataIndex: ['rate','amount_to_pay'],
    //   key: 'rate',
    //   render: (price:number, record:any) => {
    //     return price?`${price} ${record.rate?.currency}`: null
    //   }
    // },
    {
      title: getText('Status'),
      dataIndex: 'status',
      key: 'status',
      render: (status:any) => {
        return <Tag color="success">{status==='SHIPPED'?'Ready to Ship':status}</Tag>
      }
    },
    {
      title: getText('Actions'),
      dataIndex: '',
      key: '',
      fixed: 'right' as 'right',
      width: 100,
      render: (key:any, allData:any) => (
        <Dropdown overlay={() => menu(key, allData)} trigger={['click']}>
          <a className="ant-dropdown-link">
            <MoreOutlined />
          </a>
        </Dropdown>
      ),
    }
  ];

  if(auth.user.hide_price_estimation) {
    columns = columns.filter(item => item.title !== 'Price Estimate')
  }

  const rowSelection = {
    selectedRowKeys: selectedRowArr,
    onSelect: (record:any, selected:any, selectedRows: any) => {
      localStorage.removeItem('bulkPaymentOperationItems')
      let updateSelectedRowArr: any = [...selectedRowArr]
      let orders:any = selectedOrders;
      if(selected){
        orders.push(record)
        updateSelectedRowArr.push(record.id)
      } else {
        orders = orders.filter((order:any)=>{
          return order.id !== record.id;
        })
        updateSelectedRowArr = updateSelectedRowArr.filter((order:any)=>{
          return order !== record.id;
        })
        
      }
      setSelectedOrders(orders)
      setSelectedRowArray(updateSelectedRowArr)
    },
    onSelectAll: (selected:any,selectedRows: any,changeRows: any) => {
      localStorage.removeItem('bulkPaymentOperationItems')
      let updateSelectedRowArr: any = [...selectedRowArr]
      let orders:any = selectedOrders;
      if(selected){
        changeRows.map((row:any) => {
          return orders.push(row)
        })
        changeRows.map((row:any) => {
          return updateSelectedRowArr.push(row.id)
        })
      }
      else{
        changeRows.map((row:any) => {
          return orders = orders.filter((value:any)=>{
            return value.id !== row.id;
          })
        })
        changeRows.map((row:any) => {
          return updateSelectedRowArr = updateSelectedRowArr.filter((value:any)=>{
            return value !== row.id;
          })
        })
      }
      setSelectedOrders(orders)
      setSelectedRowArray(updateSelectedRowArr)
    }
  };

  const onSwitchPage = (page:any, pageSize: any) => {
    props.setPageSize(pageSize)
    props.setPage(page)
  }

  const onPageSizeChange = (current:any, pageSize:any) => {
    setPageSize(pageSize)
  }
  return (
    <>
      <PayBulkOrderHeader
        selectedOrders={selectedOrders}
        setSelectedRowArray={setSelectedRowArray}
        setSelectedOrders={setSelectedOrders}
        selectedRowKeys={selectedRowArr}
        walletRechargeStatus={props.walletRechargeStatus}
        propsFetchQuery={props.refetchQuery} 
        setPropsReftechQuery={props.setRefetchQuery}
      />
      
      <Table
        rowClassName='table_body'
        rowKey="id"
        style={props.data && props.data.results.length > 0 ? {marginTop:'-3px'} : {marginTop:'59px'}}
        dataSource={props.data && props.data.results}
        columns={columns}
        loading={props.isLoading}
        rowSelection={{preserveSelectedRowKeys:true,...rowSelection}}
        pagination={{
          onChange: onSwitchPage,
          position:['topRight','bottomRight'],
          defaultPageSize: pageSize,
          pageSize: pageSize,
          onShowSizeChange: onPageSizeChange,
          showSizeChanger: true,
          total: props.data && props.data.count,
          pageSizeOptions: ['10', '20', '50', '100']
        }}
      />
    </>
  )
};