import { Row, Col, Select, Button, Form as F, Divider } from 'antd'
import '../../styles/ReturnOrderForm.css'
import { useAuth } from '../../../auth/services/authContext'
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { useQuery } from 'react-query';
import { geReturntLocations_from_b2c, FetchDestinationStatesb2c, getCities_from_b2c } from '../../../orders/services/privateApi';
import {DatePicker, FormInput, FormSelect} from '../../../orders/components/Forms';
import {useReturnBulkOrder} from "../../services/returnBulkOrderContext";
import { assignReturnAddress, createLanesForReturns, disabledDate } from '../../../orders/helpers';
import {PickUpAddressModal as AddAddressModal} from "../../../UserSettings/components/PickUpAddressModal";
import { useState } from 'react';
import { getFormatedDate } from '../../../commons/utils/utilizer';
import { validatePostalCode } from '../../../commons/utils/validator';
import { countryCodes } from '../../../commons/utils/constants';

interface PropsType{
  current: number,
  setCurrentState: (val: number) => void,
  dataFilled?: number
}

const empty_lane = (lane:any) =>{
  return{
    origin_country: lane.origin,
    receiver_address_id: '',
    consignee_name: '',
    consignee_number: '',
    consignee_email: '',
    consignee_address: '',
    consignee_state: '',
    consignee_city: '',
    consignee_postal: '',
    pickup_date: '',
  }
};

const validationSchema = () => {
  return Yup.object().shape({
    distinctLanes: Yup.array().of(Yup.object().shape({
      consignee_name: Yup.string().required('Please enter consignee name'),
      consignee_number: Yup.number().required('Please enter consignee number').typeError('Please enter numbers only'),
      consignee_email: Yup.string().required('Please enter consignee email').email('Please enter in abc@email.com format'),
      consignee_address: Yup.string().required('Please enter consignee address'),
      origin_country: Yup.string().required('Please select consignee country'),
      consignee_state: Yup.string().required('Please select consignee state'),
      consignee_city: Yup.string().required('Please select consignee city'),
      consignee_postal: Yup.mixed().required('Please enter consignee postal').test(
        'postal-check',
        (val: any, context: any) => {
          if(!val){
            return context.createError({
              path: context.path,
              message: 'Please enter Postal Code'
            })
          }
          else{
            console.log(context)
            const error = validatePostalCode(countryCodes[context.parent.origin_country], val.toString());
            if(error){
              return context.createError({
                path: context.path,
                message: error
              })
            }
          }
          return true
        }
      ),
      pickup_date: Yup.date().required('Please select a valid date'),
    }))
  })};


const {Option} = Select;

const CityListFromb2c = (country: any, state: any) => {
  return useQuery(['cityListFromb2c',country, state], () => {
    if(state){
      return getCities_from_b2c(country, state)
    }
  });
}

const LaneForm = ({index, orderType, fName, formik, locations, onConsigneeAddressSelect, setShowAddAddressModal, setCurrentOrderType, setAddressType, showAddAddressModal, currentOrderType, addressType, setShowAddAddressModalCallback, receiverLanesLength}:
  {index:number, orderType: any, fName:string, formik:any, locations:any, onConsigneeAddressSelect: any, setShowAddAddressModal: any, setCurrentOrderType: any, setAddressType: any, showAddAddressModal: any, currentOrderType: any, addressType: any, setShowAddAddressModalCallback: any, receiverLanesLength: any}) =>{
  return (
    <div key={index} >
      <F.Item
        label={`${orderType.origin} to ${orderType.destination} : ${orderType.count} ${'shipments in total'}`}
        className={"single-order-form-title"}>
      </F.Item>
      <Row gutter={14}>          
        <Col span={24}>
          <FormSelect name={`distinctLanes.[${index}].receiver_address_id`}
            label={'Receiver Address'}
            wrapperClass="no-margin-item"
            placeholder={'Select an address'}
            showSearch={'false'}
            loading={locations.isLoading}
            onChangeCallback={(value: any) => {
              onConsigneeAddressSelect(value, index, formik);
            }}>
            {
              locations.data?.filter((l: { country: any; }) => l.country === orderType.destination)
                .map((item:any) => <Option key={item.id} value={item.id}>{`${item.contact_name} - ${item.address}`}</Option>)
            }
          </FormSelect>
          <F.Item className={'no-margin-item'}>
            <Button type="primary" onClick={() => {setShowAddAddressModal(true); setCurrentOrderType(index); setAddressType('pickup')}}
              className={"no-border-btn"}>
              {'Add new address'}
            </Button>
            {(showAddAddressModal && currentOrderType===index && addressType==='pickup') && <AddAddressModal setShowAddAddressModalCallback={setShowAddAddressModalCallback} addressType={'return'} countryCode={orderType.destination}/>}
          </F.Item>    

          <Row gutter={20}>
            <Col span={12}>
              <FormInput 
                required
                name={`distinctLanes.[${index}].consignee_name`}
                label={'Full Name'}
                placeholder='Name'
              />
            </Col>
            <Col span={12}>
              <FormInput 
                required
                name={`distinctLanes.[${index}].consignee_number`}
                label={'Phone Number'}
                placeholder='12345678'
              />
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={24}>
              <FormInput 
                required
                name={`distinctLanes.[${index}].consignee_email`}
                label={'Email'}
                placeholder='Please enter a valid email'
              />
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={24}>
              <FormInput 
                required
                name={`distinctLanes.[${index}].consignee_address`}
                label={'Address'}
                placeholder='Address'
              />
            </Col>
          </Row>    
          <Row gutter={20}>
            <Col span={12}>
              <FormSelect defaultValue={orderType.destination} name={`distinctLanes.[${index}].consignee_country`} required label={'Country'} wrapperClass="no-margin-item" placeholder={'Select a country'} disabled={true}>
                <Option value={orderType.destination}>{orderType.destination}</Option>
              </FormSelect>
            </Col>
            <Col span={12}>
              <FormSelect required label={'State'} name={`distinctLanes.[${index}].consignee_state`} loading={FetchDestinationStatesb2c(orderType.destination).isLoading} onChangeCallback={(state:string) => {
                formik.setFieldValue(`distinctLanes.[${index}].consignee_city`, '');
              }}>
                {
                  (FetchDestinationStatesb2c(orderType.destination).data || []).map((item: {state_id: number, state_name:string} ) => {
                    return  <Option key={item.state_id} value={item.state_name}>{item.state_name}</Option>
                  })
                }
              </FormSelect>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <FormSelect label={'City'} name={`distinctLanes.[${index}].consignee_city`} 
                loading={CityListFromb2c(orderType.destination, formik.values.distinctLanes[index].consignee_state).isLoading}
              >
                {
                  (CityListFromb2c(orderType.destination, formik.values.distinctLanes[index].consignee_state).data || [])
                    .map((item: {city_id:number, city_name: string} ) => {
                      return <Option key={item.city_id} value={item.city_name}>{item.city_name}</Option>
                    })
                }
              </FormSelect>
              {CityListFromb2c(orderType.destination, formik.values.distinctLanes[index].consignee_state).isError && <p style={{color:'red', marginBottom:0}}>Failed to fetch cities, try later</p>}
            </Col>
            <Col span={12}>
              <FormInput 
                required
                name={`distinctLanes.[${index}].consignee_postal`}
                label={'Postal Code'}
                placeholder='Please enter a valid postal code'
              />
            </Col>
          </Row>  
        </Col>
      </Row>

      <Row gutter={14}>
        <Col span={12}>
          <>
            <DatePicker
              disabledDate={disabledDate} 
              showToday={false} 
              name={`distinctLanes.[${index}].pickup_date`} 
              label={'Pickup Date'} 
              wrapperClass="no-margin-item"
            />
          </>
        </Col>
      </Row>
      {index + 1 < receiverLanesLength && <Divider/>}
    </div>
  )
};

export const ReceiverDetailsForm = (props: PropsType) => {
  let isEditing = window.location.pathname.includes('/pay/return/bulk')
  const {current, setCurrentState} = props
  const auth = useAuth();
  const returnBulkOrderContext = useReturnBulkOrder();
  const [showAddAddressModal, setShowAddAddressModal] = useState<boolean>(false);
  const [addressType, setAddressType] = useState<string>('');
  const [currentOrderType, setCurrentOrderType] = useState(-1);
  const [addressInfo, setAddressInfo] = useState<any>([]);

  const locations = useQuery('returnLocationsFromb2c', () => {
    return geReturntLocations_from_b2c(auth.user.secret_key)
  })

  const receiverContext = createLanesForReturns(
    isEditing? returnBulkOrderContext.parsedJson : returnBulkOrderContext.parsedJson.consolidatedOrderItems,
    auth.user.secret_key, 'RETURNS'); 

  const initialValues = {
    distinctLanes: returnBulkOrderContext.receiverFormContext.form ? returnBulkOrderContext.receiverFormContext.form:
      receiverContext.lanes.map((lane:any)=>{
        return {...empty_lane(lane)}
      })
  };

  const getCountOfShipments = () => {
    let count = 0;
    receiverContext?.lanes?.map((obj:any)=>{
      return count = count+(obj?obj.count:0);
    })
    return count
  }
  
  const setShowAddAddressModalCallback = (val: boolean) => {
    setShowAddAddressModal(val);
    setCurrentOrderType(-1);
    locations.refetch();
  };

  const onConsigneeAddressSelect = (value:any, index:any, formik:any) => {
    let selectedAddress:any = locations.data?.filter(address => {
      return  address.id === value;
    })
    formik.setFieldValue(`distinctLanes.[${index}].consignee_name`,selectedAddress[0].contact_name || '')
    formik.setFieldValue(`distinctLanes.[${index}].consignee_number`,selectedAddress[0].contact_number || '')
    formik.setFieldValue(`distinctLanes.[${index}].consignee_email`,selectedAddress[0].contact_email || '')
    formik.setFieldValue(`distinctLanes.[${index}].consignee_address`,selectedAddress[0].address || '')
    formik.setFieldValue(`distinctLanes.[${index}].consignee_state`,selectedAddress[0].state || '')
    formik.setFieldValue(`distinctLanes.[${index}].consignee_city`,selectedAddress[0].city || '')
    formik.setFieldValue(`distinctLanes.[${index}].consignee_postal`,selectedAddress[0].postal || '')
  }

  const onSubmit = (values: any) => {
    let file_name = returnBulkOrderContext.parsedJson.file_name
    receiverContext.lanes.map((lane:any, index:number)=>{
      const fName = `${lane.origin}-${lane.destination}`;
      const value = {
        consignee_address_id: values['distinctLanes'][index].receiver_address_id,
        consignee_address: values['distinctLanes'][index].consignee_address,
        consignee_city: values['distinctLanes'][index].consignee_city,
        consignee_email: values['distinctLanes'][index].consignee_email,
        consignee_name: values['distinctLanes'][index].consignee_name,
        consignee_number: values['distinctLanes'][index].consignee_number,
        consignee_postal: values['distinctLanes'][index].consignee_postal,
        consignee_state: values['distinctLanes'][index].consignee_state,
        consignee_country: lane.destination,
        pickup_date: getFormatedDate(values['distinctLanes'][index].pickup_date),
        billing_address_id: values['distinctLanes'][index].pickup_address_id,
        service_type: 'PICKUP',
      };
      let temp = addressInfo;
      temp[fName] = value;
      setAddressInfo(temp);
      return value;
    });
    const orders = assignReturnAddress(returnBulkOrderContext.parsedJson.consolidatedOrderItems, addressInfo, locations.data);
    returnBulkOrderContext.setReceiverFormContext({form: values['distinctLanes']});
    returnBulkOrderContext.setBulkOrder({file_name: file_name, orders: orders});
    returnBulkOrderContext.setPriceContext(null)
    setCurrentState(2)
  }
    
  if(current > 1){
    return (
      <div className='return-order-form-container'>
        {receiverContext.lanes &&                     
          receiverContext.lanes?.map((order: any,index:any) => {
            return <div style={{marginBottom: 10}}>
              <p style={{margin: 0}}>{`${order.origin} to ${order.destination} : ${order.count} ${'shipments in total'}`}</p>
            </div>
          })                    
        }                  
      </div>
    )
  }

  return (
    <div className='return-order-form-container ant-form-vertical'>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnChange={true}
      >
        {formik =>(
          <Form>
            <p><strong>{getCountOfShipments()?getCountOfShipments():0} shipments in total</strong></p>
            <FieldArray name="distinctLanes"
              render={() => (
                <>
                  {
                    receiverContext.lanes?.map((orderType: any, index: number) => {
                      const fName = `${orderType.origin}-${orderType.destination}`;
                      return <LaneForm key={index} index={index} orderType={orderType} fName={fName} formik={formik} locations={locations} onConsigneeAddressSelect={onConsigneeAddressSelect}
                        setShowAddAddressModal={setShowAddAddressModal} setCurrentOrderType={setCurrentOrderType} setAddressType={setAddressType}
                        showAddAddressModal={showAddAddressModal} currentOrderType={currentOrderType} addressType={addressType} 
                        setShowAddAddressModalCallback={setShowAddAddressModalCallback} receiverLanesLength={receiverContext.lanes.length}
                      />
                    })}</>
              )}/>
            <div className={"button-block"}>
              <Button htmlType="submit" type="primary" className={"blue-button"}>
                {'Save & Continue'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )

}

export const ReceiverDetailsFormTitle = (props: PropsType) => {
  let isEditing = window.location.pathname.includes('/pay/return/bulk')
  const {current, setCurrentState} = props;
  return (
    <div className={"title-block"}>
      <p className={'step-title'}>Where are we delivering to?</p>
      {current>1 && !isEditing ? <p onClick={()=> setCurrentState(1)} className={"edit-text"}>{'Edit details'}</p>:''}
    </div>
  )
}