import { Button, Col, Checkbox, message, Row } from 'antd';
import {useState} from 'react';
import '../../styles/SingleOrderForm.css';
import {useAuth} from '../../../auth/services/authContext';
import { 
  // confirmMonthlyBillingSingleOrder, 
  confirmMonthlyBillingBulkOrder, createMonthlyBillingSingleOrder_in_b2c, createReturnSingleOrder_in_b2c } from "../../services/privateApi";
import {useHistory} from "react-router-dom";
import {useMutation} from "react-query";
import { useLanguage } from '../../../languages/Language';
import {UserCreatedOrderSuccessfully, UserViewedOrderSuccessPage} from "../../../commons/mixpanel"
// import { SingleOrder } from '../../SingleOrder.page';

export const SelectedPaymentMethod = ({orderData, orderIsStillValid, orderType, context, orderDirection}: any) => {
  let { getText } = useLanguage();
  let history = useHistory();
  const auth = useAuth();
  const [checked, setChecked] = useState(false);
  const token = auth.user.authToken;

  // let displayErrorMessage = (errData: any) => {
  //   return Object.entries(errData.orders).map(([key, value]) => {
  //     console.log('errDataerrData', value)
  //     return <>
  //       <p><strong> - {key}:</strong> {value}</p></>
  //   })
  // }
  const createMonthlyBillingSingleOrderInB2C:any = useMutation(async () => {
    return orderDirection === 'FORWARD' ? createMonthlyBillingSingleOrder_in_b2c(token, context) : createReturnSingleOrder_in_b2c(auth.user.secret_key, context);
  },  {
    onSuccess: (val) => {
      UserCreatedOrderSuccessfully('single', auth?.user?.fullName)
      UserViewedOrderSuccessPage()
      history.push(orderDirection === 'FORWARD' ? '/orders/success' : '/orders/success/returns')
    },
    onError: (error: { response: { data: { orders: any } } }) => {
      let errorMessage: any;
      let errObject = error.response.data.orders ? error.response.data.orders : error.response.data;
      if(errObject) {
        try{
          errorMessage = Object.values(errObject).map((item: any) => {
            if(typeof(item) == 'string'){
              return (JSON.stringify(item))
            }
            return (              
              Object.entries(item).map((y: any) => {
                return `${y[0]}: ${y[1]}`
              })           
            )
          })
          const displayedMessage = errorMessage.join()
          message.error({
            content: <>{displayedMessage}</>,
            duration: 15,
            className: 'final-order-creation-error'
          })
          
        }catch(e){
          message.error({
            content: <>
              <span>We are not able to process your request right now, please reach out to us at  
                <a href={"mailto:clientsupport@janio.asia"}>clientsupport@janio.asia</a> for further help
              </span></>,
            duration: 15,
          })
        }
        
      }else{
        message.error({
          content: <>
            <span>We are not able to process your request right now, please reach out to us at  
              <a href={"mailto:clientsupport@janio.asia"}>clientsupport@janio.asia</a> for further help
            </span></>,
          duration: 15,
        })
      }
    },
  });

  const createMonthlyBillingBulkOrder:any = useMutation(async (orderId:string) => {
    return confirmMonthlyBillingBulkOrder(token, orderId);
  },  {
    onSuccess: (val) => {
      UserCreatedOrderSuccessfully('bulk', auth?.user?.fullName)
      history.push('/orders/success')
    },
    onError: (error: { response: { data: { error: any } } }) => {
      let errorMessage: any
      let errObject = error.response.data.error.orders ? error.response.data.error.orders : error.response.data.error;
      if(errObject) {
        try{
          errorMessage = Object.values(errObject).map((item: any) => {
            if(typeof(item) == 'string'){
              return ({item})
            }
            return (              
              Object.entries(item).map((y: any) => {
                return `${y[0]}: ${y[1]}`
              })           
            )
          })
          const displayedMessage = errorMessage.join()
          message.error(displayedMessage)
        }catch(e){
          message.error({
            content: <>
              <span>We are not able to process your request right now, please reach out to us at  
                <a href={"mailto:clientsupport@janio.asia"}>clientsupport@janio.asia</a> for further help
              </span></>,
            duration: 15,
          })
        }      
      }else{
        message.error({
          content: <>
            <span>We are not able to process your request right now, please reach out to us at  
              <a href={"mailto:clientsupport@janio.asia"}>clientsupport@janio.asia</a> for further help
            </span></>,
          duration: 15,
        })
      }
      
    },
  });

  const handleMonthlyBilling = () => {
    orderType === 'SINGLE'?
      createMonthlyBillingSingleOrderInB2C.mutate() :
      createMonthlyBillingBulkOrder.mutate(orderData.batch_no)
  }

  return (
    <>
      <div style={{marginTop:'20px'}}>
        {
          !orderIsStillValid &&
          <Col span={24} style={{'marginTop': '10px','marginBottom':'10px'}}>
            <Col style={{color:"#ff4d4f"}}>{getText('You are updating the order from the past. Please select pickup date again.')}</Col>
          </Col>
        }
        <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)}>
          {getText('I agree to pay')} <b>{getText('additional courier charges')}</b> {getText('in case shipment details (weight, dimensions, item category, pickup and destination address) are found to be inaccurate.')}
        </Checkbox>
        {!checked ? <Col style={{color:"#ff4d4f"}}>{getText('Please accept agreement to proceed.')}</Col>: ''}
        <div style={{marginTop: '20px'}}>
          <Button type="primary"
            className="blue-button"
            onClick={handleMonthlyBilling}
            loading={createMonthlyBillingSingleOrderInB2C.isLoading || createMonthlyBillingBulkOrder.isLoading}
            disabled={!(checked && orderIsStillValid)}>
            {getText('Create Shipment')}
          </Button>
        </div>
        <Row align='bottom' style={{textAlign: 'right', margin: '15px -15px -18px 0', color: 'rgb(255, 77, 79)', fontSize: '15px'}}><Col span={24}>*Prices shown does not include GST/VAT/D&T</Col></Row>
      </div>
    </>
  )
}