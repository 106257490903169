import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useLocation
} from "react-router-dom"
import {Image, Layout} from 'antd'
import './styles/AuthRoute.css';
import { useAuth
  // ,isSessionCurrent,refreshUserSession
} from '../auth/services/authContext'
import { Login } from '../auth/Login.page'
import janio_logo from "../assets/images/janio_logo.svg";
import footer from '../assets/images/footer.svg';
import {Register} from "../auth/Register.page";
import {Reset} from "../auth/Reset.page";
import {Resend} from "../auth/Resend.page";
import {CreatePassword} from "../auth/CreatePassword.page";
import {Activation} from "../auth/Activation.page";
import {Migration} from "../auth/Migration.page";
import {AuthLanguageSelector} from '../languages/AuthLanguageSelector';
import {NewTCModal} from "./components/TermsAndConditions/NewTCModal";
import { featureFlag } from "../commons/utils/utilizer";

const { Header, Footer, Content } = Layout;

export const AuthLayout = () => {
  const auth = useAuth()
  let { path } = useRouteMatch();
  let { pathname } = useLocation();
  if( auth.user.isSignedIn ){
    return <Redirect to={{pathname: ""}} />
  }
  // if(auth.user.isSignedIn && !isSessionCurrent(auth.user)){
  //   refreshUserSession(auth);
  // }
  return (
    <Layout className={'auth-container'}>
      <Header>
        <a href="#">
          <Image preview={false} src={janio_logo}/>
        </a>
      </Header>
      <Content>
        {(auth.user.isSignedIn && (!auth.user.hasOwnProperty('tnc') || auth.user.tnc === false)) && <NewTCModal/>}
        <Switch>
          <Route path={`${path}/login`}>
            <AuthLanguageSelector/>
            <Login />
            <AuthLanguageSelector/>
          </Route>
          <Route path={`${path}/signup`}>
            <AuthLanguageSelector/>
            <Register />
          </Route>
          <Route path={`${path}/reset_password`}>
            <Reset />
          </Route>
          <Route path={`${path}/resend_verification`}>
            <Resend />
          </Route>
          <Route path={`${path}/create_password/:uid/:token`}>
            <CreatePassword />
          </Route>
          <Route path={`${path}/activation/:uid/:token`}>
            <Activation />
          </Route>
          <Route path={`${path}/migration/`}>
            <Migration />
          </Route>
        </Switch>
      </Content>
      
      <Footer>
        <Image preview={false} src={(!pathname.includes('signup') || featureFlag()) ? footer : undefined}/>
      </Footer>
    </Layout>
  )
}
