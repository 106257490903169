import { ReactNode } from "react"
import {Divider} from "antd";
import {CloseOutlined} from "@ant-design/icons/lib";
import '../styles/ToggleSidebar.css';
import { useLanguage } from '../../languages/Language';

interface Props {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => any
  children: ReactNode
}

export const RightToggleSidebar = (props: Props) => {
  let { getText } = useLanguage();

  return (
    <div className={'toggle-bar-container'} style={{display : props.isOpen ? 'flex' : 'none'}}>
      <div className={'toggle-bar-header'}>
        <p className={'regular-bold-text'} style={{marginBottom: 0}}>{getText('Shipment Tracker')}</p>
        <CloseOutlined onClick={() => props.setIsOpen(false)}/>
      </div>
      <Divider/>
      {props.children}
    </div>
  )
}
