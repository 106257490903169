import { Col, Table, Dropdown, Menu, Modal, message,
  // Spin, 
  Row, Popover } from 'antd';
import { Link, useHistory, useLocation } from "react-router-dom";
// import { useAuth } from '../../auth/services/authContext';
// import { useQuery } from "react-query";
import { 
  // useEffect, 
  useState} from 'react';
import { 
  // getParentDetails, 
  OrderData, OrderDetailData_from_b2c} from '../services';
import {contactCustomerService} from "../../commons/utils/constants";
import {MoreOutlined, DownloadOutlined, ExclamationCircleOutlined} from '@ant-design/icons'
import '../styles/ManageShipments.css'
import {getDateInReadableFormat,capitalizeString,replaceString} from "../../commons/utils/utilizer";
import { useLanguage } from '../../languages/Language';
import {Tips} from "../../commons/components/Tips/Tips";
import { Tip4Viewed, UserClickedePODDOwnload } from '../../commons/mixpanel'
// import {DropoffSupportTooltip} from '../Components/DropoffSupportTooltip';
import Button from 'antd/lib/button';
import { useMutation } from 'react-query';
import { cancelOrder_on_b2c } from '../../orders/services/privateApi';
import { useAuth } from '../../auth/services/authContext';

interface Props {
  data?: OrderData;
  isLoading: boolean;
  setPageSize: (pageSize: number) => any;
  setPage: (page: number) => any;
  displayTrackingSidebar: (orderId?: number) => void;
  selectedOrdersCallback?: (orders:Array<OrderDetailData_from_b2c>,orderStatus: string) => any;
  orderStatus: string
  hasData: boolean
  filterInReports?: boolean
  ordersSelected?: boolean
  current: number
  setCurrent: (val: number) => void;
  dataFrom: string
}

const noLabelInfo = (data?: any) => {
  if(data.private_tracker_status_code === 'RETURN_ORDER_PENDING_CONSIGNEE_CONFIRMATION') {
    Modal.info({
      title: 'Shipment label generation is pending on consignee confirmation',
      content: (
        <div>
          <p style={{margin: '30px 0'}}><strong>An email has been sent to your consignee to confirm the pickup.</strong></p>
          <p>If the shipment labels do not appear after consignee confirmation, please contact us at {contactCustomerService()}</p>
        </div>
      ),
      onOk() {},
      width:(600)
    })
  } else {
    Modal.info({
      title: 'Shipment label cannot be printed',
      content: (
        <div>
          <p style={{margin: '30px 0'}}><strong>Please try again after 10 minutes as the shipment labels are still being processed.</strong></p>
          <p>If the issue persists, please contact us at {contactCustomerService()} for updates on the availability of the labels.</p>
        </div>
      ),
      onOk() {},
      width:(600)
    })
  }
}

const {confirm} = Modal;
const AllOrders = (props: Props) => {
  const auth = useAuth();
  let { getText } = useLanguage();
  const {data, isLoading} = props
  const statusEligibleForCancel = ["ORDER_CREATED", "PENDING_PICKUP"]
  const [pageSize, setPageSize] = useState(10)
  // const [orderId, setOrderId] = useState(0)
  const [selectedOrders, setSelectedOrders] = useState([]);
  // const [expandedRows, setExpandedRows] = useState([])
  const location:any = useLocation()
  const history = useHistory()
  let prevPathName= location.state?.from;
  const [showTip, setShowTip] = useState(prevPathName==="/integrations"?true:false);

  // const getSingleOrderDataDetails = useQuery(['getSingleOrderDataForViewShipment', orderId], async () => {
  //   return fetchOrder(auth.user.secret_key, orderId)
  // }, {enabled: orderId > 0, retry: false})

  // const getParentOrderDetails = useQuery(['getParentOrderDetails', orderId], async () => {
  //   return getParentDetails(orderId, auth.user.secret_key)
  // }, {enabled: orderId > 0, retry: false})

  const setShowTipCallback = (val:boolean) => {
    setShowTip(val);
  }
  const onDoneButtonClick = () => {
    let getParentPopup:any = document.getElementById("ant-popover-container-id");
    document.body.removeChild(getParentPopup);
    document.body.style.removeProperty("overflow");
    setShowTip(false);
  }
  const onTipPrevButtonClick = () => {
    history.push({
      pathname: "/integrations",
      state: {
        from: location.pathname
      }
    })
  }

  const cancelOrderOnb2c = useMutation(async(tracking_no:string) => {
    return cancelOrder_on_b2c(auth.user.secret_key, tracking_no)
  }, {
    onSuccess: (response:any) => {
      message.success(`${response.detail}`)
      props.setPageSize(pageSize)
    },
    onError: (error:any) => {
      let errorResponse = error.response.data;
      if(typeof errorResponse === 'object'){
        let errorObj = Object.entries(errorResponse)
        message.error(<><strong>{errorObj[0][0]}</strong>: {errorObj[0][1]}</>, 10)
      }
      else{
        message.error("Failed to cancel the order")
      }
    }
  })

  const cancelOrder = (tracking_no:string) => {
    showConfirmDeletion(tracking_no)
  }

  const showConfirmDeletion = (tracking_no:string) =>{
    return (
      confirm({
        className: 'custom-confirm-modal',
        icon: <ExclamationCircleOutlined />,
        okButtonProps : { loading: cancelOrderOnb2c.isLoading },
        content: <p>{getText("Click")} <strong>{getText("OK")}</strong> to cancel the order</p>,
        onOk() {
          cancelOrderOnb2c.mutate(tracking_no);
        },
        onCancel() {},
      })
    )
  }

  const dataForTable = (tableData: any) => {
    const dataWithoutPackage = tableData?.filter((data: any) => data.order_type !== 'PACKAGE')
    return dataWithoutPackage;
  }

  const menu = (key: any, data: OrderDetailData_from_b2c) => {
    return (
      <Menu>
        <Menu.Item key="0">
          <Link to={data.order_type === 'MPS' ? `/orders/mps/view_shipment_details/${data.order_id}` : `/orders/view_shipment_details/${data.order_id}`}>
            <button className="table-link">
              {getText('View shipment info')}
            </button>
          </Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="2">
          <button style={{'textAlign': 'left'}} className="table-link" onClick={() => props.displayTrackingSidebar(data?.order_id)}>
            {getText('Track Shipment')}
          </button>
        </Menu.Item>
        {statusEligibleForCancel.includes(data.public_tracker_status_v2) && <Menu.Divider/>}
        {statusEligibleForCancel.includes(data.public_tracker_status_v2) && <Menu.Item key="3">
          <button style={{textAlign: 'left'}} className="table-link" onClick={() => {cancelOrder(data.tracking_no)}}>
            {getText('Cancel Order')}
          </button>
        </Menu.Item>}
      </Menu>
    );
  }

  // useEffect(() => {
  //   if(orderId > 0) {
  //     // getSingleOrderDataDetails.refetch()
  //     getParentOrderDetails.refetch()
  //   }
  //   return undefined
  // }, [orderId]) // eslint-disable-line react-hooks/exhaustive-deps

  const noPODInfo = () => {
    return <div className='ashish'>
      <p style={{margin: '8px 0'}}> If the issue persists, contact us at {<a href={'mailto:clientsupport@janio.asia'} style={{color: '#050593'}}>clientsupport@janio.asia</a>} for updates on the availability of the POD.</p>
    </div>
  }

  // const expandedRowRender = (record: any, index: any, indent: any, expanded: any) =>{
    
  //   if(expanded) {
  //     setOrderId(record.order_id)
  //   }
  //   const expandedColumns = [
  //     { 
  //       title: 'Parent Tracking Number', 
  //       key: 'tracking_no' ,
  //       render: (key: any, allData: any) => getParentOrderDetails.data?.tracking_no
  //     },
  //     { 
  //       title: 'Child Tracking Number', 
  //       dataIndex: 'tracking_no', 
  //       key: 'tracking_no' 
  //     },
  //     // { 
  //     //   title: 'Item Description', 
  //     //   key: 'item_desc',
  //     //   render: (key: any, allData: any) => allData?.items[0]?.item_desc
  //     // },
  //     // { 
  //     //   title: 'Dimension',
  //     //   key: 'dimension',
  //     //   render: (key: any, allData: any) => {
  //     //     return `(W)${allData?.package_width} x (L)${allData?.package_length} x (H)${allData?.package_height}`
  //     //   }
  //     // },
  //     { 
  //       title: 'Action', 
  //       key: 'action', 
  //       render: (key: any, allData: any) => {
  //         return (
  //           <Link to={{ pathname: `/orders/mps/view_shipment_details/${record.order_id}`, state: { tracking_no: allData.tracking_no } }}>{getText('View shipment info')}</Link>
  //         )
  //       }
  //     },
  //   ];

  //   const expandedDataSource = getParentOrderDetails.data?.packages
  //   return <Spin spinning={getParentOrderDetails.isLoading}><Table className={'mps-expanded-table'} columns={expandedColumns} dataSource={getParentOrderDetails.isLoading ? [] : expandedDataSource} pagination={false} /></Spin>
  // }

  let columns = [
    {
      title: getText('Shipper Order Id'),
      dataIndex: 'shipper_order_id',
      key: 'shipper_order_id',
      render: (shipper_order_id: any) => {
        return shipper_order_id ? shipper_order_id : 'N/A'
      }
    },
    {
      title: 'Tracking number',
      dataIndex: 'tracking_no',
      key: 'tracking_no',
      render: (tracking_no: any, data: any) => {
        return <Link to={data.order_type === 'MPS' ? `/orders/mps/view_shipment_details/${data.order_id}` : `/orders/view_shipment_details/${data.order_id}`}>
          <button className="table-link" style={{cursor: 'pointer', textAlign: 'left'}}>
            {tracking_no ? tracking_no : 'N/A'}
          </button>
        </Link>
      }
    },
    {
      title: 'Order Status',
      dataIndex: 'public_tracker_status_v2',
      key: 'public_tracker_status_v2',
      render: (public_tracker_status_v2: string) => {
        if(public_tracker_status_v2){
          let replacedString = replaceString(public_tracker_status_v2,'_'," ")
          return capitalizeString(replacedString);
        }
      }
    },
    {
      title: 'Pickup Country',
      dataIndex: 'pickup_country',
      key: 'pickup_country',
      render: (pickup_country: any) => {
        return pickup_country
      }
    },
    {
      title: getText('Destination Country'),
      dataIndex: 'consignee_country',
      key: 'consignee_country',
      render: (consignee_country: any) => {
        return consignee_country
      }
    },
    {
      title: getText('Created At'),
      dataIndex: 'created_on',
      key: 'created_on',
      render: (created_on: any) => {
        return getDateInReadableFormat(created_on)
      }
    },
    {
      title: 'Created By',
      dataIndex: 'agent_application_name',
      key: 'agent_application_name',
      render: (agent_application_name: any, allData: any) => {
        return (agent_application_name ? agent_application_name : 'N/A')
      }
    },
    {
      title: 'Order Direction',
      dataIndex: 'order_direction',
      key: 'order_direction',
      render: (order_direction: string, allData: any) => {
        if(allData.additional_data){
          let orderDirection = allData.additional_data.order_direction ? allData.additional_data.order_direction : 'N/A'
          return capitalizeString(orderDirection);
        }
      }
    },
    // {
    //   title: getText('Order Type'),
    //   dataIndex: 'order_type',
    //   key: 'order_type',
    //   render: (order_type: string) => {
    //     if(order_type){
    //       return capitalizeString(order_type);
    //     }
    //   }
    // },
    {
      title: 'Service Type',
      dataIndex: 'service_category',
      key: 'service_category',
      render: (service_category: string) => {
        if(service_category){
          let replacedString = replaceString(service_category,'_'," ")
          return capitalizeString(replacedString);
        }
        // <>
        //   <DropoffSupportTooltip service={service_category === 'Dropoff_WH' ? 'Dropoff at warehouse' : service_type === 'CC_LM' ? 'Customs clearance and last mile': service_type}/>
        // </>
      }
    },
    {
      title:<Tips
        visible={showTip}
        step={'4'}
        placement={'bottomRight'}
        hidePreviousText={false}
        onPrevClick={onTipPrevButtonClick}
        onNextClick={onDoneButtonClick}
        onExitTour={setShowTipCallback}
        nextText={"Done"}
        mixpanelEvent={Tip4Viewed}
        contentMessage={"Once a shipment is successfully submitted, you will be able to have your label printed under the column named Actions."}>
        {getText('Actions')}
      </Tips>,
      dataIndex: 'Actions',
      key: '',
      fixed: 'right' as 'right',
      width: 225,
      render: (key:any, allData:any) => {
        if(allData.public_tracker_status_v2 !== 'DELIVERY_COMPLETED') {
          return(
            <>
              <Row gutter={8}>
                <Col span={10}>
                  <Button type='primary'  style={{color: '#fff', borderRadius: '4px', width: '100%'}}>
                    {
                      allData.order_label_url ?
                        <a type="button" href={`${allData.order_label_url}`} target="_blank" rel="noopener noreferrer" className={'table-link'}>{getText('Print')}</a>
                        : <a onClick={noLabelInfo}>{getText('Print')}</a>
                    }
                  </Button>
                </Col>
                <Col span={9} style={{fontSize: '21px', color: '#050593', display: 'flex', alignItems: 'center'}}>
                  <Dropdown overlay={() => menu(key, allData)} trigger={['click']} placement="bottomRight">
                    <a className="ant-dropdown-link">
                      <MoreOutlined />
                    </a>
                  </Dropdown>
                </Col>
              </Row>
            </>
          )
        }
        return <Col span={24}>
          <Row gutter={8}>
            <Col span={10}>
              <Button type='primary' style={{borderRadius: '4px', width: '100%'}}>
                {
                  allData.order_label_url ?
                    <a type="button" href={`${allData.order_label_url}`} target="_blank" rel="noopener noreferrer" className={'table-link'}>{getText('Print')}</a>
                    : <a onClick={noLabelInfo}>{getText('Print')}</a>
                }
              </Button>
            </Col>
            <Col span={10} className='popover-warning'>
              {
                  allData.epod_url! ?
                    <Button type='default' style={{color: '#050593', borderRadius: '4px', width: '100%'}}>
                      <a type='button'
                        href={allData.epod_url} target="_blank" rel="noopener noreferrer"
                        onClick={() => UserClickedePODDOwnload('Single', 'All')}
                        className={'table-link'}>{'POD'}<DownloadOutlined style={{marginLeft: '4px', fontSize: '18px', position: 'relative', top: '2px', color: '#000'}} /></a>
                    </Button> : 
                    <Popover overlayClassName='wrapper-popup' overlayStyle={{width: '500px'}} placement="left" content={noPODInfo} title={<span style={{marginTop: '8px', display: 'block', fontSize: '14px'}}>POD cannot be downloaded right now</span>}>
                      <Button disabled type='default' style={{color: '#050593', borderRadius: '4px', width: '100%', background: '#f5f5f5', borderColor: '#d9d9d9'}}>
                        <a>{'POD'}<DownloadOutlined style={{marginLeft: '4px', fontSize: '18px', position: 'relative', top: '2px', color: '#000'}} /></a>
                      </Button>
                    </Popover>
              }
            </Col>
            <Col span={4} style={{fontSize: '21px', color: '#050593', display: 'flex', alignItems: 'center'}}>
              <Dropdown overlay={() => menu(key, allData)} trigger={['click']} placement="bottomRight">
                <a className="ant-dropdown-link">
                  <MoreOutlined /></a>
              </Dropdown>
            </Col>
          </Row>
        </Col>
      }
    }
  ];
  
  const rowSelection = {
    onSelect: (record:any,selected:any,selectedRows: any) => {
      let orders:any = selectedOrders;
      if(selected){
        orders.push(record)
      }
      else{
        orders = orders.filter((order:any)=>{
          return order.order_id !== record.order_id;
        })
      }
      setSelectedOrders(orders)
      props.selectedOrdersCallback!(orders,props.orderStatus)
    },
    onSelectAll: (selected:any,selectedRows: any,changeRows: any) => {
      let orders:any = selectedOrders;
      if(selected){
        changeRows.map((row:any) => {
          return orders.push(row)
        })
      }
      else{
        changeRows.map((row:any) => {
          return orders = orders.filter((value:any)=>{
            return value.order_id !== row.order_id;
          })
        })
      }
      setSelectedOrders(orders)
      props.selectedOrdersCallback!(orders,props.orderStatus)
    }
  };
  const onSwitchPage = (page:any, pageSize: any) => {
    props.setPageSize(pageSize)
    props.setPage(page)
    
    if(props.dataFrom === 'manageShipment'){
      props.setCurrent(page)
    } 
  }

  const onPageSizeChange = (current:any, pageSize:any) => {
    setPageSize(pageSize)
  }

  if(props.filterInReports) {
    columns = columns.filter((col: any) => {
      return col.dataIndex !== "Actions"
    })
  }

  // const handleExpandRow = (expanded: any, record: any) => {
  //   const expandedRows: any = [];
  //   if (expanded) {
  //     expandedRows.push(record.order_id);
  //   }
  //   setExpandedRows(expandedRows)
  // }
  
  return (
    <Col className={(!props.ordersSelected) ? 'mt-85 for-delivered-bulk' : (props.ordersSelected) ? 'mt-50 for-delivered-bulk' : 'mt-0 for-delivered-bulk'} span={24} >
      {(data?.results?.length! > 0 && !props.ordersSelected) && <p style={{margin: '0px 0px 7px 0px', paddingTop: '15px'}}>{`${data?.count} results`}</p>}
      <Table
        className='pagination-select-wrapper'
        rowClassName='table_body'
        rowKey="order_id"
        rowSelection={props.filterInReports ? undefined : {preserveSelectedRowKeys:true,...rowSelection}}
        // expandable={
        //   { 
        //     expandedRowRender: expandedRowRender,
        //     rowExpandable: record => record.order_type === 'MPS',
        //     expandedRowKeys: expandedRows,
        //     onExpand: handleExpandRow
        //   }
        // }
        dataSource={dataForTable(data?.results)}
        columns={columns}
        loading={isLoading}
        scroll={{ x: 1300 }}
        pagination={{
          onChange: onSwitchPage,
          position:['bottomRight'],
          defaultPageSize: pageSize,
          pageSize: pageSize,
          total: data && data.count,
          showSizeChanger: true,
          onShowSizeChange: onPageSizeChange,
          pageSizeOptions: ['10', '20', '50', '100'],
          current : props.current 
        }}
      />
    </Col>
  )
};

export default AllOrders
