import {Alert} from 'antd'
import React from "react";
import {useAuth} from "../../../auth/services/authContext";

export const PickupChargeAlert = () => {
  const auth = useAuth();

  let billingCountry = auth.user.billingCountry

  if(billingCountry === 'SG'){
    return (
      <Alert type="info" showIcon message={
        <div style={{fontSize:'14px'}}>
          {"To avoid extra charges of the SGD 6.54 (Inclusive of Taxes) pickup attempt fee, we advice you to select your pickup date the same as your upcoming scheduled pickup date. You might want to consider selecting our drop-off service with no extra charges!"}
        </div>
      }
      />
    )
  }
  return <></>
}