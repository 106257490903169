import React, { useState } from 'react'
import {Alert, Modal} from 'antd'
import TermsAndCondition from './TermsAndCondition'

const ClaimsGuidelines = () => {
  const [isShowModal, setIsShowModal] = useState(false)
  const termsAndConditionModal = () => {
    setIsShowModal(!isShowModal)
  }
  return (
    <>
      <Alert closable type='info' showIcon message={
        <div style={{cursor: 'pointer'}} onClick={() => setIsShowModal(true)}>
          {`Please go through claims guidelines`}
        </div>
      }/>
      <Modal title={'Terms and Condition'} visible={isShowModal} onCancel={termsAndConditionModal} onOk={termsAndConditionModal}>
        <TermsAndCondition/>
      </Modal>
    </>
  )
}

export default ClaimsGuidelines