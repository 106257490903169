import {
  FieldProps,
  FastField,
} from 'formik';
import {
  Input,
  Form as F,
  Select,
  Checkbox as AntCheckbox,
  DatePicker as AntdDatePicker,
  Upload,
  Button
} from 'antd';
import { InputProps } from 'antd/lib/input/Input'
import { ExclamationCircleFilled, UploadOutlined } from '@ant-design/icons'

import React, {ReactNode} from 'react'

const {TextArea} = Input

interface FormInputProps {
  name: string
  label?: string | any
  required?: boolean
  wrapperClass?: string
  inputProps?: InputProps
  placeholder?: string
  isPassword?: boolean
  prefix?: string | number
  textArea?: boolean
  rows?: number
  maxLength?: number
  height?: number
  resize?: 'none' | 'both' | 'horizontal' | 'vertical' | 'block' | 'inline'
}

export const FastFormInput = (props: FormInputProps) => {
  if(props.textArea){
    return (
      <FastField name={props.name}>
        {(field: FieldProps) =>
          <F.Item
            validateStatus={(field.meta.touched && field.meta.error) ? 'error': undefined}
            help={(field.meta.touched && field.meta.error) ? field.meta.error : null}
            required={props.required || false}
            label={props.label}
            className={props.wrapperClass}>
            <TextArea rows={props.rows} {...field.field} maxLength={props.maxLength} style={{ height: props.height, resize: props.resize }} placeholder={props.placeholder}/>
          </F.Item>
        }
      </FastField>
    )
  }
  return (
    <FastField name={props.name}>
      {(field: FieldProps) =>
        <F.Item
          validateStatus={(field.meta.touched && field.meta.error) ? 'error' : undefined}
          help={(field.meta.touched && field.meta.error) ? field.meta.error : null}
          required={props.required || false}
          label={props.label}
          className={props.wrapperClass}>
          {props.isPassword ? 
            <Input.Password {...props.inputProps} {...field.field} placeholder={props.placeholder} /> : 
            <Input {...props.inputProps} {...field.field} placeholder={props.placeholder} prefix={props.prefix}  />
          }
          
        </F.Item>
      }
    </FastField>
  )
}

interface FormSelectProps extends FormInputProps{
  children: ReactNode
  loading?: boolean
  showSearch?: string
  onChangeCallback?: (val:any) => any
  onSelectCallback?: (val:any) => any
  disabled?: boolean
  defaultValue?: string
  notFoundContent?: any
}

export const FastFormSelect = (props: FormSelectProps) => {
  return (
    <>
      <FastField name={props.name}>
        {(field: FieldProps) => {
          return <F.Item
            validateStatus={(field.meta.touched && field.meta.error) ? 'error' : undefined}
            help={(field.meta.touched && field.meta.error) ? field.meta.error : null}
            label={props.label}
            required={props.required || false}
            className={props.wrapperClass}
          >
            <Select
              showSearch={props.showSearch !== 'false'}
              notFoundContent={props.notFoundContent}
              defaultValue={field.field.value ? field.field.value : null}
              placeholder={props.placeholder}
              optionFilterProp="children"
              loading={props.loading}
              disabled={props.disabled}
              value={field.field.value || props.defaultValue}
              onChange={(val) => {
                field.form.setFieldValue(props.name, val);
                if(props.onChangeCallback){
                  props.onChangeCallback(val)
                }} }
              onSelect={(val) => {
                field.form.setFieldValue(props.name, val);
                if(props.onSelectCallback){
                  props.onSelectCallback(val)
                }} }
              filterOption={(input, option) => {
                if(option?.options) {
                  option.options.map((inputData: any, optionData: any) => {
                    return inputData.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  })
                } else {
                  return option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                return false
              }
              }>
              {props.children}
            </Select>
          </F.Item>
        }}
      </FastField>
    </>
  )
}

interface DatePickerProps extends FormInputProps{
  disabledDate?: any,
  onChange?: (val:any) => any,
  showToday?: boolean,
  disabled?: boolean
}

export const FastDatePicker = (props: DatePickerProps) => {
  return (
    <FastField name={props.name}>
      {(field: FieldProps) => {
        return <F.Item
          validateStatus={(field.meta.touched && field.meta.error) ? 'error' : undefined}
          help={(field.meta.touched && field.meta.error) ? field.meta.error : null}
          label={props.label}
          required={props.required || false}
          className={props.wrapperClass}>
          <AntdDatePicker
            disabledDate={props.disabledDate}
            disabled={props.disabled}
            showToday={props.showToday}
            style={{width: "100%"}}
            defaultValue={field.field.value}
            onChange={(val) => {
              field.form.setFieldValue(props.name, val);
              if(props.onChange){
                props.onChange(val)
              }
            }}
          />
        </F.Item>
      }
      }
    </FastField>
  )
}


interface CheckboxProps extends FormInputProps{
  children?: ReactNode
  checked?: boolean
  onChange?: (val:any) => any
}

export const FastCheckBox = (props: CheckboxProps) => {
  return (
    <FastField name={props.name}>
      {(field: FieldProps) =>
        <F.Item
          validateStatus={(field.meta.touched && field.meta.error) ? 'error' : undefined}
          help={(field.meta.touched && field.meta.error) ? field.meta.error : null}
          label={props.label}
          className={props.wrapperClass}>
          <AntCheckbox checked={field.field.value} onChange={e => field.form.setFieldValue(props.name, e.target.checked)}>
            {props.children}
          </AntCheckbox>
        </F.Item>
      }
    </FastField>
  )
}

interface UploadProps extends FormInputProps{
  children?: ReactNode
  onChange?: (val: any) => any
  beforeUpload?: (val: any) => any
  loading?: boolean
  showRemove?: boolean
  onRemove?: (file: any) => any
  fileList?: any
}

export const FastUploadFile = (props: UploadProps) => {
  return (
    <FastField name={props.name}>
      {(field: FieldProps) => 
        <F.Item
          validateStatus={(field.meta.touched && field.meta.error) ? 'error': undefined}
          help={(field.meta.touched && field.meta.error) ? field.meta.error: null}
          label={props.label}
          required={props.required || false}  
          className={props.wrapperClass}
        >
          <Upload {...props} showUploadList={{showRemoveIcon: props.showRemove || false}}>
            <Button icon={<UploadOutlined />}>Upload files</Button>
          </Upload>
        </F.Item>
      }
    </FastField>
  )
}

interface RadioButtonProps extends FormInputProps{
  checked?: boolean
  options?: any
  visualisation: string
  disabled?: boolean
}

export const FastRadioGroup = (props: RadioButtonProps) => {
  const {label, name, options, visualisation, disabled, ...rest} = props

  return (
    <>
      <label>{label}</label>
      <div className={visualisation === 'buttons' ? 'radio-as-btn-wrapper' : 'radio-control-wrapper'}>
        <FastField name={name} {...rest}>
          {
            ({field}: FieldProps) => {
              return options.map((option: any) => {
                return (
                  <React.Fragment key={option.key}>
                    <div className={visualisation === 'buttons' ? 'radio-as-buttons' : 'radio-control'}>
                      <input
                        type="radio" 
                        id={option.value} 
                        {...field} 
                        value={option.value} 
                        checked={field.value === option.value}
                        disabled={disabled}
                      />
                      <label htmlFor={option.value}><span>{option.key}</span></label>
                    </div>
                  </React.Fragment>
                )
              })
            }
          }
        </FastField>
      </div>
    </>
    
  )
}


export const TitleErrorMarker = (props: {hasError: boolean, text: string}) => {
  if(props.hasError){
    return <p>
      <ExclamationCircleFilled style={{color: 'red'}} /> {props.text}
    </p>
  }

  return <p>{props.text}</p>
}