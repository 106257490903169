import { Form, Row, Col, Select, Button } from 'antd'
import { useState, useRef } from 'react'
import '../../styles/ReturnOrderForm.css'
import { useAuth } from '../../../auth/services/authContext'
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import { useSingleOrderB2C, OrderErrorB2C } from "../../services/returnSingleOrderContext";
import { PickUpAddressModal as AddAddressModal } from "../../../UserSettings/components/PickUpAddressModal";
import { useQuery } from 'react-query';
import { getLocationsByCountry_from_b2c, getStates_from_b2c, getCities_from_b2c, geReturntLocationsByCountry_from_b2c } from '../../../orders/services/privateApi';
import { countryCodes, countryCallingCodes } from '../../../commons/utils/constants';
import { validatePostalCode } from '../../../commons/utils/validator';
import {FormInput, FormSelect, TitleErrorMarker} from '../../../orders/components/Forms';
import { parseErrors } from '../../../../src/orders/helpers';
import {sortResponseAlphabatically} from "../../../commons/utils/utilizer" 
import { LabelRadio } from '../../../ReUsableComponents/ReUsable'

interface PropsType{
  current: number,
  setCurrentState: (val: number) => void,
  dataFilled?: number
}

const validationSchema = () => {
  return Yup.object().shape({
    consignee_name: Yup.string().required('Name is required'),
    consignee_number: Yup.number().required('Phone number is required'),
    consignee_email: Yup.string().email('Please enter in abc@email.com format').required('Email is required'),
    consignee_address: Yup.string().required('Address is required'),
    consignee_country: Yup.string().required('Country is required'),
    consignee_state: Yup.string().required('state is required'),
    consignee_city: Yup.string().required('city is required'),
    consignee_postal: Yup.string().required('Postal code is required').test(
      'postal-check',
      (val: any, context: any) => {
        if(!val){
          return context.createError({
            path: context.path,
            message: 'Please enter Postal Code'
          })
        }
        else{
          const error = validatePostalCode(countryCodes[context.parent.consignee_country], val.toString());
          if(error){
            return context.createError({
              path: context.path,
              message: error
            })
          }
        }
        return true
      }
    ),
  })
}

const parseOrderContextError = (error: OrderErrorB2C) => {
  return {
    consignee_name: error.consignee_name,
    consignee_number: error.consignee_number,
    consignee_email: error.consignee_email,
    consignee_address: error.consignee_address,
    consignee_country: error.consignee_country,
    consignee_state: error.consignee_state,
    consignee_city: error.consignee_city,
    consignee_postal: error.consignee_postal,
    identification_document_name: error.identification_document_name,
    consignee_identifcation_number: error.consignee_identifcation_number
  }
}

const {Option, OptGroup} = Select;
export const ReceiverDetailsForm = (props: PropsType) => {
  const {current, setCurrentState} = props
  const auth = useAuth();
  const singleOrderContext = useSingleOrderB2C();
  const ShipmentDetailsFormRef: any = useRef();

  const [showAddAddressModal, setShowAddAddressModal] = useState<boolean>(false);
  const [state, setState] = useState<string>(singleOrderContext.singleOrder.consignee_state ? singleOrderContext.singleOrder.consignee_state : '');
  const [freeText, setfreeText] = useState<string>('dropdown');
  const [addressType, setAddressType] = useState<string>('');

  const pickupLocationsFromb2c = useQuery('pickupLocationsFromb2c', () => {
    return getLocationsByCountry_from_b2c(auth.user.secret_key, singleOrderContext.singleOrder.consignee_country)
  })

  const returnLocationsFromb2c = useQuery('returnLocationsFromb2c', () => {
    return geReturntLocationsByCountry_from_b2c(auth.user.secret_key, singleOrderContext.singleOrder.consignee_country)
  })

  const statesListFromb2c = useQuery(['statesListFromb2c', singleOrderContext.singleOrder.consignee_country], () => {
    if(singleOrderContext.singleOrder.consignee_country!){
      return getStates_from_b2c(singleOrderContext.singleOrder.consignee_country!)
    }
  });

  const cityListFromb2c = useQuery(['cityListFromb2c', state], () => {
    if(state){
      return getCities_from_b2c(singleOrderContext.singleOrder.consignee_country!, state)
    }
  });

  const onConsigneeAddressSelect = (value:any, formik:any) => {
    let [objId, type] = value.split("-")
    let selectedAddress:any = type === 'pickup' ? pickupLocationsFromb2c.data?.filter(address => {
      return  address.pickup_point_id === parseInt(objId)}) : returnLocationsFromb2c.data?.filter(address => {
      return  address.id === parseInt(objId)})
    if(type === 'pickup') {
      formik.setFieldValue('consignee_name',selectedAddress[0].pickup_point_name || '')
      formik.setFieldValue('consignee_number',selectedAddress[0].pickup_point_number || '')
      formik.setFieldValue('consignee_email',selectedAddress[0].pickup_point_email || '')
      formik.setFieldValue('consignee_address',selectedAddress[0].pickup_point_address || '')
      formik.setFieldValue('consignee_state',selectedAddress[0].pickup_point_state || '')
      formik.setFieldValue('consignee_city',selectedAddress[0].pickup_point_city || '')
      formik.setFieldValue('consignee_postal',selectedAddress[0].pickup_point_postal || '')
      // formik.setFieldValue('consignee_id',selectedAddress[0].consignee_id || '')
      // formik.setFieldValue('consignee_id_type',selectedAddress[0].consignee_id_type || '')
    } else {
      formik.setFieldValue('consignee_name',selectedAddress[0].contact_name || '')
      formik.setFieldValue('consignee_number',selectedAddress[0].contact_number || '')
      formik.setFieldValue('consignee_email',selectedAddress[0].contact_email || '')
      formik.setFieldValue('consignee_address',selectedAddress[0].address || '')
      formik.setFieldValue('consignee_state',selectedAddress[0].state || '')
      formik.setFieldValue('consignee_city',selectedAddress[0].city || '')
      formik.setFieldValue('consignee_postal',selectedAddress[0].postal || '')
      // formik.setFieldValue('consignee_id',selectedAddress[0].consignee_id || '')
      // formik.setFieldValue('consignee_id_type',selectedAddress[0].consignee_id_type || '')
    }
    

    if(selectedAddress.length > 0) {
      if(type === 'pickup') {
        singleOrderContext.updateSingleOrderData({
          consignee_number: selectedAddress[0].pickup_point_number.startsWith('+') ? selectedAddress[0].pickup_point_number : `${countryCallingCodes[countryCodes[singleOrderContext.singleOrder.consignee_country!]]} ${selectedAddress[0].pickup_point_number}`,
        })
      }else {
        singleOrderContext.updateSingleOrderData({
          consignee_number: selectedAddress[0].contact_number.startsWith('+') ? selectedAddress[0].contact_number : `${countryCallingCodes[countryCodes[singleOrderContext.singleOrder.consignee_country!]]} ${selectedAddress[0].contact_number}`,
        })
      }
    }
  }

  const initialValues={
    consignee_name: singleOrderContext.singleOrder.consignee_name,
    consignee_number: singleOrderContext.singleOrder.consignee_number,
    consignee_email: singleOrderContext.singleOrder.consignee_email ? singleOrderContext.singleOrder.consignee_email : '',
    consignee_address: singleOrderContext.singleOrder.consignee_address,
    consignee_country: singleOrderContext.singleOrder.consignee_country!,
    consignee_state: singleOrderContext.singleOrder.consignee_country.toLowerCase() === 'singapore'?
      singleOrderContext.singleOrder.consignee_country :
      singleOrderContext.singleOrder.consignee_state,
    consignee_city: singleOrderContext.singleOrder.consignee_country.toLowerCase() === 'singapore'?
      singleOrderContext.singleOrder.consignee_country :
      singleOrderContext.singleOrder.consignee_city,
    consignee_postal: singleOrderContext.singleOrder.consignee_postal,
    pickup_address: singleOrderContext.singleOrder.consignee_address,
  }
  const {initialErrors, initialTouched, hasError, emptyError} = parseErrors(singleOrderContext.errors, parseOrderContextError)
  // if(hasError){
  //   if(current > 1){
  //     setCurrentState(1);
  //   }
  // }
  const onSubmit = (values: any) => {
    singleOrderContext.updateSingleOrderData({
      consignee_name: values.consignee_name,
      consignee_number: values.consignee_number.startsWith('+') ? values.consignee_number : `${countryCallingCodes[countryCodes[singleOrderContext.singleOrder.consignee_country!]]} ${values.consignee_number}`,
      consignee_email: values.consignee_email,
      consignee_address: values.consignee_address,
      consignee_country: values.consignee_country,
      consignee_state: values.consignee_state,
      consignee_city: values.consignee_city,
      consignee_postal: values.consignee_postal,
    })
    setCurrentState(3)

    singleOrderContext.setErrors({...emptyError})
  }

  const setShowAddAddressModalCallback = (val: boolean) => {
    setShowAddAddressModal(val);
    setAddressType('');
    pickupLocationsFromb2c.refetch()
    returnLocationsFromb2c.refetch()
  };

  const setCreatedLocationIdCallback = (location: any) => {
    if (ShipmentDetailsFormRef.current) {    
      ShipmentDetailsFormRef.current.setFieldValue('pickup_address', `${location.id}-return`)
      ShipmentDetailsFormRef.current.setFieldValue('consignee_name', location.contact_name || '')
      ShipmentDetailsFormRef.current.setFieldValue('consignee_number', location.contact_number || '')
      ShipmentDetailsFormRef.current.setFieldValue('consignee_email', location.contact_email || '')
      ShipmentDetailsFormRef.current.setFieldValue('consignee_address', location.address || '')
      ShipmentDetailsFormRef.current.setFieldValue('consignee_state', location.state || '')
      ShipmentDetailsFormRef.current.setFieldValue('consignee_city', location.city || '')
      ShipmentDetailsFormRef.current.setFieldValue('consignee_postal', location.postal || '')
      // ShipmentDetailsFormRef.current.setFieldValue('pickup_address', location.id)
    }
  }

  const customerAddress = <>
    <p style={{marginBottom: '0px'}}>{singleOrderContext.singleOrder.consignee_name}, {singleOrderContext.singleOrder.consignee_number}</p>
    <p>{singleOrderContext.singleOrder.consignee_address}, {singleOrderContext.singleOrder.consignee_postal}, {singleOrderContext.singleOrder.consignee_city}, {singleOrderContext.singleOrder.consignee_state}</p>
  </>;
    
  if(current > 2){
    return (
      <div className='return-order-form-container'>               
        <Row>
          <Col span={24} className={'mb-10'}>
            <div style={{fontWeight: 'bold'}}><TitleErrorMarker text={'Receiver Overview'} hasError={hasError} /></div>
          </Col>                     
          <Col span={24}>
            <p style={{color: '#808080', marginBottom: '0px'}}>Receiver Details</p>
            <p>{customerAddress}</p>
          </Col>                    
        </Row>                
      </div>
    )
  }

  const setFreeTextCallback = (val:string) => {
    setfreeText(val);
  };

  return (
    <div className='return-order-form-container ant-form-vertical' ref={r => { ShipmentDetailsFormRef.current = ShipmentDetailsFormRef.current ? ShipmentDetailsFormRef.current : r; }}>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        initialErrors={initialErrors}
        initialTouched={initialTouched}
        validationSchema={validationSchema}
        validateOnChange={true}
        innerRef={ShipmentDetailsFormRef}
      >
        {formik => (
          <FormikForm>
            <Form.Item label={<strong><TitleErrorMarker text={'Receiver Details'} hasError={hasError} /></strong>} style={{marginBottom: '-25px'}} />
            <Row gutter={20}>
              <Col span={12} style={{marginBottom: '35px'}}>
                <FormSelect 
                  name="pickup_address" 
                  label={"Saved Addresses"} 
                  placeholder={'Select address'}
                  wrapperClass="no-margin-item"
                  onChangeCallback={(value: any) => {
                    onConsigneeAddressSelect(value, formik);
                  }}
                  defaultValue={initialValues.pickup_address}
                >
                  <OptGroup label="Return Points" key="returns">
                    {
                      returnLocationsFromb2c.data?.map(item => <Option key={item.id} value={`${item.id}-return`}>{`${item.return_point_name} - ${item.address}`}</Option>)
                    }
                  </OptGroup>
                  <OptGroup label="Pickup Points" key="pickup">
                    {
                      pickupLocationsFromb2c.data?.map(item => <Option key={item.pickup_point_id} value={`${item.pickup_point_id}-pickup`}>{`${item.pickup_point_name} - ${item.pickup_point_address}`}</Option>)
                    }
                  </OptGroup>
                </FormSelect>
                <Form.Item className={'no-margin-item'}>
                  <Button type="primary" onClick={() => {
                    setShowAddAddressModal(true);
                    setAddressType('pickup')
                  }} className={"no-border-btn"}>
                    {'Add new address'}
                  </Button>
                  {(showAddAddressModal && addressType === 'pickup') &&
                  <AddAddressModal
                    setShowAddAddressModalCallback={setShowAddAddressModalCallback}
                    setCreatedLocationIdCallbackForReturns={setCreatedLocationIdCallback}
                    countryCode={singleOrderContext.singleOrder.consignee_country!}
                    addressType={'return'}
                  />}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={20}>
              <Col span={12}>
                <FormInput 
                  required
                  name="consignee_name" 
                  label={'Full Name'}
                  placeholder='Name'
                />
              </Col>
              <Col span={12}>
                <FormInput 
                  required
                  name="consignee_number" 
                  label={'Phone Number'}
                  prefix={
                    singleOrderContext.singleOrder.consignee_number!.startsWith('+') ? null : countryCallingCodes[singleOrderContext.singleOrder.consignee_country!]
                  }
                  placeholder='12345678'
                />
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={24}>
                <FormInput 
                  required
                  name="consignee_email" 
                  label={'Email'}
                  placeholder='Please enter a valid email'
                />
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={24}>
                <FormInput 
                  required
                  name="consignee_address" 
                  label={'Address'}
                  placeholder='Address'
                />
              </Col>
            </Row>    
            <Row gutter={20}>
              <Col span={12}>
                <FormSelect name="consignee_country" required label={'Country'} wrapperClass="no-margin-item" placeholder={'Select a country'} disabled={true}>
                  <Option value={formik.values.consignee_country}>{formik.values.consignee_country}</Option>
                </FormSelect>
              </Col>
              <Col span={12}>
                <FormSelect required label={'State'} name="consignee_state" loading={statesListFromb2c.isLoading} onChangeCallback={(state:string) => {
                  setState(state);
                  formik.setFieldValue('consignee_city', '');
                }}>
                  {
                    (sortResponseAlphabatically(statesListFromb2c?.data, 'state_name') || []).map((item: {id: number, state_name:string} ) => {
                      return  <Option key={item.id} value={item.state_name}>{item.state_name}</Option>
                    })
                  }
                </FormSelect>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={12}>
                {
                  formik.values.consignee_country === 'US' ?
                    freeText === 'dropdown' ? 
                      <>
                        <FormSelect label={<LabelRadio setFreeTextCallback={setFreeTextCallback} freeText={freeText} />}
                          notFoundContent={<p style={{marginBottom: '0px', color: '#525050'}}>City not found. Please select Other</p>} name="consignee_city" loading={cityListFromb2c.isLoading}
                          placeholder={'Select a city'}>
                          {
                            (sortResponseAlphabatically(cityListFromb2c?.data, 'city_name') || [])
                              .map((item: {id:number, city_name: string} ) => (
                                <Option key={item.id} value={item.city_name}>{item.city_name}</Option>
                              ))
                          }
                        </FormSelect>
                        {cityListFromb2c.isError && <p style={{color:'red', marginBottom:0}}>Failed to fetch cities, try later</p>}
                      </>
                      : 
                      <FormInput placeholder='Please type in the city' label={<LabelRadio setFreeTextCallback={setFreeTextCallback} freeText={freeText} />} name="consignee_city" /> : 
                    <FormSelect label={'City'} name="consignee_city" loading={cityListFromb2c.isLoading}>
                      {
                        (sortResponseAlphabatically(cityListFromb2c?.data, 'city_name') || [])
                          .map((item: {id:number, city_name: string} ) => (
                            <Option key={item.id} value={item.city_name}>{item.city_name}</Option>
                          ))
                      }
                    </FormSelect>
                }
              </Col>
              <Col span={12} style={{marginTop: singleOrderContext.singleOrder.consignee_country! === 'US' ? '35px' : '0px'}}>
                <FormInput 
                  required
                  name="consignee_postal" 
                  label={'Postal Code'}
                  placeholder='Please enter a valid postal code'
                />
              </Col>
            </Row>
            <Row justify='end'>
              <Button htmlType='submit' type='primary'>{'Save & Continue'}</Button>
            </Row>
          </FormikForm>
        )}    
      </Formik>
    </div>
  )

}

export const ReceiverDetailsFormTitle = (props: PropsType) => {
  const {current, setCurrentState} = props;
  return (
    <div className={"title-block"}>
      <p className={'step-title'}>Where are we delivering to?</p>
      {current>2 ? <p onClick={()=> setCurrentState(2)} className={"edit-text"}>{'Edit details'}</p>:''}
    </div>
  )
}