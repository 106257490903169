import {
  // Card,
  // Row,
  // Col,
  // Button,
  // Spin, 
  Alert
} from 'antd'
import {CLAIM_SOP} from '../Support/QuestionData'
import {ShopifyAccountsToken} from '../commons/utils/constants'
import {useAuth} from "../auth/services/authContext";

const GstFormLink = 'https://docs.google.com/forms/d/e/1FAIpQLSfBZd2cbHbh-EG5Q2pVQxq9tIIAzFHuhJR-X1FvXb6UbVVMvA/viewform?edit_requested=true#responses'

const GstPdfLink = 'https://4410524.fs1.hubspotusercontent-na1.net/hubfs/4410524/Customs%20Changes%20PDFs%20and%20Files/eCommerce%20Customs%20Clearance%20-%202023%20Changes%20to%20Singapore%E2%80%99s%20GST%20for%20Low%20Value%20Goods%20(Pdf)%20(final).pdf'

export const ClaimRelatedAlert = () =>  {
  return (
    <Alert 
      message={<span>Please note that from <strong>12th December 2022</strong>, there are new updates to the standard operating procedure related to claims for missing orders, damaged orders and invoice disputes. Further information can be found <a style={{fontSize: "16px", fontWeight: 'bold', textDecoration: 'underline'}} href={CLAIM_SOP} target="_blank" rel='noopener noreferrer'>here </a>.</span>} 
      type="info" 
      className='disclaimer-claims-related mb-10'
      showIcon 
      closable 
    />
  )
};

export const NewGstOvrRelatedAlert = () =>  {
  return (
    <Alert 
      message={
        <>
          <p><strong>Ship from abroad to Singapore?</strong></p>
          <p>
            New Singapore GST rules: Please provide your Singapore GST registration number/ OVR number and other details <a style={{fontSize: "16px", fontWeight: 'bold', textDecoration: 'underline'}} href={GstFormLink} target="_blank" rel='noopener noreferrer'>here </a>
          </p>

          <p>
            To check if you are liable for the new GST rules, please click <a style={{fontSize: "16px", fontWeight: 'bold', textDecoration: 'underline'}} href={GstPdfLink} target="_blank" rel='noopener noreferrer'>here </a>
          </p>
        </>
      } 
      type="info" 
      className='gst-related'
      showIcon 
      closable 
    />
  )
};

export const PrePaidServiceClosureAlert = () =>  {
  const easyPArcelLink = 'https://easyparcel.com/my/?utm_campaign=Service%20EDMs&utm_medium=email&_hsmi=251081[…]IoEtORZj8Y38uT8hzdA&utm_content=251081601&utm_source=hs_email'

  const easyShipLink = 'https://www.easyship.com/en-sg/couriers/shipping-janio-express?gclid=Cj0KCQjwn9CgBhDj[…]30NMIc5DrlGRhiq3iQ&utm_content=251081601&utm_source=hs_email'
  return (
    <Alert 
      message={
        <>
          <p>Starting <strong>April 1st, 2023,</strong> our <strong>Prepaid services will be closing,</strong> and you won't be able to create any new shipments through the portal. During that same period, from <strong>April 1st to April 14th,</strong> we'll get your stored wallet value refunded. If you wish to continue using our services, you can find us on delivery resellers such as <a href={easyPArcelLink} target="_blank" rel='noopener noreferrer'> EasyParcel</a> and <a href={easyShipLink} target="_blank" rel='noopener noreferrer'> Easyship</a>.</p>
        </>
      } 
      type="warning" 
      className='prepaid-closure-related'
      showIcon 
    />
  )
};

export const NewShopifyChangesAlert = () =>  {
  const auth = useAuth()
  const docLink = 'https://docs.google.com/document/d/e/2PACX-1vT-WGyIhjoyhNi6m_-kb7qF2HMtr-SV2Ak_Dejz-1ct9JrhExTIStzB4vXwf8sSbUqN-SJRhgkRffSA/pub'
  if(ShopifyAccountsToken.includes(auth.user.authToken)) {
    return (
      <Alert 
        message={
          <>
            <p>From 1st July 2023 onwards, we will adhere to the new Fulfillment workflow in Shopify. This will affect clients who currently use Auto-Fulfilment from Janio to Shopify. Click <a href={docLink} target="_blank" rel='noopener noreferrer'> here</a> for more details.</p>
          </>
        } 
        type="warning" 
        className='prepaid-closure-related mb-10'
        showIcon
      />
    )
  }
  return null
  
};
