import { Card, Col, Row, Steps } from "antd"
import { useState } from "react";
import { UploadOrderForm, UploadOrderFormTitle } from "./components/bulkOrder/UploadOrderForm";
import { ReceiverDetailsForm, ReceiverDetailsFormTitle } from "./components/bulkOrder/ReceiverDetailsForm"
import { Link } from "react-router-dom";
import { CaretLeftOutlined } from "@ant-design/icons";
import { CSVTemplateAlert } from "./components/bulkOrder/CSVTemplateAlert";
import { BulkOrderContextProvider } from './services/returnBulkOrderContext'
import './styles/ReturnOrderForm.css';
import { OrderSubmission, OrderSubmissionTitle } from "../orders/components/bulkOrder/OrderSubmission";

const {Step} = Steps;
const OrderForm = () => {
  const [current, setCurrent] = useState(0);
  const setCurrentState = (val: number) => {
    setCurrent(val);
  };

  const steps = [
    {
      title: <UploadOrderFormTitle current={current} setCurrentState={setCurrentState} dataFilled={0}/>,
      content: <UploadOrderForm current={current} setCurrentState={setCurrentState} dataFilled={0}/>,
    },
    {
      title: <ReceiverDetailsFormTitle current={current} setCurrentState={setCurrentState} dataFilled={0}/>,
      content: <ReceiverDetailsForm current={current} setCurrentState={setCurrentState} dataFilled={0}/>,
    },
    {
      title: <OrderSubmissionTitle current={current} setCurrentState={setCurrentState}/>,
      content: <OrderSubmission current={current} setCurrentState={setCurrentState} orderType={'return'}/>,
    }
  ];

  return (
    <>
      <Steps direction='vertical'  current={current}>
        {steps.map((item, index) => (
          <Step title= {item.title} key= {index} 
            description = {current>=index? item.content : ''} />
        ))}
      </Steps>
    </>
  )
}

export const ReturnBulkOrder = () => {
  return (
    <Row align='middle' justify='center'>
      <Col span={16} className={'order-form-container'}>
        <Link to={"/orders/create"}>
          <p className="go-back-btn"><CaretLeftOutlined/> Create shipment </p>
        </Link>
        <Card className={'form-card'}>
          <div style={{marginTop:-15, marginBottom:15}}>
            <CSVTemplateAlert/>
          </div>
          <BulkOrderContextProvider>
            <OrderForm/>
          </BulkOrderContextProvider>
                
        </Card>   
      </Col>
    </Row>
  )
}