import { Form, Button, Row, Col, Checkbox, message } from 'antd';
import {useState} from 'react';
import {useMutation} from "react-query";
import { useAuth } from '../../../auth/services/authContext';
import '../../styles/SingleOrderForm.css';
import {useSingleOrderB2C} from "../../services/singleOrderContext";
import { useLanguage } from '../../../languages/Language';
import { useHistory } from 'react-router-dom';
import { createMonthlyBillingShopifyOrder_in_b2c } from '../../services/privateApi';

interface PropsType {
  current: number,
  setCurrentState: (val:number)=> void,
  isEditing?:boolean
  isMarketplaceOrder?: boolean
  showCard?: boolean
}

export const CreateShopifyOrder = (props:PropsType) =>{
  let { getText } = useLanguage();
  let history = useHistory()
  const auth = useAuth();
  const [checked, setChecked] = useState(false);
  const {current} = props;
  const singleOrderContext = useSingleOrderB2C();
  // const authToken = auth.user.authToken;

  // Check and set whether the pickup fee exists in order response
  // let pickup_charge = () => {
  //   if (singleOrderContext.singleOrder.account_charges && singleOrderContext.singleOrder.account_charges!.length > 0) {
  //     return parseFloat(singleOrderContext.singleOrder.account_charges![0].amount)
  //   }
  //   if(singleOrderContext.singleOrder.pickup_charge) {
  //     return parseFloat(singleOrderContext.singleOrder.pickup_charge)
  //   }
  //   return null
  // }

  const createShopifyOrderInB2C:any = useMutation(async () => {
    let context = {
      draft_order_ids:[singleOrderContext.singleOrder.id],
      secret_key: auth.user.secret_key
    }
    return createMonthlyBillingShopifyOrder_in_b2c(context);
  },  {
    onSuccess: (val: any) => {
      return (
        message.success('Order Created Succesfully', 10),
        history.push('/orders?status=all')
      )
    },
    onError: (error: { response: { data: { orders: any } } }) => {
      let errorMessage: any;
      let errObject = error.response.data.orders ? error.response.data.orders : error.response.data;
      if(errObject) {
        try{
          errorMessage = Object.values(errObject).map((item: any) => {
            if(typeof(item) == 'string'){
              return (JSON.stringify(item))
            }
            return (              
              Object.entries(item).map((y: any) => {
                return `${y[0]}: ${y[1]}`
              })           
            )
          })
          const displayedMessage = errorMessage.join()
          message.error({
            content: <>{displayedMessage}</>,
            duration: 15,
            className: 'final-order-creation-error'
          })
          
        }catch(e){
          message.error({
            content: <>
              <span>We are not able to process your request right now, please reach out to us at  
                <a href={"mailto:clientsupport@janio.asia"}>clientsupport@janio.asia</a> for further help
              </span></>,
            duration: 15,
          })
        }
        
      }else{
        message.error({
          content: <>
            <span>We are not able to process your request right now, please reach out to us at  
              <a href={"mailto:clientsupport@janio.asia"}>clientsupport@janio.asia</a> for further help
            </span></>,
          duration: 15,
        })
      }
    },
  });

  if(props.showCard === false) return null
  if(current !== 3) return null

  // const orderIsStillValid = () => {
  //   // currently only checks for pickup date
  //   // should add more in the future, and consider more messages
  //   if(pickupDateHasExpired(singleOrderContext.singleOrder.pickup_date)){
  //     return false
  //   }

  //   return true
  // }

  const handleMonthlyBilling = () => {
    createShopifyOrderInB2C.mutate()
  }
  return (
    // (singleOrderContext.singleOrder.payment_status !== 'PAID' && singleOrderContext.singleOrder.payment_status !== 'MONTHLY_BILLING_PAYMENT_PENDING') && singleOrderContext.singleOrder.amount_to_pay > 0 ?
    <Form
      layout={'vertical'}
      requiredMark={false}
    >
      <div className="single-order-form-container price-form">
        <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)}>
          {getText('I agree to pay')} <b>{getText('additional courier charges')}</b> {getText('in case shipment details (weight, dimensions, item category, pickup and destination address) are found to be inaccurate.')}
        </Checkbox>
        {!checked ? <Col style={{color:"#ff4d4f"}}>{getText('Please accept agreement to proceed.')}</Col>: ''}
        <div style={{marginTop: '20px'}}>
          <Button type="primary"
            className="blue-button"
            onClick={handleMonthlyBilling}
            loading={createShopifyOrderInB2C.isLoading}
            disabled={(!checked)}>
            {getText('Create Shipment')}
          </Button>
        </div>
        <Row align='bottom' style={{textAlign: 'right', margin: '15px -15px -18px 0', color: 'rgb(255, 77, 79)', fontSize: '15px'}}><Col span={24}>*Prices shown does not include GST/VAT/D&T</Col></Row>
        
      </div>
    </Form>
    // :
    // <div className="single-order-form-container">
    //   <div className={"title-block"}>
    //     <div>{getText('Order Already Paid')}</div>
    //   </div>
    // </div>
  )
};
export const CreateShopifyOrderTitle = (props:PropsType) =>{
  const auth = useAuth();
  let { getText } = useLanguage();
  const {current, setCurrentState} = props;
  return (
    <div className={"title-block"}>
      <p className={'step-title'}>{auth.user.hide_price_estimation ? 'Create Order' : getText('Create Shipment')}</p>
      {current>3 ? <p onClick={()=> setCurrentState(3)} className={"edit-text"}>{getText('Edit details')}</p>:''}
    </div>
  )
};