import { PrivateLayout } from './commons/PrivateRoute'
import { AuthLayout } from './commons/AuthRoute'
import {ApiDocsLayout} from './commons/ApiDocsRoute'
import { ProvideAuth } from './auth/services/authContext'
import { QueryClient, QueryClientProvider } from 'react-query'
import {DetectPlatformPage} from './Integrations/DetectPlatform.Page';

import { LanguageProvider } from './languages/Language';

import './App.less'

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { OtpVerification } from './SmsCampaignForReturn/OtpVerification'
import { ClientConfirmation } from './SmsCampaignForReturn/ClientConfirmation'
import { SuccessPage } from './SmsCampaignForReturn/SuccessPage'
import { IntegrationAuthCallback } from './Integrations/IntegrationAuthCallback.page'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})


function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ProvideAuth>
        <Router>
          <LanguageProvider>
            <Switch>
              <Route path="/rts/verification/:tracking_number">
                <OtpVerification />
              </Route>
              <Route path="/rts/confirmation/:tracking_number">
                <ClientConfirmation />
              </Route>
              <Route path="/rts/success">
                <SuccessPage />
              </Route>
              <Route path="/apidocs">
                <ApiDocsLayout />
              </Route>
              <Route path={`/integration`}>
                <IntegrationAuthCallback />
              </Route>
              <Route path={`/detect-platform`}>
                <DetectPlatformPage />
              </Route>
              <Route path="/auth">
                <AuthLayout />
              </Route>
              <Route path="">
                <PrivateLayout />
              </Route>
            </Switch>
          </LanguageProvider>
        </Router>
      </ProvideAuth>
    </QueryClientProvider>
  )
}

export default App;
