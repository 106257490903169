import {Alert} from 'antd'
import React from "react";
import {useAuth} from "../../../auth/services/authContext";

export const PickupThresholdAlert = () => {
  const auth = useAuth();
  let billingCountry = auth.user.billingCountry;

  if(billingCountry === 'SG'){
    return (
      <Alert type="info" className='threshhold-alert' showIcon closable message={
        <span style={{fontSize:'15px',color:'black'}}>
          {"Janio will re-evaluate the pickup attempt fee the next working day if you successfully handed over at least 5 shipments on your scheduled pickup date"}
        </span>
      }
      />
    )
  }
  return <></>
}
