import { Row, Col, Tabs, Spin} from 'antd';
import { useEffect, useState } from 'react';
import { useQuery } from "react-query";
import {useAuth} from "../../auth/services/authContext";
import { getIntegratedShipmentDataFromB2C, getOrderSummaryCount_from_b2c } from "../../ManageShipments/services";
import { useHistory } from 'react-router-dom';
import { NotReadyToShip } from './NotReadyToShip'
import { ReadyToShip } from './ReadyToShip'
import ShopifyFilters from './ShopifyFilters';
import { NewShopifyChangesAlert } from '../../dashboard/MessagesOnHomepage';

const { TabPane } = Tabs;

export const IntegratedOrders = (props:any) => {
  const auth = useAuth();
  let history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const result = urlParams.get('result');
  let defaultActiveKeyURL = history.location.search.split('=')[1]
  if(result){
    defaultActiveKeyURL= 'ready'
  }
  const [shipStatus, setStatus] = useState(defaultActiveKeyURL ? defaultActiveKeyURL : 'notReady');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState<number | string>('');
  const [country, setCountry] = useState<null | string>('')
  const [refetchQuery, setRefetchQuery] = useState<boolean>(false)

  const fetchShipmentData = useQuery(['fetchShipmentData', shipStatus, pageSize, page, searchQuery, country
  ], async () => {
    return shipStatus !== 'notReady' ? getIntegratedShipmentDataFromB2C(auth.user.secret_key, {
      "status": 'READY_TO_SHIP',
      "page_size": pageSize,
      "page": page,
      "shipper_order_ids": searchQuery,
      "consignee_countries": country
    }) :
    // getShipmentData(authToken, {
    //   "ship_status": 'ready_to_ship',
    //   "page_size": pageSize,
    //   "page": page,
    //   "searchQuery": searchQuery,
    //   "consignee_countries": country
    // })
      getIntegratedShipmentDataFromB2C(auth.user.secret_key, {
        "status": 'ERROR,NEW',
        "page_size": pageSize,
        "page": page,
        "shipper_order_ids": searchQuery,
        "consignee_countries": country
      })
      // getShipmentData(authToken, {
      //   "page_size": pageSize,
      //   "page": page,
      //   "searchQuery": searchQuery,
      //   "consignee_countries": country 
      // })
  }, {cacheTime: 0})

  // const getReadyToShipDataCount = useQuery(['fetchReadyToShipCount'], async() => {
  //   return getShipmentData(authToken, {"ship_status": 'ready_to_ship'})
  // })
  // const getNotReadyToShipDataCount = useQuery(['fetchNotReadyToShipCount'], async() => {
  //   return getShipmentData(authToken, {})
  // })

  const summaryFromb2c = useQuery('ordersSummaryb2c', () =>{
    return getOrderSummaryCount_from_b2c(auth.user.secret_key)
  }, {cacheTime: 0});

  useEffect(() => {
    if(refetchQuery) {
      summaryFromb2c.refetch()
      fetchShipmentData.refetch()
    }
  }, [refetchQuery]) //eslint-disable-line react-hooks/exhaustive-deps

  const SetCommonStates = (key:any) => {
    return (
      setPageSize(10),
      setPage(1),
      setSearchQuery(''),
      setStatus(key)
    )
  }

  const callback = (key: any) => {
    window.history.replaceState(null, '', `?status=${key}`)
    summaryFromb2c.refetch()
    if (key === 'notReady') {
      SetCommonStates(key)
    }
    if (key === 'ready') {
      SetCommonStates(key)
    }
  }

  const hasData = () => {
    if(
      (shipStatus === 'notReady' && summaryFromb2c.data?.to_ship === 0)
      || (shipStatus === 'ready' && summaryFromb2c.data?.ready_to_ship === 0)
    ) {
      return true
    }
    return false
  }

  const resetCommonSearch = () => {
    setSearchQuery('')
  }
  return (
    <>
      <NewShopifyChangesAlert />
      <h1 className="ant-page-header-heading-title page-title">
        {"Integration Shipments"}
      </h1>
      <Row className={'main-container integrated-shipments-wrapper'} style={{'position': 'relative'}}>
        <Col span="24">
          <Row className='integrated-shipments-row-wrap' gutter={8}>
            <Col>
              {!hasData() && <ShopifyFilters 
                country={country} 
                setCountry={setCountry} 
                status={shipStatus} 
                setSearchQuery={setSearchQuery} 
                setStatus={setStatus} 
                searchQuery={searchQuery}
                resetCommonSearch={resetCommonSearch} 
              />}
            </Col>
          </Row>
          <Tabs defaultActiveKey={defaultActiveKeyURL} onChange={callback} 
            // className={!hasData() ? '' : 'no-data-table-handler'}
          >
            <TabPane 
              tab={
                <label style={{cursor: 'pointer'}}>
                  {'To Ship'}
                  ({summaryFromb2c.isLoading ? <Spin size="small"/> : summaryFromb2c.data?.to_ship || 0})
                </label>}
              key="notReady"
            >
              <NotReadyToShip 
                data={fetchShipmentData.data!}
                setPageSize={setPageSize}
                setPage={setPage}
                orderStatus={"notReady"}
                isLoading={fetchShipmentData.isLoading}
              />
            </TabPane>

            <TabPane 
              tab={
                <label style={{cursor: 'pointer'}}>
                  {'Ready to Ship'}
                  ({summaryFromb2c.isLoading ? <Spin size="small"/> : summaryFromb2c.data?.ready_to_ship || 0})
                </label>}
              key="ready"
              // style={{marginBottom : '-46px !important'}}
            >
              <ReadyToShip 
                data={fetchShipmentData.data!}
                setPageSize={setPageSize}
                setPage={setPage}
                orderStatus={"ready"}
                isLoading={fetchShipmentData.isLoading}
                walletRechargeStatus={result}
                refetchQuery={refetchQuery}
                setRefetchQuery={setRefetchQuery}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </>
  )
};