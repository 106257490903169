import { Row,Col, Tabs } from 'antd';
// import AllOrders from './ShipmentStatusType/AllOrders';
// import PendingPickup from './ShipmentStatusType/PendingPickup';
// import CommonTable from './ShipmentStatusType/CommonTable';

// import PrintLabelHeader from './ShipmentStatusType/PrintLabelHeader';
// import PrintPodHeader from './ShipmentStatusType/PrintPodHeader';
import './styles/ManageShipments.css'
// import { getOrderData, getOrderSummaryCount_from_b2c } from "./services";
// import {OrderDetailData_from_b2c} from './services';
import { useAuth } from './../auth/services/authContext';
// import { useQuery } from "react-query";
// import { 
//   // useEffect, 
//   useState } from 'react';
import { useHistory} from 'react-router-dom';
// import {UserViewedManageShipments} from "../commons/mixpanel"
// import { useLanguage } from '../languages/Language';
// import Filters from './Components/Filters';
import moment from 'moment';
// import ShowBothBulkDownloadHeaders from './ShipmentStatusType/ShowBothBulkDownloadHeaders';

/* eslint-disable @typescript-eslint/no-unused-vars */
import HoldOrder from './ShipmentStatusType/HoldOrder'; 
import { getExceptionListQuery } from '../orders/services/privateApi';
import DummyDataTable from './ShipmentStatusType/DummyDataTable';
import FreightFilters from './Components/FreightFilters';
import { useState } from 'react';
/* eslint-enable @typescript-eslint/no-unused-vars */

const { TabPane } = Tabs;
// interface state {
//  orders: Array<OrderDetailData_from_b2c>;
//  orderStatus: string;
// }
export const formatDateDefaultFn = (type: string) => {
  if(type === 'start') {
    return  moment().subtract(3, 'months').startOf('day').format()
  } else {
    return moment().endOf('day').format();
  }
}
export const ManageShipmentsContainer = (props:any) => {
  // const isProduction = window.location.hostname.includes('ss.janio.asia')
  // let { getText } = useLanguage();
  let history = useHistory();
  let defaultActiveKeyURL = history.location.search.split('=')[1]
  const auth = useAuth();

  // const [page, setPage] = useState(1);
  // const [page_size, setPageSize] = useState(10);
  const [status] = useState('');
  // const [selectedOrders, setSelectedOrders] = useState<any>({orders:[],orderStatus:''});
  const [shipperOrderId, setShipperOrderId] = useState<null | string>('')
  const [trackingNumber, setTrackingNumber] = useState<null | string>('')
  const [orderStatus, setOrderStatus] = useState<null | string>('')
  const [serviceType, setServiceType] = useState<null | string>('')
  const [pickupCountry, setPickupCountry] = useState<null | string>('')
  const [destinationCountry, setDestinationCountry] = useState<null | string>('')
  const [startDate, setStartDate] = useState<null | string>(formatDateDefaultFn('start'))
  const [endDate, setEndDate] = useState<null | string>(formatDateDefaultFn('end'))
  
  // const [holdSearchQuery, setHoldSearchQuery] = useState<string>('')
  // const [flaggedOrdersPage, setFlaggedOrdersPage] = useState<string>('')
  
  // const [current, setCurrent] = useState<number>(1)

  // const authToken = auth.user.authToken;
  // const setSelectedOrdersCallback =(orders:Array<OrderDetailData_from_b2c>,status:string) => {
  //   setSelectedOrders({orders:orders,orderStatus:status})
  // }
  
  // const getFlaggedOrderDataQuery = useQuery((['getFlaggedOrderData', page_size, page, holdSearchQuery, flaggedOrdersPage]),async () => {
  //   return getExceptionListQuery(auth.user.secret_key, {
  //     // secret_key: "cU4RnObE6ia2HabI4qr3f83YmbLztrfw",
  //     secret_key: auth.user.secret_key,

  //     // for now untill it is changed to b2c id and not b2c name
  //     agent_application_ids: [parseInt(auth.user.b2c_id)],
      
  //     // agent_application_ids: [3521],
  //     // agent_application_ids: ["Developer's Client AccountX"],
  //     page_size: page_size,
  //     page: holdSearchQuery.length > 0 ? undefined : page,
  //     tracking_no: holdSearchQuery.length > 0 ? holdSearchQuery : undefined,
  //     cursor: flaggedOrdersPage ? flaggedOrdersPage : undefined
  //   })
  // }, {enabled: !isProduction})

  // const getOrderDataQuery = useQuery(['getOrderData', status, page_size, page, shipperOrderId, trackingNumber, orderStatus, pickupCountry, destinationCountry, startDate, endDate, serviceType], async () => {
  //   if(status !== 'on_hold'){ 
  //     return getOrderData(authToken, {
  //       "order_status": status,
  //       "page_size": page_size,
  //       "page": page,
  //       "tracking_no": trackingNumber,
  //       "shipper_order_id": shipperOrderId,
  //       "public_status_v2_shipper": orderStatus,
  //       "pickup_country": pickupCountry,
  //       "consignee_country": destinationCountry,
  //       "secret_key": auth.user.secret_key,
  //       "date_from": encodeURIComponent(startDate!),
  //       "date_to": encodeURIComponent(endDate!),
  //       "service_category": serviceType,

  //     })
  //   }    
  // }, {retry: false, cacheTime: 0})

  // const getOrderSummaryCountQuery_from_b2c = useQuery(['getOrderSummaryCount_from_b2c'], async () => {
  //   return getOrderSummaryCount_from_b2c(auth.user.secret_key)
  // })
  
  // useEffect(() => {

  //   // ANTD click on page 1 hack
  //   if(trackingNumber || shipperOrderId) {
  //     let element: HTMLElement = document.getElementsByClassName('ant-pagination-item-1')[0] as HTMLElement;
  //     if(element) {
  //       element.click();
  //     }
  //     setPage(1)
  //   }
  // }, [getOrderDataQuery.data]) // eslint-disable-line react-hooks/exhaustive-deps

  // const SetCommonStates = (key:any) => {
  //   UserViewedManageShipments(key)
  //   return (
  //     setPageSize(10),
  //     setPage(1),
  //     setTrackingNumber(''),
  //     setStatus(key)
  //   )
  // }
  // const callback = (key: any) => {
  //   window.history.replaceState(null, '', `?status=${key}`)
  //   if (key === 'all') {
  //     SetCommonStates('')
  //   }
  //   if (key === 'PENDING_PICKUP') {
  //     SetCommonStates(key)
  //   }
  //   if (key === 'IN_TRANSIT') {
  //     SetCommonStates(key)
  //   }
  //   if (key === 'DELIVERED') {
  //     SetCommonStates(key)
  //   }
  //   if (key === 'IN_TRANSIT') {
  //     SetCommonStates(key)
  //   }
  //   if (key === 'OUT_FOR_DELIVERY') {
  //     SetCommonStates(key)
  //   }
  //   if (key === 'DELIVERED') {
  //     SetCommonStates(key)
  //   }
  //   if (key === 'draft') {
  //     SetCommonStates(key)
  //   }
  //   if (key === 'on_hold') {
  //     SetCommonStates(key)
  //   }
  //   if (key === 'CANCELLED') {
  //     SetCommonStates(key)
  //   }
  //   if (key === 'delivered') {
  //     SetCommonStates(key)
  //   }
  //   if (key === "marketplace_draft") {
  //     SetCommonStates(key);
  //   }
  //   if (key === "bulkUpload") {
  //     SetCommonStates(key)
  //   }
  //   setSelectedOrders({orders:[],orderStatus:''})
  // }

  // const hasData = () => {
  //   if(
  //     (status === 'on_hold' && getOrderSummaryCountQuery_from_b2c.data?.on_hold! === 0)
  //     || (status === 'CANCELLED' && getOrderSummaryCountQuery_from_b2c.data?.cancelled! === 0)
  //     || (status === 'PENDING_PICKUP' && getOrderSummaryCountQuery_from_b2c.data?.pending_pickup! === 0)
  //     || (status === 'IN_TRANSIT' && getOrderSummaryCountQuery_from_b2c.data?.in_transit! === 0)
  //     || (status === 'OUT_FOR_DELIVERY' && getOrderSummaryCountQuery_from_b2c.data?.out_for_delivery! === 0)
  //     || (status === 'DELIVERED' && getOrderSummaryCountQuery_from_b2c.data?.delivered! === 0)
  //     || (status === 'marketplace_draft' && getOrderSummaryCountQuery_from_b2c.data?.channel_draft! === 0)
  //     || (status === 'bulkUpload')
  //   ) {
  //     return true
  //   }
  //   return false
  // }

  // const returnBulkDownloadHeader = () => {
  //   if((status === "PENDING_PICKUP" && selectedOrders.orderStatus === "PENDING_PICKUP") && selectedOrders.orders.length>0) {
  //     return <PrintLabelHeader selectedOrders={selectedOrders.orders} />
  //   }
  //   if(selectedOrders.orderStatus === "created" && selectedOrders.orders.length>0) {
  //     return <ShowBothBulkDownloadHeaders selectedOrders={selectedOrders.orders} orderStatus={status} /> 
  //   }
  //   if((selectedOrders.orderStatus === 'DELIVERED' && status === 'DELIVERED') && selectedOrders.orders.length>0) {
  //     return <PrintPodHeader selectedOrders={selectedOrders.orders} />
  //   }
  // }

  return (
    <>
      <h1 className={`ant-page-header-heading-title page-title ${auth.user.b2c_id === '4214' ? 'negative-mb-12' : ''}`}>{'Manage Shipments'}</h1>
      <Row className={'main-container'} style={{'position': 'relative'}}>
        <Col span={24}>
          <Row gutter={8} className={'filter-row-wrapper'}>
            <Col span={24} className={'filter-wrapper'}>
              {/* <Col span={0}>
                {returnBulkDownloadHeader()}
              </Col>
              {!hasData() && selectedOrders.orders.length>0 ? null :  */}
              <Col span={24}>
                <FreightFilters 
                  status={status} 
                  shipperOrderIdString={shipperOrderId}
                  orderStatusString={orderStatus} 
                  pickupCountryString={pickupCountry} 
                  destinationCountryString={destinationCountry}
                  serviceTypeString={serviceType}
                  setShipperOrderIdString={setShipperOrderId}
                  setOrderStatusString={setOrderStatus} 
                  setPickupCountryString={setPickupCountry} 
                  setDestinationCountryString={setDestinationCountry} 
                  setServiceTypeString={setServiceType}
                  setDate_from={setStartDate}
                  setDate_to={setEndDate}
                  date_from={startDate}
                  date_to={endDate}
                  hasData={true}
                  // setPage={setPage}
                  // page={page}
                  // setCurrent={setCurrent}
                  // dataFrom={'manageShipment'}
                  trackingNumberString={trackingNumber}
                  setTrackingNumberString={setTrackingNumber}
                />
              </Col>
            </Col>
          </Row>
          <Tabs defaultActiveKey={defaultActiveKeyURL} onChange={() => {}} style={{marginTop: '-10px'}}>
            <TabPane tab={<label style={{cursor: 'pointer'}}>{'All'} </label>} key="all" 
            // disabled={selectedOrders.orders.length > 0}
            >
              {/* <AllOrders
                data={getOrderDataQuery.data}
                setPageSize={setPageSize}
                setPage={setPage}
                selectedOrdersCallback={setSelectedOrdersCallback}
                ordersSelected={selectedOrders.orders.length > 0}
                orderStatus={"created"}
                isLoading={getOrderDataQuery.isLoading}
                displayTrackingSidebar={props.displayTrackingSidebar}
                hasData={!hasData()}
                current={current}
                setCurrent={setCurrent}
                dataFrom={'manageShipment'}
              /> */}
              <DummyDataTable hasData={true} />
            </TabPane>       
          </Tabs>
        </Col>
      </Row>
    </>
  )
};