import {useEffect, useState, useRef} from 'react';
import {Row, Col, Card, Alert, Button, message, Tooltip, Spin, Form, Select, Form as F, Popconfirm,Steps} from 'antd'
import {FormInput, FormSelect, CheckBox} from '../orders/components/Forms';
import {useAuth} from "../auth/services/authContext";
import {Formik, Form as FormikForm} from 'formik';
import {useMutation } from "react-query";
import { useLanguage } from '../languages/Language';
import * as Yup from 'yup';
import './styles/shipmentRules.css'
import { CaretLeftOutlined, EnvironmentOutlined, InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {useParams, useHistory} from "react-router-dom";
import { useQuery } from "react-query";
import { getLocations_from_b2c, getServiceDefinitions_from_b2c, updateShipmentRuleDatainB2C, createShipmentRulesInB2C, getShipmentRuleDataFromB2C } from "../orders/services/privateApi";
import { PickUpAddressModal as AddAddressModal } from "../UserSettings/components/PickUpAddressModal";
import { isPickupServiceEnabled, filterOnlyRequiredDataFromB2c, getFormattedServiceTypeTextForShipmentRules } from "../commons/utils/utilizer";
import { pickupExpressB2cId, pickupExpressPriorityB2cId } from "../commons/utils/constants";
// import { getPUDOPoints } from '../GoogleMaps/services';
// import { DropOffAddressModal } from '../orders/components/DropOffAddressModal';
import { getAllCountries_from_b2c } from "../orders/services/privateApi";

const {Option} = Select;
const {Step} = Steps;
// interface ParcelDimensionRule {
//   type: string,
//   status: string,
//   amount: string,
//   currency: string,
//   created_at: string,
//   updated_at: string,
//   transaction_id: string
// }

// interface FormValue {
//   shipper_order_id?: string
//   consignee_country?: string,
//   pickup_country?: string,
//   pickup_point_id?: string | number,
//   service_id?: string | number,
//   service_level?: string,
//   serviceType: string | null,
//   pickup_date: moment.Moment | null
//   pickup_time: string
// }
interface ServiceDefinitionInterface {
  dropoff_id?: number,
  dropoff_address?: string,
  dropoff_postal?: string,
  dropoff_point_country?: string,
  dropoff_point_state?: string,
  dropoff_point_city?: string,
  dropoff_point_province?: string,
  dropoff_point_contact_person?: string,
  dropoff_point_number?: string,
  source?: string,
  additional_data?: any,
  service_id?: string | number,
  origin_country?: string,
  destination_country?: string,
  service_type?: string,
  exclusive_agents?: [
    
  ],
  exclusive_agents_ids?: [
    
  ]
}
interface PickupDetailsInterface {
  is_active?: boolean,
  pickup_point_address?: string,
  pickup_point_city?: string,
  pickup_point_contact_person?: string,
  pickup_point_country?: string,
  pickup_point_email?: string,
  pickup_point_id?: number,
  pickup_point_name?: string,
  pickup_point_notes?: string | null,
  pickup_point_number?: string,
  pickup_point_postal?: string,
  pickup_point_province?: string | null,
  pickup_point_state?: string
}
export const pickupExpress = (origin: string, destination: string, b2c: any) => {
  if(pickupExpressB2cId.includes(b2c) && origin === 'SG' && (destination === 'TH' || destination === 'KR' || destination === 'PH' || destination === 'HK' || destination === 'JP' || destination === 'AU' || destination === 'TW')) {
    return true
  } else {
    return false
  }
}

export const pickupExpressPriority = (origin: string, destination: string, b2c: any) => {
  if(pickupExpressPriorityB2cId.includes(b2c) && origin === 'SG' && (destination === 'US' || destination === 'UK')) {
    return true
  } else {
    return false
  }
}

export const errData = (errors: any) => {
  let errorList = <ul style={{textAlign: 'left', maxWidth: '400px'}}>
    {
      Object.values(errors).map((val:any, index: number) => {
        return <li key={index} style={{fontSize: '12px',color: '#4f4a4a', fontWeight: 600, margin: '10px 0'}}>{(Object.keys(errors)[index]).replace(/_/g, ' ')}: {val} </li>
      })
    }
  </ul>
  return message.error(
    <span>Error: Please correct the following details and try again. {errorList}</span>,
    10
  )
}

const validationSchema = (getText: any) => {
  return (
    Yup.object().shape({
      rule_name: Yup.string().required('Please enter rule name').max(50, 'Maximum 50 characters are allowed'),
      pickup_country: Yup.string().nullable().required(getText('Please select condition')),
      consignee_country: Yup.string().nullable().required(getText('Please select operator')),
      serviceType: Yup.string().required('Please select category'),
      service_id: Yup.string().nullable().when('serviceType',{
        is: 'Pickup',
        then: Yup.string().nullable().required(getText('Please select Pickup Address'))
      })
    })
  )
}

export const ServiceTypeRule = (props: any) => {
  const ruleParams = useParams<any>()
  const history = useHistory()
  const ShipmentDetailsFormRef: any = useRef();

  let { getText } = useLanguage();
  const [isEditFlag, setIsEditFlag] = useState<boolean>(false)
  const [showAddAddressModal, setShowAddAddressModal] = useState<boolean>(false);
  const [addressType, setAddressType] = useState<string>('');
  const [orginCountry, setOrginCountry] = useState<string>('')
  const [destCountry, setDestCountry] = useState<string>('')
  const [uniquesServiceError, setUniqueServiceError] = useState<boolean>(false)
  const [serviceDefinition, setServiceDefinition] = useState<Array<ServiceDefinitionInterface> | undefined>([])
  const [pickupCompleteDetails, setPickupCompleteDetails] = useState<PickupDetailsInterface | undefined>({})

  const auth = useAuth();
  // const resetFieldValuesOnPickupChange = ['consignee_country_code', 'service_type', 'pickup_address_id','pickup_date'];
  // const resetFieldValuesOnDestinationChange = ['service_type', 'pickup_address_id'];
  // const resetFieldValuesOnServiceTypeChange = ['pickup_address_id','dropoff_vendor_point_id'];

  const resetFieldValuesOnPickupChange = ['consignee_country', 'serviceType', 'service_id','pickup_date'];
  const resetFieldValuesOnDestinationChange = ['serviceType', 'service_id'];
  const resetFieldValuesOnServiceTypeChange = ['dropoff_vendor_name','service_id','pickup_date'];

  useEffect(() => {
    let isEdit = window.location.href.indexOf('edit') > -1

    if (isEdit) {
      setIsEditFlag(true)
    }
  }, [])

  const getShipmentData = useQuery(['getShipmentParcelRuleData'], () => {
    return getShipmentRuleDataFromB2C(auth.user.secret_key, ruleParams.id)
  }, {enabled : isEditFlag, cacheTime: 0})

  const getAllCountriesData = useQuery('getAllCountriesData', () => {
    return getAllCountries_from_b2c()
  })

  // Fetch service Definitions Data From b2c
  const getServiceDefinitionsDataFromb2c = useQuery('serviceDefinitions', () => {
    return getServiceDefinitions_from_b2c(auth.user.secret_key)
  })

  const pickupLocationsFromb2c = useQuery('pickupLocationsFromb2c', () => {
    return getLocations_from_b2c(auth.user.secret_key)
  })


  const emptyInitialValue = {
    rule_name: '',
    serviceType: 'Pickup',
    service_id: '',
    pickup_country: '',
    consignee_country: '',
    apply_on_existing_order: false
  }

  const assignOriginCountry = (fullData: any) => {
    if(fullData[0].source_field_name === 'draft_order.pickup_country') {
      return fullData[0].matches_to_value
    } else if(fullData[1].source_field_name === 'draft_order.pickup_country') {
      return fullData[1].matches_to_value
    } else {
      return ''
    }
  }

  const assignDestinationCountry = (fullData: any) => {
    if(fullData[0].source_field_name === 'draft_order.consignee_country') {
      return fullData[0].matches_to_value
    } else if(fullData[1].source_field_name === 'draft_order.consignee_country') {
      return fullData[1].matches_to_value
    } else {
      return ''
    }
  }

  useEffect(() => {
    if(getShipmentData.isFetched && pickupLocationsFromb2c.isFetched && getServiceDefinitionsDataFromb2c.isFetched) {
      let originCountry = assignOriginCountry(getShipmentData.data.rule_source_fields)
      let destCountry = assignDestinationCountry(getShipmentData.data.rule_source_fields)
      setOrginCountry(originCountry)
      setDestCountry(destCountry)

      let pickup_point_obj = getShipmentData.data.rule_target_fields.filter((obj: any) => {
        return obj.target_name === 'draft_order.extra_data'
      })

      let serviceType = getShipmentData.data.rule_target_fields.filter((obj: any) => {
        return obj.target_name === 'draft_order.service_type'
      })
      let pickup_point_id = pickup_point_obj.length > 0 ? JSON.parse(pickup_point_obj![0].target_value)?.pickup_point_id : ''

      if(serviceType[0]?.target_value === 'Dropoff' || serviceType[0]?.target_value === 'Customs clearance and last mile' || serviceType[0]?.target_value === 'Dropoff Express' || serviceType[0]?.target_value === 'Dropoff Express Priority') {
        let dropoff_id = JSON.parse(pickup_point_obj![0].target_value)!.dropoff_id
        filterServiceDefinition(originCountry, destCountry, serviceType[0]?.target_value, dropoff_id)
      } else {
        filterServiceDefinition(originCountry, destCountry, serviceType[0]?.target_value)
      }

      setPickupObject(pickup_point_id, originCountry)
    }
  }, [getShipmentData.isFetched, pickupLocationsFromb2c.isFetched, getServiceDefinitionsDataFromb2c.isFetched]) //eslint-disable-line react-hooks/exhaustive-deps

  const fetchedInitialValue = () => {
    if(isEditFlag && (getShipmentData.data && getShipmentData.data.rule_name.length > 0  && getShipmentData.data?.action_type === 'SERVICE_TYPE')) {
      
      let serviceType = getShipmentData.data.rule_target_fields.filter((obj: any) => {
        return obj.target_name === 'draft_order.service_type'
      })
      let location_id;
      if(isPickupServiceEnabled(serviceType.length > 0 ? serviceType[0].target_value : 'Pickup')){
        location_id = getShipmentData.data.rule_target_fields.filter((obj: any) => {
          return obj.target_name === 'draft_order.extra_data'
        })
        let pickupPointId = location_id.length > 0 ? JSON.parse(location_id[0].target_value)!.pickup_point_id : ''
        return {
          rule_name: getShipmentData.data.rule_name,
          serviceType: serviceType.length > 0 ? serviceType![0].target_value : 'Pickup',
          service_id: pickupPointId,
          pickup_country: assignOriginCountry(getShipmentData.data.rule_source_fields),
          consignee_country: assignDestinationCountry(getShipmentData.data.rule_source_fields),
          apply_on_existing_order: getShipmentData.data.apply_on_existing_order
        }
      } else {
        location_id = getShipmentData.data.rule_target_fields.filter((obj: any) => {
          return obj.target_name === 'draft_order.extra_data'
        })
        let dropoff_id = location_id.length > 0 ? JSON.parse(location_id[0].target_value) : ''
        return {
          rule_name: getShipmentData.data.rule_name,
          serviceType: serviceType.length > 0 ? serviceType![0].target_value : 'Dropoff',
          service_id: dropoff_id.dropoff_id,
          pickup_country: assignOriginCountry(getShipmentData.data.rule_source_fields),
          consignee_country: assignDestinationCountry(getShipmentData.data.rule_source_fields),
          apply_on_existing_order: getShipmentData.data.apply_on_existing_order
        } 
      }

      // service_id: (getShipmentData.data.serviceType === 'Pickup' || getShipmentData.data.serviceType === 'Pickup Express') ? getShipmentData.data.pickup_point_id : getShipmentData.data.serviceDefinition!.dropoff_id
    }
    return emptyInitialValue
  }

  const initialValue = fetchedInitialValue()

  const getDropOffDataFromB2C = (originCountry?: string, destinationCountry?: string, serviceType?: string) => {
    if(getServiceDefinitionsDataFromb2c.data) {
      let dataArr = filterOnlyRequiredDataFromB2c(getServiceDefinitionsDataFromb2c.data, originCountry, destinationCountry, serviceType)
      if(serviceType === 'Customs clearance and last mile') {
        return dataArr?.filter((item: any) => {
          if(item.exclusive_agents?.length! > 0) {
            return item.exclusive_agents?.length! > 0
          } else{
            return item
          }
        })
      }
      return dataArr
    }
    return []
  }


  const filterServicesData = getServiceDefinitionsDataFromb2c.data?.filter((countryObj) => {
    return (orginCountry === countryObj.origin_country) && (destCountry === countryObj.destination_country)
  })
    .map(serviceDef => serviceDef.service_type)
    .filter((value, index, self) => self.indexOf(value) === index)

  const setShowAddAddressModalCallback = (val: boolean) => {
    setShowAddAddressModal(val);
    setAddressType('');

    // This will refetch the pickup locations every time the modal is triggered.
    // Ideally there should be an indicator if a new location is added and only
    // refetch then.
    pickupLocationsFromb2c.refetch()
  }

  const setCreatedLocationIdCallback = (id: string) => {
    if (ShipmentDetailsFormRef.current) {
      ShipmentDetailsFormRef.current.setFieldValue('service_id', id)
    }
  }

  const submitOrder:any = useMutation(async (req:any) => {
    if(isEditFlag) {
      return updateShipmentRuleDatainB2C(auth.user.secret_key, req, ruleParams.id)
    }
    return createShipmentRulesInB2C(auth.user.secret_key, req);
  },  {
    onSuccess: (val) => {
      let ruleSuccess = <span>Rule id <strong>{val.id}</strong> is {isEditFlag ? 'Updated': 'Created'} Succesfully</span>
      message.success(ruleSuccess)

      history.goBack()
    },
    onError: (error:any) => {
      errData(error.response.data)
    },
  });

  const onFormikSubmit = (values: any) => {
    let rulesSourceFieldArray = [
      {
        source_field_name: "draft_order.pickup_country",
        operator: "=",
        matches_to_value: values.pickup_country,
      },
      {
        source_field_name: "draft_order.consignee_country",
        operator: "=",
        matches_to_value: values.consignee_country,
      }
    ]
    let rulesTargetFieldArray;
    if(isPickupServiceEnabled(values.serviceType)){
      // pickupData = getPickUpAddressData(values.pickup_address_id!, pickupLocationsFromb2c.data)
      rulesTargetFieldArray = [
        {
          target_name: 'draft_order.pickup_contact_name',
          target_value: pickupCompleteDetails?.pickup_point_name,
        },
        {
          target_name: 'draft_order.pickup_contact_number',
          target_value: pickupCompleteDetails?.pickup_point_number,
        },
        {
          target_name: 'draft_order.pickup_state',
          target_value: pickupCompleteDetails?.pickup_point_state,
        },
        {
          target_name: 'draft_order.pickup_city',
          target_value: pickupCompleteDetails?.pickup_point_city,
        },
        {
          target_name: 'draft_order.pickup_address',
          target_value: pickupCompleteDetails?.pickup_point_address,
        },
        {
          target_name: 'draft_order.pickup_postal',
          target_value: pickupCompleteDetails?.pickup_point_postal,
        },
        // {
        //   target_name: 'draft_order.pickup_location',
        //   target_value: pickupCompleteDetails?.pickup_point_id,
        // },
        {
          target_name: 'draft_order.service_id',
          target_value: serviceDefinition![0].service_id,
        },
        {
          target_name: 'draft_order.service_type',
          target_value: values.serviceType,
        },
        {
          target_name: "draft_order.extra_data",
          target_value: `{"pickup_point_id":${pickupCompleteDetails?.pickup_point_id}}`
        }
      ]
    }
    if(values.serviceType === 'Dropoff') {
      rulesTargetFieldArray = [
        // {
        //   target_name: 'draft_order.dropoff_vendor_name',
        //   target_value: serviceDefinition![0].dropoff_address,
        // },
        {
          target_name: 'draft_order.pickup_contact_name',
          target_value: serviceDefinition![0].dropoff_point_contact_person,
        },
        {
          target_name: 'draft_order.pickup_contact_number',
          target_value: serviceDefinition![0].dropoff_point_number,
        },
        {
          target_name: 'draft_order.pickup_state',
          target_value: serviceDefinition![0].dropoff_point_state,
        },
        {
          target_name: 'draft_order.pickup_city',
          target_value: serviceDefinition![0].dropoff_point_city,
        },
        {
          target_name: 'draft_order.pickup_address',
          target_value: serviceDefinition![0].dropoff_address,
        },
        {
          target_name: 'draft_order.pickup_postal',
          target_value: serviceDefinition![0].dropoff_postal,
        },
        // {
        //   target_name: 'draft_order.dropoff_vendor_point_id',
        //   target_value: serviceDefinition![0].dropoff_id,
        // },
        {
          target_name: 'draft_order.service_type',
          target_value: values.serviceType,
        },
        {
          target_name: 'draft_order.service_id',
          target_value: serviceDefinition![0].service_id,
        },
        {
          target_name: "draft_order.extra_data",
          target_value: `{"dropoff_id":${serviceDefinition![0].dropoff_id}}`
        }
      ]
    }

    let request = {
      action_type: "SERVICE_TYPE",
      rule_name: values.rule_name,
      meta_data: {serviceType: values.serviceType},
      rule_source_fields: rulesSourceFieldArray,
      rule_target_fields: rulesTargetFieldArray,
      apply_on_existing_order: values.apply_on_existing_order,
      addressID: pickupCompleteDetails?.pickup_point_id
    }

    submitOrder.mutate(request)
  }

  // const onChangeHandler = (value: any) => {
  //   setOriginCountryCode(value)
  // }

  if(isEditFlag && getShipmentData.isFetching) {
    return (
      <div className="spinner-centre">
        <Spin />
      </div>
    )
  }

  if(isEditFlag && (getShipmentData.data && getShipmentData.data?.action_type !== 'SERVICE_TYPE')) {
    return (
      <Row align="middle" justify="center">
        <Col span={14} className="bulk-payment-error">
          <Alert
            message="Error while fetching Rule"
            description={'No such rule exist'}
            type="error"
            showIcon
          />
        </Col>
      </Row>

    )
  }
  const setPickupObject = (pickup_point_id: number, selected_pickup_country: string) => {
   
    let pickupCountrySelectedArray = pickupLocationsFromb2c.data?.filter(l => l.pickup_point_country === selected_pickup_country)
    let pickupSelectedObject = pickupCountrySelectedArray?.filter(item => item.pickup_point_id === pickup_point_id)
    setPickupCompleteDetails(pickupSelectedObject![0])
  }

  const filterServiceDefinition = (pickupCountry?: string, consigneeCountry?: string, serviceType?: string, value?: any) => {
    if(getServiceDefinitionsDataFromb2c.data) {
      if(serviceType === 'Dropoff' || serviceType === 'Customs clearance and last mile' || serviceType === 'Dropoff Express' || serviceType === 'Dropoff Express Priority') {
        let filterOutServiceId = getServiceDefinitionsDataFromb2c.data.filter((data: any) => {
          return (data.origin_country === pickupCountry && data.destination_country === consigneeCountry && data.service_type === serviceType)
        }).filter(id => id!.dropoff_id === value)
        if(filterOutServiceId.length > 1) {
          let exclusiveLane = filterOutServiceId?.filter(item => item.exclusive_agents?.length! > 0)
          setServiceDefinition(exclusiveLane)
        } else {
          setServiceDefinition(filterOutServiceId)
        }
      } else {
        let filterOutServiceId = getServiceDefinitionsDataFromb2c.data.filter((data: any) => {
          return (data.origin_country === pickupCountry && data.destination_country === consigneeCountry && data.service_type === serviceType)
        })
        
        if(filterOutServiceId.length > 1) {
          let exclusiveLane = filterOutServiceId?.filter(item => item.exclusive_agents?.length! > 0)
          if(exclusiveLane.length > 0) {
            setServiceDefinition(exclusiveLane)
          } else {
            setUniqueServiceError(true)
          }
          
        } else {
          setServiceDefinition(filterOutServiceId)
        }
      }
    }
    return []
  }
  // @ts-ignore

  return (
    <div ref={r => { ShipmentDetailsFormRef.current = ShipmentDetailsFormRef.current ? ShipmentDetailsFormRef.current : r; }}>
      <Row align="middle" justify="center">
        <Col span="14" className={"rule-header"}>{getText('Service Type Rule')}</Col>
        <Col span="14">
          {
            !isEditFlag ?
              <p className="go-back-btn" style={{cursor: 'pointer'}}>
                <Popconfirm
                  title={<div className="delete-card">{getText('All your progress in this session will be Lost!')}</div>}
                  onConfirm={() => history.goBack()}
                  onCancel={() => null}
                  okText={getText('Quit')}
                  cancelText={getText('Cancel')}
                  icon={<QuestionCircleOutlined style={{ color: 'red' } }/>}
                >
                  <a href="#"><CaretLeftOutlined/> {getText('Go back')} </a>
                </Popconfirm>
              </p> :
              <p className="go-back-btn" onClick={() => history.goBack()} style={{cursor: 'pointer'}}><CaretLeftOutlined/> {getText('Go back')} </p>
          }
        </Col>
      </Row>
      <Formik
        initialValues={initialValue}
        innerRef={ShipmentDetailsFormRef}
        enableReinitialize= {true}
        onSubmit={onFormikSubmit}
        validateOnChange={true}
        validationSchema={validationSchema(getText)}
      >
        { formik => {
          return (

            (
              <Row align="middle" justify="center">
                <Col span="14">
                  <FormikForm>
                    <Steps direction="vertical">
                      <Step status={"process"}
                        title={<p className={"mb-5"}>{getText('What is the name for this service type rule?')}</p>}
                        description={
                          <Card className={'card-separator'}>
                            <Row gutter={14}>
                              <Col span={12} className={'label-break'}>
                                <FormInput name="rule_name" label={getText('Rule Name')} required wrapperClass="no-margin-item mb-15" />
                              </Col>
                            </Row>
                          </Card>
                        }
                      />
                      <Step status={"process"}
                        title={<p className={"mb-5"}>{getText('What is the condition?')}</p>}
                        description={
                          <Card className={'card-separator card-bg-color-when'}>
                            <F.Item label={getText('When')} className={"single-order-form-title"}></F.Item>
                            <Row gutter={14}>
                              <Col span={12} className="label-break">
                                <FormSelect name="pickup_country" loading={getAllCountriesData.isLoading} label={getText('Origin Country')} required onChangeCallback={(value: any) => {
                                  resetFieldValuesOnPickupChange.forEach(field => formik.setFieldValue(field, ''));
                                  // onChangeHandler(value)
                                  setOrginCountry(value)
                                  setUniqueServiceError(false)
                                }} wrapperClass="no-margin-item" >
                                  {
                                    getAllCountriesData && getAllCountriesData.data?.map((item: any) => {
                                      return <Option key={item} value={item}>{item}</Option>
                                    })
                                  }
                                </FormSelect>
                                {(getAllCountriesData.isError) && <p style={{ color: 'red', marginBottom: 0, marginTop: '-20px', fontSize: '11px' }}>{getText("Failed to load origin countries, please try again later")}</p>}
                              </Col>
                              <Col span={12} className="label-break">
                                <FormSelect name="consignee_country"
                                  label={getText('Destination Country')} loading={getAllCountriesData.isLoading} required
                                  onChangeCallback={(value: any) => {
                                    resetFieldValuesOnDestinationChange.forEach(field => formik.setFieldValue(field, '')); setDestCountry(value)
                                    setUniqueServiceError(false)
                                  }} wrapperClass="no-margin-item"
                                >
                                  {
                                    getAllCountriesData && getAllCountriesData.data?.map((item: any) => {
                                      return <Option key={item} value={item}>{item}</Option>
                                    })
                                  }
                                </FormSelect>
                                {(getAllCountriesData.isError) && <p style={{ color: 'red', marginBottom: 0, marginTop: '-20px', fontSize: '11px' }}>{getText("Failed to load destination countries, please try again later")}</p>}
                              </Col>
                            </Row>

                          </Card>
                        }
                      />
                      <>
                        <Step status={"process"}
                          title={<p className={"mb-5"}>{getText('What are the action to apply?')}</p>}
                          description={
                            <Card className={'card-separator card-bg-color-then'}>
                              <F.Item label={getText('Then')} className={"single-order-form-title"}></F.Item>

                              <Row gutter={14}>
                                <Col span={12}  className=" shipment-spacer-mb label-break">
                                  <FormSelect name="serviceType" required 
                                    onChangeCallback={(value: any) =>{resetFieldValuesOnServiceTypeChange.forEach(field => formik.setFieldValue(field, '')); setUniqueServiceError(false)}} label={<label className="info-disclaimer">{getText('Service Type')}:<Tooltip title={getText('Our delivery team will come to your address to have the parcel pick up.')}><InfoCircleOutlined />
                                    </Tooltip></label>} wrapperClass="no-margin-item" >
                                    {
                                      filterServicesData?.map((type: any, index: number) => {
                                        return type !== 'CC_LM' && <Option key={index} value={type}>{getFormattedServiceTypeTextForShipmentRules(type)}</Option>
                                      })
                                    }
                                  </FormSelect>
                                  {uniquesServiceError && <div style={{color: 'red', marginTop: '-23px'}}>Unable to find a unique service</div>}
                                </Col>
                                <Col span={12} className={uniquesServiceError ? 'shipment-spacer-mb label-break hideBlock' : 'shipment-spacer-mb label-break'}>
                                  {(isPickupServiceEnabled(formik.values.serviceType)) &&
                                    <FormSelect onSelectCallback={(value: any) => {
                                      setPickupObject(value, formik.values.pickup_country!)
                                      filterServiceDefinition(formik.values.pickup_country!, formik.values.consignee_country!, formik.values.serviceType!)
                                    }} name="service_id" required label={getText('Pickup Address')} loading={pickupLocationsFromb2c.isLoading} wrapperClass="no-margin-item" placeholder={'Select an address'} showSearch={'false'} defaultValue={formik.values.service_id?.toString()}>
                                      {
                                        pickupLocationsFromb2c.data?.filter(l => l.pickup_point_country === formik.values.pickup_country).map(item => <Option key={item.pickup_point_id} value={item.pickup_point_id}>{`${item.pickup_point_name} - ${item.pickup_point_address}`}</Option>)
                                      }
                                    </FormSelect>
                                  }
                                  {(formik.values.serviceType === 'Dropoff' || formik.values.serviceType === 'Dropoff Express' || formik.values.serviceType === 'Dropoff Express Priority') && (
                                    <FormSelect onSelectCallback={(value: any) => {
                                      setPickupObject(value, formik.values.pickup_country!)
                                      filterServiceDefinition(formik.values.pickup_country!, formik.values.consignee_country!, formik.values.serviceType!, value)
                                    }} name="service_id" required
                                    label={<label>{'Drop-off Address'} <EnvironmentOutlined style={{color: 'red', fontSize: '14px', position: 'relative', top: '1px'}} /></label>} wrapperClass="no-margin-item" placeholder={'Select an Address'} defaultValue={formik.values.service_id?.toString()}>
                                      {
                                        getDropOffDataFromB2C(formik.values?.pickup_country!, formik.values?.consignee_country!, 'Dropoff')?.map((item:any) => item.dropoff_address &&  <Option className={"dropoff-dropdown"} key={item.dropoff_address} value={item.dropoff_id}>{`${item.service_id ? item.dropoff_address : 'No Data'}`}</Option>)
                                      }
                                    </FormSelect>)
                                  }
                                  {(isPickupServiceEnabled(formik.values.serviceType)) && <Form.Item className={'no-margin-item'}>
                                    <Button type="link" onClick={() => {
                                      setShowAddAddressModal(true);
                                      setAddressType('pickup')
                                    }} className={"no-border-btn"} style={{background: 'none'}}>
                                      {getText('Add new address')}
                                    </Button>
                                    {(showAddAddressModal && addressType === 'pickup') &&
                                    <AddAddressModal
                                      setShowAddAddressModalCallback={setShowAddAddressModalCallback}
                                      setCreatedLocationIdCallback={setCreatedLocationIdCallback}
                                      countryCode={formik.values.pickup_country}
                                      addressType={'pickup'}
                                    />}
                                  </Form.Item>}
                                </Col>
                              </Row>

                              <Col>
                                <CheckBox name="apply_on_existing_order" label={''}
                                >{getText('Apply to new and existing shipments')}</CheckBox>
                              </Col>

                              <div className={`button-block create-rule-btn ${uniquesServiceError ? 'hideBlock' : ''}`}>
                                <Button htmlType="submit" type="primary" className={"blue-button"} loading={submitOrder.isLoading}>
                                  {isEditFlag ? getText('Update Rule') : getText('Create Rule')}
                                </Button>
                              </div>
                            </Card>
                          }
                        />
                      </>
                    </Steps>
                  </FormikForm>
                </Col>
              </Row>
            )
          )}
        }
      </Formik>
    </div>

  )
}
