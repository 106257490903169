import {Tag} from 'antd';
import '../styles/StatusBadge.css';

interface StatusProps{
  statusText: string
  statusCode: string
  color: string
}

export const StatusBadge = (props:StatusProps) =>{
  return(
    <div className="statuses">
      <h4 className="status-text">{props.statusText} <Tag color={`${props.color}`}>{props.statusCode}</Tag></h4>
    </div>
  )
}