import '../styles/ApiHeader.css';
import {Tag} from 'antd';

interface HeaderProps {
  apiName: string
  apiMethod: string
  devApiEndPoint: string
  prodApiEndPoint: string
}

export const ApiHeader = (props:HeaderProps) =>{
  return(
    <div className="api-header">
      <h1 className="api-name">{props.apiName} <Tag color="#28a745">{props.apiMethod}</Tag></h1>
      <h2 className="request-header">Request Example</h2>
      <div className="end-point-urls">
        <p>Development Endpoint: <a target="_blank" rel='noopener noreferrer' href={`${props.devApiEndPoint}`}>{`${props.devApiEndPoint}`}</a></p>
        <p>Production Endpoint: <a target="_blank" rel='noopener noreferrer' href={`${props.prodApiEndPoint}`}>{`${props.prodApiEndPoint}`}</a></p>
      </div>
    </div>
  )
}