import {Button, Modal} from 'antd';
import React, {useState} from "react";
import {useAuth} from "../../auth/services/authContext";
// @ts-ignore
import TourBahasa from '../../assets/static/Tour_Bahasa.pdf'
// @ts-ignore
import TourPDF from '../../assets/static/Tour_new.pdf'
import {TourPopupViewed} from "../../commons/mixpanel"
import { useLanguage } from '../../languages/Language';

export const TourModal = (props:any) =>{
  let { getText } = useLanguage();
  const [takeTour, setTakeTour] = useState<boolean>(false);
  const auth = useAuth();
  let billingCountry = auth.user.billingCountry;
  const language = localStorage.getItem('rcml-lang');
  const handleCancel = () => {
    props.setShowTourCallback(false,false);
    localStorage.setItem("logged_in_once", String(true));
  };

  const getTourPDf = () => {
    // PDFTourViewed();
    if(billingCountry!=='ID'){
      return language === 'id'?TourBahasa:TourPDF
    }
    return TourBahasa;
  }

  const takeonBoardTour = () => {
    TourPopupViewed()
    setTakeTour(true);
    props.setShowTourCallback(props.tourButtonTouched, false);
    localStorage.setItem("logged_in_once", String(true))
  }

  return (
    <>
      {<Modal width={520}
        visible={!takeTour}
        onCancel={handleCancel}
        className={'tour-container'}
        footer={[
          <Button key="cancel" onClick={handleCancel} className={"no-border-grey-btn"}>
            {getText('Nah. I’m fine')}
          </Button>,
          <Button key="submit" type="primary" className={"blue-button"} onClick={takeonBoardTour}>
            {getText('Take a tour')}
          </Button>,
        ]}>
        <div style={{paddingTop:30}}>
          <p className={'tour-label'}>{getText('Welcome to Janio Merchant Portal')}</p>
          <p className={'tour-sub-label'}>{getText('Thank you for signing up an account with Janio.')}</p>
        </div>
      </Modal>}
      {props.tourButtonTouched && (<Modal width={900} visible={takeTour} onCancel={handleCancel} footer={null} className={'custom-modal'}>
        <iframe title="Welcome Tour" src={getTourPDf()} width="100%" height="500px" style={{marginTop: 20}}>
        </iframe>
      </Modal>)
      }
    </>
  )
};
