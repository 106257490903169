import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col,
  Alert
} from 'antd'
import { useMutation } from 'react-query'
import {useState} from 'react'
import { useAuth } from './services/authContext'
import './styles/AuthForm.css'
import {validateMessages} from "../commons/utils/constants";
import {resetMixpanelCookies, trackSignIn} from "../commons/mixpanel"
import {getAuthErrorContent} from "../commons/utils/utilizer";
import { useLanguage } from '../languages/Language';
import { featureFlag } from "../commons/utils/utilizer";

const LoginForm = () => {
  resetMixpanelCookies()
  const auth = useAuth();
  let { getText } = useLanguage()
  const [isLoginFailed, setIsLoginFailed] = useState<boolean>(false);
  const [error, setError] = useState('')
  const authMutation = useMutation(async ({email, password}: {email: string, password: string}) => {
    return auth.signIn(email, password)
  },  {
    onSuccess: (val) => {
      trackSignIn(val.email, val.full_name)
    },
    onError: (val) => {
      let error: any = val
      setIsLoginFailed(true)

      setError({...error.response.data})
    },
  });

  return (
    <Form
      onFinish={(val) => {
        authMutation.mutate(val)
      }}
      layout={'vertical'}
      requiredMark={false}
      validateMessages={validateMessages}
    >
      <Form.Item label={getText("Log in to Merchant Portal")} className={"form-title"}>
      </Form.Item>
      {isLoginFailed && <Alert
        className={"form-error"}
        message="Unable to process your request"
        description={getAuthErrorContent(error)}
        type="error"
        showIcon
      />}
      <Form.Item
        label={getText("Email Address")}
        name="email"
        rules={[{ required: true, message: getText('Please enter your email address')}, { type: 'email' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={getText("Password")}
        name="password"
        rules={[{ required: true, message: getText('Please enter your password')}]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <a className="form-text" href="/auth/reset_password">
          {getText("Forgot your password?")}
        </a>
      </Form.Item>
      <Form.Item className={'form-footer'}>
        <Button type="primary" size={"large"} htmlType="submit" className={"form-button"} loading={authMutation.isLoading}>
          {getText("Login")}
        </Button>
        {
          featureFlag() && 
          <>{getText("No account?")} <a href="/auth/signup" className={"form-text"}> {getText("Sign up now")}</a></>
        }
      </Form.Item>
    </Form>
  )
};


export const Login = () =>  {
  return (
    <Row align="middle" justify="center">
      <Col span={12} className={'auth-form-container'}>
        <Card>
          <LoginForm />
        </Card>
      </Col>
    </Row>
  )
};
