import { Col, Row, DatePicker, Spin } from "antd"
import { BarPlot } from "./charts/BarPlot"
import { Donut } from "./charts/Donut"
import { LinePlot } from "./charts/LinePlot"
import { PiePlot } from "./charts/PiePlot"
import { StackedColumn } from "./charts/StackedColumn"
import { SlaPerformanceTable } from "./charts/SlaPerformanceTable"
// import { ShipmentTable } from "./charts/ShipmentTable"
import { useState } from "react"
import { useQuery } from "react-query"
import { useAuth } from "../../auth/services/authContext"
import { getBreachedSlaApi, getShipmentBreakdownApi, getShipmentbyDestinationCountryApi, getShipmentNotMovingUpdateApi, getShipmentPerformanceApi, getSlaPerformanceApi, getSlaPerformanceByDestinationZoneApi } from "../services/chartsApi"
import moment, {Moment} from "moment"
import { UserSelectedAnalyticsButton } from "../../commons/mixpanel"
import { AnalyticsButton } from "../../commons/components/AnalyticsButton/AnalyticsButton"
import { getOrderSummaryCount_from_b2c } from "../../ManageShipments/services";
import { NoAnalyticsAvailable } from "./NoAnalyticsAvailable"
const { RangePicker } = DatePicker;

export const ShipmentAnalytics = () => {
  var today = new Date();
  var todayForInformationMessage = today.toISOString().split('T')[0]
  today.setMonth(today.getMonth()-1)

  const [range, setRange] = useState<string>('last_month');
  const [startDate, setStartDate] = useState<string>(today.toISOString().split('T')[0])
  const [endDate, setEndDate] = useState<string>('')
  const [shipmentBarChartGranularity, setGhipmentBarChartGranularity] = useState<string>('weekly')
  const [slaLineChartGranularity, setSlaLineChartGranularity] = useState<string>('weekly')
  const [slaTableGranularity, setSlaTableGranularity] = useState<string>('weekly')
  // const [shipmentTableGranularity, setShipmentTableGranularity] = useState<string>('weekly')

  const auth = useAuth();

  const summary = useQuery('ordersSummary', () =>{
    return getOrderSummaryCount_from_b2c(auth.user.secret_key)
  });
  const getShipmentBreakdown = useQuery(['getShipmentBreakdown', startDate, endDate], async () => {
    return getShipmentBreakdownApi(startDate, endDate, auth.user.authToken)
  })
  const getShipmentPerformance = useQuery(['getShipmentPerformance',startDate, endDate], async () => {
    return getShipmentPerformanceApi(startDate, endDate, auth.user.authToken)
  })
  const getShipmentbyDestinationCountry = useQuery(['getShipmentbyDestinationCountry', startDate, endDate, shipmentBarChartGranularity], async () => {
    return getShipmentbyDestinationCountryApi(startDate, endDate, shipmentBarChartGranularity, auth.user.authToken)
  })
  // const getShipmentbyDestinationZone = useQuery(['getShipmentbyDestinationZone', startDate, endDate, shipmentTableGranularity], async () => {
  //   return getShipmentbyDestinationZoneApi(startDate, endDate, shipmentTableGranularity, auth.user.authToken)
  // })
  const getSlaPerformanceByDestinationZone = useQuery(['getSlaPerformanceByDestinationZone', startDate, endDate, slaTableGranularity], async () => {
    return getSlaPerformanceByDestinationZoneApi(startDate, endDate, slaTableGranularity, auth.user.authToken)
  })
  const getSlaPerformance = useQuery(['getSlaPerformance', startDate, endDate, slaLineChartGranularity], async () => {
    return getSlaPerformanceApi(startDate, endDate, slaLineChartGranularity, auth.user.authToken)
  })
  const getBreachedSla = useQuery(['getBreachedSla', startDate, endDate], async () => {
    return getBreachedSlaApi(startDate, endDate, auth.user.authToken)
  })
  const getShipmentNotMovingUpdate = useQuery(['getShipmentNotMovingUpdate', startDate, endDate], async () => {
    return getShipmentNotMovingUpdateApi(startDate, endDate, auth.user.authToken)
  })

  let shipmentAlreadyCreated = summary.data?.pending_pickup! > 0 || summary.data?.pending_pickup! > 0
  const dataLoading = () => {
    if(getShipmentBreakdown.isLoading || getShipmentPerformance.isLoading ||
      getBreachedSla.isLoading || getShipmentNotMovingUpdate.isLoading)
      return true;
    return false;
  }

  const getStartDate = (start: string) => {
    var today = new Date();
    if(start === 'last_day'){
      today.setDate(today.getDate()-1)
      setRange('last_day')
    }else if(start === 'last_week'){
      today.setDate(today.getDate()-7)
      setRange('last_week')
    }else  if(start === 'last_month'){
      today.setMonth(today.getMonth()-1)
      setRange('last_month')
    }else  if(start === 'last_quarter'){
      today.setMonth(today.getMonth()-3)
      setRange('last_quarter')
    }
    setStartDate(today.toISOString().split('T')[0])
    setEndDate('')
  }
  const formatDate = (date:Moment) => {
    return date.format('YYYY-MM-DD');
  }
  const getCustomDate = (value: any) => {
    if(value){
      setStartDate(formatDate(value[0]))
      setEndDate(formatDate(value[1]))
      setRange('custom')
    }else{
      let today = new Date();
      today.setMonth(today.getMonth()-1)
      setStartDate(today.toISOString().split('T')[0])
      setEndDate('')
      setRange('last_month')
    }
    UserSelectedAnalyticsButton('custom_date')
  }

  if(!shipmentAlreadyCreated){
    return (
      <NoAnalyticsAvailable/>
    )
  }

  return (
    <>
      <h3 className={'shipment-header'}>Analytics</h3>
      {dataLoading()? <div className={'chart-spinner'}><Spin/></div> :
        <>
          <Row gutter={[16, 16]} justify={'start'} style={{marginTop: '24px'}}>
            <Col span={3}>
              <AnalyticsButton onButtonClick={() => getStartDate('last_day')} mixpanelAnalytics={() => UserSelectedAnalyticsButton('last day')} className={'filter-button'} type={range==='last_day'? 'primary':'default'}>Last Day</AnalyticsButton>
            </Col>
            <Col span={3}>
              <AnalyticsButton onButtonClick={() => getStartDate('last_week')} mixpanelAnalytics={() => UserSelectedAnalyticsButton('last week')} className={'filter-button'} type={range==='last_week'? 'primary':'default'}>Last Week</AnalyticsButton>
            </Col>
            <Col span={3}>
              <AnalyticsButton onButtonClick={() => getStartDate('last_month')} mixpanelAnalytics={() => UserSelectedAnalyticsButton('last month')} className={'filter-button'} type={range==='last_month'? 'primary':'default'}>Last Month</AnalyticsButton>
            </Col>
            <Col span={3}>
              <AnalyticsButton onButtonClick={() => getStartDate('last_quarter')} mixpanelAnalytics={() => UserSelectedAnalyticsButton('last quarter')} className={'filter-button'} type={range==='last_quarter'? 'primary':'default'}>Last Quarter</AnalyticsButton>
            </Col>
            <Col span={6}>
              <RangePicker
                style={{width: '100%'}}
                value={[endDate? moment(startDate): null,endDate? moment(endDate): null]}
                onChange={getCustomDate}
              />
            </Col>
          </Row>
          <Row>
            <h4 style={{padding: '0px', marginBottom: '-0.5em'}}>{`Information shown from ${moment(startDate).format('DD MMM YYYY')} to ${range === 'custom'? moment(endDate).format('DD MMM YYYY') : moment(todayForInformationMessage).format('DD MMM YYYY')}`}</h4>
          </Row>
          <Row gutter={[16, 16]} style={{marginTop: '30px'}}>    
            <Col span={12} className={'analytics-col-wrapper'}>
              <LinePlot linePlotData={getSlaPerformance} granularity={slaLineChartGranularity} setGranularity={setSlaLineChartGranularity} range={range}/>
            </Col>
            <Col span={12} className={'analytics-col-wrapper'}>
              <PiePlot piePlotData={getShipmentPerformance}/>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>    
            <Col span={24} className={'analytics-col-wrapper'}>
              <SlaPerformanceTable slaPerformanceTableData={getSlaPerformanceByDestinationZone.data} granularity={slaTableGranularity} setGranularity={setSlaTableGranularity} range={range}/>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12} className={'analytics-col-wrapper'}>
              <Donut donutData={getShipmentBreakdown}/>
            </Col>
            <Col span={12} className={'analytics-col-wrapper'}>
              <StackedColumn stackedColumnData={getShipmentbyDestinationCountry} granularity={shipmentBarChartGranularity} setGranularity={setGhipmentBarChartGranularity} range={range}/>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>    
            <Col span={12} className={'analytics-col-wrapper'}>
              <BarPlot type={'breachedSla'} barPlotData={getBreachedSla}/>
              {/* <ShipmentTable shipmentTableData={getShipmentbyDestinationZone.data} granularity={shipmentTableGranularity} setGranularity={setShipmentTableGranularity} range={range}/> */}
            </Col>
            <Col span={12} className={'analytics-col-wrapper'}>
              <BarPlot type={'shipmentNotMoving'} barPlotData={getShipmentNotMovingUpdate}/>
            </Col>
          </Row> 
          {/* <Row gutter={[24, 24]}>
            <Col span={12} className={'analytics-col-wrapper'}>
              <BarPlot type={'breachedSla'} barPlotData={getBreachedSla}/>
            </Col>
          </Row>  */}
        </>} 
    </>
  )
}