import React, { useState } from 'react'
import {Row, Col, Button, Card, Switch, Form, Alert} from 'antd';
import {Formik, Form as FormikForm} from 'formik';
// import { useMutation } from 'react-query';
import { useB2BOrderContext } from "../b2bOrderCreationContext"
interface PropTypes {
  current: number,
  setCurrentState: (val: number) => void,
}

interface SwitchStates {
  pickup_liftgate: boolean,
  delivery_liftgate: boolean
  record_exporter: boolean,
  record_importer: boolean
  shipment_insurance: boolean
  dangerous_goods_handling: boolean,
  delivered_at_place: boolean,
  delivered_duty_paid: boolean,
  high_value_clearance: boolean
}

export const RecommendedServicesForm = (props: PropTypes) => {
  // const auth = useAuth()
  const orderContextData = useB2BOrderContext()

  const [switchState, setSwitchState] = useState<SwitchStates>({
    pickup_liftgate: false,
    delivery_liftgate: false,
    record_exporter: false,
    record_importer: false,
    shipment_insurance: false,
    dangerous_goods_handling: false,
    delivered_at_place: false,
    delivered_duty_paid: false,
    high_value_clearance: false
  })
  const [dataSubmitted, setDataSubmitted] = useState<boolean>(false)

  const initialValues = {
    pickup_liftgate: orderContextData.b2bOrderData.lift_gate?.pickup_liftgate,
    delivery_liftgate: orderContextData.b2bOrderData.lift_gate?.delivery_liftgate,
    record_exporter: orderContextData.b2bOrderData.customs_brokerage?.record_exporter,
    record_importer: orderContextData.b2bOrderData.customs_brokerage?.record_importer,
    shipment_insurance: orderContextData.b2bOrderData.insurance?.shipment_insurance,
    dangerous_goods_handling: orderContextData.b2bOrderData.others?.dangerous_goods_handling,
    delivered_at_place: orderContextData.b2bOrderData.others?.delivered_at_place,
    delivered_duty_paid: orderContextData.b2bOrderData.others?.delivered_duty_paid,
    high_value_clearance: orderContextData.b2bOrderData.others?.high_value_clearance
  }

  const onFormikSubmit = (values: any) => {
    let updatedData = {
      lift_gate: {
        pickup_liftgate: switchState.pickup_liftgate,
        delivery_liftgate: switchState.delivery_liftgate
      },
      customs_brokerage: {
        record_exporter: switchState.record_exporter,
        record_importer: switchState.record_importer
      },
      insurance: {
        shipment_insurance: switchState.shipment_insurance
      },
      others: {
        dangerous_goods_handling: switchState.dangerous_goods_handling,
        delivered_at_place: switchState.delivered_at_place,
        delivered_duty_paid: switchState.delivered_duty_paid,
        high_value_clearance: switchState.high_value_clearance
      }
    }
    orderContextData.updateB2BOrderData(updatedData)
    setDataSubmitted(true)
    props.setCurrentState(5)
  }

  if (props.current > 4) {
    return (
      <>
        {<Card title={'Goods Overview'} className={"form-summary-container"} style={{fontSize: '19px'}}>
          <p style={{ margin: 0 }} className={'gray-text'}>Goods Value: <span style={{color: '#000', fontSize: '17px'}}>{orderContextData.b2bOrderData.goods_value!}</span></p>
          <p className={'gray-text'}>Shipment Ready Date: <span style={{color: '#000', fontSize: '17px'}}>{orderContextData.b2bOrderData.goods_ready_date}</span></p>
          <p className={'gray-text'}>Is Hazardous Goods: <span style={{color: '#000', fontSize: '17px'}}>{orderContextData.b2bOrderData.hazardous_good.toString()}</span></p>
        </Card>}
      </>
    )
  }

  const onChange = (value: boolean, field_name: string) => {
    let Fieldarr = ['pickup_liftgate','delivery_liftgate', 'record_exporter', 'record_importer', 'shipment_insurance', 'dangerous_goods_handling', 'delivered_at_place', 'delivered_duty_paid', 'high_value_clearance'] 
    if(Fieldarr.includes(field_name)) {
      setSwitchState((prevState) => (
        {
          ...prevState,
          [field_name] : value
        }
      ))
    }
  }

  return (
    <Row className='claim-main-container single-order-form-container ant-form-vertical'>
      <Col span={24}>
        {dataSubmitted &&
          <>
            <Alert
              message="Data Submitted Successfully"
              description={
                <p>
                  {JSON.stringify(orderContextData.b2bOrderData)}
                  <Button style={{marginLeft: '20px'}} type='dashed' onClick={() => setDataSubmitted(false)}>Close</Button>
                </p>
              }
              type="success"
              showIcon
            />
          </>
        }
        <Formik 
          initialValues={initialValues}
          onSubmit={onFormikSubmit}
        >
          {formik => {
            return (
              <FormikForm className='dispute-main-container'>
                <Col span={24}>
                  <Row gutter={32}>
                    <Col span={8}>
                      <h4 style={{marginTop: '10px', fontWeight: 'bold'}}>Lift Gate</h4>

                      <Form.Item name="pickup_liftgate" valuePropName="checked" className='switch_wrapper'>
                        <Switch defaultChecked={switchState.pickup_liftgate} onChange={(checked: boolean) => onChange(checked, 'pickup_liftgate')} size='small' />
                        <span className='switch_span'>Liftgate at Pickup</span>
                      </Form.Item>
                      <Form.Item name="delivery_liftgate" valuePropName="checked" className='switch_wrapper'>
                        <Switch defaultChecked={initialValues.delivery_liftgate} onChange={(checked: boolean) => onChange(checked, 'delivery_liftgate')} size='small' />
                        <span className='switch_span'>Liftgate at Delivery</span>
                      </Form.Item>
                      
                    </Col>

                    <Col span={8}>
                      <h4 style={{marginTop: '10px', fontWeight: 'bold'}}>Customs Brokerage</h4>
                      <Form.Item name="record_exporter" valuePropName="checked" className='switch_wrapper'>
                        <Switch defaultChecked={switchState.record_exporter} onChange={(checked: boolean) => onChange(checked, 'record_exporter')} size='small' />
                        <span className='switch_span'>EOR (Exporter of Record)</span>
                      </Form.Item>
                      <Form.Item name="record_importer" valuePropName="checked" className='switch_wrapper'>
                        <Switch defaultChecked={switchState.record_importer} onChange={(checked: boolean) => onChange(checked, 'record_importer')} size='small' />
                        <span className='switch_span'>IOR (Importer of Record)</span>
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <h4 style={{marginTop: '10px', fontWeight: 'bold'}}>Insurance</h4>
                      <Form.Item name="shipment_insurance" valuePropName="checked" className='switch_wrapper'>
                        <Switch defaultChecked={switchState.shipment_insurance} onChange={(checked: boolean) => onChange(checked, 'shipment_insurance')} size='small' />
                        <span className='switch_span'>Shipment Insurance</span>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <h4 style={{marginTop: '20px', fontWeight: 'bold'}}>Others</h4>
                      <Form.Item name="dangerous_goods_handling" valuePropName="checked" className='switch_wrapper'>
                        <Switch defaultChecked={switchState.dangerous_goods_handling} onChange={(checked: boolean) => onChange(checked, 'dangerous_goods_handling')} size='small' />
                        <span className='switch_span'>Dangerous Goods Handling (both packaging and documentation)</span>
                      </Form.Item>

                      <Form.Item name="delivered_at_place" valuePropName="checked" className='switch_wrapper'>
                        <Switch defaultChecked={switchState.delivered_at_place} onChange={(checked: boolean) => onChange(checked, 'delivered_at_place')} size='small' />
                        <span className='switch_span'>Delivered at Place (DAP)</span>
                      </Form.Item>

                      <Form.Item name="delivered_duty_paid" valuePropName="checked" className='switch_wrapper'>
                        <Switch defaultChecked={switchState.delivered_duty_paid} onChange={(checked: boolean) => onChange(checked, 'delivered_duty_paid')} size='small' />
                        <span className='switch_span'>Delivered Duty Paid (DDP)</span>
                      </Form.Item>

                      <Form.Item name="high_value_clearance" valuePropName="checked" className='switch_wrapper'>
                        <Switch defaultChecked={switchState.high_value_clearance} onChange={(checked: boolean) => onChange(checked, 'high_value_clearance')} size='small' />
                        <span className='switch_span'>High Value Clearance</span>
                      </Form.Item>
                    </Col>
                  </Row>

                  {props.current === 4 && <div className={`button-block`}>
                    <Button htmlType="submit" type="primary" className={"blue-button"}>
                      {'Save & Continue'}
                    </Button>
                  </div>}
                </Col>
              </FormikForm>
            )
          }
          }
        </Formik>
      </Col>
    </Row>
  )

}

export const RecommendedServicesFormTitle = (props: PropTypes) => {
  return (
    <div className={"title-block"}>
      <p className={'step-title'}>{'Recommended Services (Optional)'}</p>
      {props.current > 4 ? <p onClick={() => props.setCurrentState(4)} className={"edit-text"}>{'Edit details'}</p> : ''}
    </div>
  )
}