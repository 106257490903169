import { useEffect } from "react"
import { embedDashboard } from "@superset-ui/embedded-sdk"
import { useMutation } from "react-query"
import { getSupersetDataLogin, getSupersetDataGuest } from "../orders/services/privateApi";
import { useAuth } from "../auth/services/authContext";
import { useLocation } from "react-router";
import { message } from "antd";
import { featureFlag } from "../commons/utils/utilizer";

interface SuperSetLogin {
  access_token: string,
  refresh_token: string
}

const DashboardHeaderMapping: any = {
  order_statistics: 'Order Statistics',
  delivery_performance: 'Delivery Performance',
  transit_times: 'Transit Times',
  risks_exceptions: 'Risks & Exceptions',
  estimated_delivery_date: 'Estimated Delivery Dates'
}
export const SupersetDashboardApp = () => {
  // const location: {id: string} = useParams()
  const path: {pathname: string} = useLocation()
  const headingName = path.pathname.split('/')![3]
  const auth = useAuth()
  const supersetDataLogin = useMutation(async () => {
    let param = {
      "username": "embedded-admin",
      "password": "QdV6kp1Eb1K",
      // >>>>>>>>>>>>check this>>>>>>>>>
      "refresh": false,
      "provider": "db"
    }
    return getSupersetDataLogin(param)
  } ,  {
    onSuccess: (val: SuperSetLogin) => {
      const dataSet = path.pathname.includes('estimated_delivery_date') ? 38: 36
      let dashboardId = () => {
        let id;
        if(path.pathname.includes('order_statistics')) {
          return id = '04fbc53a-ad12-4204-9ac0-80d915f65cb4'
        }
        if(path.pathname.includes('delivery_performance')) {
          return id = 'c2142884-3253-4b8d-8c81-0173839f535d'
        }
        if(path.pathname.includes('transit_times')) {
          return id = '8fd45bf8-11e1-444f-a9dc-2d0bdde54b54'
        }
        if(path.pathname.includes('risks_exceptions')) {
          return id = 'c95d521f-aa9b-4af0-adc6-8783d2367c0d'
        }
        if(path.pathname.includes('estimated_delivery_date')) {
          return id = '29a3854d-877a-4018-9edd-e4167fd13128'
        }
        return id
      }
      const embed = async () => {
        let b2c_id;
        
        if(featureFlag()){
          b2c_id = 7172;
        } else{
          if(auth.user.b2c_id === auth.user.master_b2c_id) {
            b2c_id = auth.user.b2c_id
          }else if(auth.user.b2c_id !== auth.user.master_b2c_id && auth.user.can_view_master_shipments) {
            b2c_id = auth.user.master_b2c_id
          } else {
            b2c_id = auth.user.b2c_id
          }
        }
        
        let params = {
          "resources": [
            {
              "id": dashboardId()!,
              "type": "dashboard"
            }
          ],
          "rls": [
            {
              "clause": `agent_application_id=${b2c_id}`,
              "dataset": dataSet
            }
          ],
          "user": {
            "first_name": "embedded",
            "last_name": "analytics",
            "username": "embedded-analytics",
            // "password": "Test@1234",
          }
        }
        await embedDashboard({
          id: dashboardId()!,
          supersetDomain: "https://embedapp.janio.asia",
          // supersetDomain: "http://superset.janio.asia",
          mountPoint: document.getElementById("dashboard")!, // html element in which iframe render
          fetchGuestToken: () => getSupersetDataGuest(params, val.access_token),
          dashboardUiConfig: {
            hideTitle: true,
            hideChartControls: path.pathname.includes('estimated_delivery_date') ? false : true,
            hideTab: true
          }
        })
      }
      if(document.getElementById("dashboard")) {
        embed()
      }
    },
    onError: (error: any) => {
      if(error.response.data) {
        message.error(error.response.data.message)
      }else {
        message.error('We are facing some Issues, Please try after sometime.')
      }
      
    },
  });
  
  // console.log('supersetDatasupersetData', supersetDataGuest)
  useEffect(() => {
    supersetDataLogin.mutate()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="App">
      <h1>{DashboardHeaderMapping[headingName]!}</h1>
      <div id="dashboard" />
    </div>
  )
}