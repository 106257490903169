import {
  Card,
  Row,
  Col,
  Button
} from 'antd'
// @ts-ignore
import {Link} from "react-router-dom";

interface AccountProps {
  emailSuccess: string
}
export const AccountAddedSuccessfully = (props: AccountProps) =>  {
  return (
    <>
      <Row gutter={24}>
        <Col span={8} className="success_container wallet_container" style={{marginTop: '60px'}}>
          <Card>
            <div className="check_mark">
              <main></main>
            </div>

            <p style={{fontSize: '16px'}}>Sub-account created!</p>
            <span style={{'color': '#000', 'display': 'block', 'margin': '-12px 0 10px 0'}}>We have sent an invitation link to <strong>{props.emailSuccess}</strong></span>
            
            <Row justify={'center'}>
              <Link to={"/settings/manage_accounts"}>
                <Button style={{'color': '#fff'}}>OK</Button>
              </Link>
            </Row>

          </Card>
        </Col>
      </Row>
    </>
  )
};

export const AccountTransferredSuccessfully = (props: AccountProps) =>  {
  return (
    <>
      <Row gutter={24}>
        <Col span={8} className="success_container wallet_container" style={{marginTop: '60px'}}>
          <Card>
            <div className="check_mark">
              <main></main>
            </div>

            <p style={{fontSize: '16px'}}>Successfully Transferred!</p>
            <span style={{'color': '#000', 'display': 'block', 'margin': '-12px 0 10px 0'}}><strong>{props.emailSuccess}</strong> is now under you as a sub-account</span>
            
            <Row justify={'center'}>
              <Col span="12">
                <Link to={"/settings/manage_accounts"}>
                  <Button style={{'color': '#fff'}}>OK</Button>
                </Link>
              </Col>
            </Row>

          </Card>
        </Col>
      </Row>
    </>
  )
};