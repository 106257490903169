import {
  Card,
  Row,
  Col,
  Steps,
  Spin,
  Alert,
} from 'antd'
import React, {useEffect, useState} from 'react';
import {ShipmentDetailsForm, ShipmentDetailsFormTitle} from "./components/singleOrder/ShipmentDetailsForm";
import {ConsigneeDetailsForm, ConsigneeDetailsFormTitle} from './components/singleOrder/ConsigneeDetailsForm';
import {ItemDetailsForm, ItemDetailsFormTitle} from "./components/singleOrder/ItemDetailsForm";
import {CreateShopifyOrder, CreateShopifyOrderTitle} from "./components/singleOrder/CreateShopifyOrder";
import './styles/ShipmentPage.css';
import '../dashboard/styles/HomePage.css';
import {Link} from "react-router-dom";
import {CaretLeftOutlined} from '@ant-design/icons'
import { useQuery } from "react-query";
import {useAuth} from "../auth/services/authContext";
import { fetchMarketplaceOrderFromB2C, FetchOrderResponseFromB2C_Shopify } from './services/privateApi';
import { useParams } from 'react-router-dom';
import { SingleOrderContextProviderForB2C} from "../orders/services/singleOrderContext";
import { countryCallingCodes } from '../commons/utils/constants';
import { useLanguage } from '../languages/Language';

const {Step} = Steps;

const removeCountryPrefix = (country_code: any, phone_no: string) => {
  if(phone_no.startsWith('+')){
    const code = countryCallingCodes[country_code]
    return phone_no.replace(code, '')
  }

  return phone_no
}

const OrderForm = () => {
  
  let { getText } = useLanguage();
  const authSecretKey = useAuth().user.secret_key;
  const orderID = useParams<any>()
  const [current, setCurrent] = useState(2);
  const [progress, setProgress] = useState(0);
  const [refetchQuery, setRefetchQuery] = useState(false)

  const setCurrentState = (val: number) => {
    setCurrent(val);
  };

  const getSingleOrderDataQuery = useQuery(
    ["getSingleOrderData", orderID.id],
    async () => {
      return fetchMarketplaceOrderFromB2C(authSecretKey, orderID.id)
    },
    { cacheTime: 0 }
  );


  // const pickUpDateExpired = pickupDateHasExpired(getSingleOrderDataQuery.data?.pickup_date)
  const orderFormat: any = (value: FetchOrderResponseFromB2C_Shopify) => {
    return {
      id: value.id,
      order_id: value.order_id,
      channel: value.channel,
      service_id: value.service_id,
      tracking_no: value.tracking_no,
      shipper_order_id: value.shipper_order_id,
      status: value.status,
      consignee_name: value.consignee_name,
      consignee_address: value.consignee_address,
      consignee_country: value.consignee_country,
      consignee_state: value.consignee_state,
      consignee_city: value.consignee_city,
      consignee_province: value.consignee_province,
      consignee_number: removeCountryPrefix(
        value.consignee_country,
        value.consignee_number
      ),
      consignee_postal: value.consignee_postal,
      consignee_email: value.consignee_email,
      // consignee_id: value.consignee_id,
      // consignee_id_type: value.consignee_id_type,
      pickup_contact_name: value.pickup_contact_name,
      pickup_contact_number: value.pickup_contact_number,
      pickup_address: value.pickup_address,
      pickup_state: value.pickup_state,
      pickup_city: value.pickup_city,
      pickup_province: value.pickup_province,
      pickup_postal: value.pickup_postal,
      pickup_country: value.pickup_country,
      pickup_date: value.pickup_date,
      pickup_point_id: value.extra_data.pickup_point_id,
      serviceDefinition: {dropoff_id: value.extra_data.dropoff_id},
      dropoff_id: value.extra_data.dropoff_id,
      fulfillment_status: value.fulfillment_status,
      cod_amt_to_collect: value.cod_amt_to_collect,
      cod_currency: value.cod_currency,
      extra_data: value.extra_data,
      channel_order_id: value.channel_order_id,
      other_status: value.other_status,
      order_length: value.order_length,
      order_width: value.order_width,
      order_height: value.order_height,
      order_weight: value.order_weight,
      payment_type: value.payment_type,
      delivery_note: value.delivery_note,
      incoterm: value.incoterm,
      serviceType: value.service_type,
      payment_status: value.payment_status,
      validation_result: value.validation_result,
      amount: null,
      currency: '',
      amount_to_pay: null,
      gst_amount: '',
      total_duties: '',
      total_taxes: '',
      charge_id: null,
      items: value.items.map(item => ({
        id: item.id,
        item_desc: item.item_description,
        item_category: item.item_category,
        item_quantity: item.item_qty,
        item_price_currency: item.item_price_currency,
        item_price_value: item.item_price_value,
        product_id: '',
        item_sku: item.item_sku,
      }))
    }
  }

  useEffect(() => {
    if (current > progress) {
      setProgress(current);
    }
    if(refetchQuery) {
      getSingleOrderDataQuery.refetch()
    }
  }, [current, progress]); //eslint-disable-line react-hooks/exhaustive-deps


  const createErrorAlert = () => {
    let errorDataArr = []
    if (getSingleOrderDataQuery.data) {
      let data = orderFormat(getSingleOrderDataQuery && getSingleOrderDataQuery.data!)
      const validationErrorMessages = data?.validation_result?.errors ?? {}
      if((validationErrorMessages.pickup_contact_number || validationErrorMessages.pickup_address || validationErrorMessages.pickup_city || validationErrorMessages.pickup_contact_name || validationErrorMessages.pickup_postal || validationErrorMessages.pickup_state) && (data.serviceType === 'Pickup' || data.serviceType === 'Pickup_Express')) {
        errorDataArr.push({
          errorType: 'Shipment Overview',
          detailError: 'Pickup Address',
          action: 'Please select Pickup Address',
        })
      }
      if(validationErrorMessages.pickup_date) {
        errorDataArr.push({
          errorType: 'Shipment Overview',
          detailError: 'Pickup Date',
          action: validationErrorMessages.pickup_date.join(', '),
        })
      }
      if(validationErrorMessages.service_id || getSingleOrderDataQuery.data.service_type === null) {
        errorDataArr.push({
          errorType: 'Shipment Overview',
          detailError: 'Service Type',
          action: 'Please select Service Type',
        })
      }
      if(validationErrorMessages.pickup_contact_number) {
        errorDataArr.push({
          errorType: 'Shipment Overview',
          detailError: 'Pickup Contact Number',
          action: validationErrorMessages.pickup_contact_number.join(', '),
        })
      }
      if(validationErrorMessages.consignee_country) {
        errorDataArr.push({
          errorType: 'Shipment Overview',
          detailError: 'Consignee Country',
          action: validationErrorMessages.consignee_country.join(', '),
        })
      }
      if(validationErrorMessages.consignee_address) {
        errorDataArr.push({
          errorType: 'Customer Details',
          detailError: 'Consignee Address',
          action: validationErrorMessages.consignee_address.join(', '),
        })
      }
      if(validationErrorMessages.consignee_name) {
        errorDataArr.push({
          errorType: 'Customer Details',
          detailError: 'Consignee Name',
          action: validationErrorMessages.consignee_name.join(', '),
        })
      }
      if(validationErrorMessages.consignee_number) {
        errorDataArr.push({
          errorType: 'Customer Details',
          detailError: 'Consignee Number',
          action: validationErrorMessages.consignee_number.join(', '),
        })
      }
      if(validationErrorMessages.consignee_email) {
        errorDataArr.push({
          errorType: 'Customer Details',
          detailError: 'Consignee Email',
          action: validationErrorMessages.consignee_email.join(', '),
        })
      }
      if(validationErrorMessages.consignee_state) {
        errorDataArr.push({
          errorType: 'Customer Details',
          detailError: 'Consignee State',
          action: validationErrorMessages.consignee_state.join(', '),
        })
      }
      if(validationErrorMessages.consignee_postal) {
        errorDataArr.push({
          errorType: 'Customer Details',
          detailError: 'Consignee Postal Code',
          action: validationErrorMessages.consignee_postal.join(', '),
        })
      }
      if(validationErrorMessages.consignee_city) {
        errorDataArr.push({
          errorType: 'Customer Details',
          detailError: 'Consignee City',
          action: validationErrorMessages.consignee_city.join(', '),
        })
      }
      if(validationErrorMessages.order_height) {
        errorDataArr.push({
          errorType: 'Item & Parcel Overview',
          detailError: `Order height`,
          action: validationErrorMessages.order_height.join(', ')
        })
      }
      if(validationErrorMessages.order_length) {
        errorDataArr.push({
          errorType: 'Item & Parcel Overview',
          detailError: `Order length`,
          action: validationErrorMessages.order_length.join(', '),
        })
      }
      if(validationErrorMessages.order_width) {
        errorDataArr.push({
          errorType: 'Item & Parcel Overview',
          detailError: `Order width`,
          action: validationErrorMessages.order_width.join(', '),
        })
      }
      if(validationErrorMessages.order_weight) {
        errorDataArr.push({
          errorType: 'Item & Parcel Overview',
          detailError: `Order weight`,
          action: validationErrorMessages.order_weight.join(', '),
        })
      }
      validationErrorMessages?.items?.map((item: any) => {
        if(item.item_category) {
          errorDataArr.push({
            errorType: 'Item & Parcel Overview',
            detailError: 'Item Category',
            action: item.item_category.join(', ')
          })
        }
        if(item.item_price_value) {
          errorDataArr.push({
            errorType: 'Item & Parcel Overview',
            detailError: 'Item Price Value',
            action: item.item_price_value.join(', ')
          })
        }
        if(item.item_description) {
          errorDataArr.push({
            errorType: 'Item & Parcel Overview',
            detailError: 'Item Descrition',
            action: item.item_description.join(', ')
          })
        }
        if(item.item_price_currency) {
          errorDataArr.push({
            errorType: 'Item & Parcel Overview',
            detailError: 'Item Price Currency',
            action: item.item_price_currency.join(', ')
          })
        }
        if(item.item_qty) {
          errorDataArr.push({
            errorType: 'Item & Parcel Overview',
            detailError: 'Item Quanity',
            action: item.item_qty.join(', ')
          })
        }
        return null
      })
      return errorDataArr
    }
    return null
  }

  const showMissingDetailsError = () => {
    if(createErrorAlert()!.length > 0) {
      
      return createErrorAlert()?.map((item: any) => {
        return (
          <div className="shopify-error-msg">
            <p className="error-msg">
              <span className="error-msg-title">{item.errorType}</span>
              <ul className="error-msg-content">
                <li className="error-msg-item">
                  <b>{item.detailError}:</b> {item.action}
                </li>
              </ul>
            </p>
          </div>
        )
      })
    }
    return null
  }

  const errorClasses = () => {
    let classesError: any = ['shopify-error']

    if(createErrorAlert()!.length > 0) {
      createErrorAlert()?.map((item: any) => {
        if(item.errorType === 'Customer Details') {
          classesError.push('customer-form-error')
        }
        if (item.errorType === 'Shipment Overview') {
          classesError.push('shipment-form-error')
        }
        if (item.errorType === 'Item & Parcel Overview') {
          classesError.push('item-form-error')
        }
        return null
      })
      return classesError
    }
    return 'shopify-error'
  }

  let steps = [
    {
      title: <ShipmentDetailsFormTitle current={current} setCurrentState={setCurrentState} dataFilled={1}  />,
      content: <ShipmentDetailsForm current={current} setCurrentState={setCurrentState} dataFilled={1} isMarketplaceOrder />,
    },
    {
      title: <ConsigneeDetailsFormTitle current={current} setCurrentState={setCurrentState} dataFilled={2}/>,
      content: <ConsigneeDetailsForm current={current} setCurrentState={setCurrentState} dataFilled={2}/>,
    },
    {
      title: <ItemDetailsFormTitle current={current} setCurrentState={setCurrentState} dataFilled={3}  />,
      content: <ItemDetailsForm current={current} setCurrentState={setCurrentState} dataFilled={3} setRefetchQuery={setRefetchQuery} isMarketplaceOrder />,
    },
    {
      title: <CreateShopifyOrderTitle current={current} setCurrentState={setCurrentState} />,
      content: <CreateShopifyOrder current={current} setCurrentState={setCurrentState} isEditing isMarketplaceOrder showCard={createErrorAlert()!?.length > 0 ? false: true} />,
    }
  ];
  return getSingleOrderDataQuery.data ? (
    <SingleOrderContextProviderForB2C
      order={orderFormat(
        getSingleOrderDataQuery.data
      )}
    >
      {/* {pickUpDateExpired ? (
        <Alert
          className="custom-alert"
          message="Alert"
          description={getText(
            "You are updating the order from the past. Please select pickup date again."
          )}
          type="warning"
          showIcon
          closable
        />
      ) : null} */}
      {createErrorAlert()!.length > 0 && (
        <Alert
          message={`${getText("Please provide the information below")}:`}
          description={showMissingDetailsError()}
          type="error"
          showIcon
          className={errorClasses()}
          closable
        />
      )}
      <Steps
        direction="vertical"
        current={current}
        className={"form-progress-bar"}
      >
        {steps.map((item, index) => (
          <Step
            key={index}
            title={item.title}
            description={
              current >= index || progress >= index ? item.content : ""
            }
          />
        ))}
      </Steps>
    </SingleOrderContextProviderForB2C>
  ) : (
    <Spin style={{ position: "absolute", left: "50%" }} />
  );
};

export const PayMarketplaceOrder = () => {
  let { getText } = useLanguage();
  return (
    <Row align="middle" justify="center">
      <Col span={16} className={'order-form-container'}>
        <Link to={"/orders/create"}>
          <p className="go-back-btn"><CaretLeftOutlined/> {getText('Create shipment')} </p>
        </Link>
        <Card className={'form-card'}>
          <OrderForm />
        </Card>
      </Col>
    </Row>
  )
};
