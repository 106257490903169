import {loadStripe} from '@stripe/stripe-js';

export const stripePayment = async (val: any) => {
  // Get Stripe.js instance
  const host = window.location.hostname;
  const key = host === 'ss.janio.asia' ? 'pk_live_51IaIGnJ6AZ8Y7KdBHFPphsb4qY82njVl8eNlhdwtBdkkvaxYZabftqrMWNfDhDLwC5Gg7qXdtpKOExaZzWz6nOQD00tpDxs03N'
    : 'pk_test_51IaIGnJ6AZ8Y7KdBXfeYk4enxaZw9Oy0lxtHxKXN7LnvsWYTJkTvOG24wBwky49dzPit5M9qemVjCn8qNJb0doEo00Yva9zWH8';

  const stripe = await loadStripe(key)

  // When the customer clicks on the button, redirect them to Checkout.
  stripe?.redirectToCheckout({
    sessionId: val.gateway_payload.session_id
  })
}