import { Row,Col } from 'antd';
import '../styles/style.css'
import ReportsFilters from '../../ManageShipments/Components/ReportsFilters';

export const ReportsFilteredData = (props:any) => {
  return (
    <>
      <Row className={'main-container reports-main-container'} style={{'position': 'relative'}} gutter={16}>
        <Col span={24} className={'filter-wrapper reports-filter-wrapper'} 
          // style={{top: '30px'}}
          style={{padding: '0'}}
        >
          <Col span={24} style={{padding: '0'}}>
            <ReportsFilters
            />
          </Col>
        </Col>
      </Row>
    </>
    
  )
};