import React, { useState, createContext, ReactNode, useContext } from 'react';
import { LanguageInterface } from './LangauageInterfaces'
import { dictionaryList } from './index';
import {useLocation} from 'react-router-dom';

export const LanguagePreferences: {[key: string]: string} = {
  en: 'English',
  id: 'Bahasa Indonesia'
};

interface LanguageContext {
  userLanguage: string,
  getText: (text: keyof LanguageInterface) => string
  setLanguage: (lang: string) => void
}

// create the language context with default selected language
const languageContext = createContext<LanguageContext>({} as LanguageContext);

// it provides the language context to app
export const LanguageProvider = ({ children }: { children: ReactNode }) => {
  const {search} = useLocation();
  const queryLanguage = new URLSearchParams(search).get('lang');
  const sessionLanguage = window.localStorage.getItem('rcml-lang');
  let defaultLanguage = sessionLanguage ? sessionLanguage : queryLanguage;
  const [userLanguage, setUserLanguage] = useState(defaultLanguage || 'en');
  const getText = (text: keyof LanguageInterface) => {
    let dictionaryText = dictionaryList[userLanguage][text]
    if(dictionaryText) {
      return dictionaryText;
    }
    return text
  }

  const provider = {
    userLanguage,
    getText,
    setLanguage: (selected: string) => {
      const newLanguage = LanguagePreferences[selected] ? selected : 'en'
      setUserLanguage(newLanguage);
      window.localStorage.setItem('rcml-lang', newLanguage);
    }
  };

  return (
    <languageContext.Provider value={provider}>
      {children}
    </languageContext.Provider>
  );
};


export const useLanguage = () => {
  return useContext(languageContext);
}
