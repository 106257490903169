import React, { useState } from 'react'
import {Row, Col, Select, Button, Card, Radio} from 'antd';
import * as Yup from 'yup';
import {Formik, Form as FormikForm} from 'formik';
import { FormSelect, FormInput } from '../../orders/components/Forms';
import '../b2bOrders.css'

import { useB2BOrderContext } from "../b2bOrderCreationContext"
// import { getAllCountries_from_b2c } from '../../orders/services/privateApi';
// import { useQuery } from 'react-query';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select;
interface PropTypes {
  current: number,
  setCurrentState: (val: number) => void,
}

interface FormValues {
  calculation_type: string,
  package_type: string
  no_of_units: string | number
  pallet_type: string
  dimension_length: string | number,
  dimension_width: string | number,
  dimension_height: string | number,
  dimension_weight: string | number,
  // boxes_type: string,
  total_units_for_calculate_by_total_shipments: string | number,
  total_volume: string | number,
  total_weight: string | number
}

const validationSchema = (calculationType: string, PackageType: string) => {
  if(calculationType === 'calculate_by_unit_type') {
    return (
      Yup.object().shape({
        calculation_type: Yup.string().required('Please select Calculation type'),
        package_type: Yup.string().required('Please select Package type'),
        no_of_units: Yup.string().required('Please input No of units'),
        pallet_type: Yup.string().nullable().when('package_type', {
          is: ('pallets'),
          then: Yup.string().required('Please select Pallet type')
        }),
        // boxes_type: Yup.string().nullable().when('package_type', {
        //   is: ('boxes'),
        //   then: Yup.string().required('Please select Boxes type')
        // }),
        dimension_length: Yup.string().required('Please input dimension length'),
        dimension_width: Yup.string().required('Please input dimension width'),
        dimension_height: Yup.string().required('Please input dimension height'),
        dimension_weight: Yup.string().required('Please input dimension weight')
      })
    )
  } else {
    return (
      Yup.object().shape({
        total_units_for_calculate_by_total_shipments: Yup.string().required('Please input number of units for calculating'),
        total_volume: Yup.string().required('Please input total volume'),
        total_weight: Yup.string().required('Please input total weight'),
      })
    )
  }
}

const PalletsTypes = {
  pallets_non_specified_size: "Pallets (non specified size)",
  "48_40": "48” x 40”",
  pallets_specified_size_EUR_1: "120 x 80CM (EUR 1)",
  pallets_specified_size_EUR_2: "120 x 100CM (EUR 2)",
}

// const BoxesTypes = {
//   boxes: "Boxes",
//   crates: "Crates"
// }

export const ShippingDetailsForm = (props: PropTypes) => {
  const [radioValue, setRadioValue] = useState('calculate_by_unit_type');
  const [packageTypeValue, setPackageTypeValue] = useState('pallets');
  const [isLengthWidthDisabled, setIsLengthWidthDisabled] = useState(false)
  const orderContextData = useB2BOrderContext()

  const resetFieldValuesOnCalculationTypeChange = ['no_of_units', 'pallet_type', 'dimension_length', 'dimension_width', 'dimension_height', 'dimension_weight', 'total_units_for_calculate_by_total_shipments', 'total_volume', 'total_weight'];
  const resetFieldValuesOnPackageTypeChange = ['pallet_type'];

  const initialValues = {
    calculation_type: orderContextData.b2bOrderData.calculation_type ? orderContextData.b2bOrderData.calculation_type : radioValue,
    package_type: orderContextData.b2bOrderData.package_type ? orderContextData.b2bOrderData.package_type : packageTypeValue,
    no_of_units: orderContextData.b2bOrderData.no_of_units,
    pallet_type: orderContextData.b2bOrderData.pallet_type,
    // boxes_type: orderContextData.b2bOrderData.boxes_type,
    dimension_length: orderContextData.b2bOrderData.dimension_length,
    dimension_width: orderContextData.b2bOrderData.dimension_width,
    dimension_height: orderContextData.b2bOrderData.dimension_height,
    dimension_weight: orderContextData.b2bOrderData.dimension_weight,
    total_units_for_calculate_by_total_shipments: orderContextData.b2bOrderData.total_units_for_calculate_by_total_shipments,
    total_volume: orderContextData.b2bOrderData.total_volume,
    total_weight: orderContextData.b2bOrderData.total_weight
  }

  const onFormikSubmit = (values: FormValues) => {
    let updateData = {
      calculation_type: radioValue,
      package_type: packageTypeValue,
      no_of_units: values.no_of_units,
      pallet_type: values.pallet_type,
      // boxes_type: values.boxes_type,
      dimension_length: values.dimension_length,
      dimension_width: values.dimension_width,
      dimension_height: values.dimension_height,
      dimension_weight: values.dimension_weight,
      total_units_for_calculate_by_total_shipments: values.total_units_for_calculate_by_total_shipments,
      total_volume: values.total_volume,
      total_weight: values.total_weight
    }

    orderContextData.updateB2BOrderData(updateData)
    props.setCurrentState(3)
  }

  const formikSetlengthWidth = (formik: any, length: number | string, width: number | string, disabled: boolean) => {
    formik.setFieldValue('dimension_length', length)
    formik.setFieldValue('dimension_width', width)
    setIsLengthWidthDisabled(disabled)
  }

  const calculateLengthWidth = (pallet: string, formik: any) => {
    if(pallet === "48_40"){
      formikSetlengthWidth(formik, 48, 40, true)
    }else if(pallet === "pallets_specified_size_EUR_1"){
      formikSetlengthWidth(formik, 120, 80, true)
    }else if(pallet === "pallets_specified_size_EUR_2"){
      formikSetlengthWidth(formik, 120, 100, true)
    }else{
      formikSetlengthWidth(formik, '', '', false)
    }
  }

  if (props.current > 2) {
    return (
      <>
        {<Card title={'Shipping Overview'} className={"form-summary-container"} style={{fontSize: '19px'}}>
          <p style={{ margin: 0 }} className={'gray-text'}>Calculation Type: <span style={{color: '#000', fontSize: '17px'}}>{orderContextData.b2bOrderData.calculation_type! === 'calculate_by_unit_type' ? 'Calculate by unit type' 
            : 'Calculate by total shipment'}</span></p>
          
          {orderContextData.b2bOrderData.calculation_type === 'calculate_by_unit_type' &&
            <>
              <p className={'gray-text'}>Package Type: <span style={{color: '#000', fontSize: '17px'}}>{orderContextData.b2bOrderData.package_type!}</span></p>
              {orderContextData.b2bOrderData.package_type === 'pallets' && 
                <p className={'gray-text'}>Pallet Type: <span style={{color: '#000', fontSize: '17px'}}>{(PalletsTypes as any)[(orderContextData.b2bOrderData.pallet_type!)]}</span></p>
              }
              {/* {orderContextData.b2bOrderData.package_type === 'boxes' && 
                <p className={'gray-text'}>Boxes Type: <span style={{color: '#000', fontSize: '17px'}}>{(BoxesTypes as any)[orderContextData.b2bOrderData.boxes_type!]}</span></p>
              } */}
              <p className={'gray-text'}>Dimensions (LxWxH per Unit): <span style={{color: '#000', fontSize: '17px'}}>{orderContextData.b2bOrderData.dimension_length!} x {orderContextData.b2bOrderData.dimension_width!} x {orderContextData.b2bOrderData.dimension_height!}</span></p>
              <p className={'gray-text'}>Weight (per Unit): <span style={{color: '#000', fontSize: '17px'}}>{orderContextData.b2bOrderData.dimension_weight!} KG</span></p>
            </>
          }
          
          {orderContextData.b2bOrderData.calculation_type === 'calculate_by_total_shipment' &&
            <>
              <p className={'gray-text'}>Total Volume (CBM): <span style={{color: '#000', fontSize: '17px'}}>{orderContextData.b2bOrderData.total_volume!} KG</span></p>
              <p className={'gray-text'}>Total Weight: <span style={{color: '#000', fontSize: '17px'}}>{orderContextData.b2bOrderData.total_weight!} KG</span></p>
            </>
          }
        </Card>}
      </>
    )
  }

  return (
    <Row className='claim-main-container single-order-form-container ant-form-vertical'>
      <Col span={24}>
        <Formik 
          initialValues={initialValues}
          onSubmit={onFormikSubmit}
          validationSchema={() => validationSchema(radioValue, packageTypeValue)}
        >
          {formik => {
            return (
              <FormikForm className='dispute-main-container'>
                <Col span={24}>
                  <Radio.Group name='calculation_type' onChange={
                    (e: any) => {
                      formik.setFieldValue('calculation_type', e.target.value)
                      setRadioValue(e.target.value);
                      resetFieldValuesOnCalculationTypeChange.forEach(field => formik.setFieldValue(field, ''))
                    }
                  } value={radioValue} id='b2b_radio'>
                    <Radio value={'calculate_by_unit_type'}>Calculate by unit type</Radio>
                    <Radio value={'calculate_by_total_shipment'}>Calculate by total shipment</Radio>
                  </Radio.Group>
                  
                  {radioValue === 'calculate_by_unit_type' &&
                    <>
                      <Row>
                        <Col span={16}>
                          <h4 style={{marginTop: '10px', fontWeight: 'bold'}}>Package type</h4>
                          <Radio.Group value={packageTypeValue} defaultValue={packageTypeValue} name='package_type' buttonStyle="solid" onChange={(e: any) => {
                            formik.setFieldValue('package_type', e.target.value)
                            setPackageTypeValue(e.target.value);
                            resetFieldValuesOnPackageTypeChange.forEach(field => formik.setFieldValue(field, ''))
                          }} className='b2b_custom_radios'>
                            <Col span={12}>
                              <Radio.Button value="pallets">Pallets</Radio.Button>
                            </Col>
                            <Col span={12}>
                              <Radio.Button value="boxes">Box/Carton/Bag</Radio.Button>
                            </Col>
                          </Radio.Group>
                        </Col>
                        <Col span={8} className='custom-input-height'>
                          <FormInput name={'no_of_units'} label={<h4 style={{marginTop: '10px', fontWeight: 'bold'}}># of units</h4>} inputProps={{type: 'number', min: 0, step:1.00}} />
                        </Col>
                      </Row>

                      <Col span={24}>
                        <Row>
                          <Col span={packageTypeValue === 'pallets'? 8:0} className='border-padding-helper'>
                            {packageTypeValue === 'pallets' &&
                            <>
                              <h4 style={{marginTop: '10px', fontWeight: 'bold'}}>Pallet type</h4>
                              <FormSelect name="pallet_type" label={''}  placeholder='Please select Pallet type' onChangeCallback={(value: string) => calculateLengthWidth(value, formik)}>
                                {
                                  Object.entries(PalletsTypes).map(([itemKey, itemValue]: [string, string]) => {
                                    return <Option key={itemKey} value={itemKey}>{itemValue}</Option>
                                  })
                                }
                              </FormSelect>
                            </>
                            }

                            {/* {packageTypeValue === 'boxes' &&
                            <>
                              <h4 style={{marginTop: '10px', fontWeight: 'bold'}}>Boxes type</h4>
                              <FormSelect name="boxes_type" label={''}  placeholder='Please select boxes type'>
                                {
                                  Object.entries(BoxesTypes).map(([itemKey, itemValue]: [string, string]) => {
                                    return <Option key={itemKey} value={itemKey}>{itemValue}</Option>
                                  })
                                }
                              </FormSelect>
                            </>
                            } */}
                          </Col>
                          
                          <Col span={packageTypeValue === 'pallets'? 16: 24} className='custom-input-height border-padding-helper'>
                            <Col span={24}>
                              <h4 style={{marginTop: '10px', fontWeight: 'bold', marginLeft: '16px'}}>Dimensions <span style={{color: '#878484', fontWeight: 'normal'}}>(LxWxH per Unit)</span></h4>
                              <Row style={{marginTop: '16px'}}>                          
                                <Col span={6}>
                                  <FormInput name={'dimension_length'} label={''} placeholder= 'Length (cm)' inputProps={{type: 'number', min: 0, step:1.00}} disabled={isLengthWidthDisabled}/>
                                </Col>

                                <Col span={6}>
                                  <FormInput name={'dimension_width'} label={''} placeholder= 'Width (cm)' inputProps={{type: 'number', min: 0, step:1.00}} disabled={isLengthWidthDisabled}/>
                                </Col>

                                <Col span={6}>
                                  <FormInput name={'dimension_height'} label={''} placeholder= {`Height ${formik.values.pallet_type === '48_40'? '(in)': '(cm)'}`} inputProps={{type: 'number', min: 0, step:1.00}} />
                                </Col>

                                <Col span={6} className='custom-input-height' style={{marginTop: '-17px'}}>
                                  <span style={{fontWeight: 'bold', display: 'block', margin: '-28px 0px 18px 16px', fontSize: '16px'}}>Weight <span style={{color: '#878484', fontWeight: 'normal'}}>(Per Unit)</span></span>
                                  <FormInput name={'dimension_weight'} label={''} placeholder= 'Weight (kg)' inputProps={{type: 'number', min: 0, step:.01, addonAfter: 'KG'}} />
                                </Col>
                              </Row>
                            </Col>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  }

                  {radioValue === 'calculate_by_total_shipment' &&
                    <>
                      <Col span={24} className='discliamer-b2b'>
                        <div><InfoCircleOutlined />Calculating by total shipment is less accurate and therefore more likely to incur an additional charge for inaccurate or oversized dimensions.</div>
                      </Col>
                      <Row>
                        <Col span={16}>
                          <h4 style={{marginTop: '10px', fontWeight: 'bold'}}>Package type</h4>
                          <Radio.Group value={packageTypeValue} defaultValue={packageTypeValue} name='package_type' buttonStyle="solid" onChange={(e: any) => {
                            formik.setFieldValue('package_type', e.target.value)
                            setPackageTypeValue(e.target.value);
                            resetFieldValuesOnPackageTypeChange.forEach(field => formik.setFieldValue(field, ''))
                          }} className='b2b_custom_radios'>
                            <Col span={12}>
                              <Radio.Button value="pallets">Pallets</Radio.Button>
                            </Col>
                            <Col span={12}>
                              <Radio.Button value="boxes">Box/Carton/Bag</Radio.Button>
                            </Col>
                          </Radio.Group>
                        </Col>
                        <Col span={8} className='custom-input-height'>
                          <FormInput name={'total_units_for_calculate_by_total_shipments'} label={<h4 style={{marginTop: '10px', fontWeight: 'bold'}}># of units</h4>} inputProps={{type: 'number', min: 0, step:1.00}} />
                        </Col>
                      </Row>
                      <Row gutter={16}>                

                        <Col span={8}>
                          <h4 style={{marginTop: '10px', fontWeight: 'bold'}}>Total volume</h4>
                          <FormInput name={'total_volume'} label={''} placeholder= 'Total volume' inputProps={{type: 'number', min: 0, step:1.00, addonAfter: 'CBM'}} />
                        </Col>

                        <Col span={8}>
                          <h4 style={{marginTop: '10px', fontWeight: 'bold'}}>Total weight</h4>
                          <FormInput name={'total_weight'} label={''} placeholder='Total weight' inputProps={{type: 'number', min: 0, step:.01, addonAfter: 'KG'}} />
                        </Col>
                      </Row>
                    </>
                    
                  }
                  
                  {props.current === 2 &&
                    <div className={`button-block`}>
                      <Button htmlType="submit" type="primary" className={"blue-button"}>
                        {'Save & Continue'}
                      </Button>
                    </div>
                  }
                </Col>
              </FormikForm>
            )
          }
          }
        </Formik>
      </Col>
    </Row>
  )

}

export const ShippingDetailsFormTitle = (props: PropTypes) => {
  return (
    <div className={"title-block"}>
      <p className={'step-title'}>{'What are you shipping?'}</p>
      {props.current > 2 ? <p onClick={() => props.setCurrentState(2)} className={"edit-text"}>{'Edit details'}</p> : ''}
    </div>
  )
}