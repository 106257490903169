import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row
} from 'antd';
import {useAuth} from "../services/authContext";
import {useMutation} from "react-query";
import {update_user_profile as updateUserProfile} from "../services/authApi";
import {useState} from "react";
import {countryCallingCodes} from "../../commons/utils/constants";
import ReactFlagsSelect from "react-flags-select/build";
import { useLanguage } from '../../languages/Language';

export const ProfileForm = () => {
  const auth = useAuth();
  const authToken = auth.user.authToken;
  const { getText } = useLanguage();
  const phoneNumberWithCode = auth.user.phoneNumber ? auth.user.phoneNumber.split(" ") : '';
  const countryPrefix = Object.keys(countryCallingCodes).find(key => countryCallingCodes[key] === phoneNumberWithCode[0]);
  const [selectedCountry, setSelectedCountry] = useState(countryPrefix ? countryPrefix : '');
  const [errorMessage, setErrorMessage] = useState('');

  const updateProfile = useMutation(async ({phone_number}: {phone_number: string}) => {
    message.loading(getText('Saving information'));
    if(phone_number) {
      let phoneNumberWithCode = countryCallingCodes[selectedCountry] + ' ' + phone_number;
      return updateUserProfile(authToken, phoneNumberWithCode)
    }
  },  {
    onSuccess: (val) => {
      message.destroy();
      message.success(getText('Successfully updated'));
      auth.user.phoneNumber = val.phone_number;
      const storage = localStorage.getItem('AUTH_SESSION');
      if (storage !== null){
        const json = JSON.parse(storage);
        json.phone_number = val.phone_number;
        localStorage.setItem('AUTH_SESSION', JSON.stringify(json))
      }
    },
    onError: (val:any) => {
      message.destroy();
      message.error(getText('Failed to update'));
      if(val.response.data.hasOwnProperty('phone_number')) {
        setErrorMessage(`Phone number is ${val.response.data.phone_number}`)
      }
      else{
        setErrorMessage(getText("Failed to update the phone number"))
      }
    },
  });
  return (
    <Form
      onFinish={(val) => {
        updateProfile.mutate(val);
      }}
      initialValues={{
        phone_number: phoneNumberWithCode[1],
        user_name: auth.user.email
      }}
      layout={'vertical'}
      requiredMark={false}
    >
      <Form.Item label={getText('Profile')} className={"form-title form-left-title"}>
      </Form.Item>
      <Divider/>
      {updateProfile.isError && <Alert
        className={"form-error"}
        message={
          <ul className="error-alert">
            <li>{errorMessage}</li>
          </ul>
        }
        type="error"
        showIcon
      />}
      <Row gutter={14}>
        <Col span={12}>
          <Form.Item
            label={getText('Username')}
            name="user_name"
          >
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Form.Item
          label={getText('Contact Number')}
        >
          <Row gutter={4}>
            <Col span={8}>
              <Form.Item className={"form-sub-label"}>
                <ReactFlagsSelect
                  selected={selectedCountry}
                  searchPlaceholder="Search countries"
                  onSelect={code => setSelectedCountry(code)}
                  searchable
                  className="menu-flags"
                  showSelectedLabel={false}
                  selectButtonClassName="menu-flags-button"
                  selectedSize={10}
                  optionsSize={10}
                  id="flags-select"
                />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                className={"form-sub-label show-prefix"}
                name="phone_number"
                rules={[{ required: true, message: getText('Please enter phone number') }]}
              >
                <Input disabled={!selectedCountry} prefix={selectedCountry ? countryCallingCodes[selectedCountry] : null} />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
      </Row>
      <div className={"button-block"}>
        <Button htmlType="submit" type="primary" className={"blue-button"} disabled={updateProfile.isLoading}>
          {getText('Save')}
        </Button>
      </div>
    </Form>
  )
};
