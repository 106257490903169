import { Line } from '@ant-design/plots';
import { useEffect, useState } from 'react';
import { Spin, Empty } from 'antd';
import { UserSelectedAnalyticsGranularity } from '../../../commons/mixpanel';
import { AnalyticsButton } from "../../../commons/components/AnalyticsButton/AnalyticsButton"

interface LineProps{
  linePlotData: any
  granularity: string
  setGranularity(val: string): void
  range: string
}
export const LinePlot = (props: LineProps) => {
  const {granularity, setGranularity, range} = props
  const [chartData, setChartData] = useState([])
  useEffect(() => {
    let chartArray: any = []
    if(props.linePlotData.data){
      props.linePlotData.data.forEach((element: any)=>{
        let data = {
          year: element.created.slice(0, 10).split("-").reverse().join("-"),
          value: Math.round(element.sla*100),
        }
        chartArray.push(data)
      })
      setChartData(chartArray.reverse())
    }
    
  },[props.linePlotData.data])
  
  const config = {
    data: chartData,
    xField: 'year',
    yField: 'value',
    label: {},
    point: {
      size: 5,
      shape: 'diamond',
      style: {
        fill: 'white',
        stroke: '#5B8FF9',
        lineWidth: 2,
      },
    },
    scrollbar: {
      type: ('horizontal' as any),
    },
    meta: {
      value: {
        formatter: (v: any) => `${v}%`,
      },
    },
    tooltip: {
      showMarkers: true,
      customContent: (title: any, items: any) => {
        return (
          <>
            <h5 >{`Date: ${title}`}</h5>
            {items?.map((item: any) => {
              const { value } = item;
              return (
                <p>{`SLA value: ${value}`}</p>
              )
            })}
          </>
        )
      }
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: 'red',
        },
      },
    },
    interactions: [
      {
        type: 'marker-active',
      },
    ],
  };
  return (
    <div className='chart-header-custom'>
      <h2>SLA Performance</h2>
      <div className='granularity'>
        <AnalyticsButton onButtonClick={()=>setGranularity('weekly')} mixpanelAnalytics={() => UserSelectedAnalyticsGranularity('SLA Performance', 'weekly')} className={'granularity-button'} type={granularity==='weekly'? 'primary':'default'} disabled={range === 'last_day' || range === 'last_week'}>Weekly</AnalyticsButton>
        <AnalyticsButton onButtonClick={()=>setGranularity('monthly')} mixpanelAnalytics={() => UserSelectedAnalyticsGranularity('SLA Performance', 'monthly')} className={'granularity-button'} type={granularity==='monthly'? 'primary':'default'} disabled={range === 'last_day' || range === 'last_week'}>Monthly</AnalyticsButton>
      </div>
      {!props.linePlotData.isLoading? 
        props.linePlotData.data.length !== 0 ? <Line {...config}  className={'sub-charts'} /> : <div className={'sub-charts no-data-image'}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/></div>
        : <div className={'sub-charts chart-spinner'}>
          <Spin/>
        </div>
      }
    </div>
  )
};