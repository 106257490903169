import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Input, Row, Select, Tooltip, message } from 'antd';
import moment from 'moment';
import { Fragment, useState } from 'react';
import { useQuery } from 'react-query';
import { OrderStatusListForAllStatus, claimStatuses, claimUsers, claimFiltersOptions } from '../../commons/utils/constants';
import { getAllCountries_from_b2c } from '../../orders/services/privateApi';

interface IClaimTrackingFilters {
  setPage: (value: number) => void;
  setShipperOrderId(value: string): void;
  setTrackingNumber(value: string): void;
  setOrderStatus(value: string): void;
  setDestinationCountry(value: string): void;
  setClaimType: (value: string) => void;
  setClaimStatus: (value: string) => void;
  setSubmittedBy: (value: string) => void;
  setSubmittedOnStart: (value: string) => void;
  setSubmittedOnEnd: (value: string) => void;
  setLastUpdatedStart: (value: string) => void;
  setLastUpdatedEnd: (value: string) => void;
}

const { RangePicker } = DatePicker;

const ClaimsTrackingFilters = (props: IClaimTrackingFilters) => {
  const [shipperOrderId, setShipperOrderId] = useState<string>('');
  const [trackingNumber, setTrackingNumber] = useState<string>('');
  const [orderStatus, setOrderStatus] = useState<[]>([]);
  const [destinationCountry, setDestinationCountry] = useState<[]>([]);
  const [claimType, setClaimType] = useState<string | undefined>(undefined);
  const [claimStatus, setClaimStatus] = useState<string | undefined>(undefined);
  const [submittedBy, setSubmittedBy] = useState<string | undefined>(undefined);
  const [submittedOnStart, setSubmittedOnStart] = useState<string | null>(null);
  const [submittedOnEnd, setSubmittedOnEnd] = useState<string | null>(null);
  const [lastUpdatedStart, setLastUpdatedStart] = useState<string | null>(null);
  const [lastUpdatedEnd, setLastUpdatedEnd] = useState<string | null>(null);
  const [isSearchExceeded, setIsSearchExceeded] = useState<boolean>(false);

  const {Option} = Select

  const getCountries = useQuery('getCountriesData', () => getAllCountries_from_b2c());

  const onChange = (value: any, type: string) => {
    if (type === 'shipper_order_id') {
      setShipperOrderId(value);
      return;
    }

    if (type === 'tracking_number') {
      setTrackingNumber(value);
      return;
    }

    if (type === 'order_status') {
      setOrderStatus(value);
      return;
    }

    if (type === 'destination_country') {
      setDestinationCountry(value);
      return;
    }

    if (type === 'claim_type') {
      setClaimType(value);
      return;
    }

    if (type === 'claim_status') {
      setClaimStatus(value);
      return;
    }

    if (type === 'submitted_by') {
      setSubmittedBy(value);
      return;
    }

    if (type === 'submitted_on') {
      setSubmittedOnStart(moment(value[0]).startOf('day').format());
      setSubmittedOnEnd(moment(value[1]).endOf('day').format());
      return;
    }

    if (type === 'last_updated') {
      setLastUpdatedStart(moment(value[0]).startOf('day').format());
      setLastUpdatedEnd(moment(value[1]).endOf('day').format());
      return;
    }
  };

  const onSubmit = () => {
    if (trackingNumber) {
      props.setTrackingNumber(trackingNumber);
    }

    if (shipperOrderId) {
      props.setShipperOrderId(shipperOrderId);
    }

    if (orderStatus) {
      props.setOrderStatus(orderStatus.join(','));
    }

    if (destinationCountry) {
      props.setDestinationCountry(destinationCountry.join(','));
    }

    if (claimType) {
      props.setClaimType(claimType);
    }

    if (claimStatus) {
      props.setClaimStatus(claimStatus);
    }

    if (submittedBy) {
      props.setSubmittedBy(submittedBy);
    }

    if (submittedOnStart) {
      props.setSubmittedOnStart(submittedOnStart);
    }

    if (submittedOnEnd) {
      props.setSubmittedOnEnd(submittedOnEnd);
    }

    if (lastUpdatedStart) {
      props.setLastUpdatedStart(lastUpdatedStart);
    }

    if (lastUpdatedEnd) {
      props.setLastUpdatedEnd(lastUpdatedEnd);
    }
  };

  const onReset = () => {
    setShipperOrderId('');
    setTrackingNumber('');
    setOrderStatus([]);
    setDestinationCountry([]);
    setClaimType(undefined);
    setClaimStatus(undefined);
    setSubmittedBy(undefined);
    setSubmittedOnStart('');
    setSubmittedOnEnd('');
    setLastUpdatedStart('');
    setLastUpdatedEnd('');

    props.setShipperOrderId('');
    props.setTrackingNumber('');
    props.setOrderStatus('');
    props.setDestinationCountry('');
    props.setClaimType('');
    props.setClaimStatus('');
    props.setSubmittedBy('');
    props.setSubmittedOnStart('');
    props.setSubmittedOnEnd('');
    props.setLastUpdatedStart('');
    props.setLastUpdatedEnd('');
    props.setPage(1);

    setIsSearchExceeded(false);
  };

  const maxSearchInputs = (type: string) => {
    if (type === 'tracking_number') {
      if (trackingNumber.length > 0) {
        if (trackingNumber.includes(',')) {
          let v = trackingNumber.split(',');
          if (v.length > 10) {
            setIsSearchExceeded(true);
            return message.error('Please search less than 10 Tracking Numbers, click on Reset button to clear the search', 10);
          } else {
            setIsSearchExceeded(false);
          }
        } else {
          let v = trackingNumber.split(' ');
          if (v.length > 10) {
            setIsSearchExceeded(true);
            return message.error('Please search less than 10 Tracking Numbers, click on Reset button to clear the search', 10);
          } else {
            setIsSearchExceeded(false);
          }
        }
      }
    }

    if (type === 'shipper_order_id') {
      if (shipperOrderId.length > 0) {
        if (shipperOrderId.includes(',')) {
          let v = shipperOrderId.split(',');
          if (v.length > 10) {
            setIsSearchExceeded(true);
            return message.error('Please search less than 10 Shipper Order IDs, click on Reset button to clear the search', 10);
          } else {
            setIsSearchExceeded(false);
          }
        } else {
          let v = shipperOrderId.split(' ');
          if (v.length > 10) {
            return message.error('Please search less than 10 Shipper Order IDs, click on Reset button to clear the search', 10);
          } else {
            setIsSearchExceeded(false);
          }
        }
      }
    }
  };

  const removeUnderscoreAndCapitalize = (status: string) => {
    return status.replaceAll('_', ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <Row gutter={8}>

          {/*Tracking Number*/}
          <Col span={8}>
            <Tooltip title={<Fragment><span>Search multiple Tracking numbers(maximum 10 TNs) in the format</span> <strong>TN1 TN2 TN3</strong></Fragment>} placement="topLeft">
              <Input
                id="tracking_number"
                placeholder="Tracking number(s)"
                value={trackingNumber}
                onChange={(event) => onChange(event.target.value, 'tracking_number')}
                onBlur={() => maxSearchInputs('tracking_number')}
                suffix={<SearchOutlined style={{ fontSize: '18px', color: '#808080' }} />}
              />
            </Tooltip>
          </Col>

          {/*Shipper Order Id*/}
          <Col span={8}>
            <Tooltip title={<Fragment><span>Search multiple Shipper Order IDs(maximum 10 IDs) in the format</span> <strong>ID1 ID2 ID3</strong></Fragment>} placement="topLeft">
              <Input
                id="shipper_order_id"
                placeholder="Shipper Order Id"
                value={shipperOrderId}
                onChange={(event) => onChange(event.target.value, 'shipper_order_id')}
                onBlur={() => maxSearchInputs('shipper_order_id')}
                suffix={<SearchOutlined style={{ fontSize: '18px', color: '#808080' }} />}
              />
            </Tooltip>
          </Col>

          {/*Order Status*/}
          <Col span={4}>
            <Select
              showArrow
              allowClear
              mode="multiple"
              className="select-wrapper"
              dropdownClassName="custom-font"
              placeholder="Order Status"
              value={orderStatus}
              maxTagCount={1}
              style={{ width: '100%' }}
              options={[...Object.entries<string>(OrderStatusListForAllStatus).map(([value, label]) => ({ value, label: removeUnderscoreAndCapitalize(label) }))]}
              onChange={(value: any) => onChange(value, 'order_status')}
            />
          </Col>

          {/*Destination Country*/}
          <Col span={4}>
            <Select
              showArrow
              allowClear
              mode="multiple"
              className="select-wrapper"
              placeholder="Destination Country"
              value={destinationCountry}
              maxTagCount={1}
              style={{ width: '100%' }}
              // options={getCountries && [...getCountries.data?.map((item: string) => ({ label: item, value: item }))]}
              onChange={(value: any) => onChange(value, 'destination_country')}
            >
              {
                getCountries && getCountries.data?.map((item: any) => {
                  return <Option key={item} value={item}>{item}</Option>
                })
              }
            </Select>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row gutter={8}>

          {/*Submitted By*/}
          <Col span={4}>
            <Select
              showArrow
              allowClear
              className="select-wrapper"
              dropdownClassName="custom-font"
              placeholder="Submitted By"
              value={submittedBy}
              style={{ width: '100%' }}
              options={[...Object.entries<string>(claimUsers).map(([value, label]) => ({ value, label }))]}
              onChange={(value: any) => onChange(value, 'submitted_by')}
            />
          </Col>

          {/*Status*/}
          <Col span={4}>
            <Select
              showArrow
              allowClear
              className="select-wrapper"
              dropdownClassName="custom-font"
              placeholder="Status"
              value={claimStatus}
              style={{ width: '100%' }}
              options={[...Object.entries<string>(claimStatuses).map(([value, label]) => ({ value, label }))]}
              onChange={(value: any) => onChange(value, 'claim_status')}
            />
          </Col>

          {/*Claim Type*/}
          <Col span={4}>
            <Select
              showArrow
              allowClear
              className="select-wrapper"
              dropdownClassName="custom-font"
              placeholder="Claim Type"
              value={claimType}
              maxTagCount={1}
              style={{ width: '100%' }}
              options={[...Object.entries<string>(claimFiltersOptions).map(([value, label]) => ({ value, label }))]}
              onChange={(value: any) => onChange(value, 'claim_type')}
            />
          </Col>

          {/*Submitted On*/}
          <Col span={4}>
            <RangePicker
              style={{ width: '100%' }}
              placeholder={['Submitted On Start Date', 'Submitted On End Date']}
              value={[submittedOnStart ? moment(submittedOnStart) : null, submittedOnEnd ? moment(submittedOnEnd) : null]}
              onChange={(value: any) => onChange(value, 'submitted_on')}
            />
          </Col>

          {/*Last Updated*/}
          <Col span={4}>
            <RangePicker
              style={{ width: '100%' }}
              placeholder={['Last Updated Start Date', 'Last Updated End Date']}
              value={[lastUpdatedStart ? moment(lastUpdatedStart) : null, lastUpdatedEnd ? moment(lastUpdatedEnd) : null]}
              onChange={(value: any) => onChange(value, 'last_updated')}
            />
          </Col>

          <Col span={4}>
            <Row gutter={8}>
              <Col span={12}>
                <Button type="primary" onClick={() => onSubmit()} style={{ width: '100%' }} disabled={isSearchExceeded}>Search</Button>
              </Col>
              <Col span={12}>
                <Button type="default" onClick={() => onReset()} style={{ width: '100%' }}>Reset</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ClaimsTrackingFilters;