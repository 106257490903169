import { MissingParcelForm } from '../Forms/SingleClaimForms/MissingParcelForm'
import { DamagedParcelForm } from '../Forms/SingleClaimForms/DamagedParcelForm'

interface ClaimformProp{
  claimType: string
  tracking_no: string
  order_id: any
}
export const ClaimsForm = (props: ClaimformProp) => {

  if(props.claimType === 'missing_orders'){
    return ( <MissingParcelForm {...props}/>)
  }
  else{
    return ( <DamagedParcelForm {...props}/>)
  }
}

export const ClaimFormTitle = () => {
  return (
    <div>
      <p className={'claim-step-title'}>{'Add supporting information'}</p>
    </div>
  )
}