import {CeilSignificance, OrderWeightLimit, TextMap, OrderWeightLimitForPostpaidCLients} from "./constants";

export const validateVolumetricWeight = (dimension:any, client_billing?: string) =>{
  const volumetric_weight = (dimension.length*dimension.width*dimension.height)/5000;
  const max_weight = Math.max(dimension.weight, volumetric_weight);
  const weight = Math.ceil(max_weight / CeilSignificance) * CeilSignificance;

  if(client_billing === 'PayOnMonthlyInvoice') {
    if(weight > OrderWeightLimitForPostpaidCLients){
      return volumetric_weight > dimension.weight ? 'volumetric weight'
        : 'weight'
    }
  }else {
    if(weight > OrderWeightLimit){
      return volumetric_weight > dimension.weight ? 'volumetric weight'
        : 'weight'
    }
  }
  return null
};

export const validatePostalCode = (country:string, code:string) => {
  if((country !== 'JP' && country !== 'GB') && isNaN(code as any)){
    return TextMap.NotANumberError
  }
  if(country === 'SG'){
    if(code.length!== 4 && code.length!== 6){
      return TextMap.SGPostalError
    }
  }
  else if(country === 'ID' || country === 'MY' || country === 'TH' || country === 'US'){
    if(code.length!== 5){
      return TextMap.CountryPostalError
    }
  }
  else if(country === 'TW'){
    switch(code.length){
    case 3:
    case 5:
    case 6: break;
    default: return TextMap.TWPostalError 
    }
  }
  else if(country === 'VN'){
    switch(code.length){
    case 5:
    case 6: break;
    default: return TextMap.VNPostalError 
    }
  }
  else if(country === 'HK'){
    if(code.length!== 6){
      return TextMap.HKPostalError
    }
  }
  else if(country === 'PH' || country === 'AU'){
    if(code.length!== 4){
      return TextMap.PHPostalError
    }
  }
  else if(country === 'JP'){
    if(!new RegExp(/[0-9]{3}-?[0-9]{4}/).test(code)){
      return TextMap.JPPOstalError
    }
  }
  return null
};



const countryArr = ['Singapore','Indonesia','Malaysia','Thailand','Vietnam','US','Philippines','Taiwan','Hong Kong']

export const validateLane = (pickup_country: string, consignee_country: string) => {
  if( (pickup_country && pickup_country !== 'Singapore') || (consignee_country && !countryArr.includes(consignee_country))
  ) {
    return ['Service is not available at given countries']
  }
}


export const validateAddress = (address: string) => {
  let isNotValid = /^\d*\.?\d+$/.test(address);

  if(isNotValid){
    return ['Address can not be only numbers']
  }
}


export const validateEmail = (email: string) => {
  let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

  if(email && !regEmail.test(email)){
    return ['Invalid email']
  }
}


export const validateItemQuantity = (qty: string | number) => {
  if(typeof qty === 'string'){
    qty = Number(qty)
  }

  if(qty % 1 !== 0){
    return ['Item quantity must be an integer']
  }
}

const getDecimals = (value: number) => {
  if (value % 1 !== 0){
    return value.toString().split('.')[1].length
  }
  return 0
}

export const validateWeight = (dimension: {weight: number, width: number, length: number, height: number}) => {
  if(isNaN(dimension.weight)) {
    return [`This field in not a valid number`]
  }
  if( getDecimals(dimension.weight) > 2 ){
    return ["Weight can't have more than 2 decimal places"]
  }

  const result = validateVolumetricWeight(dimension)
  if (result === 'weight') {
    return ['Exceeded volumetric weight.']
  }
}

export const validateDimensions= (dimension: {weight: number, width: number, length: number, height: number}) => {
  const result = validateVolumetricWeight(dimension)
  if (result === 'volumetric weight') {
    return ['Exceeded volumetric weight.']
  }
}

export const validatePostal = (country:string, code:string) => {
  if(country === 'SG'){
    if(code.length!== 4 && code.length!== 6){
      return [TextMap.SGPostalError]
    }
  }
  else if(country === 'ID'){
    if(code.length!== 5){
      return [TextMap.IDPostalError]
    }
  }
};


export const validateDecimalPlaces = (value: number, max: number) => {
  if(isNaN(value)) {
    return [`This field in not a valid number`]
  }
  if( getDecimals(value) > max){
    return [`This field can't have more than ${max} decimal places`]
  }
}

export const validateBulkOrderPostalCode = (postalCode: any) => {
  if(typeof postalCode === 'string' && postalCode.includes('-')){
    return new RegExp(/[0-9]{3}-?[0-9]{4}/).test(postalCode)
  } 
  else if(typeof postalCode === 'string' && postalCode.includes(' ')) {
    return true
  }
  else{
    return !isNaN(postalCode)
  }
}

export const validateFileType = (file: any) => {
  return (file.type === 'application/pdf' || file.type === 'image/png' || file.type === 'image/jpeg')
}
export const validateFileTypeXlsx = (file: any) => {
  return (file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
}
export const validateFileSize = (file: any) => {
  return (file.size / 1024 / 1024  < 10)
}

// if((country !== 'JP' && country !== 'GB') && isNaN(code as any)){
//   return TextMap.NotANumberError
// }
