import {
  Form,
  Button,
  Col,
  Row,
  Select, Card, Alert
} from 'antd';
import '../../styles/SingleOrderForm.css';
import React, {useRef, useState} from "react";
import {useSingleOrder, OrderError} from '../../services/singleOrderContext';
import {sortResponseAlphabatically} from "../../../commons/utils/utilizer" 
import {countries, countryCallingCodes, countryCodes, IndonesiaIdentificationTypes, SouthKoreaIdentificationTypes} from "../../../commons/utils/constants";
import {validatePostalCode} from "../../../commons/utils/validator";
import {getStates} from "../../services/privateApi";
import {FormInput, FormSelect, TitleErrorMarker} from '../Forms';
import {Formik, Form as FormikForm} from 'formik';
import {parseErrors} from '../../helpers';
import * as Yup from 'yup';
import {SingleOrderCreate} from "../../../commons/mixpanel"
import {useQuery} from "react-query";
import {getConsigneeLocation, getCities} from "../../services/privateApi";
import {useAuth} from "../../../auth/services/authContext";
import { useLanguage } from '../../../languages/Language';
import { ConsigneeAddressModalForShopify } from "../../../UserSettings/components/ConsigneeAddressModalForShopify";
import { LabelRadio } from '../../../ReUsableComponents/ReUsable'

const {Option} = Select;

interface PropsType {
    current: number,
    setCurrentState: (val:number)=> void,
    dataFilled: number
}
interface CustomerProps {
  consignee_name?: string,
  consignee_number?: string,
  consignee_email?: string,
  consignee_address?: string,
  consignee_country?: string,
  consignee_state?: string,
  consignee_city?: string,
  consignee_postal?: string,
  consignee_id?: string
  consignee_id_type?: string
  consignee_address_id?: string
}

const validationSchema = (getText: any) => {
  return Yup.object().shape({
    consignee_name: Yup.string().required(getText('Please enter Full Name')),
    consignee_number: Yup.string().required(getText('Please enter phone number')),
    consignee_email: Yup.string().email(getText('Please enter a valid email')).required(getText('Please enter a valid email')),
    consignee_address: Yup.string().required(getText('Please enter Address')),
    consignee_state: Yup.string().required(getText('Please select State')),
    consignee_city: Yup.string().required(getText('Please enter City')),
    consignee_country: Yup.string().required(getText('Please select Destination Country')),
    consignee_postal: Yup.string().required(getText('Please enter Postal Code')).test(
      'postal-check',
      (val: any, context: any) => {
        if(!val){
          return context.createError({
            path: context.path,
            message: getText('Please enter Postal Code')
          })
        }
        else{
          const error = validatePostalCode(countryCodes[context.parent.consignee_country],val);
          if(error){
            return context.createError({
              path: context.path,
              message: error
            })
          }
        }
        return true
      }
    )
  })};

const parseOrderContextError = (error: OrderError) => {
  return {
    consignee_name: error.consignee_name,
    consignee_number: error.consignee_number,
    consignee_email: error.consignee_email,
    consignee_address: error.consignee_address,
    consignee_country: error.consignee_country_code,
    consignee_state: error.consignee_state,
    consignee_city: error.consignee_city,
    consignee_postal: error.consignee_postal,
    consignee_id: error.consignee_id,
    consignee_id_type: error.consignee_id_type
  }
}

export const ConsigneeDetailsForm = (props:PropsType) =>{
  let { getText } = useLanguage();
  const auth = useAuth();
  const ConsigneeDetailsFormRef:any = useRef();
  const singleOrderContext = useSingleOrder();
  const {current, setCurrentState, dataFilled} = props;
  const [customerData, setCustomerData] = useState<CustomerProps | null>({});
  const [showConsigneeAddressModal, setShowConsigneeAddressModal] = useState<boolean>(false);
  const [consigneeFieldsDisabled, setConsigneeFieldDisabled] = useState<boolean>(false)
  const [freeText, setfreeText] = useState<string>('dropdown');
  const [state, setState] = useState<string>(singleOrderContext.singleOrder.consignee_state ? singleOrderContext.singleOrder.consignee_state : '');
  const setShowConsigneeAddressModalCallback = (val: boolean) => {
    setShowConsigneeAddressModal(val)
    consigneeLocations.refetch();
  }

  const statesList = useQuery(['statesList', singleOrderContext.singleOrder.consignee_country_code], () => {
    if(singleOrderContext.singleOrder.consignee_country_code){
      return getStates(auth.user.authToken, singleOrderContext.singleOrder.consignee_country_code!)
    }
  });

  const cityList = useQuery(['cityList', state], () => {
    if(state){
      return getCities(auth.user.authToken, state)
    }
  });

  const consigneeLocations = useQuery('consigneeLocations', () => {
    return getConsigneeLocation(auth.user.authToken)
  })

  const setCreatedLocationIdCallback = (location: any) => {
    if (ConsigneeDetailsFormRef.current) {
      ConsigneeDetailsFormRef.current.setFieldValue('consignee_address_id', location.id)     
      ConsigneeDetailsFormRef.current.setFieldValue('consignee_name',location.contact_person || '')
      ConsigneeDetailsFormRef.current.setFieldValue('consignee_number',location.contact_number || '')
      ConsigneeDetailsFormRef.current.setFieldValue('consignee_email',location.contact_email || '')
      ConsigneeDetailsFormRef.current.setFieldValue('consignee_address',location.address || '')
      ConsigneeDetailsFormRef.current.setFieldValue('consignee_state',location.state || '')
      ConsigneeDetailsFormRef.current.setFieldValue('consignee_city',location.city || '')
      ConsigneeDetailsFormRef.current.setFieldValue('consignee_postal',location.postal || '')
      ConsigneeDetailsFormRef.current.setFieldValue('consignee_id',location.consignee_id || '')
      ConsigneeDetailsFormRef.current.setFieldValue('consignee_id_type',location.consignee_id_type || '')
      setConsigneeFieldDisabled(true)
    }
        
  }

  const formInitialValue: CustomerProps = {
    consignee_name: singleOrderContext.singleOrder.consignee_name,
    consignee_number: singleOrderContext.singleOrder.consignee_number,
    consignee_email: singleOrderContext.singleOrder.consignee_email,
    consignee_address: singleOrderContext.singleOrder.consignee_address,
    consignee_country: countries[singleOrderContext.singleOrder.consignee_country_code!],
    consignee_state: singleOrderContext.singleOrder.consignee_country_code === 'SG' ?
      countries[singleOrderContext.singleOrder.consignee_country_code!] : 
      singleOrderContext.singleOrder.consignee_state,
    consignee_city: singleOrderContext.singleOrder.consignee_country_code === 'SG' ?
      countries[singleOrderContext.singleOrder.consignee_country_code!] : 
      singleOrderContext.singleOrder.consignee_city,
    consignee_postal: singleOrderContext.singleOrder.consignee_postal,
    consignee_id: singleOrderContext.singleOrder.consignee_id,
    consignee_id_type: singleOrderContext.singleOrder.consignee_id_type,
    consignee_address_id: singleOrderContext.singleOrder.consignee_address
  }

  const {initialErrors, initialTouched, hasError, emptyError} = parseErrors(singleOrderContext.errors, parseOrderContextError)
  if(hasError){
    if(current > 1){
      setCurrentState(1);
    }
    if(ConsigneeDetailsFormRef.current != null){
      window.scrollTo(0, ConsigneeDetailsFormRef.current.offsetTop);
    }
  }
  const submitForm = (values: CustomerProps) => {
    SingleOrderCreate('parcelDetails');
    setCurrentState(2);
    setCustomerData({...values});
    singleOrderContext.updateSingleOrderData({
      consignee_name: values.consignee_name,
      consignee_number: values.consignee_number,
      consignee_email: values.consignee_email,
      consignee_address: values.consignee_address,
      consignee_state: values.consignee_state,
      consignee_city: values.consignee_city,
      consignee_postal: values.consignee_postal,
      consignee_id: values.consignee_id,
      consignee_id_type: values.consignee_id_type,
      identification_document_name: values.consignee_id_type,
      consignee_identification_number: values.consignee_id
    })
    singleOrderContext.setErrors({...emptyError})
  }

  const onConsigneeAddressSelect = (value:any, formik:any) => {
    let selectedAddress:any = consigneeLocations.data?.filter(address => {
      return  address.id === value;
    })
    formik.setFieldValue('consignee_name',selectedAddress[0].contact_person || '')
    formik.setFieldValue('consignee_number',selectedAddress[0].contact_number || '')
    formik.setFieldValue('consignee_email',selectedAddress[0].contact_email || '')
    formik.setFieldValue('consignee_address',selectedAddress[0].address || '')
    formik.setFieldValue('consignee_state',selectedAddress[0].state || '')
    formik.setFieldValue('consignee_city',selectedAddress[0].city || '')
    formik.setFieldValue('consignee_postal',selectedAddress[0].postal || '')
    formik.setFieldValue('consignee_id',selectedAddress[0].consignee_id || '')
    formik.setFieldValue('consignee_id_type',selectedAddress[0].consignee_id_type || '')
  }

  const onConsigneeAddressRemove = (formik:any) => {
    formik.setFieldValue('consignee_address_id','')
    formik.setFieldValue('consignee_name','')
    formik.setFieldValue('consignee_number','')
    formik.setFieldValue('consignee_email','')
    formik.setFieldValue('consignee_address','')
    formik.setFieldValue('consignee_state','')
    formik.setFieldValue('consignee_city','')
    formik.setFieldValue('consignee_postal','')
    formik.setFieldValue('consignee_id','')
    formik.setFieldValue('consignee_id_type','')
  }

  const setFreeTextCallback = (val:string) => {
    setfreeText(val);
  };

  return (
    <div ref={r => {ConsigneeDetailsFormRef.current = ConsigneeDetailsFormRef.current ? ConsigneeDetailsFormRef.current :r}}>
      { current < 2 && <>
        <Formik
          innerRef={ConsigneeDetailsFormRef}
          initialValues={formInitialValue}
          initialErrors={initialErrors}
          initialTouched={initialTouched}
          validationSchema={validationSchema(getText)}
          enableReinitialize= {true}
          onSubmit={submitForm}
          validateOnChange={false}>
          { formik => (
            <div className={"single-order-form-container ant-form-vertical"}>
              {formInitialValue.consignee_country === "Indonesia" ?
                <div style={{marginBottom:20}}>
                  <Alert
                    message={<p style={{margin:0}}> {getText("Please provide your")} <strong> {getText("customer's identification number")} </strong> {getText("to ensure a smooth delivery")}</p>}
                    type="info"
                    showIcon
                  />
                </div> : ''
              }
              <FormikForm>
                <Form.Item label={<TitleErrorMarker text={getText('Customer Details')} hasError={hasError}/>} className={"single-order-form-title"}></Form.Item>
                <Row gutter={36}>
                  <Col span={12}>
                    <FormSelect name="consignee_address_id" label={"Select Customer Address"} placeholder={'Select customer'} wrapperClass="no-margin-item"
                      onChangeCallback={(value: any) => {
                        onConsigneeAddressSelect(value, formik);
                        setConsigneeFieldDisabled(true)
                      }}>
                      {
                        // Display consignee locations of the selected consignee country
                        consigneeLocations.data?.filter(l => l.country_code === singleOrderContext.singleOrder.consignee_country_code).map(item => <Option key={item.id} value={item.id}>{`${item.name} - ${item.address}`}</Option>)
                      }
                    </FormSelect>
                    <Form.Item>
                      <Button type="primary" className={"no-border-btn"} onClick={() => {setShowConsigneeAddressModal(true)}}>
                        {'Add new customer'}
                      </Button>
                    </Form.Item>
                  </Col>
                  {formik.values.consignee_address_id !== ''&&
                    <Col span={6} offset={4}>
                      <Button type="primary" className={"no-border-btn consignee-remove-btn"} onClick={() => {onConsigneeAddressRemove(formik); setConsigneeFieldDisabled(false)}}>
                        {'Remove customer'}
                      </Button>
                    </Col>
                  }
                </Row>
                <Row gutter={14}>
                  <Col span={12}>
                    <FormInput label={getText('Full Name')} inputProps={{disabled:consigneeFieldsDisabled}} name="consignee_name" />
                  </Col>
                  <Col span={12}>
                    <FormInput label={getText('Phone Number')} name="consignee_number"
                      inputProps={{
                        disabled:consigneeFieldsDisabled,
                        prefix: countryCallingCodes[singleOrderContext.singleOrder.consignee_country_code!]
                      }}/>
                  </Col>
                </Row>
                { formInitialValue.consignee_country === "Indonesia" ?
                  <Row gutter={14}>
                    <Col span={12}>
                      <FormSelect label={getText('Identification Type')} disabled={consigneeFieldsDisabled} name="consignee_id_type" onChangeCallback={(value: any) => {formik.setFieldValue("consignee_id", '')}}>
                        {
                          Object.entries(IndonesiaIdentificationTypes).map((type:any) => {
                            return  <Option key={type[0]} value={type[0]}>{type[1]}</Option>
                          })
                        }
                      </FormSelect>
                    </Col>
                    {formik.values.consignee_id_type ?
                      <Col span={12}>
                        <FormInput inputProps={{disabled:consigneeFieldsDisabled}} label={`${IndonesiaIdentificationTypes[formik.values.consignee_id_type]}`} name="consignee_id" />
                      </Col> : ''
                    }
                  </Row> : ''
                }
                { formInitialValue.consignee_country === "South Korea" ?
                  <Row gutter={14}>
                    <Col span={12}>
                      <FormSelect label={getText('Identification Type')} disabled={consigneeFieldsDisabled} name="consignee_id_type" onChangeCallback={(value: any) => {formik.setFieldValue("consignee_id", '')}}>
                        {
                          Object.entries(SouthKoreaIdentificationTypes).map((type:any) => {
                            return  <Option key={type[0]} value={type[0]}>{type[1]}</Option>
                          })
                        }
                      </FormSelect>
                    </Col>
                    {formik.values.consignee_id_type ?
                      <Col span={12}>
                        <FormInput inputProps={{disabled:consigneeFieldsDisabled}} label={`${SouthKoreaIdentificationTypes[formik.values.consignee_id_type]}`} name="consignee_id" />
                      </Col> : ''
                    }
                  </Row> : ''
                }
                <FormInput label={getText('Email')} inputProps={{disabled:consigneeFieldsDisabled}} name="consignee_email" />
                <FormInput label={getText('Address')} inputProps={{disabled:consigneeFieldsDisabled}} name="consignee_address" />

                <Row gutter={14}>
                  {formInitialValue.consignee_country ?
                    <Col span={12}>
                      <FormInput label={getText('Country')} inputProps={{disabled:true}} name="consignee_country"/>
                    </Col>
                    : ''}
                  <Col span={12}>
                    {consigneeFieldsDisabled || formik.values.consignee_country === 'UK' ? 
                      <FormInput inputProps={{disabled:consigneeFieldsDisabled}} label={getText('State')} name="consignee_state" ></FormInput> :
                      <FormSelect required label={getText('State')} name="consignee_state" loading={statesList.isLoading} onChangeCallback={(state:string) => {
                        setState(state);
                        formik.setFieldValue('consignee_city', '');
                      }}>
                        {
                          (sortResponseAlphabatically(statesList?.data, 'state_name') || []).map((item: {id: number, state_name:string} ) => {
                            return  <Option key={item.id} value={item.state_name}>{item.state_name}</Option>
                          })
                        }
                      </FormSelect>}
                    {statesList.isError && <p style={{color:'red', marginBottom:0}}>{getText("Failed to fetch states, try later")}</p>}
                  </Col>
                </Row>
                <Row gutter={14}>
                  <Col span={12}>
                    {
                      formik.values.consignee_country === 'US' ?
                        freeText === 'dropdown' ? 
                          <>
                            <FormSelect label={<LabelRadio setFreeTextCallback={setFreeTextCallback} freeText={freeText} />}
                              notFoundContent={<p style={{marginBottom: '0px', color: '#525050'}}>City not found. Please select Other</p>} name="consignee_city" loading={cityList.isLoading} disabled={consigneeFieldsDisabled}
                              placeholder={'Select a city'}>
                              {
                                (sortResponseAlphabatically(cityList?.data, 'city_name') || [])
                                  .map((item: {id:number, city_name: string} ) => (
                                    <Option key={item.id} value={item.city_name}>{item.city_name}</Option>
                                  ))
                              }
                            </FormSelect>
                            {cityList.isError && <p style={{color:'red', marginBottom:0}}>Failed to fetch cities, try later</p>}
                          </>
                          : 
                          <FormInput placeholder='Please type in the city' label={<LabelRadio setFreeTextCallback={setFreeTextCallback} freeText={freeText} />} name="consignee_city" />
                          
                        : formik.values.consignee_country === 'UK' ?
                          <FormInput placeholder='Please type in the city' label={'City'} name="consignee_city" /> 
                          :
                          <FormSelect label={getText('City')} name="consignee_city" loading={cityList.isLoading}>
                            {
                              (sortResponseAlphabatically(cityList?.data, 'city_name') || [])
                                .map((item: {id:number, city_name: string} ) => (
                                  <Option key={item.id} value={item.city_name}>{item.city_name}</Option>
                                ))
                            }
                          </FormSelect>
                    }
                  </Col>
                  <Col span={6} style={{marginTop: singleOrderContext.singleOrder.consignee_country_code! === 'US' ? '35px' : '0px'}}>
                    <FormInput label={getText('Postal code')} inputProps={{disabled:consigneeFieldsDisabled}} name="consignee_postal" />
                  </Col>
                </Row>

                {current === 1 && (
                  <div className="button-block">
                    <Button htmlType="submit" type="primary" className={"blue-button"}>
                      {getText('Save & Continue')}
                    </Button>
                  </div>
                )}
              </FormikForm>
            </div>
          )}
        </Formik>
      </>}
      {showConsigneeAddressModal && 
      <ConsigneeAddressModalForShopify 
        setShowAddressModalCallback={setShowConsigneeAddressModalCallback} 
        addressType={"customer"} 
        countryCode={singleOrderContext.singleOrder.consignee_country_code!} 
        setCreatedLocationIdCallback={setCreatedLocationIdCallback}/>}
      <>
        {((customerData && current > 1) || (current > 1 && dataFilled === 2)) && <Card title={<TitleErrorMarker text={getText('Customer Details')} hasError={hasError}/>} className={"form-summary-container"}>
          <p style={{margin: 0}}>{formInitialValue.consignee_name}, {formInitialValue.consignee_number}</p>
          <p className={'gray-text'}>{formInitialValue.consignee_address}</p>
          <p className={'gray-text'}>{formInitialValue.consignee_state}, {formInitialValue.consignee_city}, {formInitialValue.consignee_postal}</p>
        </Card>}
      </>
    </div>
  )
};
export const ConsigneeDetailsFormTitle = (props:PropsType) =>{
  let { getText } = useLanguage();
  const {current, setCurrentState} = props;
  return (
    <div className={"title-block"}>
      <p className={'step-title'}>{getText('Who are you delivering to?')}</p>
      {current>1 ? <p onClick={()=> setCurrentState(1)} className={"edit-text"}>{getText('Edit details')}</p>:''}
    </div>
  )
};
