import React from 'react'
import ClaimsContainer from './Component/ClaimsContainer'
import ClaimsGuidelines from './Component/ClaimsGuidelines'
import './styles/claims.css'

const ClaimsPage = () => {
  return (
    <div>
      <div className='claims-notification'>
        <ClaimsGuidelines/>
      </div>
      <div>
        <h1 className='ant-page-header-heading-title page-title'>{'Manage Claims & Disputes'}</h1>
        <ClaimsContainer/>
      </div>
    </div>
  )
}

export default ClaimsPage