import { Divider, Radio, Space, Button, Col, Checkbox, message } from 'antd';
import {useEffect, useState} from 'react';
import '../../styles/SingleOrderForm.css';
import {useAuth} from '../../../auth/services/authContext';
import { confirmMonthlyBillingSingleOrder, confirmMonthlyBillingBulkOrder } from "../../services/privateApi";
import {PayFromCards} from './PayFromCards'
import {PayFromJanioCredits} from './PayFromJanioCredits'
import {useLocation, useHistory} from "react-router-dom";
import {useMutation} from "react-query";
import { useLanguage } from '../../../languages/Language';
import {UserCreatedOrderSuccessfully, UserViewedOrderSuccessPage} from "../../../commons/mixpanel"

export const SelectedPaymentMethod = ({walletDetail, orderData, orderIsStillValid, reloadComponent, orderType, bulkOrderChargeIds, discountAmount, promoCode}: any) => {
  let params = useLocation().search
  let { getText } = useLanguage();
  let history = useHistory();
  const auth = useAuth();
  const isPostPaidAvailable = auth.user.allowPostpaid
  const setDefaultPaymentValue = isPostPaidAvailable ? 1 : 2
  const [value, setValue] = useState(setDefaultPaymentValue);
  const [checked, setChecked] = useState(false);
  const token = auth.user.authToken;

  const createMonthlyBillingSingleOrder:any = useMutation(async (orderId:number) => {
    return confirmMonthlyBillingSingleOrder(token, orderId);
  },  {
    onSuccess: (val) => {
      UserCreatedOrderSuccessfully('single', auth?.user?.fullName)
      UserViewedOrderSuccessPage()
      history.push('/orders/success')
    },
    onError: (error: { response: { data: { error: any } } }) => {
      let errorMessage: any
      let errObject = error.response.data.error.orders ? error.response.data.error.orders : error.response.data.error;
      if(errObject) {
        errorMessage = Object.values(errObject).map((item: any) => {
          if(typeof(item) == 'string'){
            return ({item})
          }
          return (              
            Object.entries(item).map((y: any) => {
              return `${y[0]}: ${y[1]}`
            })           
          )
        })
        const displayedMessage = errorMessage.join()
        message.error(displayedMessage)
      }else{
        message.error('Failed to create shipment')
      }
    },
  });

  const createMonthlyBillingBulkOrder:any = useMutation(async (orderId:string) => {
    return confirmMonthlyBillingBulkOrder(token, orderId);
  },  {
    onSuccess: (val) => {
      UserCreatedOrderSuccessfully('bulk', auth?.user?.fullName)
      history.push('/orders/success')
    },
    onError: (error: { response: { data: { error: any } } }) => {
      let errorMessage: any
      let errObject = error.response.data.error.orders ? error.response.data.error.orders : error.response.data.error;
      if(errObject) {
        errorMessage = Object.values(errObject).map((item: any) => {
          if(typeof(item) == 'string'){
            return ({item})
          }
          return (              
            Object.entries(item).map((y: any) => {
              return `${y[0]}: ${y[1]}`
            })           
          )
        })
        const displayedMessage = errorMessage.join()
        message.error(displayedMessage)
      }else{
        message.error('Failed to create shipment')
      }
      
    },
  });

  const handleMonthlyBilling = () => {
    orderType === 'SINGLE'?
      createMonthlyBillingSingleOrder.mutate(orderData.id) :
      createMonthlyBillingBulkOrder.mutate(orderData.batch_no)
  }

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if(params === '?result=success') {
      setValue(2)
    }
  },[params])

  return (
    <>
      {auth.user.payment_type !== 'PayOnMonthlyInvoice' ?
        <>
          <p style={{margin: '25px 0 15px 0'}}><strong>Payment Methods</strong></p>
          <Radio.Group onChange={onChange} value={value} defaultValue={value}>
            <Space direction="vertical">
              <Radio style={{marginBottom: '15px'}} value={1} disabled={!isPostPaidAvailable}>Charge Later (Deducted when order gets delivered)</Radio>
              <Radio value={2} >Janio Credits (Deduct upon shipment creation)</Radio>
            </Space>
          </Radio.Group>
          <Divider />

          {value === 1 ?
            <PayFromCards
              orderData={orderData}
              walletDetail={walletDetail}
              orderIsStillValid={orderIsStillValid}
              reloadComponent={reloadComponent}
              orderType={orderType}
              bulkOrderChargeIds={bulkOrderChargeIds}
              promoCode={promoCode}
            /> :
            <PayFromJanioCredits
              orderData={orderData}
              walletDetail={walletDetail}
              orderIsStillValid={orderIsStillValid}
              reloadComponent={reloadComponent}
              orderType={orderType}
              bulkOrderChargeIds={bulkOrderChargeIds}
              discountAmount={discountAmount}
              promoCode={promoCode}
            />
          }
        </> :
        <div style={{marginTop:'20px'}}>
          {
            !orderIsStillValid &&
           <Col span={24} style={{'marginTop': '10px','marginBottom':'10px'}}>
             <Col style={{color:"#ff4d4f"}}>{getText('You are updating the order from the past. Please select pickup date again.')}</Col>
           </Col>
          }
          <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)}>
            {getText('I agree to pay')} <b>{getText('additional courier charges')}</b> {getText('in case shipment details (weight, dimensions, item category, pickup and destination address) are found to be inaccurate.')}
          </Checkbox>
          {!checked ? <Col style={{color:"#ff4d4f"}}>{getText('Please accept agreement to proceed.')}</Col>: ''}
          <div style={{marginTop: '20px'}}>
            <Button type="primary"
              className="blue-button"
              onClick={handleMonthlyBilling}
              loading={createMonthlyBillingSingleOrder.isLoading || createMonthlyBillingBulkOrder.isLoading}
              disabled={!(checked && orderIsStillValid)}>
              {getText('Create Shipment')}
            </Button>
          </div>
        </div>
      }
    </>
  )
}