
import React, { useState } from 'react'
import {Row, Col, Table} from 'antd'
import { Link } from 'react-router-dom';
import { capitalizeString, getDateInReadableFormat, replaceString } from '../../commons/utils/utilizer';
import Button from 'antd/lib/button';

interface Claim{
    data: any;
    setPage: (page: number) => any;
    setPageSize: (pageSize: number) => any;
    page: number;
    pageSize: number;
    setSelectedOrders: (order: any) => any;
    isLoading: boolean;
    current: number;
    setCurrent: (current: any) => any
}

const ClaimsSubmissionTable = (props: Claim) => {

  const [selectedOrders, setSelectedOrders] = useState([])
  const [pageSize, setPageSize] = useState(10)

  let columns = [
    {
      title: 'Shipper Order Id',
      dataIndex: 'shipper_order_id',
      key: 'shipper_order_id',
      width: 135,
      render: (shipper_order_id: any) => {
        return shipper_order_id ? shipper_order_id : 'N/A'
      }
    },
    {
      title: 'Tracking number',
      dataIndex: 'tracking_no',
      key: 'tracking_no',
      width: 195,
      render: (tracking_no: any, data: any) => {
        return <Link to={`/orders/view_shipment_details/${data.order_id}`}>
          <button className="table-link" style={{cursor: 'pointer', textAlign: 'left'}}>
            {tracking_no ? tracking_no : 'N/A'}
          </button>
        </Link>
      }
    },
    {
      title: 'Order Status',
      dataIndex: 'public_tracker_status_v2',
      key: 'public_tracker_status_v2',
      width: 150,
      render: (public_tracker_status_v2: string) => {
        if(public_tracker_status_v2){
          let replacedString = replaceString(public_tracker_status_v2,'_'," ")
          return capitalizeString(replacedString);
        }
      }
    },
    {
      title: 'Pickup Country',
      dataIndex: 'pickup_country',
      key: 'pickup_country',
      width: 130,
      render: (pickup_country: any) => {
        return pickup_country
      }
    },
    {
      title: 'Destination Country',
      dataIndex: 'consignee_country',
      key: 'consignee_country',
      width: 160,
      render: (consignee_country: any) => {
        return consignee_country
      }
    },
    {
      title: 'Created At',
      dataIndex: 'created_on',
      key: 'created_on',
      width: 120,
      render: (created_on: any) => {
        return getDateInReadableFormat(created_on)
      }
    },
    {
      title: 'Created By',
      dataIndex: 'agent_application_name',
      key: 'agent_application_name',
      width: 120,
      render: (agent_application_name: any, allData: any) => {
        return (agent_application_name ? agent_application_name : 'N/A')
      }
    },
    {
      title: 'Order Direction',
      dataIndex: 'order_direction',
      key: 'order_direction',
      width: 135,
      render: (order_direction: string, allData: any) => {
        if(allData.additional_data){
          let orderDirection = allData.additional_data.order_direction ? allData.additional_data.order_direction : 'N/A'
          return capitalizeString(orderDirection);
        }
      }
    },
    {
      title: 'Service Type',
      dataIndex: 'service_category',
      key: 'service_category',
      width: 120,
      render: (service_category: string) => {
        if(service_category){
          let replacedString = replaceString(service_category,'_'," ")
          return capitalizeString(replacedString);
        }
      }
    },
    {
      title: 'Actions',
      dataIndex: 'Actions',
      key: '',
      width: 120,
      fixed: 'right' as 'right',
      render: (key:any, allData:any) => {
        return(
          <Row gutter={8}>
            <Col span={24}>
              <Link to={{ pathname: `/claims/submission/${allData.order_id}`, state:{tracking_no: allData.tracking_no, order_id: allData.order_id}}}>
                <Button type='primary'  style={{color: '#fff', borderRadius: '4px', width: '100%'}}>
                  {'Claim'}
                </Button>
              </Link>
            </Col>
          </Row>
        )
      }
    }
  ]; 

  const rowSelection = {
    onSelect : (record: any, selected: boolean, selectedRows: any) => {
      let orders: any = selectedOrders;
      if(selected){
        orders.push(record)
      }else{
        orders = orders.filter((order: any) => order.order_id !== record.order_id)
      }
      setSelectedOrders(orders)
      props.setSelectedOrders(orders)
    },
    onSelectAll: (selected:boolean,selectedRows: any,changeRows: any) => {
      
      let orders:any = selectedOrders;
      if(selected){
        changeRows.map((row:any) => {
          return orders.push(row)
        })
      }
      else{
        changeRows.map((row:any) => {
          return orders = orders.filter((value:any)=>{
            return value.order_id !== row.order_id;
          })
        })
      }
      setSelectedOrders(orders)
      props.setSelectedOrders(orders)
    }
  }

  const onSwitchPage = (page:any, pageSize: any) => {
    props.setPageSize(pageSize)
    props.setPage(page)
    props.setCurrent(page)
    
  }
  const onPageSizeChange = (current:any, pageSize:any) => {
    setPageSize(pageSize)
  }
  
  return (
    <Col className={selectedOrders.length?'mt-50':'mt-85'} span={24} >
      <Table
        className='pagination-select-wrapper'
        rowClassName='table_body'
        rowKey="order_id"
        loading={props.isLoading}
        rowSelection={{preserveSelectedRowKeys:true,...rowSelection}}
        dataSource={props.data?.results}
        columns={columns}
        scroll={{ x: 1300 }}
        pagination={{
          onChange: onSwitchPage,
          position:['bottomRight'],
          defaultPageSize: pageSize,
          pageSize: pageSize,
          total: props.data && props.data.results.length,
          showSizeChanger: false,
          onShowSizeChange: onPageSizeChange,
          pageSizeOptions: selectedOrders.length> 0? []: ['10', '20', '50', '100'],
          current : props.current 
        }}
      />
    </Col>
  )
}

export default ClaimsSubmissionTable