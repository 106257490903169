import {
  Row,
  Col
} from 'antd'
import './styles/HomePage.css';
import { ShipmentAnalytics } from './components/ShipmentAnalytics';

export const NewDashboard = () =>  {
  return (
    <Row align="middle" justify="center">
      <Col span={24} className={'form-container'}>
        <ShipmentAnalytics />
      </Col>
    </Row>
  )
};
