import {Alert} from 'antd'
import React from "react";

export const CSVTemplateAlert = () => {
  return (
    <Alert type="info" showIcon message={
      <div style={{fontSize:'14px'}}>
        {"Please make sure to download the latest template to avoid any errors."}
      </div>
    }
    />
  )
}