import {
  Row,
  Typography,
  Card,
} from 'antd';
import {useMutation} from "react-query";
import {migration} from "./services/authApi";
import {useEffect, useState} from "react";
import { useAuth } from './services/authContext'
import Cookies from 'universal-cookie';
import { trackSignIn } from '../commons/mixpanel';

const { Text } = Typography;

const cookies = new Cookies();
const MigrationForm = () => {

  // const setToken = cookies.set('_tkn.merchant.janio.asia', '7814ebbba1476ef24d8b4a25321fcd88db53208b')
  const migrationToken: string = cookies.get('_tkn.merchant.janio.asia') 
  // const migrationToken: string = '9aa2fec4ed87df29177a8cc4aca9d59748c066dd'

  const auth = useAuth();
  const [errorMessage, setErrorMessage] = useState<string>('')
  const migrate = useMutation(async () => {
    return migration(migrationToken)
  },  {
    onSuccess: (val) => {
      const userSession = {
        isSignedIn: true,
        authToken: val.auth_token,
        b2c_id: val.b2c_id,
        email: val.email,
        fullName: val.full_name,
        phoneNumber: val.phone_number,
        billingCountry: val.billing_country,
        allowPostpaid: val.allow_postpaid,
        tnc: val.tnc,
        payment_type: val.payment_type,
        account_type: val.account_type,
        hide_price_estimation: val.hide_price_estimation,
        can_view_master_shipments: val.can_view_master_shipments,
        secret_key: val.secret_key,
        master_b2c_id: val.master_b2c_id
      };
      auth.setUserSession(userSession);
      trackSignIn(val.email, val.full_name)
    },
    onError: (error: any) => {
      if(error.response && error.response.data){
        setErrorMessage(error.response.data.error)
      }
      else{
        setErrorMessage(error.toString())
      }
    },
  });

  useEffect(() =>{
    if(migrationToken && migrate.isIdle){
      migrate.mutate();
    }
  },[migrationToken, migrate]);

  if(migrate.isIdle || migrate.isLoading){
    return <Text type="secondary" strong> You will be navigated to Home page once the Migration is successful. </Text>
  }
  else if(migrate.isError){
    return <Text style={{color:"red"}} type="secondary" strong>{errorMessage}</Text>
  }
  return <></>
};


export const Migration = () =>  {
  return (
    <Row align="middle" justify="center">
      <Card>
        <MigrationForm />
      </Card>
    </Row>
  )
};
