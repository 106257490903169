import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col, Image,Modal, Tooltip
} from 'antd';
import {useQuery} from "react-query";
import { InfoCircleOutlined } from '@ant-design/icons'
import {useHistory,useLocation} from "react-router-dom";
import './styles/ShipmentPage.css';
import '../dashboard/styles/HomePage.css';
// import single_order_return from '../assets/images/single_order_return.svg';
// import bulk_order_return from '../assets/images/bulk_order_return.svg';
import { Link } from 'react-router-dom';
import { UserViewedCreateShipment, Tip2Viewed } from '../commons/mixpanel'
import { useLanguage } from '../languages/Language';
import {Tips} from "../commons/components/Tips/Tips";
import single_shipment from '../assets/icons/single_shipment.png';
import bulk_shipment from '../assets/icons/bulk_shipment.png';
// import mps from '../assets/icons/mps.png';
import returns from '../assets/icons/returns.png';
import bulk_return from '../assets/icons/bulk_return.png';
import {shipperprofileFromB2C} from "../../src/auth/services/authApi";

// @ts-ignore
import singleOrderCreationVideo from '../assets/videos/Single_order_creation.mp4';
// @ts-ignore
import bulkOrderCreationVideo from '../assets/videos/Bulk_order_creation.mp4';
import { useAuth } from "../auth/services/authContext";

const { Meta } = Card;
const ShipmentOptions = () => {
  const auth = useAuth()
  let { getText } = useLanguage();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [orderType, setOrderType] = useState("");
  let history = useHistory();
  let location:any = useLocation();
  let prevPathName= location.state?.from;
  const [showTip, setShowTip] = useState((prevPathName==="/" || prevPathName==="/integrations")?true:false);

  const getShipperProfile = useQuery('shipperProfileDetails', () =>{
    return shipperprofileFromB2C(auth.user.secret_key)
  }, {cacheTime: 0});

  useEffect(() => {
    UserViewedCreateShipment(auth?.user?.fullName)

    if(getShipperProfile.isFetched) {
      localStorage.setItem('allow_custom_tracking_no', JSON.stringify(getShipperProfile.data?.allow_custom_tracking_no!))
    }

  }, [getShipperProfile.isFetched]) //eslint-disable-line react-hooks/exhaustive-deps

  // const displayIframeModal = (orderType:string) => {
  //   setShowModal(true);
  //   setOrderType(orderType);
  // }

  const onClose = () => {
    setShowModal(false)
    setOrderType("")
  }

  const setShowTipCallback = (val:boolean) => {
    setShowTip(val);
  }
  const onTipNextButtonClick = () => {
    history.push({
      pathname: '/integrations',
      state: {
        from: location.pathname
      }
    })
  }
  const onTipPrevButtonClick = () => {
    history.push({
      pathname: "/",
      state: {
        from: location.pathname
      }
    })
  }

  return (
    <div className={'shipment-container'}>
      <p className={'regular-bold-text'}>{'What type of shipment would you like to create?'}</p>
      <p style={{marginTop: '25px'}} className={'regular-bold-text'}>
        <label className="info-disclaimer">Forward Shipments:
          <Tooltip placement="right" title={'Create a forward shipment to your customer.'}>
            <InfoCircleOutlined />
          </Tooltip>
        </label>
      </p>
      <Row gutter={16} className={'cards-container'}>
        <Col span={8}>
          <Link to={'/orders/create/single'}>
            <Card
              hoverable
              className={'option-card'}
              cover={<Image preview={false} src={single_shipment} className={"shipment-logo"}/>}>
              <Meta title={getText('Single shipment')} description={getText('Create a shipment to ship to your customer')} className={'custom-card-meta'}/>
            </Card>
          </Link>
        </Col>
        <Col span={8}>
          <Link to={'/orders/create/bulk'}>
            <Card
              hoverable
              className={'option-card'}
              cover={<Image preview={false} src={bulk_shipment} className={"shipment-logo"}/>}>
              <Meta title={getText('Bulk shipment')} description={getText('Create shipments through our file upload submission')} className={'custom-card-meta'}/>
            </Card>
          </Link>
        </Col>

        {/* <Col span={8}>
          <Link to={'/orders/create/mps'}>
            <Card
              hoverable
              className={'option-card'}
              cover={<Image preview={false} src={mps} className={"shipment-logo"}/>}>
              <Meta 
                title={
                  <label className="info-disclaimer">MPS Shipments:
                    <Tooltip placement="right" title={'You can group packages and retrieve a one-to-many “master” tracking number.'}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </label>
                } 
                description={'Create multi parcel shipments through our file upload submission'} 
                className={'custom-card-meta'}
              />
            </Card>
          </Link>
        </Col> */}
      </Row>
      <Row justify={'start'} className={'video-links'}>
        <Col span={8}>
          {/* <div className="video-tutorials"><Link to="#" onClick={() => {displayIframeModal("single")}}>Learn how to create a single shipment</Link></div> */}
        </Col>
        <Col span={8}>
          {/* <div className="video-tutorials"><Link to="#" onClick={() => {displayIframeModal("bulk")}}>Learn how to create a bulk shipment</Link></div> */}
        </Col>
      </Row>
      <Row gutter={16} className={'cards-container'}>
        <Tips visible={showTip} step={'2'} placement={'bottom'} hidePreviousText={false} onPrevClick={onTipPrevButtonClick} onNextClick={onTipNextButtonClick} onExitTour={setShowTipCallback} contentMessage={"Create a single shipment or use our file upload to create multiple shipments"} mixpanelEvent={Tip2Viewed}>
        </Tips>
      </Row>
      {showModal?
        <Modal width={800} visible={showModal} onCancel={onClose} footer={null} className={'custom-modal'}>
          <iframe title={"Single order creation tutorial"} src={orderType==="single"?singleOrderCreationVideo:bulkOrderCreationVideo} width="100%" height="500px" style={{marginTop: 20}}>
          </iframe>
        </Modal>
        :<></>
      }

      <p style={{marginTop: '25px'}} className={'regular-bold-text'}>
        <label className="info-disclaimer">Return Shipments:
          <Tooltip placement="right" title={'Get your customer to ship back the shipments back to you.'}>
            <InfoCircleOutlined />
          </Tooltip>
        </label>
      </p>
      <Row gutter={16} className={'cards-container'}>
        <Col span={8}>
          <Link to={'/orders/create/return/single'}>
            <Card
              hoverable
              className={'option-card'}
              cover={
                <Image preview={false} src={returns} className={"shipment-logo"}/>
              }>
              <Meta title={'Return Single Shipment'} description={'Create a return shipment to ship back to your merchant'} className={'custom-card-meta'}/>
            </Card>
          </Link>
        </Col>
        <Col span={8}>
          <Link to={'/orders/create/return/bulk'}>
            <Card
              // style={{display: 'flex'}}
              hoverable
              className={'option-card'}
              cover={<Image preview={false} src={bulk_return} className={"shipment-logo"}/>}>
              <Meta title={'Return Bulk Shipment'} description={'Create return shipments through our file upload submission'} className={'custom-card-meta'}/>
            </Card>
          </Link>
        </Col>
      </Row>
    </div>
  )

};

export const CreateShipment = () =>  {
  return (
    <Row align="middle" justify="center">
      <Col span={16} className={'order-form-container'}>
        <Card>
          <ShipmentOptions />
        </Card>
      </Col>
    </Row>
  )
};
