import React, {useState} from "react";
import {Button, Image, Upload, message, Alert, Spin} from 'antd';
import { useMutation } from "react-query";
import '../../styles/BulkOrderForm.css';
import '../../styles/SingleOrderForm.css';
import upload from "../../../assets/images/upload.svg";
import file_icon from '../../../assets/images/file_icon.svg';
import {TextMap, itemCategories} from "../../../commons/utils/constants";
import {useBulkOrder} from "../../services/bulkOrderContext";
import {DownloadOutlined} from "@ant-design/icons/lib";
import * as _ from 'lodash';
import { CleanedRow, readFileAsJSON, cleanColumnsForwardOrder, performValidationForward, CleanedRowForward, consolidateForwardOrderItems, verifyItemUrlBulk} from "../../fileHelpers";
// @ts-ignore
import downloadCSV from '../../../assets/static/bulk_order_upload_csv_template_gst_option.csv';
// @ts-ignore
import downloadXlsx from '../../../assets/static/bulk_order_upload_xlsx_template_gst_option.xlsx'
import { orderPartialValidate } from '../../services/privateApi'
import { useAuth } from '../../../auth/services/authContext';
import { isApiError, RequestError } from "../../../commons/api";
import { useLanguage } from '../../../languages/Language';
import ItemProductUrlModal from "../../ItemProductUrlModal";

const isValidFile = (type: string, name: string) => {
  if(type){
    return type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      type === 'application/vnd.ms-excel' || type === 'text/csv';
  }
  else if(name){
    const temp = name.split('.');
    const extension = temp[temp.length-1];
    return extension === 'csv' || extension === 'xls' || extension === 'xlsx'
  }
  return false
};

interface PropsType {
    current: number,
    setCurrentState: (val: number) => void,
    dataFilled: number
}

const UploadErrorDisplayForward = ({serverError, codDduError}: any) => {
  if(serverError.message){
    return (
      <ul className={'error-list-body'}>
        {
          serverError.message.map((error: any, index: any) => {
            return <li className={'error-list-item'}>
              {error}
            </li>
          })
        }
      </ul>)
  }else{
    return (
      <div>Some error occured. Please try again after sometime
      </div>
    )
  }
  
}

const UploadErrorDisplay = ({serverError,codDduError}: any) => {
  let rowErrorsMapping: Record<string, Record<string, Number[]>> = {};
  let { getText } = useLanguage();
  if(serverError&&serverError.orders){
    for (const [index, line_errors] of Object.entries(serverError.orders)){
      let line_no = parseInt(index) + 2;
      for(const [key, keyErrors] of Object.entries(line_errors as any[])){
        if(keyErrors){
          if(key in rowErrorsMapping){
            keyErrors.forEach((keyError:any) => {
              if(keyError in rowErrorsMapping[key]){
                rowErrorsMapping[key][keyError].push(line_no)
              }
              else{
                rowErrorsMapping[key][keyError] = [line_no]
              }
            })
          }
          else{
            rowErrorsMapping[key] = {}
            keyErrors.forEach((keyError: any) =>
              rowErrorsMapping[key][keyError] = [line_no]
            )
          }
        }
      }
    }
  }
  let rowErrors:any = {...rowErrorsMapping!, ...codDduError!}
  return (
    <ul className={'error-list-body'}>
      {
        Object.entries(rowErrors)?.map(([key, value]:any) => {
          if(key === 'item_category') {
            return (
              <li className={'error-list-item'} key={key}>
                <strong>{key}</strong>{`: ${Object.keys(value)} ${getText("Please make the necessary change at line")} ${Object.values(value).toString()}.`} {getText("Please only use the following item categories")}:
                <ul className="item-category-error">
                  {itemCategories.map((categories, index) => {
                    return <li key={index}>{categories}</li>
                  })}
                </ul>
              </li>
            )
          }
          if(key === 'consignee_state') {
            return (
              <li className={'error-list-item'} key={key}>
                <strong>{key}</strong>{`: ${Object.keys(value)} ${getText("Please make the necessary change at line")} ${Object.values(value).toString()}.`} {getText("If you need help finding the correct state for your customer's address, please click")} <a href="http://apidocs.janio.asia/locations" target="_blank" rel="noreferrer"  style={{
                  color: '#050593',fontWeight: 600
                }}>here</a>
              </li>
            )
          }
          if(key === 'COD' || key === 'incoterm') {
            return (
              <li className={'error-list-item'} key={key}>
                <strong>{key}</strong>{`: ${Object.keys(value)} ${Object.values(value).toString()}.`}
              </li>
            )
          }
          if(key === 'custom_tn') {
            return (
              <li className={'error-list-item'} key={key}>
                <strong>{key}</strong>{`: ${Object.keys(value).toString()}`}
              </li>
            )
          }
          if(key === 'tracking_no' && Object.keys(value).toString().includes('items cannot be duplicate')) {
            return (
              <li className={'error-list-item'} key={key}>
                <strong>{key}</strong>{`: ${Object.keys(value).toString()}`}
              </li>
            )
          }
          return (
            <li className={'error-list-item'} key={key}>
              <strong>{key}</strong>{`: ${Object.keys(value)} ${getText("Please make the necessary change at line")} ${Object.values(value).toString()}.`}
            </li>
          )
        })
      }
    </ul>
  )
}

const SampleDownload = () => {
  let { getText } = useLanguage();
  return (
    <p className={'help-label'}>
      {getText("Need help getting started? Download our")} <a href={downloadCSV} download="Sample_CSV.csv">csv</a> {getText("and")} <a href={downloadXlsx} download="sample.xlsx">xlsx</a> {getText("templates here.")}
    </p>
  )
}

const isCustomTnExist = (data: any) => {
  return data.some((item: any) => {
    return item.tracking_no.length > 0
  })
}

export const UploadOrderForm = (props: PropsType) => {
  const isCustomTNPermission = JSON.parse(localStorage.getItem('allow_custom_tracking_no')!)
  let { getText } = useLanguage();
  const auth = useAuth();
  const [codDduError, setCodDduError] = useState();
  const [isCountExceed, setIsCountExceed] = useState<boolean>(false);
  const [orderData, setOrderData] = useState<Array<CleanedRowForward>>()
  const [doesTnExist, setDoesTnExist] = useState<boolean>(false)
  const bulkOrderContext = useBulkOrder();
  const {current, setCurrentState, dataFilled} = props;
  const authToken = auth.user.secret_key;
  const [showItemProductUrlModal, setShowItemProductUrlModal] = useState(false)

  // const replaceKeyWithNewKey = (arr: any, keyOne: string, keyTwo: string) => {
  //   return arr.map((item: any) => {
  //     let mutatedObj = {
  //       [keyTwo]: item[keyOne],
  //       'order_direction': 'FORWARD',
  //       ...item
  //     }
  //     delete mutatedObj[keyOne];

  //     return mutatedObj
  //   })
  // }



  const validateAndNormalizeData = useMutation<Array<CleanedRow>, RequestError, File>(async (file: File) => {
    const fileContent = await readFileAsJSON(file)
    if(fileContent.length < 1){
      throw new Error(getText('The uploaded file is empty'))
    }
    const cleanedRows = cleanColumnsForwardOrder(fileContent)
    if(isCustomTnExist(cleanedRows) && !isCustomTNPermission) {
      setDoesTnExist(true)
      return
    }
    setOrderData(cleanedRows)
    performValidationForward(cleanedRows)
    
    // const codDduRequestData = getCodDduOrders(cleanedRows);

    // if(codDduRequestData&&codDduRequestData.request.length>0){
    //   let validationResponse = await validateCodDdu(authToken,codDduRequestData);
    //   let errors = getCodDduValidationError(cleanedRows, validationResponse);
    //   setCodDduError(errors)
    // }

    // let replaceOriginCountry = replaceKeyWithNewKey(cleanedRows, 'origin_country', 'pickup_country')
    const response = await orderPartialValidate(authToken, cleanedRows);
    return response.data
  });

  const [fileName, setFileName] = useState(dataFilled === 1 ? 'bulk_order.csv' : '');
  const onSaveAndContinue = () => {
    const consolidatedOrderItems = consolidateForwardOrderItems(orderData!)
    let addFileNameWithOrder = {
      file_name: fileName,
      consolidatedOrderItems
    }
    if(addFileNameWithOrder?.consolidatedOrderItems.length > 500) {
      setIsCountExceed(true)
    }else if(verifyItemUrlBulk(consolidatedOrderItems)){
      setShowItemProductUrlModal(true)
    }else{
      bulkOrderContext.setParsedJson(addFileNameWithOrder)
      setCurrentState(1)
    }
  };

  const onProductUrlModalSubmit = () => {
    const consolidatedOrderItems = consolidateForwardOrderItems(orderData!)
    let addFileNameWithOrder = {
      file_name: fileName,
      consolidatedOrderItems
    }
    bulkOrderContext.setParsedJson(addFileNameWithOrder)
    setCurrentState(1)
    
  }

  const beforeUpload = (file: File) => {
    setCodDduError(undefined)
    setIsCountExceed(false)
    setDoesTnExist(false)
    bulkOrderContext.setBulkOrder({})
    bulkOrderContext.setPickUpFormContext({})
    const isValid = isValidFile(file.type, file.name);
    if (isValid) {
      setFileName(file.name)
      validateAndNormalizeData.mutate(file)
    }
    else {
      message.error(TextMap.FileTypesAccepted);
    }

    return false
  };

  const runReUpload = () => {
    return <div>
      <Upload
        action=""
        accept=".csv,.xlsx,.xls"
        name="file"
        showUploadList={false}
        beforeUpload={beforeUpload}
      >
        <Button icon={<DownloadOutlined />}
          className={"blue-button"} style={{borderRadius: 2}} >
          {getText('Reupload file')}
        </Button>
      </Upload>
    </div>
  }

  if(current === 0){
    // No file selected yet, render file uploader
    if(!fileName){
      return (
        <>
          <div className={'uploader'}>
            <Upload
              action=""
              accept=".csv,.xlsx,.xls"
              name="file"
              listType="picture-card"
              className={'uploader-container'}
              showUploadList={false}
              beforeUpload={beforeUpload}>
              <div className={'uploader-content'}>
                <Image style={{marginBottom: 10, marginTop: 30}} preview={false} src={upload}/>
                <p style={{fontWeight: 'bold', fontSize: 15}}>{getText('Click')} <a onClick={(e) => e.preventDefault()} href="" style={{
                  color: '#050593',
                  textDecoration: 'underline'
                }}>{getText('here')}</a> {getText('to browse your file')}</p>
                <p style={{marginBottom: 20, marginTop: 10}}>{getText('Files supported (.xls, .xlsx and .csv)')}</p>
              </div>
            </Upload>
          </div>
          <SampleDownload />
        </>
      )
    }

    if(validateAndNormalizeData.isLoading){
      return (
        <div className={'uploader-summary-container'} style={{minHeight: '120px', position: 'relative'}}>
          <div className={'uploader-summary'}>
            <Spin tip="Validating CSV..." className="validate-loader" />
          </div>
        </div>
      )
    }

    if((validateAndNormalizeData.isError && validateAndNormalizeData.error) || !_.isEmpty(codDduError)){
      return (
        <>
          <Alert
            showIcon
            message={getText("Please correct the following errors before reuploading your file:")}
            description={
              validateAndNormalizeData.error?(
                isApiError(validateAndNormalizeData.error) ?
                  <UploadErrorDisplayForward serverError={validateAndNormalizeData.error.response?.data} codDduError={codDduError}/> :
                  validateAndNormalizeData?.error?.message):
                <UploadErrorDisplay codDduError={codDduError}/>
            }
            type="error"
            className={'error-list-container'}
          />
          <SampleDownload />
          <div className="button-block">
            {runReUpload()}
          </div>
        </>
      )
    }

    if(isCountExceed){
      return (
        <>
          <Alert
            showIcon
            message={getText("Please correct the following errors before reuploading your file:")}
            description={'For smooth order creation process, please keep the orders count in your file less than or equal to 500.'}
            type="error"
            className={'error-list-container'}
          />
          <SampleDownload />
          <div className="button-block">
            {runReUpload()}
          </div>
        </>
      )
    }

    if(doesTnExist && !isCustomTNPermission) {
      return (
        <>
          <Alert
            showIcon
            message={getText("Please correct the following errors before reuploading your file:")}
            description={
              <>
                <span style={{display: 'block', fontSize: '15px'}}>You don't have permission to use custom tracking number. Please remove the value(s) under custom tracking number column.</span>
              </>
            }
            type="error"
            className={'error-list-container'}
          />
          <SampleDownload />
          <div className="button-block">
            {runReUpload()}
          </div>
        </>
      )
    }

    // All ok, render summary
    return (
      <>
        <div className={'uploader-summary-container'}>
          <div className={'uploader-summary'}>
            <Image preview={false} src={file_icon}/>
            <p>{fileName}</p>
          </div>
        </div>
        <div className="button-block">
          {runReUpload()}
          <Button type="primary" onClick={() => onSaveAndContinue()} className={"blue-button"} style={{borderRadius: 2, marginLeft: 10}}>
            {getText('Save & Continue')}
          </Button>
          <ItemProductUrlModal isVisible={showItemProductUrlModal} setIsVisible={setShowItemProductUrlModal} onSubmit={onProductUrlModalSubmit}/>
        </div>
      </>
    )
  }
  else{
    return (
      <div className={'uploader-summary-container'}>
        <div className={'uploader-summary'}>
          <Image preview={false} src={file_icon}/>
          <p>{fileName}</p>
        </div>
      </div>
    )
  }

};

export const UploadOrderFormTitle = (props: PropsType) => {
  let { getText } = useLanguage();
  const bulkOrderContext = useBulkOrder();
  const {current, setCurrentState} = props;
  return (
    <div className={"title-block"}>
      <p className={'step-title'}>{getText('Upload bulk shipments')}</p>
      {current > 0 && !bulkOrderContext.canEditOrder ? <p onClick={() => setCurrentState(0)} className={"edit-text"}>{getText('Edit details')}</p> : ''}
    </div>
  )
};