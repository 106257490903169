import { Button, Card, Col, Form, Row, Select, Tooltip } from 'antd';
import React, { useRef, useState, useEffect } from "react";
import { PickUpAddressModal as AddAddressModal } from "../../../UserSettings/components/PickUpAddressModal";
import { InfoCircleOutlined, EnvironmentOutlined } from '@ant-design/icons'
import moment from 'moment';
import { getAllCountries_from_b2c, getServiceDefinitions_from_b2c, getLocations_from_b2c } from "../../services/privateApi";
import { useSingleOrderB2C, OrderErrorB2C } from "../../services/singleOrderContext";
import { useAuth } from "../../../auth/services/authContext";
import {countryCodes, existingServices, pickupTimeSlots } from "../../../commons/utils/constants";
import { useQuery } from "react-query";
import '../../styles/SingleOrderForm.css';
import {getFormatedDate, parseDate, getFormattedServiceTypeText, filterOnlyRequiredDataFromB2c, isPickupServiceEnabled} from "../../../commons/utils/utilizer";
import { Formik, Form as FormikForm } from 'formik';
import { FormInput, FormSelect, DatePicker, TitleErrorMarker } from '../Forms';
import { disabledDate, parseErrors, pickupDateHasExpired,canChoosePickupDate, PickupDays} from '../../helpers';
import * as Yup from 'yup';
import { SingleOrderCreate } from "../../../commons/mixpanel"
import { useLanguage } from '../../../languages/Language';
import {PickupChargeAlert} from '../../../commons/components/PickupFeeAlerts/PickupChargeAlert';
const { Option } = Select;
interface PropsType {
  current: number,
  setCurrentState: (val: number) => void,
  dataFilled: number,
  setPickupDateExpiredCallback?: (val: boolean) => any
  isMarketplaceOrder?: boolean
 }

interface FormValue {
  shipper_order_id?: string
  consignee_country?: string,
  pickup_country?: string,
  pickup_point_id?: string | number,
  service_id?: string | number,
  service_level?: string,
  serviceType: string | null,
  pickup_date: moment.Moment | null
  pickup_time: string
}

interface PickupDetailsInterface {
  is_active?: boolean,
  pickup_point_address?: string,
  pickup_point_city?: string,
  pickup_point_contact_person?: string,
  pickup_point_country?: string,
  pickup_point_email?: string,
  pickup_point_id?: number,
  pickup_point_name?: string,
  pickup_point_notes?: string | null,
  pickup_point_number?: string,
  pickup_point_postal?: string,
  pickup_point_province?: string | null,
  pickup_point_state?: string
}
interface ServiceDefinitionInterface {
  dropoff_id?: number,
  dropoff_address?: string,
  dropoff_postal?: string,
  dropoff_point_country?: string,
  dropoff_point_state?: string,
  dropoff_point_city?: string,
  dropoff_point_province?: string,
  dropoff_point_contact_person?: string,
  dropoff_point_number?: string,
  source?: string,
  additional_data?: any,
  service_id?: string | number,
  origin_country?: string,
  destination_country?: string,
  service_type?: string,
  exclusive_agents?: [
    
  ],
  exclusive_agents_ids?: [
    
  ]
}

const parseOrderContextError = (error: OrderErrorB2C) => {
  let err = {
    pickup_address_id: '',
    consignee_country: error.consignee_country,
    serviceType: error.serviceType,
    pickup_date: error.pickup_date,
    shipper_order_id: error.shipper_order_id
  }

  let pickup_err = [];
  if (error.pickup_point?.pickup_point_name) {
    pickup_err.push(error.pickup_point?.pickup_point_name);
  }
  if (error.pickup_point?.pickup_point_number) {
    pickup_err.push(error.pickup_point?.pickup_point_number);
  }
  if (error.pickup_point?.pickup_point_address) {
    pickup_err.push(error.pickup_point?.pickup_point_address);
  }
  if (error.pickup_point?.pickup_point_address) {
    pickup_err.push(error.pickup_point?.pickup_point_address);
  }
  if (error.pickup_point?.pickup_point_city) {
    pickup_err.push(error.pickup_point?.pickup_point_city);
  }
  if (error.pickup_point?.pickup_point_postal) {
    pickup_err.push(error.pickup_point?.pickup_point_postal);
  }

  if (pickup_err.length > 0) {
    err.pickup_address_id = pickup_err.join(',')
  }

  return err;
}

const validationSchema = (props: any,getText: any, isMarketplaceOrder?: boolean) => {
  return (
    Yup.object().shape({
      pickup_country: Yup.string().required(getText('Please select Origin Country')),
      consignee_country: Yup.string().required(getText('Please select Destination Country')),
      serviceType: Yup.string().required(getText('Please select Service Type')),
      service_id: Yup.string().required(getText('Please select Address')),
      pickup_date: Yup.date().nullable().when(['serviceType', 'pickup_country'], {
        is: (serviceType: any, pickup_country: any) => (serviceType === 'Pickup' || serviceType === 'Pickup Express') && pickup_country === 'Singapore' && !isMarketplaceOrder,
        then: Yup.date().nullable().required('Please select Pickup Date')
          .test(
            'valid-date',
            (val: any, context: any) => {
              if (val) {
                let formattedDate = parseDate(val);
                if ((!moment().isBefore(moment({ hour: 8, minute: 0 })) && formattedDate!.isBefore(moment().endOf('day')))) {
                  return context.createError({
                    path: context.path,
                    message: getText('Please select valid date')
                  })
                }
              }
              return true
            }
          )
      }),
    })
  )
}

export const ShipmentDetailsForm = (props: PropsType) => {

  let { getText } = useLanguage();
  const resetFieldValuesOnPickupChange = ['consignee_country', 'serviceType', 'service_id','pickup_date'];
  const resetFieldValuesOnDestinationChange = ['serviceType', 'service_id'];
  const resetFieldValuesOnServiceTypeChange = ['dropoff_vendor_name','service_id','pickup_date'];
  const auth = useAuth();
  const singleOrderContext = useSingleOrderB2C();
  const ShipmentDetailsFormRef: any = useRef();
  let pickupDate = singleOrderContext.singleOrder?.pickup_date ? singleOrderContext.singleOrder!.pickup_date : null;
  const [pickupCompleteDetails, setPickupCompleteDetails] = useState<PickupDetailsInterface | undefined>({})
  const [serviceDefinition, setServiceDefinition] = useState<Array<ServiceDefinitionInterface> | undefined>([])
  const [orginCountry, setOrginCountry] = useState<string>(singleOrderContext.singleOrder.pickup_country ? singleOrderContext.singleOrder.pickup_country : '')
  const [destCountry, setDestCountry] = useState<string>(singleOrderContext.singleOrder.consignee_country ? singleOrderContext.singleOrder.consignee_country : '')
  const [uniquesServiceError, setUniqueServiceError] = useState<boolean>(false)

  const pickupLocationsFromb2c = useQuery('pickupLocationsFromb2c', () => {
    return getLocations_from_b2c(auth.user.secret_key)
  })
  const initialValue = {
    shipper_order_id: singleOrderContext.singleOrder.shipper_order_id,
    pickup_country: singleOrderContext.singleOrder.pickup_country,
    consignee_country: singleOrderContext.singleOrder.consignee_country,
    serviceType: singleOrderContext.singleOrder.serviceType,
    pickup_date: pickupDateHasExpired(pickupDate) ? null : parseDate(singleOrderContext.singleOrder.pickup_date),
    service_id: isPickupServiceEnabled(singleOrderContext.singleOrder.serviceType) || !existingServices.includes(singleOrderContext.singleOrder.serviceType) ? singleOrderContext.singleOrder.pickup_point_id : singleOrderContext.singleOrder.serviceDefinition!.dropoff_id,
    pickup_time: '8am-7pm SGT (UTC+8)'
  }
  const { initialErrors, initialTouched, hasError, emptyError } = parseErrors(
    singleOrderContext.errors, parseOrderContextError
  )
  const { current, setCurrentState, dataFilled } = props;
  const [showAddAddressModal, setShowAddAddressModal] = useState<boolean>(false);
  const [shipmentData, setShipmentData] = useState<FormValue | null>(null);
  const [addressType, setAddressType] = useState<string>('');

  // if(hasError){
  //   setCurrentState(0);
  //   if (ShipmentDetailsFormRef.current != null) {
  //     window.scrollTo(0, ShipmentDetailsFormRef.current.offsetTop);
  //   }
  // }

  const getAllCountriesData = useQuery('getAllCountriesData', () => {
    return getAllCountries_from_b2c()
  })

  // Fetch service Definitions Data From b2c
  const getServiceDefinitionsDataFromb2c = useQuery('serviceDefinitions', () => {
    return getServiceDefinitions_from_b2c(auth.user.secret_key)
  })

  const filterServiceDefinition = (pickupCountry?: string, consigneeCountry?: string, serviceType?: string, value?: any) => {
    if(getServiceDefinitionsDataFromb2c.data) {
      if(serviceType === 'Dropoff' || serviceType === 'Customs clearance and last mile' || serviceType === 'Dropoff Express' || serviceType === 'Dropoff Express Priority') {
        let filterOutServiceId = getServiceDefinitionsDataFromb2c.data.filter((data: any) => {
          return (data.origin_country === pickupCountry && data.destination_country === consigneeCountry && data.service_type === serviceType)
        }).filter(id => id!.dropoff_id === value)
        if(filterOutServiceId.length > 1) {
          let exclusiveLane = filterOutServiceId?.filter(item => item.exclusive_agents?.length! > 0)
          setServiceDefinition(exclusiveLane)
        } else {
          setServiceDefinition(filterOutServiceId)
        }
      } else {
        let filterOutServiceId = getServiceDefinitionsDataFromb2c.data.filter((data: any) => {
          return (data.origin_country === pickupCountry && data.destination_country === consigneeCountry && data.service_type === serviceType)
        })
        
        if(filterOutServiceId.length > 1) {
          let exclusiveLane = filterOutServiceId?.filter(item => item.exclusive_agents?.length! > 0)
          if(exclusiveLane.length > 0) {
            setServiceDefinition(exclusiveLane)
          } else {
            setUniqueServiceError(true)
          }
          
        } else {
          setServiceDefinition(filterOutServiceId)
        }
      }
    }
    return []
  }
  // @ts-ignore

  
  const setPickupObject = (pickup_point_id: string | number, selected_pickup_country: string) => {
    let pickupCountrySelectedArray = pickupLocationsFromb2c.data?.filter(l => l.pickup_point_country === selected_pickup_country)
    let pickupSelectedObject = pickupCountrySelectedArray?.filter(item => item.pickup_point_id === pickup_point_id)
    // console.log('sasasa', pickupSelectedObject)
    setPickupCompleteDetails(pickupSelectedObject![0])
  }

  const setShowAddAddressModalCallback = (val: boolean) => {
    setShowAddAddressModal(val);
    setAddressType('');

    // This will refetch the pickup locations every time the modal is triggered.
    // Ideally there should be an indicator if a new location is added and only
    // refetch then.
    pickupLocationsFromb2c.refetch()
  };

  const setCreatedLocationIdCallback = (id: string) => {
    if (ShipmentDetailsFormRef.current) {
      ShipmentDetailsFormRef.current.setFieldValue('service_id', id)
    }
  }
  useEffect(()=>{
    let pickupDateExpired = pickupDateHasExpired(pickupDate);
    if(pickupDateExpired){
      singleOrderContext.setErrors({pickup_date:'Please select new pickup date'})
      setCurrentState(0)
      if(props.setPickupDateExpiredCallback){
        props.setPickupDateExpiredCallback(true)
      }
    }

    if(props.isMarketplaceOrder) {
      let data = singleOrderContext.singleOrder
      if(pickupLocationsFromb2c.isFetched && getServiceDefinitionsDataFromb2c.isFetched) {
        if(data.serviceType === 'Dropoff' || data.serviceType === 'Dropoff Express' || data.serviceType === 'Dropoff Express Priority') {
          filterServiceDefinition(data.pickup_country, data.consignee_country, data.serviceType, data.serviceDefinition?.dropoff_id)
        } else {
          setPickupObject(data.pickup_point_id, data.pickup_country)
          filterServiceDefinition(data.pickup_country, data.consignee_country, data.serviceType)
        }
      }
    }
  },[pickupDate, pickupLocationsFromb2c.isFetched, getServiceDefinitionsDataFromb2c.isFetched]) // eslint-disable-line react-hooks/exhaustive-deps

  // console.log('singleOrderContext.singleOrder', singleOrderContext.singleOrder)
  // console.log('pickupCompleteDetails', pickupCompleteDetails)

  const onFormikSubmit = (values: FormValue) => {
    SingleOrderCreate('shipmentOverview', auth?.user?.fullName)
    SingleOrderCreate('customerDetails')
    setCurrentState(1);
    let pickup_date = getFormatedDate(values.pickup_date)
    // console.log('values.pickup_date', pickup_date)
    setShipmentData({ ...values });
    let shipmentDetailsContext = {
      shipper_order_id: values.shipper_order_id,
      additional_data: (values.serviceType === 'Dropoff' || values.serviceType === 'Dropoff Express' || values.serviceType === 'Dropoff Express Priority') ? {"ParknParcel": serviceDefinition![0].additional_data} : {},
      pickup_country: values.pickup_country,
      consignee_country: values.consignee_country,
      service_id: serviceDefinition![0].service_id,
      pickup_point_id: isPickupServiceEnabled(values.serviceType) || !existingServices.includes(values.serviceType) ? pickupCompleteDetails?.pickup_point_id : '',
      pickup_address: isPickupServiceEnabled(values.serviceType) || !existingServices.includes(values.serviceType) ? pickupCompleteDetails?.pickup_point_address : serviceDefinition![0].dropoff_address,
      serviceDefinition: serviceDefinition![0],
      pickup_state: isPickupServiceEnabled(values.serviceType) || !existingServices.includes(values.serviceType) ? pickupCompleteDetails?.pickup_point_state : serviceDefinition![0].dropoff_point_state,
      pickup_city: isPickupServiceEnabled(values.serviceType) || !existingServices.includes(values.serviceType) ? pickupCompleteDetails?.pickup_point_city : serviceDefinition![0].dropoff_point_city,
      pickup_postal: isPickupServiceEnabled(values.serviceType) || !existingServices.includes(values.serviceType) ? pickupCompleteDetails?.pickup_point_postal : serviceDefinition![0].dropoff_postal,
      pickup_point: isPickupServiceEnabled(values.serviceType) || !existingServices.includes(values.serviceType) ? pickupCompleteDetails : null,
      serviceType: values.serviceType,
      pickup_contact_name: (values.serviceType === 'Dropoff' || values.serviceType === 'Customs clearance and last mile' || values.serviceType === 'Dropoff Express' || values.serviceType === 'Dropoff Express Priority') ? serviceDefinition![0].dropoff_point_contact_person : pickupCompleteDetails?.pickup_point_contact_person,
      pickup_contact_number: (values.serviceType === 'Dropoff' || values.serviceType === 'Customs clearance and last mile' || values.serviceType === 'Dropoff Express' || values.serviceType === 'Dropoff Express Priority') ? serviceDefinition![0].dropoff_point_number : pickupCompleteDetails?.pickup_point_number,
      pickup_notes: null,
      pickup_date: pickup_date,
      extra_data: isPickupServiceEnabled(values.serviceType) || !existingServices.includes(values.serviceType) ? {pickup_point_id: pickupCompleteDetails?.pickup_point_id, dropoff_id: null} : {pickup_point_id: null, dropoff_id: serviceDefinition![0].dropoff_id},
      pickup_start_time: (values.pickup_country === 'Singapore' && values.consignee_country === 'Singapore') ?'08:00+08:00' : undefined,
      pickup_end_time: (values.pickup_country === 'Singapore' && values.consignee_country === 'Singapore') ?'19:00+08:00': undefined,
    }
    singleOrderContext.updateSingleOrderDataB2C(shipmentDetailsContext)

    singleOrderContext.setErrors({ ...emptyError })
  }

  if (current !== 0 && dataFilled === 0) {
    return (
      <>
        {shipmentData && <Card title={<TitleErrorMarker text={getText('Shipment Overview')} hasError={hasError} />} className={"form-summary-container"}>
          <p style={{ margin: 0 }}>{shipmentData.pickup_country!} to {shipmentData.consignee_country!}</p>
          <p className={'gray-text'}>{getFormattedServiceTypeText(shipmentData?.serviceType!)} service</p>
        </Card>}
      </>
    )
  }
  else if (current !== 0 && dataFilled === 1) {
    return (
      <>
        {singleOrderContext.singleOrder && <Card title={<TitleErrorMarker text={getText('Shipment Overview')} hasError={hasError} />} className={"form-summary-container"}>
          <p style={{ margin: 0 }}>{initialValue.pickup_country!} to {initialValue.consignee_country!}</p>
          <p className={'gray-text'}>{getFormattedServiceTypeText(initialValue?.serviceType!)}</p>
        </Card>}
      </>
    )
  }
  const filterServicesData = getServiceDefinitionsDataFromb2c.data?.filter((countryObj) => {
    return (orginCountry === countryObj.origin_country) && (destCountry === countryObj.destination_country)
  })
    .map(serviceDef => serviceDef.service_type)
    .filter((value, index, self) => self.indexOf(value) === index)

  const onPickupDateChange = (val:any) => {
    if(props.setPickupDateExpiredCallback){
      props.setPickupDateExpiredCallback(false)
    }
  }

  const showPickupDate = (originCountry: string) => {
    let countryArr = ['SG', 'TH']
    if(countryArr.includes(countryCodes[originCountry])) {
      return true
    } else {
      return false
    }
  }

  const getDropOffDataFromB2C = (originCountry?: string, destinationCountry?: string, serviceType?: string) => {
    if(getServiceDefinitionsDataFromb2c.data) {
      let dataArr = filterOnlyRequiredDataFromB2c(getServiceDefinitionsDataFromb2c.data, originCountry, destinationCountry, serviceType)
      if(serviceType === 'Customs clearance and last mile') {
        return dataArr?.filter((item: any) => {
          if(item.exclusive_agents?.length! > 0) {
            return item.exclusive_agents?.length! > 0
          } else{
            return item
          }
        })
      }
      return dataArr
    }
    return []
  }

  const onDestinationCountryChange = (value:any) => {
    if(destCountry !== value){
      setDestCountry(value)
      singleOrderContext.updateSingleOrderDataB2C({
        consignee_state: '',
        consignee_city: '',
        consignee_postal: '',
        cod_amt_to_collect: null,
        cod_amt_to_collect_currency: '',
        payment_type: 'prepaid',
        incoterm:'DDP'
      });
    }
  }

  return (
    <div ref={r => { ShipmentDetailsFormRef.current = ShipmentDetailsFormRef.current ? ShipmentDetailsFormRef.current : r; }}>
      <Formik
        innerRef={ShipmentDetailsFormRef}
        initialValues={initialValue}
        initialErrors={initialErrors}
        initialTouched={initialTouched}
        enableReinitialize={true}
        onSubmit={onFormikSubmit}
        validateOnChange={true}
        validationSchema={() => validationSchema(props, getText, props.isMarketplaceOrder)}>
        {(formik) => {
          return (
            <div className={"single-order-form-container ant-form-vertical"}>
              <FormikForm>

                <Form.Item label={<TitleErrorMarker text={getText('Shipment Overview')} hasError={hasError} />} className={"single-order-form-title"}></Form.Item>
                <Row gutter={14}>
                  <Col span={12}>
                    <FormInput name="shipper_order_id" label={getText("Shipper Order Id (Optional)")}/>
                  </Col>
                </Row>
                <Row gutter={14}>
                  <Col span={12}>
                    <FormSelect name="pickup_country" loading={getAllCountriesData.isLoading} label={getText('Origin Country')} required onChangeCallback={(value: any) => {
                      resetFieldValuesOnPickupChange.forEach(field => formik.setFieldValue(field, ''));
                      setOrginCountry(value)
                      setUniqueServiceError(false)
                    }}
                    >
                      
                      {
                        getAllCountriesData && getAllCountriesData.data?.map((item: any) => {
                          return <Option key={item} value={item}>{item}</Option>
                        })
                      }
                    </FormSelect>
                    {(getAllCountriesData.isError) && <p style={{ color: 'red', marginBottom: 0, marginTop: '-20px', fontSize: '11px' }}>{getText("Failed to load origin countries, please try again later")}</p>}
                  </Col>
                  <Col span={12}>
                    <FormSelect name="consignee_country" disabled={formik.values.pickup_country ? false : true} label={getText('Destination Country')} loading={getAllCountriesData.isLoading} required
                      onChangeCallback={(value: any) => {
                        resetFieldValuesOnDestinationChange.forEach((field) => {
                          formik.setFieldValue(field, '')
                        });
                        onDestinationCountryChange(value)
                        setUniqueServiceError(false)
                      }}
                    >
                      {
                        getAllCountriesData && getAllCountriesData.data?.map((item: any) => {
                          return <Option key={item} value={item}>{item}</Option>
                        })
                      }
                    </FormSelect>
                    {(getAllCountriesData.isError) && <p style={{ color: 'red', marginBottom: 0, marginTop: '-20px', fontSize: '11px' }}>{getText("Failed to load destination countries, please try again later")}</p>}
                  </Col>
                </Row>
                <Row gutter={14}>
                  <Col span={12}>
                    <FormSelect name="serviceType" required onChangeCallback={(value: any) => {resetFieldValuesOnServiceTypeChange.forEach(field => formik.setFieldValue(field, '')); setUniqueServiceError(false)
                    }} label={<label className="info-disclaimer">{getText('Service Type')}:<Tooltip title={getText('Our delivery team will come to your address to have the parcel pick up.')}><InfoCircleOutlined />
                    </Tooltip></label>} >
                      {
                        filterServicesData?.map((type: any, index: number) => {
                          return <Option key={index} value={type}>{getFormattedServiceTypeText(type)}</Option>
                        })
                      }
                    </FormSelect>
                    {uniquesServiceError && <div style={{color: 'red', marginTop: '-23px'}}>Unable to find a unique service</div>}
                  </Col>
                  <Col span={12} className={uniquesServiceError ? 'hideBlock' : ''}>
                    {((isPickupServiceEnabled(formik.values.serviceType)) || (formik.values.serviceType && !existingServices.includes(formik.values.serviceType))) && (
                      <FormSelect onSelectCallback={(value: any) => {
                        setPickupObject(value, formik.values.pickup_country!)
                        filterServiceDefinition(formik.values.pickup_country!, formik.values.consignee_country!, formik.values.serviceType!)
                      }} name="service_id" required label={getText('Pickup Address')} loading={pickupLocationsFromb2c.isLoading} wrapperClass="no-margin-item" placeholder={'Select an address'} showSearch={'false'} defaultValue={formik.values.service_id?.toString()}>
                        {
                          pickupLocationsFromb2c.data?.filter(l => l.pickup_point_country === formik.values.pickup_country!
                            // && l.type === 'FORWARD'
                          ).map(item => <Option key={item.pickup_point_id} value={item.pickup_point_id}>{`${item.pickup_point_name} - ${item.pickup_point_address}`}</Option>)
                        }
                      </FormSelect>)
                    }
                    {(formik.values.serviceType === 'Dropoff' || formik.values.serviceType === 'Dropoff Express' || formik.values.serviceType === 'Dropoff Express Priority') && (
                      <FormSelect onSelectCallback={(value: any) => {
                        setPickupObject(value, formik.values.pickup_country!)
                        filterServiceDefinition(formik.values.pickup_country!, formik.values.consignee_country!, formik.values.serviceType!, value)
                      }} name="service_id" required
                      label={<label>{'Drop-off Address'} <EnvironmentOutlined style={{color: 'red', fontSize: '14px', position: 'relative', top: '1px'}} /></label>} wrapperClass="no-margin-item" placeholder={'Select an Address'}>
                        {
                          getDropOffDataFromB2C(formik.values?.pickup_country!, formik.values?.consignee_country!, 'Dropoff')?.map((item:any) => item.dropoff_address &&  <Option className={"dropoff-dropdown"} key={item.dropoff_address} value={item.dropoff_id}>{`${item.service_id ? item.dropoff_address : 'No Data'}`}</Option>)
                        }
                      </FormSelect>)
                    }

                    {(formik.values.serviceType === 'Customs clearance and last mile') && (
                      <FormSelect onSelectCallback={(value: any) => {
                        setPickupObject(value, formik.values.pickup_country!)
                        filterServiceDefinition(formik.values.pickup_country!, formik.values.consignee_country!, formik.values.serviceType!, value)
                      }} name="service_id" required
                      label={<label>{'Pickup Address'}</label>} wrapperClass="no-margin-item" placeholder={'Select a drop-off Address'}>
                        {
                          getDropOffDataFromB2C(formik.values?.pickup_country!, formik.values?.consignee_country!, 'Customs clearance and last mile')?.map((item:any) => item.dropoff_address &&  <Option className={"dropoff-dropdown"} key={item.dropoff_address} value={item.dropoff_id}>{`${item.service_id ? item.dropoff_address : 'No Data'}`}</Option>)
                        }
                      </FormSelect>)
                    }

                    {((isPickupServiceEnabled(formik.values.serviceType)) || (formik.values.serviceType && !existingServices.includes(formik.values.serviceType))) && <Form.Item className={'no-margin-item'}>
                      <Button type="primary" onClick={() => {
                        setShowAddAddressModal(true);
                        setAddressType('pickup')
                      }} className={"no-border-btn"}>
                        {getText('Add new address')}
                      </Button>
                      {(showAddAddressModal && addressType === 'pickup') &&
                      <AddAddressModal
                        setShowAddAddressModalCallback={setShowAddAddressModalCallback}
                        setCreatedLocationIdCallback={setCreatedLocationIdCallback}
                        countryCode={formik.values.pickup_country}
                        addressType={'pickup'}
                      />}
                    </Form.Item>}
                  </Col>
                </Row>
                {
                  showPickupDate(formik.values.pickup_country!) &&
                    <Row gutter={14} className={uniquesServiceError ? 'hideBlock' : ''}>
                      <Col span={12}>
                        {((isPickupServiceEnabled(formik.values.serviceType)) || (formik.values.serviceType && !existingServices.includes(formik.values.serviceType))) && (<>
                          <DatePicker disabledDate={disabledDate} required={formik.values.pickup_country === 'Singapore' && !props.isMarketplaceOrder} showToday={false} name="pickup_date" label={`Pickup Date ${props.isMarketplaceOrder ? '(Optional)' : ''}`} wrapperClass="no-margin-item"
                            onChange={onPickupDateChange} disabled={canChoosePickupDate(formik.values.pickup_country!)}
                          />
                          <Form.Item label={canChoosePickupDate(formik.values.pickup_country!)?`Your order will be picked up within ${PickupDays[formik.values.pickup_country!]} working days`:getText('If not selected, it will be pickup the next business day')} className={'form-item-info'}></Form.Item>
                        </>)}
                      </Col>
                      <Col span={12}>
                        {((isPickupServiceEnabled(formik.values.serviceType)) || (formik.values.serviceType && !existingServices.includes(formik.values.serviceType))) && 
                        (formik.values.consignee_country === 'Singapore' && formik.values.pickup_country === 'Singapore') &&
                        <>
                          {/* <FormInput name="pickup_time" label={<label className="info-disclaimer">{getText('Pickup Time')}:<Tooltip title={getText('If order is created before 8 AM then pickup will be same day else next day')}><InfoCircleOutlined />
                          </Tooltip></label>} wrapperClass="no-margin-item" inputProps={{ disabled: true }} /> */}
                          
                          
                          <FormSelect name="pickup_time" label={<label className="info-disclaimer">{getText('Pickup Time')}:<Tooltip title={getText('If order is created before 8 AM then pickup will be same day else next day')}><InfoCircleOutlined />
                          </Tooltip></label>} wrapperClass="no-margin-item" disabled={true} >
                            {
                              Object.entries(pickupTimeSlots).map(([pickupTimeKey, pickupTimeValue]:[pickupTimeKey: any, pickupTimeValue: any]) => (
                                <Option key={pickupTimeKey} value={pickupTimeValue}>{pickupTimeKey}</Option>
                              ))
                            }
                          </FormSelect>
                          <Form.Item label={getText('Timing are fixed and cannot be selected')} className={'form-item-info'}></Form.Item>
                        </>}
                      </Col>
                    </Row>
                }
                
                {!props.isMarketplaceOrder &&
                  (((isPickupServiceEnabled(formik.values.serviceType)) || (formik.values.serviceType && !existingServices.includes(formik.values.serviceType))) && formik.values.service_id) &&
                  <div className={uniquesServiceError ? 'hideBlock' : ''}>
                    <p><strong>{formik.values.serviceType} Information</strong></p>
                    <div className="dropoff-gray-text">
                      {formik.values.pickup_date &&
                        <div className="gray-text-grouping">
                          <p><strong>Pickup Date</strong> </p>
                          <span>Pickup Date: {getFormatedDate(formik.values.pickup_date)}</span>
                        </div>
                      }
                      <div className="gray-text-grouping">
                        <p><strong>Pickup Time</strong> </p>
                        <span>Time: Timings are fixed between 9am - 7pm</span>
                      </div>
                      <div className="gray-text-grouping">
                        <p><strong>Contact Person</strong> </p>
                        <span>Name: {pickupCompleteDetails?.pickup_point_contact_person}</span>
                        <span>Phone: {pickupCompleteDetails?.pickup_point_number}</span>
                      </div>
                      <div className="gray-text-grouping">
                        <p><strong>Pickup Location</strong> </p>
                        <span>Address: {pickupCompleteDetails?.pickup_point_address}</span>
                        <span>Postal Code: {pickupCompleteDetails?.pickup_point_postal}</span>
                        <span>State: {pickupCompleteDetails?.pickup_point_state}</span>
                        <span>City: {pickupCompleteDetails?.pickup_point_city}</span>
                      </div>
                    </div>
                  </div>
                }
                {!props.isMarketplaceOrder &&
                  ((formik.values.serviceType === 'Dropoff' || formik.values.serviceType === 'Customs clearance and last mile' || formik.values.serviceType === 'Dropoff Express' || formik.values.serviceType === 'Dropoff Express Priority') && formik.values.service_id && serviceDefinition!.length > 0) &&
                  <div className={uniquesServiceError ? 'hideBlock' : ''}>
                    <p><strong>{formik.values.serviceType === 'Dropoff' || formik.values.serviceType === 'Dropoff Express Priority' ? 'Dropoff' : 'Pickup'} Information</strong></p>
                    <div className="dropoff-gray-text">
                      <div className="gray-text-grouping">
                        <p><strong>Contact Person</strong> </p>
                        <span>Name: {serviceDefinition![0].dropoff_point_contact_person}</span>
                        <span>Phone: {serviceDefinition![0].dropoff_point_number}</span>
                      </div>
                      <div className="gray-text-grouping">
                        <p><strong>{formik.values.serviceType === 'Dropoff' || formik.values.serviceType === 'Dropoff Express Priority' ? 'Dropoff' : 'Pickup'} Location</strong> </p>
                        <span>Address: {serviceDefinition![0].dropoff_address}</span>
                        <span>Postal Code: {serviceDefinition![0].dropoff_postal}</span>
                        <span>State: {serviceDefinition![0].dropoff_point_state}</span>
                        <span>City: {serviceDefinition![0].dropoff_point_city}</span>
                      </div>
                    </div>
                  </div>
                  
                }

                {!uniquesServiceError && ((formik.values.serviceType === 'Pickup' || formik.values.serviceType === 'Pickup Express')&&formik.values.pickup_country==='Singapore') &&
                  <PickupChargeAlert/>
                }
                <div className={`button-block ${uniquesServiceError ? 'hideBlock' : ''}`}>
                  <Button htmlType="submit" type="primary" className={"blue-button"}>
                    {getText('Save & Continue')}
                  </Button>
                </div>

              </FormikForm>
            </div>
          )
        }}
      </Formik>
    </div>
  )
};

export const ShipmentDetailsFormTitle = (props: PropsType) => {
  let { getText } = useLanguage();
  const { current, setCurrentState } = props;
  return (
    <div className={"title-block"}>
      <p className={'step-title'}>{getText('Where are we picking up from?')}</p>
      {current > 0 ? <p onClick={() => setCurrentState(0)} className={"edit-text"}>{getText('Edit details')}</p> : ''}
    </div>
  )
};