import { instance as api} from "../../commons/api";

export const getShipmentBreakdownApi = async(range: string, end_date: string, token: string) => {
  const endpoint = end_date? `dashboard/charts/shipments_breakdown/?start_date=${range}&end_date=${end_date}` :
    `dashboard/charts/shipments_breakdown/?start_date=${range}`
  let response = await api.get(endpoint,{
    headers: {
      'Authorization': `Token ${token}`
    }});
  return response.data
}

export const getShipmentPerformanceApi = async(range: string, end_date: string, token: string) => {
  const endpoint = end_date? `dashboard/charts/shipments_performance/?start_date=${range}&end_date=${end_date}` :
    `dashboard/charts/shipments_performance/?start_date=${range}`
  let response = await api.get(endpoint,{
    headers: {
      'Authorization': `Token ${token}`
    }});
  return response.data
}

export const getShipmentbyDestinationCountryApi = async(range: string, end_date: string, granularity: string, token: string) => {
  const endpoint = end_date? `dashboard/charts/shipments_by_destination_country/?start_date=${range}&end_date=${end_date}&time_grain=${granularity}` :
    `dashboard/charts/shipments_by_destination_country/?start_date=${range}&time_grain=${granularity}`
  let response = await api.get(endpoint,{
    headers: {
      'Authorization': `Token ${token}`
    }});
  return response.data
}

export const getShipmentbyDestinationZoneApi = async(range: string, end_date: string, granularity: string, token: string) => {
  const endpoint = end_date? `dashboard/charts/shipments_by_destination_zone/?start_date=${range}&end_date=${end_date}&time_grain=${granularity}` :
    `dashboard/charts/shipments_by_destination_zone/?start_date=${range}&time_grain=${granularity}`
  let response = await api.get(endpoint,{
    headers: {
      'Authorization': `Token ${token}`
    }});
  return response.data
}

export const getSlaPerformanceByDestinationZoneApi = async(range: string, end_date: string, granularity: string, token: string) => {
  const endpoint = end_date? `dashboard/charts/get_sla_performance_by_destination_zone/?start_date=${range}&end_date=${end_date}&time_grain=${granularity}` :
    `dashboard/charts/get_sla_performance_by_destination_zone/?start_date=${range}&time_grain=${granularity}`
  let response = await api.get(endpoint,{
    headers: {
      'Authorization': `Token ${token}`
    }});
  return response.data
}

export const getSlaPerformanceApi = async(range: string, end_date: string, granularity: string, token: string) => {
  const endpoint = end_date? `dashboard/charts/get_sla_performance/?start_date=${range}&end_date=${end_date}&time_grain=${granularity}` :
    `dashboard/charts/get_sla_performance/?start_date=${range}&time_grain=${granularity}`
  let response = await api.get(endpoint,{
    headers: {
      'Authorization': `Token ${token}`
    }});
  return response.data
}

export const getBreachedSlaApi = async(range: string, end_date: string, token: string) => {
  const endpoint = end_date? `dashboard/charts/breached_sla/?start_date=${range}&end_date=${end_date}` :
    `dashboard/charts/breached_sla/?start_date=${range}`
  let response = await api.get(endpoint,{
    headers: {
      'Authorization': `Token ${token}`
    }});
  return response.data
}

export const getShipmentNotMovingUpdateApi = async(range: string, end_date: string, token: string) => {
  const endpoint = end_date? `dashboard/charts/shipments_not_moving_since_last_update/?start_date=${range}&end_date=${end_date}` :
    `dashboard/charts/shipments_not_moving_since_last_update/?start_date=${range}`
  let response = await api.get(endpoint,{
    headers: {
      'Authorization': `Token ${token}`
    }});
  return response.data
}