import {
  Col,
  Row,
  Tooltip,
  Radio
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons'
import './reusable.css'


export const LabelRadio = (props: any) => {

  const onChange = (e: any) => {
    props.setFreeTextCallback(e.target.value);
  };

  return (
    <Row>
      <Col span={24} onClick={(e) => e.preventDefault()}>
        <Tooltip 
          placement="right" 
          title={'Please choose a city from the provided dropdown menu or manually enter the city if it is not available in the list.'}><span className='required_star'>*</span>City
          <InfoCircleOutlined style={{fontSize: '12px', marginLeft: '4px', marginTop: '1px'}} />
        </Tooltip>
      </Col>
      <Col span={24}>
        <Radio.Group onChange={onChange} value={props.freeText} className={'radio_inline'}>
          <Radio value={'dropdown'}>Select from list</Radio>
          <Radio value={'input'}>Other
            {/* hack => Added 2 spans to add the preventDefault() functionality which was selecting 1st radio button on clicking anywhere outside */}
            <span onClick={(e) => e.preventDefault()} 
              style={{
                width: '100%', 
                display: 'block', 
                height:'38px', 
                position: 'absolute', 
                top: 0, 
                left: '60px', 
                cursor: 'default'
              }}>
            </span>
            <span onClick={(e) => e.preventDefault()} 
              style={{
                width: '100%', 
                display: 'block', 
                height:'14px', 
                position: 'absolute', 
                top: '17px', 
                left: '23px', 
                cursor: 'default'
              }}>
            </span>
          </Radio>
        </Radio.Group>
      </Col>
    </Row>
  )
}