import React, { useEffect, useState } from 'react'
import {Row, Col, Card, Steps} from 'antd';
import { B2BOrderContextProvider } from './b2bOrderCreationContext';
import { ShippingOriginForm, ShippingOriginFormTitle } from './Components/ShippingOriginForm';
import { ShippingDestinationForm, ShippingDestinationFormTitle } from './Components/ShippingDestinationForm';
import { GoodsDetailsForm, GoodsDetailsFormTitle } from './Components/GoodsDetailsForm';
import { ShippingDetailsForm, ShippingDetailsFormTitle } from './Components/ShippingDetailsForm';
import { RecommendedServicesForm, RecommendedServicesFormTitle } from './Components/RecommendedServicesForm';
import { TermsAndConditions, TermsAndConditionsTitle } from './Components/TermsAndConditions';

const {Step} = Steps;
const B2BOrdersPage = () => {
  const [current, setCurrent] = useState(0)
  const [progress, setProgress] = useState(0)
  const setCurrentState = (val: number) => {
    setCurrent(val);
  };
  
  const steps = [
    {
      title: <ShippingOriginFormTitle current={current} setCurrentState={setCurrentState} />,
      content: <ShippingOriginForm current={current} setCurrentState={setCurrentState} />
    },
    {
      title: <ShippingDestinationFormTitle current={current} setCurrentState={setCurrentState} />,
      content: <ShippingDestinationForm current={current} setCurrentState={setCurrentState} />
    },
    {
      title: <ShippingDetailsFormTitle current={current} setCurrentState={setCurrentState} />,
      content: <ShippingDetailsForm current={current} setCurrentState={setCurrentState} />
    },
    {
      title: <GoodsDetailsFormTitle current={current} setCurrentState={setCurrentState} />,
      content: <GoodsDetailsForm current={current} setCurrentState={setCurrentState} />
    },
    {
      title: <RecommendedServicesFormTitle current={current} setCurrentState={setCurrentState} />,
      content: <RecommendedServicesForm current={current} setCurrentState={setCurrentState} />
    },
    {
      title: <TermsAndConditionsTitle current={current} setCurrentState={setCurrentState} />,
      content: <TermsAndConditions current={current} setCurrentState={setCurrentState} />
    }
  ]

  
  useEffect(() => {
    if (current > progress) {
      setProgress(current);
    }
  }, [current, progress]);

  return (
    <>
      <Steps direction='vertical' current={current}>
        {
          steps.map((item, index) =>( 
            <Step 
              key={index} 
              title={item.title} 
              description={(current >= index || progress >= index)? item.content: ''} 
            /> 
          ))
        }
      </Steps>
    </>
  )
}


const B2BOrdersWrapper = () => {
  return (
    <Row align='middle' justify='center'>
      <Col span={24} className={'claim-container'}>
        {/* <Link to={'/orders/create'}>
          <p><CaretLeftOutlined/> <strong>Create B2B Shipment</strong></p>
        </Link> */}
        <Card className='claim-card'>
          <B2BOrderContextProvider>
            <B2BOrdersPage/>
          </B2BOrderContextProvider>
        </Card>
      </Col>
    </Row>
  )
}

export default B2BOrdersWrapper