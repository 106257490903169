import { Form, Row, Col, Select, Button, Divider, Form as F, message } from 'antd'
import '../../styles/ReturnOrderForm.css'
// import React, { useEffect, useState } from 'react'
import {useMutation} from "react-query";
import { useAuth } from '../../../auth/services/authContext'
import { Formik, Form as FormikForm, FieldArray, FormikHelpers } from 'formik'
import {useSingleOrderB2C, ItemB2C, OrderErrorB2C, combineFieldsErrorsB2C, formatItemErrorsB2C} from "../../services/returnSingleOrderContext";
import * as Yup from 'yup';
import {fetch_b2c_price_estimates, B2CPriceEstimatesResponse} from "../../../orders/services/privateApi";
import {parseErrors} from '../../../orders/helpers';
import {validateVolumetricWeight} from "../../../commons/utils/validator";
import { useLanguage } from '../../../languages/Language';
import {FormInput, FormSelect} from '../../../orders/components/Forms';
import {ItemCategoriesFromB2C, countryCurrencies, contactCustomerService, mostUsedCurrencies, countryCodeCurrencies} from "../../../commons/utils/constants";
// import {itemCategories, countryCurrencies, GoodsListSheetIds, countries, contactCustomerService,countryCodeCurrencies} from "../../../commons/utils/constants";


interface PropsType{
  current: number,
  setCurrentState: (val: number) => void,
  dataFilled?: number
}
interface OrderProps {
  items: ItemB2C[]
  order_weight: number | null
  order_length: number | null
  order_width: number | null
  order_height: number | null
  custom_tn?: any
}

const validationSchema = (getText:any, client_billing: string) => {
  return Yup.object().shape({
    order_weight: Yup.string().nullable().required(getText('Weight is required')).test(
      'volumetric_weight',
      (val: any, context: any) => {
        const result = validateVolumetricWeight({weight:val, length:context.parent.order_length, width:context.parent.order_width, height:context.parent.order_height}, client_billing);
        if(result && result === 'weight'){
          message.error(<p>{getText("Your parcel exceeds our volumetric weight. Please reach out to us at")} <a href={'mailto:clientsupport@janio.asia'}>clientsupport@janio.asia</a> {getText("should you wish to ship with us.")}</p>, 5);
          return context.createError({
            path: context.path,
            message: getText('Exceeded volumetric weight')
          })
        }
        return true
      }
    ),
    order_length: Yup.string().nullable().required(getText('Length is required')).test(
      'volumetric_weight',
      (val: any, context: any) => {
        const result = validateVolumetricWeight({weight:context.parent.order_weight, length:val, width:context.parent.order_width, height:context.parent.order_height}, client_billing);
        if(result && result === 'volumetric weight'){
          message.error(<p>{getText("Your parcel exceeds our volumetric weight. Please reach out to us at")} <a href={'mailto:clientsupport@janio.asia'}>clientsupport@janio.asia</a> {getText("should you wish to ship with us.")}"</p>, 5);
          return context.createError({
            path: context.path,
            message: getText('Exceeded volumetric weight')
          })
        }
        return true
      }
    ),
    order_width: Yup.string().nullable().required(getText('Width is required')).test(
      'volumetric_weight',
      (val: any, context: any) => {
        const result = validateVolumetricWeight({weight:context.parent.order_weight, length:context.parent.order_length, width:val, height:context.parent.order_height}, client_billing);
        if(result && result === 'volumetric weight'){
          return context.createError({
            path: context.path,
            message: getText('Exceeded volumetric weight')
          })
        }
        return true
      }
    ),
    order_height: Yup.string().nullable().required(getText('Height is required')).test(
      'volumetric_weight',
      (val: any, context: any) => {
        const result = validateVolumetricWeight({weight:context.parent.order_weight, length:context.parent.order_length, width:context.parent.order_width, height:val}, client_billing);
        if(result && result === 'volumetric weight'){
          return context.createError({
            path: context.path,
            message: getText('Exceeded volumetric weight')
          })
        }
        return true
      }
    ),
    custom_tn: Yup.string().nullable().notRequired().when(
      ['custom_tn'], {
        is: (value: any) => value?.length,
        then: (rule) => {
          return rule.matches(
            /^[a-zA-Z0-9-]*$/,
            "Custom Tracking numbers must be alphanumeric (a-z, A-Z, 0-9, hyphen(-))"
          )
        }
      }
    ),
    items: Yup.array().of(Yup.object().shape({
      item_desc: Yup.string().nullable().required(getText('Item Description is required')),
      item_category: Yup.string().nullable().required(getText('Item Category is required')),
      item_quantity: Yup.number().integer('Quantity must be an Integer').nullable().required(getText('Item Quantity is required')),
      // item_price_currency: Yup.string().nullable().required(getText('Required')),
      item_price_currency: Yup.string().nullable().required(getText('Required')).test(
        'item_price_currency',
        (val: any, context: any) => {
          if(!mostUsedCurrencies.includes(val)){
            return context.createError({
              path: context.path,
              message: `${val} is currently not a supported currency`
            })
          }
          return true
        }
      ),
      item_price_value: Yup.string().nullable().required(getText('Required')),
      item_product_url: Yup.string().nullable().max(200,'Cannot be more than 200 characters')
    })),
  },
  // cyclic dependency
  [['custom_tn', 'custom_tn']])
}

const {Option, OptGroup} = Select;
const ItemForm = ({isDomestic, remove, index}: {isDomestic: boolean, remove: () => any, index: number}) => {
  let { getText } = useLanguage();
  return (
    <>
      <FormInput name={`items.[${index}].item_desc`} required label={getText('Item Description')}/>

      <Row gutter={14}>
        <Col span={12}>
          <FormSelect name={`items.[${index}].item_category`} required label={getText('Item Category')} placeholder={'Please select item category'}>
            {ItemCategoriesFromB2C.map(item => (
              <Option key={item} value={item}>{item}</Option>
            ))}
          </FormSelect>
        </Col>

        <Col span={12}>
          <Row gutter={14}>
            <Col span={6}>
              <FormInput name={`items.[${index}].item_quantity`} required label={getText('Quantity')} inputProps={{type: 'number', min: 1, step:1}}/>
            </Col>
            <Col span={8}>
              <FormSelect name={`items.[${index}].item_price_currency`} required label={getText('Currency')} showSearch={"true"}>
                <>
                  <OptGroup label="Most Used">
                    {mostUsedCurrencies.map(item => (
                      <Option key={item} value={item}>{item}</Option>
                    ))}
                  </OptGroup>
                  <OptGroup label="All">
                    {countryCurrencies.map(item => (
                      <Option key={item} value={item}>{item}</Option>
                    ))}
                  </OptGroup>
                </>
              </FormSelect>
            </Col>
            <Col span={10}>
              <FormInput name={`items.[${index}].item_price_value`} required label={getText('Per Item Value')} inputProps={{type: 'number', min: 1, step:0.01}} />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={14}>
        <Col span={12}>
          <FormInput name={`items.[${index}].item_product_id`} label={getText('Product ID (Optional)')}/>
        </Col>
        <Col span={12}>
          <FormInput name={`items.[${index}].item_product_url`} label={getText('Product URL (Optional)')}/>
        </Col>
        <Col span={12}>
          <FormInput name={`items.[${index}].item_sku`} label={getText('Item SKU (Optional)')}/>
        </Col>
      </Row>

      {index > 0 && <div className={"button-block"}>
        <Button className={'outline-btn'} onClick={remove}>
          {`Remove`}
        </Button>
      </div>
      }
    </>)
}
const empty_item = {
  item_desc: '',
  item_category: '',
  item_quantity: '',
  item_price_currency: '',
  item_price_value: '',
  item_product_id: '',
  item_sku: '',
  item_product_url: '',
}

const parseOrderContextError = (error: OrderErrorB2C) => ({
  items: error.items,
  order_weight: error.order_weight,
  order_length: error.order_length,
  order_height: error.order_height,
  order_width: error.order_width,
  custom_tn: error.tracking_no
})

export const ItemDetailsForm = (props: PropsType) => {
  const isCustomTNPermission = JSON.parse(localStorage.getItem('allow_custom_tracking_no')!)
  const singleOrderContext = useSingleOrderB2C();
  let { getText } = useLanguage();
  const auth = useAuth()
  
  const initialValues: any = {
    items: singleOrderContext.singleOrder.items,
    order_weight:  singleOrderContext.singleOrder.order_weight,
    order_length: singleOrderContext.singleOrder.order_length,
    order_height: singleOrderContext.singleOrder.order_height,
    order_width: singleOrderContext.singleOrder.order_width,
    custom_tn: singleOrderContext.singleOrder.tracking_no
    // incoterm: singleOrderContext.singleOrder.incoterm === 'DDU'?'DDU':'DDP',
    // cod_currency: consigneeCurrency,
    // cod_amount: singleOrderContext.singleOrder.cod_amount,
    // isCod: singleOrderContext.singleOrder.payment_type === 'cod'?'true':'false'
  }

  const {emptyError} = parseErrors(singleOrderContext.errors, parseOrderContextError)

  // const submitOrder = useMutation(async (args: any) => {
  //   return singleOrderContext.submitSingleOrder(auth.user.secret_key);
  // });

  const validateOrder = useMutation(async (args: any) => {
    return singleOrderContext.UpdateSingleOrder(auth.user.secret_key);
  });

  const fetchPriceEstimatesInB2cSingleOrder = useMutation(async () => {
    let formData = singleOrderContext.singleOrder
    const data: (any) = {
      pickup_country: formData.pickup_country,
      consignee_country: formData.consignee_country,
      consignee_state: formData.consignee_state,
      order_weight: formData.order_weight,
      order_height: formData.order_height,
      order_length: formData.order_length,
      order_width: formData.order_weight,
      pickup_state: formData.pickup_state,
      currency: singleOrderContext.singleOrder.pickup_country === 'Indonesia' && singleOrderContext.singleOrder.consignee_country === 'Indonesia' ? 'IDR' : countryCodeCurrencies[auth.user.billingCountry],
      service_type: 'Pickup',
      order_direction: "RETURNS",
      pickup_postal: formData.pickup_postal,
      consignee_postal: formData.consignee_postal,
      dropoff_vendor_name: '',
      addresses: [
        {
          request_id: "5156857e4ef7_pickup",
          address_type: "pickup",
          country: formData.pickup_country,
          state: formData.pickup_state,
          city: formData.pickup_city,
          postal_code: formData.pickup_postal,
          zone_type: "pricing"
        },
        {
          request_id: "68f621ca261a_consignee",
          address_type: "consignee",
          country: formData.consignee_country,
          state: formData.consignee_state,
          city: formData.consignee_city,
          postal_code: formData.consignee_postal,
          zone_type: "pricing"
        }
      ]
    }
    return fetch_b2c_price_estimates(auth.user.secret_key, data)
  },  {
    onSuccess: (values: B2CPriceEstimatesResponse) => {
      singleOrderContext.updateSingleOrderData(
        {
          PriceEstimates: [{
            amount: values.total,
            currency: values.currency,
            gst_amount: values.gst_amount,
            total_duties: values.total_duties,
            total_taxes: values.total_taxes,
            shipping_charge: values.shipping_charge,
            total: values.total,
            // pickup_charge: values.rate.pickup_charge
          }]
        }
      )
      singleOrderContext.updateErrorInPriceEstimate(false)
      props.setCurrentState(4);
    },
    onError: (error: any) => {
      singleOrderContext.updateSingleOrderData(
        {
          PriceEstimates: [{
            amount: 0,
            currency: '',
            gst_amount: 0,
            total_duties: 0,
            total_taxes: 0,
            shipping_charge: 0,
            total: 0,
            // pickup_charge: values.rate.pickup_charge
          }]
        }
      )
      singleOrderContext.updateErrorInPriceEstimate(true)
      props.setCurrentState(4);
    },
  })

  const errData = (errors: any) => {
    let errorsList: Array<any> = [errors];
    if(errors.hasOwnProperty("items")){
      const itemErrors = formatItemErrorsB2C(errors)
      // delete errors["items"];
      itemErrors.map((itemError:any) => {
        return errorsList.push(itemError);
      })
    }
    let errorList = <ul style={{textAlign: 'left'}}>
      {
        errorsList.map((error) =>
          Object.values(error).map((val:any, index: number) => {
            return <li key={index} style={{fontSize: '12px',color: '#4f4a4a', fontWeight: 600}}>{(Object.keys(error)[index]).replace(/_/g, ' ')}: {val} </li>
          })
        )
      }
    </ul>
    return message.error(
      <span>Error: Please correct the following details and try again. {errorList}</span>,
      10
    )
  }

  const onSubmit = (values: OrderProps, helpers: FormikHelpers<OrderProps>) => {
    singleOrderContext.updateSingleOrderData({
      order_weight: values.order_weight,
      order_length: values.order_length,
      order_width: values.order_width,
      order_height: values.order_height,
      payment_type: 'prepaid',
      items: values.items,
      tracking_no: values.custom_tn,
    })

    singleOrderContext.setErrors({
      ...emptyError
    })

    const handleError = (error: any) => {
      
      if(error.response) {
        singleOrderContext.setErrors(error.response.data)
        const combined = combineFieldsErrorsB2C(error.response.data)
        singleOrderContext.setErrors(combined);
  
        const parsedError = parseOrderContextError(combined)
        helpers.setErrors(parsedError)
  
        if(error.response.data['non_field_errors']){
          let errors = error.response.data['non_field_errors'].join(',');
          message.error(getText('Failed to create shipment, please try again:')  + errors, 10);
        }
        else{
          errData(error.response.data)
        }
      }
      else{
        message.error(
          <span>Failed to create shipment, please contact {contactCustomerService()}, {error.message} </span>,
          10
        );
      }
    }

    // singleOrderContext.UpdateSingleOrder
    validateOrder.mutate(null, {
      onSuccess: (response: any) => {
        if(auth.user.hide_price_estimation) {
          singleOrderContext.updateSingleOrderData(
            {
              PriceEstimates: [{
                amount: 0,
                currency: '',
                gst_amount: 0,
                total_duties: 0,
                total_taxes: 0,
                shipping_charge: 0,
                total: 0,
                // pickup_charge: values.rate.pickup_charge
              }]
            }
          )
          props.setCurrentState(4)
        } else {
          fetchPriceEstimatesInB2cSingleOrder.mutate()
        }
      },
      onError: handleError,
    })
  }

  if(props.current > 3){
    return (
      <div className='return-order-form-container'>               
        <Row>
          <Col span={24} className={'mb-10'}>
            <p style={{fontWeight: 'bold'}}>Item & Parcel Overview</p>
          </Col>                     
          <Col span={24}>
            <p style={{color: '#808080', marginBottom: '0px'}}>Item Details</p>
            {
              singleOrderContext.singleOrder.items.map((item: any, index: number) => {
                return <p key={index} style={{marginBottom: '10px'}}>{`${index+1}. ${item.item_desc}`}</p>
              })
            }
          </Col>  

          <Col span={24}>
            <p style={{color: '#808080', marginBottom: '0px'}}>Parcel Details</p>
            <p>{initialValues.order_weight} kg,{initialValues.order_length}x{initialValues.order_width}x{initialValues.order_height} cm</p>
          </Col>                    
        </Row>                
      </div>
    )
  }

  return (
    <div className='return-order-form-container ant-form-vertical'>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema(getText, auth.user.payment_type)}
        validateOnChange={true}
      >
        {formik => (
          <FormikForm>
            <Form.Item label={<strong>Item Details</strong>} style={{marginBottom: '-35px', marginLeft: '-3px'}} />
            {
              initialValues.items.length > 1 &&
              <Form.Item label={<span style={{color: '#b0a9a9'}}>Remove the item you don't wish to collect from the customer</span>} style={{marginBottom: '-25px', marginLeft: '-3px'}} />
            }
            <FieldArray name="items"
              render={arrayHelpers => (
                <>
                  {formik.values.items.map((item: any, index: number) => (
                    <div key={index} style={{position: 'relative'}}>
                      <Row>
                        <Col>
                        </Col>
                        <Col>
                          <F.Item label={<p style={{fontWeight: 400, marginTop: '5px'}}>{`Item ${index+1}`} <span style={{color: '#b0a9a9'}}> | To collect from customer</span></p>} className={"single-order-form-title"}></F.Item>
                        </Col>
                      </Row>
                      <ItemForm 
                        isDomestic={false} 
                        index={index} 
                        remove={() => arrayHelpers.remove(index)}
                      />
                      <Divider/>
                    </div>
                  ))}

                  <F.Item>
                    <Button className={'outline-btn'} onClick={() => arrayHelpers.push(empty_item)} disabled={((!formik.isValid && formik.errors.hasOwnProperty("items"))) ? true: false}>
                      Add another item
                    </Button>
                  </F.Item>

                  {(singleOrderContext.singleOrder.items.length > formik.values.items.length && singleOrderContext.singleOrder.forward_tracking_no) &&
                    <Row justify='start'>
                      <Col>Removed items by mistake? Click
                        <Button 
                          type='dashed'
                          onClick={() => formik.resetForm()}
                          style={{margin: '0px 5px', padding: '0px 10px', fontWeight: 'bold', color: '#050593', fontSize: '17px', boxShadow: '0px 2px 4px grey'}}
                        >
                          here
                        </Button>
                        to add items again
                      </Col>
                    </Row>
                  }
                </>
              )} />
            <Divider />

            <Form.Item label={<strong>Parcel Details</strong>} style={{marginBottom: '0px', marginLeft: '-3px'}} />
            <Row gutter={20}>
              <Col span={6}>
                <FormInput 
                  required
                  name="order_weight" 
                  label={'Total weight(in kg)'}
                  placeholder='Weight'
                />
              </Col>
              <Col span={6}>
                <FormInput 
                  required
                  name="order_length" 
                  label={'Length(in cm)'}
                  placeholder='length'
                />
              </Col>
              <Col span={6}>
                <FormInput 
                  required
                  name="order_width" 
                  label={'Width(in cm)'}
                  placeholder='Width'
                />
              </Col>
              <Col span={6}>
                <FormInput 
                  required
                  name="order_height" 
                  label={'Height(in cm)'}
                  placeholder='Height'
                />
              </Col>
            </Row>
            {isCustomTNPermission && 
              <Row gutter={14}>
                <Col span={12}>
                  <FormInput name="custom_tn" label={'Custom Tracking Number (optional)'} />
                </Col>      
              </Row>
            }

            <Row justify='end'>
              <Button htmlType='submit' type='primary' loading={validateOrder.isLoading || fetchPriceEstimatesInB2cSingleOrder.isLoading}>{'Save & Continue'}</Button>
            </Row>
          </FormikForm>
        )}    
      </Formik>
    </div>
  )
}

export const ItemDetailsFormTitle = (props: PropsType) => {
  const {current, setCurrentState} = props;
  return (
    <div className={"title-block"}>
      <p className={'step-title'}>What are we picking up?</p>
      {current>3 ? <p onClick={()=> setCurrentState(3)} className={"edit-text"}>{'Edit details'}</p>:''}
    </div>
  )
}