import {
  Card,
  Row,
  Col,
  Steps
} from 'antd'
import React, {useEffect, useState} from 'react';
import './styles/ShipmentPage.css';
import '../dashboard/styles/HomePage.css';
import {UploadOrderForm, UploadOrderFormTitle} from "./components/bulkOrder/UploadOrderForm";
import {PickUpDetailsForm, PickUpDetailsFormTitle} from "./components/bulkOrder/PickUpDetailsForm";
// import {PriceEstimateForm, PriceEstimateFormTitle} from "./components/bulkOrder/PriceEstimateForm";
// import {MakePaymentForm, MakePaymentFormTitle} from "./components/bulkOrder/MakePayment";
import {Link} from "react-router-dom";
import {CaretLeftOutlined} from '@ant-design/icons'
import {BulkOrderContextProvider} from "./services/bulkOrderContext";
import { useLanguage } from '../languages/Language';
import {CSVTemplateUpdateAlert} from "./components/bulkOrder/CSVTemplateUpdateAlert";
import { OrderSubmission, OrderSubmissionTitle } from './components/bulkOrder/OrderSubmission';

const {Step} = Steps;

const OrderForm = () => {
  const [current, setCurrent] = useState(0);
  const [progress, setProgress] = useState(0);
  const setCurrentState = (val: number) => {
    setCurrent(val);
    if(!val){
      setProgress(val);
    }
  };

  const steps = [
    {
      title: <UploadOrderFormTitle current={current} setCurrentState={setCurrentState} dataFilled={0}/>,
      content: <UploadOrderForm current={current} setCurrentState={setCurrentState} dataFilled={0}/>,
    },
    {
      title: <PickUpDetailsFormTitle current={current} setCurrentState={setCurrentState} dataFilled={0}/>,
      content: <PickUpDetailsForm current={current} setCurrentState={setCurrentState} dataFilled={0}/>,
    },
    {
      title: <OrderSubmissionTitle current={current} setCurrentState={setCurrentState} dataFilled={0}/>,
      content: <OrderSubmission current={current} setCurrentState={setCurrentState} dataFilled={0} orderType={'forward'}/>,
    },
    // {
    //   title: <MakePaymentFormTitle current={current} setCurrentState={setCurrentState}/>,
    //   content: <MakePaymentForm current={current} setCurrentState={setCurrentState}/>,
    // }
  ];
  useEffect(() => {
    if (current > progress) {
      setProgress(current);
    }
  }, [current, progress]);

  return (
    <Steps direction="vertical" current={current} className={"form-progress-bar"}>
      {steps.map((item, index) => (
        <Step key={index} title={item.title}
          description={(current >= index || progress >= index) ? item.content : ''}/>
      ))}
    </Steps>
  )
};


export const BulkOrder = () => {
  let { getText } = useLanguage();
  return (
    <Row align="middle" justify="center">
      <Col span={16} className={'order-form-container'}>
        <Link to={"/orders/create"}>
          <p className="go-back-btn"><CaretLeftOutlined/> {getText('Create shipment')} </p>
        </Link>
        <Card className={'form-card'}>
          <div style={{marginTop:-15, marginBottom:15}}>
            <CSVTemplateUpdateAlert/>
          </div>
          <BulkOrderContextProvider>
            <OrderForm/>
          </BulkOrderContextProvider>
        </Card>
      </Col>
    </Row>
  )
};
