import { useMutation, useQuery } from 'react-query';
import { instanceForb2c as apib2c } from '../commons/api';
// import { capitalizeEveryFirstLetterString } from '../commons/utils/utilizer';

const getOrder = async (trackingNumber: string) => {
  const response = await apib2c.get(`/order/finance-orders/?key=foobarbaz&tracking_nos=${trackingNumber}`);
  return response.data[0];
};

export const useGetOrder = (trackingNumber: string) => useQuery({
  queryKey: ['getOrder', trackingNumber],
  queryFn: () => getOrder(trackingNumber),
  enabled: !!trackingNumber
});

const sendOrder = async (data: any) => {
  const payload = {
    tracking_no: data.trackingNumber,
    client_selection: data.serviceType,
    selected_date: data.pickupDate
  };

  try {
    await apib2c.post('/order/ecms-return-order-feedback/', payload);
  } catch (error: any) {
    console.error(error?.response?.data);
    throw new Error('Something wrong with our server. Please try again later.')
  }
};

export const useSendOrder = () => useMutation(sendOrder);

interface MaskedDetails {
  language: string,
  masked_returns_pickup_contact_number: string
}
export const fetchMaskedTracking_no = async (tracking_no: string) => {
  let response = await apib2c.get<MaskedDetails>(`order/masked-returns-contact-number/?tracking_no=${tracking_no}`);
  return response.data
};

// Send OTP
export const sendOtpRequest = async (params: any) => {
  let response = await apib2c.post('order/send-returns-otp/',
    params
  );
  return response.data
};

// Verify OTP
export const verifyOtp = async (params: any) => {
  let response = await apib2c.post('auth/verify-otp/',
    params
  );
  return response.data
};