import moment from 'moment';
import { parseLocaleCurrency } from '../../commons/utils/currency';

export const parseAmount = (total: number, currency: string) => {
  return parseLocaleCurrency(total, currency);
};

export const parseInvoiceAmount = (total: number, type: string, currency: string) => {
  return type === 'CREDIT_NOTE' ? `-${parseLocaleCurrency(total, currency)}` : parseLocaleCurrency(total, currency);
};

export const parseCreditedAmountInvoice = (amountCredited: number, amountPaid: number, currency: string) => {
  return parseLocaleCurrency((amountCredited + amountPaid), currency);
}

export const parseCreditedAmountCreditNote = (total: number, remainingCredit: number, currency: string) => {
  return parseLocaleCurrency((total - remainingCredit), currency);
}

export const parseBalance = (amountDue: number, remainingCredit: number, type: string, currency: string) => {
  return type === 'CREDIT_NOTE' ? `(${parseLocaleCurrency(remainingCredit, currency)})` : parseLocaleCurrency(amountDue, currency);
};

export const parseOverdue = (dueDate: string) => {
  const todayDate = moment().utc().startOf('day');
  const dueDateM = moment(dueDate).utc().startOf('day');
  const overdue = todayDate.diff(dueDateM, 'days');
  return overdue;
};