import moment from "moment"
import {countryCodes, existingServices} from "../commons/utils/constants";
import {
  getBillingAddressData,
  getFormatedDate,
  getPickUpAddressData,
  getDropOffAddressData,
  getReturnsBillingAddressData,
  getDropOffWarehouseAddressData,
  getDropOffAddressDataForBulkOrder,
  getPickUpAddressDataForForward,
  getBillingAddressDataForForward,
  isPickupServiceEnabled
} from "../commons/utils/utilizer";
import * as Sentry from "@sentry/react";
import {FetchDestinationCountries, GetServiceDefinitionsDataFromb2c} from "./services/privateApi";

interface ErrorHelper {
  initialErrors: {}
  initialTouched: {}
  emptyError: {}
  hasError: boolean
}

type FunctionHelper = <T, S>(errors: T, fn: (errors: T) => S) => ErrorHelper

export const parseErrors: FunctionHelper = (errors, fn) => {
  const initialErrors = fn(errors);
  const initialTouched = {};
  let hasError = false;
  let emptyError = {};

  Object.keys(initialErrors).forEach((key) => {
    //@ts-ignore
    if(initialErrors[key]){
      //@ts-ignore
      initialTouched[key] = true;
      //@ts-ignore
      emptyError[key] = undefined;
      hasError = true
    }
  });

  return {
    initialErrors,
    initialTouched,
    hasError,
    emptyError
  }
};

// check if a given pickup date is no longer valid.
// Accepts string param in the YYYY-MM-DD format
export const pickupDateHasExpired = (pickupDate?: string | null) => {
  if(pickupDate){
    var now = moment();
    let pickUpDate = moment(pickupDate, 'YYYY-MM-DD')

    if(now.hour() > 7 && pickUpDate.isSame(now, 'days')){
      return true
    }

    return pickUpDate.isBefore(now, 'day')
  }
  return false
};

export const createLanesForFoward = (parsedJson:any,token:any) => {
  let getServicesOfLanes = (response:any) =>{
    if(response){
      let service_lane: any = [];
      response.forEach((lane: any) => {
        if(!service_lane.includes(lane.service_type)){
          if(lane.service_type === 'Dropoff' && lane.dropoff_id === undefined){
            return
          }
          service_lane.push(lane.service_type)
        }       
      });
      return service_lane
    }
    return ["Pickup"];
  }

  const getDestinationCountries = (services: any, consignee_country: any, pickup_country: any) => {
    if(services){
      return services?.filter((service: any) => {
        return service.origin_country === pickup_country && service.destination_country === consignee_country
      })
    }
  }

  const getDropOffLocation = (services: any,consignee_country: any,  pickup_country: any) => {
    if(services){
      return services?.filter((service: any) => {
        return service.origin_country === pickup_country && 
        service.destination_country === consignee_country && 
        service.service_type === 'Dropoff'
      })
    }
  }

  let billingAddrAsPickUpAddr:any={};
  let lanes: any={};
  let countries: any={}
  let serviceDefinition: any={}
  let dropOff_location: any={}
  let laneDropOff_location: any ={}
  parsedJson.forEach((obj:any) => {
    const fName = `${obj.pickup_country}-${obj.consignee_country}`;
    if(lanes.hasOwnProperty(fName)){
      lanes[fName].count=lanes[fName].count+1;
    }else {
      billingAddrAsPickUpAddr[fName]=true;
      lanes[fName]={origin: obj.pickup_country, destination: obj.consignee_country,count: 1}
      // serviceDefinition = getServiceDefinitions_from_b2c(token)
      serviceDefinition = GetServiceDefinitionsDataFromb2c(token);
      countries = getDestinationCountries(serviceDefinition?.data, obj.consignee_country, obj.pickup_country)
      dropOff_location = getDropOffLocation(serviceDefinition?.data, obj.consignee_country, obj.pickup_country)
      let serviceTypes = getServicesOfLanes(countries)
      laneDropOff_location[fName] = dropOff_location
      lanes[fName].services = serviceTypes;
    }
  });
  return({
    lanes: Object.values((lanes)),
    billingAddrAsPickUpAddr: billingAddrAsPickUpAddr,
    getDropOffLocations: laneDropOff_location
  })
    
};

export const getServiceId = (origin: string, destination: string, values: any, serviceDefinition: any) => {
  let serviceList;
  if(values.service_type === 'Dropoff' || values.service_type === 'Customs clearance and last mile'){
    serviceList = serviceDefinition?.data?.filter((service: any) => {
      return service.origin_country === origin && 
      service.destination_country === destination &&
      service.service_type === values.service_type
    }).filter((id: any) => id.dropoff_id === values.dropoff_vendor_point_id)

    if(serviceList.length > 1) {
      let exclusiveLane = serviceList?.filter((item: any) => item.exclusive_agents?.length! > 0)
      return exclusiveLane
    } else {
      return serviceList
    }
  }else{
    serviceList = serviceDefinition?.data?.filter((service: any) => {
      return service.origin_country === origin && 
      service.destination_country === destination &&
      service.service_type === values.service_type
    })

    if(serviceList.length > 1) {
      let exclusiveLane = serviceList?.filter((item: any) => item.exclusive_agents?.length! > 0)
      return exclusiveLane
    } else {
      return serviceList
    }
  }
}

export const createLanesForReturns = (parsedJson:any,token:any, order_direction?:string) => {
  let getServicesOfLanes = (response:any,destination:string) =>{
    if(response){
      return response?.filter((countryObj:any) => {
        return destination === countryObj.destination_country_code
      })[0].service_type
    }
    return ["PICKUP"];
  }

  const getDestinationCountries = (services: any, consignee_country: any, pickup_country: any) => {
    if(services){
      return services?.filter((service: any) => {
        return service.origin_country === pickup_country && service.destination_country === consignee_country
      })
    }
  }

  let billingAddrAsPickUpAddr:any={};
  let lanes: any={};
  let serviceDefinition: any={}
  let countries: any={}
  parsedJson.forEach((obj:any) => {
    const fName = `${obj.pickup_country}-${obj.consignee_country}`;
    if(lanes.hasOwnProperty(fName)){
      lanes[fName].count=lanes[fName].count+1;
    }else {
      billingAddrAsPickUpAddr[fName]=true;
      lanes[fName]={origin: obj.pickup_country, destination: obj.consignee_country,count: 1}
      serviceDefinition = GetServiceDefinitionsDataFromb2c(token);
      countries = getDestinationCountries(serviceDefinition?.data, obj.consignee_country, obj.pickup_country)
      let serviceTypes = getServicesOfLanes(countries?.data,countryCodes[obj.consignee_country])
      lanes[fName].services = serviceTypes;
    }
  });
  return({
    lanes: Object.values((lanes)),
    billingAddrAsPickUpAddr: billingAddrAsPickUpAddr})
};

export const createLanes = (parsedJson:any,token:any, order_direction?:string) => {
  let getServicesOfLanes = (response:any,destination:string) =>{
    if(response){
      return response?.filter((countryObj:any) => {
        return destination === countryObj.destination_country_code
      })[0].service_type
    }
    return ["Pickup"];
  }

  let billingAddrAsPickUpAddr:any={};
  let lanes: any={};
  let countries: any={}
  parsedJson.forEach((obj:any) => {
    const fName = `${obj.pickup_country}-${obj.consignee_country}`;
    if(lanes.hasOwnProperty(fName)){
      lanes[fName].count=lanes[fName].count+1;
    }else {
      billingAddrAsPickUpAddr[fName]=true;
      lanes[fName]={origin: obj.pickup_country, destination: obj.consignee_country,count: 1}
      countries = FetchDestinationCountries(token,countryCodes[obj.pickup_country], order_direction === 'RETURNS');
      let serviceTypes = getServicesOfLanes(countries?.data,countryCodes[obj.consignee_country])
      lanes[fName].services = serviceTypes;
    }
  });
  return({
    lanes: Object.values((lanes)),
    billingAddrAsPickUpAddr: billingAddrAsPickUpAddr})
};

export const assignAddressForBulk = (parsedJson: any, pickupDetails:any, locations:any,pudos:any, warehouses: any) => {
  const finalResult: any[] = [];
  for (let obj of parsedJson) {
    const fieldName = `${obj.pickup_country}-${obj.consignee_country}`;
    let pickup_data:any={};
    let billing_data:any={};
    if(isPickupServiceEnabled(pickupDetails[fieldName].service_type) || !existingServices.includes(pickupDetails[fieldName].service_type)){
      pickup_data = getPickUpAddressDataForForward(pickupDetails[fieldName].pickup_address_id, locations);
      const billingAddressId = pickupDetails[fieldName].billing_address_id ? pickupDetails[fieldName].billing_address_id: pickupDetails[fieldName].pickup_address_id;
      billing_data = getBillingAddressDataForForward(billingAddressId, locations);
      pickup_data.pickup_date = getFormatedDate(pickupDetails[fieldName].pickup_date);
    }
    else if(pickupDetails[fieldName].service_type==='Dropoff_WH' || pickupDetails[fieldName].service_type==='Dropoff Express'  || pickupDetails[fieldName].service_type==='Customs clearance and last mile' || pickupDetails[fieldName].service_type==='Dropoff Express Priority'){
      let dropOffData = getDropOffWarehouseAddressData(pickupDetails[fieldName].dropoff_vendor_point_id, warehouses);
      pickup_data.pickup_location = undefined;
      pickup_data.pickup_contact_name = dropOffData.name;
      pickup_data.pickup_contact_number = dropOffData.contact_number;
      pickup_data.pickup_address = dropOffData.address;
      pickup_data.pickup_state = dropOffData.dropOff_state;
      pickup_data.pickup_city = dropOffData.dropOff_city;
      pickup_data.pickup_postal = dropOffData.dropOff_postal;
      pickup_data.pickup_country_code = countryCodes[obj.pickup_country];
      billing_data.billing_location = undefined;
      billing_data.billing_contact_name = dropOffData.name;
      billing_data.billing_contact_number = dropOffData.contact_number;
      billing_data.billing_address = dropOffData.address;
      billing_data.billing_state = dropOffData.dropOff_state;
      billing_data.billing_city = dropOffData.dropOff_city;
      billing_data.billing_postal = dropOffData.dropOff_postal;
      billing_data.billing_country_code = countryCodes[obj.pickup_country];
      pickup_data.dropoff_vendor_point_id = pickupDetails[fieldName].dropoff_vendor_point_id;
      pickup_data.dropoff_vendor_name = dropOffData.vendor_name;
    }
    else if(pickupDetails[fieldName].service_type==='Dropoff'){
      let dropOffData = getDropOffAddressDataForBulkOrder(pickupDetails[fieldName].dropoff_vendor_point_id,pudos[fieldName]);
      pickup_data.pickup_location = undefined;
      pickup_data.pickup_contact_name = dropOffData.name;
      pickup_data.pickup_contact_number = dropOffData.contact_number;
      pickup_data.pickup_address = dropOffData.address;
      pickup_data.pickup_state = dropOffData.dropOff_state;
      pickup_data.pickup_city = dropOffData.dropOff_city;
      pickup_data.pickup_postal = dropOffData.dropOff_postal;
      pickup_data.pickup_country_code = countryCodes[obj.pickup_country];
      billing_data.billing_location = undefined;
      billing_data.billing_contact_name = dropOffData.name;
      billing_data.billing_contact_number = dropOffData.contact_number;
      billing_data.billing_address = dropOffData.address;
      billing_data.billing_state = dropOffData.dropOff_state;
      billing_data.billing_city = dropOffData.dropOff_city;
      billing_data.billing_postal = dropOffData.dropOff_postal;
      billing_data.billing_country_code = countryCodes[obj.pickup_country];
      pickup_data.dropoff_vendor_point_id = pickupDetails[fieldName].dropoff_vendor_point_id;
      pickup_data.dropoff_vendor_name = dropOffData.vendor_name;
    }
    pickup_data.service_type = pickupDetails[fieldName].service_type
    pickup_data.service_id = pickupDetails[fieldName].service_id;
    const orderObject = { ...obj, ...pickup_data, ...billing_data};
    finalResult.push(orderObject);
  }
  return(finalResult)
};

export const assignAddress = (parsedJson: any, pickupDetails:any, locations:any,pudos:any, warehouses: any) => {
  const finalResult: any[] = [];
  for (let obj of parsedJson) {
    const fieldName = `${obj.pickup_country}-${obj.consignee_country}`;
    let pickup_data:any={};
    let billing_data:any={};
    if(pickupDetails[fieldName].service_type==='Pickup' || pickupDetails[fieldName].service_type==='Pickup_Express'){
      pickup_data = getPickUpAddressData(pickupDetails[fieldName].pickup_address_id, locations);
      const billingAddressId = pickupDetails[fieldName].billing_address_id ? pickupDetails[fieldName].billing_address_id: pickupDetails[fieldName].pickup_address_id;
      billing_data = getBillingAddressData(billingAddressId, locations);
      pickup_data.pickup_date = getFormatedDate(pickupDetails[fieldName].pickup_date);
    }
    else if(pickupDetails[fieldName].service_type==='Dropoff_WH' || pickupDetails[fieldName].service_type==='Dropoff_Express'  || pickupDetails[fieldName].service_type==='CC_LM'){
      let dropOffData = getDropOffWarehouseAddressData(pickupDetails[fieldName].dropoff_vendor_point_id, warehouses);
      pickup_data.pickup_location = undefined;
      pickup_data.pickup_contact_name = dropOffData.name;
      pickup_data.pickup_contact_number = dropOffData.contact_number;
      pickup_data.pickup_address = dropOffData.address;
      pickup_data.pickup_state = dropOffData.dropOff_state;
      pickup_data.pickup_city = dropOffData.dropOff_city;
      pickup_data.pickup_postal = dropOffData.dropOff_postal;
      pickup_data.pickup_country_code = countryCodes[obj.pickup_country];
      billing_data.billing_location = undefined;
      billing_data.billing_contact_name = dropOffData.name;
      billing_data.billing_contact_number = dropOffData.contact_number;
      billing_data.billing_address = dropOffData.address;
      billing_data.billing_state = dropOffData.dropOff_state;
      billing_data.billing_city = dropOffData.dropOff_city;
      billing_data.billing_postal = dropOffData.dropOff_postal;
      billing_data.billing_country_code = countryCodes[obj.pickup_country];
      pickup_data.dropoff_vendor_point_id = pickupDetails[fieldName].dropoff_vendor_point_id;
      pickup_data.dropoff_vendor_name = dropOffData.vendor_name;
    }
    else if(pickupDetails[fieldName].service_type==='Dropoff'){
      let dropOffData = getDropOffAddressData(pickupDetails[fieldName].dropoff_vendor_point_id,pudos);
      pickup_data.pickup_location = undefined;
      pickup_data.pickup_contact_name = dropOffData.name;
      pickup_data.pickup_contact_number = dropOffData.contact_number;
      pickup_data.pickup_address = dropOffData.address;
      pickup_data.pickup_state = dropOffData.dropOff_state;
      pickup_data.pickup_city = dropOffData.dropOff_city;
      pickup_data.pickup_postal = dropOffData.dropOff_postal;
      pickup_data.pickup_country_code = countryCodes[obj.pickup_country];
      billing_data.billing_location = undefined;
      billing_data.billing_contact_name = dropOffData.name;
      billing_data.billing_contact_number = dropOffData.contact_number;
      billing_data.billing_address = dropOffData.address;
      billing_data.billing_state = dropOffData.dropOff_state;
      billing_data.billing_city = dropOffData.dropOff_city;
      billing_data.billing_postal = dropOffData.dropOff_postal;
      billing_data.billing_country_code = countryCodes[obj.pickup_country];
      pickup_data.dropoff_vendor_point_id = pickupDetails[fieldName].dropoff_vendor_point_id;
      pickup_data.dropoff_vendor_name = dropOffData.vendor_name;
    }
    pickup_data.service_type = pickupDetails[fieldName].service_type
    const orderObject = { ...obj, ...pickup_data, ...billing_data};
    finalResult.push(orderObject);
  }
  return(finalResult)
};

export const assignReturnAddress = (parsedJson: any, receiverDetails:any, locations:any) => {
  const finalResult: any[] = [];
  for (let obj of parsedJson) {
    const fieldName = `${obj.pickup_country}-${obj.consignee_country}`;
    let billing_data:any={};
    if(receiverDetails[fieldName].service_type==='Pickup'){
      billing_data = getReturnsBillingAddressData(receiverDetails[fieldName])
    }
    const orderObject = { ...obj, ...receiverDetails[fieldName], ...billing_data};
    finalResult.push(orderObject);
  }
  return(finalResult)
};

export const disabledDate = (current:any) => {
  const invalidDates =  moment().isBefore(moment({ hour:8, minute: 0 })) ? moment().add(-1, 'days') : moment();
  const disableWeekends = moment(current).day() === 0 || moment(current).day() === 6
  const disabledDatesAfter14Days = moment().add(14, 'days') <= current;

  // Can not select days before today and today if it is already 8AM also no selection of weekends (saturday & sunday)
  return (current && current <  invalidDates) || (disableWeekends || disabledDatesAfter14Days);
};

export const disabledDateForB2B = (current:any) => {
  const invalidDates =  moment().isBefore(moment({ hour:8, minute: 0 })) ? moment().add(-1, 'days') : moment();
  const disableWeekends = moment(current).day() === 0 || moment(current).day() === 6
  // const disabledDatesAfter14Days = moment().add(14, 'days') <= current;

  // Can not select days before today and today if it is already 8AM also no selection of weekends (saturday & sunday)
  return (current && current <  invalidDates) || disableWeekends;
};

export const disabledDateForReturns = (current:any) => {
  const invalidDates =  moment().isBefore(moment({ hour:8, minute: 0 })) ? moment().add(-1, 'days') : moment();
  const disableWeekends = moment(current).day() === 0 || moment(current).day() === 6
  const disabledDatesAfter14Days = moment().add(14, 'days') <= current;
  const disabledNext2Dates = moment().add(2, 'days')  >= current;

  // Can not select days before today and today if it is already 8AM also no selection of weekends (saturday & sunday)
  return (current && current <  invalidDates) || (disableWeekends || disabledDatesAfter14Days || disabledNext2Dates);
};

export const parseValidationErrors = (data:any, orders:any) =>{
  let errorList:any={};
  Object.values(data).map((key: any) => {
    if(!errorList.hasOwnProperty(key.error)){
      errorList[key]={};
    }
    const content = data[key];
    Object.keys(content).map((innerKey) => {
      if(errorList[key].hasOwnProperty(orders[innerKey].tracking_no)){
        errorList[key][orders[innerKey].tracking_no] = errorList[key][orders[innerKey].tracking_no].concat(JSON.stringify(content[innerKey],null,''));
      }
      else{
        errorList[key][orders[innerKey].tracking_no] = JSON.stringify(content[innerKey], null, '');
      }
      return innerKey;
    });
    return key;
  });
  return errorList;
};

export const getUpdateBulkOrderPayload = (data : any) =>{
  const payload = {orders:[]};
  payload.orders = data.map((order:any) => {
    return {
      batch_no: order.batch_no,
      consignee_name: order.consignee_name,
      consignee_address: order.consignee_address,
      consignee_country_code: countryCodes[order.consignee_country],
      consignee_state: order.consignee_state,
      consignee_city: order.consignee_city,
      consignee_number: order.consignee_number,
      consignee_postal: order.consignee_postal,
      consignee_email: order.consignee_email,
      pickup_date: order.pickup_date,
      pickup_contact_name: order.pickup_contact_name,
      pickup_contact_number: order.pickup_contact_number,
      pickup_address: order.pickup_address,
      pickup_state: order.pickup_state,
      pickup_city: order.pickup_city,
      pickup_province: order.pickup_province,
      pickup_postal: order.pickup_postal,
      pickup_country_code: order.pickup_country_code,
      pickup_location: order.pickup_location,
      billing_location: order.billing_location,
      billing_contact_name: order.billing_contact_name,
      billing_contact_number: order.billing_contact_number,
      billing_address: order.billing_address,
      billing_state: order.billing_state,
      billing_city: order.billing_city,
      billing_province: order.billing_province,
      billing_postal: order.billing_postal,
      billing_country_code: order.billing_country_code,
      dropoff_vendor_point_id: order.dropoff_vendor_point_id,
      dropoff_vendor_name: order.dropoff_vendor_name,
      service_type: order.service_type,
      order_length: order.order_length,
      order_width: order.order_width,
      order_height: order.order_height,
      order_weight: order.order_weight,
      payment_type: order.payment_type,
      incoterm: order.incoterm,
      cod_amount_to_collect: order.cod_amount_to_collect,
      tracking_no: order.tracking_no,
      id: order.id,
      items: order.items,
      pickup_charge: order.account_charges?.length > 0 ? parseFloat(order.account_charges[0]?.amount) : 0
    }

  });
  return payload;
};
export const reportToSentry = (errorMessage:string, errorObject:any) =>Sentry.withScope(scope => {
  if(errorObject){
    for (const key in errorObject) {
      if(errorObject.hasOwnProperty(key))
        scope.setTag(key, errorObject[key])
    }
  }
  scope.setLevel(Sentry.Severity.Error);
  Sentry.captureMessage(errorMessage);
  scope.clear();
});

export const canChoosePickupDate = (countryCode: string) => {
  let pickUpDateExemptedCountryCodes = ['MY','TH']
  return pickUpDateExemptedCountryCodes.includes(countryCode)
}

export const PickupDays: any = {
  'MY': '1-3',
  'TH': '1-3'
}

export const returnDummyConsigneeCountryMapping = (consignee_country_code: string) => {
  let dataObject: any = {}
  if(consignee_country_code === 'SG') {
    dataObject['consignee_name'] = "Janio Asia"
    dataObject['consignee_number'] = "91234567"
    dataObject['consignee_address'] = "Janio Asia"
    dataObject['consignee_state'] = "Singapore"
    dataObject['consignee_postal'] = "1234"
    dataObject['consignee_city'] = "singapore"
  }
  if(consignee_country_code === 'AU') {
    dataObject['consignee_name'] = "Janio Asia"
    dataObject['consignee_number'] = "91234567"
    dataObject['consignee_address'] = "Janio Asia"
    dataObject['consignee_state'] = "Western Australia"
    dataObject['consignee_postal'] = "0852"
    dataObject['consignee_city'] = "metro manali"
  }
  if(consignee_country_code === 'ID') {
    dataObject['consignee_name'] = "Janio Asia"
    dataObject['consignee_number'] = "91234567"
    dataObject['consignee_address'] = "Janio Asia"
    dataObject['consignee_state'] = "Aceh"
    dataObject['consignee_postal'] = "23111"
    dataObject['consignee_city'] = "metro manali"
  }
  if(consignee_country_code === 'TH') {
    dataObject['consignee_name'] = "Janio Asia"
    dataObject['consignee_number'] = "91234567"
    dataObject['consignee_address'] = "Janio Asia"
    dataObject['consignee_state'] = "Bangkok"
    dataObject['consignee_postal'] = "10200"
    dataObject['consignee_city'] = "metro manali"
  }
  if(consignee_country_code === 'MY') {
    dataObject['consignee_name'] = "Janio Asia"
    dataObject['consignee_number'] = "91234567"
    dataObject['consignee_address'] = "Janio Asia"
    dataObject['consignee_state'] = "Johor"
    dataObject['consignee_postal'] = "80200"
    dataObject['consignee_city'] = "metro manali"
  }
  if(consignee_country_code === 'VN') {
    dataObject['consignee_name'] = "Janio Asia"
    dataObject['consignee_number'] = "91234567"
    dataObject['consignee_address'] = "Janio Asia"
    dataObject['consignee_state'] = "Hanoi"
    dataObject['consignee_postal'] = "03000"
    dataObject['consignee_city'] = "metro manali"
  }
  if(consignee_country_code === 'US') {
    dataObject['consignee_name'] = "Janio Asia"
    dataObject['consignee_number'] = "9123456"
    dataObject['consignee_address'] = "Janio Asia"
    dataObject['consignee_state'] = "California"
    dataObject['consignee_postal'] = "00000"
    dataObject['consignee_city'] = "metro manali"
  }
  if(consignee_country_code === 'PH') {
    dataObject['consignee_name'] = "Janio Asia"
    dataObject['consignee_number'] = "91234567"
    dataObject['consignee_address'] = "Janio Asia"
    dataObject['consignee_state'] = "Abra"
    dataObject['consignee_postal'] = "2817"
    dataObject['consignee_city'] = "metro manali"
  }
  if(consignee_country_code === 'TW') {
    dataObject['consignee_name'] = "Janio Asia"
    dataObject['consignee_number'] = "91234567"
    dataObject['consignee_address'] = "Janio Asia"
    dataObject['consignee_state'] = "Yilan City"
    dataObject['consignee_postal'] = "26044"
    dataObject['consignee_city'] = "metro manali"
  }
  if(consignee_country_code === 'HK') {
    dataObject['consignee_name'] = "Janio Asia"
    dataObject['consignee_number'] = "91234567"
    dataObject['consignee_address'] = "Janio Asia"
    dataObject['consignee_state'] = "Hong Kong Island"
    dataObject['consignee_postal'] = "999077"
    dataObject['consignee_city'] = "metro manali"
  }

  return dataObject
}

export const errorExtract = (objectOfArray: Object) => {
  return Object.values(objectOfArray).map(arr => arr).map(ar => ar)
}