import { useState, useContext, createContext, ReactNode } from "react"
import { instanceForb2c as apib2c } from '../commons/api'

interface B2BOrderInterface {
  origin_country: string,
  origin_type: string,
  origin_address: string
  destination_country: string,
  destination_type: string,
  destination_address: string,
  calculation_type: string,
  package_type: string,
  pallet_type: string,
  boxes_type: string,
  no_of_units: string | number,
  dimension_length: string | number,
  dimension_width: string | number,
  dimension_height: string | number,
  dimension_weight: string | number,
  units: string | number,
  total_units_for_calculate_by_total_shipments: string | number
  total_volume: string | number,
  total_weight: string | number,
  goods_value: string | number,
  goods_ready_date: string,
  hazardous_good: boolean,
  manifest_type_id: string,
  manifest_type_name: string,
  commercial_invoice_id: string,
  commercial_invoice_name: string,
  lift_gate?: {
    pickup_liftgate: boolean,
    delivery_liftgate: boolean
  },
  customs_brokerage?: {
    record_exporter: boolean,
    record_importer: boolean
  },
  insurance?: {
    shipment_insurance: boolean
  },
  others?: {
    dangerous_goods_handling: boolean,
    delivered_at_place: boolean,
    delivered_duty_paid: boolean,
    high_value_clearance: boolean
  },
  estimated_price_per_kg?: number | string
  estimated_rate?: number | string
}

interface B2BOrderContextInterface {
  b2bOrderData: B2BOrderInterface
  updateB2BOrderData: (values: any) => any
  submitB2BOrderData: (token: string) => Promise<any>
}

const b2bOrderContext = createContext<B2BOrderContextInterface>({} as B2BOrderContextInterface);

export const useB2BOrderContext = () =>{
  return useContext(b2bOrderContext);
};

const useB2BContextProvider = (data?: B2BOrderInterface) => {
  const [b2bOrderData, setB2bOrderData] = useState<B2BOrderInterface>(() =>{
    return {
      origin_country: "",
      origin_type: "",
      origin_address: "",
      destination_country: "",
      destination_type: "",
      destination_address: "",
      calculation_type: "",
      package_type: "",
      pallet_type: "",
      boxes_type: "",
      no_of_units: "",
      dimension_length: "",
      dimension_width: "",
      dimension_height: "",
      dimension_weight: "",
      total_units_for_calculate_by_total_shipments: "",
      units: "",
      total_volume: "",
      total_weight: "",
      goods_value: "",
      goods_ready_date: "",
      hazardous_good: false,
      manifest_type_id: "",
      manifest_type_name: "",
      commercial_invoice_id: "",
      commercial_invoice_name: "",
      lift_gate: {
        pickup_liftgate: false,
        delivery_liftgate: false
      },
      customs_brokerage: {
        record_exporter: false,
        record_importer: false
      },
      insurance: {
        shipment_insurance: false
      },
      others: {
        dangerous_goods_handling: false,
        delivered_at_place: false,
        delivered_duty_paid: false,
        high_value_clearance: false
      },
      estimated_price_per_kg: "",
      estimated_rate: "",
    }
  });

  const updateB2BOrderData = (values: any) => {
    setB2bOrderData((current: any) => ({...current, ...values}));
    return b2bOrderData
  };

  const submitB2BOrderData = async (token: string) => {
    let response = await apib2c.post('orders/orders/',
      {
        ...b2bOrderData
      },
      {
        headers: {
          'Authorization': `Token ${token}`
        }});
    return response.data
  };
  
  return {
    b2bOrderData,
    updateB2BOrderData,
    submitB2BOrderData
  }
};

export const B2BOrderContextProvider = ({children, order}: {children: ReactNode, order?: B2BOrderInterface}) => {
  const orderData = useB2BContextProvider(order);

  return (
    <b2bOrderContext.Provider value={orderData}>
      {children}
    </b2bOrderContext.Provider>
  )
};