import { Form, Divider, notification } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons'
import { useAuth } from '../../../auth/services/authContext';
import '../../styles/SingleOrderForm.css';
import {useSingleOrderB2C} from "../../services/singleOrderContext";
import { pickupDateHasExpired } from '../../helpers';
import { SelectedPaymentMethod } from '../PayForOrders/SelectPaymentMethod';
import { useLanguage } from '../../../languages/Language';
import { Link } from 'react-router-dom';
import {PickupChargeAlert} from '../../../commons/components/PickupFeeAlerts/PickupChargeAlert';

interface PropsType {
  current: number,
  setCurrentState: (val:number)=> void,
  isEditing?:boolean
  isMarketplaceOrder?: boolean
}

const openNotificationWithIcon = (type:any, getText: any) => {
  (notification as any)[type]({
    message: getText('DISCLAIMER'),
    description: getText('Shipping charges are subject to change based on weight and/or D&T of items if different from declared info'),
    className: 'disclaimer-area',
    style: {
      width: 400,
    },
    duration: 7,
  });
}

export const PriceEstimateForm = (props:PropsType) =>{
  let { getText } = useLanguage();
  const auth = useAuth();
  const {current} = props;
  const singleOrderContext = useSingleOrderB2C();
  // const authToken = auth.user.authToken;

  // Check and set whether the pickup fee exists in order response
  // let pickup_charge = () => {
  //   if (singleOrderContext.singleOrder.account_charges && singleOrderContext.singleOrder.account_charges!.length > 0) {
  //     return parseFloat(singleOrderContext.singleOrder.account_charges![0].amount)
  //   }
  //   if(singleOrderContext.singleOrder.pickup_charge) {
  //     return parseFloat(singleOrderContext.singleOrder.pickup_charge)
  //   }
  //   return null
  // }

  let orderData:any = {
    amount: singleOrderContext.singleOrder?.PriceEstimates![0].total ? singleOrderContext.singleOrder?.PriceEstimates![0].total : '0',
    total: singleOrderContext.singleOrder?.PriceEstimates![0].total ? singleOrderContext.singleOrder?.PriceEstimates![0].total : '0',
    currency: singleOrderContext.singleOrder?.PriceEstimates![0].currency,
    // id: singleOrderContext.singleOrder.id!,
    duties: singleOrderContext.singleOrder?.PriceEstimates![0].total_duties ? singleOrderContext.singleOrder?.PriceEstimates![0].total_duties: 0,
    taxes: singleOrderContext.singleOrder?.PriceEstimates![0].total_taxes ? singleOrderContext.singleOrder?.PriceEstimates![0].total_taxes : 0,
    gst_amount: singleOrderContext.singleOrder?.PriceEstimates![0].gst_amount ? singleOrderContext.singleOrder?.PriceEstimates![0].gst_amount : 0,
    amountToPay: singleOrderContext.singleOrder?.PriceEstimates![0].total ? singleOrderContext.singleOrder?.PriceEstimates![0].total : 0,
    // charge_ids: singleOrderContext.singleOrder.charge_id?[singleOrderContext.singleOrder.charge_id]:[],
    // pickup_charge: pickup_charge(),
    // is_tax_payable: singleOrderContext.singleOrder.is_tax_payable
  }
  // let orderCharges:any = [{order_id:singleOrderContext.singleOrder.id,amount:singleOrderContext.singleOrder.amount_to_pay}]

  if(current !== 3) return null

  const orderIsStillValid = () => {
    // currently only checks for pickup date
    // should add more in the future, and consider more messages
    if(pickupDateHasExpired(singleOrderContext.singleOrder.pickup_date)){
      return false
    }

    return true
  }

  const renderPriceBreakUp = () => {
    let taxes = parseFloat(orderData.duties) + parseFloat(orderData.taxes) + parseFloat(orderData.gst_amount)
    let shipping_charges = (parseFloat(orderData.total) - taxes)
    // taxes = orderData.is_tax_payable?taxes:0;
    return (
      <>
        <div className="title-block price-breakup">
          <span>{getText('Shipping & Handling')}: </span>
          <span>{`${orderData!.currency} ${shipping_charges.toFixed(2)}`}</span>
        </div>
        {/* <div className="title-block price-breakup">
          <span style={{color: '#050593'}}>{getText('GST/VAT/D&T')}: </span>
          <span>{`${orderData!.currency} ${taxes.toFixed(2)}`}</span>
        </div> */}

        {orderData.pickup_charge! > 0 && 
          <>
            <div className="title-block price-breakup">
              <span>{'Pickup Attempt Fee*' } <span className='inclusive-text'>(Inclusive of Taxes)</span><Link to="/support/pickup_fee" target="_blank" rel="noopener noreferrer" >  What is this?</Link>
              </span>
              <span>{`${orderData!.currency} ${orderData.pickup_charge?.toFixed(2)}`}</span>
            </div>
          </>
        }
      </>
    )
  }
  // // Calculate total amount if pickup charge exists
  // let amountToPay = orderData.pickup_charge! ? (orderData.amountToPay + orderData.pickup_charge) : orderData.amountToPay
  // // Deduct D&T if order is DDU
  // amountToPay = orderData.is_tax_payable?amountToPay:(parseFloat(orderData.amount) + orderData!.pickup_charge || 0)

  
  return (
    // (singleOrderContext.singleOrder.payment_status !== 'PAID' && singleOrderContext.singleOrder.payment_status !== 'MONTHLY_BILLING_PAYMENT_PENDING') && singleOrderContext.singleOrder.amount_to_pay > 0 ?
    <Form
      layout={'vertical'}
      requiredMark={false}
    >
      <div className="single-order-form-container price-form">
        {(!auth.user.hide_price_estimation && !singleOrderContext.errorInPriceEstimate) && 
          <div>
            <Form.Item label={<label className="info-disclaimer">{getText('Total Estimated Cost')}:<InfoCircleOutlined  onClick={() => openNotificationWithIcon('info', getText)}/></label>} className={"single-order-form-title"}>
            </Form.Item>
            <div className="title-block price-breakup">
              <span>{getText('Total Items')}: </span>
              <span>{singleOrderContext.singleOrder.items.length}</span>
            </div>

            {renderPriceBreakUp()}

            {orderData.pickup_charge!>0 &&(
              <>
                <p style={{marginTop: '20px'}}>*Choose service type as Drop off instead to waive off the pickup fee</p>
                <PickupChargeAlert/>
              </>
            )
            }

            <Divider style={{margin: '15px 0'}} />
            <div className="title-block price-breakup" style={{marginBottom: '10px'}}>
              <strong>{getText('Total Charges')}: </strong>
              <strong>{`${orderData!.currency} ${(orderData.amountToPay).toFixed(2)}`}</strong>
            </div>

            <Divider style={{margin: '15px 0'}} />

          </div>
        }
        <SelectedPaymentMethod 
          orderData={orderData}
          orderIsStillValid={orderIsStillValid()}
          orderType={'SINGLE'}
          context={singleOrderContext.singleOrder}
          orderDirection={'FORWARD'}
        />
        
      </div>
    </Form> 
    // :
    // <div className="single-order-form-container">
    //   <div className={"title-block"}>
    //     <div>{getText('Order Already Paid')}</div>
    //   </div>
    // </div>
  )
};
export const PriceEstimateFormTitle = (props:PropsType) =>{
  const auth = useAuth();
  let { getText } = useLanguage();
  const {current, setCurrentState} = props;
  return (
    <div className={"title-block"}>
      <p className={'step-title'}>{auth.user.hide_price_estimation ? 'Create Order' : getText('Price estimate')}</p>
      {current>3 ? <p onClick={()=> setCurrentState(3)} className={"edit-text"}>{getText('Edit details')}</p>:''}
    </div>
  )
};