import {createContext, ReactNode, useContext, useState} from "react"

interface ReceiverFormContext {
    form: any
}

interface EstimatesFormContext {
  tableData: {}
  selectedOrders: {}
  overallEstimates: {shipmentsCount:0, estimatedPrice:0.00}
}

interface BulkOrder {
  file_name?: string,
  orders: any
}
interface PriceContext {
  batch_no: any;
  total: any
  currency:string
}

interface BulkOrderContext {
    bulkOrder: BulkOrder
    setBulkOrder: (values: any) => any
    parsedJson: {
      file_name: string,
      consolidatedOrderItems: []
    }
    setParsedJson: (values:{}) => any
    receiverFormContext: ReceiverFormContext
    setReceiverFormContext: ( value: ReceiverFormContext) => any
    estimatesFormContext: EstimatesFormContext
    setEstimatesFormContext: (values: EstimatesFormContext) => any
    canEditOrder: boolean
    setCanEditOrder: (values:boolean) => any
    priceContext: PriceContext[] | null
    setPriceContext: (values: PriceContext[] | null) => any}

const returnBulkOrderContext = createContext<BulkOrderContext>({} as BulkOrderContext);

export const BulkOrderContextProvider = ({children, orders, renderOnlyEstimates}: { children: ReactNode, orders?:any, renderOnlyEstimates?: boolean }) => {
  let serverData = () =>{
    if(orders){
      return {
        renderOnlyEstimates: renderOnlyEstimates, 
        data: orders.data
      }
    }
  }
  const useReturnBulkOrder = useReturnBulkOrderContextProvider(serverData());
  return (
    <returnBulkOrderContext.Provider value={useReturnBulkOrder}>
      {children}
    </returnBulkOrderContext.Provider>
  )
};

export const useReturnBulkOrder = () => {
  return useContext(returnBulkOrderContext);
};

const useReturnBulkOrderContextProvider = (serverData?:any) => {
  const [bulkOrder, setBulkOrder] = useState<BulkOrder>(() => {
    if(serverData) {
      return {
        orders: serverData.data.map((item:any) => {
          return item.order
        })
      }
    }
    return {
      orders: null,
      rates: null
    }
  });
  const [canEditOrder, setCanEditOrder] = useState(false);

  const [parsedJson, setParsedJson] = useState(() => {
    if(serverData) {
      return serverData.data.map((item:any) => {
        return item.order
      })
    }
    return {}
  });

  const [receiverFormContext, setReceiverFormContext] = useState<ReceiverFormContext>(() => {
    if(serverData){
      return serverData.data.map((item:any) => {
        return {
          consignee_country: item.order.consignee_country,
          consignee_address: item.order.consignee_address,
          consignee_city: item.order.consignee_city,
          consignee_name: item.order.consignee_name,
          consignee_number: item.order.consignee_number,
          consignee_postal: item.order.consignee_postal,
          consignee_state: item.order.consignee_state,
          consignee_email: item.order.consignee_email,
        }
      })
    }
    return {}
  })

  const [estimatesFormContext, setEstimatesFormContext] = useState<EstimatesFormContext>(() => {
    return {
      tableData: {},
      selectedOrders: {},
      overallEstimates: {shipmentsCount:0, estimatedPrice:0.00}
    }
  });

  const [priceContext, setPriceContext] = useState<PriceContext[] | null>(() => {
    if(serverData){
      return serverData.data.map((rate: any) => {
        if (serverData.renderOnlyEstimates) {
          return {
            id: rate.order.id, 
            batch_no: rate.order.batch_no, 
            total: rate.price.total, 
            currency: rate.price.currency,
            gst_amount: rate.price.gst_amount,
            total_duties: rate.price.total_duties,
            is_tax_payable: rate.price.is_tax_payable,
            total_taxes: rate.price.total_taxes,
            charge_id: rate.price.charge_id,
            pickup_charge: rate.price.pickup_charge ? parseFloat(rate.price.pickup_charge) : 0
          }
        }
        return {
          id: rate.order.id, 
          batch_no: rate.order.batch_no, 
          total: rate.rate.total, 
          currency: rate.rate.currency,
          gst_amount: rate.rate.gst_amount,
          total_duties: rate.rate.total_duties,
          is_tax_payable: rate.rate.is_tax_payable,
          total_taxes: rate.rate.total_taxes,
          charge_id: rate.rate.charge_id,
          pickup_charge: rate.account_charges.length > 0 ? parseFloat(rate.account_charges[0].amount) : 0
        }
      });
    }
    return null
  });

  return {
    bulkOrder,
    setBulkOrder,
    parsedJson,
    setParsedJson,
    receiverFormContext,
    setReceiverFormContext,
    estimatesFormContext,
    setEstimatesFormContext,
    canEditOrder,
    setCanEditOrder,
    priceContext,
    setPriceContext
  }
};