import {useEffect, useState} from 'react';
import {Row, Col, Card, Image, Button, Divider, message, Spin, Popconfirm, Table, Empty} from 'antd'
import {useAuth} from "../auth/services/authContext";
import {useMutation, useQuery } from "react-query";
import { paymentMethod, getCardData, deleteCardData, getCardDataBills } from "../orders/services/privateApi";
import { useLanguage } from '../languages/Language';
import no_card from "../assets/images/no_card.png";
import './postpaid.css'
import { stripePayment } from '../orders/services/stripePayment'
import {formatDate} from '../commons/utils/utilizer'

interface WholeTableData {
  type: string,
  status: string,
  amount: string,
  currency: string,
  created_at: string,
  updated_at: string,
  transaction_id: string 
}
interface CardProps {
  cardID: number | null,
  cardName: string | null
}
interface StripeResponse {
  gateway_payload: {
    session_id: string
  }
}

export interface CardInterface {
  gateway_payment_method_id: string,
  id: number
  name: string
}
interface StripeRedirectionCallBack {
  success: string, 
  cancel: string
}
export const AddCardBtn = (props: StripeRedirectionCallBack) => {
  let {success, cancel} = props
  let { getText } = useLanguage();
  const auth = useAuth();
  
  const saveCard = useMutation(async (token:string) => {
    // Call your backend to create the Checkout Session
    let request = {
      "success_path": success,
      "cancel_path": cancel
    }
    return paymentMethod(token, request);
  },
  {
    onSuccess: async (val: StripeResponse) => {
      stripePayment(val)
    },
    onError: (error:any) => {
      message.error({
        content: `Unable to process your request at this moment. Please contact Customer Service. ${error.message}`,
        duration: 5,
        className: 'top-up-error'
      })
    },
  }
  );
  const saveCardHandler = () => {
    saveCard.mutate(auth.user.authToken);
  }

  return (
    <Button 
      type="primary"
      onClick={saveCardHandler}
      loading={saveCard.isLoading}
    >
      {getText('Add a Payment Card')}
    </Button>
  )
}

const PaymentMethodBills = (props: any) => {
  let {cardID, cardName} = props.cardData
  const auth = useAuth();
  let { getText } = useLanguage();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const getCardBills = useQuery(['getCardBills', cardID, limit, offset], async () => {
    return getCardDataBills(cardID, auth.user.authToken, {
      "limit": limit,
      "offset": offset
    })
  }, {enabled : cardID !== null});

  useEffect(() => {
    setOffset(0)
  }, [cardID])

  const columns = [
    {
      title: getText('Type'),
      dataIndex: 'type',
      key: 'type',
      render: (type: string) => {
        return (
          <span>{type}</span>
        )
      }
    },
    {
      title: getText('Created On'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at:any) => {
        return (
          <span>
            {formatDate(created_at,"en-GB")}
          </span>
        )
      }
    },
    {
      title: getText('Status'),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: getText('Transaction ID'),
      dataIndex: 'transaction_id',
      key: 'transaction_id',
    },
    {
      title: getText('Updated on'),
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (updated_at:any) => {
        return (
          <span>
            {formatDate(updated_at, "en-GB")}
          </span>
        )
      }
    },
    {
      title: getText('Amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (amount:any, wholeData:WholeTableData) => {
        if(wholeData.type === 'DEBIT') {
          return (
            <span style={{color: 'red'}}>
              - {wholeData.currency} {amount === 0 ? '0.00' : amount}
            </span>
          );
        } else {
          return (
            <span style={{color: '#73cf46'}}>
              + {wholeData.currency} {amount === 0 ? '0.00' : amount}
            </span>
          );
        }
      }
    },
  ];

  const onSwitchPage = (page:any, pageSize: any) => {
    setLimit(pageSize)
    setOffset((page - 1) * pageSize)
  }

  const showCardBills = () => {
    if(getCardBills.isLoading) {
      return <div className="data-loading"><Spin tip="Fetching Records..." /></div>
    }
    if(getCardBills.data && getCardBills.data?.results.length > 0) {
      return (
        <Table
          rowClassName='table_body'
          rowKey="id"
          dataSource={getCardBills.data && getCardBills.data?.results}
          columns={columns}
          loading={getCardBills.isLoading}
          pagination={{
            onChange: onSwitchPage,
            position:['topRight'],
            total: getCardBills.data ? getCardBills.data.count : 0,
            pageSize: limit,
          }}
        />
      )
    } else {
      return <Empty />
    }
  }

  if(cardID) {
    return (
      <Col span={24} className="transaction_container" style={{marginTop: '25px'}}>
        <Card style={{minHeight: '200px'}}>
          <p>{getText('Transaction History')} for {cardName} <span> ({getCardBills.data?.results ? getCardBills.data.count : 0} {getText('transactions')})</span></p>
          {showCardBills()}
        </Card>
      </Col>
    )
  }
  return null
}

export const CardDetails = () => {
  let { getText } = useLanguage();
  const auth = useAuth();

  const [cardData, setCardData ] = useState<CardProps>({
    cardID: null,
    cardName: null
  })
  const getCardDetails = useQuery('getCardDetails', async () => {
    return getCardData(auth.user.authToken)
  });
  
  const deleteCard = useMutation(async (id: number) => {
    return deleteCardData(id, auth.user.authToken);
  },  {
    onSuccess: (val) => {
      message.success('Card deleted successfully');
      getCardDetails.refetch()
    },
    onError: (error: any) => {
      let errorResponse = error.response.data;
      message.error(`${errorResponse.error}. Failed to delete the card.`);
    },
  });

  if(deleteCard.isLoading) {
    return <Spin className="center-align-spin" />
  }

  const confirm = (value: CardInterface) => {
    let card_id = value.id
    deleteCard.mutate(card_id);
  }
  
  const cancel = (e: any) => {
    return
  }

  const setCardDataHandler = (e: React.MouseEvent<HTMLElement>, cardID: number, cardName: string) => {
    e.preventDefault()
    setCardData({
      cardID: cardID,
      cardName: cardName
    })
  }

  if(getCardDetails.isLoading) {
    return <Spin className="center-align-spin" />
  }

  if(getCardDetails.data && getCardDetails.data!.payment_methods.length > 0) {
    return (
      <Row align="middle" justify="center">
        <Col span={16} className={'auth-form-container card-available'}>
          <Row>
            <Col span={16}>
              <h2><strong>{getText('Payment Methods')}</strong></h2>
            </Col>
            <Col span={8} style={{textAlign: 'right'}}>
              <AddCardBtn success={'/card_details?result=success'} cancel={'/card_details?result=cancel'} />
            </Col>
          </Row>
          
          <Card>
            <strong>{getText('Active Cards')} </strong>
            <Divider />
            {
              getCardDetails.data!.payment_methods.map((card: CardInterface) => {
                return (
                  <Row className={'card-details'} key={card.id}>
                    <Col span={18}>
                      <strong>{`${card.name}`}</strong> 
                    </Col>
                    
                    <Col span={6}>
                      <Row gutter={12}>
                        <Col span={12} className={'card-actions'}>
                          <a href="#" onClick={(e) => setCardDataHandler(e, card.id, card.name )} style={{color:'#050593'}}>{getText('View')}</a>
                        </Col>

                        <Col span={12} className={'card-actions'}>
                          <Popconfirm
                            title={<div className="delete-card">{getText('Are you sure to delete this card?')}</div>}
                            onConfirm={() => confirm(card)}
                            onCancel={cancel}
                            okText={getText('Confirm')}
                            cancelText={getText('Cancel')}
                            placement="left"
                          >
                            <a href="#">{getText('Delete')}</a>
                          </Popconfirm>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )
              })
            }
          </Card>
          
          {getCardDetails.data!.payment_methods && <PaymentMethodBills cardData={cardData} />}

        </Col>
        
      </Row>
      
    )
  }
  return (
    <Row align="middle" justify="center">
      <Col span={16} className={'auth-form-container postpaid-card-container'}>
        <Row>
          <Col span={18}>
            <h2><strong>Payment Method</strong></h2>
          </Col>
        </Row>
        
        <Card>
          <Image preview={false} src={no_card} style={{textAlign: 'center'}}/>
          <br /><br /><br />
          <strong>There is no active card here </strong>
          
          <br /><br />
          <AddCardBtn success={'/card_details?result=success'} cancel={'/card_details?result=cancel'} />
        </Card>
      </Col>
    </Row>
  )
}