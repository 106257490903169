import { Button, Card, Col, DatePicker, Divider, Row, Select, Spin, message, Image } from 'antd';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router";
import { useGetOrder, useSendOrder } from './services';
import janio_logo from "../assets/images/janio_logo.svg";
import { addBusinessDays } from '../commons/utils/addBussinessDays';

export const ClientConfirmation = () => {
  const history = useHistory();
  const location: { tracking_number: string } = useParams();
  const orders = useGetOrder(location.tracking_number);
  const sendOrder = useSendOrder();

  const [serviceType, setServiceType] = useState<string>('');
  const [pickupDate, setPickupDate] = useState<string>('');

  const disabledDate = (current: any) => {
    const startDate = current < moment('2024-01-24', 'YYYY-MM-DD');
    const endDate = current > moment('2024-02-03', 'YYYY-MM-DD');
    const disableToday = current < addBusinessDays(moment().format('YYYY-MM-DD'), 3).endOf('day');
    const disableWeekends = moment(current).day() === 0 || moment(current).day() === 6;

    // Can select from 24th Jan till 2nd Feb 2024, except current date + 3, past dates and weekends (saturday & sunday)
    return (current && disableToday) || (startDate || endDate || disableWeekends);
  };

  const onServiceType = (value: string) => {
    setServiceType(value);
  };

  const onChange = (date: any, dateString: string) => {
    setPickupDate(dateString);
  };

  const onSubmit = () => {
    sendOrder.mutate({
      trackingNumber: orders.data.tracking_no,
      serviceType: serviceType,
      ...(pickupDate && serviceType !== 'DROP_OFF' && serviceType !== 'NOT_REQ' && { pickupDate: pickupDate + 'T09:00:00' })
    });
  };

  useEffect(() => {
    if (sendOrder.isLoading || sendOrder.isIdle) {
      return;
    }

    if (sendOrder.isError) {
      message.error('Something wrong with our server. Please try again later', 5);
      sendOrder.reset();
      return;
    }

    if (sendOrder.isSuccess) {
      sendOrder.reset();
      history.replace('/rts/success');
    }
  }, [sendOrder]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Spin spinning={orders.isLoading}>
      <Row align="middle" justify="center" style={{ paddingTop: '30px', paddingBottom: '30px' }} >
        <Col span={12} className="auth-form-container">
          <div className="logo" style={{textAlign: 'center', marginTop: '30px'}}>
            <a href="#">
              <Image preview={false} src={janio_logo} className={"site-logo"}/>
            </a>
          </div>
          {orders.isSuccess &&
            <Card style={{ minWidth: '380px', borderTop: 'none' }}>
              <h3 style={{ color: 'rgb(5, 5, 147)', fontSize: '16px', fontWeight: 'bold', textAlign: 'center', marginTop: '-20px', marginBottom: '20px' }}>
                Janio Return Order Confirmation
              </h3>

              <Col span={24}>
                <p>Hi {orders.data.attribute_data.attr2},
                  <br /> <br />
                  Greetings from Janio Asia (on behalf of ECMS). Regarding the return of your Amazon parcel,
                  tracking number {orders.data.tracking_no} , we would like to know if you still wish to proceed with the return.
                  <br /> <br />
                  If yes, please indicate your preferred date for the parcel pickup. You could also choose to drop-off
                  your parcel at our main office (Mon-Fri 9am-6pm).
                </p>
              </Col>

              <Divider />

              <Row gutter={[24, 16]}>
                {orders.data.items && orders.data.items.length && orders.data.items.map((item: any, index: number) => (
                  <Col key={index} span={24}>
                    <p style={{ margin: 0, display: 'inline', float: 'left', width: '90%' }}>{item.item_desc}</p>
                    <p style={{ margin: 0, display: 'inline', float: 'right' }}>x{item.item_quantity}</p>
                  </Col>
                ))}
              </Row>

              <Divider />

              <Row>
                <Col span={24}><p>Please select from the 3 options below.</p></Col>
                <Col span={24}>
                  <Select
                    value={serviceType}
                    onChange={onServiceType}
                    style={{ minWidth: '100%' }}
                  >
                    <Select.Option value="PICK_UP">Pickup from my address</Select.Option>
                    <Select.Option value="DROP_OFF">Dropoff at Janio Office</Select.Option>
                    <Select.Option value="NOT_REQ">Do not require service</Select.Option>
                  </Select>
                </Col>
              </Row>

              {serviceType &&
                <Fragment>

                  <Divider />

                  <Row style={{ width: '100%' }}>
                    {serviceType === "PICK_UP" &&
                      <Fragment>
                        <Col span={24}>
                          <p>
                            Address:
                            <br /> <br />
                            {orders.data.origin_address}
                            <br /> <br />
                            If a change of address is needed, please email to help@janio.asia.
                          </p>
                        </Col>

                        <Col span={24}>
                          <p>
                            Please select a desired pickup date.
                            (Optional. If unselected, you will be scheduled for the next available pickup)
                          </p>

                          <DatePicker
                            style={{ width: '100%' }}
                            disabledDate={disabledDate}
                            onChange={onChange}
                            showToday={false}
                          />
                        </Col>
                      </Fragment>
                    }

                    {serviceType === "DROP_OFF" &&
                      <Col>
                        <p>
                          Address: <br /> <br />

                          111 North Bridge Road <br />
                          #20-02, Peninsula Plaza <br />
                          Singapore 179098 <br /> <br />

                          Please drop the parcel off during the following timing <br /> <br />

                          Weekdays (Excluding PH) : 0900 to 1800
                        </p>
                      </Col>
                    }

                    {serviceType === "NOT_REQ" &&
                      <Col>
                        <p>
                          By selecting this option,
                          you are confirming that you do not require any service from
                          Janio for this tracking number ({orders.data.tracking_no}).
                        </p>
                      </Col>
                    }
                  </Row>

                  <Divider />

                  <Row style={{ width: '100%' }}>
                    <Button type="primary" style={{ width: '100%' }} loading={sendOrder.isLoading} onClick={onSubmit}>Submit</Button>
                  </Row>
                </Fragment>
              }
            </Card>
          }

          {orders.isError &&
            <Card style={{ minWidth: '380px' }}>
              <h3 style={{ color: 'rgb(5, 5, 147)', fontSize: '16px', fontWeight: 'bold', textAlign: 'center', marginTop: '-20px', marginBottom: '20px' }}>
                Janio Return Order Confirmation
              </h3>

              <Col span={24}>
                <p>Failed to fetch the order details</p>
              </Col>
            </Card>
          }

        </Col>
      </Row>
    </Spin>
  )
};
