import React from 'react'
import {Button} from 'antd'
import { Link } from 'react-router-dom'

const MultiClaimSelectedHeader = (props: any) => {
  return (
    <>
      {
        props.selectedOrders.length>0 && <div className='main-selected-header-container'>
          <div className='selected-content'>
            <div className='selected-length'>
              {props.selectedOrders.length} {'Shipments selected'}
            </div>
            <div style={{color: '#050593', background: '#fff', marginLeft: '35px', fontWeight: 600, minWidth: '125px', textAlign: 'center'}}>
              <Button className='claim-button-selected' style={{color: '#050593', fontWeight: 'bold', border: 'none'}}>
                <Link to={{pathname: '/claims/multi', state: {selectedOrders: props.selectedOrders}}}>{'Claim'}</Link>
              </Button>
            </div>
          </div>
          <div className='arrow-down' style={{marginLeft:'10px'}}></div>
        </div>
      }
    </>
  )
}

export default MultiClaimSelectedHeader