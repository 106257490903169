import {
  Card,
  Row,
  Col,
  Button
} from 'antd'
import { Link } from 'react-router-dom';
import '../../wallet/styles/wallet.css'
// @ts-ignore

export const EscalateSuccess = () =>  {

  return (
    <>
      <Row gutter={24}>
        <Col span={18} className="success_container wallet_container" style={{marginTop: '60px'}}>
          <Card>
            <div className="check_mark">
              <main></main>
            </div>

            <p>{"Escalated Successfully"}</p>
            <Col span="24">
              <Row gutter={12} justify='center'>
                <Col span={12} >
                  <Link to={`/claims?status=claim_tracking`}  id="success_container">
                    <Button style={{color: '#fff', height: '35px'}} type={'default'}>{'Escalate another order'}</Button>
                  </Link>
                </Col>
              </Row>
            </Col>

          </Card>
        </Col>
      </Row>
    </>
  )
};
