import { useState, useContext, createContext, ReactNode } from "react"
import {instance as api} from "../../commons/api";
import { instanceForb2c as apib2c } from '../../commons/api'
import { countryCallingCodes, countryCodes } from "../../commons/utils/constants";
export interface TrackingNumberSuccess {
  pickup_details: {
    within_coverage: boolean,
    pickup_errors: {
      pickup_state: string[],
      pickup_city: string[]
    },
    pickup_contact_name: string,
    pickup_contact_email: string,
    pickup_contact_number: string,
    pickup_address: string,
    pickup_country: string,
    pickup_state: string,
    pickup_city: string,
    pickup_postal: string
  },
  consignee_details: {
    return_point_name: string | null,
    consignee_name: string,
    consignee_email: string | null,
    consignee_number: string,
    consignee_address: string,
    consignee_country: string,
    consignee_state: string,
    consignee_city: string,
    consignee_postal: string
  },
  order_details: {
    shipper_order_id?: string,
    order_weight: number | string,
    order_height: number | string,
    order_width: number | string,
    order_length: number | string,
    items: ItemB2C
  }
}

interface ReturnSingleOrderContextB2C {
  singleOrder: SingleOrderB2C
  updateSingleOrderData: (values: any) => any
  submitSingleOrder: (token: string) => Promise<any>
  UpdateSingleOrder: (token: string) => Promise<any>
  CheckOrderPaymentStatus: (token: string, orderId: number) => Promise<any>
  setErrors: (error: OrderErrorB2C) => any
  errors: OrderErrorB2C
  updateErrorInPriceEstimate: (values: boolean) => any
  errorInPriceEstimate: boolean
}
export interface AccountChargesForB2c {
  total: number,
  currency: string,
  weight_upper_bound: string,
  chargable_weight: string,
  total_taxes: number,
  total_duties: number,
  gst_amount: number,
  shipping_charge: string,
  duties_and_taxes: number
}
interface SingleOrderB2C {
  pickup_country: string,
  within_coverage: boolean,
  pickup_contact_name: string,
  pickup_contact_email: string,
  pickup_contact_number: string,
  pickup_address: string,
  pickup_state: string,
  pickup_city: string,
  pickup_postal: string,
  pickup_date: string | null
  service: string,
  firstmile_type: string,
  service_type: string,
  dropoffObject: {},
  is_label_less_order: boolean,
  order_weight: string,
  order_height: string,
  order_width: string,
  order_length: string,
  items: ItemB2C[],
  consignee_country: string,
  consignee_address: string,
  consignee_state: string,
  consignee_city: string,
  consignee_postal: string,
  delivery_notes: string,
  addressValue: string,
  consignee_name: string,
  consignee_identifcation_number?: string,
  identification_document_name?: string,
  consignee_email: string,
  consignee_number: string,
  forward_tracking_no: string,
  tracking_no: string,
  PriceEstimates?: AccountChargesForB2c[]
}

export interface ItemB2C {
  item_desc: string,
  item_category: string,
  item_quantity: string,
  item_product_id: string,
  item_sku: string,
  item_price_currency: string,
  item_price_value: string,
}
interface ItemErrorB2C {
  item_desc?: string,
  item_category?: string,
  item_quantity?: string,
  item_product_id?: string,
  item_sku?: string,
  item_price_currency?: string,
  item_price_value?: string,
}
interface ItemServerErrorB2C {
  item_desc?: Array<string>
  item_category?: Array<string>
  item_quantity?: Array<string>
  item_price_currency?: Array<string>
  item_price_value?: Array<string>
  item_product_id?: Array<string>
  item_sku?: Array<string>
}
export interface OrderErrorB2C {
  pickup_country?: string,
  within_coverage?: boolean,
  pickup_contact_name?: string,
  pickup_contact_email?: string,
  pickup_contact_number?: string,
  pickup_address?: string,
  pickup_state?: string,
  pickup_city?: string,
  pickup_postal?: string,
  pickup_date?: string
  service?: string,
  firstmile_type?: string,
  service_type?: string,
  dropoffObject?: {},
  is_label_less_order?: boolean,
  order_weight?: string,
  order_height?: string,
  order_width?: string,
  order_length?: string,
  items?: Array<ItemErrorB2C>,
  consignee_country?: string,
  consignee_address?: string,
  consignee_state?: string,
  consignee_city?: string,
  consignee_postal?: string,
  delivery_notes?: string,
  addressValue?: string,
  consignee_name?: string,
  consignee_identifcation_number?: string,
  identification_document_name?: string,
  consignee_email?: string,
  consignee_number?: string,
  forward_tracking_no?: string
  tracking_no?: string
}
interface ServerErrorsB2C {
  pickup_country?: Array<string>,
  within_coverage?: Array<string>,
  pickup_contact_name?: Array<string>,
  pickup_contact_email?: Array<string>,
  pickup_contact_number?: Array<string>,
  pickup_address?: Array<string>,
  pickup_state?: Array<string>,
  pickup_city?: Array<string>,
  pickup_postal?: Array<string>,
  pickup_date?: Array<string>,
  service?: Array<string>,
  firstmile_type?: Array<string>,
  service_type?: Array<string>,
  dropoffObject?: Array<string>,
  is_label_less_order?: Array<string>,
  order_weight?: Array<string>,
  order_height?: Array<string>,
  order_width?: Array<string>,
  order_length?: Array<string>,
  items: {[key: number] : ItemServerErrorB2C},
  consignee_country?: Array<string>,
  consignee_address?: Array<string>,
  consignee_state?: Array<string>,
  consignee_city?: Array<string>,
  consignee_postal?: Array<string>,
  delivery_notes?: Array<string>,
  addressValue?: Array<string>,
  consignee_name?: Array<string>,
  consignee_email?: Array<string>,
  consignee_number?: Array<string>,
  consignee_identifcation_number?: Array<string>,
  identification_document_name?: Array<string>,
  forward_tracking_no?: Array<string>,
  tracking_no?: Array<string>
}

export const combineFieldsErrorsB2C = (serverErrors: ServerErrorsB2C) => {
  let errors: OrderErrorB2C = {}
  if(serverErrors.items){
    errors.items = []
    let itemErrors = formatItemErrorsB2C(serverErrors)
    Object.keys(itemErrors).forEach((key: any) => {
    // @ts-ignore
      errors.items[key] = itemErrors[key]
    })
  }
  if(serverErrors.tracking_no){
    errors.tracking_no = serverErrors.tracking_no.join(',');
  }
  if(serverErrors.consignee_name){
    errors.consignee_name = serverErrors.consignee_name.join(',');
  }
  if(serverErrors.consignee_address){
    errors.consignee_address = serverErrors.consignee_address.join(',');
  }
  if(serverErrors.consignee_country){
    errors.consignee_country = serverErrors.consignee_country.join(',');
  }
  if(serverErrors.consignee_state){
    errors.consignee_state = serverErrors.consignee_state.join(',');
  }
  if(serverErrors.consignee_city){
    errors.consignee_city = serverErrors.consignee_city.join(',');
  }
  if(serverErrors.consignee_number){
    errors.consignee_number = serverErrors.consignee_number.join(',');
  }
  if(serverErrors.consignee_postal){
    errors.consignee_postal = serverErrors.consignee_postal.join(',');
  }
  if(serverErrors.consignee_email){
    errors.consignee_email = serverErrors.consignee_email.join(',');
  }
  if(serverErrors.pickup_contact_name){
    errors.pickup_contact_name = serverErrors.pickup_contact_name.join(',');
  }
  if(serverErrors.pickup_contact_number){
    errors.pickup_contact_number = serverErrors.pickup_contact_number.join(',');
  }
  if(serverErrors.pickup_address){
    errors.pickup_address = serverErrors.pickup_address.join(',');
  }
  if(serverErrors.pickup_state){
    errors.pickup_state = serverErrors.pickup_state.join(',');
  }
  if(serverErrors.pickup_city){
    errors.pickup_city = serverErrors.pickup_city.join(',');
  }
  if(serverErrors.pickup_postal){
    errors.pickup_postal = serverErrors.pickup_postal.join(',');
  }
  if(serverErrors.pickup_country){
    errors.pickup_country = serverErrors.pickup_country.join(',');
  }
  if(serverErrors.pickup_date){
    errors.pickup_date = serverErrors.pickup_date.join(',');
  }
  if(serverErrors.service_type){
    errors.service_type = serverErrors.service_type.join(',');
  }
  if(serverErrors.order_length){
    errors.order_length = serverErrors.order_length.join(',');
  }
  if(serverErrors.order_width){
    errors.order_width = serverErrors.order_width.join(',');
  }
  if(serverErrors.order_height){
    errors.order_height = serverErrors.order_height.join(',');
  }
  if(serverErrors.order_weight){
    errors.order_weight = serverErrors.order_weight.join(',');
  }
  if(serverErrors.forward_tracking_no) {
    errors.forward_tracking_no = serverErrors.forward_tracking_no.join(',');
  }
  if(serverErrors.consignee_identifcation_number){
    errors.consignee_identifcation_number = serverErrors.consignee_identifcation_number.join(',');
  }
  if(serverErrors.identification_document_name){
    errors.identification_document_name = serverErrors.identification_document_name.join(',');
  }

  return errors
}

export const formatItemErrorsB2C = (serverErrors: ServerErrorsB2C) => {
  let itemErrors : Array<ItemErrorB2C> = []
  if(serverErrors.items){
    Object.keys(serverErrors.items).forEach((key: any) => {
      let itemError: ItemErrorB2C = {}
      let val = serverErrors.items[key]
      if(val.item_desc){
        itemError.item_desc = val.item_desc.join(',');
      }
      if(val.item_category){
        itemError.item_category = val.item_category.join(',');
      }
      if(val.item_quantity){
        itemError.item_quantity = val.item_quantity.join(',');
      }
      if(val.item_price_currency){
        itemError.item_price_currency = val.item_price_currency.join(',');
      }
      if(val.item_price_value){
        itemError.item_price_value = val.item_price_value.join(',');
      }
      if(val.item_product_id){
        itemError.item_product_id = val.item_product_id.join(',');
      }
      if(val.item_sku){
        itemError.item_sku = val.item_sku.join(',');
      }
      itemErrors.push(itemError);
    })
  }
  return itemErrors;
}

const prependCountryCode = (country_code: string, phone_no: string) => {
  if(phone_no.startsWith('+')){
    return phone_no
  }
  return countryCallingCodes[country_code] + phone_no
}

const singleOrderContextB2C = createContext<ReturnSingleOrderContextB2C>({} as ReturnSingleOrderContextB2C);

export const SingleOrderContextProviderB2C = ({children, order}: {children: ReactNode, order?: SingleOrderB2C}) => {
  let serverData = () => {
    if (order) {
      return order
    }
  }
  const singleOrder = useSingleOrderContextProviderB2C(serverData());

  return (
    <singleOrderContextB2C.Provider value={singleOrder}>
      {children}
    </singleOrderContextB2C.Provider>
  )
};

export const useSingleOrderB2C = () =>{
  return useContext(singleOrderContextB2C);
};

const useSingleOrderContextProviderB2C = (serverData?: SingleOrderB2C) => {
  const [errorInPriceEstimate, setErrorInPriceEstimate] = useState<boolean>(false)
  const [singleOrder, setSingleOrder] = useState<SingleOrderB2C>(() =>{
    if(serverData && serverData !== undefined) {
      return serverData
    }

    return {
      pickup_country: '',
      within_coverage: true,
      pickup_contact_name: '',
      pickup_contact_email: '',
      pickup_contact_number: '',
      pickup_address: '',
      pickup_state: '',
      pickup_city: '',
      pickup_postal: '',
      pickup_date: null,
      service: '',
      firstmile_type: '',
      service_type: '',
      dropoffObject: {},
      is_label_less_order: true,
      order_weight: '',
      order_height: '',
      order_width: '',
      order_length: '',
      items: [{
        item_desc: '',
        item_category: '',
        item_quantity: '',
        item_price_currency: '',
        item_price_value: '',
        item_product_id: '',
        item_sku: '',
        item_product_url: ''
      }],
      consignee_country: '',
      consignee_address: '',
      consignee_state: '',
      consignee_city: '',
      consignee_postal: '',
      delivery_notes: '',
      addressValue: '',
      consignee_name: '',
      consignee_email: '',
      consignee_number: '',
      consignee_identifcation_number: '',
      identification_document_name: '',
      forward_tracking_no: '',
      tracking_no:'',
    }
  });
  const [errors, setErrors] = useState<OrderErrorB2C>({})

  const updateSingleOrderData = (values: any) => {
    setSingleOrder((current) => ({...current, ...values}));
    return singleOrder
  };

  const submitSingleOrder = async (secret_key: string) => {
    let response = await apib2c.post('order/return-orders/',
      {
        orders: [{
          ...singleOrder,
          consignee_number: prependCountryCode(countryCodes[singleOrder.consignee_country], singleOrder.consignee_number),
        }],
        secret_key: secret_key
      });
      
    return response.data
  };

  const UpdateSingleOrder = async (secretKey: string) => {
    let singleOrderPayload:any = singleOrder;
    singleOrderPayload.order_direction = 'RETURNS'
    let response = await apib2c.post(`order/return-order-validation/`,
      {
        ...singleOrderPayload,
        consignee_number: prependCountryCode(singleOrder.consignee_country, singleOrder.consignee_number),
        secret_key: secretKey
      });
    return response.data
  };

  const CheckOrderPaymentStatus = async (token: string, orderId: number) => {
    let singleOrderPayload:any = singleOrder;
    let response = await api.post(`orders/orders/${orderId}/calculate_amount_to_pay/`,
      {
        ...singleOrderPayload,
        consignee_number: prependCountryCode(singleOrder.consignee_country, singleOrder.consignee_number),
      },
      {
        headers: {
          'Authorization': `Token ${token}`
        }});

    return response.data
  };

  const updateErrorInPriceEstimate = (value: boolean) => {
    setErrorInPriceEstimate(value)
  };


  return {
    singleOrder,
    updateSingleOrderData,
    submitSingleOrder,
    UpdateSingleOrder,
    CheckOrderPaymentStatus,
    errors,
    setErrors,
    updateErrorInPriceEstimate,
    errorInPriceEstimate
  }
};

export const setAllFields = (values: TrackingNumberSuccess, trackingNumber: string) => {
  return {
    within_coverage: values.pickup_details.within_coverage,
    pickup_errors: {
      pickup_state: values.pickup_details.pickup_errors.pickup_state,
      pickup_city: values.pickup_details.pickup_errors.pickup_city
    },
    pickup_contact_name: values.pickup_details.pickup_contact_name,
    pickup_contact_email: values.pickup_details.pickup_contact_email,
    pickup_contact_number: values.pickup_details.pickup_contact_number,
    pickup_address: values.pickup_details.pickup_address,
    pickup_country: values.pickup_details.pickup_country,
    pickup_state: values.pickup_details.pickup_state,
    pickup_city: values.pickup_details.pickup_city,
    pickup_postal: values.pickup_details.pickup_postal,
    return_point_name: values.consignee_details.return_point_name,
    consignee_name: values.consignee_details.consignee_name,
    consignee_email: values.consignee_details.consignee_email,
    consignee_number: values.consignee_details.consignee_number,
    consignee_address: values.consignee_details.consignee_address,
    consignee_country: values.consignee_details.consignee_country,
    consignee_state: values.consignee_details.consignee_state,
    consignee_city: values.consignee_details.consignee_city,
    consignee_postal: values.consignee_details.consignee_postal,
    shipper_order_id: values.order_details.shipper_order_id,
    order_weight: values.order_details.order_weight,
    order_height: values.order_details.order_height,
    order_width: values.order_details.order_width,
    order_length: values.order_details.order_length,
    items: values.order_details.items,
    forward_tracking_no: trackingNumber
  }
}