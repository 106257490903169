import React, { useEffect, useState } from 'react'
import {Row, Col, Card, Steps} from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {CaretLeftOutlined} from '@ant-design/icons'
import {ClaimTypeSelectionTitle, ClaimsTypeSelection} from './Component/ClaimsTypeSelection';
import { ClaimFormTitle, ClaimsForm } from './Component/ClaimsForm';

const {Step} = Steps;
const SingleClaim = () => {
  const [current, setCurrent] = useState(0)
  const [progress, setProgress] = useState(0)
  
  const [claimType, setClaimType] = useState<string>('')
  const location = useLocation();
  const {tracking_no, order_id}: any = location.state

  const steps = [
    {
      title: <ClaimTypeSelectionTitle current={current} setCurrent={setCurrent}/>,
      content: <ClaimsTypeSelection 
        claimType={claimType} 
        setClaimType={setClaimType} current={current} 
        setCurrent={setCurrent} 
        tracking_no={tracking_no}/>
    },
    {
      title: <ClaimFormTitle/>,
      content: <ClaimsForm 
        claimType={claimType} 
        tracking_no={tracking_no} 
        order_id={order_id}/>
    }
  ]

  
  useEffect(() => {
    if (current > progress) {
      setProgress(current);
    }
  }, [current, progress]);

  return (
    <>
      <Steps direction='vertical' current={current}>
        {steps.map((item, index) =>( 
          <Step 
            key={index} 
            title={item.title} 
            description={(current >= index || progress >= index)? item.content: ''} /> ))}
      </Steps>
    </>
  )
}


const SingleClaimSubmission = () => {
  return (
    <Row align='middle' justify='center'>
      <Col span={24} className={'claim-container'}>
        <Link to={'/claims'}>
          <p><CaretLeftOutlined/> Claims</p>
        </Link>
        <Card className='claim-card'>
          <SingleClaim/>
        </Card>
      </Col>
    </Row>
  )
}

export default SingleClaimSubmission