import React from 'react'

const TermsAndCondition = () => {
  return (
    <p>According to section 5.3 <a href='https://ss.janio.asia/terms_conditions' target='_blank' rel='noreferrer'>Janio T&C</a>, the maximum claimable amount for a client who doesn’t purchase insurance is limited to the following amounts:1. Cross Border Shipments
    i. up to a maximum of USD One Hundred (USD 100.00); or
    ii. the Declared Value of an individual item in the Order, whichever is lower; or2. Domestic Shipments in the following countries, the lower of the following respective values shall apply
    a. Malaysia
    i. up to a maximum of MYR Two Hundred (MYR 200.00); or
    ii. the Declared Value of an individual item in the Order;b. Indonesia
    i. up to a maximum of IDR One Million (IDR 1,000,000.00);
    ii. the Declared Value of an individual item in the Order; or
    iii. ten times of the Freight Charges for an individual item in the Order;c. Singapore
    i. up to a maximum of USD Fifty (USD 50.00); or
    ii. the Declared Value of an individual item in the Order; and / ord. China and all other countries
    i. up to a maximum of USD Fifty (USD 50.00); or
    ii. the Declared Value of an individual item in the OrderShould a client be interested in extra coverage for lost or damaged claims, they can purchase Janio's ParcelShield insurance which covers all B2C domestic and cross-border damaged or lost parcels with an increased maximum coverage amount up to USD 7,500 per parcel. Clients may contact Janio’s Commercial representative for further information about ParcelShield insurance. Clients who purchase insurance shall adhere to the Insurance Payment Limits.</p>
  )
}

export default TermsAndCondition