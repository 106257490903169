import mixpanel from 'mixpanel-browser'

const isInDev = () => {
  return !window.location.hostname.includes('janio.asia')
  // return !window.location.hostname.includes('localhost')
}


if(!isInDev()){
  console.log('NOT DOING INIT')
  let isStaging = window.location.hostname.includes('staging.janio.asia')
  if(isStaging) {
    mixpanel.init('fc1dce3e41c03b39a9ec2aed07452099', {cross_subdomain_cookie: false});
  } else {
    mixpanel.init('eff8918820fd69cd6c69141910a0b619', {cross_subdomain_cookie: false});
  }

  mixpanel.clear_opt_in_out_tracking({
    options:{
      cookie_domain: 'ss.janio.asia ss.staging.janio.asia'
    }
  })
}

export const UserSignedUpNotVerified = (email, fullName) => {
  if(isInDev()) return
  mixpanel.identify(email)
  mixpanel.register_once({"$name": fullName, "$email": email });
  mixpanel.people.set({ "$name": fullName, "$email": email });
  return mixpanel.track('User Signed up, Verification is pending')
}

export const UserSignedUpAndVerified = () => {
  if(isInDev()) return
  // mixpanel.identify(email)
  // mixpanel.people.set({ "$name": fullName, "$email": email });
  return mixpanel.track('User Signed up, Verification is also done')
}

export const trackSignIn = (email, fullName) => {
  if(isInDev()) return
  mixpanel.identify(email)
  mixpanel.people.set_once({ "$name": fullName, "$email": email });
  return mixpanel.track('User Logged In')
}

export const trackSignInForRedirectedCLients = (email, fullName) => {
  if(isInDev()) return
  return mixpanel.identify(email)
}

export const TourPopupViewed = () => {
  if(isInDev()) return
  return mixpanel.track('Tour Pop Up Viewed')
}

export const Tip3Viewed = () => {
  if(isInDev()) return
  return mixpanel.track('Tip 3 Viewed')
}

export const Tip4Viewed = () => {
  if(isInDev()) return
  return mixpanel.track('Tip 4 Viewed')
}

export const Tip2Viewed = () => {
  if(isInDev()) return
  return mixpanel.track('Tip 2 Viewed')
}

export const Tip1Viewed = () => {
  if(isInDev()) return
  return mixpanel.track('Tip 1 Viewed')
}

export const PDFTourViewed = () => {
  if(isInDev()) return
  return mixpanel.track('PDF Tour Viewed')
}

export const VideoTourViewed = () => {
  if(isInDev()) return
  return mixpanel.track('Video Tour Viewed - Support Page')
}

export const SinglePickupVideoViewed = () => {
  if(isInDev()) return
  return mixpanel.track('Single Pickup Order Creation Video Viewed')
}

export const SingleDropOffVideoViewed = () => {
  if(isInDev()) return
  return mixpanel.track('Single Drop Off Order Creation Video Viewed')
}

export const BulkPickUpDropOffVideoViewed = () => {
  if(isInDev()) return
  return mixpanel.track('Bulk Pickup/Drop-off Order Creation Video Viewed')
}
export const UserViewedDashboard = () => {
  if(isInDev()) return
  return mixpanel.track('Dashboard Viewed')
}

export const UserViewedOnBoardTour = () => {
  if(isInDev()) return
  return mixpanel.track('On Board Tour Viewed')
}

export const UserViewedCreateShipment = (userName) => {
  if(isInDev()) return
  return mixpanel.track(`CreateShipment Viewed by ${userName}`)

}

export const UserViewedAnalytics = () => {
  if(isInDev()) return
  return mixpanel.track('User viewed analytics and dashboard page')
}

export const UserViewedManageShipment = (userName) => {
  if(isInDev()) return
  return mixpanel.track(`Manage Shipments Viewed by ${userName}`)
}

export const UserViewedB2BManageShipment = (userName) => {
  if(isInDev()) return
  return mixpanel.track(`B2B Manage Shipments Viewed by ${userName}`)
}

export const UserViewedViewShipmentDetailsPage = (userName) => {
  if(isInDev()) return
  return mixpanel.track(`View Shipment Details Viewed by ${userName}`)
}

export const UserViewedIntegrationShipments = () => {
  if(isInDev()) return
  return mixpanel.track('Integration Shipments Viewed')
}

export const UserViewedRateCalculator = () => {
  if(isInDev()) return
  return mixpanel.track('Rate Calculator Viewed')
}

export const UserViewedPickupCalendar = () => {
  if(isInDev()) return
  return mixpanel.track('Pickup Calendar Viewed')
}

export const UserViewedReports = (userName) => {
  if(isInDev()) return
  return mixpanel.track(`Reports Viewed by ${userName}`)
}

export const UserViewedClaims = () => {
  if(isInDev()) return
  return mixpanel.track("Claims Viewed")
}

export const UserViewedBillingInvoices = () => {
  if(isInDev()) return
  mixpanel.track('Billing & Invoices Viewed')
  UserViewedBillingInvoicesTabs('balance')
}

export const UserViewedIntegrations = () => {
  if(isInDev()) return
  return mixpanel.track('Integrations Viewed')
}

export const UserViewedShipmentRules = () => {
  if(isInDev()) return
  return mixpanel.track('Shipment Rules Viewed')
}

export const UserViewedWalletPage = () => {
  if(isInDev()) return
  return mixpanel.track('Wallet Page Viewed')
}

export const UserViewedChargeLater = () => {
  if(isInDev()) return
  return mixpanel.track('Charge Later Viewed')
}

export const UserViewedTermAndConditions = () => {
  if(isInDev()) return
  return mixpanel.track('T&Cs Viewed')
}

export const UserViewedSettings = () => {
  if(isInDev()) return
  return mixpanel.track('Settings Page Viewed')
}

export const UserViewedSupportPage = () => {
  if(isInDev()) return
 return mixpanel.track('Support Page Viewed')
}

export const UserViewedChangePassword = () => {
  if(isInDev()) return
  return mixpanel.track('Change Password Viewed')
}

export const UserWalletRecharged = () => {
  if(isInDev()) return
  return mixpanel.track('User Wallet Topped Up')
}

export const UserSelectedAnalyticsButton = (button) => {
  if(isInDev()) return
  return mixpanel.track(`User viewed ${button}'s analytics`)
}

export const UserSelectedAddIntegration = (card) => {
  if(isInDev()) return
  return mixpanel.track(`User selected ${card} integration`)
}

export const UserClicksOnReportShare = (reportType) => {
  if(isInDev()) return
  return mixpanel.track(`User clicks on ${reportType} button`)
}

export const UserSelectedAnalyticsGranularity = (chart, granularity) => {
  if(isInDev()) return
  return mixpanel.track(`User viewed ${chart}'s ${granularity} granularity`)
}

export const UserViewedManageShipments = (orderStatus) => {
  if(isInDev()) return
  if(orderStatus === 'created') {
    return mixpanel.track('User Viewed Created Orders')
  } else if(orderStatus === 'draft') {
    return mixpanel.track('User Viewed Draft Orders')
  } else if(orderStatus === 'hold') {
    return mixpanel.track('User Viewed On Hold Orders')
  } else if(orderStatus === 'cancelled') {
    return mixpanel.track('User Viewed Cancelled Orders')
  }
}

export const SingleOrderCreate = (step, userName) => {
  if(isInDev()) return
  if(step === 'shipmentOverview') {
    return mixpanel.track(`User Viewed Single Order Create Step 1 by ${userName}`)
  } else if(step === 'customerDetails') {
    return mixpanel.track('User Viewed Single Order Create Step 2')
  } else if(step === 'parcelDetails') {
    return mixpanel.track('User Viewed Single Order Create Step 3')
  } else if(step === 'shippingCharges') {
    return mixpanel.track('User Viewed Single Order Create Step 4')
  }
}

export const ReturnSingleOrderCreate = (userName) => {
  if(isInDev()) return
  mixpanel.track(`User Viewed Return Single Order Create Step 1 by ${userName}`)
}



export const RateCalculatorPage = (action) => {
   if(isInDev()) return
   if(action === 'visitedRateCalculator'){
    return mixpanel.track('User Visited Rate Calculator Page')
   }
   else if(action === 'submittedRateCalculator') {
    return mixpanel.track('User Submitted For Rate Calculation')
   }
}

export const UserUsingMobile = () => {
  if(isInDev()) return
  return mixpanel.track('User Logged In Through Mobile')
}

export const UserViewedCreateShipmentPage = (value) => {
  if(isInDev()) return
  if(value === 'homepage'){
    return mixpanel.track('User Viewed Create Shipment Page - From Dashboard Create Shipment Button')
  }
  if(value === 'b2bmenu') {
    return mixpanel.track('User Viewed Create B2B Shipment Page - From Left Create B2B Shipment Menu Option')
  }
  else{
    return mixpanel.track('User Viewed Create Shipment Page - From Left Create Shipment Menu Option')
  }
}


export const UserViewedManageShipmentsPage = (value) => {
  if(isInDev()) return
  if(value === 'created'){
    return mixpanel.track('User Viewed Manage Shipments Created Orders - From Dashboard')
  }
  else if(value === 'draft'){
    return mixpanel.track('User Viewed Manage Shipments Draft Orders - From Dashboard')
  }
  else if(value === 'delivered'){
    return mixpanel.track('User Viewed Manage Shipments Delivered Orders - From Dashboard')
  }
  else if(value === 'onhold'){
    return mixpanel.track('User Viewed Manage Shipments On Hold Orders - From Dashboard')
  }
  else if(value === 'toship'){
    return mixpanel.track('User Viewed Integration Shipments To Ship Orders - From Dashboard')
  }
  else if(value === 'readytoship'){
    return mixpanel.track('User Viewed Integration Shipments Ready To Ship Orders - From Dashboard')
  }
  else if(value === 'ordersuccess'){
    return mixpanel.track('User Viewed Manage Shipments Page - From Order Success Page')
  }
}

export const UserCreatedDraftOrder = (value) => {
  if(isInDev()) return
  if(value === 'single'){
    return mixpanel.track('User Created Draft Order Using Single Order Creation')
  }
  if(value === 'bulk') {
    return mixpanel.track('User Created Draft Order Using Bulk Order Creation')
  }
}

export const UserCreatedOrderSuccessfully = (value, userName) => {
  if(isInDev()) return
  if(value === 'single'){
    return mixpanel.track(`Order Created Successfully - Using Single Order Creation ${userName}`)
  }
  if(value === 'bulk') {
    return mixpanel.track(`Order Created Successfully - Using Bulk Order Creation ${userName}`)
  }
}

export const UserViewedSupersetAnalytics = (value) => {
  if(isInDev()) return
  if(value === 'ORDER_STATISTICS'){
    return mixpanel.track("User clicked on superset's Order Statistics Page")
  }
  if(value === 'DELIVERY_PERFORMANCE'){
    return mixpanel.track("User clicked on superset's Delivery Performance Page")
  }
  if(value === 'TRANSIT_TIMES'){
    return mixpanel.track("User clicked on superset's Transit Times Page")
  }
  if(value === 'RISK_EXCEPTIONS'){
    return mixpanel.track("User clicked on superset's Risk & Exceptions Page")
  }
}

export const UserViewedOrderSuccessPage = () => {
  if(isInDev()) return
  return mixpanel.track('User Viewed Order Successfully Created Page')
}

export const UserClickedePODDOwnload = (orderType, tab) => {
  if(isInDev()) return
  return mixpanel.track(`click on ${orderType} ePOD download button under ${tab} Tab`)
}

export const UserRespondedToException = (flagType) => {
  if(isInDev()) return
  return mixpanel.track(`click on Respond now button for ${flagType} flag`)
}

export const UserConfirmedDispositionSubmission = (dispositionType) => {
  if(isInDev()) return
  return mixpanel.track(`click on Confirm button for submitting ${dispositionType} disposition`)
}

export const UserViewedBillingInvoicesTabs = (tab) => {
  if(isInDev()) return

  if (tab === 'balance') {
    return mixpanel.track('User viewed Balance tab on Billing & Invoices page')
  }

  if (tab === 'invoicesPaid') {
    return mixpanel.track('User viewed Invoices Paid tab on Billing & Invoices page')
  }

  if (tab === 'allInvoices') {
    return mixpanel.track('User viewed All Invoices tab on Billing & Invoices page')
  }
}

export const UserClickedGetStatement = () => {
  if(isInDev()) return
  return mixpanel.track('User clicked on Get Statement button on Billing & Invoice pages')
}

export const UserClickedInvoiceDocumentButton = (tab) => {
  if(isInDev()) return
  return mixpanel.track(`User clicked on Invoice & Document button in ${tab} tab on Billing & Invoices page`)
}

export const UserClickedAttachmentButton = (tab) => {
  if(isInDev()) return
  return mixpanel.track(`User clicked on an attachment in ${tab} tab on Billing & Invoices page`)
}

export const resetMixpanelCookies = () => {
  if(isInDev()) return
  return mixpanel.reset()
}