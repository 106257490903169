import React, { useRef, useState } from 'react'
import {Formik, Form as FormikForm} from 'formik';
import {Row, Col, Checkbox, Button, message, Modal, Select} from 'antd';
import { useMutation } from 'react-query';
import * as Yup from 'yup';
import { useAuth } from '../../../auth/services/authContext';
import { fileUpload, removeUploadedFile } from '../../../orders/services/privateApi';
import { FastFormInput, FastFormSelect } from '../../../commons/components/FastFormFields/FastFields';
import { UploadFile } from '../../../orders/components/Forms';
import TermsAndCondition from '../../Component/TermsAndCondition';
import { damagedParcelFileDropdownOptions } from '../../../commons/utils/constants';
// import { createUploadData } from '../../../commons/utils/utilizer';
import { validateFileSize, validateFileType } from '../../../commons/utils/validator';
import { useHistory } from 'react-router';

const {Option} = Select;

interface ClaimformProp{
  claimType: string
  tracking_no: string
  order_id: any
}

export const DamagedParcelForm = (props: ClaimformProp) => {
  // const {claimType, tracking_no, order_id} = props
  const { tracking_no } = props
  const history = useHistory();
  // const {submitClaimFormQuery, error, isLoading} = useSubmitClaim()
  const auth = useAuth()
  const secretKey = auth.user.secret_key
  
  const [uploadUrlFirst, setUploadUrlFirst] = useState<any>({type:'', id:[]})
  const [uploadUrlSecond, setUploadUrlSecond] = useState<any>({type:'', id:[]})
  const [uploadUrlThird, setUploadUrlThird] = useState<any>({type:'', id:[]})
  const [uploadUrlFourth, setUploadUrlFourth] = useState<any>({type:'', id:[]})

  const uploadUrlType = useRef('')

  const [checked, setChecked] = useState(false)
  const [isShowModal, setIsShowModal] = useState(false)
  const termsAndConditionModal = () => {
    setIsShowModal(!isShowModal)
  }

  const validationSchema = () => {
    return ( 
      Yup.object().shape({
        description: Yup.string().max(80, 'Must be less than 80 characters'),
        // file_first: Yup.mixed().required('Please add file'),
        // file_second: Yup.mixed().required('Please add file'),
        // file_third: Yup.mixed().required('Please add file'),
        // file_fourth: Yup.mixed().required('Please add file'),
        fileType_first: Yup.string().when("file_first", (file_first: any, schema: any)=>{
          if(file_first)
            return schema.required('Please select file type')
          return schema
        }),
        fileType_second: Yup.string().when("file_second", (file_second: any, schema: any)=>{
          if(file_second)
            return schema.required('Please select file type')
          return schema
        }),
        fileType_third: Yup.string().when("file_third", (file_third: any, schema: any)=>{
          if(file_third)
            return schema.required('Please select file type')
          return schema
        }),
        fileType_fourth: Yup.string().when("file_fourth", (file_fourth: any, schema: any)=>{
          if(file_fourth)
            return schema.required('Please select file type')
          return schema
        }),
      }))
  }
  const initialValues= {
    description: '',
    file_first: '',
    file_second: '',
    file_third: '',
    file_fourth: '',
    fileType_first: '',
    fileType_second: '',
    fileType_third: '',
    fileType_fourth: '',
  }
  
  const fileUploadQuery:any = useMutation(async (file: any) => {
    return fileUpload(file, secretKey);
  },  {
    onSuccess: (val) => {
      if(uploadUrlType.current === 'file_first'){
        const uploadUrl = uploadUrlFirst
        uploadUrl.id = [val.id]
        setUploadUrlFirst(uploadUrl)
      }
      if(uploadUrlType.current === 'file_second'){
        const uploadUrl = uploadUrlSecond
        uploadUrl.id = [val.id]
        setUploadUrlSecond(uploadUrl)
      }
      if(uploadUrlType.current === 'file_third'){
        const uploadUrl = uploadUrlThird
        uploadUrl.id = [val.id]
        setUploadUrlThird(uploadUrl)
      }
      if(uploadUrlType.current === 'file_fourth'){
        const uploadUrl = uploadUrlFourth
        uploadUrl.id = [val.id]
        setUploadUrlFourth(uploadUrl)
      }
      message.success('File uploaded successfully.')
    },
    onError: (error: any) => {
      message.error('error in uploading. Please again later')
    },
  })

  const fileRemoveQuery:any = useMutation(async (id: any) => {
    return removeUploadedFile(auth.user.secret_key, id);
  },  {
    onSuccess: (val: any) => {
      message.success('File removed successfully.')
    },
    onError: (error: any) => {
      message.error('error in uploading. Please again later')
    },
  })
 
  const onSubmit = (values: any) => {
    // const uploadData = createUploadData([uploadUrlFirst, uploadUrlSecond, uploadUrlThird, uploadUrlFourth])
    // const formValues = {
    //   claim_type: claimType.toUpperCase(),
    //   tracking_no: tracking_no,
    //   incident_desc: values.description,
    //   order: order_id,
    //   ...uploadData
    // }
    // submitClaimFormQuery.mutate([formValues]);
    history.push('/claims/success')
  }

  const onFileAddition = (event: any, formik: any, formikFileName: string) => {
    const isValidFileType = validateFileType(event)
    const isValidFileSize = validateFileSize(event)
    if(!isValidFileType){
      message.error('Only .png, .jpg and .pdf files are accepted.')
    }
    if(!isValidFileSize){
      message.error('Each file needs to be less than 10mb.')
    }
    if(isValidFileType && isValidFileSize){
      uploadUrlType.current = formikFileName
      formik.setFieldValue(formikFileName, event.name)
      fileUploadQuery.mutate(event)
    }
    return false
  }

  const onFileRemove = (event: any, formik: any, formikFileName: string) => {
    if(!event.uid) return false;
    if(formikFileName === 'file_first'){
      const uploadUrl = uploadUrlFirst
      fileRemoveQuery.mutate(uploadUrlFirst.id[0])
      uploadUrl.id = []
      setUploadUrlFirst(uploadUrl)
    }
    if(formikFileName === 'file_second'){
      const uploadUrl = uploadUrlSecond
      fileRemoveQuery.mutate(uploadUrlSecond.id[0])
      uploadUrl.id = []
      setUploadUrlSecond(uploadUrl)
    }
    if(formikFileName === 'file_third'){
      const uploadUrl = uploadUrlThird
      fileRemoveQuery.mutate(uploadUrlThird.id[0])
      uploadUrl.id = []
      setUploadUrlThird(uploadUrl)
    }
    if(formikFileName === 'file_fourth'){
      const uploadUrl = uploadUrlFourth
      fileRemoveQuery.mutate(uploadUrlFourth.id[0])
      uploadUrl.id = []
      setUploadUrlFourth(uploadUrl)
    }
    formik.setFieldValue(formikFileName, '')
  }

  const updateDropdownType = (val: any, formik: any, uploadFileType: string) => {
    if(uploadFileType === 'fileType_first'){
      const uploadUrl = uploadUrlFirst
      uploadUrl.type = val;
      setUploadUrlFirst(uploadUrl)
    }
    if(uploadFileType === 'fileType_second'){
      const uploadUrl = uploadUrlSecond
      uploadUrl.type = val;
      setUploadUrlSecond(uploadUrl)
    }
    if(uploadFileType === 'fileType_third'){
      const uploadUrl = uploadUrlThird
      uploadUrl.type = val;
      setUploadUrlThird(uploadUrl)
    }
    if(uploadFileType === 'fileType_fourth'){
      const uploadUrl = uploadUrlFourth
      uploadUrl.type = val;
      setUploadUrlFourth(uploadUrl)
    }
    formik.setFieldValue(uploadFileType, val)
  }

  return (
    <>
      <Formik 
        initialValues={initialValues}
        onSubmit={onSubmit}   
        validationSchema={validationSchema} 
      >
        {formik => (
          <FormikForm className='claim-main-container multi-claim-group claim-form-padding-left-zero'>
            {/* {Object.keys(error).length>0 && <Alert
              message={<ErrorMessage errorMessage={error}/>}
              type="error"
              showIcon
              className='error-message-margin'
            />} */}
            <Row style={{marginBottom: '10px'}}>
              <Col span={6} style={{marginLeft: '30px'}}><span style={{fontSize: '14px'}}>Description for <strong>{tracking_no}</strong></span></Col>
              <Col span={15}><span className='text-wrap' title={"Upload Files: Only .png, .jpg and .pdf files are accepted.Each file needs to be less than 10mb. You can add up to a maximum of 4 files."}>{"Upload Files: Only .png, .jpg and .pdf files are accepted. Each file needs to be less than 10mb. You can add up to a maximum of 4 files."}</span></Col>
            </Row>
            <Row gutter={6} className='claim-form-horizontal claim-form-padding-left-thirty'>
              <Col span={8} className='claim-input-padding claims-input-width claims-input-display'>
                <FastFormInput required name='description' textArea={true} rows={7} height={170} resize={'none'}/>
              </Col>
              <Col span={5} className='claim-input-padding claims-input-width claims-upload-display'>
                <Row className='supporting-doc-upload supporting-doc-full-width'>
                  <UploadFile
                    accept=".png,.jpg,.pdf"
                    fileList={formik.values.file_first ? null: []}
                    multiple={false}
                    required  
                    name={'file_first'} 
                    beforeUpload={(e) =>onFileAddition(e, formik, 'file_first')}
                    showRemove
                    listType={'picture'}
                    onRemove={(e) => onFileRemove(e, formik, 'file_first')}
                  />
                </Row>
                <Row className='supporting-doc-type-dropdowm'>
                  <FastFormSelect required name='fileType_first' onChangeCallback={(val) => updateDropdownType(val, formik, 'fileType_first')} placeholder='Select File Type'> 
                    {
                      Object.entries(damagedParcelFileDropdownOptions).map(([optionKey, optionValue]:[any, any])=>{
                        return <Option key={optionKey} value={optionKey}>{optionValue}</Option>
                      })
                    }
                  </FastFormSelect>
                </Row>
              </Col>
              <Col span={5} className='claim-input-padding claims-input-width claims-upload-display'>
                <Row className='supporting-doc-upload supporting-doc-full-width'>
                  <UploadFile
                    accept=".png,.jpg,.pdf"
                    fileList={formik.values.file_second ? null: []} 
                    multiple={false}
                    required  
                    name={'file_second'} 
                    beforeUpload={(e) =>onFileAddition(e, formik, 'file_second')}
                    showRemove
                    listType={'picture'}
                    onRemove={(e) => onFileRemove(e, formik, 'file_second')}
                  />
                </Row>
                <Row className='supporting-doc-type-dropdowm'>
                  <FastFormSelect required name='fileType_second' onChangeCallback={(val) => updateDropdownType(val, formik, 'fileType_second')} placeholder='Select File Type'>                   
                    {
                      Object.entries(damagedParcelFileDropdownOptions).map(([optionKey, optionValue]:[any, any])=>{
                        return <Option key={optionKey} value={optionKey}>{optionValue}</Option>
                      })
                    }
                  </FastFormSelect>
                </Row>
              </Col>
              <Col span={5} className='claim-input-padding claims-input-width claims-upload-display'>
                <Row className='supporting-doc-upload supporting-doc-full-width'>
                  <UploadFile
                    accept=".png,.jpg,.pdf"
                    fileList={formik.values.file_third ? null: []} 
                    multiple={false}
                    required  
                    name={'file_third'} 
                    beforeUpload={(e) =>onFileAddition(e, formik, 'file_third')}
                    showRemove
                    listType={'picture'}
                    onRemove={(e) => onFileRemove(e, formik, 'file_third')}
                  />
                </Row>
                <Row className='supporting-doc-type-dropdowm'>
                  <FastFormSelect required name='fileType_third' onChangeCallback={(val) => updateDropdownType(val, formik, 'fileType_third')} placeholder='Select File Type'>                   
                    {
                      Object.entries(damagedParcelFileDropdownOptions).map(([optionKey, optionValue]:[any, any])=>{
                        return <Option key={optionKey} value={optionKey}>{optionValue}</Option>
                      })
                    }
                  </FastFormSelect>
                </Row>
              </Col>
              <Col span={5} className='claim-input-padding claims-input-width claims-upload-display'>
                <Row className='supporting-doc-upload supporting-doc-full-width'>
                  <UploadFile
                    accept=".png,.jpg,.pdf"
                    fileList={formik.values.file_fourth ? null: []}
                    multiple={false} 
                    required  
                    name={'file_fourth'} 
                    beforeUpload={(e) =>onFileAddition(e, formik, 'file_fourth')}
                    showRemove
                    listType={'picture'}
                    onRemove={(e) => onFileRemove(e, formik, 'file_fourth')}
                  />
                </Row>
                <Row className='supporting-doc-type-dropdowm'>
                  <FastFormSelect required name='fileType_fourth' onChangeCallback={(val) => updateDropdownType(val, formik, 'fileType_fourth')} placeholder='Select File Type'>                   
                    {
                      Object.entries(damagedParcelFileDropdownOptions).map(([optionKey, optionValue]:[any, any])=>{
                        return <Option key={optionKey} value={optionKey}>{optionValue}</Option>
                      })
                    }
                  </FastFormSelect>
                </Row>
              </Col>
            </Row>
            <Row className='claim-submit-checkbox'>
              <Col className='claim-form-padding-left-thirty'>
                <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)}>
                  I agree to the <a onClick={termsAndConditionModal}>terms and conditions</a> mentioned
                </Checkbox>
              </Col>
              <Col className='claim-submit-button'>
                <Button type='primary' htmlType='submit' disabled={!checked} className='blue-button'>{'Submit Claim'}</Button>
              </Col>
            </Row>
            <Modal title={'Terms and Condition'} visible={isShowModal} onCancel={termsAndConditionModal} onOk={termsAndConditionModal}>
              <TermsAndCondition/>
            </Modal>
          </FormikForm>
        )}
      </Formik>
    </>
  )
}