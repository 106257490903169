import {
  Row,
  Checkbox,
  Divider,
  Col,
  message,
  Button
} from 'antd';
import '../../styles/BulkOrderForm.css';
import {useMutation} from "react-query";
import {useBulkOrder} from "../../services/bulkOrderContext";
import {useAuth} from "../../../auth/services/authContext"; 
import { useEffect, useState } from 'react';
import { createMonthlyBillingBulkOrder_in_b2c, createMonthlyBillingReturnBulkOrder_in_b2c } from '../../services/privateApi';
import { useHistory } from 'react-router-dom';
// @ts-ignore
import prohibitedItems from '../../../assets/static/List_of_Prohibited_and_Restricted_Goods.pdf';
import { useReturnBulkOrder } from '../../../Returns/services/returnBulkOrderContext';
  
export const OrderSubmission = (props: any) => {
  const {current, setCurrentState} = props;
  const auth = useAuth();
  const bulkOrderContext = useBulkOrder();
  const returnBulkOrderContext = useReturnBulkOrder();

  const [checkboxWeight, setCheckboxWeight] = useState(false)
  const [checkboxValue, setCheckboxValue] = useState(false)
  const [checkboxRestriction, setCheckboxRestriction] = useState(false)
  let history = useHistory();
  useEffect(() => {
    setCurrentState(2)
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  const disabledSubmit = () => {
    if(!checkboxValue || !checkboxRestriction || !checkboxWeight){
      return true
    }else{
      return false
    }
  }

  const onSubmit = () => {
    if(props.orderType === 'forward'){
      const orders = bulkOrderContext.bulkOrder.orders;
      createMonthlyBillingBulkOrderInB2C.mutate(orders)
    }else{
      const orders = returnBulkOrderContext.bulkOrder.orders;
      createMonthlyBillingRetunrBulkOrderInB2C.mutate(orders)
    }
  }

  const createMonthlyBillingRetunrBulkOrderInB2C:any = useMutation(async (orders:any) => {
    return createMonthlyBillingReturnBulkOrder_in_b2c(auth.user.secret_key, orders);
  },  {
    onSuccess: (val) => {
      history.push('/orders/success')
    },
    onError: (error: { response: { data: any } }) => {
      let errorMessage: any
      let errObject = error.response.data?.orders ? error.response.data.orders : error.response.data.error;
      if(error.response.data.message){
        message.error(error.response.data.message)
      }
      if(errObject) {
        errorMessage = Object.values(errObject).map((item: any) => {
          if(typeof(item) == 'string'){
            return ({item})
          }else if(typeof(item) === 'object' && Object.entries(item).length === 0){
            return null
          }else if(typeof(item) === 'object' && Object.values(item) instanceof Array){
            return (
              Object.values(item).map((value: any) => {
                return JSON.stringify(value)
              })
            )
          }
          return (
            Object.entries(item).map((y: any) => {
              return `${y[0]}: ${y[1]}`
            })           
          )
        })
        const displayedMessage = errorMessage.join()
        message.error(displayedMessage)
      }else{
        message.error('Failed to create shipment')
      }
        
    },
  });

  const createMonthlyBillingBulkOrderInB2C:any = useMutation(async (orders:any) => {
    return createMonthlyBillingBulkOrder_in_b2c(auth.user.secret_key, orders);
  },  {
    onSuccess: (val) => {
      history.push('/orders/success')
    },
    onError: (error: { response: { data: any } }) => {
      let errorMessage: any
      let errObject = error.response.data?.orders ? error.response.data.orders : error.response.data.error;
      try{
        if(error.response.data.message){
          message.error(error.response.data.message)
        }
        else if(errObject) {
          errorMessage = Object.values(errObject).map((item: any) => {
            if(typeof(item) == 'string'){
              return ({item})
            }else if(typeof(item) === 'object' && Object.entries(item).length === 0){
              return null
            }else if(typeof(item) === 'object' && Object.values(item) instanceof Array){
              return (
                Object.entries(item).map(([key,value]: any) => {
                  return JSON.stringify(`${key}: ${value}`)
                })
              )
            }
            return (              
              Object.entries(item).map((y: any) => {
                return `${y[0]}: ${y[1]}`
              })           
            )
          })
          const displayedMessage = errorMessage.join()
          message.error(displayedMessage)
        }else{
          message.error({
            content: <>
              <span>We are not able to process your request right now, please reach out to us at  
                <a href={"mailto:clientsupport@janio.asia"}>clientsupport@janio.asia</a> for further help
              </span></>,
            duration: 15,
          })
        }
      }catch(e){
        message.error({
          content: <>
            <span>We are not able to process your request right now, please reach out to us at  
              <a href={"mailto:clientsupport@janio.asia"}>clientsupport@janio.asia</a> for further help
            </span></>,
          duration: 15,
        })
      }
        
    },
  });
  if(current === 2){
    return (
      <div className={"single-order-form-container ant-form-vertical"}>
        <Row gutter={[8, 8]}>
          <Col className={"single-order-form-title"} span={24}>
            Shipment Reweighs Policy
          </Col>
          <Col span={24}>
            <Checkbox checked={checkboxWeight} onChange={(e)=> setCheckboxWeight(e.target.checked)}>
              I confirm that the declared weight and dimensions in this form are correct and accurate.
            </Checkbox>
            {!checkboxWeight ? <Col style={{color:"#ff4d4f"}}>{'Please accept agreement to proceed.'}</Col>: ''}
          </Col>
        </Row>
        <Divider/>
        <Row gutter={[8, 8]}>
          <Col className={"single-order-form-title"} span={24}>
            Order Value Declaration
          </Col>
          <Col span={24}>
            <Checkbox checked={checkboxValue} onChange={(e)=> setCheckboxValue(e.target.checked)}>
              I confirm that the declared order value in this csv file is correct, complete and accurate.
            </Checkbox>
            {!checkboxValue ? <Col style={{color:"#ff4d4f"}}>{'Please accept agreement to proceed.'}</Col>: ''}
          </Col>
        </Row>
        <Divider/>
        <Row gutter={[8, 8]}>
          <Col className={"single-order-form-title"} span={24}>
            Shipping Restrictions and Prohibited Items
          </Col>
          <Col span={24}>
            <Checkbox checked={checkboxRestriction} onChange={(e)=> setCheckboxRestriction(e.target.checked)}>
              I confirm that my shipment does not contain any prohibited items specified in this <a href={prohibitedItems} download="List of Prohibited Items and Goods.pdf">list</a>.
            </Checkbox>
            {!checkboxRestriction ? <Col style={{color:"#ff4d4f"}}>{'Please accept agreement to proceed.'}</Col>: ''}
          </Col>
        </Row>
        <div className={"button-block"}>
          <Button disabled={disabledSubmit()} type="primary" onClick={onSubmit} className={"blue-button"}
            loading={props.orderType === 'forward'? createMonthlyBillingBulkOrderInB2C.isLoading : createMonthlyBillingRetunrBulkOrderInB2C.isLoading}>
            {'Submit'}
          </Button>
        </div>
      </div>
    )
  }
  return (
    <div>
      <Row>
        <Col className={"single-order-form-title"}>
          Terms and Conditions
        </Col>
      </Row>
      <div className={"button-block"}>
        <Button disabled={true} type="primary" className={"blue-button"}>
          {'Submit'}
        </Button>
      </div>
    </div>
  ) 
};
export const OrderSubmissionTitle = (props: any) => {
  // const {current, setCurrentState} = props;
  return (
    <div className={"title-block"}>
      <p className={'step-title'}>Order Submission</p>
      {/* {current > 2 ? <p onClick={() => setCurrentState(2)} className={"edit-text"}>Edit details</p> : ''} */}
    </div>
  )
};
  