import React from 'react'
import {Card} from 'antd';

export const EscalateTNCard = (props: any) => {
  if(props.tracking_no){
    return (
      <>
        <Card>
          <p className='claim-type-header'>{'Tracking number'}</p>
          <p className='claim-type-gray'>
            {props.tracking_no && props.tracking_no.map((order: any, index: number) => <span key={order.order_id}>{`${order.tracking_no}`}{!(index === props.tracking_no.length- 1) && ', '}</span>)}
          </p>
        </Card>     
      </>
    )
  }
  return null;
}

export const EscalateTNCardTitle = () => {
  return (
    <div className='claim-step-header'>
      <p className={'claim-step-title'}>{'Escalations are being submitted for the following Tracking Numbers'}</p>
    </div>
  )
}