import moment from "moment";
import {createContext, ReactNode, useContext, useState} from "react"

interface PickUpFormContext {
  form?: any
}
interface EstimatesFormContext {
  tableData: {}
  selectedOrders: {}
  overallEstimates: {shipmentsCount:0, estimatedPrice:0.00}
}

interface BulkOrder {
  file_name?: string,
  orders: any
}
interface PriceContext {
  batch_no: any;
  total: any
  currency:string
}

interface BulkOrderContext {
    bulkOrder: BulkOrder
    setBulkOrder: (values: any) => any
    parsedJson: {
      file_name: string,
      consolidatedOrderItems: []
    }
    setParsedJson: (values:{}) => any
    pickUpFormContext: PickUpFormContext
    setPickUpFormContext: (values: PickUpFormContext) => any
    estimatesFormContext: EstimatesFormContext
    setEstimatesFormContext: (values: EstimatesFormContext) => any
    canEditOrder: boolean
    setCanEditOrder: (values:boolean) => any
    priceContext: PriceContext[] | null
    setPriceContext: (values: PriceContext[] | null) => any}

const bulkOrderContext = createContext<BulkOrderContext>({} as BulkOrderContext);

export const BulkOrderContextProvider = ({children, orders, renderOnlyEstimates}: { children: ReactNode, orders?:any, renderOnlyEstimates?: boolean }) => {
  let serverData = () =>{
    if(orders){
      return {
        renderOnlyEstimates: renderOnlyEstimates, 
        data: orders.data
      }
    }
  }
  const useBulkOrder = useBulkOrderContextProvider(serverData());
  return (
    <bulkOrderContext.Provider value={useBulkOrder}>
      {children}
    </bulkOrderContext.Provider>
  )
};

export const useBulkOrder = () => {
  return useContext(bulkOrderContext);
};

const useBulkOrderContextProvider = (serverData?:any) => {
  const [bulkOrder, setBulkOrder] = useState<BulkOrder>(() => {
    if(serverData) {
      return {
        orders: serverData.data.map((item:any) => {
          return item.order
        })
      }
    }
    return {
      orders: null,
      rates: null
    }
  });
  const [canEditOrder, setCanEditOrder] = useState(false);

  const [parsedJson, setParsedJson] = useState(() => {
    if(serverData) {
      return serverData.data.map((item:any) => {
        return item.order
      })
    }
    return {}
  });

  const [pickUpFormContext, setPickUpFormContext] = useState<PickUpFormContext>(() => {
    if(serverData){
      let form:any=[];
      let index = 0;
      let distinctLanes:any={}
      serverData.data.map((item:any) => {
        const fName = `${item.order.pickup_country}-${item.order.consignee_country}`;
        if(!distinctLanes.hasOwnProperty(fName)){
          distinctLanes[fName]={
            service_type: item.order.service_type,
            use_pickup_as_shipping: item.order.billing_location === item.order.pickup_location,
            pickup_address_id: item.order.pickup_location,
            dropoff_vendor_point_id: parseInt(item.order.dropoff_vendor_point_id),
            billing_address_id: item.order.billing_location === item.order.pickup_location ? null: item.order.billing_location,
            pickup_date: item.order.pickup_date ? moment(item.order.pickup_date) : null,
            pickup_time: '9am - 7pm'
          };
          form[index]=distinctLanes[fName]
          index++;
        }
        return form;
      })
      return {
        form:form,
      }
    }
    return {
      form: null,
    }
  });

  const [estimatesFormContext, setEstimatesFormContext] = useState<EstimatesFormContext>(() => {
    return {
      tableData: {},
      selectedOrders: {},
      overallEstimates: {shipmentsCount:0, estimatedPrice:0.00}
    }
  });

  const [priceContext, setPriceContext] = useState<PriceContext[] | null>(() => {
    if(serverData){
      return serverData.data.map((rate: any) => {
        if (serverData.renderOnlyEstimates) {
          return {
            id: rate.order.id, 
            batch_no: rate.order.batch_no, 
            total: rate.price.total, 
            currency: rate.price.currency,
            gst_amount: rate.price.gst_amount,
            total_duties: rate.price.total_duties,
            is_tax_payable: rate.price.is_tax_payable,
            total_taxes: rate.price.total_taxes,
            charge_id: rate.price.charge_id,
            pickup_charge: rate.price.pickup_charge ? parseFloat(rate.price.pickup_charge) : 0
          }
        }
        return {
          id: rate.order.id, 
          batch_no: rate.order.batch_no, 
          total: rate.rate.total, 
          currency: rate.rate.currency,
          gst_amount: rate.rate.gst_amount,
          total_duties: rate.rate.total_duties,
          is_tax_payable: rate.rate.is_tax_payable,
          total_taxes: rate.rate.total_taxes,
          charge_id: rate.rate.charge_id,
          pickup_charge: rate.account_charges.length > 0 ? parseFloat(rate.account_charges[0].amount) : 0
        }
      });
    }
    return null
  });

  return {
    bulkOrder,
    setBulkOrder,
    parsedJson,
    setParsedJson,
    pickUpFormContext,
    setPickUpFormContext,
    estimatesFormContext,
    setEstimatesFormContext,
    canEditOrder,
    setCanEditOrder,
    priceContext,
    setPriceContext
  }
};