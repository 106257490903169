import { Alert, Button, Col, Collapse, Divider, Form, message, Modal, Row, Spin, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom';
import '../../styles/ReturnOrderForm.css'
import { useAuth } from '../../../auth/services/authContext';
import {useReturnBulkOrder} from "../../services/returnBulkOrderContext";
import { createLanes, getUpdateBulkOrderPayload } from '../../../orders/helpers';
import { createBatchOrder, get_batch_orders, submitReturnOrderForGetRate } from '../../../orders/services/privateApi';
import { getTableData } from '../../../commons/utils/utilizer';
import { DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { PriceTableColumns } from '../../../commons/utils/constants';
import { useMutation } from 'react-query';

interface PropsType{
  current: number,
  setCurrentState: (val: number) => void,
  dataFilled?: number,
  renderPriceEstimateData?: boolean
}

const {Panel} = Collapse;

const priceBreakUp = (data: any) => {

  let total_duties = data.total_duties ? parseFloat(data.total_duties) : 0
  let total_taxes = data.total_taxes ? parseFloat(data.total_taxes) : 0
  let gst_amount = data.gst_amount ? parseFloat(data.gst_amount) : 0
  let pickup_fee = data.pickup_charge ? data.pickup_charge : 0

  let taxes = total_duties + total_taxes
  let shipping_charges = (parseFloat(data.total) - taxes) - gst_amount

  let total_price = parseFloat(data.total) + pickup_fee

  return (
    <div className={'amount-breakup-lane'}>
      <Row>
        <Col span={16}>Shipping Rate:</Col>
        <Col span={8} className='strong-weight'>{data.currency} {shipping_charges.toFixed(2)}</Col>
      </Row>
      <Row>
        <Col span={16}>Duties:</Col>
        <Col span={8} className='strong-weight'>{data.currency} {total_duties.toFixed(2)}</Col>
      </Row>
      <Row>
        <Col span={16}>Taxes:</Col>
        <Col span={8} className='strong-weight'>{data.currency} {total_taxes.toFixed(2)}</Col>
      </Row>
      <Row>
        <Col span={16}>GST:</Col>
        <Col span={8} className='strong-weight'>{data.currency} {gst_amount.toFixed(2)}</Col>
      </Row>
      {
        pickup_fee > 0 && 
        <Row>
          <Col span={16}>Pickup Fee:<span className='inclusive-text'>(Inclusive of Taxes)</span>
            <Link to="/support/pickup_fee" target="_blank" rel="noopener noreferrer" > What is this?</Link>
          </Col>
          <Col span={8} className='strong-weight'>{data.currency} {pickup_fee.toFixed(2)}</Col>
        </Row>
      }
      
      <Row>
        <Col span={16}>Total Amount:</Col>
        <Col span={8} className='strong-weight'>{data.currency} {total_price.toFixed(2)}</Col>
      </Row>
    </div>
  )
}

export const PriceEstimateForm = (props: PropsType) => {
  const history = useHistory();
  const location = useLocation();
  const {current, setCurrentState} = props;
  const auth = useAuth();
  let isEditing = window.location.pathname.includes('/pay/return/bulk')
  const returnBulkOrderContext = useReturnBulkOrder();

  useEffect(() => {
    setCurrentState(2)
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  const distinctLanes =  createLanes(
    isEditing? returnBulkOrderContext.parsedJson : returnBulkOrderContext.parsedJson.consolidatedOrderItems,
    auth.user.authToken, 'RETURNS'
  );

  const [overallPrice, setOverallPrice] = useState<number>(0.00);
  const [overallShipmentsCount, setOverallShipmentsCount] = useState<number>(0);
  const [selectedOrders, setSelectedOrders] = useState<any>(null);
  const [tableData, setTableData] = useState<any>(null);
  const [ordersWithRates, setOrdersWithRates] = useState({data: returnBulkOrderContext.priceContext});
  const [validationError, setValidationError] = useState(null);

  useEffect(()=> {
    if(!returnBulkOrderContext.priceContext || !ordersWithRates.data){
      setTableData(null)
      setShowBulkPriceEstimateModal()
      submitReturnOrderForGetRate(auth.user.authToken, returnBulkOrderContext.bulkOrder.file_name!, returnBulkOrderContext.bulkOrder.orders)
        .then((result:any) =>{
          
          const temp = result.data.map((data:any) =>{
            return {
              total: data['price'].total,
              currency: data['price'].currency,
              chargable_weight: data['price'].chargable_weight,
              gst_amount: data['price'].gst_amount,
              total_duties: data['price'].total_duties,
              total_taxes: data['price'].total_taxes,
              weight_upper_bound: data['price'].weight_upper_bound,
              charge_id: data['price'].charge_id,
              is_tax_payable: data['price'].is_tax_payable,
              pickup_charge: data['price'] ? data['price'].pickup_charge : 0
            }
          });
          
          setOrdersWithRates({data: [...temp]});
          returnBulkOrderContext.setPriceContext(temp)
          const data = getTableData(returnBulkOrderContext.bulkOrder.orders, temp);
          if(data){
            setOverallPrice(data.overallEstimates.estimatedPrice);
            setOverallShipmentsCount(data.overallEstimates.shipmentsCount);
            setSelectedOrders(data.selectedOrders);
            setTableData(data.tableData)
          }
        })
        .catch((error) =>{
        })

    }else {
      const data = getTableData(returnBulkOrderContext.bulkOrder.orders, returnBulkOrderContext.priceContext);
      if(data){
        setOverallPrice(data.overallEstimates.estimatedPrice);
        setOverallShipmentsCount(data.overallEstimates.shipmentsCount);
        setSelectedOrders(data.selectedOrders);
        setTableData(data.tableData)
      }
    }
  },[returnBulkOrderContext.bulkOrder.orders,returnBulkOrderContext.priceContext]);// eslint-disable-line react-hooks/exhaustive-deps
  /* eslint-disable */

  if (validationError){
    const formattedErrors = validationError;
    const errorContent = <ul className={'error-list-body'}>
      {
        Object.entries(formattedErrors!).map((item: any, index: number) => {
          if(item[1].error.hasOwnProperty('tracking_no')) {
            return (
              <li className={'error-list-item'} key={index}>
                <strong>{item[1].order.tracking_no}</strong>: order with this tracking no already exists.
              </li>
            )
          } else {
            return undefined
          }
        })
      }
    </ul> ;
    return (
      <>
        <Alert
          showIcon
          message={"Please correct the following errors before reuploading your file:"}
          description={errorContent.props.children[0] !== undefined ? errorContent : 'Unable to process your request, Please contact our customer support'}
          type="error"
          className={'error-list-container'}
        />
        <div className={"button-block"}>
          <Button type="primary" icon={<DownloadOutlined />} onClick={() => setCurrentState(0)} className={"blue-button"} style={{borderRadius: 2}}>
            {'Reupload file'}
          </Button>
        </div>
      </>
    )
  }

  const setShowBulkPriceEstimateModal = () => {
    if(auth.user.hide_price_estimation) {
      Modal.info({
        title: "Order summary is in progress",
        content: (
          <div>
            <p>
            Please be patient while we creating your order summary. While waiting, we will direct you to Manage Shipments - Bulk Upload Status.
            </p>
          </div>
        ),
        onOk(){
          history.push('/orders?status=bulkUpload')
        }
  
      })
    } else {
      Modal.info({
        title: "Estimating prices in progress",
        content: (
          <div>
            <p>
            Please be patient while we get your price estimate. While waiting, we will direct you to Manage Shipments - Bulk Upload Status.
            </p>
          </div>
        ),
        onOk(){
          history.push('/orders?status=bulkUpload')
        }
  
      })
    }
    
  }

  const TableForm = ({index, checked, fName, route, selectedRowKeys, showSummary}: {index:number, checked?:boolean, fName:string, route:any, selectedRowKeys?:any, showSummary: boolean}) => {
    return (
      <div style={{marginBottom: 20, paddingRight: 10, paddingLeft: 10}} key={index}>
        <Row gutter={14} className={'space-between'} style={{marginLeft: '0px'}}>
          {`${route.origin} to ${route.destination} | ${Object.keys(selectedOrders[fName].indexes).length}/ ${route.count} ${'selected shipments'}`}

          {
            !auth.user.hide_price_estimation && 
            <Row>
              <Form.Item label={`${selectedOrders[fName].currency} ${parseFloat(selectedOrders[fName].totalPrice).toFixed(2)} `} className={"single-order-form-title"}>
              </Form.Item>
              <Tooltip color={"#fff"} overlayClassName='price-breakup-lane' placement="left" title={priceBreakUp(selectedOrders[fName].priceBreakUp)}>
                <InfoCircleOutlined className={'info-price-break-lane'}/>
              </Tooltip>
            </Row>
          }

        </Row>
        <p className={'gray-text'} style={{marginLeft: 1, marginBottom: 10}}>Pick-up service</p>
        {
          !showSummary? null
            : !auth.user.hide_price_estimation && <Collapse
              className={'custom-collapse'}
              expandIconPosition={'right'}
              style={{marginLeft: '-23px'}}
            >
              <Panel header={'Click for more details'} key={index}>
                <Table
                  columns={PriceTableColumns()}
                  dataSource={tableData[fName]}
                  pagination={false}
                  className={'custom-table'}
                  scroll={{ x: 1500 }} sticky
                />
              </Panel>
            </Collapse>
        }
      </div>
    )
  }

  const submitOrder = useMutation(async (token: string) => {
    let location_split = location.pathname.split('bulk/')
    let batch_id = location_split[1]
    return get_batch_orders(auth.user.authToken, batch_id);
  });
  const createBulkOrder = useMutation(async (data: any) => {
    let location_split = location.pathname.split('bulk/')
    let batch_id = location_split[1]
    let req = {
      batch_id: batch_id
    }
    return createBatchOrder(auth.user.authToken, req);
  });

  const onSuccessHandler = (response:any) =>{
    const prices = response.data.map((data:any) => {
      return {
        id: data.order.id,
        batch_no: data.order.batch_no,
        total: data.rate.total,
        currency: data.rate.currency,
        chargable_weight: data.rate.chargable_weight,
        gst_amount: data.rate.gst_amount,
        total_duties: data.rate.total_duties,
        total_taxes: data.rate.total_taxes,
        is_tax_payable: data.rate.is_tax_payable,
        charge_id: data.rate.charge_id,
        weight_upper_bound: data.rate.weight_upper_bound,
        pickup_charge: data.account_charges.length > 0 ? parseFloat(data.account_charges[0].amount) : 0
      }
    });

    returnBulkOrderContext.setPriceContext(prices);
    returnBulkOrderContext.setCanEditOrder(true);
    setCurrentState(3);
    message.success("Current shipment has move to Draft")
  };

  const onErrorHandler = (error:any) =>{
    let val: any = error;
    if(val.response && val.response.status === 400){
      setValidationError({...val.response.data});
    }
    else{
      message.error('Failed to create order, please try later')
    }
  };

  const onSaveAndContinue = () => {
    const data = getUpdateBulkOrderPayload(returnBulkOrderContext.bulkOrder.orders);
    createBulkOrder.mutate(data, {
      onSuccess: (response: any) => {
        submitOrder.mutate(auth.user.authToken, {
          onSuccess: (response: any) => {
            setCurrentState(3)
            onSuccessHandler(response);
          },
          onError: (error: any) => {
            onErrorHandler(error);
          }
        })
      },
      onError: (error: any) => {
        onErrorHandler(error);
      }
    })
  };

  if(isEditing && !props.renderPriceEstimateData) {
    setCurrentState(3)
  }
  if(props.renderPriceEstimateData) {
    return (ordersWithRates.data && tableData) ?
      <div className={"single-order-form-container ant-form-vertical"}>
        <Form
          layout={'vertical'}
          requiredMark={false}>
          {  
            auth.user.hide_price_estimation ? 
              <div className={"title-block"} style={{marginBottom: '-20px' }}>
                <Form.Item label={`Order Summary for ${overallShipmentsCount} ${'Shipments'}`} className={"single-order-form-title"}>
                </Form.Item>
              </div> 
              :
              <div className={"title-block"}>
                <Form.Item label={`${'Price estimate for'} ${overallShipmentsCount} ${'Shipments'}`} className={"single-order-form-title"}>
                </Form.Item>
                <Form.Item label={`${ordersWithRates.data![0].currency} ${overallPrice.toFixed(2)}`} className={"single-order-form-title"}>
                </Form.Item>
              </div>
          }
          <Divider/>
          {
            distinctLanes.lanes?.map((route: any, index:number) => {
              const fName = `${route.origin}-${route.destination}`;
              const selectedRowKeys = Object.keys(selectedOrders[fName].indexes).map(key => parseInt(key) );
              const checked = Object.keys(selectedOrders[fName]).length > 0;
              return (current === 2 && !props.dataFilled) ? 
                <TableForm key={index} index={index} checked={checked} fName={fName} route={route} selectedRowKeys={selectedRowKeys} showSummary={false} /> 
                : 
                <TableForm key={index} index={index} checked={checked} fName={fName} route={route} selectedRowKeys={selectedRowKeys} showSummary={true} />
            })
          }
          {current === 2 && (
            <div className={"button-block"}>
              <Button
                type="primary"
                className={"blue-button"}
                onClick={onSaveAndContinue}
                loading={submitOrder.isLoading || createBulkOrder.isLoading}
              >
                {'Save & Continue'}
              </Button>
            </div>
          )}
        </Form>

      </div>
      :
      <div style={{position: 'relative', paddingBottom: '30px'}}>
        <Spin style={{position: 'absolute', left: '50%'}}/>
      </div>
  }
  return (ordersWithRates.data && tableData) && (
    <div className={"single-order-form-container ant-form-vertical"}>
      <Form
        layout={'vertical'}
        requiredMark={false}>
        {
          auth.user.hide_price_estimation ? 
          <div className={"title-block"} style={{marginBottom: '-20px'}}>
            <Form.Item label={`Order summary for ${overallShipmentsCount} ${'Shipments'}`} className={"single-order-form-title"}>
            </Form.Item>
          </div>
          :
          <div className={"title-block"}>
            <Form.Item label={`${'Price estimate for'} ${overallShipmentsCount} ${'Shipments'}`} className={"single-order-form-title"}>
            </Form.Item>
            <Form.Item label={`${ordersWithRates.data![0].currency} ${overallPrice.toFixed(2)}`} className={"single-order-form-title"}>
            </Form.Item>
          </div>
        }
        <Divider/>
        {
          distinctLanes.lanes?.map((route: any, index:number) => {
            const fName = `${route.origin}-${route.destination}`;
            const selectedRowKeys = Object.keys(selectedOrders[fName].indexes).map(key => parseInt(key) );
            const checked = Object.keys(selectedOrders[fName]).length > 0;
            return (current === 2 && !props.dataFilled) ? 
              <TableForm key={index} index={index} checked={checked} fName={fName} route={route} selectedRowKeys={selectedRowKeys} showSummary={false} /> 
              : 
              <TableForm key={index} index={index} checked={checked} fName={fName} route={route} selectedRowKeys={selectedRowKeys} showSummary={true} />
          })
        }
      </Form>
    </div>
  )

}

export const PriceEstimateFormTitle = (props:PropsType) =>{
  let auth = useAuth()
  return (
    <div className={"title-block"}>
      <p className={'step-title'}>{auth.user.hide_price_estimation ? 'Order Summary':'Price estimate'}</p>
    </div>
  )
};