import React from "react";
import { Link } from "react-router-dom";
import { Card, Row, Col, Breadcrumb, Image } from "antd";
import { getShopifyURL } from "../commons/utils/utilizer";
import { useLanguage } from "../languages/Language";
import shopify_logo from "../assets/images/shopify_logo.svg";
// import magento_logo from "../assets/images/magento_logo.svg";
// import woocommerce_logo from "../assets/images/woocommerce_logo.svg";
import "./styles/AddChannel.page.css";
import { UserSelectedAddIntegration } from "../commons/mixpanel";
import { NewShopifyChangesAlert } from "../dashboard/MessagesOnHomepage";

export const AddChannelPage = () => {
  let { getText } = useLanguage();
  // const auth = useAuth();
  // const [showModal, setShowModal] = useState<boolean>(false);
  // const [storeName, setStoreName] = useState<string|undefined>();
  // const [apiName, setApiName] = useState<string|undefined>();
  // const [apiKey, setApiKey] = useState<string|undefined>();
  // const [storeUrl, setStoreUrl] = useState<string|undefined>();
  // const handleCancel = () => {
  //   setShowModal(false)
  //   setApiKey(undefined)
  //   setApiName(undefined)
  //   setStoreName(undefined)
  //   setStoreUrl(undefined)
  // };
  // let hideApiIntegration = hideApi(auth.user.authToken);

  //Magento-WooCommerce API
  // const createStore:any = useMutation(async () => {
  //   let params:any = {
  //     store_type: storeName!.toUpperCase(),
  //     store_key: apiName,
  //     store_url: storeUrl
  //   }
  //   return createStoreToken(auth.user.authToken, params);
  // },  {
  //   onSuccess: (val:any) => {
  //     message.success("Successfully saved the store")
  //     setShowModal(false)
  //     setApiKey(undefined)
  //     setApiName(undefined)
  //     setStoreName(undefined)
  //     history.push(
  //       {
  //         pathname: '/integrations',
  //         search: '?active=apiintegration'
  //       }
  //     )
  //   },
  //   onError: (error:any) => {
  //     if(error.response.status === 500){
  //       message.error("Internal server error. Please try again after sometime")
  //     }
  //     else if(error.response.data) {
  //       if('store_key' in error.response.data) {
  //         message.error({content: "Store Name Error: " + error.response.data.store_key, key: '1', duration: 10})
  //       } else {
  //         message.error(error.response.data.message)
  //       }
  //     }
  //     else {
  //       message.error("Failed to save store")
  //     }
  //   },
  // });

  //API key
  // const createApi:any = useMutation(async () => {
  //   let params:any = {
  //     api_token: apiKey,
  //     api_name: apiName
  //   }
  //   return createApiToken(auth.user.authToken, params);
  // },  {
  //   onSuccess: (val:any) => {
  //     message.success("Successfully saved the api token")
  //     setShowModal(false)
  //     setApiKey(undefined)
  //     setApiName(undefined)
  //     history.push(
  //       {
  //         pathname: '/integrations',
  //         search: '?active=apiintegration'
  //       }
  //     )
  //   },
  //   onError: (error:any) => {
  //     if(error.response.data) {
  //       if('api_name' in error.response.data) {
  //         message.error({content: "API Name Error: " + error.response.data.api_name, key: '1', duration: 10})
  //       } else {
  //         message.error("Failed to save api token")
  //       }
  //     }
  //     else {
  //       message.error("Failed to save api token")
  //     }
  //   },
  // });

  // const onSaveButtonClick = () => {
  //   if(storeName === 'Magento' || 'WooCommerce'){
  //     createStore.mutate()
  //   }else{
  //     createApi.mutate()
  //   }
    
  // }

  // const onNameChange = (event:any) => {
  //   setApiName(event.target.value)
  // }

  // const onUrlChange = (event:any) => {
  //   setStoreUrl(event.target.value)
  // }

  // const generateApiKey = () => {
  //   let uuid = uuidv4();
  //   let apiKey = uuid.toUpperCase().replaceAll('-','');
  //   setApiKey(apiKey)
  // }

  // const disableButton = () => {
  //   if(storeName === 'Magento' && apiName && storeUrl){
  //     return false
  //   }else if(storeName === 'WooCommerce' && apiName && storeUrl){
  //     return false
  //   }else if(storeName === 'API key' && apiName){
  //     return false
  //   }else{
  //     return true
  //   }
  // }


  return (
    <>
      <NewShopifyChangesAlert />
      <h1>{getText("Add integration")}</h1>
      <Breadcrumb className="bread-crumbs">
        <Breadcrumb.Item>
          <Link to={`/integrations`}>
            <span>{getText("Integrations")}</span>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{getText("Add integration")}</Breadcrumb.Item>
      </Breadcrumb>
      <Row align="middle" justify="center">
        <Col span={24} className={"form-container"}>
          <Card className="user-setting-card-wrapper">
            <h2>Store Integration</h2>
            <p>Adding a store enables you to sync your orders and manage your preferences</p>
            <Row gutter={16}>
              <Col span={6}>
                <a href={getShopifyURL()} onClick={() => UserSelectedAddIntegration('Shopify')} style={{display: 'block'}}>
                  <Card
                    hoverable
                    className={"option-card user-setting-card-container shopify_logo-container"}
                    cover={
                      <Image
                        preview={false}
                        src={shopify_logo}
                        className={"shopify_logo"}
                      />
                    }
                  >
                    <Card.Meta title="Shopify" className={"custom-card-meta"} />
                  </Card>
                </a>
              </Col>
              {/* <Col span={6}>
                <a onClick={() =>{setStoreName('Magento'); setShowModal(true); UserSelectedAddIntegration('Magento')}} style={{display: 'block'}}>
                  <Card
                    hoverable
                    className={"option-card user-setting-card-container shopify_logo-container"}
                    cover={
                      <Image
                        preview={false}
                        src={magento_logo}
                        className={"magento_logo"}
                      />
                    }
                  >
                    <Card.Meta title="Magento" className={"custom-card-meta"} />
                  </Card>
                </a>
              </Col>
              <Col span={6}>
                <a onClick={() =>{setStoreName('WooCommerce'); setShowModal(true); UserSelectedAddIntegration('WooCommerce')}} style={{display: 'block'}}>
                  <Card
                    hoverable
                    className={"option-card user-setting-card-container shopify_logo-container"}
                    cover={
                      <Image
                        preview={false}
                        src={woocommerce_logo}
                        className={"woocommerce_logo"}
                      />
                    }
                  >
                    <Card.Meta title="WooCommerce" className={"custom-card-meta"} />
                  </Card>
                </a>
              </Col> */}
            </Row>

            {/* <Row gutter={16} style={{marginTop: '15px'}}>
              {!hideApiIntegration && <Col span={6}>
                <h2>API Integration</h2>
                <p>Janio Asia API allows you to integrate and manage your shipments into your system. Click <a href="/apidocs/create"
                  style={{color: '#050593',textDecoration: 'underline', fontSize: '16px',fontWeight:'bold'}} target="_blank" rel='noopener noreferrer'> {getText('here')}</a> for documentation.</p>
                <a href="#" onClick={() => {setStoreName('API key'); generateApiKey();setShowModal(true);}} style={{display: 'block', position: 'relative'}}>
                  <Card
                    hoverable
                    className={"option-card user-setting-card-container shopify_logo-container"}
                    cover={
                      <div className={"api-logo"}>
                        <SyncOutlined/>
                        <span> API Key</span>
                      </div>
                    }
                  >
                    <Card.Meta title="Manage API Integration" className={"custom-card-meta"} />
                  </Card>
                </a>
              </Col>}
              <Modal
                title={`${storeName} Integration`}
                width={600}
                closable={true}
                bodyStyle={{ height:'200px'}}
                onCancel={handleCancel}
                visible={showModal}
                className={'modal-right-footer'}
                footer={[
                  <div>
                    <div>
                      <Button className={storeName === 'Magento' || storeName === 'WooCommerce' ?'modal-button':'api-key-button'} type="primary" disabled={disableButton()} onClick={onSaveButtonClick} loading={createApi.isLoading || createStore.isLoading}>
                        {'Create'}
                      </Button>
                    </div>
                  </div>,
                ]}>
                {storeName === 'Magento' || storeName === 'WooCommerce'  ?
                  <div className={'magento-content'}>
                    <div>
                      <span>Store Key</span>
                      <Input value={apiName} onChange={onNameChange}/>
                    </div>
                    <div>
                      <span>Store URL</span>
                      <Input value={storeUrl} onChange={onUrlChange}/>
                    </div>
                    <div>
                      <span>Store Type</span>
                      <Input value={storeName} disabled={true}/>
                    </div>
                  </div> 
                  :
                  <div className={'api-key-content'}>
                    <div>
                      <span>API Name</span>
                      <Input value={apiName} onChange={onNameChange}/>
                    </div>
                    <div>
                      <span>Secret Token Key</span>
                      <Input value={apiKey} disabled={true}/>
                    </div>
                  </div>
                }                  
              </Modal>
            </Row> */}
          </Card>
        </Col>
      </Row>
    </>
  );
};
