import { Col, Row, Radio } from 'antd';
import { CaretLeftOutlined } from '@ant-design/icons'
import {Link} from "react-router-dom";
import { useAuth } from "../../../auth/services/authContext";
import { useLanguage } from '../../../languages/Language';

export const SubAccountSettingsView = () => {
  let { getText } = useLanguage();
  const auth = useAuth();

  return (
    <div>
      <Row align="middle" justify="center">
        <Col span={14}>
          <Link to={"/settings/"}>
            <p className="go-back-btn" style={{margin: '0px 0px -10px 0px'}}><CaretLeftOutlined/ > {getText('Back')} </p>
          </Link>
        </Col>
        <Col span={14} style={{marginTop:24}}>
          <div className={"single-order-form-container ant-form-vertical add-sub-account"}>
            <Col span={24}>
              <h3>{getText('Shipment Visibility')}</h3>
              <label>Will the master account be able to see your shipment on their portal?</label>
              <Radio.Group name="can_master_view_shipments" defaultValue={auth.user.can_master_view_shipments} disabled className={'radio-grp-add'}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Col>

            <Col span={24}>
              <label>Are you able to view the master account created shipment?</label>
              <Radio.Group name="can_view_master_shipments" defaultValue={auth.user.can_view_master_shipments} disabled className={'radio-grp-add'}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Col>

            <Col span={24}>
              <label>Are you able to view other sub-accounts created shipment?</label>
              <Radio.Group name="can_view_siblings_shipments" defaultValue={auth.user.can_view_siblings_shipments} disabled className={'radio-grp-add'}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Col>

            <Col span={24}>
              <label>Is price estimation hidden for you?</label>
              <Radio.Group name="hide_price_estimation" defaultValue={auth.user.hide_price_estimation} disabled className={'radio-grp-add'}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Col>
          </div>
        </Col>
      </Row>
    </div>
  )
};