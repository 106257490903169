/**
 * Download base64 attachment
 * @param {string} base64 Base64 string. If your string include mimetype `data:image/png;base64,iVBORw0KGg...`, please split it and pass string after comma (`iVBORw0KGg...`)
 * @param {string} filename Filename to be download
 * @param {string} mimetype Attachment mimetype
 */
const download = (base64: string, filename: string, mimetype: string) => {
  const link = document.createElement('a');
  link.href = `data:${mimetype};base64,${base64}`;
  link.download = filename;
  document.body.append(link);
  link.click();
  link.remove();
  URL.revokeObjectURL(link.href);
};

export default download;
