import { instance as api } from "../commons/api";
import { instanceForb2c as apib2c } from "../commons/api";

// Send OTP
export const onboardShopifytoB2C = async (params: any) => {
  let response = await apib2c.post('shopify/setup-shopify-channel/',
    params
  );
  return response.data
};

export const onboardShopify = async (
  token: string,
  code: string,
  host: string,
  hmac: string,
  shop: string,
  timestamp: string
): Promise<string> => {
  return await api
    .get(
      `channels/shopify/callback?code=${code}&hmac=${hmac}&host=${host}&shop=${shop}&timestamp=${timestamp}`,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    )
    .then((result) => {
      return "success";
    })
    .catch(function (error) {
      if (error.response.data && error.response.data.message) {
        // Something happened in setting up the request that triggered an Error
        return error.response.data.message;
      }
      else if (error.message){
        return error.message;
      }
      else {
        return "Something went wrong, please contact"
      }
    });
};

export const createApiToken = async (authToken: string, data:any) => {
  let response = await api.post(`auth/account-auth/`, data,
    {
      headers: {
        'Authorization': `Token ${authToken}`
      }
    });
  return response.data;
}

export const createStoreToken = async (authToken: string, data:any) => {
  let response = await api.post(`channels/api2cart/onboard-store/`, data,
    {
      headers: {
        'Authorization': `Token ${authToken}`
      }
    });
  return response.data;
}

export const deleteStore = async (token: string, channelUuid: string) => {
  let response = await api.delete(`channels/channels-edit/${channelUuid}/`,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }
  );
  return response.data;
};

export const editStore = async (token: string, channelUuid: string, storeKey: string) => {
  let response = await api.put(`channels/channels-edit/${channelUuid}/`,
    {
      'access_token': storeKey,
    },
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }
  );
  return response.data;
};
