import { Row, Col, Table, Button, 
  // Tabs, 
  Input, Modal, Radio, Divider,
  Form,
  message,
  Select,
} from 'antd';
import {
  // getStates, getCities, 
  getStates_from_b2c, getCities_from_b2c} from "../../orders/services/privateApi";
import { useState } from 'react';
import { useQuery, useMutation } from "react-query";
// import { 
//   Link, 
//   useLocation 
// } from 'react-router-dom';
import {
  // getDateInReadableFormat,
  capitalizeString,replaceString } from "../../commons/utils/utilizer";
import { useLanguage } from '../../languages/Language';
// import {DropoffSupportTooltip} from '../Components/DropoffSupportTooltip';
import { useAuth } from '../../auth/services/authContext';
import { 
  // getExceptionListQuery, 
  getExceptionByTrackingIdMutation, submitDisposition } from '../../orders/services/privateApi';
import moment from 'moment';
import * as _ from 'lodash';
import { dispositionOptions } from '../../commons/utils/constants';
import { errorExtract } from '../../orders/helpers';
import { UserConfirmedDispositionSubmission, UserRespondedToException } from '../../commons/mixpanel';
import { RadioChangeEvent } from 'antd/lib/radio';
// import { countryCodes } from '../../commons/utils/constants';

// const { TabPane } = Tabs;
const { Option } = Select;
const DispositionsMapping: any = {
  'redeliver order': 'Redeliver the parcel to new address',
  'return to original address': 'Return the parcel to original address',
  'return to new address': 'Return the parcel to new address',
  'dispose order': 'Dispose the parcel',
  Resume_Pickup: 'Resume order pickup',
  Cancel_Order: 'Cancel Order',
  'resume pickup': 'Resume order pickup',
  'cancel order': 'Cancel Order'
}
interface AddressInterface {
  consignee_address?: string,
  consignee_country?: string,
  consignee_state?: string,
  consignee_city?: string,
  consignee_postal?: any,
  consignee_name: string,
  consignee_email: string,
  consignee_phone: any,
}
const HoldOrder = (props:any) => {
  const auth = useAuth()
  // const location: any = useLocation()
  const [form] = Form.useForm();
  // const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [trackingNo, setTrackingNo] = useState('');
  const [orderId, setOrderId] = useState('');
  const [isModal, setIsModal] = useState(false)
  const [showAddressEqualModal, setShowAddressEqualModal] = useState(false)
  const [radioValue, setRadioValue] = useState('');
  const [current, setCurrent] = useState<number>(1)
  const [addressState, setAddresState] = useState<AddressInterface>({
    consignee_address: '',
    consignee_country: '',
    consignee_state: '',
    consignee_city: '',
    consignee_postal: '',
    consignee_name: '',
    consignee_email: '',
    consignee_phone: '',
  })
  const [confirmSubmit, setConfirmSubmit] = useState<boolean>(false);
  const [addressValues, setAddressValues] = useState<AddressInterface>({
    consignee_address: '',
    consignee_country: '',
    consignee_state: '',
    consignee_city: '',
    consignee_postal: '',
    consignee_name: '',
    consignee_email: '',
    consignee_phone: '',
  })
  const addressComparison = ['Redeliver Order', 'Return to new address'] 
  let { getText } = useLanguage();

  // const getExceptionList = useQuery(
  //   ["getexceptionList", page, pageSize, searchQuery, props.status === 'on_hold'],
  //   async () => {
  //     console.log('sasasdwss')
  //     let requestData = {
  //       secret_key: "OzOkA2iRmXVVfGuiFSI9ZqdZhfWku4um",
  //       // agent_application_ids: [auth.user.b2c_id],
  //       agent_application_ids: ["Hendra Test 555"],
  //       page_size: 100,
  //       page: 1
  //     }
  //     return getExceptionListQuery(auth.user.secret_key, requestData);
  //   }, {cacheTime: 0}
  // );

  const dispostionSubmitFn = useMutation(async ({token, tracking_no, param}:{token: string, tracking_no: any, param: any}) => {
    return submitDisposition(token, tracking_no, param)
  } ,  {
    onSuccess: (val: any) => {
      setIsModal(false)
      setTrackingNo('')
      setRadioValue('')
      setOrderId('')
      setConfirmSubmit(false)
      setAddresState({
        consignee_address: '',
        consignee_country: '',
        consignee_state: '',
        consignee_city: '',
        consignee_postal: '',
        consignee_name: '',
        consignee_email: '',
        consignee_phone: '',
      })
      props.getOrderDataQuery.refetch()
      message.success('successfully submitted disposition')
    },
    onError: (error: any) => {
      try{
        if(error.response.data.message) {
          const validJsonString = error.response.data.message
            .replace(/"/g, '@')
            .replace(/'/g, '"')
            .replace(/@/g, '\'');
          const errorMessage: Array<string> = JSON
            .parse(validJsonString)
          const errorDisplay = errorExtract(errorMessage)
          message.error({
            content: <ul style={{listStyle:'none'}}>{
              errorDisplay.map((disp: string)=> {
                return <li>{disp}</li>
              })}</ul>,
          })
        }else {
          message.error('We are facing some Issues, Please try after sometime.')
        }
      }catch(e){
        console.log(e)
        message.error('We are facing some Issues, Please try after sometime.')
      }
    },
  });

  const getExceptionByTrackingId = useMutation(async ({token, tracking_no, flag}:{token: string, tracking_no: any, flag: any}) => {
    return getExceptionByTrackingIdMutation(token, tracking_no, flag)
  } ,  {
    onSuccess: (val: any) => {
      setIsModal(true)
      setAddressValues({
        consignee_address: val?.consigneeAddress ?? '',
        consignee_country: val?.consigneeCountry ?? '',
        consignee_state: val?.consigneeState ?? '',
        consignee_city: val?.consigneeCity ?? '',
        consignee_postal: val?.consigneePostalCode ?? '',
        consignee_name: val?.consigneeName ?? '',
        consignee_email: val?.consigneeEmail ?? '',
        consignee_phone: val?.consigneePhoneNumber ?? '',
      })
      setAddresState({
        consignee_address: val?.consigneeAddress ?? '',
        consignee_country: val?.consigneeCountry ?? '',
        consignee_state: val?.consigneeState ?? '',
        consignee_city: val?.consigneeCity ?? '',
        consignee_postal: val?.consigneePostalCode ?? '',
        consignee_name: val?.consigneeName ?? '',
        consignee_email: val?.consigneeEmail ?? '',
        consignee_phone: val?.consigneePhoneNumber ?? '',
      })
    },
    onError: (error: any) => {
      if(error.response.data) {
        message.error(error.response.data.message)
      }else {
        message.error('We are facing some Issues, Please try after sometime.')
      }
    },
  })
  
  // const getExceptionByTrackingId = useQuery(
  //   ["getExceptionByTrackingId", trackingNo],
  //   async () => {
  //     return getExceptionByTrackingIdQuery(auth.user.secret_key, trackingNo);
  //   }, {cacheTime: 0, enabled: trackingNo.length > 0}
  // );

  const statesList = useQuery(['statesList', getExceptionByTrackingId.data?.consigneeCountry], () => {
    if(getExceptionByTrackingId.data?.consigneeCountry){
      return getStates_from_b2c(getExceptionByTrackingId.data?.consigneeCountry)
    }
  }, {enabled: getExceptionByTrackingId.data?.flag?.includes('wrong')});

  const cityList = useQuery(['cityList', addressState.consignee_state], () => {
    if(addressState.consignee_state){
      return getCities_from_b2c(addressState?.consignee_country!, addressState.consignee_state)
    }
  });

  // const onChange = (key: string) => {
  //   console.log(key);
  //   // getExceptionList.refetch()
  // };

  const onRadioButtonChange = (e: RadioChangeEvent) => {
    // setAddresState({
    //   consignee_address: '',
    //   consignee_country: getExceptionByTrackingId.data?.consigneeCountry,
    //   consignee_state: '',
    //   consignee_city: '',
    //   consignee_postal: '',
    // })
    setRadioValue(e.target.value);
    form.resetFields()
  };

  // const resetFilters = () => {
  //   props.setSearchQuery('');
  // }

  const handleOk = () => {
    // setIsModal(false);
    // setConfirmSubmit(true)
    form.validateFields()
      .then(() => {
        if(addressComparison.includes(radioValue)){
          _.isEqual(addressValues, addressState) ? setShowAddressEqualModal(true) : setShowAddressEqualModal(false);
        }
        
        setConfirmSubmit(true)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const submitData = () => {
    let metaData;
    if(radioValue.toLowerCase() === 'redeliver order') {
      // metaData = {new_address: `${addressState.consignee_address}, ${addressState.consignee_country}, ${addressState.consignee_state}`}
      metaData = {
        consignee_country: addressState.consignee_country,
        consignee_address: addressState.consignee_address,
        consignee_state: addressState.consignee_state,
        consignee_city: addressState.consignee_city,
        consignee_postal: addressState.consignee_postal,
      }
    }else if(radioValue.toLowerCase() === 'return to new address'){
      metaData = {
        consignee_country: addressState.consignee_country,
        consignee_address: addressState.consignee_address,
        consignee_state: addressState.consignee_state,
        consignee_city: addressState.consignee_city,
        consignee_postal: addressState.consignee_postal,
        consignee_name: addressState.consignee_name,
        consignee_email: addressState.consignee_email,
        consignee_number: addressState.consignee_phone
      }
    }
    else {
      metaData = {}
    }
    let requestBody = {
      flag: getExceptionByTrackingId.data?.flag,
      disposition: radioValue,
      metadata: metaData
    }
    dispostionSubmitFn.mutate({token: auth.user.secret_key, tracking_no: orderId, param: requestBody})
    UserConfirmedDispositionSubmission(radioValue)
  }
  const handleCancel = () => {
    setConfirmSubmit(false)
  };

  const onCloseModal = () => {
    setIsModal(false);
    setTrackingNo('');
    setOrderId('')
    setRadioValue('')
    setConfirmSubmit(false)
    setAddresState({
      consignee_address: '',
      consignee_country: '',
      consignee_state: '',
      consignee_city: '',
      consignee_postal: '',
      consignee_name: '',
      consignee_email: '',
      consignee_phone: '',
    })
  }

  const respondNowOnclick = (trackingNo: any, flag: any, order_id: string) => {
    props.setSearchQuery('')
    setTrackingNo(trackingNo)
    setOrderId(order_id)
    getExceptionByTrackingId.mutate({token: auth.user.secret_key, tracking_no: trackingNo, flag: flag})
    UserRespondedToException(flag)
  }

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
    },
  };

  const showConfirmSection = () => {
    if(radioValue.toLowerCase() === 'redeliver order' || radioValue.toLowerCase() === 'return to new address') {
      return (
        <>
          <h3><span>{`${DispositionsMapping[radioValue.toLowerCase()]}: ${addressState.consignee_address}, ${addressState.consignee_city}, ${addressState.consignee_state}, ${addressState.consignee_country}, ${addressState.consignee_postal}`} </span></h3>
        </>
      )
    }
    if(radioValue.toLowerCase() === 'return to original address') {
      return (
        <h3>
          <span>{`${DispositionsMapping[radioValue.toLowerCase()]}: ${getExceptionByTrackingId.data?.pickupAddress
          }, ${getExceptionByTrackingId.data?.pickupCity
          }, ${getExceptionByTrackingId.data?.pickupState}, ${getExceptionByTrackingId.data?.pickupCountry}, ${getExceptionByTrackingId.data?.pickupPostalCode}`}</span>
        </h3>
      )
    }
    return (
      <>
        <h3><span>{`${DispositionsMapping[radioValue.toLowerCase()]}`}</span></h3>
      </>
    )
  }

  const showOptionsOnRadioSelect = () => {
    if(radioValue.toLowerCase() === 'redeliver order' || radioValue.toLowerCase() === 'return to new address') {
      return (
        <>
          <Divider />
          <h3>Current Consignee Address
            <span>{`${getExceptionByTrackingId.data?.consigneeAddress!}, ${getExceptionByTrackingId.data?.consigneeCity
            }, ${getExceptionByTrackingId.data?.consigneeState}, ${getExceptionByTrackingId.data?.consigneeCountry}, ${getExceptionByTrackingId.data?.consigneePostalCode}`}</span>
          </h3>

          <h3>Update New Address</h3>
          <Form
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            className='exception-update-form'
            layout={'vertical'}
            validateMessages={validateMessages}
            // initialValues={{ size: componentSize }}
            // style={{ maxWidth: 600 }}
          >
            <Row gutter={[16, 35]}>
              <Col span={24} className='address-type'>
                {/* <span>Address<strong style={{color: 'red', fontSize: '16px'}}>*</strong></span> */}
                <Form.Item  rules={[{ required: true, }]}
                  name="consignee_address"
                  label="Address"
                  initialValue={addressState.consignee_address}
                >
                  <Input placeholder='Please enter new address' value={addressState.consignee_address} onChange={
                    (event: any) => {
                      setAddresState({
                        ...addressState,
                        consignee_address: event.target.value
                      })
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            {
              radioValue.toLowerCase() === 'return to new address' && 
              <Row gutter={[16, 35]}>
                <Col span={24}>
                  {/* <span>Address<strong style={{color: 'red', fontSize: '16px'}}>*</strong></span> */}
                  <Form.Item  rules={[{ required: true,}]}
                    name="consignee_name"
                    label="Name"
                    initialValue={addressState.consignee_name}
                  >
                    <Input placeholder='Name' value={addressState.consignee_name} onChange={
                      (event: any) => {
                        setAddresState({
                          ...addressState,
                          consignee_name: event.target.value
                        })
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            }
            {
              radioValue.toLowerCase() === 'return to new address' && 
              <Row gutter={[16, 35]}>
                <Col span={24}>
                  {/* <span>Address<strong style={{color: 'red', fontSize: '16px'}}>*</strong></span> */}
                  <Form.Item  rules={[{ required: true, type: 'email'}]}
                    name="consignee_email"
                    label="Email"
                    initialValue={addressState.consignee_email}
                  >
                    <Input placeholder='Email' value={addressState.consignee_email} onChange={
                      (event: any) => {
                        setAddresState({
                          ...addressState,
                          consignee_email: event.target.value
                        })
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            }
            {
              radioValue.toLowerCase() === 'return to new address' && 
              <Row gutter={[16, 35]}>
                <Col span={24}>
                  {/* <span>Address<strong style={{color: 'red', fontSize: '16px'}}>*</strong></span> */}
                  <Form.Item  rules={[{ required: true, pattern: new RegExp(/^[+]?[0-9]+$/), message: 'Enter valid Phone number' }]}
                    name="consignee_phone"
                    label= "Phone Number"
                    initialValue={addressState.consignee_phone}
                  >
                    <Input placeholder='Phone' value={addressState.consignee_phone} onChange={
                      (event: any) => {
                        setAddresState({
                          ...addressState,
                          consignee_phone: event.target.value
                        })
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            }
            <Row gutter={[20, 35]}>
              <Col span={12}>
                {/* <span><strong style={{color: 'red', fontSize: '16px'}}>*</strong></span> */}
                <Form.Item  rules={[{ required: true, }]}
                  name="consignee_country"
                  label="Country"
                  initialValue={addressState.consignee_country}
                >
                  <Select onChange={(value: any) => {
                    setAddresState({
                      ...addressState,
                      consignee_country: value
                    })
                  }}
                  value={addressState.consignee_country}
                  disabled={true}
                  >
                    {/* <Select.Option value="demo">Demo</Select.Option> */}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item  rules={[{ required: true, }]}
                  name="consignee_state"
                  label="State"
                  initialValue={addressState.consignee_state}
                >
                  <Select
                    placeholder="Select state"
                    value={addressState.consignee_state!}
                    onChange={(value: any) => {
                      setAddresState({
                        ...addressState,
                        consignee_state: value
                      })
                    }}
                    // allowClear
                  >
                    {
                      statesList.data?.map((item: any) => {
                        return <Option key={item.id} value={item.state_name}>{item.state_name}</Option>
                      })
                    }
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[20, 35]}>
              <Col span={12}>
                <Form.Item  rules={[{ required: true, }]}
                  name="consignee_city"
                  label="City"
                  initialValue={addressState.consignee_city}
                >
                  <Select
                    placeholder="Select city"
                    value={addressState.consignee_city!}
                    onChange={(value: any) => {
                      setAddresState({
                        ...addressState,
                        consignee_city: value
                      })
                    }}
                  >
                    {
                      cityList.data?.map((item: any) => {
                        return <Option key={item.id} value={item.city_name}>{item.city_name}</Option>
                      })
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item  rules={[{ required: true, pattern: new RegExp(/^[0-9]+$/), message: 'Enter valid Postal Code' }]}
                  name="consignee_postal"
                  label="Postal Code"
                  initialValue={addressState.consignee_postal}
                >
                  <Input name={'consignee_postal'} value={addressState.consignee_postal} defaultValue={getExceptionByTrackingId.data?.consigneeCountry! === 'Hong Kong' ? '000000' : ''} placeholder='Enter valid postal code' onChange={(event: any) => {
                    setAddresState({
                      ...addressState,
                      consignee_postal: event.target.value
                    })
                  }}/>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      )
    }
    if(radioValue.toLowerCase() === 'return to original address') {
      return (
        <>
          <Divider />
          <h3>{'Return the Parcel to Original Address'}
            <span>{`${getExceptionByTrackingId.data?.pickupAddress!}, ${getExceptionByTrackingId.data?.pickupCity
            }, ${getExceptionByTrackingId.data?.pickupState}, ${getExceptionByTrackingId.data?.pickupCountry}, ${getExceptionByTrackingId.data?.pickupPostalCode}`}</span>
          </h3>
        </>
      )
    }
    if(radioValue.toLowerCase() === 'dispose order'){
      return (
        <>
          <Divider />
          <h3>{'Dispose the Parcel'}
          </h3>
        </>
      )
    }
    return null
  }
  const columns = [
    {
      title: getText('Shipper Order Id'),
      dataIndex: 'shipper_order_id',
      key: 'shipper_order_id',
      render: (shipper_order_id: any) => {
        return shipper_order_id ? shipper_order_id : 'N/A'
      }
    },
    {
      title: 'Janio Tracking number',
      dataIndex: 'janio_tracking_number',
      key: 'janio_tracking_number',
      render: (janio_tracking_number: any) => {
        return janio_tracking_number ? janio_tracking_number : 'N/A'
      }
    },
    {
      title: 'Issue',
      dataIndex: 'flag',
      key: 'flag',
      render: (flag: string) => {
        if(flag){
          let replacedString = replaceString(flag,'_'," ")
          return capitalizeString(replacedString);
        }
      }
    },
    {
      title: 'Order Status',
      dataIndex: 'public_status',
      key: 'public_status',
      render: (public_status: any) => {
        return public_status
      }
    },
    {
      title: 'Due Date to Response',
      dataIndex: 'due_date_to_response_epoch',
      key: 'due_date_to_response_epoch',
      render: (due_date_to_response_epoch: any) => {
        return moment.unix(parseInt(due_date_to_response_epoch)).format('YYYY-MM-DD, h:mm A')
      }
    },
    {
      title: 'Default Action After Due Date',
      dataIndex: 'default_disposition',
      key: 'default_disposition',
      render: (default_disposition: any) => {
        return default_disposition
      }
    },
    {
      title: 'Action',
      dataIndex: '',
      key: '',
      fixed: 'right' as 'right',
      // width: 100,
      render: (key:any, allData:any) => (
        <Button type='primary' key={allData.janio_tracking_number!} loading={getExceptionByTrackingId.isLoading && (allData.janio_tracking_number === trackingNo)} onClick={() => {respondNowOnclick(allData.janio_tracking_number, allData.flag, allData.order_id)}}>Respond Now</Button>
      ),
    }
  ];

  const onSwitchPage = (page:any, pageSize: any) => {
    setCurrent(page)
    props.setPageSize(pageSize)
    props.setPage(page)
    props.page < page ? props.setCursor(props.data?.meta?.after) : props.setCursor(props.data?.meta?.before) 
  }

  const onPageSizeChange = (current:any, pageSize:any) => {
    setPageSize(pageSize)
  }

  // const onChangeHandler = (value:any) => {
  //   props.setCursor('')
  //   props.setSearchQuery(value)
  //   setCurrent(1)
  // }

  return (
    <Col span={24} className='mt-85 for-delivered-bulk'>
      {/* <Col span={6}>
        <Space direction="horizontal"  style={{width: '100%', marginBottom: '13px'}}>
          <Input id="searchId" placeholder={'Tracking Number'} value={props.searchQuery} 
            onChange={
              (event) => onChangeHandler(event.target.value)
            }
            suffix={<SearchOutlined style={{fontSize: '18px', color: '#808080'}}/>}
          />
          <Button type='primary' onClick={() => resetFilters()}>Reset</Button>
        </Space>
      </Col> */}
      <Table
        className='pagination-select-wrapper'
        rowClassName='table_body'
        rowKey="janio_tracking_number"
        dataSource={props.data?.data!}
        columns={columns}
        loading={props.getOrderDataQuery.isLoading || props.getOrderDataQuery.isFetching}
        scroll={{ x: 1300 }}
        pagination={{
          onChange: onSwitchPage,
          position:['bottomRight'],
          defaultPageSize: pageSize,
          pageSize: pageSize,
          total: props.data?.meta?.count!,
          showSizeChanger: true,
          onShowSizeChange: onPageSizeChange,
          pageSizeOptions: ['10', '20', '50', '100', '300'],
          simple: true,
          current: current
        }}
      />
      <Modal 
        title={`Response to ${getExceptionByTrackingId.data?.janio_tracking_number}, ${getExceptionByTrackingId.data?.shipper_order_id}`} 
        visible={isModal} 
        onOk={handleOk} 
        onCancel={onCloseModal}
        className={'modal-right-footer exception-disposition-wrapper'}
        width={700}
        footer={[
          <Button 
            onClick={onCloseModal}
            key={'close'}
          >{"Close"}</Button>,
          <Button 
            disabled={!confirmSubmit}
            onClick={handleCancel}
            key={'cancel'}
          >{getText("Back")}</Button>,
          <Button 
            onClick={confirmSubmit ? submitData : handleOk}
            type="primary"
            loading={dispostionSubmitFn.isLoading}
            disabled={!radioValue.length}
            key={'submit'}
          >{confirmSubmit ? 'Submit Response' : 'Confirm'}
          </Button>
        ]}
      >
        <div className='exception-dispositions'>
          <h3>Shipper Order ID, Janio Tracking Number <span>{`${getExceptionByTrackingId.data?.shipper_order_id}, ${getExceptionByTrackingId.data?.janio_tracking_number}`}</span></h3>
          <h3>Issue <span>{getExceptionByTrackingId.data?.flag?.replaceAll('_', ' ')}</span></h3>

          <h3>Response</h3>

          {confirmSubmit ? showConfirmSection() : 
            <Radio.Group onChange={onRadioButtonChange} value={radioValue} style={{width: '100%'}}>
              {
                getExceptionByTrackingId.data?.disposition?.map((item: any) => {
                  return <p>
                    <Radio value={item.id} key={item.id}>{dispositionOptions[`${item.label}`] ?? item.label}</Radio>
                  </p>
                })
              }
              {showOptionsOnRadioSelect()}
            </Radio.Group>
          }
        </div>
      </Modal>
      <Modal 
        title={`Same address alert !!`} 
        visible={showAddressEqualModal}  
        className={'modal-right-footer exception-disposition-wrapper'}
        width={700}
        onCancel={()=>{setShowAddressEqualModal(false); handleCancel()}}
        footer={[
          <Button 
            onClick={()=>{setShowAddressEqualModal(false); handleCancel()}}
            key={'cancel'}
          >{getText("Back")}</Button>,
          <Button 
            onClick={()=>setShowAddressEqualModal(false)}
            type="primary"
            key={'submit'}
          >{'Continue'}
          </Button>
        ]}
      >
        <div>Current consignee address and updated consignee address are same. Do you wish to continue?</div>
      </Modal>
    </Col>
  )
};

export default HoldOrder