import { useState, useContext, createContext, ReactNode } from "react"
import { instance as api } from '../../commons/api'
import { message } from 'antd';
// import {profile as getProfile} from "./authApi";

interface AuthContext {
  user: User
  signIn: (email: string, password: string) => any
  signOut: () => any
  setUserSession: (val:any) => any
}

export interface User {
  isSignedIn: boolean
  authToken: string
  b2c_id: string
  email: string
  parent_email: string
  fullName: string
  phoneNumber: string
  billingCountry: string
  allowPostpaid: boolean
  tnc:boolean
  payment_type: string
  hide_price_estimation: boolean
  account_type: string
  secret_key: string
  master_b2c_id: string,
  can_view_master_shipments: boolean
  can_master_view_shipments: boolean
  can_view_siblings_shipments: boolean
}

const authContext = createContext<AuthContext>({} as AuthContext)

export const ProvideAuth = ({children}: {children: ReactNode}) => {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(authContext);
}

const AUTH_STORAGE_KEY = 'AUTH_SESSION'

const useProvideAuth = () => {
  const [user, setUser] = useState<User>(() => {
    const storage = localStorage.getItem(AUTH_STORAGE_KEY);
    if (storage !== null){
      // Transition layer on the local session, after renaming the session key
      const storedSession = JSON.parse(storage)
      if (!storedSession.fullName){
        storedSession.fullName = storedSession.full_name
        localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(storedSession))
      }

      return storedSession
    }

    return {
      isSignedIn: false,
      authToken: '',
      b2c_id: '',
      email: '',
      parent_email: '',
      fullName: '',
      phoneNumber: '',
      billingCountry: '',
      allowPostpaid: false,
      tnc: false,
      payment_type: '',
      hide_price_estimation: false,
      can_view_master_shipments: false,
      can_master_view_shipments: false,
      can_view_siblings_shipments: false,
      account_type: '',
      secret_key: '',
      master_b2c_id: ''
    }
  })

  const setUserSession = (session: User) => {
    setUser(session);
    localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(session))
  }

  const signIn = async (email: string, password: string) => {
    let response = await api.post('auth/token/login/', {
      email: email, password: password
    })
    setUserSession({
      isSignedIn: true,
      authToken: response.data.auth_token,
      b2c_id: response.data.b2c_id,
      email: response.data.email,
      parent_email: response.data.parent_email,
      fullName: response.data.full_name,
      phoneNumber: response.data.phone_number,
      billingCountry: response.data.billing_country,
      allowPostpaid: response.data.allow_postpaid,
      tnc: response.data.tnc,
      payment_type: response.data.payment_type,
      account_type: response.data.account_type,
      hide_price_estimation: response.data.hide_price_estimation,
      can_view_master_shipments: response.data.can_view_master_shipments,
      can_master_view_shipments: response.data.can_master_view_shipments,
      can_view_siblings_shipments: response.data.can_view_siblings_shipments,
      secret_key: response.data.secret_key,
      master_b2c_id: response.data.master_b2c_id

    })
    return response.data
  }

  const signOut = () => {
    setUserSession({
      isSignedIn: false,
      authToken: '',
      b2c_id: '',
      email: '',
      fullName: '',
      phoneNumber: '',
      billingCountry: '',
      allowPostpaid: false,
      tnc:false,
      payment_type: '',
      account_type: '',
      secret_key: '',
      master_b2c_id: '',
      hide_price_estimation: false,
      can_view_master_shipments: false,
      can_master_view_shipments: false,
      can_view_siblings_shipments: false,
      parent_email: '',
    })
    localStorage.clear()
  }

  return {
    user,
    signIn,
    signOut,
    setUserSession
  }
};

const onClose = () => {
  let locationCheck = window.location.href
  localStorage.removeItem('AUTH_SESSION')
  if(locationCheck.includes('auth/activation')) {
    return 
  }
  window.location.href = `${locationCheck}`;
}

export const signOutForDisabledAccount = () => {
  message.error({content: 'You do not have permission to perform this action.', key: '1', duration: 5, onClose: onClose})
}

// export const refreshUserSession = (currentSession:any) => {
//   getProfile(currentSession.user.authToken)
//     .then((response) => {
//       let session:User = {
//         isSignedIn: true,
//         authToken: response.auth_token,
//         b2c_id: response.auth.b2c_id,
//         email: response.email,
//         fullName: response.full_name,
//         phoneNumber: response.phone_number,
//         billingCountry: response.billing_country,
//         allowPostpaid: response.allow_postpaid,
//         tnc: response.tnc,
//         payment_type: response.payment_type,
//         account_type: response.account_type,
//         secret_key: response.secret_key,
//         master_b2c_id: response.master_b2c_id,
//         hide_price_estimation: response.hide_price_estimation,
//         can_view_master_shipments: response.can_view_master_shipments
//       }
//       currentSession.setUserSession(session)
//     })
//     .catch(error => {
//       message.destroy()
//       message.error({content: "Failed to update the session",OnClose:onClose})
//       currentSession.signOut();
//     })
// }

export const isSessionCurrent = (session:any) => {
  let sessionProperties = ["isSignedIn","authToken","b2c_id","email","fullName","phoneNumber","billingCountry","allowPostpaid","tnc", "payment_type", "hide_price_estimation", "account_type", "secret_key", "master_b2c_id", "can_view_master_shipments"].sort()
  let currentSessionProperties = Object.keys(session).sort();
  return JSON.stringify(sessionProperties)===JSON.stringify(currentSessionProperties)?true:false;
}

export const allowedFeatures = {
  shopify: [
    "bhaskar.rao@janio.asia",
    "florance.arogyaswamy+1@janio.asia",
    "florance.arogyaswamy@janio.asia",
    "hello@basepiece.com",
    "jackson.lim+1@janio.asia",
    "meg.bits@gmail.com",
    "megha.bhardwaj+1@janio.asia",
    "missence08@gmail.com",
    "nailwonderlandsg@gmail.com",
    "ryan.chong+qa@janio.asia",
    "sales@babybunnies.co",
    "shafiq@meka3dp.com",
    "support@soslickpomade.com",
  ],
};
