import React, { useState } from 'react'
import {Card, Col, Row, Steps} from 'antd';
import { InformationCheckForm, InformationCheckFormTitle } from './components/singleOrder/InformationCheckForm';
import { Link } from 'react-router-dom';
import { CaretLeftOutlined } from '@ant-design/icons';
import { PickupDetailsFormTitle, PickupDetailsForm } from './components/singleOrder/PickupDetailsForm';
import { ReceiverDetailsForm, ReceiverDetailsFormTitle } from './components/singleOrder/ReceieverDetailsForm';
import { ItemDetailsForm, ItemDetailsFormTitle } from './components/singleOrder/ItemDetailsForm';
import {SingleOrderContextProviderB2C} from './services/returnSingleOrderContext';
import { PriceEstimateForm, PriceEstimateFormTitle } from './components/singleOrder/PriceEstimateForm';
import './styles/ReturnOrderForm.css';
const {Step} = Steps;

const OrderForm = () => {
  const [current, setCurrent] = useState(0)
  const setCurrentState= (value: number) => {
    setCurrent(value)
  }
  const steps = [
    {
      title: <InformationCheckFormTitle current={current} setCurrentState={setCurrentState}/>,
      content: <InformationCheckForm current={current} setCurrentState={setCurrentState}/>,
    },
    {
      title: <PickupDetailsFormTitle current={current} setCurrentState={setCurrentState}/>,
      content: <PickupDetailsForm current={current} setCurrentState={setCurrentState}/> ,
    },
    {
      title: <ReceiverDetailsFormTitle current={current} setCurrentState={setCurrentState}/>,
      content: <ReceiverDetailsForm current={current} setCurrentState={setCurrentState}/>,
    },
    {
      title: <ItemDetailsFormTitle current={current} setCurrentState={setCurrentState}/>,
      content: <ItemDetailsForm current={current} setCurrentState={setCurrentState}/>,
    },
    {
      title: <PriceEstimateFormTitle current={current} setCurrentState={setCurrentState}/>,
      content: <PriceEstimateForm current={current} setCurrentState={setCurrentState}/>
    }
  ]

  return (
    <>
      <Steps direction='vertical'  current={current}>
        {steps.map((item, index) => (
          <Step title= {item.title} key= {index} 
            description = {current>=index? item.content : ''} />
        ))}
      </Steps>
    </>
  )
}
const ReturnSingleOrder = () => {
  return (
    <Row align='middle' justify='center'>
      <Col span={16} className={'order-form-container'}>
        <Link to={"/orders/create"}>
          <p className="go-back-btn"><CaretLeftOutlined/> Create shipment </p>
        </Link>
        <Card className={'form-card'}>
          <SingleOrderContextProviderB2C>
            <OrderForm/>
          </SingleOrderContextProviderB2C>
        </Card>
      </Col>
    </Row>
  )
}

export default ReturnSingleOrder