import { Row, Tabs, Tag, Spin, Col, Divider, Breadcrumb, 
  // Button, Menu, Dropdown, message, 
  Alert,
  // Popconfirm 
} from 'antd';
import '../styles/ManageShipments.css'
import { useAuth } from '../../auth/services/authContext';
import { useQuery } from "react-query";
import { fetchOrder_from_b2c } from '../../orders/services/privateApi';
import { Link, useParams } from 'react-router-dom';
// import { DownOutlined } from '@ant-design/icons';
import { getOrderTrackers, 
  getOrderLogs, 
  // getPaymentDetailsForOrder 
} from '../services'
import {
  getDateInReadableFormat
} from "../../commons/utils/utilizer";
// import {fetchOrderRate} from '../../orders/services/privateApi';
import {IndonesiaIdentificationTypes, countryCodeCurrencies, countryCodes} from "../../commons/utils/constants";
import { useLanguage } from '../../languages/Language';
import {contactCustomerService} from "../../commons/utils/constants";
import { useEffect } from 'react';
import { UserViewedViewShipmentDetailsPage } from '../../commons/mixpanel';

const { TabPane } = Tabs;

// interface OrderBill {
//   bill_payment: any
//   created_at: string
//   payment_method: {
//     gateway: string
//     gateway_payment_method_id: string
//     id: number
//     name: string
//   }
//   status: string
//   updated_at: string
// }
// interface WalletTransaction {
//   amount: string
//   cashflow_type: string
//   created_at: string
//   description: string
//   id: number
//   transaction_id: string
//   transaction_type: string
//   updated_at: string
//   wallet: number
// }
// interface PaymentDetailInterface {
//   amount: string
//   currency: string
//   order_bill: OrderBill
//   wallet_transaction: WalletTransaction
// }

export const ViewShipmentDetail = (props:any) => {
  const isProduction = window.location.hostname.includes('ss.janio.asia')
  const auth = useAuth();
  const orderID = useParams<any>()
  // const authToken = auth.user.authToken;
  const { getText } = useLanguage();
  
  const getSingleOrderDataDetails = useQuery(['getSingleOrderDataForViewShipment', orderID.id], async () => {
    return fetchOrder_from_b2c(orderID.id, auth.user.secret_key)
  })
  const getOrderTrack = useQuery(['getOrderTrackForViewShipment', orderID.id], async () => {
    return getOrderTrackers(orderID.id, auth.user.secret_key)
  })

  // const getPaymentDetails = useQuery(['getPaymentDetailsForViewShipment', orderID.id], async () => {
  //   return getPaymentDetailsForOrder(authToken, orderID.id)
  // })

  // const getOrderRateData = useQuery(['getOrderRateData', orderID.id], async () => {
  //   return fetchOrderRate(authToken, orderID.id)
  // })

  const getOrderLogActivity = useQuery(['getOrderLogsForViewShipment',  orderID.id], async () => {
    return getOrderLogs(getSingleOrderDataDetails.data![0].tracking_no, auth.user.secret_key)
  }, { 
    enabled: (!!getSingleOrderDataDetails.data?.length && !isProduction),
    cacheTime: 0
  })

  // const orderCancellation:any = useMutation(async (token:string) => {
  //   return orderCancel(authToken, orderID.id);
  // },  {
  //   onSuccess: (val) => {
  //     // history.push('/success')
  //     message.success(getText('Order Cancelled successfully'));
  //   },
  //   onError: (error: any) => {
  //     let errorMessage = error.response.data
  //     message.error(`${errorMessage.error ? errorMessage.error : '' } ${getText("Failed to cancel shipment, this shipment can't be cancelled")}`, 12);
  //   },
  // });

  // const cancelOrder = () => {
  //   orderCancellation.mutate()
  // }

  // console.log('getSingleOrderDataDetails', getSingleOrderDataDetails?.data![0])
  // const menu = (
  //   <Menu >
  //     <Menu.Item key="0" style={{'padding': '0','margin': '-5px'}}>
  //       <Button
  //         type="text"
  //         // onClick={cancelOrder}
  //         style={{'fontSize': '12px'}}
  //         disabled={!getSingleOrderDataDetails.data?.is_editable || getSingleOrderDataDetails.data?.origin === 'B2C'}
  //       >
  //         <Popconfirm 
  //           title={'Are you sure you want to cancel this Shipment'}
  //           okText="Yes" 
  //           placement="bottomRight"
  //           cancelText="No" 
  //           onConfirm={cancelOrder}
  //           onCancel={() => null}
  //         >
  //           <a href="#">Cancel Shipment</a>
  //         </Popconfirm>
  //       </Button>
  //     </Menu.Item>
  //   </Menu>
  // );

  // if(orderCancellation.isLoading) {
  //   return <Spin style={{'position': 'fixed','left': '50%', 'top': '50%'}} />
  // }

  useEffect(() => {
    UserViewedViewShipmentDetailsPage(auth.user.fullName)
  },[auth.user.fullName])

  return (
    <>
      {(getSingleOrderDataDetails.isSuccess && getOrderTrack.isSuccess) ?
        <>
          <Row>
            <Col span={18}>
              <Breadcrumb className="bread-crumbs">
                <Breadcrumb.Item>
                  <Link to={`/orders`} >
                    {getText('Manage Shipments')}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{getSingleOrderDataDetails.data![0].tracking_no}</Breadcrumb.Item>
              </Breadcrumb>
            </Col>

            <Col span={6}>
              <Row align="middle" gutter={[16,16]} style={{justifyContent: 'right', paddingRight: '20px'}}>
                <Col>
                  {
                    getSingleOrderDataDetails.data![0].order_label_url ?
                      <a href={`${getSingleOrderDataDetails.data![0].order_label_url}`} target="_blank" rel="noopener noreferrer" className={'print-enabled'}>{getText('Print Label')}</a>
                      : <a className={'print-disabled'}>{getText('Print Label')}</a>
                  }
                </Col>

                {/* {
                  // auth.user.payment_type === 'PayOnMonthlyInvoice' &&
                  auth.user.authToken === 'e338b185c4018ee60db449dddd0286b4f80df078' && 
                  <Col>
                    <Dropdown overlay={menu} trigger={['click']}>
                      <a className="ant-dropdown-link" style={{'color': '#050593'}} onClick={e => e.preventDefault()}>
                        {getText('More actions')} <DownOutlined />
                      </a>
                    </Dropdown>
                  </Col>
                } */}
              </Row>
            </Col>

            {
              !getSingleOrderDataDetails.data![0].order_label_url && <Col span={24}>
                <Alert 
                  message={
                    <div>
                      <span style={{fontSize: '14px', color: '#000'}}>Please try again after 10 minutes as the shipment labels are still being processed. If the issue persists, contact us at {contactCustomerService()} for updates on the availability of the labels.</span>
                    </div>
                  }
                  type="warning"
                  showIcon
                  className='gst-related'
                />
              </Col>
            }
          </Row>


          <Row >
            <Col span={18}>
              <Row justify="space-between" align="middle">
                <Col span={18}>
                  <h1 style={{marginBottom: '20px'}}>
                    {getSingleOrderDataDetails.data![0].tracking_no}
                    <Tag color="success" className="view-shipment-tag-adjust">{getText('Created')} </Tag>
                    {/* {getSingleOrderDataDetails.data.origin === 'B2C' && <Tag color="volcano" className="view-shipment-tag-adjust">{getText('Imported')} </Tag>} */}
                  </h1>
                </Col>

                {/* Hiding for now */}
                {/* {
                  getSingleOrderDataDetails.data.is_editable && getSingleOrderDataDetails.data.order_direction! !== 'RETURNS' ?
                    <Col span={6} style={{'textAlign': 'right','paddingRight': '16px'}}>
                      <Link to={`/orders/pay/single/${orderID.id}`} >
                        <Button
                          type="text"
                          style={{'fontSize': '12px', 'color': '#050593','fontWeight': 600}}
                        >
                          {getText("Edit Order")}
                        </Button>
                      </Link>
                    </Col> : null
                } */}

              </Row>
            </Col>
          </Row>
          <Row justify="space-between" style={{'paddingRight': '20px'}}>
            <Col span={18} className={'view-shipment-main-container view-shipment'} id="view-shipment">
              <Tabs defaultActiveKey="0" animated={{ inkBar: true, tabPane: true }}>
                <TabPane tab={getSingleOrderDataDetails.data![0].service_category !== 'dropoff' ? `${getText('Pickup Details')}`: 'Drop-off Details'} key="0">
                  <>
                    <h4>{getSingleOrderDataDetails.data![0].service_category !== 'dropoff' ? 'Pickup Details' : 'Drop-off Details'}</h4>
                    <Row className="view-shipment-info-row">
                      <Col span={4} className="view-shipment-info-key">
                        {getText('Picked up Date')}:
                      </Col>
                      <Col span={6}>
                        {getSingleOrderDataDetails.data![0].pickup_date}
                      </Col>
                    </Row>
                    <Row className="view-shipment-info-row">
                      <Col span={4} className="view-shipment-info-key">
                        {getText('Full Name')}:
                      </Col>
                      <Col span={6}>
                        {getSingleOrderDataDetails.data![0].pickup_contact_name}
                      </Col>
                    </Row>
                  </>
                  <Row className="view-shipment-info-row">
                    <Col span={4} className="view-shipment-info-key">
                      {getText('Mobile number')}:
                    </Col>
                    <Col span={6}>
                      {getSingleOrderDataDetails.data![0].pickup_contact_number}
                    </Col>
                  </Row>

                  <Row className="view-shipment-info-row">
                    <Col span={4} className="view-shipment-info-key">
                      {getText('Address')}:
                    </Col>
                    <Col span={6}>
                      {getSingleOrderDataDetails.data &&
                      `${getSingleOrderDataDetails.data![0].pickup_contact_name},
                       ${getSingleOrderDataDetails.data![0].pickup_address},
                       ${getSingleOrderDataDetails.data![0].pickup_state},
                       ${getSingleOrderDataDetails.data![0].pickup_country},
                       ${getSingleOrderDataDetails.data![0].pickup_postal}`}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab={`${getText('Customer Details')}`} key="1" >
                  <h4>{getText('Customer Details')}</h4>
                  <Row className="view-shipment-info-row">
                    <Col span={4} className="view-shipment-info-key">
                      {getText('Full Name')}:
                    </Col>
                    <Col span={6}>
                      {getSingleOrderDataDetails.data![0].consignee_name}
                    </Col>
                  </Row>

                  <Row className="view-shipment-info-row">
                    <Col span={4} className="view-shipment-info-key">
                      {getText('Email address')}:
                    </Col>
                    <Col span={6}>
                      {getSingleOrderDataDetails.data![0].consignee_email}
                    </Col>
                  </Row>

                  <Row className="view-shipment-info-row">
                    <Col span={4} className="view-shipment-info-key">
                      {getText('Mobile number')}:
                    </Col>
                    <Col span={6}>
                      {getSingleOrderDataDetails.data![0].consignee_number}
                    </Col>
                  </Row>
                  {(getSingleOrderDataDetails.data![0].consignee_country === "Indonesia") ?
                    <Row className="view-shipment-info-row">
                      <Col span={4} className="view-shipment-info-key">
                        {getText('Identification Type')}:
                      </Col>
                      <Col span={6}>
                        {getSingleOrderDataDetails.data &&
                         <p style={{fontSize:"11px"}}>{getSingleOrderDataDetails.data![0].consignee_identifcation_number ?getSingleOrderDataDetails.data![0].consignee_identifcation_number : ''}<span style={{color: '#808080'}}>  ({getSingleOrderDataDetails.data![0].identification_document_name ? (IndonesiaIdentificationTypes[getSingleOrderDataDetails.data![0].identification_document_name]) : ''})</span></p> }
                      </Col>
                    </Row> : ''
                  }

                  <Row className="view-shipment-info-row">
                    <Col span={4} className="view-shipment-info-key">
                      {getText('Address')}:
                    </Col>
                    <Col span={6}>
                      {getSingleOrderDataDetails.data![0] &&
                      `${getSingleOrderDataDetails.data![0].consignee_address},
                       ${getSingleOrderDataDetails.data![0].consignee_city},
                       ${getSingleOrderDataDetails.data![0].consignee_state},
                       ${getSingleOrderDataDetails.data![0].consignee_country},
                       ${getSingleOrderDataDetails.data![0].consignee_postal}`}
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tab={`${getText('Item & Parcel Details')}`} key="2">
                  <h4>{getText('Shipment Details')}</h4>
                  <h5>{getText('Parcel Details')}</h5>
                  <Row className="view-shipment-info-row">
                    <Col span={4} className="view-shipment-info-key">
                      {getText('Payment type')}:
                    </Col>
                    <Col span={10}>
                      {getSingleOrderDataDetails.data![0].payment_type === 'prepaid' ? 'Prepaid' : (getSingleOrderDataDetails.data![0].payment_type === 'cod'?'COD' : getSingleOrderDataDetails.data![0].payment_type)}
                    </Col>
                  </Row>

                  <Row className="view-shipment-info-row">
                    <Col span={4} className="view-shipment-info-key">
                      {getText('Transaction ID')}:
                    </Col>
                    <Col span={10}>
                      N/A
                    </Col>
                  </Row>

                  <Row className="view-shipment-info-row">
                    <Col span={4} className="view-shipment-info-key">
                      {getText('Cash to collect')}:
                    </Col>
                    <Col span={6}>
                      {getSingleOrderDataDetails.data![0].cod_amt_to_collect ? `${countryCodeCurrencies[countryCodes[getSingleOrderDataDetails.data![0].consignee_country]]} ${getSingleOrderDataDetails.data![0].cod_amt_to_collect}`: 'N/A'}
                    </Col>
                  </Row>

                  <Row className="view-shipment-info-row">
                    <Col span={4} className="view-shipment-info-key">
                      {getText('Weight (kg)')}
                    </Col>
                    <Col span={6}>
                      {getSingleOrderDataDetails.data![0].order_weight} KG
                    </Col>
                  </Row>

                  <Row className="view-shipment-info-row">
                    <Col span={4} className="view-shipment-info-key">
                      {getText('Dimension')}:
                    </Col>
                    <Col span={10}>
                      (W){getSingleOrderDataDetails.data![0].order_width} x (L){getSingleOrderDataDetails.data![0].order_length} x (H){getSingleOrderDataDetails.data![0].order_height} cm
                    </Col>
                  </Row>

                  <Divider />

                  {getSingleOrderDataDetails.data![0].items?.map((item, index) => {
                    return (
                      <div key={`item_${index}`}>
                        <h5>{getText('Item')} {index + 1}</h5>
                        <Row className="view-shipment-info-row">
                          <Col span={4} className="view-shipment-info-key">
                            {getText('Item Description')}:
                          </Col>
                          <Col span={10}>
                            {item.item_desc}
                          </Col>
                        </Row>

                        <Row className="view-shipment-info-row">
                          <Col span={4} className="view-shipment-info-key">
                            {getText('Item Category')}:
                          </Col>
                          <Col span={10}>
                            {item.item_category}
                          </Col>
                        </Row>

                        <Row className="view-shipment-info-row">
                          <Col span={4} className="view-shipment-info-key">
                            {getText('Quantity')}:
                          </Col>
                          <Col span={10}>
                            {item.item_quantity}
                          </Col>
                        </Row>

                        <Row className="view-shipment-info-row">
                          <Col span={4} className="view-shipment-info-key">
                            {getText('Item Value')}:
                          </Col>
                          <Col span={4}>
                            {item.item_price_currency} {item.item_price_value}
                          </Col>
                        </Row>

                        {
                          item.gst_ovr &&
                          <>
                            <Row className="view-shipment-info-row">
                              <Col span={4} className="view-shipment-info-key">
                                {'GST OVR number'}:
                              </Col>
                              <Col span={10}>
                                {item.gst_ovr}
                              </Col>
                            </Row>

                            <Row className="view-shipment-info-row">
                              <Col span={4} className="view-shipment-info-key">
                                {'GST collected'}:
                              </Col>
                              <Col span={10}>
                                {item.gst_collected === true ? 'Yes' : 'No'}
                              </Col>
                            </Row>
                          </>
                        }

                        {
                          item.item_product_id && 
                          <Row className="view-shipment-info-row">
                            <Col span={4} className="view-shipment-info-key">
                              {'Product ID'}:
                            </Col>
                            <Col span={10}>
                              {item.item_product_id}
                            </Col>
                          </Row>
                        }

                        {
                          item.item_sku && 
                          <Row className="view-shipment-info-row">
                            <Col span={4} className="view-shipment-info-key">
                              {'Item SKU'}:
                            </Col>
                            <Col span={10}>
                              {item.item_sku}
                            </Col>
                          </Row>
                        }

                        {
                          item.item_url && 
                          <Row className="view-shipment-info-row">
                            <Col span={4} className="view-shipment-info-key">
                              {'Product URL'}:
                            </Col>
                            <Col span={10}>
                              {item.item_url}
                            </Col>
                          </Row>
                        }
                        
                        <Divider />
                      </div>
                    )
                  })}
                </TabPane>
                
                <TabPane tab={`${getText('Tracker Updates')}`} key="4">
                  {getOrderTrack.data!.updates!.length > 0 ? getOrderTrack.data?.updates!.map((item:any, index) => {
                    return (
                      <div key={`tracking_${index}`}>
                        <Row className="view-shipment-info-row">
                          <Col span={4} className="view-shipment-info-key">{getText('Status')}:</Col>
                          <Col span={18}>{item.status}</Col>
                        </Row>
                        <Row className="view-shipment-info-row">
                          <Col span={4} className="view-shipment-info-key">{getText('Address')}:</Col>
                          <Col span={18}>{item.address}</Col>
                        </Row>
                        <Row className="view-shipment-info-row">
                          <Col span={4} className="view-shipment-info-key">{getText('Country')}:</Col>
                          <Col span={18}>{item.country}</Col>
                        </Row>
                        <Row className="view-shipment-info-row">
                          <Col span={4} className="view-shipment-info-key">{getText('Description')}:</Col>
                          <Col span={18}>{item.description}</Col>
                        </Row>
                        <Row className="view-shipment-info-row">
                          <Col span={4} className="view-shipment-info-key">{getText('Updated on')}:</Col>
                          <Col span={18}>{item.updated_on ? getDateInReadableFormat(item.updated_on) : null}</Col>
                        </Row>
                        <Divider/>
                      </div>
                    )
                  }) : <h1>{getText('No Data Available')}</h1>}
                </TabPane>
              </Tabs>
            </Col>

            <Col span={6} className="view-shipment-main-container" id="activity-container">
              <h4>{getText('Activity Updates')}</h4>
              <Divider />
              {
                (getOrderLogActivity.data && getOrderLogActivity.data.length > 0 && !isProduction) ?
                  getOrderLogActivity.data.map((item, index) => {
                    return (
                      <ul key={`log_${index}`}>
                        <li><small>{getText('Activity From')}:</small> <span>{item.source_name}</span></li>
                        <li><small>{getText('Activity Date')}:</small> <span>{getDateInReadableFormat(item.activity_on)}</span></li>
                        <li><small>{getText('Activity Message')}:</small><div className="activity-message" dangerouslySetInnerHTML={{__html: item.message_html}} /></li>

                        <Divider />
                      </ul>
                    )
                  })
                  : <p>{getText('No activity')}</p>
              }
            </Col>
          </Row>
        </> : <Spin style={{'position': 'fixed','left': '50%', 'top': '50%'}} />
      }
    </>
  )
};