import {
  Row,
  Col,
  Button
} from 'antd'
import './styles/HomePage.css';
import {useHistory,useLocation} from "react-router-dom";
import React, {useState, useEffect} from "react";
import {useQuery} from "react-query";
import {useAuth} from "../auth/services/authContext";
import {TourModal} from "./components/OnboardingTour";
import {YouAreSubAccountModal} from "./components/YouAreSubAccountModal";
import { getOrderSummaryCount_from_b2c } from "../ManageShipments/services";
import {UserViewedDashboard,Tip1Viewed,UserViewedCreateShipmentPage, trackSignInForRedirectedCLients} from "../commons/mixpanel";
import { useLanguage } from '../languages/Language';
import {Tips} from "../commons/components/Tips/Tips";
import Cookies from 'universal-cookie';
import {
  // ClaimRelatedAlert, 
  NewGstOvrRelatedAlert,
  PrePaidServiceClosureAlert,
  NewShopifyChangesAlert
} from './MessagesOnHomepage'
import { ActiveShipment } from './components/ActiveShipment';

// import home_draft from '../assets/icons/home_draft.svg';

/* eslint-disable @typescript-eslint/no-unused-vars */
import AttentionShipment from './components/AttentionShipment';

/* eslint-enable @typescript-eslint/no-unused-vars */

const DashboardContent = () => {
  const isProduction = window.location.hostname.includes('ss.janio.asia')
  const mp_token = isProduction ? 'eff8918820fd69cd6c69141910a0b619' : 'fc1dce3e41c03b39a9ec2aed07452099'
  let { getText } = useLanguage();
  const cookies = new Cookies();
  const auth = useAuth();
  let history = useHistory();
  let location:any = useLocation();
  let pathname = location.pathname;
  let prevPathName= location.state?.from;
  const userName = auth.user.fullName;
  let today = new Date();
  today.setFullYear(today.getFullYear()-1)
  const logged_in_once = localStorage.getItem("logged_in_once");
  const [showTour, setShowTour] = useState<boolean>(!logged_in_once);
  const [showHideSubAccountAlert, setShowHideSubAccountAlert] = useState<boolean>(true);
  const [showTip, setShowTip] = useState<boolean>(prevPathName==="/orders/create"?true:false);
  const [tourButtonIsTouched] = useState<boolean>(false);

  if(localStorage.getItem('referrer_shopify')) {
    history.push('/shopify-integration')
  }

  const summaryFromb2c = useQuery('ordersSummaryb2c', () =>{
    return getOrderSummaryCount_from_b2c(auth.user.secret_key)
  }, {cacheTime: 0});
  
  const setShowTourCallback = (val:boolean,showTip:boolean) => {
    setShowTour(val);
    setShowTip(showTip);
  };
  const setShowTipCallback = (val:boolean) => {
    setShowTip(val);
  }

  const cookieExist = cookies.get(`sub_aacount_alert_shown_${auth.user.email}`)

  const onTipNextButtonClick = () => {
    history.push({
      pathname: '/orders/create',
      state: {
        from: pathname
      }
    })
  }
  const hasOrders = () => {
    if(summaryFromb2c.isLoading){
      return true
    }
    return summaryFromb2c?.data?.delivered! > 0 || summaryFromb2c?.data?.pending_pickup! > 0 || summaryFromb2c?.data?.cancelled! > 0
  }
  const getShowTour = () => {
    if(auth.user.tnc===true && showTour &&(!hasOrders() || tourButtonIsTouched)){
      // TourPopupViewed()
      return true;
    }
    // else {
    //   UserViewedDashboard()
    //   return false;
    // }
  }
  const getShowSubAccountAlert = () => {
    if(auth.user.account_type === 'Sub Account' && showHideSubAccountAlert && !cookieExist) {
      return true
    }
    return false
  }
  const setSubAccountCallback = (val:boolean) => {
    setShowHideSubAccountAlert(val)
  };

  const NewDashboard = () => {
    return (
      <>
        <Row>
          <p className={'header-sub-label'}>{'Below are the latest updates for your shipments in the past 3 months'}</p>
        </Row>
        <ActiveShipment b2cSummaryData={summaryFromb2c} />
        {auth.user.payment_type !== 'PayInstantly' &&
          <>
            {/* Remove this while releasing Exception handling */}
            {(summaryFromb2c.data?.to_ship! > 0 || summaryFromb2c.data?.ready_to_ship! > 0 || !isProduction) &&
              <Row>
                <p className={'header-sub-label'}>{'Shipments that need your attention'}</p>
              </Row>
            }
            {/* Uncomment this while releasing Exception handling */}
            {/* <Row>
              <p className={'header-sub-label'}>{'Shipments that need your attention'}</p>
            </Row> */}
            <AttentionShipment b2cSummaryData={summaryFromb2c}/>
          </>
        }
      </>
    )
  }
  useEffect(() => {
    const isDev = window.location.hostname.includes('localhost')
    if(isDev) {
      return
    } else {
      const mixpanelCookie = cookies.get(`mp_${mp_token}_mixpanel`)
      // console.log('mixpanelCookiemixpanelCookie', mixpanelCookie.$user_id)
      if(mixpanelCookie.$user_id === undefined) {
        trackSignInForRedirectedCLients(auth.user.email)
      } else {
        UserViewedDashboard()
      }
    }
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Row className={'home-header'}>
        <Col>
          <p className={`header-label ${auth.user.b2c_id === '4214' ? 'header-font-size' : ''}`}>{`${getText('welcome')}, ${userName}`}</p>
        </Col>
        <Col>
          <Row gutter={12}>
            <Col>
              <Button key="track-shipment" type="primary" className={"outline-btn"} style={{display: "none"}}>
                <a href={'/orders/track'}>Track shipment</a>
              </Button>
            </Col>
            <Col>
              <Tips visible={!tourButtonIsTouched&&showTip} step={'1'} placement={'left'} hidePreviousText={true} onNextClick={onTipNextButtonClick} onExitTour={setShowTipCallback} contentMessage={"Get started by creating your first shipment with Janio"} mixpanelEvent={Tip1Viewed}>
                {<Button key="create-shipment" type="primary" className={"blue-button"} style={{display: "none"}} onClick={() => UserViewedCreateShipmentPage('homepage')}>
                  <a href={'/orders/create'}>{getText('Create shipment')}</a>
                </Button>}
              </Tips>
            </Col>
          </Row>
        </Col>
      </Row>
      
      <NewDashboard/>
      {getShowSubAccountAlert() && <YouAreSubAccountModal email={auth.user.email} parent_email={auth.user.parent_email} setSubAccountCallback={setSubAccountCallback} />}
      {summaryFromb2c.isFetched && getShowTour() && <TourModal setShowTourCallback={setShowTourCallback} tourButtonTouched={true}/>}    </>
  )
};


export const Dashboard = () =>  {
  const auth = useAuth()
  return (
    <Row align="middle" justify="center">
      <Col span={24} className={'form-container'}>
        {auth.user.b2c_id !== '4214' && 
          <div style={{marginBottom:'30px'}}>
            <NewShopifyChangesAlert />
            {auth.user.payment_type === 'PayInstantly' ? <PrePaidServiceClosureAlert /> : <NewGstOvrRelatedAlert />}
            
          </div>
        }
        <DashboardContent />
      </Col>
    </Row>
  )
};
