import {useState} from 'react';
import {Row, Col, Card, Tag, Button, Table, Spin, message, Result, Breadcrumb, Switch} from 'antd'
import {useAuth} from "../../../auth/services/authContext";
import { useLanguage } from '../../../languages/Language';
import {Link} from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { getSubAccounts, 
  updateSubAccountStatusData 
} from "../../../orders/services/privateApi";
import {getDateInReadableFormat} from '../../../commons/utils/utilizer'

export const ManageSubAccounts = (props: any) => {
  let { getText } = useLanguage();
  const auth = useAuth();
  const authToken = auth.user.authToken;

  const [page_size, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const getSubAccountListData = useQuery(['getSubAccountListData', page, page_size], async () => {
    return getSubAccounts(authToken, {
      "page": page,
      "page_size": page_size,
    })
  }, {cacheTime: 0})

  const updateSubAccountStatus = useMutation(async (params: any) => {
    return updateSubAccountStatusData(auth.user.authToken, params, params.id);
  },  {
    onSuccess: (val) => {
      message.success('Account updated succesfully!')
      getSubAccountListData.refetch()
    },
    onError: (error: any) => {
      let errorResponse = error.response.data;
      message.error(`${errorResponse.error}. Failed to fetch the sub-accounts. Please try again after sometime.`);
    },
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'user_data',
      render: (user_data: {email: string, id: number}) => {
        return user_data.email
      }
    },
    {
      title: 'Created On',
      dataIndex: 'created_on',
      key: 'created_on',
      render: (created_on:any, fullData: any) => {
        return (
          <span>{getDateInReadableFormat(fullData.created_on)}</span>
        )
      }
    },
    {
      title: 'Updated On',
      dataIndex: 'updated_on',
      key: 'updated_on',
      render: (created_on:any, fullData: any) => {
        return (
          <span>{getDateInReadableFormat(fullData.created_on)}</span>
        )
      }
    },
    {
      title: 'Status',
      dataIndex: 'sub_account_disabled',
      width: 90,
      render: (sub_account_disabled: boolean) => {
        if(!sub_account_disabled) {
          return (
            <Tag color="success">Active</Tag>
          );
        } else {
          return (
            <Tag color="error">Inactive</Tag>
          );
        }
      }
    },
    {
      title: 'Switch',
      dataIndex: 'switch',
      width: 90,
      render: (text: string, record: any)=>(
        <Switch key={record.id} defaultChecked={!record.sub_account_disabled} onChange={(value, event)=>{
          let new_record = {...record}
          new_record.is_active = value
          return updateSubAccountStatus.mutate(new_record, record.id)
        }} />
      )
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'view',
      fixed: 'right' as 'right',
      render: (action:any, fullData:any)=> {
        return (
          <>
            <Row gutter={16}>
              <Col>
                <Link to={{ pathname: `/settings/add_sub_account/edit/${fullData.id}`, state: { editData: fullData } }}>Edit preference </Link>
              </Col>
            </Row>
          </>
        )
      }
    }
  ];
  
  // const cancel = (e: any) => {
  //   return
  // }

  if(getSubAccountListData.isFetching) {
    return (
      <div className="spinner-centre">
        <Spin />
      </div>
    )
  }

  if(auth.user.account_type === "Sub Account" || auth.user.payment_type === "PayInstantly") {
    return (
      <>
        <Breadcrumb className="bread-crumbs">
          <Breadcrumb.Item>
            <Link to={`/settings`} >
              <span style={{border: 'none',background: 'none'}} >
                {getText("Settings")}
              </span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{"Manage Account"}</Breadcrumb.Item>
        </Breadcrumb>
        <Row justify='center'>
          <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
          />
        </Row>
      </>
    )
  }

  const onSwitchPage = (pageNumber:any, pageSize: any) => {
    setPage(pageNumber)
    setPageSize(pageSize)
  }
  const onPageSizeChange = (current:any, pageSize:any) => {
    setPageSize(pageSize)
  }

  return (
    <>
      <Breadcrumb className="bread-crumbs">
        <Breadcrumb.Item>
          <Link to={`/settings`} >
            <span style={{border: 'none',background: 'none'}} >
              {getText("Settings")}
            </span>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{"Manage Account"}</Breadcrumb.Item>
      </Breadcrumb>
      <div className={'main-rules-container'}>
        <Row>
          <Col style={{'marginBottom': '15px'}} span={21}>
            <p style={{fontSize: '33px', paddingLeft: '4px', marginBottom: '-20px', marginTop: '-11px'}}>Sub-accounts</p>
          </Col>
          <Col style={{'marginBottom': '15px', 'textAlign': 'right'}} span={3}>
            <Link to={`/settings/add_sub_account`} >
              <Button type="primary"> {"Add sub-account"} </Button>
            </Link>
          </Col>
        </Row>
        <div>
          <Col span={24} className="transaction_container">
            <Card>
              <Table
                // rowClassName='table_body'
                scroll={{ x: 1300, y:500}}
                rowKey={'index'}
                dataSource={getSubAccountListData?.data!.results }
                columns={columns}
                loading={getSubAccountListData.isLoading}
                pagination={{
                  onChange: onSwitchPage,
                  position:['bottomRight'],
                  defaultPageSize: page_size,
                  pageSize: page_size,
                  onShowSizeChange: onPageSizeChange,
                  showSizeChanger: true,
                  total: getSubAccountListData.data && getSubAccountListData.data.count,
                  pageSizeOptions: ['10', '20', '50', '100'],
                  current: page
                }}
              />
            </Card>
          </Col>
        </div>
      </div>
    </>
  )
}