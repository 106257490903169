import { DownloadOutlined } from '@ant-design/icons';
import { Alert, Button, Image, message, Spin, Upload } from 'antd';
import { useState } from 'react';
import { useMutation } from 'react-query';
// @ts-ignore
import downloadCSV from '../../../assets/static/return_bulkOrder_csv_template_janio_order_id.csv';
// @ts-ignore
import downloadXlsx from '../../../assets/static/return_bulkOrder_xlsx_template_janio_order_id.xlsx';
import { useAuth } from '../../../auth/services/authContext';
import { isApiError, RequestError } from '../../../commons/api';
import { TextMap } from '../../../commons/utils/constants';
import { cleanColumns, CleanedRow, consolidateOrderItems, performValidationReturns, readFileAsJSON } from '../../../orders/fileHelpers';
import { orderPartialValidate } from '../../../orders/services/privateApi';
import upload from "../../../assets/images/upload.svg";
import file_icon from '../../../assets/images/file_icon.svg';
import {useReturnBulkOrder} from "../../services/returnBulkOrderContext";
import '../../styles/ReturnOrderForm.css'

interface PropsType {
    current: number,
    setCurrentState: (val: number) => void,
    dataFilled: number,
}

const UploadErrorDisplayReturns = ({serverError}: any) => {
  if(serverError.message){
    return (
      <ul className={'error-list-body'}>
        {
          serverError.message.map((error: any, index: any) => {
            return <li className={'error-list-item'}>
              {error}
            </li>
          })
        }
      </ul>)
  }else{
    return (
      <div>Some error occured. Please try again after sometime
      </div>
    )
  }
  
}  

const SampleDownload = () => {
  return (
    <p className={'help-label'}>
      Need help getting started? Download our <a href={downloadCSV} download="Sample_CSV.csv">csv</a> and <a href={downloadXlsx} download="sample.xlsx">xlsx</a> templates here
    </p>
  )
}

const isValidFile = (type: string, name: string) => {
  if(type){
    return type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      type === 'application/vnd.ms-excel' || type === 'text/csv';
  }
  else if(name){
    const temp = name.split('.');
    const extension = temp[temp.length-1];
    return extension === 'csv' || extension === 'xls' || extension === 'xlsx'
  }
  return false
};

const isCustomTnExist = (data: any) => {
  return data.some((item: any) => {
    if(item.tracking_no) {
      return (item.tracking_no.length) > 0
    }
    return false
  })
}

export const UploadOrderForm = (props: PropsType) => {
  const isCustomTNPermission = JSON.parse(localStorage.getItem('allow_custom_tracking_no')!)
  const auth = useAuth();
  const returnBulkOrderContext = useReturnBulkOrder();
  const {current, setCurrentState, dataFilled} = props;
  const [isCountExceed, setIsCountExceed] = useState<boolean>(false);
  const [fileName, setFileName] = useState(dataFilled === 1 ? 'bulk_order.csv' : '');
  const [orderData, setOrderData] = useState([])
  const [doesTnExist, setDoesTnExist] = useState<boolean>(false)

  const replaceKeyWithNewKey = (arr: any, keyOne: string, keyTwo: string) => {
    return arr.map((item: any) => {
      let mutatedObj = {
        [keyTwo]: item[keyOne],
        'order_direction': 'RETURNS',
        ...item
      }
      delete mutatedObj[keyOne];

      return mutatedObj
    })
  }

  const validateAndNormalizeData = useMutation<Array<CleanedRow>, RequestError, File>(async (file: File) => {
    const fileContent = await readFileAsJSON(file)
    if(fileContent.length < 1){
      throw new Error('The uploaded file is empty')
    }

    if(fileContent.length > 500){
      throw new Error(`Currently, we're not accepting bulk order for more than 500 orders. Please upload another file.`)
    }
    
    const cleanedRows = cleanColumns(fileContent, 'RETURNS')
    if(isCustomTnExist(cleanedRows) && !isCustomTNPermission) {
      setDoesTnExist(true)
      return
    }

    performValidationReturns(cleanedRows, 'RETURNS')    

    let replaceOriginCountry = replaceKeyWithNewKey(cleanedRows, 'origin_country', 'pickup_country')
    setOrderData(replaceOriginCountry)
    const response = await orderPartialValidate(auth.user.secret_key, replaceOriginCountry, 'RETURNS');
    return response
  });

  const onSaveAndContinue = () => {
    const consolidatedOrderItem = consolidateOrderItems(orderData)
    const consolidatedOrderItems = consolidatedOrderItem.map((item: any) => {
      return {
        'order_direction': 'RETURNS',
        ...item,
      }
    })
    let addFileNameWithOrder = {
      file_name: fileName,
      consolidatedOrderItems
    }
    if(addFileNameWithOrder?.consolidatedOrderItems.length > 500) {
      setIsCountExceed(true)
    } else {
      returnBulkOrderContext.setParsedJson(addFileNameWithOrder)
      setCurrentState(1)
    }
  };

  const beforeUpload = (file: File) => {
    setIsCountExceed(false)
    setDoesTnExist(false)
    const isValid = isValidFile(file.type, file.name);

    if (isValid) {
      setFileName(file.name)
      validateAndNormalizeData.mutate(file)
    }
    else {
      message.error(TextMap.FileTypesAccepted);
    }
  
    return false
  };

  const runReUpload = () => {
    return <div>
      <Upload
        action=""
        accept=".csv,.xlsx,.xls"
        name="file"
        showUploadList={false}
        beforeUpload={beforeUpload}
      >
        <Button icon={<DownloadOutlined />}
          className={"blue-button"} style={{borderRadius: 2}} >
          {'Reupload file'}
        </Button>
      </Upload>
    </div>
  }

  if(current === 0){
  // No file selected yet, render file uploader
    if(!fileName){
      return (
        <>
          <div className={'uploader'}>
            <Upload
              action=""
              accept=".csv,.xlsx,.xls"
              name="file"
              listType="picture-card"
              className={'uploader-container'}
              showUploadList={false}
              beforeUpload={beforeUpload}>
              <div className={'uploader-content'}>
                <Image style={{marginBottom: 10, marginTop: 30}} preview={false} src={upload}/>
                <p style={{fontWeight: 'bold', fontSize: 15}}>{'Click'} <a onClick={(e) => e.preventDefault()} href="" style={{
                  color: '#050593',
                  textDecoration: 'underline'
                }}>{'here'}</a> {'to browse your file'}</p>
                <p style={{marginBottom: 20, marginTop: 10}}>{'Files supported (.xls, .xlsx and .csv)'}</p>
              </div>
            </Upload>
          </div>
          <SampleDownload />
        </>
      )
    }

    if(validateAndNormalizeData.isLoading){
      return (
        <div className={'uploader-summary-container'} style={{minHeight: '120px', position: 'relative'}}>
          <div className={'uploader-summary'}>
            <Spin tip="Validating CSV..." className="validate-loader" />
          </div>
        </div>
      )
    }

    if(validateAndNormalizeData.isError && validateAndNormalizeData.error){
      return (
        <>
          <Alert
            showIcon
            message={"Please correct the following errors before reuploading your file:"}
            description={
              validateAndNormalizeData.error && (
                isApiError(validateAndNormalizeData.error) ?
                  <UploadErrorDisplayReturns serverError={validateAndNormalizeData.error.response?.data}/> :
                  validateAndNormalizeData?.error?.message)
            }
            type="error"
            className={'error-list-container'}
          />
          <SampleDownload />
          <div className="button-block">
            {runReUpload()}
          </div>
        </>
      )
    }

    if(isCountExceed){
      return (
        <>
          <Alert
            showIcon
            message={"Please correct the following errors before reuploading your file:"}
            description={'For smooth order creation process, please keep the orders count in your file less than 500.'}
            type="error"
            className={'error-list-container'}
          />
          <SampleDownload />
          <div className="button-block">
            {runReUpload()}
          </div>
        </>
      )
    }

    if(doesTnExist && !isCustomTNPermission) {
      return (
        <>
          <Alert
            showIcon
            message={"Please correct the following errors before reuploading your file:"}
            description={
              <>
                <span style={{display: 'block', fontSize: '15px'}}>You don't have permission to use custom tracking number. Please remove the value(s) under custom tracking number column.</span>
              </>
            }
            type="error"
            className={'error-list-container'}
          />
          <SampleDownload />
          <div className="button-block">
            {runReUpload()}
          </div>
        </>
      )
    }

    return (
      <>
        <div className={'uploader-summary-container'}>
          <div className={'uploader-summary'}>
            <Image preview={false} src={file_icon}/>
            <p>{fileName}</p>
          </div>
        </div>
        <div className="button-block">
          {runReUpload()}
          <Button type="primary" onClick={() => onSaveAndContinue()} className={"blue-button"} style={{borderRadius: 2, marginLeft: 10}}>
            {'Save & Continue'}
          </Button>
        </div>
      </>
    )
  }
  else{
    return (
      <div className={'uploader-summary-container'}>
        <div className={'uploader-summary'}>
          <Image preview={false} src={file_icon}/>
          <p>{fileName}</p>
        </div>
      </div>
    )
  }
}

export const UploadOrderFormTitle = (props: PropsType) => {
  let isEditing = window.location.pathname.includes('/pay/return/bulk')
  const {current, setCurrentState} = props;
  return (
    <div className={'title-block'}>
      <p className={'step-title'}>Upload return bulk shipments</p>
      {current > 0 && !isEditing? <p onClick={() => setCurrentState(0)} className={"edit-text"}>Edit details</p> : ''}
    </div>
  )
}