import { Column } from '@ant-design/plots';
import { each, groupBy } from '@antv/util';
import { useEffect, useState } from 'react';
import { Spin, Empty } from 'antd';
import { UserSelectedAnalyticsGranularity } from '../../../commons/mixpanel';
import { AnalyticsButton } from "../../../commons/components/AnalyticsButton/AnalyticsButton"

interface StackedColumnProps{
  stackedColumnData: any
  granularity: string
  setGranularity(val: string): void
  range: string
}
export const StackedColumn = (props: StackedColumnProps) => {
  const {granularity, setGranularity, range} = props
  const [chartData, setChartData] = useState([])
  useEffect(() => {
    let chartArray: any = []
    if(props.stackedColumnData.data){
      props.stackedColumnData.data.forEach((element: any) => {
        let data = {
          year: element.created.slice(0, 10).split("-").reverse().join("-"),
          value: element.count,
          type: element.consignee_country,
        }
        chartArray.push(data)
      })
      setChartData(chartArray.reverse())
    }
    
  },[props.stackedColumnData.data])
  const annotations: any = [];
  each(groupBy(chartData, 'year'), (values, k) => {
    const value = values.reduce((a: any, b: any) => a + b.value, 0);
    annotations.push({
      type: 'text',
      position: [k, value],
      content: `${value}`,
      style: {
        textAlign: 'center',
        fontSize: 14,
        fill: 'rgba(0,0,0,0.85)',
      },
      offsetY: -10,
    });
  });
  const config = {
    data: chartData,
    isStack: true,
    xField: 'year',
    yField: 'value',
    seriesField: 'type',
    paddingRight: 130,
    color: ['#5AD8A6','#5B8FF9','#5D7092','#F6BD16','#E8684A','#6DC8EC'],
    xAxis: {
      label: {
        autoRotate: false,
      },
    },
    minColumnWidth: 30,
    maxColumnWidth: 30,
    columnWidthRatio: 0.1,
    legend: {
      position: ('top-left' as any)
    },
    scrollbar: {
      type: ('horizontal' as any),
    },
    label: {
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
      // position: () => IntervalGeometryLabelPosition,
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
    annotations,
  };

  return (
    <div className='chart-header-custom'> 
      <h2>Shipments by Destination Country </h2>    
      <div className='granularity'>
        <AnalyticsButton onButtonClick={()=>setGranularity('weekly')} mixpanelAnalytics={() => UserSelectedAnalyticsGranularity('Shipments by Destination Country', 'weekly')} className={'granularity-button'} type={granularity==='weekly'? 'primary':'default'} disabled={range === 'last_day' || range === 'last_week'}>Weekly</AnalyticsButton>
        <AnalyticsButton onButtonClick={()=>setGranularity('monthly')} mixpanelAnalytics={() => UserSelectedAnalyticsGranularity('Shipments by Destination Country', 'monthly')} className={'granularity-button'} type={granularity==='monthly'? 'primary':'default'} disabled={range === 'last_day' || range === 'last_week'}>Monthly</AnalyticsButton>
      </div>
      {!props.stackedColumnData.isLoading? 
        Object.keys(props.stackedColumnData.data).length !== 0 ? <Column {...config} className={'sub-charts stack-class'} /> : <div className={'sub-charts no-data-image'}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/></div>
        : <div className={'sub-charts chart-spinner'}>
          <Spin/>
        </div>
      }
    </div>
  )
};
