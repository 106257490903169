import moment from 'moment';
import Attachment from './interfaces/Attachment';
import Client from './interfaces/Client';
import Invoice from './interfaces/Invoice';
import CacheData from './interfaces/CacheData';
import { instanceForFinance as api } from '../commons/api';
import { User } from '../auth/services/authContext';
import download from '../commons/utils/download';
import { getCacheData, storeCacheData } from '../commons/utils/cache';

const EXPIRY = (window.location.hostname.includes('staging') || window.location.hostname.includes('localhost')) ? 1 : 30;

const getClientID = () => {
  const user = getCacheData('AUTH_SESSION') as User | null;

  if (!user) {
    throw new Error('client not found');
  }

  return user.b2c_id || user.master_b2c_id;
};

export const getInvoices = async () => {
  const cacheData = getCacheData('xero-invoices') as CacheData | null;

  let data: Invoice[] = [];
  let lastFetch: string = '';
  if (!cacheData) {
    const clientID = getClientID();
    const response = await api.get<Invoice[]>(`/invoices/xero-invoices?client_id=${clientID}`);
    data = response.data;
    lastFetch = moment().format('hh:mm A');
    storeCacheData('xero-invoices', { invoices: data, lastFetch }, EXPIRY);
  } else {
    data = cacheData.invoices;
    lastFetch = cacheData.lastFetch;
  }

  const all = data;
  const unpaid = data.filter(({ status }) => status === 'AUTHORISED');
  const paid = data.filter(({ status }) => status === 'PAID');
  const unpaidCurrencyCodes = unpaid.map((({ currencyCode }) => currencyCode));
  const paidCurrencyCodes = paid.map((({ currencyCode }) => currencyCode));
  const unpaidCurrencies = [...new Set([...unpaidCurrencyCodes])];
  const paidCurrencies = [...new Set([...paidCurrencyCodes])];
  return { unpaid, paid, all, unpaidCurrencies, paidCurrencies, lastFetch };
};

export const getAttachments = async (invoiceID: string) => {
  const clientID = getClientID();
  const response = await api.get<Attachment[]>(`/invoices/xero-invoices/${invoiceID}/attachments?client_id=${clientID}`);
  return response.data;
};

export const getAttachment = async (endpoint: string, invoice: Invoice, attachment: Attachment) => {
  const clientID = getClientID();

  let queryString = `${endpoint}?client_id=${clientID}`;
  if (endpoint === 'attachment') {
    queryString += `&attachment_id=${attachment.attachmentID}&mime_type=${attachment.mimeType}`;
  } else { // endpoint = download
    queryString += `&type=${invoice.type}`;
  }

  const response = await api.get<string>(`/invoices/xero-invoices/${invoice.invoiceID}/${queryString}`);
  const data = response.data;
  download(data, attachment.fileName, attachment.mimeType);
};

export const getClientInfo = async () => {
  const cacheData = getCacheData('xero-client-info') as Client | null;

  let data: Client;
  if (!cacheData) {
    const clientID = getClientID();
    const response = await api.get<Client>(`/invoices/xero-client-info?client_id=${clientID}`);
    data = response.data;
    storeCacheData('xero-client-info', data);
  } else {
    data = cacheData;
  }

  return data;
};