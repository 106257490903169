import { Table, Button, Tag} from 'antd';
import { useState } from 'react';
import { Link} from 'react-router-dom';
// import {getDateInReadableFormat } from "../../commons/utils/utilizer";
import { useLanguage } from '../../languages/Language';

export const NotReadyToShip = (props:any) => {
  let { getText } = useLanguage();
  const [pageSize, setPageSize] = useState(10);

  const columns = [
    {
      title: getText('Order Type'),
      dataIndex: ['channel','channel_type'],
      key: 'channel_type',
    },
    {
      title: 'Store Name',
      dataIndex: ['channel','channel_name'],
      key: 'channel_name',
    },
    {
      title: 'Shipment Order Id',
      dataIndex: 'shipper_order_id',
      key: 'shipper_order_id  ',
    },
    // {
    //   title: getText('Created On'),
    //   dataIndex: 'created_on',
    //   key: 'created_on',
    //   render: (created_on: any) => {
    //     return getDateInReadableFormat(created_on)
    //   }
    // },
    {
      title: getText('Consignee Country'),
      dataIndex: 'consignee_country',
      key: 'consignee_country',
      render: (consignee_country: any) => {
        return consignee_country
      }
    },
    {
      title: getText('Customer Name'),
      dataIndex: 'consignee_name',
      key: 'consignee_name',
      render: (consignee_name:any) => {
        return consignee_name
      }
    },
    {
      title: getText('Status'),
      dataIndex: 'status',
      key: 'status',
      render: (status:any) => {
        return <Tag color="error">{status==='ERROR'?'Insufficient info':status}</Tag>
      }
    },
    {
      title: getText('Actions'),
      dataIndex: '',
      key: '',
      fixed: 'right' as 'right',
      width: 100,
      render: (key:any, allData:any) => (
        <Link to={`/orders/marketplace/pay/single/${allData.id}`} >
          <Button type="link" >
            Update Info
          </Button>
        </Link>
      ),
    }
  ];

  const onSwitchPage = (page:any, pageSize: any) => {
    props.setPageSize(pageSize)
    props.setPage(page)
  }

  const onPageSizeChange = (current:any, pageSize:any) => {
    setPageSize(pageSize)
  }
  return (
    <>
      <Table
        rowClassName='table_body'
        rowKey="id"
        style={props.data && props.data.results.length > 0 ? {marginTop:'-3px'} : {marginTop:'59px'}}
        dataSource={props.data && props.data.results}
        columns={columns}
        loading={props.isLoading}
        pagination={{
          onChange: onSwitchPage,
          position:['topRight','bottomRight'],
          defaultPageSize: pageSize,
          pageSize: pageSize,
          onShowSizeChange: onPageSizeChange,
          showSizeChanger: true,
          total: props.data && props.data.count,
          pageSizeOptions: ['10', '20', '50', '100']
        }}
      />
    </>
  )
};