import { Pie } from '@ant-design/plots';
import { useEffect, useState } from 'react';
import { Spin, Empty } from 'antd';
interface PieProps{
  piePlotData: any
}
export const PiePlot = (props: PieProps) => {
  const [chartData, setChartData] = useState([])
  const removeUnderscore = (status: string) => {
    const capitalize = status.charAt(0).toUpperCase() + status.slice(1)
    return capitalize.replaceAll('_',' ')
  }
  useEffect(() => {
    let chartArray: any = []
    let emptyData: any = [];
    if(props.piePlotData.data){
      props.piePlotData.data.forEach((element: any) => {
        Object.entries(element).forEach(([key, value]) => {
          if(value !== 0){
            emptyData.push(value)
          }          
          let data = {
            type: removeUnderscore(key),
            value: value
          }
          chartArray.push(data)
        });  
      });
      emptyData.length > 0 ? setChartData(chartArray) : setChartData([])
    }
    
  },[props.piePlotData.data])
  
  const config = {
    appendPadding: 10,
    data: chartData,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    color: ['#5AD8A6','#5B8FF9','#5D7092','#F6BD16'],
    label: {
      type: 'outer',
      offset: '-30%',
      content: ({ percent }: any) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };
  return (
    <div className='chart-header-custom'>
      <h2>Shipment Performance</h2>
      {!props.piePlotData.isLoading? 
        Object.keys(props.piePlotData.data).length !== 0 && chartData.length !==0 ? <Pie {...config}  className={'sub-charts'} /> : <div className={'sub-charts no-data-image'}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/></div> 
        : <div className={'sub-charts chart-spinner'}>
          <Spin/>
        </div>
      }
    </div>
  )
};