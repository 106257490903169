import React, { useEffect } from 'react'
// import {useQuery} from "react-query";
import {
  Switch,
  Route,
  Redirect,
  Link,
  useRouteMatch,
  useLocation,
} from "react-router-dom"
import {
  Layout as L,
  Menu,
  Image,
  Modal,
  // Badge,
  Tag
} from 'antd'
import {
  LogoutOutlined
} from '@ant-design/icons'
import {useAuth
  // ,refreshUserSession,isSessionCurrent
} from '../auth/services/authContext';
import './styles/PrivateRoute.css';
import janio_logo_header from "../assets/images/janio_logo_header.svg";
import {NewTCModal} from "./components/TermsAndConditions/NewTCModal";
import {TopUpSuccess} from "../wallet/TopUpSuccess";
import {SingleOrder} from '../orders/SingleOrder.page'
import {PayOrder} from '../orders/PayOrder.page'
import {PayOrderShopify} from '../orders/PayOrderShopify.page'
import {PayMarketplaceOrder} from '../orders/PayMarketplaceOrder.page'
import {BulkOrder} from "../orders/BulkOrder.page";
// import {MpsBulkOrder} from "../MpsOrder/MpsBulkOrder.page";
import {CreateShipment} from "../orders/CreateShipment.page";
import {ShipmentsPage} from "../ManageShipments/Shipments.page";
import {ViewShipmentDetail} from "../ManageShipments/viewShipmentDetails/ViewShipmentDetail";
// import {ViewMpsShipmentDetails} from "../ManageShipments/viewShipmentDetails/ViewMpsShipmentDetails";
import {Dashboard} from "../dashboard/Home.page";
import {NewDashboard} from "../dashboard/NewHome.page";
import {UserSettings} from "../UserSettings/components/UserSettings";
import {UpdateProfile} from "../auth/Profile.page";
import {Notifications} from "../auth/components/Notifications";
import {NeedHelpPage} from "../Support/Support.page";
import {ManageAddressesPage} from "../UserSettings/components/ManageAddresses.page";
import { BillingInvoices } from '../BillingInvoices/BillingInvoices.page';
import {
  // UserViewedWalletPage, 
  UserViewedSettings, UserViewedChangePassword, UserViewedSupportPage, UserViewedIntegrations, UserUsingMobile, UserViewedCreateShipmentPage, UserViewedSupersetAnalytics,
  // UserViewedChargeLater, 
  UserViewedShipmentRules, UserViewedReports, UserViewedIntegrationShipments, UserViewedManageShipment, UserViewedBillingInvoices, UserViewedClaims, UserViewedB2BManageShipment
  // UserViewedAnalytics
} from "../commons/mixpanel";
import {PrintLabelSize} from "../UserSettings/components/PrintLabelSize";
import {ManageSubAccounts} from "../UserSettings/components/SubAccounts/ManageSubAccounts";
import {SubAccountSettingsView} from "../UserSettings/components/SubAccounts/SubAccountView";
import {AddSubAccount} from "../UserSettings/components/SubAccounts/AddSubAccount";
import {PayBulkOrder} from "../orders/PayBulkOrder.page";
import {PayReturnBulkOrder} from "../Returns/PayReturnBulkOrder.page"
// import {GetPriceEstimates} from '../orders/components/getPriceEstimates/GetPriceEstimates'
import {NotFound} from "../auth/NotFound.page";
import { IntegrationsListPage } from "../Integrations/IntegrationsList.page";
import { AddChannelPage } from "../Integrations/AddChannel.page";
import { CardDetails } from "../Postpaid/CardDetails";
import { useLanguage } from '../languages/Language';
import { AuthCallbackPage } from "../Integrations/AuthCallback.page";
import { ParcelDimensionRule } from "../ShipmentRules/ParcelDimensionRule";
import { ItemCategoryRule } from "../ShipmentRules/ItemCategoryRule";
import { ServiceTypeRule } from "../ShipmentRules/ServiceTypeRule";
import { ShipmentRulesListing } from "../ShipmentRules/ShipmentRulesListing";
import { IntegratedOrders } from "../Integrations/Components/IntegratedOrders";
import { useState } from 'react';
import { DROP_OFF_SERVICE_SUPPORT_EMAIL } from "../commons/utils/constants";
import ReturnSingleOrder from '../Returns/ReturnSingleOrder.page';
import {PayReturnOrder} from '../Returns/PayReturnOrder.page'
import { ReturnBulkOrder } from '../Returns/ReturnBulkOrder.page';
import {ReportsFilteredData} from '../../src/Reports/components/ReportsFilteredData';
import { ChangeAccount } from './utils/ChangeAccount';
import { EditAddress } from '../UserSettings/components/EditAddress';
import { SupersetDashboardApp } from '../SupersetEmbedDashboard/SupersetEmbedDashboard';

import home_icon from '../assets/icons/home_icon.svg';
import home_icon_selected from '../assets/icons/home_icon_selected.svg';
import analytics_icon from '../assets/icons/analytics_icon.svg';
import analytics_icon_selected from '../assets/icons/analytics_icon_selected.svg';
import shipment_icon from '../assets/icons/shipment_icon.svg';
import shipment_icon_selected from '../assets/icons/shipment_icon_selected.svg';
import b2b_shipment_icon from '../assets/icons/b2b_shipment_icon.svg';
import b2b_shipment_icon_selected from '../assets/icons/b2b_shipment_icon_selected.svg';
// import terms_and_conditions_icon from '../assets/icons/terms_and_conditions_icon.svg';
// import terms_and_conditions_icon_selected from '../assets/icons/terms_and_conditions_icon_selected.svg';
import payment_icon from '../assets/icons/payment_icon.svg';
import payment_icon_selected from '../assets/icons/payment_icon_selected.svg';
import integration_icon from '../assets/icons/integration_icon.svg';
import integration_icon_selected from '../assets/icons/integration_icon_selected.svg';
import support_icon from '../assets/icons/support_icon.svg';
import support_icon_selected from '../assets/icons/support_icon_selected.svg';
import setting_icon from '../assets/icons/setting_icon.svg';
import setting_icon_selected from '../assets/icons/setting_icon_selected.svg';
import report_icon from '../assets/icons/report_icon.svg';
import report_icon_selected from '../assets/icons/report_icon_selected.svg';
import claims_icon from '../assets/icons/claims_icon.svg';
import claims_icon_selected from '../assets/icons/claims_icon_selected.svg';
import ClaimsPage from '../Claims/Claims.page';
import SingleClaimSubmission from '../Claims/SingleClaimSubmission';
import MultiClaimSubmission from '../Claims/MultiClaimSubmission';
import { ClaimsFormTable } from '../Claims/Component/ClaimsFormTable';
import { featureFlag } from './utils/utilizer';
import { IntegrationAuthCallback } from '../Integrations/IntegrationAuthCallback.page';
import B2BOrdersWrapper from '../orders_b2b/B2BOrdersWrapper.page';
import DisputesPageWrapper from '../Disputes/Disputes.page';
import { ManageShipmentsContainer } from '../ManageShipments/ManageB2BShipmentsContainer';
import { FreightSuccess } from '../orders_b2b/Components/FreightSuccess';
import EscalateSubmission from '../Escalate/EscalateSubmission';
import { EscalateSuccess } from '../Escalate/Component/EscalateSuccess';
import { ClaimSuccess } from '../Claims/Component/ClaimSuccess';

const {SubMenu} = Menu;
// let allowed_agent_Ids = ['7172','2656','6604', '7119','106','1337','2608', '7000', '3255', '6523', '70', '7971', '3521', '8588', '8560']

export const PrivateLayout = () => {
  let { getText } = useLanguage();
  const auth = useAuth();
  const [showMobileModal, setShowMobileModal] = useState<boolean>(false);
  const [showDropOffMobileModal, setShowDropOffMobileModal] = useState<boolean>(true);
  let locationCheck = useLocation().pathname
  const [selected, setSelected] = useState<string>('home')
  // const [customReleaseFlag, setCustomReleaseFlag] = useState<boolean>(false)

  const rootSubmenuKeys = ['dashboard/analytics', 'orders', 'claims', 'shopify', 'payments', 'b2borders'];

  const [openKeys, setOpenKeys] = useState<any[]>([]);

  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  useEffect(() => {
    if(locationCheck.includes('/analytics')){
      setSelected('analytics')
    }else if(locationCheck.includes('/orders') || locationCheck.includes('/get_price_estimates') || locationCheck.includes('/pickup_calendar')){
      setSelected('shipment')
    }else if(locationCheck.includes('/b2borders')) {
      setSelected('b2bshipment')
    }
    else if(locationCheck.includes('/reports')){
      setSelected('report')
    }else if(locationCheck.includes('/claims')){
      setSelected('claims')
    }else if(locationCheck.includes('/submit_disputes')){
      setSelected('submit_disputes')
    }else if(locationCheck.includes('/integrations') || locationCheck.includes('/shipment_rules')){
      setSelected('integration')
    }else if(locationCheck.includes('/wallet') || locationCheck.includes('/card_details')){
      setSelected('payment')
    }else if(locationCheck.includes('/settings')){
      setSelected('setting')
    }else if(locationCheck.includes('/terms_conditions')){
      setSelected('terms_conditions')
    }else if(locationCheck.includes('/support')){
      setSelected('support')
    }
    else{
      setSelected('home')
    }

    // if(allowed_agent_Ids.includes(auth.user.b2c_id)) {
    //   setCustomReleaseFlag(true)
    // } else {
    //   setCustomReleaseFlag(false)
    // }

  },[locationCheck]) //eslint-disable-line react-hooks/exhaustive-deps

  const locationCheckFn = () => {
    if(locationCheck.includes('/b2borders/create') || locationCheck.includes('/b2borders/manage')) {
      return `b2b/${locationCheck.split('/')[2]}`
    }
    else if(locationCheck.includes('/orders/create')) {
      return locationCheck.split('/')[2]
    }
    
    if(locationCheck.includes('/integrated-shipments') || locationCheck.includes('/reports')) {
      return locationCheck.split('/')[2]
    }
    if(locationCheck.includes('/order_statistics') || locationCheck.includes('/delivery_performance') || locationCheck.includes('/transit_times') || locationCheck.includes('/risks_exceptions') || locationCheck.includes('/estimated_delivery_date')) {
      return locationCheck.split('/')[3]
    }
    return locationCheck.split('/')[1]
  }

  const isPostPaidAvailable = auth.user.allowPostpaid
  const isMonthlyBillingClient = auth.user.payment_type !== 'PayInstantly'
  
  let {path} = useRouteMatch();
  // if(auth.user.isSignedIn && !isSessionCurrent(auth.user)){
  //   refreshUserSession(auth);
  // }
  const onMobileDropOffModalCancel = () => {
    setShowMobileModal(true);
    setShowDropOffMobileModal(false)
  }

  const onMobileNotificationClose = () => {
    setShowMobileModal(false)
    setShowDropOffMobileModal(false)
  }

  useEffect(() => {
    if(window.screen.width < 600 && auth.user.isSignedIn && showDropOffMobileModal) {
      UserUsingMobile();
      Modal.info({
        title: <p style={{padding:10}}>Facing issues while dropping off the parcel?. Reach out to <a href={`mailto:${DROP_OFF_SERVICE_SUPPORT_EMAIL}`}>{DROP_OFF_SERVICE_SUPPORT_EMAIL}.</a></p>,
        content: <></>,
        okButtonProps : { style: { display: 'none' } },
        centered: true,
        closable: true,
        onCancel: onMobileDropOffModalCancel,
        style: {paddingRight: 15},
        bodyStyle: {margin: -15}
      });
    }
    if(window.screen.width < 600 && auth.user.isSignedIn && showMobileModal) {
      Modal.info({
        title: 'Please use a computer/tablet',
        content: 'Currently our portal do not support mobile view. Please use a computer/tablet for the best experience.',
        okButtonProps : { style: { display: 'none' } },
        centered: true,
        closable: true,
        onCancel: onMobileNotificationClose,
        style: {paddingRight: 15},
        bodyStyle: {margin: -15}
      });
    }
    // if(auth.user.isSignedIn && window.location.hostname.includes("janio.asia")
    //   ){
    //   const script = document.createElement('script')
    //   script.id = 'ze-snippet'
    //   script.src = 'https://static.zdassets.com/ekr/snippet.js?key=df25d804-038b-4b6f-91d6-05f5f63f3fc0'
    //   script.addEventListener('load', () => {
    //     //@ts-ignore
    //     window.zE(function() {
    //       //@ts-ignore
    //       window.zE.identify({
    //         name: auth.user.fullName,
    //         email: auth.user.email,
    //       });
    //       //@ts-ignore
    //       window.zE.show()
    //     })
    //   })
    //   script.async = true

    //   document.body.appendChild(script)

    //   return () => {
    //     //@ts-ignore
    //     window.zE(function() {
    //       //@ts-ignore
    //       window.zE.hide()
    //     })
    //     document.body.removeChild(script)
    //   }
    // }
    
  }, [auth,showMobileModal,showDropOffMobileModal])

  
  if (!auth.user.isSignedIn) {
    return <Redirect to={{pathname: "/auth/login"}}/>
  }

  // const content = (
  //   <>
  //     <div className='content'>
  //       Have queries? Contact us at <a href={'mailto:clientsupport@janio.asia'} style={{color: '#050593', fontWeight: 'bold'}}>clientsupport@janio.asia</a>
  //     </div>
  //   </>
  // );

  // const header = (
  //   <p className='help-header'>Contact Us</p>
  // )

  return (
    <L>
      {/* <Popover content={content} title={header} trigger="click" placement="topRight">
        <Button id='floater-contact-us' type="primary" shape="round" >Help <QuestionCircleOutlined /></Button>
      </Popover> */}
      
      <L.Sider className={'custom-sider'} width={240}>
        <div className="logo">
          <a href="#">
            <Image preview={false} src={janio_logo_header} className={"site-logo"}/>
          </a>
        </div>
        <Menu defaultSelectedKeys={['1']} defaultOpenKeys={[]} mode="inline"
          selectedKeys={[`/${locationCheckFn()}`]} openKeys={openKeys}
          onOpenChange={onOpenChange}
          className='left-nav-menu-items'
        >
          <Menu.Item key="/">
            <Link to="/"><Image style={{paddingRight: 10, height: 15}} preview={false} src={selected === 'home'? home_icon_selected : home_icon}/>{getText("Home")}</Link>
          </Menu.Item>
          {/* {!customReleaseFlag && <Menu.Item key="/analytics" style={{overflow: 'visible'}}>
            <Link 
              to="/analytics" 
              style={{position: 'relative', display: 'block'}} 
              // onClick={() => UserViewedAnalytics()}
            >
              <Image 
                style={{paddingRight: 10, height: 15, fill: '#050593'}} 
                preview={false} 
                src={selected === 'analytics'? analytics_icon_selected : analytics_icon}
                className={'left-nav-logo'}
              />{'Analytics'}
            </Link>
            
          </Menu.Item>} */}

          <>
            <SubMenu key="dashboard/analytics" icon={<><Image style={{paddingRight: 10, height: 15}} preview={false} src={selected==='analytics'? analytics_icon_selected : analytics_icon}/> <Tag color="#f50" id='new-tag' style={{right: '29px'}}>Beta</Tag></>} title={"Analytics"}>
              <Menu.Item key="/order_statistics">
                <Link to="/dashboard/analytics/order_statistics" onClick={() => UserViewedSupersetAnalytics('ORDER_STATISTICS')}>{"Order Statistics"}</Link>
              </Menu.Item>
              <Menu.Item key="/delivery_performance">
                <Link to="/dashboard/analytics/delivery_performance" onClick={() => UserViewedSupersetAnalytics('DELIVERY_PERFORMANCE')} >{"Delivery Performance"}</Link>
              </Menu.Item>
            
              <Menu.Item key="/transit_times">
                <Link to="/dashboard/analytics/transit_times" onClick={() => UserViewedSupersetAnalytics('TRANSIT_TIMES')} >{"Transit Times"}</Link>
              </Menu.Item>
              <Menu.Item key="/risks_exceptions">
                <Link to="/dashboard/analytics/risks_exceptions" onClick={() => UserViewedSupersetAnalytics('RISK_EXCEPTIONS')} >{"Risks & Exceptions"}</Link>
              </Menu.Item>
              <Menu.Item key="/estimated_delivery_date">
                <Link to="/dashboard/analytics/estimated_delivery_date" onClick={() => UserViewedSupersetAnalytics('ESTIMATED_DELIVERY_DATE')} >{"Est. Delivery Date"}</Link>
              </Menu.Item>
            </SubMenu>
          </>
          
          <SubMenu key="orders" icon={<Image style={{paddingRight: 10, height: 15}} preview={false} src={selected==='shipment'? shipment_icon_selected : shipment_icon}/>} title={"Courier Shipments"}>
            {isMonthlyBillingClient &&
              <Menu.Item key="/create">
                <Link to="/orders/create" onClick={() => UserViewedCreateShipmentPage('menu')}>{"Create Shipments"}</Link>
              </Menu.Item>
            }
            <Menu.Item key="/orders">
              <Link to="/orders?status=all" onClick={() => UserViewedManageShipment(auth?.user?.fullName)}>{getText("Manage Shipments")}</Link>
            </Menu.Item>
            
            {isMonthlyBillingClient &&
              <Menu.Item key="/integrated-shipments">
                <Link to="/orders/integrated-shipments" onClick={() => UserViewedIntegrationShipments()}>{getText("Integration Shipments")}</Link>
              </Menu.Item>
            }
            {/* {isMonthlyBillingClient && !auth.user.hide_price_estimation &&
              <Menu.Item key="/get_price_estimates">
                <Link to="/get_price_estimates" onClick={() => UserViewedRateCalculator()}>{getText("Rate Calculator")}</Link>
              </Menu.Item>
            } */}
            {/* <Menu.Item key="/pickup_calendar">
              <Link to="/pickup_calendar" onClick={() => UserViewedPickupCalendar()}>{"Pickup Calendar"}</Link>
            </Menu.Item> */}
          </SubMenu>

          {featureFlag() &&
            <SubMenu key="b2borders" icon={<Image style={{height: '18px', paddingRight: '7px', position: 'relative', top: '-4px', left: '-1px'}} preview={false} src={selected==='b2bshipment'? b2b_shipment_icon_selected : b2b_shipment_icon}/>} title={"Freight Shipments"}>
              <Menu.Item key="/b2b/create">
                <Link to="/b2borders/create" onClick={() => UserViewedCreateShipmentPage('b2bmenu')}>{"Create Shipments"}</Link>
              </Menu.Item>
              <Menu.Item key="/b2b/manage">
                <Link to="/b2borders/manage?status=all" onClick={() => UserViewedB2BManageShipment(auth?.user?.fullName)}>{"Manage Shipments"}</Link>
              </Menu.Item>
            </SubMenu>
          }

          <Menu.Item key="/filters">
            <Link to="/reports/filters" onClick={() => UserViewedReports(auth?.user?.fullName)}><Image style={{paddingRight: 10, height: 15}} preview={false} src={selected === 'report'? report_icon_selected : report_icon}/>{"Reports"}</Link>
          </Menu.Item>

          {featureFlag() && <SubMenu key="claims" icon={<Image style={{paddingRight: 10, height: 15}} preview={false} src={selected === 'claims'? claims_icon_selected: claims_icon}/>} title={"Claims & Disputes"}>
            <Menu.Item key="/claims">
              <Link to="/claims" onClick={() => UserViewedClaims()}>{"Manage"}</Link>
            </Menu.Item>

            <Menu.Item key="/submit_disputes">
              <Link to="/submit_disputes" >{"Submit Disputes"}</Link>
            </Menu.Item>
          </SubMenu>}

          <Menu.Item key="/billing">
            <Link to="/billing" onClick={() => UserViewedBillingInvoices()}><Image style={{paddingRight: 10, height: 15}} preview={false} src={selected === 'billing'? payment_icon_selected : payment_icon}/>{"Billing & Invoices"}</Link>
          </Menu.Item>

          {isMonthlyBillingClient &&
            <SubMenu key="shopify" icon={<Image style={{paddingRight: 10, height: 15}} preview={false} src={selected === 'integration'? integration_icon_selected : integration_icon}/>} title={"Integrations"}>
              <Menu.Item key="/integrations">
                <Link to="/integrations" onClick={() => UserViewedIntegrations()}>{"Integrations"}</Link>
              </Menu.Item>

              <Menu.Item key="/shipment_rules">
                <Link to="/shipment_rules" onClick={() => UserViewedShipmentRules()}>{getText("Shipment Rules")}</Link>
              </Menu.Item>
            </SubMenu>
          }

          {/* Hiding payment tab */}
          {/* <SubMenu key="payments" icon={<Image style={{paddingRight: 10, height: 15}} preview={false} src={selected === 'payment'? payment_icon_selected : payment_icon}/>} title={getText("Payments")}>
            <Menu.Item key="/wallet">
              <Link to="/wallet" onClick={() => UserViewedWalletPage()}>{getText("Janio Credit")}</Link>
            </Menu.Item>

            {isPostPaidAvailable &&
              <Menu.Item key="/card_details">
                <Link to="/card_details" onClick={() => UserViewedChargeLater()}>Charge Later</Link>
              </Menu.Item>
            }
          </SubMenu> */}

          <Menu.Item key="/settings">
            <Link to="/settings" onClick={() => UserViewedSettings()}><Image style={{paddingRight: 10, height: 15}} preview={false} src={selected === 'setting'? setting_icon_selected : setting_icon}/>{getText("Settings")}</Link>
          </Menu.Item>

          {/* <Menu.Item key="/terms_conditions">
            <Link to="/terms_conditions" onClick={() => UserViewedTermAndConditions()}><Image style={{paddingRight: 10, height: 15}} preview={false} src={selected === 'terms_conditions'? terms_and_conditions_icon_selected : terms_and_conditions_icon}/>{getText("Terms & Conditions")}</Link>
          </Menu.Item> */}
          <Menu.Item key="/support" style={{marginBottom: 70}}>
            <Link to="/support" onClick={() => UserViewedSupportPage()}><Image style={{paddingRight: 10, height: 15}} preview={false} src={selected === 'support'? support_icon_selected : support_icon}/>{getText("Support")}</Link>
          </Menu.Item>
          <Menu.Item key="/logout" className='logout-left-nav-fixed' >
            <Link 
              to=""
              style={{color: '#050593'}}
              onClick={() => {
                auth.signOut()
                if(localStorage.getItem('allow_custom_tracking_no')) {
                  localStorage.removeItem('allow_custom_tracking_no')
                }
              }}>
              <LogoutOutlined/>Logout
            </Link>
          </Menu.Item>
        </Menu>
      </L.Sider>
      <L>
        {/* <L.Header className={'custom-header'}>
          <Row justify="end">
            <Space size="large">
              <Col className={"select-language"}>
                <LanguageSelector />
              </Col>
              <Col>
                <Button
                  type="link"
                  style={{marginRight: '1em'}}
                  icon={<LogoutOutlined/>}
                  onClick={() => {
                    auth.signOut()
                    if(localStorage.getItem('allow_custom_tracking_no')) {
                      localStorage.removeItem('allow_custom_tracking_no')
                    }
                  }}
                />
              </Col>
            </Space>
          </Row>
        </L.Header> */}
        <L.Content className={'custom-content'}>
          {(auth.user.isSignedIn && (!auth.user.hasOwnProperty('tnc') || auth.user.tnc === false)) && <NewTCModal/>}
          <Switch>
            <Route path={`${path}/`}>
              <Dashboard />
            </Route>

            <Route path={`/analytics`}>
              <NewDashboard />
            </Route>

            <Route path={`/dashboard/analytics/:id/:id?`} exact>
              <Switch>
                <Route path={"/dashboard/analytics/order_statistics/:id?"}>
                  <SupersetDashboardApp />
                </Route>
              </Switch>
              <Switch>
                <Route path={"/dashboard/analytics/delivery_performance/:id?"}>
                  <SupersetDashboardApp />
                </Route>
              </Switch>
              <Switch>
                <Route path={"/dashboard/analytics/transit_times/:id?"}>
                  <SupersetDashboardApp />
                </Route>
              </Switch>
              <Switch>
                <Route path={"/dashboard/analytics/risks_exceptions/:id?"}>
                  <SupersetDashboardApp />
                </Route>
              </Switch>
              <Switch>
                <Route path={"/dashboard/analytics/estimated_delivery_date/:id?"}>
                  <SupersetDashboardApp />
                </Route>
              </Switch>
            </Route>

            <Route path={`/orders`} >
              <Switch>
                <Route path="/orders" exact>
                  <ShipmentsPage />
                </Route>
                <Route path="/orders/view_shipment_details/:id" exact>
                  <ViewShipmentDetail/>
                </Route>
                {/* <Route path="/orders/mps/view_shipment_details/:id" exact>
                  <ViewMpsShipmentDetails/>
                </Route> */}

                <Route path="/orders/create" exact >
                  {isMonthlyBillingClient ? <CreateShipment/> : <NotFound />}
                </Route>

                <Route path={`/orders/create/single`}>
                  {isMonthlyBillingClient ? <SingleOrder/> : <NotFound />}
                </Route>

                <Route path={`/orders/create/return/single`}>
                  {isMonthlyBillingClient ? <ReturnSingleOrder/> : <NotFound />}
                </Route>

                <Route path={`/orders/pay/single/:id`} exact>
                  {isMonthlyBillingClient ? <PayOrder/> : <NotFound />}
                </Route>

                <Route path={`/orders/pay/shopify/single/:id`} exact>
                  {isMonthlyBillingClient ? <PayOrderShopify/> : <NotFound />}
                </Route>

                <Route path={`/orders/pay/return/single/:id`} exact>
                  {isMonthlyBillingClient ? <PayReturnOrder/> : <NotFound />}
                </Route>

                <Route path={`/orders/marketplace/pay/single/:id`} exact>
                  {isMonthlyBillingClient ? <PayMarketplaceOrder /> : <NotFound />}
                </Route>\

                <Route path={`/orders/pay/bulk/:batch_no`} exact>
                  {isMonthlyBillingClient ? <PayBulkOrder/> : <NotFound />}
                </Route>

                <Route path={`/orders/pay/return/bulk/:batch_no`} exact>
                  {isMonthlyBillingClient ? <PayReturnBulkOrder/>  : <NotFound />}
                </Route>

                <Route path={'/orders/success/b2b-freight'} exact>
                  <FreightSuccess />
                </Route>

                <Route path={'/orders/success/:returns?'} exact>
                  <TopUpSuccess />
                </Route>

                <Route path={`/orders/create/bulk`} exact >
                  {isMonthlyBillingClient ? <BulkOrder/> : <NotFound />}
                </Route>

                {/* <Route path={`/orders/create/mps`} exact >
                  {isMonthlyBillingClient ? <MpsBulkOrder/> : <NotFound />}
                </Route> */}

                <Route path={`/orders/create/return/bulk`} exact >
                  {isMonthlyBillingClient ? <ReturnBulkOrder/> : <NotFound />}
                </Route>

                <Route path={`/orders/integrated-shipments`} exact >
                  {isMonthlyBillingClient ? 
                    <>
                      <IntegratedOrders/>
                    </> : 
                    <NotFound />
                  }
                </Route>
              </Switch>
            </Route>

            {featureFlag() &&
              <Route path={`/b2borders`} >
                <Switch>
                  <Route path="/b2borders/manage" exact>
                    <ManageShipmentsContainer />
                  </Route>

                  <Route path="/b2borders/create" exact >
                    {isMonthlyBillingClient ? <B2BOrdersWrapper /> : <NotFound />}
                  </Route>
                </Switch>
              </Route>
            }
            
            <Route path={`/integrations`}>
              <Switch>
                <Route path={`/integrations/`} exact>
                  {isMonthlyBillingClient ? <IntegrationsListPage /> : <NotFound />}
                </Route>
                <Route path={`/integrations/add`}>
                  {isMonthlyBillingClient ? <AddChannelPage /> : <NotFound />}
                </Route>
              </Switch>
            </Route>

            <Route path={`/callback`}>
              <AuthCallbackPage />
            </Route>

            <Route path={`/shopify-integration`}>
              <IntegrationAuthCallback />
            </Route>

            {/* <Route path={`/terms_conditions`}>
              <h1 className="ant-page-header-heading-title page-title">{getText('Terms & Conditions')}</h1>
              <NewTermsAndConditionsPage/>
            </Route> */}
            <Route
              path={`/support/:defaultKey?`}>
              <h1 className={`ant-page-header-heading-title page-title ${auth.user.b2c_id === '4214' ? 'negative-mb-12' : ''}`}>{getText('Support')}</h1>
              <NeedHelpPage/>
            </Route>

            <Route path={`/settings`}>
              <Switch>
                <Route path={`/settings`} exact>
                  <h1 className={`ant-page-header-heading-title page-title ${auth.user.b2c_id === '4214' ? 'negative-mb-12' : ''}`}>{getText('Settings')}</h1>
                  <UserSettings/>
                </Route>

                <Route
                  path={`/settings/profile`}
                  render={() => {
                    UserViewedChangePassword()
                    return <UpdateProfile/>
                  }
                  }>
                </Route>
                <Route path={`/settings/locations`}>
                  <ManageAddressesPage/>
                </Route>
                <Route path={`/settings/print_label_size`}>
                  <PrintLabelSize/>
                </Route>
                <Route path={`/settings/manage_accounts`}>
                  <ManageSubAccounts/>
                </Route>
                <Route path={`/settings/add_sub_account`}>
                  <AddSubAccount/>
                </Route>
                <Route path={`/settings/sub_account_view`}>
                  <SubAccountSettingsView/>
                </Route>
                <Route
                  path={`/settings/notifications`}
                  render={() => {
                    return <Notifications />
                  }
                  }>
                </Route>
                <Route path={`/settings/edit/address/:id`} exact>
                  {isMonthlyBillingClient ? <EditAddress /> : <NotFound />}
                </Route>
              </Switch>
            </Route>

            {/* <Route
              path={`/get_price_estimates`}>
              {(auth.user.hide_price_estimation || !isMonthlyBillingClient) ? <NotFound /> : <GetPriceEstimates />}
            </Route> */}

            {/* <Route path={`/pickup_calendar`}>
              <h1 className="ant-page-header-heading-title page-title">{'Pickup Calendar'}</h1>
              <PickupCalendar/>
            </Route> */}

            { isPostPaidAvailable &&
              <Route path={`/card_details`} >
                <Switch>
                  <Route path="/card_details">
                    <CardDetails />
                  </Route>
                </Switch>
              </Route>
            }

            <Route path={`/shipment_rules/`} >
              {isMonthlyBillingClient ? 
                <Switch>
                  <Route path="/shipment_rules/parcel_dimension">
                    <ParcelDimensionRule />
                  </Route>

                  <Route path={`/shipment_rules/edit/parcel_dimension/:id`} exact>
                    <ParcelDimensionRule/>
                  </Route>

                  <Route path="/shipment_rules/item_category">
                    <ItemCategoryRule />
                  </Route>

                  <Route path={`/shipment_rules/edit/item_category/:id`} exact>
                    <ItemCategoryRule />
                  </Route>

                  <Route path="/shipment_rules/service">
                    <ServiceTypeRule />
                  </Route>

                  <Route path={`/shipment_rules/edit/service/:id`} exact>
                    <ServiceTypeRule />
                  </Route>

                  <Route path={`/shipment_rules/`} exact>
                    <ShipmentRulesListing />
                  </Route>
                  
                </Switch> : 
                <NotFound />
              }
              
            </Route>

            <Route path="/reports/">
              <Switch>
                <Route path={`/reports/filters`} exact>
                  <h1 className={`ant-page-header-heading-title page-title ${auth.user.b2c_id === '4214' ? 'negative-mb-12' : ''}`}>{'Reports'}</h1>
                  <ReportsFilteredData/>
                </Route>
              </Switch>
            </Route>

            {featureFlag() && 
              <Route path="/claims/">
                <Switch>
                  <Route path={`/claims`} exact>
                    <ClaimsPage/>
                  </Route>
                  <Route path={`/claims/submission/:claimId`} exact>
                    <SingleClaimSubmission/>
                  </Route>
                  <Route path={`/claims/multi`} exact>
                    <MultiClaimSubmission/>
                  </Route>
                  <Route path={`/claims/form`} exact>
                    <ClaimsFormTable />
                  </Route>
                  <Route path={`/claims/success`} exact>
                    <ClaimSuccess/>
                  </Route>
                </Switch>
              </Route>
            }

            {featureFlag() && 
              <Route path="/escalate/">
                <Switch>
                  <Route path={`/escalate`} exact>
                    <EscalateSubmission/>
                  </Route>
                  <Route path={'/escalate/success/'} exact>
                    <EscalateSuccess />
                  </Route>
                </Switch>
              </Route>
            }

            {featureFlag() && 
              <Route path="/submit_disputes/">
                <Switch>
                  <Route path={`/submit_disputes`} exact>
                    <DisputesPageWrapper />
                  </Route>
                  {/* <Route path={`/claims/submission/:claimId`} exact>
                    <SingleClaimSubmission/>
                  </Route>
                  <Route path={`/claims/multi`} exact>
                    <MultiClaimSubmission/>
                  </Route>
                  <Route path={`/claims/form`} exact>
                    <ClaimsFormTable />
                  </Route> */}
                </Switch>
              </Route>
            }
            

            <Route path="/billing">
              <Switch>
                <Route path={`/billing`} exact>
                  <h1 className={`ant-page-header-heading-title page-title ${auth.user.b2c_id === '4214' ? 'negative-mb-12' : ''}`}>{'Billing & Invoices'}</h1>
                  <BillingInvoices/>
                </Route>
              </Switch>
            </Route>

            <Route path="/switch_accounts/:token/:name?">
              <ChangeAccount/>
            </Route>

            {/* if url not found */}
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </L.Content>
      </L>
    </L>
  )
};