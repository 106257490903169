import {
  Card,
  Row,
  Col,
  Steps
} from 'antd'
import React, {useEffect, useState} from 'react';
import {ShipmentDetailsForm, ShipmentDetailsFormTitle} from "./components/singleOrder/ShipmentDetailsForm";
import {ConsigneeDetailsForm, ConsigneeDetailsFormTitle} from './components/singleOrder/ConsigneeDetailsForm';
import {ItemDetailsForm, ItemDetailsFormTitle} from "./components/singleOrder/ItemDetailsForm";
import {PriceEstimateForm, PriceEstimateFormTitle} from "./components/singleOrder/PriceEstimateForm";
import './styles/ShipmentPage.css';
import '../dashboard/styles/HomePage.css';
import {Link} from "react-router-dom";
import {CaretLeftOutlined} from '@ant-design/icons'
import {
  // SingleOrderContextProvider, 
  SingleOrderContextProviderForB2C} from './services/singleOrderContext';
import {RouteLeavingGuard} from "../commons/components/RouteGuard/RouteGuard";
import {RouteLeavingGuardContextProvider} from "../commons/components/RouteGuard/routeGuardContext";

const {Step} = Steps;

const OrderForm = () => {
  const [current, setCurrent] = useState(0);
  const [progress, setProgress] = useState(0);
  const setCurrentState = (val: number) => {
    setCurrent(val);
  };
  const steps = [
    {
      title: <ShipmentDetailsFormTitle current={current} setCurrentState={setCurrentState} dataFilled={0} />,
      content: <ShipmentDetailsForm current={current} setCurrentState={setCurrentState} dataFilled={0} />,
    },
    {
      title: <ConsigneeDetailsFormTitle current={current} setCurrentState={setCurrentState} dataFilled={0} />,
      content: <ConsigneeDetailsForm current={current} setCurrentState={setCurrentState} dataFilled={0} />,
    },
    {
      title: <ItemDetailsFormTitle current={current} setCurrentState={setCurrentState} dataFilled={0}/>,
      content: <ItemDetailsForm current={current} setCurrentState={setCurrentState} dataFilled={0}/>,
    },
    {
      title: <PriceEstimateFormTitle current={current} setCurrentState={setCurrentState} />,
      content: <PriceEstimateForm current={current} setCurrentState={setCurrentState} />,
    },
  ];

  useEffect(() => {
    if (current > progress) {
      setProgress(current);
    }
  }, [current, progress]);

  return (
    <>
      <Steps direction="vertical" current={current} className={"form-progress-bar"}>
        {steps.map((item, index) => (
          <Step key={index} title={item.title}
            description={(current >= index || progress >= index) ? item.content : ''}/>
        ))}
      </Steps>
      <RouteLeavingGuard/>
    </>
  )
};


export const SingleOrder = () => {
  return (
    <Row align="middle" justify="center">
      <Col span={16} className={'order-form-container'}>
        <Link to={"/orders/create"}>
          <p className="go-back-btn"><CaretLeftOutlined/> Create shipment </p>
        </Link>
        <Card className={'form-card'}>
          <SingleOrderContextProviderForB2C>
            <RouteLeavingGuardContextProvider>
              <OrderForm/>
            </RouteLeavingGuardContextProvider>
          </SingleOrderContextProviderForB2C>
        </Card>
      </Col>
    </Row>
  )
};
