import {
  Redirect,
} from "react-router-dom"
import { Spin, Alert } from "antd";

import { contactCustomerService } from "../commons/utils/constants";
import { useAuth } from "../auth/services/authContext";
import { onboardShopify } from "./services";
import { useState, useEffect, ReactElement } from "react";
import { useLanguage } from "../languages/Language";

export const AuthCallbackPage = () => {
  console.log('firstfirst')
  const auth = useAuth();
  const { getText } = useLanguage();
  
  const [callbackResponse, setCallbackResponse] = useState("");
  const authToken = auth.user.authToken;
  const params = new URLSearchParams(window.location.search);
  let alertMsg: string | ReactElement = callbackResponse;

  useEffect(() => {
    onboardShopify(
      authToken,
      params.get("code") || "",
      params.get("host") || "",
      params.get("hmac") || "",
      params.get("shop") || "",
      params.get("timestamp") || ""
    ).then(result =>
    {
      setCallbackResponse(result)
    })
  },[authToken]) // eslint-disable-line react-hooks/exhaustive-deps

  if (callbackResponse === 'success') {
    return <Redirect to={{pathname: "/integrations"}} />
  } else if (callbackResponse === 'Something went wrong, please contact') {
    alertMsg = (<span>{getText("Something went wrong, please contact")} {contactCustomerService()} </span>)
  }
  else{
    alertMsg = callbackResponse;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        margin: 0,
      }}
    >
      {!callbackResponse && <Spin />}
      {callbackResponse && (
        <Alert
          message="Error"
          description={alertMsg}
          type="warning"
          showIcon
          closable
        />
      )}
    </div>
  );
};
