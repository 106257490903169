export const NewTermsAndConditions = () => {
  
  return (
    <>
      <p className="c1"><span className="heading">TERMS AND CONDITIONS OF SERVICES 2022</span></p>
      <p className="c1"><span className="c0">These Terms and Conditions will take effect starting in 2022.</span></p>
      <p className="c1"><span className="c0">TERMS AND CONDITIONS OF SERVICES OF JANIO (“STANDARD T&Cs”)</span></p>
      <p className="c1"><span className="c0">Except as otherwise expressly agreed in writing, the following terms and conditions will apply to the relationship between the Supplier (as defined in the Service Agreement) and the Customer (as defined in the Service Agreement, each a “Party”, and collectively, the “Parties”).</span></p>
      <p className="c1 c3"><span className="c0"></span></p>
 	    <p className="c1 c3"><span className="c0">1. &nbsp; &nbsp;BASIS OF AGREEMENT</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;1.1 &nbsp; &nbsp;The Customer agrees to accept the Services and the Supplier shall provide the Services, in accordance with this Standard Terms and Conditions, and on the Rates as reflected in the Quotation or Supplier’s Order Database (as the case may be), upon:</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;1.1.1 &nbsp; &nbsp;the issuance of a means a written confirmation issued by the Supplier to the Customer accepting the Customer’s Order, reflecting the Quotation as accepted by the Customer; or</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;1.1.2 &nbsp; &nbsp;once the Order has been successfully recorded in the Supplier’s Order Database,whichever is applicable.</span></p>
      <p className="c1 c3"><span className="c0">2. &nbsp; &nbsp;SUPPLY OF SERVICES</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;2.1 &nbsp; &nbsp;</span>The relevant portion of this Clause 2 shall apply where applicable based on the specific Service(s) supplied by the Supplier to the Customer as set out in Schedule 3.</p>
      <p className="c1"><strong>General terms and conditions</strong></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;2.2 &nbsp; &nbsp;The Supplier shall supply the Services to the Customer in accordance with the Supply Conditions, which apply to the exclusion of any other terms and conditions that the Customer seeks to impose or incorporate, or which are implied by trade, custom, practice or course of dealing, unless otherwise agreed to in writing by the Supplier.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;2.3 &nbsp; &nbsp;The Supplier shall use commercially reasonable efforts to arrange for the delivery of the Goods to be made to the Receiving Address specified in an Order subject to all applicable laws and regulations for the importation of goods of the Territory.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;2.4 &nbsp; &nbsp;The Supplier shall notify the Customer of any requirements for the importation of goods in the Territory. If Customer is unable to meet such requirements and the Parties are unable to agree upon an alternate solution that complies with all applicable laws and regulations, the Supplier may reject the Order without any Liability after informing the Customer about the same.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;2.5 &nbsp; &nbsp;The Supplier shall use any Personal Data received whether of the Customer or the Customer’s Recipients in accordance with the Supplier’s Personal Data Protection Policy.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;2.6 &nbsp; &nbsp;The Supplier reserves the right to amend or supplement the Supply Conditions from time to time:</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.6.1 &nbsp; &nbsp;to comply with any applicable law or regulatory requirement (in jurisdictions where the Customer and Supplier and /or the Supplier’s Group Entity operates); or</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.6.2 &nbsp; &nbsp;if the amendment(s) will not materially affect the nature and/or quality of the Services;</span></p>
      <p className="c1"><span className="c0">and the Supplier shall notify the Customer in writing of any such amendment(s). For the avoidance of doubt, such amendments shall only apply to Orders made by the Customer after the date of such amendment(s), and shall not have retrospective effect on any Orders previously made by the Customer.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;2.7 &nbsp; &nbsp;In order to provide the Services, the Supplier may carry out the following:</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.7.1 &nbsp; &nbsp;complete any documents, amend product or services codes and pay any Duties and Taxes Charges;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.7.2 &nbsp; &nbsp;act as the Customer’s forwarding agent for customs and export control purposes and as a receiver solely for the purpose of designating a customs broker to perform customs clearance and entry;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.7.3 &nbsp; &nbsp;redirect the Shipment to the Recipient’s customs broker or other addresses upon request by the Recipient or any person whom the Supplier in its reasonable opinion believes to be authorised; and</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.7.4 &nbsp; &nbsp;do all things as may be required or necessary for completion of the Order(s), including any further matters as may be set out in the schedule “Other Supply of Services Conditions”.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;2.8 &nbsp; &nbsp;Save as provided in Clause 4.7, the Supplier shall provide a Proof of Delivery on request by the Customer and such Proof of Delivery may be provided up to ninety (90) calendar days from the date of receipt of the Shipment, or where the Proof of Delivery is kept for less than ninety (90) calendar days from the date of receipt of the Shipment by the Logistic Provider(s) then such number of days shall apply.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;2.9 &nbsp; &nbsp;In the event of the occurrence of a Force Majeure Event:</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.9.1 &nbsp; &nbsp;the Supplier shall not incur any Liabilities for any delays in any stage of its Services; and/or any costs, expenses, damages and/or losses sustained or incurred by the Customer; and</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.9.2 &nbsp; &nbsp;the affected Party shall be entitled to a reasonable extension of the time for performing its obligations. If the period of delay or non-performance continues for more than thirty (30) calendar days, the unaffected Party may terminate the Service Agreement by giving a one (1) week written notice to the affected Party.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;2.10 &nbsp; &nbsp;In the event that the Goods are held in Storage, the Customer shall pay the Storage Fee.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;2.11 &nbsp; &nbsp;All customer service enquiries shall be directed to the Supplier at <a>clientsupport@janio.asia</a> and the Supplier shall respond to such customer service enquiries within five (5) Business Days of the receipt of the aforementioned enquiries.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;2.12 &nbsp; &nbsp;Notwithstanding anything in these Standard Terms and Conditions, but subject to any applicable Compensations as set out in Schedule 2, no Service Level Agreement Claims can be brought against the Supplier. For the avoidance of doubt, a failure to meet the Service Level Agreement does not constitute a breach of the Service Agreement.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;2.13 &nbsp; &nbsp;Notwithstanding anything in the Service Agreement, the Parties agree that the Supplier shall be entitled to nominate or appoint any of its Group Entity to perform all or part of the Supplier’s obligations under the Service Agreement.</span></p>
      <p className="c1"><strong>Shipments</strong></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;2.14 &nbsp; &nbsp;The delivery of the Goods is subject to the following terms and conditions:</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.14.1 &nbsp; &nbsp;Shipments shall be delivered to the Receiving Address provided by the Customer, but not necessarily to the named Recipient personally.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.14.2 &nbsp; &nbsp;The Supplier may deliver the Shipment to a central receiving area if the Receiving Address has a central area for the collection of goods and parcels.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.14.3 &nbsp; &nbsp;The Supplier shall on a best effort basis attempt to fulfil the Services in accordance with the relevant Service Level Agreement as set out in the Rates.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.14.4 &nbsp; &nbsp;Any other terms and conditions in the Service Agreement.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;2.15 &nbsp; &nbsp;In the event of:</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.15.1 &nbsp; &nbsp;an Unacceptable Shipment; or</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.15.2 &nbsp; &nbsp;a Shipment that has been undervalued for customs purposes,</span></p>
      <p className="c1"><span className="c0">the Supplier shall have the right to reject the Unacceptable Shipment and the Supplier shall notify the Customer of the Unacceptable Shipment. The
                                             Customer may elect to (1) trigger the Return to Shipper Mechanism, (2)
                                             request for a disposal of the Unacceptable Shipment, (3) request for a release of the Unacceptable Shipment, or (4) request for a sale of the UnacceptableShipment, subject always to the right of the Supplier to destroy any Shipment which any law prevents the Supplier from returning to the Customer or conducting a sale of. In the event that the Customer does not make an Election within the Return to Shipper Election Period, the Supplier where applicable shall use commercially reasonable efforts to implement the Return to Shipper Mechanism,  failing which, the Shipment may be released, disposed of or sold without incurring any Liability whatsoever to the Supplier or anyone else. The proceeds from such sale is applied against any applicable Charges and other administrative costs with the balance of the proceeds returned to the Customer. For the avoidance of doubt, the Customer remains liable for all applicable Charges as regards the Unacceptable Shipment in addition to any other costs, expenses, fees and taxes incurred by the Supplier as a result of the implementation of the Return to Shipper Mechanism, the release, disposal or sale of such Unacceptable Shipment.Notwithstanding the aforementioned, the Supplier may in its sole and absolute discretion choose to re-label and/or re-package a Shipment or Package so that it is no longer an Unacceptable Shipment and may be delivered, and in such an event, the Customer shall pay for all costs, expenses, fees and taxes incurred by the Supplier as a result of such re-labelling or re-packaging.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;2.16 &nbsp; &nbsp;In the event that the Goods, which are not Unacceptable Shipment, fail to meet all customs rules and regulations of the Territory due to the lack of co-operation by the Customer or Consignee in any way and the Supplier (or the Logistic Provider as the case may be) is unable to achieve Customs Clearance within seven (7) Business Days from the date the Goods reach the Territory, the Supplier shall dispose of the Goods unless the Customer requests otherwise and in any event the Customer shall bear all applicable Charges.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;2.17 &nbsp; &nbsp;In the event that Goods are accepted or dealt with upon instructions to collect freight, Duties and Taxes Charges or other expenses from the Consignee or any other person, the Customer shall remain liable to pay for the same or any balance thereof plus any interest if the Supplier is not paid or not fully paid by such Consignee or other persons immediately when due. If the Consignee refuses to pay the applicable freight, Duties and Taxes Charges and/or other Charges for the Shipment:</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.17.1 &nbsp; &nbsp;the Supplier shall withhold such Shipment up till seven (7) Business Days until the required Duties and Taxes Charges or other Charges have been paid for otherwise the Supplier shall dispose off the Goods unless otherwise requested by the Customer;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.17.2 &nbsp; &nbsp;the Supplier may in its sole discretion upon written request by the Customer, pay the applicable Duties and Taxes Charges and/or other Charges for the Shipment first on behalf of the Consignee, and in such an event the Customer shall indemnify the Supplier for the amount paid;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.17.3 &nbsp; &nbsp;in the event that the Supplier pays the applicable Duties and Taxes Charges and/or other Charges for the Shipment first on behalf of the Consignee, Customer or the relevant shipper, for whatsoever reason, the Customer shall indemnify the Supplier for the amount paid; and</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.17.4 &nbsp; &nbsp;for the avoidance of doubt, the Supplier shall invoice the Customer for the abovementioned paid Charges in accordance with Clause 4.4 below.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;2.18 &nbsp; &nbsp;</span> In the event of an Uncollected Shipment, the Supplier shall notify the Customer and the Customer may elect to (1) dispose the Uncollected Shipment, (2) request for a Repeat Delivery, or (3) to trigger the Return to Shipper Mechanism. If the Customer does not make an Election within the Return to Shipper Election Period, the Supplier shall dispose off the Uncollected Shipment. In the case that the Return to Shipper Mechanism is triggered:</p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.18.1 &nbsp; &nbsp;</span>the returned Shipment will be deemed to be a new Order;</p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.18.2 &nbsp; &nbsp;the applicable Charges for the new Order will be calculated based on the Rates in the Quotation; and</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.18.3 &nbsp; &nbsp;</span>the Customer remains liable to pay the Charges for the Uncollected Shipment.</p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;2.19 &nbsp; &nbsp;</span>The Parties agree that if required for safety, security, customs or other regulatory reasons, the Supplier shall be entitled to open and inspect a Shipment without incurring any liability whatsoever. The Supplier shall inform the Customer of such an action as soon as practicable.</p>
      <p className="c1"><strong>Cancellation and/or modification of Order(s)</strong></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;2.20 &nbsp; &nbsp;In the event of any cancellation and/or modification of an Order(s) by the Customer:</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.20.1 &nbsp; &nbsp;The Customer remains liable to pay all applicable Charges for the Services so long as the Supplier has achieved the First Touchpoint; the Customer may elect to (i) collect the cancelled / modified Order from the Customer warehouse at the Territory; (ii) have the cancelled / modified Order auctioned off; (iii) request for the Supplier to dispose off the Cancelled Order subject to a fee to be determined by the Supplier; or (iv) request for the Supplier to implement the Return to Shipper Mechanism. If the Customer does not make an Election within the Return to Shipper Election Period, the Supplier shall have the right to make the aforesaid election on behalf of the Customer and the Customer shall pay for all costs, expenses, fees and taxes incurred by the Supplier as a result of the election on behalf of the Customer.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.20.2 &nbsp; &nbsp;For the avoidance of doubt, in the event that the Supplier implements the Return to Shipper Mechanism, the return of the Goods shall be deemed a new Order and the Customer shall pay for all costs, expenses, fees and taxes incurred by Supplier as a direct result of any such cancellation or modification with any applicable Charges calculated based on the Rates in the Quotation; and</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.20.3 &nbsp; &nbsp;The Customer shall be required to pay all applicable Charges for the Order in the event that the Supplier does not permit the cancellation and/or modification of an Order.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;2.21 &nbsp; &nbsp;Notwithstanding Clause 2.20, in the event that the Receiving Address is modified and the Customer requests for a redirection of an Order to a new Receiving Address (the <b>“Second Receiving Address”</b>) after the Supplier has fulfilled the First Touchpoint:</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.21.1 &nbsp; &nbsp;The Supplier shall deliver the Goods to the Second Receiving Address and the number of attempts of delivery made in this Clause 2.21.1 shall be taken into account for calculating the number of delivery attempts. A delivery attempt is deemed to have been made, upon fulfilment of the First Touchpoint and the Shipment or Package (as the case may be) has reached the Receiving Address;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.21.2 &nbsp; &nbsp;For delivery of the Shipment or Package (as the case may be) to the Second Receiving Address under Clause 2.21.1, the Customer shall not be liable to pay the Supplier the costs, expenses, fees and taxes incurred by the Supplier for the re-delivery, save where the total amount of Charges for the re-delivery to the Second Receiving Address differs from the Charges for delivery to the original Receiving Address, and such new Charges shall be based on the Rates.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.21.3 &nbsp; &nbsp;For delivery of the Shipment to the Second Receiving Address which is not included in Clause 2.21.1, such re-delivery shall be deemed as a new Order and for the avoidance of doubt, the Customer shall pay all the applicable Charges for the initial Order and the new Charges based on the Rates for this new Order.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;2.22 &nbsp; &nbsp;The Supplier shall permit the cancellation of an Order if and only if:</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.22.1 &nbsp; &nbsp;the cancellation is communicated to the Supplier before the First Touchpoint; and</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;2.22.2 &nbsp; &nbsp;the cancellation is made by the Customer itself.</span></p>
      <p className="c1 c3"><span className="c0">3. &nbsp; &nbsp; CUSTOMER’S OBLIGATIONS</span></p>
      <p className="c1 c3"><span className="c0">&nbsp; &nbsp;3.1 &nbsp; &nbsp;The Customer shall:</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;3.1.1 &nbsp; &nbsp;ensure that the terms of the Order are complete and accurate;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;3.1.2 &nbsp; &nbsp;co-operate with the Supplier in all matters relating to the Services and comply with all the relevant additional requirements in Schedule 1;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;3.1.3 &nbsp; &nbsp;provide the Supplier, the relevant Logistic Provider(s), their employees, agents, consultants and subcontractors, with access to the Customer’s premises, office accommodation and other facilities as reasonably required by the Supplier and/or Logistic Provider(s) to perform the Services and/or complete the Order(s);</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;3.1.4 &nbsp; &nbsp;provide the Supplier with such information and materials as the Supplier may reasonably require in order to supply the Services, including but not limited to all details and documentations required for Customs Clearance, and ensure that such information is complete and accurate in all material respects;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;3.1.5 &nbsp; &nbsp;obtain and maintain all necessary licences permissions and consents which may be required for the export and/or import of the Goods before the Effective Date;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;3.1.6 &nbsp; &nbsp;use an application programming interface with the Supplier or use the Supplier’s system to ensure that any Order(s) can be automatically received on the Order Database;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;3.1.7 &nbsp; &nbsp;obtain the permission of the Recipient to allow the Supplier to use the Personal Data of the Recipient for the purposes of (i) conducting surveys; and (ii) sending notifications to the Recipient in relation to the Services, and for the avoidance of doubt such permission may be withdrawn by the Recipient at any time;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;3.1.8 &nbsp; &nbsp;comply with all applicable laws, including the Data Protection Legislation; and</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;3.1.9 &nbsp; &nbsp;comply with any additional obligations imposed by the Logistics Provider(s).</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;3.2 &nbsp; &nbsp;</span>If the Supplier’s performance of any of its obligations under the Service Agreement is prevented or delayed due to the Customer’s default:</p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;3.2.1 &nbsp; &nbsp;</span>then without limitation and prejudice to any other right or remedy available to the Supplier, the Supplier shall have the right to:</p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;a. &nbsp; &nbsp; &nbsp;&nbsp;suspend performance of the Services until the Customer remedies the Customer’s default; and</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;b. &nbsp; &nbsp;&nbsp;&nbsp;rely on the Customer’s default to relieve itself from the performance of any of its obligations to the extent that the Customer’s default prevents or delays the Supplier’s performance of any of its obligations;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;3.2.2 &nbsp; &nbsp;</span>the Customer shall reimburse the Supplier on written demand, and payment shall be made within 30 calendar days of the date of such demand, for all actual and direct costs or losses sustained or incurred by the Supplier arising directly from the Customer’s.</p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;3.3 &nbsp; &nbsp;</span>The Customer is responsible for ensuring that the Shipment is not an Unacceptable Shipment.</p>
      <p className="c1 c3"><span className="c0">4.&nbsp; &nbsp;CHARGES AND PAYMENT</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;4.1 &nbsp; &nbsp;The applicable Charges for the Services are set out in each Quotation and/or otherwise rendered in accordance with the Supply Conditions.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;4.2 &nbsp; &nbsp;The Supplier shall have the right to invoice the Customer upon the Supplier’s fulfilment of the First Touchpoint, regardless of whether the Goods are eventually successfully delivered to the Recipient.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;4.3 &nbsp; &nbsp;The Supplier shall invoice the Customer the aggregate of all applicable Charges under this Service Agreement, in accordance with Schedule 3, for the completion of any stages of the Services, regardless of the successful delivery to the Recipient. However, where applicable:</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;4.3.1 &nbsp; &nbsp;any Duties and Taxes Charges shall be payable prior to the delivery of the Goods; and</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;4.3.2 &nbsp; &nbsp;any Supplementary Charges shall be invoiced within a reasonable period after discovery of the relevant error by the Supplier.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;4.4 &nbsp; &nbsp;The Customer shall:</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;4.4.1 &nbsp; &nbsp;Pay the invoice by the respective deadlines as set out in Schedule 3 (the <b>“Payment Deadline”</b>);</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;4.4.2 &nbsp; &nbsp;Pay the invoices in full and in cleared funds by telegraphic transfer in Singapore Dollars (or whichever applicable currency), inclusive of any telegraphic transfer charges, to the Supplier’s bank account, or any other account as nominated by the Supplier, which is indicated on the relevant invoice, and the details of which are set out in Schedule 3. For the avoidance of doubt, the Supplier and Customer shall each bear its own bank charges; and</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;4.4.3 &nbsp; &nbsp;Include the invoice number for which payment is made and provide the Customer’s name for each telegraphic transfer.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;4.5 &nbsp; &nbsp;Time for payment shall be of the essence of the Service Agreement.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;4.6 &nbsp; &nbsp;All amounts payable by the Customer under the Service Agreement are exclusive of amounts in respect of any GST in Singapore and/or VAT in any Territory chargeable from time to time.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;4.6.1 &nbsp; &nbsp;The amount of any GST and/or VAT incurred for the Services rendered by the Supplier to the Customer will be reflected and included in the respective invoices issued in accordance with the terms as set out in Schedule 3.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;4.6.2 &nbsp; &nbsp;The amount of any GST and/or VAT incurred in relation to or arising out of the Duties and Taxes Administrative Cost will be reflected and included in the respective invoices issued in accordance with the terms as set out in Schedule 3.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;4.6.3 &nbsp; &nbsp;The Customer shall pay such additional amounts in respect of GST and/or VAT as are chargeable at the same time as the Payment Deadlines as stipulated in Schedule 3.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;4.7 &nbsp; &nbsp;All Claims shall only be for the Declared Value, where applicable. In the event that a Dispute arises between the Parties in relation to any Claims by the Customer, the Parties shall use their best efforts to amicably resolve such dispute using the following Dispute resolution process within 30 calendar days (<b>“Internal Dispute Mechanism”</b>):</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;4.7.1 &nbsp; &nbsp;The Customer shall file a Dispute Notice which shall be substantiated by the relevant Supporting Documents within the Dispute Notice Period. The Supplier reserves its rights to request for any additional Supporting Documents it may deem necessary.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;4.7.2 &nbsp; &nbsp;If the Supplier agrees to the Claim based on the Dispute Notice, the Customer shall not be required to make payment of the Claim. In the event the Customer has already made any payment towards the Claim to the Supplier, the Supplier shall make the necessary reimbursements.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;4.7.3 &nbsp; &nbsp;If the Supplier disagrees with the Claim in part or in whole, the Supplier shall provide the Customer with the relevant Supporting Documents (<b>the “Supplier’s Response”</b>).</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;4.7.4 &nbsp; &nbsp;If the Customer agrees with the Supplier’s Response, the Customer shall make payment on the sums owing as set out in the Supplier Response within 30 calendar days from the date of the Dispute Notice.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;4.7.5 &nbsp; &nbsp;If the Customer disagrees with the Supplier’s Response, or the Parties are unable to resolve the Dispute within 30 calendar days of the date of the Dispute Notice, the Parties shall refer the Dispute to, and resolve the Dispute in accordance with Clause 10.12.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;4.8 &nbsp; &nbsp;The Parties agree that notwithstanding any Dispute, the Customer shall pay the Supplier the aggregate of all the applicable Charges, by the Payment Deadline, minus the Disputed Sums, save for Disputes concerning Duties and Taxes Charges payable, in which case the Customer shall make the payment of the Duties and Taxes Charges payable by the Payment Deadline. In the event that the Dispute is resolved in favour of the Customer, the Supplier shall reimburse the Customer up to the sums resolved in favour of the Customer as soon as practicable.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;4.9 &nbsp; &nbsp;If the Customer fails to make a payment due to the Supplier under the Service Agreement either (i) by the Payment Deadline or (ii) in the event of a Dispute that is resolved under the Internal Dispute Mechanism, 30 calendar days from the date of the Dispute Notice, then without limitation and without prejudice to any other remedies of the Supplier, the Customer shall be liable to:</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;4.9.1 &nbsp; &nbsp;pay interest on the overdue sum from the due date until the date of payment in full of the overdue sum, accruing each day at the Interest Rate (<b>“Interests”</b>). All accrued interest becoming due and remaining unpaid will be compounded with any overdue sum at the end of each calendar month, but will remain immediately due and payable; and</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;4.9.2 &nbsp; &nbsp;indemnify the Supplier for all liabilities, costs, expenses, damages and losses (including any direct, indirect or consequential losses) suffered or incurred by Supplier as a result of the Customer’s failure to make payment under this Clause 4 including, without limitation, reasonable legal fees, collection agency fees, and other professional costs and expenses in accordance with Clause 6.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;4.10 &nbsp; &nbsp;If the Customer owes any outstanding sums to the Supplier under the Service Agreement, the Supplier may</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;4.10.1 &nbsp; &nbsp;notify the Customer that the Supplier may withhold the Customer’s Goods or cash owing to the Customer to set-off against any outstanding claims the Supplier may have against the Customer and the Supplier shall not be liable for any consequential losses suffered by the Customer; and</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;4.10.2 &nbsp; &nbsp;in addition to any other remedies available to the Supplier, the Supplier shall have, and the Customer agrees that the Supplier has the rights to or claim a lien, or encumbrance of any type or kind, including a warehouseman lien upon any portions of the Customer’s Goods in its possession for any amounts due under this Service Agreement.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;4.11 &nbsp; &nbsp;All amounts due under the Service Agreement shall be paid in full without any set-off, counterclaim, deduction or withholding (other than any deduction or withholding of tax as required by law) save as provided in Clause 4.10.</span></p>
      <p className="c1 c3"><span className="c0">5.&nbsp; &nbsp;LIMITATION OF LIABILITY</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;5.1 &nbsp; &nbsp;Nothing in this Standard Terms and Conditions and any other terms and conditions of the Service Agreement shall limit or exclude a Party’s liability:</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;5.1.1 &nbsp; &nbsp;for death or personal injury caused by its negligence, or that of its employees, agents or sub-contractors;</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;5.1.2 &nbsp; &nbsp;for fraud or fraudulent misrepresentation; or</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;5.1.3 &nbsp; &nbsp;for any other act, omission, or liability which may not be limited or excluded by law.</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;5.2 &nbsp; &nbsp;Neither Party shall have any Liability to the other Party, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, for any loss of profit, or for any indirect or consequential loss arising under or in connection with this Standard Terms and Conditions and the Service Agreement. This is provided that such claim, action or proceeding did not arise from either Party’s negligence, fault or default in performance of its obligations under the Service Agreement or statutory obligations or deterioration in its financial position.</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;5.3 &nbsp; &nbsp;Unless otherwise agreed between the Parties in Schedule 5, any failure or delay by the Supplier to perform any of its obligations under the Service Agreement results in any Claims, the Supplier’s liability for Claims arising out of an Order concerning shall be limited to the following amounts:</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;5.3.1 &nbsp; &nbsp;cross-border shipments up to a maximum of USD One Hundred Dollars (USD 100.00) or the Declared Value of an individual item in the Order, whichever is lower; or</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;5.3.2 &nbsp; &nbsp;domestic shipments in the following countries, the lower of the following respective values shall apply:</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;5.3.2.1 &nbsp; &nbsp;&nbsp;&nbsp;Malaysia: (i) up to a maximum of MYR Two Hundred (MYR 200.00); or (ii) the Declared Value of an individual item in the Order;</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;5.3.2.2 &nbsp; &nbsp;&nbsp;&nbsp;Indonesia: (i) up to a maximum of IDR One Million (IDR 1,000,000.00); (ii) the Declared Value of an individual item in the Order; or (iii) ten times of the Freight Charges for an individual item in the Order;</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;5.3.2.3 &nbsp; &nbsp;&nbsp;&nbsp;Singapore: (i) up to a maximum of USD Fifty (USD 50.00); or (ii) the Declared Value of an individual item in the Order; and/or</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;5.3.2.4 &nbsp; &nbsp;&nbsp;&nbsp;China and all other countries: (i) up to a maximum of USD Fifty (USD 50.00); or (ii) the Declared Value of an individual item in the Order,</span></p>
 	    <p className="c1"><span className="c0">subject to prescribed limitations under any applicable mandatory conventions (including but not limited to the Warsaw Convention, Hague-Visby Rules and the Hague Rules), laws or regulations on the limitation of liability in relation to the carriage of goods.</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;5.4 &nbsp; &nbsp;Without prejudice to the provisions of clause  5.3, the Supplier shall only be liable for any loss or damage to Goods if it is proven by the Customer that (i) the loss or damage occurred whilst the Goods were physically in   the   Supplier’s   possession,   and   (ii)   such   loss   or  damage was due to the wilful neglect of the Supplier or its   own   servants   acting   within   the   scope   of   their employment.</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;5.5 &nbsp; &nbsp;For the avoidance of doubt, the maximum limits in Clause 5.3 and 5.4 are inclusive of any relevant Charges, in addition to the Declared Value of the Shipment.</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;5.6 &nbsp; &nbsp;Claims are limited to one Claim per Shipment, settlement of which will be the lower of the Declared Value or the value in Clause 5.3 and 5.4, as the case may be. For the avoidance of doubt,</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;5.6.1 &nbsp; &nbsp;Settlement of any Claims in connection with or arising out of a Damaged Order shall be only for the Good(s) which are a Damaged Order and not the whole of the Package that is a Damaged Order; and</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;5.6.2 &nbsp; &nbsp;Settlement of any Claims in connection with or arising out of a missing Good(s) shall only be for the missing Good(s) and not the whole of the Package / Shipment (as the case may be).</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;5.7 &nbsp; &nbsp;The limitations in Clauses Clause 5.3 to 5.6 shall not apply to B2B Transactions. In respect of B2B Transactions:</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;5.7.1 &nbsp; &nbsp;the Supplier’s liability for Claims shall be limited to the following amounts:</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;5.7.1.1 &nbsp; &nbsp;domestic Shipments: the limit sets out in mandatorily applicable law; or, if no mandatorily applicable legal limit applies, USD 50.00 per occurrence, the actual loss, or the replacement cost of the Order, whichever is less (partial loss or damage shall be prorated on this basis); and</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;5.7.1.2 &nbsp; &nbsp;&nbsp; &nbsp;cross border Shipments: the limit set out in any applicable international carriage of goods convention, and in the event that no such international convention is applicable, the Supplier’s liability per cross border Shipment shall be limited to the actual loss, or the replacement cost of the shipment, or if no international accord, then at 33 SDRs/kg for ground transport, 19 SDRs/kg for air transport and 666.66 SDRs/packaging unit for ocean transport (or its the equivalent in Singapore dollars), whichever is less.</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;5.7.2 &nbsp; &nbsp;&nbsp; &nbsp;In the event that the Hague Rules, the Hague Visby Rules, the Warsaw Convention, the Warsaw (Hague) Convention, the Warsaw (Hague) (Montreal) Convention, or the Montreal Convention, 1999 are mandatory, the relevant limitation amounts set out therein will apply. In all other cases, the limitation amounts set out in Clause 5.7.1 shall apply.</span></p>
      <p className="c1 c3"><span className="c0">6.&nbsp; &nbsp;INDEMNITIES</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;6.1.&nbsp; &nbsp;The Customer shall fully indemnify, defend and hold harmless Supplier for all liabilities, costs, expenses, damages and losses (including any direct, indirect or consequential losses, loss of profit, loss of reputation and all interest, penalties and legal and other professional costs and expenses) suffered or incurred by Supplier as a result of, whether directly or indirectly, any act, omission or failure of the Customer (including, without limitation, the failure by the Customer to make payment due to the Supplier under Clause 4), the Customer’s officers, employees, agents, consultants and/or subcontractors including, but not limited to, any breach by the Customer resulting from any act, omission and/or failure by the Customer’s officers, employees, agents, consultants and/or subcontractors, of its obligations under the Service Agreement.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;6.2&nbsp; &nbsp;The Customer shall indemnify and hold Supplier harmless against any loss or damage which Supplier may suffer or incur as a result of the Customer’s breach, whether directly or resulting from any act, omission and/or failure by the Customer’s officers, employees, agents, consultants and/or subcontractors, howsoever arising.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;6.3&nbsp; &nbsp;The Customer acknowledges and agrees that any and all indemnities in relation to the Services are established directly between the Customer and the Supplier.</span></p>
      <p className="c1 c3"><span className="c0">7.&nbsp; &nbsp;ANTI-BRIBERY / ANTI-CORRUPTION</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;7.1&nbsp; &nbsp;The Parties represent and warrant that it has in place adequate procedures for reporting a violation of any Anti-Bribery and Anti-Corruption Laws, conducts its business ethically and complies at all times with the Anti-Bribery and Anti-Corruption Laws, and accordingly neither the Parties nor any of their Affiliates, officers, employees, agents, consultants and subcontractors, has</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;7.1.1&nbsp; &nbsp;&nbsp;&nbsp;directly or indirectly given, made, offered or received or agreed (either themselves or in agreement with others) to give, make offer, or receive any payment, gift or other advantage which a reasonable person would consider to be unethical, illegal or improper, or committed or attempted to commit (either themselves or in agreement with others) any other corrupt act, which is in violation of any Anti-Bribery and Anti-Corruption Laws;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;7.1.2&nbsp; &nbsp;&nbsp;&nbsp;received, agreed or attempted to receive the benefits of or profits from a crime or any corrupt act or agreed to assist any person to retain the benefits of or profits from a crime or any corrupt act; or</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;7.1.3&nbsp; &nbsp;&nbsp;&nbsp;been investigated (or is being investigated or is subject to a pending or threatened investigation) or is involved in an investigation (as a witness or possible suspect) in relation to any of the matters set out in Clauses 7.1.1 and/or 1.2 by any law enforcement, regulatory or other governmental agency or any customer or supplier, or has admitted to, or been found by a court in any jurisdiction to have engaged in, any corrupt act, or been debarred from bidding for any contract or business, and so far as the Customer is aware there are no circumstances which are likely to give rise to any such investigation, admission, finding or disbarment (whether under any Anti-Bribery and Anti-Corruption Laws or otherwise).</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;7.2&nbsp; &nbsp;The Customer undertakes that it shall and it shall procure all of its Affiliates, officers, employees, agents, consultants and subcontractors shall:</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;7.2.1&nbsp; &nbsp;&nbsp;&nbsp;familiarise itself with and shall at all times comply with all Anti-Bribery and Anti-Corruption Laws and the Supplier’s anti-bribery and anti-corruption policy that is in compliance with the Anti-Bribery and Anti-Corruption Laws and as amended by the Supplier from time to time;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;7.2.2&nbsp; &nbsp;&nbsp;&nbsp;shall not engage in any activity, practice or conduct which would constitute an offence under any Anti-Bribery and Anti-Corruption Laws and shall not do anything that will result in either Party being in violation of any Anti-Bribery and Anti-Corruption Laws or being investigated or involved in an investigation (as a witness or possible suspect) in relation to any of the matters set out in Clauses 10.1.1 and 10.1.2 by any law enforcement, regulatory or other governmental agency;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;7.2.3&nbsp; &nbsp;&nbsp;&nbsp;shall annually from the date of the Service Agreement, certify to the Supplier in writing (such certification being signed by an officer of the Customer) in such form as provided by the Supplier from time to time, its compliance with this Clause 7. The Customer shall provide such supporting evidence of compliance as the Supplier may reasonably request; and</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;7.2.4&nbsp; &nbsp;&nbsp;&nbsp;shall ensure that any person associated with the Customer, which performs services in connection with the Service Agreement, does so only on the basis of a written contract which incorporates the terms of this Clause 7 as amended mutatis mutandis. The Supplier shall be responsible for the observance and performance by such persons of the aforementioned terms, and shall be directly liable to the Supplier for any breach by such persons of any of the aforementioned terms.</span></p>
      <p className="c1 c3"><span className="c0">8.&nbsp; &nbsp;TERMINATION AND RENEWAL</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;8.1&nbsp; &nbsp;Without limiting or prejudicing any other right or remedy available to it, either Party may terminate the Service Agreement by giving the other Party one (1) month’s written notice or at an earlier time as agreed to between Parties.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;8.2&nbsp; &nbsp;Without limiting or prejudicing any other right or remedy available to it, either Party may terminate the Service Agreement with immediate effect by giving written notice to the other Party if:</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;8.2.1&nbsp; &nbsp;&nbsp; &nbsp;the other Party commits a material breach of any term of this Standard Terms and Conditions and/or the Service Agreement which breach is irremediable or (if such breach is remediable) fails to remedy that breach within a period of thirty (30) Business Days after being notified in writing to do so;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;8.2.2&nbsp; &nbsp;&nbsp; &nbsp;the other Party suspends, or threatens to suspend, payment of its debts or is unable to pay its debts as they fall due or admits inability to pay its debts;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;8.2.3&nbsp; &nbsp;&nbsp; &nbsp;the other Party commences negotiations with all or any class of its creditors with a view to rescheduling any of its debts, or makes a proposal for or enters into any compromise or arrangement with its creditors other than (being a company) for the sole purpose of a scheme for a solvent amalgamation of that other Party with one or more other companies or the solvent reconstruction of that other Party;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;8.2.4&nbsp; &nbsp;&nbsp; &nbsp;a creditor or encumbrancer of the other Party attaches or takes possession of, or a distress, execution, sequestration or other such process is levied or enforced on or sued against, the whole or any part of the other Party’s assets and such attachment or process is not discharged within fourteen (14) calendar days;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;8.2.5&nbsp; &nbsp;&nbsp; &nbsp;if the other Party suspends or ceases, or threatens to suspend or cease, carrying on all or a substantial part of its business;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;8.3&nbsp; &nbsp;Without affecting any other right or remedy available to it, the Supplier may terminate the Service Agreement with immediate effect and suspend all Services by giving written notice to the Customer if the Customer fails to pay any amount due under the Service Agreement on the Payment Deadline.</span></p>
      <p className="c1 c3"><span className="c0">9.&nbsp; &nbsp;CONSEQUENCES OF TERMINATION</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;9.1&nbsp; &nbsp;On termination of the Service Agreement, the Customer shall immediately pay to the Supplier all of the Supplier’s outstanding unpaid invoices and Interests and, in respect of Services supplied but for which no invoice has been submitted, the Supplier shall submit an invoice, which shall be payable by the Customer in accordance with Clause 4 above.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;9.2&nbsp; &nbsp;Termination or expiry of the Service Agreement shall not affect any rights, remedies, obligations or Liabilities of the Parties that have accrued up to the date of termination or expiry, including the right to claim damages in respect of any breach of the Service Agreement which existed at or before the date of termination or expiry. On termination of the Service Agreement, the Supplier shall, upon the Customer’s request, return all items under the care of the Supplier to the Customer at a mutually agreed upon cost.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;9.3&nbsp; &nbsp;Any provision of this Standard Terms and Conditions and/or the Service Agreement that expressly or by implication is intended to come into or continue in force on or after termination or expiry of the Service Agreement shall remain in full force and effect.</span></p>
      <p className="c1 c3"><span className="c0">10.&nbsp; &nbsp;GENERAL</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;10.1&nbsp; &nbsp;In the event of the occurrence of a Force Majeure Event:</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;10.1.1&nbsp; &nbsp;&nbsp; &nbsp;the Supplier shall not incur any Liabilities for any delays in any stage of any of the Services; and/or any costs, expenses, damages and / or losses sustained or incurred by the Customer; and</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;10.1.2&nbsp; &nbsp;&nbsp; &nbsp;the affected Party shall be entitled to a reasonable extension of the time for performing its obligations. If the period of delay or non-performance continues for more than thirty (30) calendar days, the unaffected Party may terminate the Service Agreement by giving a one (1) week written notice to the affected Party.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;10.2&nbsp; &nbsp;<b>Assignment and other dealings</b>. The Supplier may at any time assign, mortgage, charge, subcontract, delegate, declare a trust over or deal in any other manner with any or all of its rights and obligations under the Service Agreement with one (1) month’s written notice to the Customer.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;10.3&nbsp; &nbsp;Confidentiality.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;10.3.1&nbsp; &nbsp;&nbsp; &nbsp;Each Party undertakes that it shall not at any time disclose to any person any confidential information concerning the business, affairs, customers, clients or suppliers of the other Party, except as permitted by Clause 10.3.2.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;10.3.2&nbsp; &nbsp;&nbsp; &nbsp;Each Party may disclose the other Party’s confidential information:</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;10.3.2.1&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;to its employees, officers, representatives, subcontractors or advisers who need to know such information for the purposes of carrying out the Party’s obligations under the Service Agreement. Each Party shall ensure that its employees, officers, representatives, subcontractors or advisers to whom it discloses the other Party’s confidential information comply with this Clause 10.3; and</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;10.3.2.2&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;as may be required by law, a court of competent jurisdiction or any governmental or regulatory authority.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;10.3.3&nbsp; &nbsp;&nbsp; &nbsp;Neither Party shall use the other Party’s confidential information for any purpose other than to perform its obligations under the Service Agreement.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;10.4&nbsp; &nbsp;Entire agreement.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;10.4.1&nbsp; &nbsp;The Service Agreement constitutes the entire agreement between the Parties and supersedes and extinguishes all previous agreements, promises, assurances, warranties, representations and understandings between them, whether written or oral, relating to its subject matter.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;10.4.2&nbsp; &nbsp;Each Party acknowledges that in entering into the Service Agreement, it does not rely on, and shall have no remedies in respect of any statement, representation, assurance or warranty (whether made innocently or negligently) that is not set out in this Standard Terms and Conditions and/or any other terms and conditions of the Service Agreement. Each Party agrees that it shall have no claim for innocent or negligent misrepresentation based on any statement in this Standard Terms and Conditions and the Service Agreement.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;10.5&nbsp; &nbsp;<b>Variation</b>. No variation of the Service Agreement shall be effective unless it is in writing, or pursuant to an Addendum Agreement and/or the Variation Addendum.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;10.6&nbsp; &nbsp;<b>Waiver</b>. A waiver of any right or remedy under the Service Agreement or by law is only effective if given in writing and signed by both Parties and shall not be deemed a waiver of any subsequent right or remedy. A failure or delay by a Party to exercise any right or remedy provided under this Standard Terms and Conditions or the Service Agreement or by law shall not constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict any further exercise of that or any other right or remedy. No single or partial exercise of any right or remedy provided under this Standard Terms and Conditions or the Service Agreement or by law shall prevent or restrict the further exercise of that or any other right or remedy.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;10.7&nbsp; &nbsp;<b>Severance</b>. If any provision or part-provision of this Standard Terms and Conditions or the Service Agreement is or becomes invalid, illegal or unenforceable, it shall be deemed modified to the minimum extent necessary to make it valid, legal and enforceable. If such modification is not possible, the relevant provision or part-provision shall be deemed deleted. Any modification to or deletion of a provision or part-provision under this clause shall not affect the validity and enforceability of the rest of the Standard Terms and Conditions and the Service Agreement.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;10.8&nbsp; &nbsp;<b>Notices.</b></span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;10.8.1&nbsp; &nbsp;&nbsp; &nbsp;Any notice or communication given to a Party under or in connection with the Service Agreement shall be in writing and shall be delivered by hand or by pre-paid first-class post or other next working day delivery service at its registered office (if a company) or its principal place of business (in any other case); or sent by email to the address specified in the Order.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;10.8.2&nbsp; &nbsp;&nbsp; &nbsp;Any notice or communication shall be deemed to have been received:</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;10.8.2.1&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;if delivered by hand, on signature of a delivery receipt;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;10.8.2.2&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;if sent by pre-paid first-class post or other next working day delivery service, at 9.00 am on the Business Day after posting or at the time recorded by the delivery service;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;10.8.2.3&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;if sent by email, at the time of transmission, or, if this time falls outside business hours in the place of receipt, when business hours resume. Business hours means 9.00am to 5.00pm Monday to Friday on a day that is not a public holiday in the place of receipt.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;10.8.3&nbsp; &nbsp;&nbsp; &nbsp;This clause does not apply to the service of any proceedings or other documents in any legal action or, where applicable, any other method of dispute resolution.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;10.9&nbsp; &nbsp;Third party rights.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;10.9.1&nbsp; &nbsp;Unless it expressly states otherwise, the terms and conditions herein do not give rise to any rights under the Contracts (Rights of Third Parties) Act (Cap. 53B) of the Republic of Singapore to enforce any terms and conditions of this Standard Terms and Conditions and/or the Service Agreement.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;10.9.2&nbsp; &nbsp;The rights of the Parties to rescind or vary any terms and conditions of this Standard Terms and Conditions and/or the Service Agreement are not subject to the consent of any other person.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;10.10&nbsp; &nbsp;Relationship of the Parties. Nothing in this Standard Terms and Conditions and/or the Service Agreement shall be construed as creating a partnership, joint venture, contract of employment or relationship of principal and agent between the Parties. No Party shall have any authority to act for or assume any obligation or responsibility of any kind, express or implied, on behalf of the other Party or bind or commit the other Party for any purpose in any way whatsoever with the exception of the supply of the Services rendered by the Supplier put forth in Clause 2 (Supply of Services).</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;10.11&nbsp; &nbsp;<b>Governing law</b>. The terms and conditions of the Service Agreement, and any Dispute or Claim (including non-contractual disputes or claims) arising out of or in connection with them or their subject matter or formation shall be governed by, and construed in accordance with the law of the Republic of Singapore.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;10.12&nbsp; &nbsp;Dispute Resolution</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;10.12.1&nbsp; &nbsp;&nbsp; &nbsp;Any Dispute shall first be resolved by reference to the Internal Dispute Mechanism.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;10.12.2&nbsp; &nbsp;&nbsp; &nbsp;If any Dispute which is above the amount of US$200,000 is not resolved fully by the Internal Dispute Mechanism, the Parties shall refer the Dispute to the Singapore Mediation Centre for resolution by mediation. The mediator shall be an independent third party agreed to between the Parties and Parties shall share any costs and fees equally other than legal fees associated with the mediation.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;10.12.3&nbsp; &nbsp;&nbsp; &nbsp;If it proves impossible to arrive at a mutually satisfactory solution through mediation within twenty (20) Business Days, the Parties shall refer to and finally resolve the Dispute by arbitration administered by the Singapore International Arbitration Centre in accordance with the Arbitration Rules of the Singapore International Arbitration Centre for the time being in force, which rules are deemed to be incorporated by reference in this Clause. The seat of the arbitration shall be Singapore. The Tribunal shall consist of one (1) arbitrator mutually appointed by both the Parties. The language of the arbitration shall be English.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;10.12.4&nbsp; &nbsp;&nbsp; &nbsp;If any Dispute which is below the amount of US$200,000 is not resolved fully by the Internal Dispute Mechanism and/or there are any questions regarding the existence, validity or termination of the Service Agreement, the Parties shall submit to the exclusive jurisdiction of the Courts of the Republic of Singapore including, inter alia the Small Claims Tribunal, and irrevocably waives any objection on the grounds of forum non conveniens to settle the Dispute.</span></p>

      <p className="c1 c3"><strong>DEFINITIONS AND INTERPRETATION</strong></p>
      <p className="c1 c3"><span className="c0">1.1&nbsp; &nbsp;Unless the Service Agreement otherwise expressly provides, or the context otherwise requires:</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;1.1.1&nbsp; &nbsp;references to clauses and sub-clauses, are references to the clauses and sub-clauses respectively of the Standard Terms and Conditions;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;1.1.2&nbsp; &nbsp;reference to Schedules are references to the schedules of the Service Agreement or whatever schedules the Supplier designates to be;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;1.1.3&nbsp; &nbsp;words denoting the singular shall, where applicable, include the plural number and vice versa, and words denoting persons only shall, where applicable, include firms and corporations;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;1.1.4&nbsp; &nbsp;headings of clauses, sub-clauses, and Schedules are for ease of reference only and shall not affect the interpretation of the Standard Terms and Conditions;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;1.1.5&nbsp; &nbsp;&ldquo;<b>written</b>&rdquo; and &ldquo;<b>in writing</b>&rdquo; shall include email; and</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;1.1.6&nbsp; &nbsp;&ldquo;<b>month</b>&rdquo; is a reference to a period starting on one day of a calendar month and ending on the numerically corresponding day on the next succeeding calendar month.</span></p>
      <p className="c1"><span className="c0">1.2&nbsp; &nbsp;the following words and expressions shall have the following meanings in the Standard Terms and Conditions:</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Addendum Agreement:</strong> means each and every subsequent agreement to entered between the Parties supplementing and amending this Service Agreement.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Ad-hoc Charges:</strong> means any Duties and Taxes Charges, Extra Arrangements Charges, Freight Charges, and/or any other Charges agreed to from time to time, between the Supplier and the Customer in writing and subject to the Supplier’s finance department approval calculated based on the Ad-hoc Rates and will only be applicable to specified Orders.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Ad-hoc Rates: </strong> means any rates that are not the Rates as set out in Schedule 2 or the Supplier Standard Rates, as agreed to from time to time between the Supplier and the Customer in writing and subject to the Supplier’s finance department approval.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Affiliates: </strong> means any person or entity that directly or indirectly Controls, or is Controlled by or is under the common Control with the Supplier or Customer (as the case may be).</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Anti-Bribery and Anti-Corruption Laws:</strong>  means all applicable laws and regulations in force (in jurisdictions where the Customer and Supplier and/or the Supplier’s Group Entity operates) and amended from time to time, and any re-enactment thereof, relating to anti-bribery and anti-corruption.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>B2B Transaction:</strong> means a transaction where the Supplier provides Services for business purposes and in the Supplier’s sole discretion meets the relevant weight thresholds and regulatory requirements.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Business Day:</strong> means any day (other than a Saturday, Sunday or public holiday) on which commercial banks are open for normal business in Singapore and in other countries where the Customer operates in.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Cancelled Order: </strong> means any Goods or Shipment that is eventually not delivered to the Recipient due to a cancellation by the Customer.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Cash on Delivery: </strong> means a type of additional service, that may be provided by the Supplier to the Customer, of collecting cash payment from the Recipient upon the delivery of the Goods directly to the Recipient on behalf of the Customer.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Charges: </strong>  means one or more of the following taking into consideration any applicable discount:</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(a)&nbsp; &nbsp;&nbsp; &nbsp;<strong>Ad-hoc Charges;</strong></span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(b)&nbsp; &nbsp;&nbsp; &nbsp;<strong>Customs Clearance Charges;</strong></span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(c)&nbsp; &nbsp;&nbsp; &nbsp;<strong>Duties and Taxes Charges;</strong></span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(d)&nbsp; &nbsp;&nbsp; &nbsp;<strong>Extra Arrangement Charges;</strong></span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(e)&nbsp; &nbsp;&nbsp; &nbsp;<strong>Freight Charges;</strong></span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(f)&nbsp; &nbsp;&nbsp; &nbsp;<strong>Fulfilment Charges;</strong></span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(g)&nbsp; &nbsp;&nbsp; &nbsp;<strong>Last Mile Delivery Charges;</strong></span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(h)&nbsp; &nbsp;&nbsp; &nbsp;<strong>Domestic Delivery Charges; and/or</strong></span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(i)&nbsp; &nbsp;&nbsp; &nbsp;<strong>Storage Fee.</strong></span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Claims:</strong> means each and every claim, disagreement, or dispute which the Customer may have, arising out of or in connection with any of the Services provided by the Supplier, but which for the avoidance of doubt does not include Service Level Agreement Claims.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Compensation:</strong> means the compensatory payments that the Customer may request from the Supplier subject to the terms and conditions set out in Schedule 2.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Consignee: </strong> means the party receiving the Shipment after Customs Clearance.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Control: </strong> means in relation to a company (i) owning shares with voting rights which comprise more than 50% of the shares in the aforementioned company, or (ii) having the ability to appoint a majority of the board of directors or otherwise to control management decisions of the aforementioned company whether through rights attaching to shares held, by contractual arrangement or otherwise, and provided that where any entity has Control of a second entity which in turn has Control of a third entity, that first entity shall be considered to Control the third entity.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Coverage Top Up: </strong> means a type of additional service, referred to as ParcelShield, that may be provided by the Supplier to the Customer, where the Supplier increases its limitation of liability for the for the Orders.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Customs Clearance:</strong> means a type of service provided by the Supplier or the Logistic Provider, as the case may be, of ensuring the Goods meets all customs rules and regulations so that the Goods is allowed by the customs of the Territory to cross the border of the Territory to be delivered to the Recipient.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Customs Clearance Charges:</strong> means any costs, fees and expenses in connection with Customs Clearance, calculated based on the Rates.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Damaged Order:</strong> means any Goods which, save for any reasonable wear and tear, have any visible defects to their state and/or condition, and such defects reasonably cause the Goods to be not fit for purpose.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Data Protection Legislation:</strong>  means all legislation and regulatory requirements in force (in jurisdictions where the Customer’s data is collected) and amended from time to time, and any re-enactment thereof, relating to the collection, use, disclosure and/or processing of Personal Data including, without limitation, the Personal Data Protection Act 2012 (No. 26 of 2012).</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Declared Commercial Invoice Value:</strong>  means the value the Customer represents to the Recipient the Shipment is worth.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Declared Value: </strong> means the value the Customer represents to the relevant Logistic Provider and Supplier the Shipment is worth.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Dispute:</strong>  means any disagreements that the Customer has with the Supplier and brings a Claim for.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Dispute Notice:</strong>  means a written notice to be provided by the Customer to the Supplier providing inter alia the following information:</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(a)&nbsp; &nbsp;&nbsp; &nbsp;specifying the defects of the Damaged Order;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(b)&nbsp; &nbsp;&nbsp; &nbsp;specifying the invoice or part of the invoice that the Customer is disputing;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(c)&nbsp; &nbsp;&nbsp; &nbsp;specifying and describing the Missing Order;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(d)&nbsp; &nbsp;&nbsp; &nbsp;requesting for a Proof of Return; or</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(e)&nbsp; &nbsp;&nbsp; &nbsp;	requesting for Proof of Delivery,</span></p>
      <p className="c1 c3"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;as the case may be.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;<strong>Dispute Notice Period: means:</strong></span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(a)&nbsp; &nbsp;&nbsp; &nbsp;the later of the following: (i) thirty (30) calendar days from the date the relevant Order is created and (ii) seven (7) calendar days from successful delivery, for Claims arising out of or in connection with Missing and Damaged Orders;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(b)&nbsp; &nbsp;&nbsp; &nbsp;twenty (20) Business Days starting from the receipt of the invoice in
                                                                                                                 relation to which the Customer is disputing, save where the Customer
                                                                                                                 provides sufficient grounds for an extension request and the Supplier shall approve such extension requested in its sole discretion which in any event shall be no longer than forty (40) Business Days from the aforesaid invoice.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Disputed Sums:</strong> means the sum total of the portion(s) of the invoice(s) which the Customer has raised a Dispute.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Domestic Delivery:</strong> means the service provided by Supplier of arranging for the Pick-up and dropping off of the Goods at a destination which is within the same country as the Pick-up Address.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Domestic Delivery Charges:</strong> means any costs, fees and expenses in connection with the Domestic Delivery service supplied based on the calculations set out in the Rates.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Duties and Taxes Administrative Cost:</strong> means any additional fees and charges related to and arising out of the Duties and Taxes Charges based on the calculations as set out in the Rates and for the avoidance of doubt such fees and charges are based on the total Declared Value and is subjected to the amount that the Supplier is charged at the customs of the Territory.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Duties and Taxes Charges:</strong>  means any duties, taxes and/or penalties levied on goods imported into the Territory under the applicable laws and regulations, Duties and Taxes Administrative Cost, and any other applicable charges, duties, penalties and/or taxes incurred by the Supplier in the rendering of the Services, under the laws and regulations of Singapore and the Territory, based on the calculations set out in the Rates according to the rules and regulations imposed by the relevant authorities of Singapore and the Territory and subject to the amount charged at the customs of the Territory. In the event there is any variation in rules and regulations imposed by the relevant authorities of Singapore and the Territory which results in any increase in the Duties and Taxes Charges, taxes, penalties and any other applicable charges, duties and taxes incurred by the Supplier as compared to the Rates, these increase shall form part of the Duties and Taxes Charges notwithstanding them not being included in the Rates.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Effective Date:</strong> has the meaning ascribed to it in the Service Agreement.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Election:</strong>  means choosing to trigger the Return to Shipper Mechanism in accordance with Clause 2.18 or 2.20 as the case may be.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Extra Arrangement:</strong>  means any other services to be provided by the Supplier to the Customer as agreed to in writing between Parties from time to time including, amongst others, (a) Cash on Delivery, and (b) Coverage Top Up.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong> Extra Arrangement Charges:</strong> any costs, fees and expenses in connection with the Extra Arrangement supplied based on the Rates if applicable, or the Supplier’s Standard Rates or Ad-hoc Rates as the case may be.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong> First Touchpoint:</strong> means the collection of Goods from the Place of Origin.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong> Force Majeure Event:</strong>  means an event that is beyond the reasonable control of the Supplier and is associated directly with the Services, and without prejudice to the generality of the foregoing, shall include without limitation any of the following:</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(a)&nbsp; &nbsp;&nbsp; &nbsp;act of God, strike, work stoppage, lockout or industrial actions, labour or trade dispute, act of the public enemy, war declared or undeclared, invasion, act of terrorism or sabotage, embargo, blockade, revolution, riot, insurrection, civil or military disturbance or commotion, sabotage, lightning, fire, storm, flood, or other unusually severe weather conditions, earthquake, volcanic eruption, natural disaster, explosion, peril of the sea, embargo, epidemic, pandemic, radioactive, chemical or biological contamination, aircraft crash, loss or malfunction of utilities, communications or computer services, or failure or delay of transportation; or</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(b)&nbsp; &nbsp;&nbsp; &nbsp;decisions, actions, omissions or delays in acting by a governmental authority or agency, including without limitation: (i) sudden declaration of ungazetted public holidays; (ii) imposing an export or import restriction, quota or prohibition; and (iii) the failure to grant or the revocation of applicable licences and/or approvals, necessary for the purpose of the import of the Goods.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Freight:</strong>means transporting the Goods by land, sea or air for the purposes of completing the Order(s).</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Freight Charges: </strong> means any costs, fees and expenses in connection with the Goods for Freight, based on the Weight of each item in an Order and any item may be re-weighed and re-measured by the Supplier to confirm the measurements, calculated based on the Rates.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Freight Forwarding:</strong> means a type of service that may be provided by the Supplier to the Customer for the aggregation and coordination of the services provided by Logistic Provider(s) for Freight.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Fulfilment: </strong> means the services of Inbound, Fulfilment Storage, Outbound and Returns Handling of the Goods on behalf of the Customer by the Supplier, which will be calculated based on the Rates.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Fulfilment Charges:</strong>  means any costs, fees and expenses in connection with the Fulfilment supplied based on the calculations set out in the Rates.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Fulfilment Storage: means</strong></span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(a)&nbsp; &nbsp;&nbsp; &nbsp;the service of storing the Customer’s items under Fulfilment, by the Supplier on behalf of the Customer;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(b)&nbsp; &nbsp;&nbsp; &nbsp;inventory management and reporting which includes any inventory adjustment as and when required to rectify any discrepancies between the quantities of the Customer’s items recorded physically and the quantities recorded in the Supplier’s system; and</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(c)&nbsp; &nbsp;&nbsp; &nbsp;Periodical Cycle Counts.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Goods: </strong> means all the items specified in an Order, including but not limited to the merchandise, articles, effects, objects, pieces and raw material that are subject of the Services.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Group Entity:</strong>  means one or more of the subsidiaries, holding company (if any) and/ or joint venture partners of the Supplier.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>GST:</strong>  means the prevailing rate for goods and services tax under the Goods and Services Tax Act (Cap. 117A).</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Hague Rules:</strong>  means the International Convention for the Unification of Certain Rules of Law relating to Bills of Lading signed in Brussels on 25th August 1924, as amended from time to time.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Hague Visby Rules:</strong>  means the International Convention for the Unification of Certain Rules of Law relating to Bills of Lading signed in Brussels on 25th August 1924, as amended by the Protocol made at Brussels on 23rd February 1968.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Inbound: means</strong></span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(a)&nbsp; &nbsp;&nbsp; &nbsp;the receipt of the Customer’s items in the Supplier Warehouse (whether from the Customer or otherwise);</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(b)&nbsp; &nbsp;&nbsp; &nbsp;unpacking of the Customer’s items;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(c)&nbsp; &nbsp;&nbsp; &nbsp;labelling of the Customer’s items; and</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(d)&nbsp; &nbsp;&nbsp; &nbsp;any other services to be provided by the Supplier to the Customer that may be agreed to from time to time between Parties, which will be calculated based on the Rates.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Incident Report: </strong> means a report prepared either by the Supplier or the Logistic Provider concerning any incident during the delivery of the Goods and/or any events leading to the Missing Order or Damaged Order.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Interests: </strong> as defined in Clause 4.9.1.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Internal Dispute Mechanism: </strong> means the process set out in Clause 4.7.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Interest Rate:</strong> means 5% per annum.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong> Janio Freight Statement:</strong> means the document issued by the Supplier which sets out the dimensions of the Shipment provided to the Supplier by its third-party airway transport providers.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong> Last Mile Delivery: </strong>means a type of service that may be provided by the Supplier to the Customer of the aggregation and coordination of the services provided by the Logistic Provider(s) for the transportation of the Shipment, after the Shipment has been cleared by the customs in the Territory to the Receiving Address.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Last Mile Delivery Charge:</strong>  means any costs, fees and expenses in connection with the Last Mile Delivery, calculated based on the Rates.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Liability: </strong> means all and any actions, causes of action, claims, counterclaims, suits, indemnifications, damages, demands or rights whatsoever or howsoever arising, whether present, future, prospective or contingent, known or unknown, whether or not for a fixed or unliquidated amount, whether or not involving the payment of money or the performance of any act or obligation, whether arising at common law, in equity or by statute in Singapore or in any other jurisdiction or in any other manner whatsoever.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Logistics Provider: </strong> means a third-party logistics services provider engaged by the Supplier (to be chosen at the Supplier’s sole discretion, unless otherwise agreed in writing between the Customer and the Supplier) for the purposes of fulfilling the Order(s), and who at all times provides services to the Supplier as an independent contractor and not an agent or employee.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Missing Order:</strong>  means upon First Touchpoint, which shall be determined based on the Supplier’s acknowledgement (whether by way of tracking updates or otherwise), any Package(s) and/or Shipment(s) (whichever is applicable):</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(a)&nbsp; &nbsp;&nbsp; &nbsp;</span>which is not received by the Recipient and the Supplier is unable to locate the Package and/or Shipment (whichever is applicable) within ten (10) Business Days from the date of Pick-up; or</p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(b)&nbsp; &nbsp;&nbsp; &nbsp;</span>is received by the Recipient but contains one or more missing part thereof (i.e. the Goods).</p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong> Montreal Convention, 1999:</strong> means the Convention for the Unification of Certain Rules for International Carriage by Air signed in Montreal on 28 May 1999, as amended from time to time.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Order: </strong> means a document issued or any form of communication by the Customer to the Supplier requesting the delivery of Goods to a Recipient at a Receiving Address pursuant to the Service Agreement and this Standard Terms and Conditions.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Order Database: </strong> means the online database through which the Supplier receives Orders from Recipients.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Outbound: means</strong></span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(a)&nbsp; &nbsp;&nbsp; &nbsp;generating a picking list and sales order;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(b)&nbsp; &nbsp;&nbsp; &nbsp;picking of the Customer’s items from the storage location of the Customer’s items to match the packing list;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(c)&nbsp; &nbsp;&nbsp; &nbsp;packing in accordance with the Order(s) as agreed with the Customer; and</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(d)&nbsp; &nbsp;&nbsp; &nbsp;any other services to be provided by the Supplier to the Customer that may be agreed to from time to time between Parties, which will be calculated based on the Rates.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Package: </strong> means each parcel that contains one or more of the Goods packed together.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Payment Deadline: </strong> as defined in Clause 4.4.1.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Periodical Cycle Counts: </strong> means the physical verification of the quantities of the Customer’s items in Fulfilment Storage and includes any inventory adjustment as and when required to rectify any discrepancies discovered during the physical verification of the number of the Customer’s items in Fulfilment Storage.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Personal Data: </strong> means data, whether true or not, about an individual who can be identified from that data or from that data and other information to which an organisation has or is likely to have access.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Pick-up: </strong> means the service provided by the Logistic Provider(s) and/or Supplier of collecting the Goods from the Pick-up Address.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Pick-up Address: </strong> means the place designated by the Customer where the Goods are to be collected by the Supplier and/or Logistic Provider(s) for the Pick-up service.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Place of Origin: </strong> means the place where the Goods are first collected by the Supplier and/or the Logistic(s) Provider for the relevant Services, being the earlier of:</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(a)&nbsp; &nbsp;&nbsp; &nbsp;the Pick-up Address;</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(b)&nbsp; &nbsp;&nbsp; &nbsp;the Supplier Warehouse or Logistic Provider’s warehouse;</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(c)&nbsp; &nbsp;&nbsp; &nbsp;Customs; or</span></p>
 	    <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(d)&nbsp; &nbsp;&nbsp; &nbsp;the Customer’s warehouse in the Territory,</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;depending on the Services provided.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Proof of Delivery:  </strong> means the document provided by the Logistic Provider to the Supplier recording a successful delivery of the Shipment to the Recipient.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Proof of Return: </strong> means the document provided by the Logistic Provider to the Supplier recording a successful delivery of the Uncollected Shipment or Cancelled Order back to the Customer’s warehouse.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Quotation:  </strong> means the quote given by the Supplier to the Customer which is determined based on the quantity of Orders and the Receiving Address, and the sum of which is to be fixed to the Rates with effect on the date on which the quote is given. The components of the quote are the applicable Charges.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Rates:  </strong> means the charges and fees calculated as set out in Schedule 2 of the Service Agreement which are updated from time to time and based on which the Charges are calculated.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Receiving Address: </strong>  means a destination within the Territory as specified by the Customer in an Order.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Recipient: </strong> means a person or corporation to whom the Goods are delivered or intended to be delivered to by the Customer.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Repeat Delivery: </strong> means after an Uncollected Shipment, another up to three (3) attempts by the Logistic Provider to attempt to deliver the Goods to the Recipient.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Returns Handling: </strong> means the service of returning back any of the Customer’s items back to the inventory of the Customer’s items under Fulfilment by the Supplier on behalf of the Customer and any other services to be provided by the Supplier to the Customer that may be agreed to from time to time between Parties, which will be calculated based on the Rates.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Return to Shipper Election Period: </strong>  means seven (7) days from the time a notice is provided by the Supplier to the Customer in accordance with Clause 2.18 or 2.20 as the case may be.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Return to Shipper Mechanism: </strong> means the process by which any undelivered Shipment is returned back by the Supplier to the Customer’s storage location and for the avoidance of doubt, the costs of implementing the Return to Shipper Mechanism is based on the Charges calculated according to the same Rates in the Quotation.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Service Agreement: </strong> means the agreement made between Taurus One Pte Ltd (f.k.a. Janio Technologies Private Limited) a company incorporated under the laws of the Republic of Singapore (UEN No. 201810116D) and its respective customers, which incorporates this Standard Terms and Conditions.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Services: </strong> means the services that shall be rendered by the Supplier to the Customer as set out in Schedule 3, and any ad-hoc services as agreed between the Parties from time to time.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Service Level Agreement: </strong> means progressing from the First Touchpoint to the first attempt at delivery to the Recipient within the number of days as set out in the Rates and for the avoidance of doubt the calculation of the number of days is exclusive of the date of the First Touchpoint. The following is not taken into account for calculating the aforementioned number of days:</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(a)&nbsp; &nbsp;&nbsp; &nbsp;the number of days the Supplier withholds delivery of the Shipment due to the Recipient’s refusal to pay the required Duties and Taxes Charges and/or other Charges pursuant to Clause 2.17; and</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(b)&nbsp; &nbsp;&nbsp; &nbsp;delays due to inaccurate and/or incomplete data supplied to the Supplier.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Service Level Agreement Claim: </strong> means each and every claim, disagreement or dispute which the Customer may have as to the Supplier’s inability or failure to meet the Service Level Agreement.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Shipment:</strong>  means the consignment containing one or more Package packed together subject to the Services which have been transported from the Place of Origin to the Territory for delivery to the Receiving Address.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Storage: </strong> means the service of storing Goods, which are not Goods stored for Fulfilment, by the Supplier on behalf of the Customer whether at the (i) Customer’s request or (ii) or when Goods are held in a warehouse by the Supplier for a period in excess of fourteen (14) calendar days.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Storage Fee:</strong>  means the fee for Storage to be charged by the Supplier to the Customer in the amount to be determined by the Supplier.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Supplementary Charges:</strong>  means additional Charges to be charged by the Supplier to the Customer due to any error(s) in the original invoice provided resulting in an undercharging by the Supplier to the Customer, arising from inter alia the intentional input of an inaccurate postal code by the Recipient.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Supplier Database:</strong>  means the database of rates maintained by the Supplier, which shall be updated from time to time, of which access for viewing shall be granted by the Supplier to the Customer. The Supplier shall inform the Customer where and how to access this database and notify the Customer of any change in the location of the database.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Supplier’s Personal Data Protection Policy:</strong>  means the Supplier’s policy with regard to the protection of all Personal Data received which shall be in accordance with Data Protection Legislation.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Supplier’s Response: </strong> as defined in Clause 4.7.3.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Supplier Standard Rates: </strong> means the rates in the Supplier’s Database which shall apply from time to time for the provision of any goods and services for which no Rates or Ad-hoc Rates have been agreed to or are applicable.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Supplier Warehouse:</strong>  means a warehouse designated by the Supplier to the Customer to store Goods on behalf of the Customer whether for Fulfilment or Storage.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Supply Conditions: </strong> means this Standard Terms and Conditions, any other terms and conditions of the Service Agreement, and as amended from time to time.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Supporting Documents: </strong> means documents provided by the Supplier or the Customer to support or refute a Claim (as the case may be) including, without limitation, the following:</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(a)&nbsp; &nbsp;&nbsp; &nbsp;an Incident Report and any available photographic evidence;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(b)&nbsp; &nbsp;&nbsp; &nbsp;Janio Freight Statement;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(c)&nbsp; &nbsp;&nbsp; &nbsp;Proof of Delivery;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(d)&nbsp; &nbsp;&nbsp; &nbsp;Proof of Return;</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(e)&nbsp; &nbsp;&nbsp; &nbsp;any information or records in the Supplier’s working system or provided to the Supplier by the Logistics Provider(s);</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(f)&nbsp; &nbsp;&nbsp; &nbsp;material safety data sheet; or</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(g)&nbsp; &nbsp;&nbsp; &nbsp;GST permit,</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;and such other documents which are definitive save for clear data entry error.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Territory:  </strong> means any one more of the countries in the list of countries as set out in the Supplier Database that is updated from time to time.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Unacceptable Shipment: </strong> as defined in Schedule 1.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Uncollected Shipment: means</strong></span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(a)&nbsp; &nbsp;&nbsp; &nbsp;any Shipment that is not collected by the Recipient, by reason of (i) the Recipient being unidentifiable, or not locatable; or (ii) the Recipient’s neglect or inability to collect the Shipment, after three (3) attempts by the Logistic Provider to deliver the Shipment to the Receiving Address; and/or</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;(b)&nbsp; &nbsp;&nbsp; &nbsp;a Shipment and/or Package which cannot be successfully delivered due to the insufficiency and/or inaccuracy of data which is necessary and material to a successful delivery.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Variation Addendum: </strong> means the variation addendum in the Service Agreement setting out the variations, modifications and/or additions to the Standard Terms and Conditions as agreed between the Parties.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>VAT: </strong> means any applicable value added taxes under the laws and regulations of the Territory.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Volumetric Weight: </strong> is measured by the multiplication of the Good’s length, width and height (in centimetres) divided by 5000.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Warsaw Convention: </strong> means the Convention for the Unification of Certain Rules Relating to International Carriage by Air signed in Warsaw on 12 October 1929, as amended from time to time.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Warsaw (Hague) Convention: </strong> means the Warsaw Convention, as amended by the Protocol to Amend the Convention for the Unification of Certain Rules Relating to International Carriage by Air signed in Warsaw on 12 October 1929 done at the Hague on 28 September 1955.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong>Warsaw (Hague)(Montreal) Convention: </strong> means the Warsaw Convention, as amended by the Protocol to Amend the Convention for the Unification of Certain Rules Relating to International Carriage by Air signed in Warsaw on 12 October 1929 done at the Hague on 28 September 1955 and Protocol no. 4 of Montreal, 1975.</span></p>
      <p className="c1"><span className="c0">&nbsp; &nbsp;&nbsp; &nbsp;<strong> Weight: </strong>means the higher of the Volumetric Weight or the actual weight of the Goods.</span></p>
      <div>
        <h2 style={{textAlign: 'center'}}>Schedule 1:</h2>
        <h2 style={{textAlign: 'center', marginBottom:0}}><u> Part 1</u></h2>
        <h2 style={{textAlign: 'center'}}>Unacceptable Conditions for Shipping and Delivery ("Unacceptable Shipment")</h2>
        <p><span>As per Clause 2.15 of the Standard Terms and Conditions, the Supplier shall have the right to reject any shipment which is deemed to be unacceptable based on any of the following:</span></p>
        <p><span>&nbsp; &nbsp;1.&nbsp;no customs declaration is made when required by applicable customs regulations;</span></p>
        <p><span>&nbsp; &nbsp;2.&nbsp;it contains counterfeit goods, animals, bullion, currency, gem stones; weapons, explosives and ammunition; human remains; illegal items,such as ivory and narcotics;</span></p>
        <p><span>&nbsp; &nbsp;3.&nbsp;it is classified as hazardous material, dangerous goods, prohibited or restricted articles by IATA (International Air Transport Association),ICAO (International Civil Aviation Organization), ADR (European Road Transport Regulation on dangerous goods) or other relevant organization;</span></p>
        <p><span>&nbsp; &nbsp;4.&nbsp;the Receiving Address or Pick-up Address is incorrect or not properly marked;</span></p>
        <p><span>&nbsp; &nbsp;5.&nbsp;the shipping labels are illegible and barcodes cannot be scanned;</span></p>
        <p><span>&nbsp; &nbsp;6.&nbsp;the packaging and labelling are not in accordance with the guidelines provided at: <a href="https://janio.asia/articles/packaging-101-how-to-package-products-for-delivery/" target="_blank" rel="noreferrer">https://janio.asia/articles/packaging-101-how-to-package-products-for-delivery/</a></span></p>
        <p><span>&nbsp; &nbsp;7.&nbsp;the Package exceeds 30kg (whether the Volumetric Weight or its actual weight), or 100cm (whether its length, width or height);</span></p>
        <p><span>&nbsp; &nbsp;8.&nbsp;its packaging is defective or inadequate to ensure safe transportation with ordinary care in handling or if the Goods are fragile to be wrapped in protective packaging and the Supplier is to be notified beforehand of the fragile nature of the Goods; or</span></p>
        <p><span>&nbsp; &nbsp;9.&nbsp;it contains any other item which Supplier reasonably decides cannot be carried safely or legally.</span></p>
      </div>
    </>
  )
}
