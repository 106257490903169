import {Alert, 
  // Badge
} from 'antd'
import React from "react";

export const CSVTemplateUpdateAlert = () => {
  return (
  // <Alert type="info" showIcon message={
  //   <>
  //     <Badge.Ribbon text="New" color="red" className='new-ribbon-csv'>
  //       <div style={{fontSize:'14px'}}>
  //         {"We have recently updated our bulk shipment template. Please make sure to download the latest template to avoid any errors."}
  //       </div>
  //     </Badge.Ribbon>
      
  //   </>
  // }
  // />

  // Without new ribbon

    <Alert type="info" showIcon message={
      <div style={{fontSize:'14px'}}>
        {"We have recently updated our bulk shipment template. Please make sure to download the latest template to avoid any errors."}
      </div>
    }
    />
  )
}