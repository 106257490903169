import {Result, Button} from 'antd'
import {Link} from "react-router-dom" 
import not_found_image from '../assets/images/notfound.png';

export const NotFound = () => {
  return (
    <Result
      className="not-found"
      // status="404"
      icon={<img src={not_found_image} alt="Not Found" />}
      title="Page Not Found"
      subTitle="Sorry, the page you are looking for cannot be found."
      extra={
        <Button type="primary" style={{backgroundColor: '#050593'}}><Link to="/">Back to Home</Link></Button>
      }
    />
  )
}