import {
  Card,
  Row,
  Col,
  Breadcrumb,
  Tabs,
  Button} from 'antd'
import '../styles/UserSettings.css';
import {Link} from "react-router-dom";
import {useState} from "react";
// import BillingAddressesList from "./BillingAddressesList";
import PickUpAddressesList from "./PickUpAddressesList";
import ConsigneeAddressesList from "./ConsigneeAddressesList";
// import {BillingAddressModal} from "./BillingAddressModal";
import {PickUpAddressModal} from "./PickUpAddressModal";
import {ConsigneeAddressModal} from "./ConsigneeAddressModal";
import {useAuth} from "../../auth/services/authContext";
import {useQuery} from "react-query";
import {
  // getBillingAddress, 
  // getConsigneeLocation, 
  getLocations_from_b2c, getConsigneeLocation_from_b2c, geReturntLocations_from_b2c} from "../../orders/services/privateApi";
import { useLanguage } from '../../languages/Language';

const { TabPane } = Tabs;

const ManageAddressesContainer = () => {
  const auth = useAuth();
  let { getText } = useLanguage()
  const [activeTabKey, setActiveTabKey] = useState('pickup_address');

  const pickupLocationsFromb2c = useQuery('pickupLocationsFromb2c', () => {
    return getLocations_from_b2c(auth.user.secret_key)
  })

  const returnLocationsFromb2c = useQuery('returnLocationsFromb2c', () => {
    return geReturntLocations_from_b2c(auth.user.secret_key)
  })
  // const billingLocations = useQuery('billingLocations', () => {
  //   return getBillingAddress(authToken)
  // })

  // Call consignee address api
  const consigneeLocationsFromB2c = useQuery('consigneeLocations', () => {
    return getConsigneeLocation_from_b2c(auth.user.secret_key)
  })

  const [showAddAddressModal, setShowAddAddressModal] = useState<boolean>(false);
  const [addressType, setAddressType] = useState<string>('');

  const setShowAddPickUpAddressModalCallback = (val: boolean) => {
    setShowAddAddressModal(val);
    setAddressType('');
    pickupLocationsFromb2c.refetch();
    returnLocationsFromb2c.refetch()
  };

  // const setShowAddBillingAddressModalCallback = (val: boolean) => {
  //   setShowAddAddressModal(val);
  //   if(!val){
  //     billingLocations.refetch();
  //   }
  // };

  const setShowAddConsigneeAddressModalCallback = (val: boolean) => {
    setShowAddAddressModal(val);
    setAddressType('');
    consigneeLocationsFromB2c.refetch();
  };

  const onTabChange = (key:any) => {
    setActiveTabKey(key);
  }

  const addAddress = <>
    {
      (activeTabKey === 'pickup_address' || activeTabKey === 'return_address') ?
        <Button type="primary" onClick={() => {
          setShowAddAddressModal(true);
          setAddressType('pickup')
        }}
        className={"outline-btn"}>
          {activeTabKey === 'pickup_address' ? "Add pickup address" : "Add return address"}
        </Button>:
        <Button type="primary" onClick={() => {
          setShowAddAddressModal(true);
          setAddressType('customer')
        }}
        className={"outline-btn"}>
          {"Add customer"}
        </Button>
    }
    {(showAddAddressModal && addressType === 'pickup') &&
    <PickUpAddressModal
      setShowAddAddressModalCallback={setShowAddPickUpAddressModalCallback}
      addressType={activeTabKey === 'pickup_address' ? addressType : 'return'}
    />}
    {/* {(showAddAddressModal && addressType === 'billing') &&
    <BillingAddressModal
      setShowAddAddressModalCallback={setShowAddBillingAddressModalCallback}
    />} */}
    {(showAddAddressModal && addressType === 'customer') &&
    <ConsigneeAddressModal
      setShowAddressModalCallback={setShowAddConsigneeAddressModalCallback}
      addressType={addressType}
    />}
  </>;

  return (
    <div className={"settings-middle-container"}>
      <Tabs style={{marginTop: '-20px'}} className={'custom-tabs'} tabBarExtraContent={addAddress} onChange={onTabChange}>
        <TabPane tab={<label style={{cursor: 'pointer'}}>{getText("Pickup Address")} </label>} key="pickup_address">
          <PickUpAddressesList
            pickUpLocations={pickupLocationsFromb2c}
            activeTab={"pickup_address"}
          />
        </TabPane>
        <TabPane tab={<label style={{cursor: 'pointer'}}>{"Return Address"} </label>} key="return_address">
          <PickUpAddressesList
            pickUpLocations={returnLocationsFromb2c}
            activeTab={"return_address"}
          />
        </TabPane>
        {/* <TabPane tab={<label style={{cursor: 'pointer'}}>{getText("Billing Address")} </label>} key="billing_address">
          <BillingAddressesList
            data = {(billingLocations.data && billingLocations.data?.address !== "")? [billingLocations.data] : []}
            billingLocations={billingLocations}
          />
        </TabPane> */}
        <TabPane tab={<label style={{cursor: 'pointer'}}>{"Customer Address"} </label>} key="consignee_address">
          <ConsigneeAddressesList
            consigneeLocations={consigneeLocationsFromB2c}
          />
        </TabPane>
      </Tabs>
    </div>
  )
};

export const ManageAddressesPage = () =>  {
  let { getText } = useLanguage()
  return (
    <>
      <Breadcrumb className="bread-crumbs">
        <Breadcrumb.Item>
          <Link to={`/settings`} >
            <span style={{border: 'none',background: 'none'}} >
              {getText("Settings")}
            </span>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{getText("Manage Addresses")}</Breadcrumb.Item>
      </Breadcrumb>
      <Row align="middle" justify="center">
        <Col span={18} style={{marginTop:24}}>
          <Card className={'no-padding'}>
            <ManageAddressesContainer/>
          </Card>
        </Col>
      </Row>
    </>
  )
};
