import { ReactNode } from 'react';
import {Card, Row, Col, Collapse, Tabs, Divider  } from 'antd'
import { OrderRelated, PackagingRelated, PickupRelated, DeliveryRelated, ShippingChargesRelated, RefundRelated, ProhibitedItemList, DropOffRelated , TutorialVideos, ClaimsRelated} from './QuestionData'
import { useLanguage } from '../languages/Language';
import { useParams } from 'react-router-dom';
import { SinglePickupVideoViewed, SingleDropOffVideoViewed, BulkPickUpDropOffVideoViewed } from '../commons/mixpanel'
import { NewTermsAndConditions } from '../commons/components/TermsAndConditions/NewTermsAndConditions';
interface Questions {
  header: string,
  content: ReactNode
  // ReactElement<any, any>?
}

const { Panel } = Collapse;
const { TabPane } = Tabs;

const FaqQuestions = (questionType: string): Questions[] => {
  console.log(questionType)
  if(questionType === 'Order Creation Related') {
    return OrderRelated()
  }
  if(questionType === 'Packaging Related') {
    return PackagingRelated()
  }
  if(questionType === 'Pickup Related') {
    return PickupRelated()
  }
  if(questionType === 'Delivery Related') {
    return DeliveryRelated()
  }
  if(questionType === 'Shipping Charges and D&T/VAT/GST Related') {
    return ShippingChargesRelated()
  }
  if(questionType === 'Refund Related') {
    return RefundRelated()
  }
  if(questionType === 'Prohibited Item List') {
    return ProhibitedItemList()
  }
  if(questionType === 'Tutorial Videos') {
    return TutorialVideos()
  }
  if(questionType === 'Drop-off Related') {
    return DropOffRelated()
  }
  if(questionType === 'Claims Related') {
    return ClaimsRelated()
  }
  return []
}

export const NeedHelpPage = () =>  {
  let params: {defaultKey: string} = useParams()
  let defaultKeyToOPen = 'Packaging Related';
  if(params.defaultKey === 'pickup_fee'){
    defaultKeyToOPen = 'Shipping Charges and D&T/VAT/GST Related';
  }
  else if(params.defaultKey === 'tutorials'){
    SinglePickupVideoViewed();
    defaultKeyToOPen = 'Tutorial Videos';
  }
  else{
    defaultKeyToOPen ='Packaging Related';
  }

  let { getText } = useLanguage()

  let supportTypeArr = ['Packaging Related', 'Order Creation Related', 'Pickup Related', 'Delivery Related', 'Shipping Charges and D&T/VAT/GST Related','Drop-off Related','Prohibited Item List', 'Claims Related', 'Tutorial Videos', 'Terms & Conditions']
  let callback = (key:any) => {
    if(key === '0'){
      SinglePickupVideoViewed()
    }
    else if(key === '1'){
      SingleDropOffVideoViewed()
    }
    else if(key ==='2'){
      BulkPickUpDropOffVideoViewed()
    }
  }
  return (
    <>
      <Tabs tabPosition={'left'} className={"faq-container"} defaultActiveKey={defaultKeyToOPen}>
        {
          supportTypeArr.map((types: any) => {
            return (
              <TabPane tab={getText(types)} key={types} >
                <Row>
                  <Col span={24}>
                    <Card>
                      {types !== 'Terms & Conditions' && <h2>{getText(types)}</h2>}
                      {types === 'Terms & Conditions'? 
                        <div className={'main-container'}>
                          <NewTermsAndConditions />
                        </div>
                        :
                        types!=='Prohibited Item List'?
                          <Collapse
                            defaultActiveKey={['0']}
                            expandIconPosition={'left'}
                            accordion
                            onChange={types==='Tutorial Videos'?callback:()=>{}}
                          >
                            {
                              FaqQuestions(types).map((questions, index) => {
                                return (
                                  <Panel header={questions.header} key={index}>
                                    {questions.content}
                                  </Panel>
                                )
                              })
                            }
                          </Collapse>:
                          <div>
                            {
                              FaqQuestions(types).map((questions, index) => {
                                return (
                                  <div>
                                    <p style={{fontSize:'16px',fontWeight:'bold'}}>{questions.content}</p>
                                    <Divider/>
                                  </div>
                                )
                              })
                            }
                          </div>
                      }
                      <br />
                    </Card>
                  </Col>
                </Row>
              </TabPane>
            )
          })
        }
      </Tabs>
    </>
  )
};
