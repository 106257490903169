import {Button,Modal,message} from 'antd';
import {useState} from "react";
import {useMutation} from 'react-query';
import {NewTermsAndConditionsPage} from './NewTermsAndConditions.page';
import {acceptTNC} from '../../../auth/services/authApi';
import { useAuth
  // ,refreshUserSession
} from '../../../auth/services/authContext'
import { useLanguage } from '../../../languages/Language';

export const NewTCModal = () =>{
  const auth = useAuth();
  let { getText } = useLanguage();
  const [showModal, setShowModal] = useState<boolean>(true);
  const agreeTNC = useMutation(async () => {
    return acceptTNC(auth.user.authToken)
  },  {
    onSuccess: (val) => {
      // refreshUserSession(auth);
      setShowModal(false);
    },
    onError: (error: any) => {
      message.destroy();
      message.error("Failed to accept Terms and Conditions");
      setShowModal(true)
    },
  });

  const handleAgreeButton = () => {
    agreeTNC.mutate()
  }

  return(
    <>
      <Modal
        title={getText("Terms of Service")}
        width={700}
        closable={false}
        bodyStyle={{ height:'400px',overflowY: 'scroll'}}
        visible={showModal}
        className={'modal-right-footer'}
        footer={[
          <div>
            <div style={{paddingLeft:10,textAlign: 'left',fontSize:'15px',paddingBottom:15}}>Should you have any enquiries, please contact us at <a href={'mailto:clientsupport@janio.asia'} style={{color: '#050593',fontWeight:'bold'}}>clientsupport@janio.asia</a>.</div>
            <div>
              <Button type="primary" onClick={handleAgreeButton}>
                {getText('I agree')}
              </Button>
            </div>
          </div>,
        ]}>
        <NewTermsAndConditionsPage/>
      </Modal>
    </>
  )
}