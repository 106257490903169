import { Form, Divider, Tooltip } from 'antd';
import {useState, useEffect} from 'react';
import { useAuth } from '../../../auth/services/authContext';
import { useWalletDetail } from '../../../wallet/services';
import '../../styles/SingleOrderForm.css';
import {useBulkOrder} from "../../services/bulkOrderContext";
import { InfoCircleOutlined } from '@ant-design/icons'
import { useLanguage } from '../../../languages/Language';
import { SelectedPaymentMethod } from '../PayForOrders/SelectPaymentMethod';
import { pickupDateHasExpired } from '../../helpers';
import {PromoCodeInput as PromoCode} from '../../../Promotion/PromoCodeInput';
import {PickupChargeAlert} from '../../../commons/components/PickupFeeAlerts/PickupChargeAlert';
import { useLocation } from "react-router-dom";

interface PropsType {
  current: number,
  setCurrentState: (val:number)=> void,
  isEditing?:boolean
}
export const MakePaymentForm = (props:PropsType) =>{
  let location: any = useLocation()
  const auth = useAuth();
  const { getText } = useLanguage();
  const {current} = props;
  const bulkOrderContext = useBulkOrder();
  const [totalAmount, setTotalAmount] = useState<number>(0)
  const [itemObject, setItemObject] = useState<any>({})
  const [priceBreakUp, setPriceBreakUp] = useState<any>([])
  const [chargeIds, setChargeIds] = useState<number[]>();
  const [discountAmount, setDiscountAmount] = useState<number>(0);
  const [promoCode, setPromoCode] = useState();
  const setDiscountAmountCallback = (amount:number,promoCode:any) => {
    let roundedAmount = parseFloat(amount.toString());
    setDiscountAmount(roundedAmount)
    setPromoCode(promoCode)
  }

  useEffect(() => {
    let amountArr = bulkOrderContext.priceContext?.map((data:any) => {
      if(data.is_tax_payable){
        return parseFloat(data.total)
      }
      return parseFloat(data.total) - parseFloat(data.total_duties)-parseFloat(data.total_taxes)
    })

    let items = bulkOrderContext.priceContext?.map((data:any) => {
      return {
        "order_id": data.id,
        "amount": data.total,
        "currency": data.currency,
        "batch_no": data.batch_no
      }
    })
    let chargeIds = bulkOrderContext.priceContext?.map((data:any) => {return data.charge_id})

    let shippingCharges = bulkOrderContext.priceContext?.map((data:any) => {
      let total_duties = data.total_duties ? parseFloat(data.total_duties) : 0
      let total_taxes = data.total_taxes ? parseFloat(data.total_taxes) : 0
      let gst_amount = data.gst_amount ? parseFloat(data.gst_amount) : 0

      let dNt = total_duties + total_taxes
      let shippingCharges = (parseFloat(data.total) - dNt) - gst_amount
      return shippingCharges
    })

    let taxes = bulkOrderContext.priceContext?.map((data:any) => {
      if(data.total_taxes&&data.is_tax_payable) {
        return parseFloat(data.total_taxes)
      } 
      return 0
    })

    let duties = bulkOrderContext.priceContext?.map((data:any) => {
      if (data.total_duties&&data.is_tax_payable) {
        return parseFloat(data.total_duties)
      }
      return 0
    })

    let gst = bulkOrderContext.priceContext?.map((data:any) => {
      if (data.gst_amount) {
        return parseFloat(data.gst_amount)
      }
      return 0
    })

    let pickupFeeTotal = bulkOrderContext.priceContext?.map((data:any) => {
      if (data.pickup_charge) {
        return parseFloat(data.pickup_charge)
      }
      return 0
    })

    setChargeIds(chargeIds)
    setTotalAmount(getSumofArr(amountArr)+getSumofArr(pickupFeeTotal))
    setItemObject(items)

    setPriceBreakUp([getSumofArr(shippingCharges).toFixed(2), getSumofArr(taxes).toFixed(2), getSumofArr(duties).toFixed(2), getSumofArr(gst).toFixed(2),getSumofArr(pickupFeeTotal).toFixed(2)])

    bulkOrderContext.setCanEditOrder(true)
  }, [bulkOrderContext.priceContext]) // eslint-disable-line react-hooks/exhaustive-deps

  const authToken = auth.user.authToken;
  const walletDetail = useWalletDetail(authToken);
  let orderData = {
    amount: totalAmount,
    currency: bulkOrderContext.priceContext![0].currency,
    batch_no: location.pathname.split('bulk/')[1],
    amountToPay: totalAmount - discountAmount
  }
  if(current !== 3) return null

  const getSumofArr = (arr: any) => {
    return arr.reduce((a:any, b:any) => a + b )
  }

  const renderPriceBreakUp = () => {
    return (
      <div className={'amount-breakup'}>
        <p>{getText("Shipping Rate")}: <span>{priceBreakUp[0]} {orderData.currency }</span></p>
        <p>{getText("Taxes")}: <span>{priceBreakUp[1]} {orderData.currency } </span></p>
        <p>{getText("Duties")}: <span>{priceBreakUp[2]} {orderData.currency } </span></p>
        <p>{getText("GST")}: <span>{priceBreakUp[3]} {orderData.currency } </span></p>
        <p>{"Pickup Fee"}: <span>{priceBreakUp[4]} {orderData.currency } </span></p>
        <p>{getText("Total Amount")}: <span>{(orderData.amountToPay).toFixed(2)}</span></p>
      </div>
    )
  }

  const checkPaymentStatus = () => {
    return bulkOrderContext.bulkOrder.orders[0].payment_status
  }

  const reloadComponent = () => {
    walletDetail.refetch()
  }

  const orderIsStillValid = () => {
    // currently only checks for pickup date
    // should add more in the future, and consider more messages
    if(pickupDateHasExpired(bulkOrderContext.bulkOrder.orders[0].pickup_date)){
      return false
    }

    return true
  }

  let orderCharges:any = bulkOrderContext.priceContext?.map((data:any) => {
    return {
      "order_id": data.id,
      "amount": data.total
    }
  })

  return (
    checkPaymentStatus() !== 'PAID' ?
      <div className="single-order-form-container price-form" style={{border: 'none'}}>
        <Form
          layout={'vertical'}
          requiredMark={false}
        >
          {
            auth.user.hide_price_estimation ? 
              <div className={"title-block"} style={{marginBottom: '-20px'}}>
                <Form.Item label={<label className="info-disclaimer">Create Shipment:</label>} className={"single-order-form-title"}>
                </Form.Item>
              </div>
              :
              <div className={"title-block"}>
                <Form.Item label={<label className="info-disclaimer">{getText("Estimated shipping charges")}:</label>} className={"single-order-form-title"}>
                </Form.Item>

                <Form.Item label={<label className="info-disclaimer">{`${itemObject && itemObject[0] && itemObject[0].currency ? itemObject[0].currency : 'sgd'} ${(orderData.amountToPay).toFixed(2)}`}<Tooltip color={"#fff"} title={renderPriceBreakUp()}><InfoCircleOutlined className={'info-price-break'}/></Tooltip></label>} className={"single-order-form-title"}>
                </Form.Item>
              </div>
          }
          {!auth.user.hide_price_estimation && <Divider style={{marginTop: 0}}/>}
          {!props.isEditing && <PromoCode currency={orderData.currency} orderCharges={orderCharges} setDiscountAmountCallback={setDiscountAmountCallback}/>}
          {(priceBreakUp[4]!&&priceBreakUp[4]>0) && (<div style={{marginTop:15}}><PickupChargeAlert/></div>)}
          <Divider/>
          <SelectedPaymentMethod
            bulkOrderChargeIds={chargeIds}
            orderData={orderData}
            walletDetail={walletDetail}
            orderType={'BULK'}
            reloadComponent={reloadComponent}
            orderIsStillValid={orderIsStillValid()}
            promoCode={promoCode}
          />
        </Form>
      </div> :
      <div className="single-order-form-container">
        <div className={"title-block"}>
          <div>{getText("Order Already Paid")}</div>
        </div>
      </div>
  )
};
export const MakePaymentFormTitle = (props:PropsType) =>{
  const {current, setCurrentState} = props;
  const { getText } = useLanguage();
  return (
    <div className={"title-block"}>
      <p className={'step-title'}>Payment</p>
      {current>3 ? <p onClick={()=> setCurrentState(3)} className={"edit-text"}>{getText("Edit details")}</p>:''}
    </div>
  )
};

