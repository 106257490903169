import React, { useEffect } from 'react'
import {
  Button,
  Form,
} from 'antd';
import {UserSignedUpNotVerified} from "../../commons/mixpanel"

interface PropsData {
  userDetail? : {
    email: string
    full_name: string
  }
}
export const ThanksForm = (props?: PropsData) => {
  const {email, full_name} = props?.userDetail!
  useEffect(() => {
    UserSignedUpNotVerified(email, full_name)
  }, [email,full_name]) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form
      layout={'vertical'}
      requiredMark={false}
    >
      <Form.Item label="Thank you for registering!" className={"form-title"}>
      </Form.Item>
      <Form.Item className={"form-desc"}>
      Before proceeding, please check your email inbox, spam or junk folder for a verification link. If you do not receive the verification email, <a href="/auth/resend_verification" className={"form-text"}> click here to resend.</a>
      </Form.Item>
      <Form.Item className={'form-footer'}>
        <Button type="primary" size={"large"} className={"form-button"}>
          <a href={'/login'}>Back to login page</a>
        </Button>
      </Form.Item>
    </Form>
  )
};

