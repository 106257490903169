import React, { useEffect, useState } from 'react'
import {Row, Col, Card, Steps} from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {CaretLeftOutlined} from '@ant-design/icons'
import {ClaimTypeSelectionTitle, ClaimsTypeSelection} from './Component/ClaimsTypeSelection';
import { MultiClaimFormTitle, MultiClaimsForm } from './Component/MultiClaimForm';

const {Step} = Steps;
const MultiClaim = () => {
  const [current, setCurrent] = useState(0)
  const [progress, setProgress] = useState(0)
  
  const [claimType, setClaimType] = useState<string>('')
  const [selectedTN, setSelectedTN] = useState([])

  const location = useLocation();
  const {selectedOrders}: any = location.state;

  useEffect(() => {
    const claimProcessTN = selectedOrders.map((order: any) => {
      return {tracking_number: order.tracking_no, order: order.order_id}
    })
    setSelectedTN(claimProcessTN)
  },[selectedOrders])

  const steps = [
    {
      title: <ClaimTypeSelectionTitle current={current} setCurrent={setCurrent}/>,
      content: <ClaimsTypeSelection 
        claimType={claimType} 
        setClaimType={setClaimType} 
        current={current} 
        setCurrent={setCurrent} 
        selectedOrders={selectedOrders}/>
    },
    {
      title: <MultiClaimFormTitle/>,
      content: <MultiClaimsForm 
        claimType={claimType} 
        selectedOrders={selectedTN}/>
    }
  ]

  
  useEffect(() => {
    if (current > progress) {
      setProgress(current);
    }
  }, [current, progress]);

  return (
    <>
      <Steps direction='vertical' current={current}>
        {steps.map((item, index) =>( 
          <Step 
            key={index} 
            title={item.title} 
            description={(current >= index || progress >= index)? item.content: ''} /> ))}
      </Steps>
    </>
  )
}


const MultiClaimSubmission = () => {
  return (
    <Row align='middle' justify='center'>
      <Col span={24} className={'claim-container'}>
        <Link to={'/claims'}>
          <p><CaretLeftOutlined/> Claims</p>
        </Link>
        <Card className='claim-card'>
          <MultiClaim/>
        </Card>
      </Col>
    </Row>
  )
}

export default MultiClaimSubmission