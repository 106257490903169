import {
  Card,
  Row,
  Col,
  Button, Alert
} from 'antd'
import {useQuery} from "react-query";
import { Link, useLocation } from 'react-router-dom';
import './styles/wallet.css'
// @ts-ignore

import {getOrdersSummary} from "../orders/services/privateApi";
import { useLanguage } from '../languages/Language';
import {PickupThresholdAlert} from "../commons/components/PickupFeeAlerts/PickupThresholdAlert"
import {UserViewedManageShipmentsPage} from '../../src/commons/mixpanel'
import {useAuth} from '../auth/services/authContext';

export const TopUpSuccess = () =>  {
  let { getText } = useLanguage()
  let auth = useAuth()
  let params = useLocation()

  let isReturn = params.pathname.includes('returns')
  useQuery('ordersSummary', () =>{
    return getOrdersSummary(auth.user.authToken)
  });

  return (
    <>
      {/* <BillingAddressAlert/> */}
      <Row gutter={24}>
        <Col span={18} className="success_container wallet_container" style={{marginTop: '60px'}}>
          <Card>
            <div className="check_mark">
              <main></main>
            </div>

            <p>{getText("Shipment Created Successfully")}</p>
            {/* {pathCheck ? <article>Transaction ID: {transactionID}</article> : ''} */}
            <Col style={{margin: 'auto',marginBottom:10}}>
              <PickupThresholdAlert />
            </Col>
            <Col span="16" style={{margin: 'auto'}}>
              <Alert
                message={<span className="packing-alerts">{getText("Don't know how to pack your package")}? <a href={"https://janio.asia/articles/packaging-101-how-to-package-products-for-delivery/"} target="_blank" rel='noopener noreferrer'> {getText('Click here')}!</a></span>}
                type="info"
                showIcon
              />
            </Col>

            <Col span="24">
              <Row gutter={12}>
                <Col span="12" style={{textAlign: 'right'}}>
                  <Link to={isReturn ? `/orders/create/return/single` : `/orders/create/single`}  id="success_container">
                    <Button style={{color: '#fff', height: '35px'}} type={'default'}>{isReturn ? 'Create another Return shipment' : 'Create another shipment'}</Button>
                  </Link>
                </Col>
                <Col span="12"  style={{textAlign: 'left'}}>
                  <Link to={`/orders`} onClick={() => UserViewedManageShipmentsPage('ordersuccess')}>
                    <Button style={{color: '#fff', width: '220px', height: '35px'}} type={'primary'}>{getText('Print my shipping label')}</Button>
                  </Link>
                </Col>
              </Row>
            </Col>

          </Card>
        </Col>
      </Row>
    </>
  )
};
