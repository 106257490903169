import {
  Card,
  Row,
  Col,
  Breadcrumb,
  Button,
  Radio,
  Space,
  message, Spin} from 'antd'
import '../styles/UserSettings.css';
import {useMutation, useQuery} from "react-query";
import {useState} from "react";
import {Link} from "react-router-dom";
import { useLanguage } from '../../languages/Language';
import {useAuth} from "../../auth/services/authContext";
import {updateLabelAddressPreference_in_b2c, getProfileData_from_B2C, updateLabelSize_in_b2c} from "../../auth/services/authApi";

export const PrintLabelSize = () => {
  const auth = useAuth();
  let { getText } = useLanguage();
  const [labelType, setLabelType] = useState<string>('default');
  const [isLabelShipperAddress, setIsLabelShipperAddress] = useState<string>('false')

  const fetchProfileFromB2C = useQuery(['fetchProfileFromB2C'], async () => {
    return getProfileData_from_B2C(auth.user.secret_key)
  },{cacheTime:0})

  const changeLabelSize = useMutation(async () => {
    return updateLabelSize_in_b2c(auth.user.b2c_id, labelType, auth.user.secret_key)
  },  {
    onSuccess: (val:any) => {
      fetchProfileFromB2C.refetch()
      message.success("Successfully updated the Label Size")
    },
    onError: (error: any) => {
      message.destroy()
      message.error("Failed to update the Label Size")
    }
  });

  const changeAddressLabelPreference = useMutation(async () => {
    let labelAddressPreference = isLabelShipperAddress === 'true' ? true : false
    return updateLabelAddressPreference_in_b2c(auth.user.b2c_id, labelAddressPreference, auth.user.secret_key)
  },  {
    onSuccess: (val:any) => {
      fetchProfileFromB2C.refetch()
      message.success("Successfully updated the Label Address preference")
    },
    onError: (error: any) => {
      message.destroy()
      message.error("Failed to update the Label Address preference")
    }
  });

  const onSave = () => {
    changeLabelSize.mutate();
  }

  const onSavingLabelAddressPreference = () => {
    changeAddressLabelPreference.mutate();
  }

  const onLabelTypeChange = (event:any) => {
    setLabelType(event.target.value)
  }
  if(fetchProfileFromB2C.isLoading || fetchProfileFromB2C.isFetching){
    return (
      <div style={{textAlign: 'center', marginTop: 200}}>
        <Spin />
      </div>
    )
  }
  const onShipperAddressLabelChange = (event:any) => {
    setIsLabelShipperAddress(event.target.value)
  }
  return (
    <>
      <Breadcrumb className="bread-crumbs">
        <Breadcrumb.Item>
          <Link to={`/settings`} >
            <span style={{border: 'none',background: 'none'}} >
              {getText("Settings")}
            </span>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{"Shipping Label"}</Breadcrumb.Item>
      </Breadcrumb>
      <Row align="middle" justify="center">
        <Col span={16} style={{marginTop:24}}>
          <Card title="Printing Label Size" className={'no-padding label-section-wrapper'} headStyle={{fontSize:20,fontWeight:'bold'}}>
            <div className="print-label-size">
              <Radio.Group defaultValue={fetchProfileFromB2C?.data?.label_preference} onChange={onLabelTypeChange}>
                <Space direction="vertical" className={"space-item"}>
                  <Radio value={'default'}>
                    <p>*Domestic shipments - Shipping Label Only (Suitable for normal and thermal printer).</p>
                  </Radio>
                  <p className={"label-size-text"}>10.1 x 15.2 cm / 4 x 6 inches</p>
                  <Radio value={'compact'}>
                    <p>Compact Size - ONLY for domestic Singapore shipments.</p>
                  </Radio>
                  <p className={"label-size-text"}>5.4 x 10.1 cm / 2.13 x 4 inches</p>
                </Space>
              </Radio.Group>
              <div style={{marginTop:'30px',fontStyle:'italic'}}>*For international shipments, both shipping and commercial invoice will be printed as 10.1 x 15.2 cm / 4 x 6 inches.</div>
            </div>
            <div className={"button-styles"}>
              <Button htmlType="submit" type="primary" onClick={onSave} disabled={changeLabelSize.isLoading} loading={changeLabelSize.isLoading}>
                {getText('Save')}
              </Button>
            </div>
          </Card>
        </Col>
      </Row>

      <Row align="middle" justify="center">
        <Col span={16} style={{marginTop:24}}>
          <Card title="Display Pickup Address on Label" className={'no-padding label-section-wrapper'} headStyle={{fontSize:20,fontWeight:'bold'}}>
            <div className="print-label-size">
              <Radio.Group defaultValue={fetchProfileFromB2C?.data?.label_shipper_address === true ? 'true' : 'false'} onChange={onShipperAddressLabelChange}>
                <Space direction="vertical" className={"space-item"}>
                  <Radio value={"true"}>
                    <p>Yes. Display my pickup address on the label.</p>
                  </Radio>
                  <Radio value={"false"}>
                    <p>No. Do not display my pickup address on the label.</p>
                  </Radio>
                </Space>
              </Radio.Group>
            </div>
            <div className={"button-styles"}>
              <Button htmlType="submit" type="primary" onClick={onSavingLabelAddressPreference} disabled={changeAddressLabelPreference.isLoading} loading={changeAddressLabelPreference.isLoading}>
                {getText('Save')}
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  )
};
