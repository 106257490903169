import { Col, Row, Table } from 'antd';
import Button from 'antd/lib/button';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { capitalizeString, getDateInReadableFormat, replaceString } from '../../commons/utils/utilizer';

interface IClaimsTrackingTable {
  isLoading: boolean;
  data: any;
  page: number;
  setPage: (page: number) => any;
  setSelectedOrders: (order: any) => any;
}

const ClaimsTrackingTable = (props: IClaimsTrackingTable) => {
  const [selectedOrders, setSelectedOrders] = useState([]);

  const columns = [
    {
      title: 'Shipper Order Id',
      dataIndex: 'shipper_order_id',
      key: 'shipper_order_id',
      width: 135,
      render: (shipper_order_id: any) => {
        return shipper_order_id || 'N/A';
      }
    },
    {
      title: 'Tracking number',
      dataIndex: 'tracking_no',
      key: 'tracking_no',
      width: 195,
      render: (tracking_no: any, data: any) => {
        return <Link to={`/orders/view_shipment_details/${data.order_id}`}>
          <button className="table-link" style={{ cursor: 'pointer', textAlign: 'left' }}>
            {tracking_no || 'N/A'}
          </button>
        </Link>;
      }
    },
    {
      title: 'Order Status',
      dataIndex: 'public_tracker_status_v2',
      key: 'public_tracker_status_v2',
      width: 150,
      render: (public_tracker_status_v2: string) => {
        if (public_tracker_status_v2) {
          let replacedString = replaceString(public_tracker_status_v2, '_', " ");
          return capitalizeString(replacedString);
        }
      }
    },
    {
      title: 'Destination Country',
      dataIndex: 'consignee_country',
      key: 'consignee_country',
      width: 160,
      render: (consignee_country: any) => {
        return consignee_country || 'N/A';
      }
    },
    {
      title: 'Submitted On',
      dataIndex: 'created_on',
      key: 'created_on',
      width: 120,
      render: (created_on: any) => {
        return getDateInReadableFormat(created_on);
      }
    },
    {
      title: 'Submitted By',
      dataIndex: 'created_by',
      key: 'created_by',
      width: 120,
      render: (created_by: string) => {
        return created_by || 'N/A';
      }
    },
    {
      title: 'Status',
      dataIndex: 'event_type',
      key: 'event_type',
      width: 120,
      render: (event_type: string) => {
        return event_type || 'N/A';
      }
    },
    {
      title: 'Claim Type',
      dataIndex: 'claim_type',
      key: 'claim_type',
      width: 120,
      render: (claim_type: string) => {
        if (claim_type) {
          let replacedString = replaceString(claim_type, '_', " ");
          return capitalizeString(replacedString);
        } else {
          return 'N/A';
        }
      }
    },
    {
      title: 'Updated On',
      dataIndex: 'updated_on',
      key: 'updated_on',
      width: 120,
      render: (updated_on: any) => {
        return getDateInReadableFormat(updated_on);
      }
    },
    {
      title: 'Actions',
      dataIndex: 'Actions',
      key: 'Actions',
      width: 120,
      fixed: 'right' as 'right',
      render: (_: any, data: any) => {
        // TODO: add post escalate api

        return (
          <Row gutter={8}>
            <Col span={24}>
              <Link to={{pathname: `/escalate/`, state:{tracking_no: [data]}}}>
                <Button type='primary' style={{ color: '#fff', borderRadius: '4px', width: '100%' }}>
                  Escalate
                </Button>
              </Link>
            </Col>
          </Row>
        );
      }
    }
  ];

  const rowSelection = {
    onSelect: (record: any, selected: boolean, selectedRows: any) => {
      let orders: any = selectedOrders;
      if (selected) {
        orders.push(record);
      } else {
        orders = orders.filter((order: any) => order.order_id !== record.order_id);
      }
      setSelectedOrders(orders);
      props.setSelectedOrders(orders);
    },
    onSelectAll: (selected: boolean, selectedRows: any, changeRows: any) => {

      let orders: any = selectedOrders;
      if (selected) {
        changeRows.map((row: any) => {
          return orders.push(row);
        });
      }
      else {
        changeRows.map((row: any) => {
          return orders = orders.filter((value: any) => {
            return value.order_id !== row.order_id;
          });
        });
      }
      setSelectedOrders(orders);
      props.setSelectedOrders(orders);
    }
  };

  const onSwitchPage = (page: any) => {
    props.setPage(page);
  };

  return (
    <Col className={selectedOrders.length?'mt-50':'mt-85'} span={24} >
      <Table
        className='pagination-select-wrapper'
        rowClassName='table_body'
        rowKey="order_id"
        loading={props.isLoading}
        rowSelection={{ preserveSelectedRowKeys: true, ...rowSelection }}
        dataSource={props.data?.results}
        columns={columns}
        scroll={{ x: 1300 }}
        pagination={{
          position: ['bottomRight'],
          showSizeChanger:false,
          defaultPageSize: 10,
          current: props.page,
          total: props.data && props.data.count,
          onChange: onSwitchPage,
        }}
      />
    </Col>
  );
};

export default ClaimsTrackingTable;