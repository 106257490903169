import { Col, Row, Tabs } from 'antd';
import { Fragment, useState } from 'react';
import { useQuery } from 'react-query';
import Filters from '../../ManageShipments/Components/Filters';
import { formatDateDefaultFn } from '../../ManageShipments/ManageShipmentsContainer';
// import { getOrderData } from '../../ManageShipments/services';
import { useAuth } from '../../auth/services/authContext';
import ClaimsSubmissionTable from './ClaimsSubmissionTable';
import ClaimsTrackingFilters from './ClaimsTrackingFilters';
import ClaimsTrackingTable from './ClaimsTrackingTable';
import MultiClaimSelectedHeader from './MultiClaimSelectedHeader';
import MultiEscalateSelectedHeader from './MultiEscalateSelectedHeader';
import { useHistory } from 'react-router';

const { TabPane } = Tabs;
const TabKey1 = 'claim_submission';
const TabKey2 = 'claim_tracking';

const ClaimsContainer = () => {
  const auth = useAuth();
  const secretKey = auth.user.secret_key;
  const history = useHistory()
  let defaultActiveKeyURL = history.location.search.split('=')[1]
  const [key, setKey] = useState<string>(defaultActiveKeyURL? defaultActiveKeyURL : TabKey1);
  const [claimSubmissionPage, setClaimSubmissionPage] = useState<number>(1);
  const [claimTrackingPage, setClaimTrackingPage] = useState<number>(1);
  const [current, setCurrent] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectedClaimOrders, setSelectedClaimOrders] = useState({ orders: [] });
  const [selectedEscalateOrders, setSelectedEscalateOrders] = useState({ orders: [] });
  const [shipperOrderId, setShipperOrderId] = useState<null | string>('');
  const [trackingNumber, setTrackingNumber] = useState<null | string>('');
  const [orderStatus, setOrderStatus] = useState<null | string>('');
  const [serviceType, setServiceType] = useState<null | string>('');
  const [pickupCountry, setPickupCountry] = useState<null | string>('');
  const [destinationCountry, setDestinationCountry] = useState<null | string>('');
  const [startDate, setStartDate] = useState<null | string>(formatDateDefaultFn('start'));
  const [endDate, setEndDate] = useState<null | string>(formatDateDefaultFn('end'));
  const [claimType, setClaimType] = useState<null | string>('');
  const [claimStatus, setClaimStatus] = useState<null | string>('');
  const [submittedBy, setSubmittedBy] = useState<null | string>('');
  const [submittedOnStart, setSubmittedOnStart] = useState<null | string>('');
  const [submittedOnEnd, setSubmittedOnEnd] = useState<null | string>('');
  const [lastUpdatedStart, setLastUpdatedStart] = useState<null | string>('');
  const [lastUpdatedEnd, setLastUpdatedEnd] = useState<null | string>('');

  const onTabChange = (key: string) =>{ 
    window.history.replaceState(null, '', `?status=${key}`);
    setKey(key)};
  const setSelectedClaimOrdersCallback = (orders: any) => setSelectedClaimOrders({ orders: orders });
  const setSelectedEscalateOrdersCallback = (orders: any) => setSelectedEscalateOrders({ orders: orders });

  // const getClaimsData = useQuery(['getClaimsData', pageSize, claimSubmissionPage, secretKey, trackingNumber, shipperOrderId, orderStatus, pickupCountry, destinationCountry, startDate, endDate, serviceType], async () => {
  //   return getOrderData(secretKey, {
  //     "claim_eligible_orders": true,
  //     "order_status": null,
  //     "page_size": pageSize,
  //     "page": claimSubmissionPage,
  //     "tracking_no": trackingNumber,
  //     "shipper_order_id": shipperOrderId,
  //     "public_status_v2_shipper": orderStatus,
  //     "pickup_country": pickupCountry,
  //     "consignee_country": destinationCountry,
  //     "secret_key": secretKey,
  //     "date_from": encodeURIComponent(startDate!),
  //     "date_to": encodeURIComponent(endDate!),
  //     "service_category": serviceType,
  //   });
  // });

  const getClaimsData = {
    "count": 20,
    "next": "http://api.staging.janio.asia/api/order/order/?date_from=2023-12-15T00%3A00%3A00%2B05%3A30&date_to=2024-03-15T23%3A59%3A59%2B05%3A30&order_status=DELIVERED&page=2&page_size=10&secret_key=EcuGYPiw9jXycmbOO19gsXZfweepVIh1",
    "previous": null,
    "results": [
      {
        "order_id": 154934,
        "forward_order_id": null,
        "return_order_id": null,
        "is_return_order": false,
        "is_label_less_order": false,
        "service_id": 40,
        "service_level": null,
        "agent_application_id": 4214,
        "store_id": null,
        "created_by": "Client",
        "event_type": "Submitted",
        "claim_type": "Missing Parcels",
        "tracking_no": "J4N2WTQYHQU3V16OMY",
        "shipper_sub_account_id": null,
        "shipper_order_id": "JANI100",
        "shipper_sub_order_id": null,
        "consignee_name": "1661 Inc c/o Janio Malaysia",
        "consignee_address": "Lot Warehouse C, 3, Jalan Perindustrian Puchong",
        "consignee_postal": "47100",
        "consignee_country": "Malaysia",
        "consignee_state": "Selangor",
        "consignee_city": "Puchong",
        "consignee_province": "",
        "consignee_number": "+606023253456",
        "consignee_email": "",
        "order_length": "10.00",
        "order_width": "14.00",
        "order_height": "3.00",
        "order_weight": "23.00",
        "pickup_contact_name": "Dennis Chieng",
        "pickup_contact_number": "+60567435",
        "pickup_country": "Malaysia",
        "pickup_state": "Wilayah Persekutuan Kuala Lumpur",
        "pickup_city": "Pandan",
        "pickup_province": "",
        "pickup_address": "B-11-03A, Z Residence, Jalan Jalil Perwira 2",
        "pickup_postal": "58200",
        "pickup_date": null,
        "pickup_time_from": null,
        "pickup_time_to": null,
        "pickup_notes": null,
        "payment_type": "prepaid",
        "identification_document_name": null,
        "consignee_identifcation_number": null,
        "incoterm": null,
        "cod_amt_to_collect": null,
        "delivery_note": null,
        "print_default_label": true,
        "upload_batch_no": "16O240315112146",
        "is_processing": true,
        "created_on": "2024-03-15T11:21:57.227829Z",
        "updated_on": "2024-03-15T11:21:57.227859Z",
        "order_label_url": null,
        "additional_data": {
          "last_touch": "2024-03-15T11:30:48+00:00",
          "order_direction": "FORWARD",
          "billing_category": "EXWORKS"
        },
        "tracker_status_code": "SUCCESS",
        "tracker_main_text": "Delivered",
        "tracker_updated_on": "2024-03-15T11:30:48Z",
        "private_tracker_status_code": "ORDER_DELIVERED",
        "private_tracker_updated_on": "2024-03-15T11:30:48Z",
        "agent_application_name": "1661. Inc (Demo)",
        "return_tracking_no": null,
        "forward_tracking_no": null,
        "consignee_currency": "MYR",
        "consignee_currency_text": "MYR - Malaysian Ringgit",
        "partner_logo_urls": [],
        "print_urls": [],
        "external_tracking_nos": [],
        "external_order_data": [],
        "label_url_browser": "https://janio-label-demo.herokuapp.com/J4N2WTQYHQU3V16OMY,",
        "shipping_batch_nos": [],
        "customs_documents_status": null,
        "customs_documents": [],
        "weight_recon_records": [
          {
            "length": 10.0,
            "width": 14.0,
            "height": 3.0,
            "weight": 23.0,
            "created_on": "2024-03-15T11:21:58.016848Z",
            "source": "Order"
          }
        ],
        "partner_submission_status": {
          "total_count": 4,
          "success_count": 0,
          "error_count": 0
        },
        "order_exceptions": [],
        "escalation_status": null,
        "is_escalatable": false,
        "public_tracker_status_v2": "DELIVERY_COMPLETED",
        "private_tracker_status_v2": "ORDER_DELIVERED",
        "order_type": "STANDARD",
        "special_instruction": "Carefull of Glas",
        "service_category": "pickup"
      },
      {
        "order_id": 154933,
        "forward_order_id": null,
        "return_order_id": null,
        "is_return_order": false,
        "is_label_less_order": false,
        "service_id": 39,
        "service_level": null,
        "agent_application_id": 4214,
        "store_id": null,
        "created_by": "Client",
        "event_type": "In Review",
        "claim_type": "Missing Parcels",
        "tracking_no": "J4NVIU6BUIQCO16OMY",
        "shipper_sub_account_id": null,
        "shipper_order_id": "JANI99",
        "shipper_sub_order_id": null,
        "consignee_name": "C/O Anthony Bickham, 1661 Inc.",
        "consignee_address": "31 Alps Avenue, #7",
        "consignee_postal": "80200",
        "consignee_country": "Malaysia",
        "consignee_state": "Johor",
        "consignee_city": "Johor",
        "consignee_province": "",
        "consignee_number": "+606023253456",
        "consignee_email": "",
        "order_length": "10.00",
        "order_width": "14.00",
        "order_height": "8.00",
        "order_weight": "22.00",
        "pickup_contact_name": "Kevin and Anggi Dropoff",
        "pickup_contact_number": "+628119898922",
        "pickup_country": "Indonesia",
        "pickup_state": "Jakarta",
        "pickup_city": "North Jakarta City",
        "pickup_province": "",
        "pickup_address": "Gudang Janio, JL. Raya Pegangsaan Dua Km 3, RT.5/RW.3, Pegangsaan Dua, Kelapa Gading",
        "pickup_postal": "14250",
        "pickup_date": null,
        "pickup_time_from": null,
        "pickup_time_to": null,
        "pickup_notes": null,
        "payment_type": "prepaid",
        "identification_document_name": null,
        "consignee_identifcation_number": null,
        "incoterm": "DDP",
        "cod_amt_to_collect": null,
        "delivery_note": null,
        "print_default_label": true,
        "upload_batch_no": "16O240315112146",
        "is_processing": false,
        "created_on": "2024-03-15T11:21:57.227647Z",
        "updated_on": "2024-03-15T11:21:57.227677Z",
        "order_label_url": null,
        "additional_data": {
          "last_touch": "2024-03-15T11:30:48+00:00",
          "order_direction": "FORWARD",
          "billing_category": "FOB"
        },
        "tracker_status_code": "SUCCESS",
        "tracker_main_text": "Delivered",
        "tracker_updated_on": "2024-03-15T11:30:48Z",
        "private_tracker_status_code": "ORDER_DELIVERED",
        "private_tracker_updated_on": "2024-03-15T11:30:48Z",
        "agent_application_name": "1661. Inc (Demo)",
        "return_tracking_no": null,
        "forward_tracking_no": null,
        "consignee_currency": "MYR",
        "consignee_currency_text": "MYR - Malaysian Ringgit",
        "partner_logo_urls": [],
        "print_urls": [],
        "external_tracking_nos": [],
        "external_order_data": [],
        "label_url_browser": "https://janio-label-demo.herokuapp.com/J4NVIU6BUIQCO16OMY,",
        "shipping_batch_nos": [],
        "customs_documents_status": null,
        "customs_documents": [],
        "weight_recon_records": [
          {
            "length": 10.0,
            "width": 14.0,
            "height": 8.0,
            "weight": 22.0,
            "created_on": "2024-03-15T11:21:58.016823Z",
            "source": "Order"
          }
        ],
        "partner_submission_status": {
          "total_count": 0,
          "success_count": 0,
          "error_count": 0
        },
        "order_exceptions": [],
        "escalation_status": null,
        "is_escalatable": false,
        "public_tracker_status_v2": "DELIVERY_COMPLETED",
        "private_tracker_status_v2": "ORDER_DELIVERED",
        "order_type": "STANDARD",
        "special_instruction": "HS Code: 64039990, Country Of Origin: VN",
        "service_category": "dropoff"
      },
      {
        "order_id": 154932,
        "forward_order_id": null,
        "return_order_id": null,
        "is_return_order": false,
        "is_label_less_order": false,
        "service_id": 888,
        "service_level": "1 - 3 Days",
        "agent_application_id": 4214,
        "store_id": null,
        "created_by": "System",
        "event_type": "Approved",
        "claim_type": "Damaged Parcels",
        "tracking_no": "J4NCY3ZU75E5016OSG",
        "shipper_sub_account_id": null,
        "shipper_order_id": "JANI98",
        "shipper_sub_order_id": null,
        "consignee_name": "Jason",
        "consignee_address": "Blk 550 Novena",
        "consignee_postal": "309462",
        "consignee_country": "Singapore",
        "consignee_state": "Singapore",
        "consignee_city": "Singapore",
        "consignee_province": "",
        "consignee_number": "+6571234570",
        "consignee_email": "jason@email.com",
        "order_length": "10.00",
        "order_width": "30.34",
        "order_height": "14.00",
        "order_weight": "4.60",
        "pickup_contact_name": "Shipping Dept, 1661 Inc.",
        "pickup_contact_number": "+6590000100",
        "pickup_country": "Singapore",
        "pickup_state": "Singapore",
        "pickup_city": "Singapore",
        "pickup_province": "",
        "pickup_address": "31 Alps Avenue, #7",
        "pickup_postal": "498784",
        "pickup_date": "2024-03-20",
        "pickup_time_from": "09:00:00",
        "pickup_time_to": "19:00:00",
        "pickup_notes": null,
        "payment_type": "prepaid",
        "identification_document_name": null,
        "consignee_identifcation_number": null,
        "incoterm": null,
        "cod_amt_to_collect": null,
        "delivery_note": null,
        "print_default_label": true,
        "upload_batch_no": "16O240315112146",
        "is_processing": false,
        "created_on": "2024-03-15T11:21:57.227462Z",
        "updated_on": "2024-03-15T11:21:57.227493Z",
        "order_label_url": null,
        "additional_data": {
          "last_touch": "2024-03-15T11:30:48+00:00",
          "order_direction": "FORWARD",
          "billing_category": "EXWORKS_EXPRESS"
        },
        "tracker_status_code": "SUCCESS",
        "tracker_main_text": "Delivered",
        "tracker_updated_on": "2024-03-15T11:30:48Z",
        "private_tracker_status_code": "ORDER_DELIVERED",
        "private_tracker_updated_on": "2024-03-15T11:30:48Z",
        "agent_application_name": "1661. Inc (Demo)",
        "return_tracking_no": null,
        "forward_tracking_no": null,
        "consignee_currency": "SGD",
        "consignee_currency_text": "SGD - Singapore Dollar",
        "partner_logo_urls": [],
        "print_urls": [],
        "external_tracking_nos": [],
        "external_order_data": [],
        "label_url_browser": "https://janio-label-demo.herokuapp.com/J4NCY3ZU75E5016OSG,jason@email.com",
        "shipping_batch_nos": [],
        "customs_documents_status": null,
        "customs_documents": [],
        "weight_recon_records": [
          {
            "length": 10.0,
            "width": 30.34,
            "height": 14.0,
            "weight": 4.6,
            "created_on": "2024-03-15T11:21:58.016798Z",
            "source": "Order"
          }
        ],
        "partner_submission_status": {
          "total_count": 0,
          "success_count": 0,
          "error_count": 0
        },
        "order_exceptions": [],
        "escalation_status": null,
        "is_escalatable": false,
        "public_tracker_status_v2": "DELIVERY_COMPLETED",
        "private_tracker_status_v2": "ORDER_DELIVERED",
        "order_type": "STANDARD",
        "special_instruction": "HS Code: 6403.91, Country Of Origin: CN",
        "service_category": "pickup express"
      },
      {
        "order_id": 154931,
        "forward_order_id": null,
        "return_order_id": null,
        "is_return_order": false,
        "is_label_less_order": false,
        "service_id": 888,
        "service_level": "1 - 3 Days",
        "agent_application_id": 4214,
        "store_id": null,
        "created_by": "Client",
        "event_type": "Start Investigation",
        "claim_type": "Damaged Parcels",
        "tracking_no": "J4N4ZIP72WO1D16OSG",
        "shipper_sub_account_id": null,
        "shipper_order_id": "JANI97",
        "shipper_sub_order_id": null,
        "consignee_name": "Jason",
        "consignee_address": "Blk 550 Novena",
        "consignee_postal": "309462",
        "consignee_country": "Singapore",
        "consignee_state": "Singapore",
        "consignee_city": "Singapore",
        "consignee_province": "",
        "consignee_number": "+6571234570",
        "consignee_email": "jason@email.com",
        "order_length": "25.60",
        "order_width": "50.00",
        "order_height": "12.40",
        "order_weight": "3.00",
        "pickup_contact_name": "Shipping Dept, 1661 Inc.",
        "pickup_contact_number": "+6590000100",
        "pickup_country": "Singapore",
        "pickup_state": "Singapore",
        "pickup_city": "Singapore",
        "pickup_province": "",
        "pickup_address": "31 Alps Avenue, #7",
        "pickup_postal": "498784",
        "pickup_date": "2024-03-20",
        "pickup_time_from": "09:00:00",
        "pickup_time_to": "19:00:00",
        "pickup_notes": null,
        "payment_type": "prepaid",
        "identification_document_name": null,
        "consignee_identifcation_number": null,
        "incoterm": null,
        "cod_amt_to_collect": null,
        "delivery_note": null,
        "print_default_label": true,
        "upload_batch_no": "16O240315112146",
        "is_processing": false,
        "created_on": "2024-03-15T11:21:57.227275Z",
        "updated_on": "2024-03-15T11:21:57.227307Z",
        "order_label_url": null,
        "additional_data": {
          "last_touch": "2024-03-15T11:39:27+00:00",
          "order_direction": "FORWARD",
          "billing_category": "EXWORKS_EXPRESS"
        },
        "tracker_status_code": "SUCCESS",
        "tracker_main_text": "Delivered",
        "tracker_updated_on": "2024-03-15T11:39:27Z",
        "private_tracker_status_code": "ORDER_DELIVERED",
        "private_tracker_updated_on": "2024-03-15T11:39:27Z",
        "agent_application_name": "1661. Inc (Demo)",
        "return_tracking_no": null,
        "forward_tracking_no": null,
        "consignee_currency": "SGD",
        "consignee_currency_text": "SGD - Singapore Dollar",
        "partner_logo_urls": [],
        "print_urls": [],
        "external_tracking_nos": [],
        "external_order_data": [],
        "label_url_browser": "https://janio-label-demo.herokuapp.com/J4N4ZIP72WO1D16OSG,jason@email.com",
        "shipping_batch_nos": [],
        "customs_documents_status": null,
        "customs_documents": [],
        "weight_recon_records": [
          {
            "length": 25.6,
            "width": 50.0,
            "height": 12.4,
            "weight": 3.0,
            "created_on": "2024-03-15T11:21:58.016773Z",
            "source": "Order"
          }
        ],
        "partner_submission_status": {
          "total_count": 0,
          "success_count": 0,
          "error_count": 0
        },
        "order_exceptions": [],
        "escalation_status": null,
        "is_escalatable": false,
        "public_tracker_status_v2": "DELIVERY_COMPLETED",
        "private_tracker_status_v2": "ORDER_DELIVERED",
        "order_type": "STANDARD",
        "special_instruction": "HS Code: 6403.91, Country Of Origin: ID",
        "service_category": "pickup express"
      },
      {
        "order_id": 154930,
        "forward_order_id": null,
        "return_order_id": null,
        "is_return_order": false,
        "is_label_less_order": false,
        "service_id": 20,
        "service_level": null,
        "agent_application_id": 4214,
        "store_id": null,
        "created_by": "Internal Janio",
        "event_type": "Approved",
        "claim_type": "Damaged Parcels",
        "tracking_no": "J4NVKMM0XDYQ416OSG",
        "shipper_sub_account_id": null,
        "shipper_order_id": "JANI96",
        "shipper_sub_order_id": null,
        "consignee_name": "C/O Semaj Mines-Hobbs, 1661 Inc.",
        "consignee_address": "31 Alps Avenue, #7",
        "consignee_postal": "498784",
        "consignee_country": "Singapore",
        "consignee_state": "Singapore",
        "consignee_city": "Singapore",
        "consignee_province": "",
        "consignee_number": "+6571234570",
        "consignee_email": "",
        "order_length": "25.60",
        "order_width": "50.00",
        "order_height": "12.40",
        "order_weight": "3.00",
        "pickup_contact_name": "Kevin and Anggi Dropoff",
        "pickup_contact_number": "+628119898922",
        "pickup_country": "Indonesia",
        "pickup_state": "Jakarta",
        "pickup_city": "North Jakarta City",
        "pickup_province": "",
        "pickup_address": "Gudang Janio, JL. Raya Pegangsaan Dua Km 3, RT.5/RW.3, Pegangsaan Dua, Kelapa Gading",
        "pickup_postal": "14250",
        "pickup_date": null,
        "pickup_time_from": null,
        "pickup_time_to": null,
        "pickup_notes": null,
        "payment_type": "prepaid",
        "identification_document_name": null,
        "consignee_identifcation_number": null,
        "incoterm": "DDP",
        "cod_amt_to_collect": null,
        "delivery_note": null,
        "print_default_label": true,
        "upload_batch_no": "16O240315112146",
        "is_processing": false,
        "created_on": "2024-03-15T11:21:57.227076Z",
        "updated_on": "2024-03-15T11:21:57.227107Z",
        "order_label_url": null,
        "additional_data": {
          "last_touch": "2024-03-15T11:39:27+00:00",
          "order_direction": "FORWARD",
          "billing_category": "FOB"
        },
        "tracker_status_code": "SUCCESS",
        "tracker_main_text": "Delivered",
        "tracker_updated_on": "2024-03-15T11:39:27Z",
        "private_tracker_status_code": "ORDER_DELIVERED",
        "private_tracker_updated_on": "2024-03-15T11:39:27Z",
        "agent_application_name": "1661. Inc (Demo)",
        "return_tracking_no": null,
        "forward_tracking_no": null,
        "consignee_currency": "SGD",
        "consignee_currency_text": "SGD - Singapore Dollar",
        "partner_logo_urls": [],
        "print_urls": [],
        "external_tracking_nos": [],
        "external_order_data": [],
        "label_url_browser": "https://janio-label-demo.herokuapp.com/J4NVKMM0XDYQ416OSG,",
        "shipping_batch_nos": [],
        "customs_documents_status": null,
        "customs_documents": [],
        "weight_recon_records": [
          {
            "length": 25.6,
            "width": 50.0,
            "height": 12.4,
            "weight": 3.0,
            "created_on": "2024-03-15T11:21:58.016749Z",
            "source": "Order"
          }
        ],
        "partner_submission_status": {
          "total_count": 1,
          "success_count": 0,
          "error_count": 0
        },
        "order_exceptions": [],
        "escalation_status": null,
        "is_escalatable": false,
        "public_tracker_status_v2": "DELIVERY_COMPLETED",
        "private_tracker_status_v2": "ORDER_DELIVERED",
        "order_type": "STANDARD",
        "special_instruction": "HS Code: 64039990, Country Of Origin: VN",
        "service_category": "dropoff"
      },
      {
        "order_id": 154929,
        "forward_order_id": null,
        "return_order_id": null,
        "is_return_order": false,
        "is_label_less_order": false,
        "service_id": 40,
        "service_level": null,
        "agent_application_id": 4214,
        "store_id": null,
        "created_by": "System",
        "event_type": "Approved",
        "claim_type": "Weight Dispute(Parcel)",
        "tracking_no": "J4NB2U8FJXTW016OMY",
        "shipper_sub_account_id": null,
        "shipper_order_id": "JANI95",
        "shipper_sub_order_id": null,
        "consignee_name": "1661 Inc c/o Janio Malaysia",
        "consignee_address": "Lot Warehouse C, 3, Jalan Perindustrian Puchong",
        "consignee_postal": "47100",
        "consignee_country": "Malaysia",
        "consignee_state": "Selangor",
        "consignee_city": "Puchong",
        "consignee_province": "",
        "consignee_number": "+606023253456",
        "consignee_email": "",
        "order_length": "10.00",
        "order_width": "14.00",
        "order_height": "3.00",
        "order_weight": "23.00",
        "pickup_contact_name": "Dennis Chieng",
        "pickup_contact_number": "+60567435",
        "pickup_country": "Malaysia",
        "pickup_state": "Wilayah Persekutuan Kuala Lumpur",
        "pickup_city": "Pandan",
        "pickup_province": "",
        "pickup_address": "B-11-03A, Z Residence, Jalan Jalil Perwira 2",
        "pickup_postal": "58200",
        "pickup_date": null,
        "pickup_time_from": null,
        "pickup_time_to": null,
        "pickup_notes": null,
        "payment_type": "prepaid",
        "identification_document_name": null,
        "consignee_identifcation_number": null,
        "incoterm": null,
        "cod_amt_to_collect": null,
        "delivery_note": null,
        "print_default_label": true,
        "upload_batch_no": "16O240315112146",
        "is_processing": true,
        "created_on": "2024-03-15T11:21:57.226888Z",
        "updated_on": "2024-03-15T11:21:57.226921Z",
        "order_label_url": null,
        "additional_data": {
          "last_touch": "2024-03-15T11:35:04+00:00",
          "order_direction": "FORWARD",
          "billing_category": "EXWORKS"
        },
        "tracker_status_code": "SUCCESS",
        "tracker_main_text": "Delivered",
        "tracker_updated_on": "2024-03-15T11:35:04Z",
        "private_tracker_status_code": "ORDER_DELIVERED",
        "private_tracker_updated_on": "2024-03-15T11:35:04Z",
        "agent_application_name": "1661. Inc (Demo)",
        "return_tracking_no": null,
        "forward_tracking_no": null,
        "consignee_currency": "MYR",
        "consignee_currency_text": "MYR - Malaysian Ringgit",
        "partner_logo_urls": [],
        "print_urls": [],
        "external_tracking_nos": [],
        "external_order_data": [],
        "label_url_browser": "https://janio-label-demo.herokuapp.com/J4NB2U8FJXTW016OMY,",
        "shipping_batch_nos": [],
        "customs_documents_status": null,
        "customs_documents": [],
        "weight_recon_records": [
          {
            "length": 10.0,
            "width": 14.0,
            "height": 3.0,
            "weight": 23.0,
            "created_on": "2024-03-15T11:21:58.016724Z",
            "source": "Order"
          }
        ],
        "partner_submission_status": {
          "total_count": 4,
          "success_count": 0,
          "error_count": 0
        },
        "order_exceptions": [],
        "escalation_status": null,
        "is_escalatable": false,
        "public_tracker_status_v2": "DELIVERY_COMPLETED",
        "private_tracker_status_v2": "ORDER_DELIVERED",
        "order_type": "STANDARD",
        "special_instruction": "Carefull of Glas",
        "service_category": "pickup"
      },
      {
        "order_id": 154928,
        "forward_order_id": null,
        "return_order_id": null,
        "is_return_order": false,
        "is_label_less_order": false,
        "service_id": 20,
        "service_level": null,
        "agent_application_id": 4214,
        "store_id": null,
        "created_by": "System",
        "event_type": "Escalated",
        "claim_type": "Missing Parcels",
        "tracking_no": "J4NEWPGH3G5MP16OSG",
        "shipper_sub_account_id": null,
        "shipper_order_id": "JANI94",
        "shipper_sub_order_id": null,
        "consignee_name": "1661 Inc c/o Janio Malaysia",
        "consignee_address": "Lot Warehouse C, 3, Jalan Perindustrian Puchong",
        "consignee_postal": "498784",
        "consignee_country": "Singapore",
        "consignee_state": "Singapore",
        "consignee_city": "Singapore",
        "consignee_province": "",
        "consignee_number": "+6571234570",
        "consignee_email": "",
        "order_length": "10.00",
        "order_width": "14.00",
        "order_height": "54.00",
        "order_weight": "12.00",
        "pickup_contact_name": "Kevin and Anggi Dropoff",
        "pickup_contact_number": "+628119898922",
        "pickup_country": "Indonesia",
        "pickup_state": "Jakarta",
        "pickup_city": "North Jakarta City",
        "pickup_province": "",
        "pickup_address": "Gudang Janio, JL. Raya Pegangsaan Dua Km 3, RT.5/RW.3, Pegangsaan Dua, Kelapa Gading",
        "pickup_postal": "14250",
        "pickup_date": null,
        "pickup_time_from": null,
        "pickup_time_to": null,
        "pickup_notes": null,
        "payment_type": "prepaid",
        "identification_document_name": null,
        "consignee_identifcation_number": null,
        "incoterm": "DDP",
        "cod_amt_to_collect": null,
        "delivery_note": null,
        "print_default_label": true,
        "upload_batch_no": "16O240315112146",
        "is_processing": false,
        "created_on": "2024-03-15T11:21:57.226699Z",
        "updated_on": "2024-03-15T11:21:57.226730Z",
        "order_label_url": null,
        "additional_data": {
          "last_touch": "2024-03-15T11:35:04+00:00",
          "order_direction": "FORWARD",
          "billing_category": "FOB"
        },
        "tracker_status_code": "SUCCESS",
        "tracker_main_text": "Delivered",
        "tracker_updated_on": "2024-03-15T11:35:04Z",
        "private_tracker_status_code": "ORDER_DELIVERED",
        "private_tracker_updated_on": "2024-03-15T11:35:04Z",
        "agent_application_name": "1661. Inc (Demo)",
        "return_tracking_no": null,
        "forward_tracking_no": null,
        "consignee_currency": "SGD",
        "consignee_currency_text": "SGD - Singapore Dollar",
        "partner_logo_urls": [],
        "print_urls": [],
        "external_tracking_nos": [],
        "external_order_data": [],
        "label_url_browser": "https://janio-label-demo.herokuapp.com/J4NEWPGH3G5MP16OSG,",
        "shipping_batch_nos": [],
        "customs_documents_status": null,
        "customs_documents": [],
        "weight_recon_records": [
          {
            "length": 10.0,
            "width": 14.0,
            "height": 54.0,
            "weight": 12.0,
            "created_on": "2024-03-15T11:21:58.016700Z",
            "source": "Order"
          }
        ],
        "partner_submission_status": {
          "total_count": 1,
          "success_count": 0,
          "error_count": 0
        },
        "order_exceptions": [],
        "escalation_status": null,
        "is_escalatable": false,
        "public_tracker_status_v2": "DELIVERY_COMPLETED",
        "private_tracker_status_v2": "ORDER_DELIVERED",
        "order_type": "STANDARD",
        "special_instruction": "HS Code: 64041190, Country Of Origin: VN",
        "service_category": "dropoff"
      },
      {
        "order_id": 154927,
        "forward_order_id": null,
        "return_order_id": null,
        "is_return_order": false,
        "is_label_less_order": false,
        "service_id": 20,
        "service_level": null,
        "agent_application_id": 4214,
        "store_id": null,
        "created_by": "Client",
        "event_type": "Escalated",
        "claim_type": "Damaged Parcels",
        "tracking_no": "J4N1F4UN9J4X316OSG",
        "shipper_sub_account_id": null,
        "shipper_order_id": "JANI93",
        "shipper_sub_order_id": null,
        "consignee_name": "C/O Nicole Burns, 1661 Inc.",
        "consignee_address": "31 Alps Avenue, #7",
        "consignee_postal": "498784",
        "consignee_country": "Singapore",
        "consignee_state": "Singapore",
        "consignee_city": "Singapore",
        "consignee_province": "",
        "consignee_number": "+6571234570",
        "consignee_email": "",
        "order_length": "30.00",
        "order_width": "30.00",
        "order_height": "30.00",
        "order_weight": "5.00",
        "pickup_contact_name": "Kevin and Anggi Dropoff",
        "pickup_contact_number": "+628119898922",
        "pickup_country": "Indonesia",
        "pickup_state": "Jakarta",
        "pickup_city": "North Jakarta City",
        "pickup_province": "",
        "pickup_address": "Gudang Janio, JL. Raya Pegangsaan Dua Km 3, RT.5/RW.3, Pegangsaan Dua, Kelapa Gading",
        "pickup_postal": "14250",
        "pickup_date": null,
        "pickup_time_from": null,
        "pickup_time_to": null,
        "pickup_notes": null,
        "payment_type": "prepaid",
        "identification_document_name": null,
        "consignee_identifcation_number": null,
        "incoterm": "DDP",
        "cod_amt_to_collect": null,
        "delivery_note": null,
        "print_default_label": true,
        "upload_batch_no": "16O240315112146",
        "is_processing": false,
        "created_on": "2024-03-15T11:21:57.226510Z",
        "updated_on": "2024-03-15T11:21:57.226541Z",
        "order_label_url": null,
        "additional_data": {
          "last_touch": "2024-03-15T11:35:04+00:00",
          "order_direction": "FORWARD",
          "billing_category": "FOB"
        },
        "tracker_status_code": "SUCCESS",
        "tracker_main_text": "Delivered",
        "tracker_updated_on": "2024-03-15T11:35:04Z",
        "private_tracker_status_code": "ORDER_DELIVERED",
        "private_tracker_updated_on": "2024-03-15T11:35:04Z",
        "agent_application_name": "1661. Inc (Demo)",
        "return_tracking_no": null,
        "forward_tracking_no": null,
        "consignee_currency": "SGD",
        "consignee_currency_text": "SGD - Singapore Dollar",
        "partner_logo_urls": [],
        "print_urls": [],
        "external_tracking_nos": [],
        "external_order_data": [],
        "label_url_browser": "https://janio-label-demo.herokuapp.com/J4N1F4UN9J4X316OSG,",
        "shipping_batch_nos": [],
        "customs_documents_status": null,
        "customs_documents": [],
        "weight_recon_records": [
          {
            "length": 30.0,
            "width": 30.0,
            "height": 30.0,
            "weight": 5.0,
            "created_on": "2024-03-15T11:21:58.016675Z",
            "source": "Order"
          }
        ],
        "partner_submission_status": {
          "total_count": 1,
          "success_count": 0,
          "error_count": 0
        },
        "order_exceptions": [],
        "escalation_status": null,
        "is_escalatable": false,
        "public_tracker_status_v2": "DELIVERY_COMPLETED",
        "private_tracker_status_v2": "ORDER_DELIVERED",
        "order_type": "STANDARD",
        "special_instruction": "HS Code: 64039990, Country Of Origin: VN",
        "service_category": "dropoff"
      },
      {
        "order_id": 154926,
        "forward_order_id": null,
        "return_order_id": null,
        "is_return_order": false,
        "is_label_less_order": false,
        "service_id": 40,
        "service_level": null,
        "agent_application_id": 4214,
        "store_id": null,
        "created_by": "Internal Janio",
        "event_type": "Approved",
        "claim_type": "Damaged Parcels",
        "tracking_no": "J4NU1J1H87OIJ16OMY",
        "shipper_sub_account_id": null,
        "shipper_order_id": "JANI92",
        "shipper_sub_order_id": null,
        "consignee_name": "1661 Inc c/o Janio Malaysia",
        "consignee_address": "Lot Warehouse C, 3, Jalan Perindustrian Puchong",
        "consignee_postal": "47100",
        "consignee_country": "Malaysia",
        "consignee_state": "Selangor",
        "consignee_city": "Puchong",
        "consignee_province": "",
        "consignee_number": "+606023253456",
        "consignee_email": "ryan45ang93@gmail.com",
        "order_length": "10.00",
        "order_width": "14.00",
        "order_height": "3.00",
        "order_weight": "23.00",
        "pickup_contact_name": "Dennis Chieng",
        "pickup_contact_number": "+60567435",
        "pickup_country": "Malaysia",
        "pickup_state": "Wilayah Persekutuan Kuala Lumpur",
        "pickup_city": "Pandan",
        "pickup_province": "",
        "pickup_address": "B-11-03A, Z Residence, Jalan Jalil Perwira 2",
        "pickup_postal": "58200",
        "pickup_date": null,
        "pickup_time_from": null,
        "pickup_time_to": null,
        "pickup_notes": null,
        "payment_type": "prepaid",
        "identification_document_name": null,
        "consignee_identifcation_number": null,
        "incoterm": null,
        "cod_amt_to_collect": null,
        "delivery_note": null,
        "print_default_label": true,
        "upload_batch_no": "16O240315112146",
        "is_processing": true,
        "created_on": "2024-03-15T11:21:57.226319Z",
        "updated_on": "2024-03-15T11:21:57.226351Z",
        "order_label_url": null,
        "additional_data": {
          "last_touch": "2024-03-15T11:35:04+00:00",
          "order_direction": "FORWARD",
          "billing_category": "EXWORKS"
        },
        "tracker_status_code": "SUCCESS",
        "tracker_main_text": "Delivered",
        "tracker_updated_on": "2024-03-15T11:35:04Z",
        "private_tracker_status_code": "ORDER_DELIVERED",
        "private_tracker_updated_on": "2024-03-15T11:35:04Z",
        "agent_application_name": "1661. Inc (Demo)",
        "return_tracking_no": null,
        "forward_tracking_no": null,
        "consignee_currency": "MYR",
        "consignee_currency_text": "MYR - Malaysian Ringgit",
        "partner_logo_urls": [],
        "print_urls": [],
        "external_tracking_nos": [],
        "external_order_data": [],
        "label_url_browser": "https://janio-label-demo.herokuapp.com/J4NU1J1H87OIJ16OMY,ryan45ang93@gmail.com",
        "shipping_batch_nos": [],
        "customs_documents_status": null,
        "customs_documents": [],
        "weight_recon_records": [
          {
            "length": 10.0,
            "width": 14.0,
            "height": 3.0,
            "weight": 23.0,
            "created_on": "2024-03-15T11:21:58.016650Z",
            "source": "Order"
          }
        ],
        "partner_submission_status": {
          "total_count": 4,
          "success_count": 0,
          "error_count": 0
        },
        "order_exceptions": [],
        "escalation_status": null,
        "is_escalatable": false,
        "public_tracker_status_v2": "DELIVERY_COMPLETED",
        "private_tracker_status_v2": "ORDER_DELIVERED",
        "order_type": "STANDARD",
        "special_instruction": "HS Code: 64041190, Country Of Origin: CN",
        "service_category": "pickup"
      },
      {
        "order_id": 154925,
        "forward_order_id": null,
        "return_order_id": null,
        "is_return_order": false,
        "is_label_less_order": false,
        "service_id": 20,
        "service_level": null,
        "agent_application_id": 4214,
        "store_id": null,
        "created_by": "Internal Janio",
        "event_type": "Approved",
        "claim_type": "Missing Parcels",
        "tracking_no": "J4NZWJQUQXS8U16OSG",
        "shipper_sub_account_id": null,
        "shipper_order_id": "JANI91",
        "shipper_sub_order_id": null,
        "consignee_name": "C/O Tj Odom, 1661 Inc.",
        "consignee_address": "31 Alps Avenue, #7",
        "consignee_postal": "498784",
        "consignee_country": "Singapore",
        "consignee_state": "Singapore",
        "consignee_city": "Singapore",
        "consignee_province": "",
        "consignee_number": "+6571234570",
        "consignee_email": "infsdo@goat.com",
        "order_length": "29.00",
        "order_width": "14.00",
        "order_height": "54.00",
        "order_weight": "21.00",
        "pickup_contact_name": "Kevin and Anggi Dropoff",
        "pickup_contact_number": "+628119898922",
        "pickup_country": "Indonesia",
        "pickup_state": "Jakarta",
        "pickup_city": "North Jakarta City",
        "pickup_province": "",
        "pickup_address": "Gudang Janio, JL. Raya Pegangsaan Dua Km 3, RT.5/RW.3, Pegangsaan Dua, Kelapa Gading",
        "pickup_postal": "14250",
        "pickup_date": null,
        "pickup_time_from": null,
        "pickup_time_to": null,
        "pickup_notes": null,
        "payment_type": "prepaid",
        "identification_document_name": null,
        "consignee_identifcation_number": null,
        "incoterm": "DDU",
        "cod_amt_to_collect": null,
        "delivery_note": null,
        "print_default_label": true,
        "upload_batch_no": "16O240315112146",
        "is_processing": false,
        "created_on": "2024-03-15T11:21:57.226124Z",
        "updated_on": "2024-03-15T11:21:57.226159Z",
        "order_label_url": null,
        "additional_data": {
          "last_touch": "2024-03-15T11:35:04+00:00",
          "order_direction": "FORWARD",
          "billing_category": "FOB"
        },
        "tracker_status_code": "SUCCESS",
        "tracker_main_text": "Delivered",
        "tracker_updated_on": "2024-03-15T11:35:04Z",
        "private_tracker_status_code": "ORDER_DELIVERED",
        "private_tracker_updated_on": "2024-03-15T11:35:04Z",
        "agent_application_name": "1661. Inc (Demo)",
        "return_tracking_no": null,
        "forward_tracking_no": null,
        "consignee_currency": "SGD",
        "consignee_currency_text": "SGD - Singapore Dollar",
        "partner_logo_urls": [],
        "print_urls": [],
        "external_tracking_nos": [],
        "external_order_data": [],
        "label_url_browser": "https://janio-label-demo.herokuapp.com/J4NZWJQUQXS8U16OSG,infsdo@goat.com",
        "shipping_batch_nos": [],
        "customs_documents_status": null,
        "customs_documents": [],
        "weight_recon_records": [
          {
            "length": 29.0,
            "width": 14.0,
            "height": 54.0,
            "weight": 21.0,
            "created_on": "2024-03-15T11:21:58.016625Z",
            "source": "Order"
          }
        ],
        "partner_submission_status": {
          "total_count": 1,
          "success_count": 0,
          "error_count": 0
        },
        "order_exceptions": [],
        "escalation_status": null,
        "is_escalatable": false,
        "public_tracker_status_v2": "DELIVERY_COMPLETED",
        "private_tracker_status_v2": "ORDER_DELIVERED",
        "order_type": "STANDARD",
        "special_instruction": "HS Code: 6403.91, Country Of Origin: ID",
        "service_category": "dropoff"
      },
      {
        "order_id": 154934,
        "forward_order_id": null,
        "return_order_id": null,
        "is_return_order": false,
        "is_label_less_order": false,
        "service_id": 40,
        "service_level": null,
        "agent_application_id": 4214,
        "store_id": null,
        "created_by": "Client",
        "event_type": "Submitted",
        "claim_type": "Missing Parcels",
        "tracking_no": "J4N2WTQYHQU3V16OMY",
        "shipper_sub_account_id": null,
        "shipper_order_id": "JANI100",
        "shipper_sub_order_id": null,
        "consignee_name": "1661 Inc c/o Janio Malaysia",
        "consignee_address": "Lot Warehouse C, 3, Jalan Perindustrian Puchong",
        "consignee_postal": "47100",
        "consignee_country": "Malaysia",
        "consignee_state": "Selangor",
        "consignee_city": "Puchong",
        "consignee_province": "",
        "consignee_number": "+606023253456",
        "consignee_email": "",
        "order_length": "10.00",
        "order_width": "14.00",
        "order_height": "3.00",
        "order_weight": "23.00",
        "pickup_contact_name": "Dennis Chieng",
        "pickup_contact_number": "+60567435",
        "pickup_country": "Malaysia",
        "pickup_state": "Wilayah Persekutuan Kuala Lumpur",
        "pickup_city": "Pandan",
        "pickup_province": "",
        "pickup_address": "B-11-03A, Z Residence, Jalan Jalil Perwira 2",
        "pickup_postal": "58200",
        "pickup_date": null,
        "pickup_time_from": null,
        "pickup_time_to": null,
        "pickup_notes": null,
        "payment_type": "prepaid",
        "identification_document_name": null,
        "consignee_identifcation_number": null,
        "incoterm": null,
        "cod_amt_to_collect": null,
        "delivery_note": null,
        "print_default_label": true,
        "upload_batch_no": "16O240315112146",
        "is_processing": true,
        "created_on": "2024-03-15T11:21:57.227829Z",
        "updated_on": "2024-03-15T11:21:57.227859Z",
        "order_label_url": null,
        "additional_data": {
          "last_touch": "2024-03-15T11:30:48+00:00",
          "order_direction": "FORWARD",
          "billing_category": "EXWORKS"
        },
        "tracker_status_code": "SUCCESS",
        "tracker_main_text": "Delivered",
        "tracker_updated_on": "2024-03-15T11:30:48Z",
        "private_tracker_status_code": "ORDER_DELIVERED",
        "private_tracker_updated_on": "2024-03-15T11:30:48Z",
        "agent_application_name": "1661. Inc (Demo)",
        "return_tracking_no": null,
        "forward_tracking_no": null,
        "consignee_currency": "MYR",
        "consignee_currency_text": "MYR - Malaysian Ringgit",
        "partner_logo_urls": [],
        "print_urls": [],
        "external_tracking_nos": [],
        "external_order_data": [],
        "label_url_browser": "https://janio-label-demo.herokuapp.com/J4N2WTQYHQU3V16OMY,",
        "shipping_batch_nos": [],
        "customs_documents_status": null,
        "customs_documents": [],
        "weight_recon_records": [
          {
            "length": 10.0,
            "width": 14.0,
            "height": 3.0,
            "weight": 23.0,
            "created_on": "2024-03-15T11:21:58.016848Z",
            "source": "Order"
          }
        ],
        "partner_submission_status": {
          "total_count": 4,
          "success_count": 0,
          "error_count": 0
        },
        "order_exceptions": [],
        "escalation_status": null,
        "is_escalatable": false,
        "public_tracker_status_v2": "DELIVERY_COMPLETED",
        "private_tracker_status_v2": "ORDER_DELIVERED",
        "order_type": "STANDARD",
        "special_instruction": "Carefull of Glas",
        "service_category": "pickup"
      },
      {
        "order_id": 154933,
        "forward_order_id": null,
        "return_order_id": null,
        "is_return_order": false,
        "is_label_less_order": false,
        "service_id": 39,
        "service_level": null,
        "agent_application_id": 4214,
        "store_id": null,
        "created_by": "Client",
        "event_type": "In Review",
        "claim_type": "Missing Parcels",
        "tracking_no": "J4NVIU6BUIQCO16OMY",
        "shipper_sub_account_id": null,
        "shipper_order_id": "JANI99",
        "shipper_sub_order_id": null,
        "consignee_name": "C/O Anthony Bickham, 1661 Inc.",
        "consignee_address": "31 Alps Avenue, #7",
        "consignee_postal": "80200",
        "consignee_country": "Malaysia",
        "consignee_state": "Johor",
        "consignee_city": "Johor",
        "consignee_province": "",
        "consignee_number": "+606023253456",
        "consignee_email": "",
        "order_length": "10.00",
        "order_width": "14.00",
        "order_height": "8.00",
        "order_weight": "22.00",
        "pickup_contact_name": "Kevin and Anggi Dropoff",
        "pickup_contact_number": "+628119898922",
        "pickup_country": "Indonesia",
        "pickup_state": "Jakarta",
        "pickup_city": "North Jakarta City",
        "pickup_province": "",
        "pickup_address": "Gudang Janio, JL. Raya Pegangsaan Dua Km 3, RT.5/RW.3, Pegangsaan Dua, Kelapa Gading",
        "pickup_postal": "14250",
        "pickup_date": null,
        "pickup_time_from": null,
        "pickup_time_to": null,
        "pickup_notes": null,
        "payment_type": "prepaid",
        "identification_document_name": null,
        "consignee_identifcation_number": null,
        "incoterm": "DDP",
        "cod_amt_to_collect": null,
        "delivery_note": null,
        "print_default_label": true,
        "upload_batch_no": "16O240315112146",
        "is_processing": false,
        "created_on": "2024-03-15T11:21:57.227647Z",
        "updated_on": "2024-03-15T11:21:57.227677Z",
        "order_label_url": null,
        "additional_data": {
          "last_touch": "2024-03-15T11:30:48+00:00",
          "order_direction": "FORWARD",
          "billing_category": "FOB"
        },
        "tracker_status_code": "SUCCESS",
        "tracker_main_text": "Delivered",
        "tracker_updated_on": "2024-03-15T11:30:48Z",
        "private_tracker_status_code": "ORDER_DELIVERED",
        "private_tracker_updated_on": "2024-03-15T11:30:48Z",
        "agent_application_name": "1661. Inc (Demo)",
        "return_tracking_no": null,
        "forward_tracking_no": null,
        "consignee_currency": "MYR",
        "consignee_currency_text": "MYR - Malaysian Ringgit",
        "partner_logo_urls": [],
        "print_urls": [],
        "external_tracking_nos": [],
        "external_order_data": [],
        "label_url_browser": "https://janio-label-demo.herokuapp.com/J4NVIU6BUIQCO16OMY,",
        "shipping_batch_nos": [],
        "customs_documents_status": null,
        "customs_documents": [],
        "weight_recon_records": [
          {
            "length": 10.0,
            "width": 14.0,
            "height": 8.0,
            "weight": 22.0,
            "created_on": "2024-03-15T11:21:58.016823Z",
            "source": "Order"
          }
        ],
        "partner_submission_status": {
          "total_count": 0,
          "success_count": 0,
          "error_count": 0
        },
        "order_exceptions": [],
        "escalation_status": null,
        "is_escalatable": false,
        "public_tracker_status_v2": "DELIVERY_COMPLETED",
        "private_tracker_status_v2": "ORDER_DELIVERED",
        "order_type": "STANDARD",
        "special_instruction": "HS Code: 64039990, Country Of Origin: VN",
        "service_category": "dropoff"
      },
      {
        "order_id": 154932,
        "forward_order_id": null,
        "return_order_id": null,
        "is_return_order": false,
        "is_label_less_order": false,
        "service_id": 888,
        "service_level": "1 - 3 Days",
        "agent_application_id": 4214,
        "store_id": null,
        "created_by": "System",
        "event_type": "Approved",
        "claim_type": "Damaged Parcels",
        "tracking_no": "J4NCY3ZU75E5016OSG",
        "shipper_sub_account_id": null,
        "shipper_order_id": "JANI98",
        "shipper_sub_order_id": null,
        "consignee_name": "Jason",
        "consignee_address": "Blk 550 Novena",
        "consignee_postal": "309462",
        "consignee_country": "Singapore",
        "consignee_state": "Singapore",
        "consignee_city": "Singapore",
        "consignee_province": "",
        "consignee_number": "+6571234570",
        "consignee_email": "jason@email.com",
        "order_length": "10.00",
        "order_width": "30.34",
        "order_height": "14.00",
        "order_weight": "4.60",
        "pickup_contact_name": "Shipping Dept, 1661 Inc.",
        "pickup_contact_number": "+6590000100",
        "pickup_country": "Singapore",
        "pickup_state": "Singapore",
        "pickup_city": "Singapore",
        "pickup_province": "",
        "pickup_address": "31 Alps Avenue, #7",
        "pickup_postal": "498784",
        "pickup_date": "2024-03-20",
        "pickup_time_from": "09:00:00",
        "pickup_time_to": "19:00:00",
        "pickup_notes": null,
        "payment_type": "prepaid",
        "identification_document_name": null,
        "consignee_identifcation_number": null,
        "incoterm": null,
        "cod_amt_to_collect": null,
        "delivery_note": null,
        "print_default_label": true,
        "upload_batch_no": "16O240315112146",
        "is_processing": false,
        "created_on": "2024-03-15T11:21:57.227462Z",
        "updated_on": "2024-03-15T11:21:57.227493Z",
        "order_label_url": null,
        "additional_data": {
          "last_touch": "2024-03-15T11:30:48+00:00",
          "order_direction": "FORWARD",
          "billing_category": "EXWORKS_EXPRESS"
        },
        "tracker_status_code": "SUCCESS",
        "tracker_main_text": "Delivered",
        "tracker_updated_on": "2024-03-15T11:30:48Z",
        "private_tracker_status_code": "ORDER_DELIVERED",
        "private_tracker_updated_on": "2024-03-15T11:30:48Z",
        "agent_application_name": "1661. Inc (Demo)",
        "return_tracking_no": null,
        "forward_tracking_no": null,
        "consignee_currency": "SGD",
        "consignee_currency_text": "SGD - Singapore Dollar",
        "partner_logo_urls": [],
        "print_urls": [],
        "external_tracking_nos": [],
        "external_order_data": [],
        "label_url_browser": "https://janio-label-demo.herokuapp.com/J4NCY3ZU75E5016OSG,jason@email.com",
        "shipping_batch_nos": [],
        "customs_documents_status": null,
        "customs_documents": [],
        "weight_recon_records": [
          {
            "length": 10.0,
            "width": 30.34,
            "height": 14.0,
            "weight": 4.6,
            "created_on": "2024-03-15T11:21:58.016798Z",
            "source": "Order"
          }
        ],
        "partner_submission_status": {
          "total_count": 0,
          "success_count": 0,
          "error_count": 0
        },
        "order_exceptions": [],
        "escalation_status": null,
        "is_escalatable": false,
        "public_tracker_status_v2": "DELIVERY_COMPLETED",
        "private_tracker_status_v2": "ORDER_DELIVERED",
        "order_type": "STANDARD",
        "special_instruction": "HS Code: 6403.91, Country Of Origin: CN",
        "service_category": "pickup express"
      },
      {
        "order_id": 154931,
        "forward_order_id": null,
        "return_order_id": null,
        "is_return_order": false,
        "is_label_less_order": false,
        "service_id": 888,
        "service_level": "1 - 3 Days",
        "agent_application_id": 4214,
        "store_id": null,
        "created_by": "Client",
        "event_type": "Start Investigation",
        "claim_type": "Damaged Parcels",
        "tracking_no": "J4N4ZIP72WO1D16OSG",
        "shipper_sub_account_id": null,
        "shipper_order_id": "JANI97",
        "shipper_sub_order_id": null,
        "consignee_name": "Jason",
        "consignee_address": "Blk 550 Novena",
        "consignee_postal": "309462",
        "consignee_country": "Singapore",
        "consignee_state": "Singapore",
        "consignee_city": "Singapore",
        "consignee_province": "",
        "consignee_number": "+6571234570",
        "consignee_email": "jason@email.com",
        "order_length": "25.60",
        "order_width": "50.00",
        "order_height": "12.40",
        "order_weight": "3.00",
        "pickup_contact_name": "Shipping Dept, 1661 Inc.",
        "pickup_contact_number": "+6590000100",
        "pickup_country": "Singapore",
        "pickup_state": "Singapore",
        "pickup_city": "Singapore",
        "pickup_province": "",
        "pickup_address": "31 Alps Avenue, #7",
        "pickup_postal": "498784",
        "pickup_date": "2024-03-20",
        "pickup_time_from": "09:00:00",
        "pickup_time_to": "19:00:00",
        "pickup_notes": null,
        "payment_type": "prepaid",
        "identification_document_name": null,
        "consignee_identifcation_number": null,
        "incoterm": null,
        "cod_amt_to_collect": null,
        "delivery_note": null,
        "print_default_label": true,
        "upload_batch_no": "16O240315112146",
        "is_processing": false,
        "created_on": "2024-03-15T11:21:57.227275Z",
        "updated_on": "2024-03-15T11:21:57.227307Z",
        "order_label_url": null,
        "additional_data": {
          "last_touch": "2024-03-15T11:39:27+00:00",
          "order_direction": "FORWARD",
          "billing_category": "EXWORKS_EXPRESS"
        },
        "tracker_status_code": "SUCCESS",
        "tracker_main_text": "Delivered",
        "tracker_updated_on": "2024-03-15T11:39:27Z",
        "private_tracker_status_code": "ORDER_DELIVERED",
        "private_tracker_updated_on": "2024-03-15T11:39:27Z",
        "agent_application_name": "1661. Inc (Demo)",
        "return_tracking_no": null,
        "forward_tracking_no": null,
        "consignee_currency": "SGD",
        "consignee_currency_text": "SGD - Singapore Dollar",
        "partner_logo_urls": [],
        "print_urls": [],
        "external_tracking_nos": [],
        "external_order_data": [],
        "label_url_browser": "https://janio-label-demo.herokuapp.com/J4N4ZIP72WO1D16OSG,jason@email.com",
        "shipping_batch_nos": [],
        "customs_documents_status": null,
        "customs_documents": [],
        "weight_recon_records": [
          {
            "length": 25.6,
            "width": 50.0,
            "height": 12.4,
            "weight": 3.0,
            "created_on": "2024-03-15T11:21:58.016773Z",
            "source": "Order"
          }
        ],
        "partner_submission_status": {
          "total_count": 0,
          "success_count": 0,
          "error_count": 0
        },
        "order_exceptions": [],
        "escalation_status": null,
        "is_escalatable": false,
        "public_tracker_status_v2": "DELIVERY_COMPLETED",
        "private_tracker_status_v2": "ORDER_DELIVERED",
        "order_type": "STANDARD",
        "special_instruction": "HS Code: 6403.91, Country Of Origin: ID",
        "service_category": "pickup express"
      },
      {
        "order_id": 154930,
        "forward_order_id": null,
        "return_order_id": null,
        "is_return_order": false,
        "is_label_less_order": false,
        "service_id": 20,
        "service_level": null,
        "agent_application_id": 4214,
        "store_id": null,
        "created_by": "Client",
        "event_type": "Approved",
        "claim_type": "Damaged Parcels",
        "tracking_no": "J4NVKMM0XDYQ416OSG",
        "shipper_sub_account_id": null,
        "shipper_order_id": "JANI96",
        "shipper_sub_order_id": null,
        "consignee_name": "C/O Semaj Mines-Hobbs, 1661 Inc.",
        "consignee_address": "31 Alps Avenue, #7",
        "consignee_postal": "498784",
        "consignee_country": "Singapore",
        "consignee_state": "Singapore",
        "consignee_city": "Singapore",
        "consignee_province": "",
        "consignee_number": "+6571234570",
        "consignee_email": "",
        "order_length": "25.60",
        "order_width": "50.00",
        "order_height": "12.40",
        "order_weight": "3.00",
        "pickup_contact_name": "Kevin and Anggi Dropoff",
        "pickup_contact_number": "+628119898922",
        "pickup_country": "Indonesia",
        "pickup_state": "Jakarta",
        "pickup_city": "North Jakarta City",
        "pickup_province": "",
        "pickup_address": "Gudang Janio, JL. Raya Pegangsaan Dua Km 3, RT.5/RW.3, Pegangsaan Dua, Kelapa Gading",
        "pickup_postal": "14250",
        "pickup_date": null,
        "pickup_time_from": null,
        "pickup_time_to": null,
        "pickup_notes": null,
        "payment_type": "prepaid",
        "identification_document_name": null,
        "consignee_identifcation_number": null,
        "incoterm": "DDP",
        "cod_amt_to_collect": null,
        "delivery_note": null,
        "print_default_label": true,
        "upload_batch_no": "16O240315112146",
        "is_processing": false,
        "created_on": "2024-03-15T11:21:57.227076Z",
        "updated_on": "2024-03-15T11:21:57.227107Z",
        "order_label_url": null,
        "additional_data": {
          "last_touch": "2024-03-15T11:39:27+00:00",
          "order_direction": "FORWARD",
          "billing_category": "FOB"
        },
        "tracker_status_code": "SUCCESS",
        "tracker_main_text": "Delivered",
        "tracker_updated_on": "2024-03-15T11:39:27Z",
        "private_tracker_status_code": "ORDER_DELIVERED",
        "private_tracker_updated_on": "2024-03-15T11:39:27Z",
        "agent_application_name": "1661. Inc (Demo)",
        "return_tracking_no": null,
        "forward_tracking_no": null,
        "consignee_currency": "SGD",
        "consignee_currency_text": "SGD - Singapore Dollar",
        "partner_logo_urls": [],
        "print_urls": [],
        "external_tracking_nos": [],
        "external_order_data": [],
        "label_url_browser": "https://janio-label-demo.herokuapp.com/J4NVKMM0XDYQ416OSG,",
        "shipping_batch_nos": [],
        "customs_documents_status": null,
        "customs_documents": [],
        "weight_recon_records": [
          {
            "length": 25.6,
            "width": 50.0,
            "height": 12.4,
            "weight": 3.0,
            "created_on": "2024-03-15T11:21:58.016749Z",
            "source": "Order"
          }
        ],
        "partner_submission_status": {
          "total_count": 1,
          "success_count": 0,
          "error_count": 0
        },
        "order_exceptions": [],
        "escalation_status": null,
        "is_escalatable": false,
        "public_tracker_status_v2": "DELIVERY_COMPLETED",
        "private_tracker_status_v2": "ORDER_DELIVERED",
        "order_type": "STANDARD",
        "special_instruction": "HS Code: 64039990, Country Of Origin: VN",
        "service_category": "dropoff"
      },
      {
        "order_id": 154929,
        "forward_order_id": null,
        "return_order_id": null,
        "is_return_order": false,
        "is_label_less_order": false,
        "service_id": 40,
        "service_level": null,
        "agent_application_id": 4214,
        "store_id": null,
        "created_by": "System",
        "event_type": "Rejected",
        "claim_type": "Weight Dispute(Parcel)",
        "tracking_no": "J4NB2U8FJXTW016OMY",
        "shipper_sub_account_id": null,
        "shipper_order_id": "JANI95",
        "shipper_sub_order_id": null,
        "consignee_name": "1661 Inc c/o Janio Malaysia",
        "consignee_address": "Lot Warehouse C, 3, Jalan Perindustrian Puchong",
        "consignee_postal": "47100",
        "consignee_country": "Malaysia",
        "consignee_state": "Selangor",
        "consignee_city": "Puchong",
        "consignee_province": "",
        "consignee_number": "+606023253456",
        "consignee_email": "",
        "order_length": "10.00",
        "order_width": "14.00",
        "order_height": "3.00",
        "order_weight": "23.00",
        "pickup_contact_name": "Dennis Chieng",
        "pickup_contact_number": "+60567435",
        "pickup_country": "Malaysia",
        "pickup_state": "Wilayah Persekutuan Kuala Lumpur",
        "pickup_city": "Pandan",
        "pickup_province": "",
        "pickup_address": "B-11-03A, Z Residence, Jalan Jalil Perwira 2",
        "pickup_postal": "58200",
        "pickup_date": null,
        "pickup_time_from": null,
        "pickup_time_to": null,
        "pickup_notes": null,
        "payment_type": "prepaid",
        "identification_document_name": null,
        "consignee_identifcation_number": null,
        "incoterm": null,
        "cod_amt_to_collect": null,
        "delivery_note": null,
        "print_default_label": true,
        "upload_batch_no": "16O240315112146",
        "is_processing": true,
        "created_on": "2024-03-15T11:21:57.226888Z",
        "updated_on": "2024-03-15T11:21:57.226921Z",
        "order_label_url": null,
        "additional_data": {
          "last_touch": "2024-03-15T11:35:04+00:00",
          "order_direction": "FORWARD",
          "billing_category": "EXWORKS"
        },
        "tracker_status_code": "SUCCESS",
        "tracker_main_text": "Delivered",
        "tracker_updated_on": "2024-03-15T11:35:04Z",
        "private_tracker_status_code": "ORDER_DELIVERED",
        "private_tracker_updated_on": "2024-03-15T11:35:04Z",
        "agent_application_name": "1661. Inc (Demo)",
        "return_tracking_no": null,
        "forward_tracking_no": null,
        "consignee_currency": "MYR",
        "consignee_currency_text": "MYR - Malaysian Ringgit",
        "partner_logo_urls": [],
        "print_urls": [],
        "external_tracking_nos": [],
        "external_order_data": [],
        "label_url_browser": "https://janio-label-demo.herokuapp.com/J4NB2U8FJXTW016OMY,",
        "shipping_batch_nos": [],
        "customs_documents_status": null,
        "customs_documents": [],
        "weight_recon_records": [
          {
            "length": 10.0,
            "width": 14.0,
            "height": 3.0,
            "weight": 23.0,
            "created_on": "2024-03-15T11:21:58.016724Z",
            "source": "Order"
          }
        ],
        "partner_submission_status": {
          "total_count": 4,
          "success_count": 0,
          "error_count": 0
        },
        "order_exceptions": [],
        "escalation_status": null,
        "is_escalatable": false,
        "public_tracker_status_v2": "DELIVERY_COMPLETED",
        "private_tracker_status_v2": "ORDER_DELIVERED",
        "order_type": "STANDARD",
        "special_instruction": "Carefull of Glas",
        "service_category": "pickup"
      },
      {
        "order_id": 154928,
        "forward_order_id": null,
        "return_order_id": null,
        "is_return_order": false,
        "is_label_less_order": false,
        "service_id": 20,
        "service_level": null,
        "agent_application_id": 4214,
        "store_id": null,
        "created_by": "System",
        "event_type": "Approved",
        "claim_type": "Missing Parcels",
        "tracking_no": "J4NEWPGH3G5MP16OSG",
        "shipper_sub_account_id": null,
        "shipper_order_id": "JANI94",
        "shipper_sub_order_id": null,
        "consignee_name": "1661 Inc c/o Janio Malaysia",
        "consignee_address": "Lot Warehouse C, 3, Jalan Perindustrian Puchong",
        "consignee_postal": "498784",
        "consignee_country": "Singapore",
        "consignee_state": "Singapore",
        "consignee_city": "Singapore",
        "consignee_province": "",
        "consignee_number": "+6571234570",
        "consignee_email": "",
        "order_length": "10.00",
        "order_width": "14.00",
        "order_height": "54.00",
        "order_weight": "12.00",
        "pickup_contact_name": "Kevin and Anggi Dropoff",
        "pickup_contact_number": "+628119898922",
        "pickup_country": "Indonesia",
        "pickup_state": "Jakarta",
        "pickup_city": "North Jakarta City",
        "pickup_province": "",
        "pickup_address": "Gudang Janio, JL. Raya Pegangsaan Dua Km 3, RT.5/RW.3, Pegangsaan Dua, Kelapa Gading",
        "pickup_postal": "14250",
        "pickup_date": null,
        "pickup_time_from": null,
        "pickup_time_to": null,
        "pickup_notes": null,
        "payment_type": "prepaid",
        "identification_document_name": null,
        "consignee_identifcation_number": null,
        "incoterm": "DDP",
        "cod_amt_to_collect": null,
        "delivery_note": null,
        "print_default_label": true,
        "upload_batch_no": "16O240315112146",
        "is_processing": false,
        "created_on": "2024-03-15T11:21:57.226699Z",
        "updated_on": "2024-03-15T11:21:57.226730Z",
        "order_label_url": null,
        "additional_data": {
          "last_touch": "2024-03-15T11:35:04+00:00",
          "order_direction": "FORWARD",
          "billing_category": "FOB"
        },
        "tracker_status_code": "SUCCESS",
        "tracker_main_text": "Delivered",
        "tracker_updated_on": "2024-03-15T11:35:04Z",
        "private_tracker_status_code": "ORDER_DELIVERED",
        "private_tracker_updated_on": "2024-03-15T11:35:04Z",
        "agent_application_name": "1661. Inc (Demo)",
        "return_tracking_no": null,
        "forward_tracking_no": null,
        "consignee_currency": "SGD",
        "consignee_currency_text": "SGD - Singapore Dollar",
        "partner_logo_urls": [],
        "print_urls": [],
        "external_tracking_nos": [],
        "external_order_data": [],
        "label_url_browser": "https://janio-label-demo.herokuapp.com/J4NEWPGH3G5MP16OSG,",
        "shipping_batch_nos": [],
        "customs_documents_status": null,
        "customs_documents": [],
        "weight_recon_records": [
          {
            "length": 10.0,
            "width": 14.0,
            "height": 54.0,
            "weight": 12.0,
            "created_on": "2024-03-15T11:21:58.016700Z",
            "source": "Order"
          }
        ],
        "partner_submission_status": {
          "total_count": 1,
          "success_count": 0,
          "error_count": 0
        },
        "order_exceptions": [],
        "escalation_status": null,
        "is_escalatable": false,
        "public_tracker_status_v2": "DELIVERY_COMPLETED",
        "private_tracker_status_v2": "ORDER_DELIVERED",
        "order_type": "STANDARD",
        "special_instruction": "HS Code: 64041190, Country Of Origin: VN",
        "service_category": "dropoff"
      },
      {
        "order_id": 154927,
        "forward_order_id": null,
        "return_order_id": null,
        "is_return_order": false,
        "is_label_less_order": false,
        "service_id": 20,
        "service_level": null,
        "agent_application_id": 4214,
        "store_id": null,
        "created_by": "Client",
        "event_type": "Approved",
        "claim_type": "Weight Dispute(Parcel)",
        "tracking_no": "J4N1F4UN9J4X316OSG",
        "shipper_sub_account_id": null,
        "shipper_order_id": "JANI93",
        "shipper_sub_order_id": null,
        "consignee_name": "C/O Nicole Burns, 1661 Inc.",
        "consignee_address": "31 Alps Avenue, #7",
        "consignee_postal": "498784",
        "consignee_country": "Singapore",
        "consignee_state": "Singapore",
        "consignee_city": "Singapore",
        "consignee_province": "",
        "consignee_number": "+6571234570",
        "consignee_email": "",
        "order_length": "30.00",
        "order_width": "30.00",
        "order_height": "30.00",
        "order_weight": "5.00",
        "pickup_contact_name": "Kevin and Anggi Dropoff",
        "pickup_contact_number": "+628119898922",
        "pickup_country": "Indonesia",
        "pickup_state": "Jakarta",
        "pickup_city": "North Jakarta City",
        "pickup_province": "",
        "pickup_address": "Gudang Janio, JL. Raya Pegangsaan Dua Km 3, RT.5/RW.3, Pegangsaan Dua, Kelapa Gading",
        "pickup_postal": "14250",
        "pickup_date": null,
        "pickup_time_from": null,
        "pickup_time_to": null,
        "pickup_notes": null,
        "payment_type": "prepaid",
        "identification_document_name": null,
        "consignee_identifcation_number": null,
        "incoterm": "DDP",
        "cod_amt_to_collect": null,
        "delivery_note": null,
        "print_default_label": true,
        "upload_batch_no": "16O240315112146",
        "is_processing": false,
        "created_on": "2024-03-15T11:21:57.226510Z",
        "updated_on": "2024-03-15T11:21:57.226541Z",
        "order_label_url": null,
        "additional_data": {
          "last_touch": "2024-03-15T11:35:04+00:00",
          "order_direction": "FORWARD",
          "billing_category": "FOB"
        },
        "tracker_status_code": "SUCCESS",
        "tracker_main_text": "Delivered",
        "tracker_updated_on": "2024-03-15T11:35:04Z",
        "private_tracker_status_code": "ORDER_DELIVERED",
        "private_tracker_updated_on": "2024-03-15T11:35:04Z",
        "agent_application_name": "1661. Inc (Demo)",
        "return_tracking_no": null,
        "forward_tracking_no": null,
        "consignee_currency": "SGD",
        "consignee_currency_text": "SGD - Singapore Dollar",
        "partner_logo_urls": [],
        "print_urls": [],
        "external_tracking_nos": [],
        "external_order_data": [],
        "label_url_browser": "https://janio-label-demo.herokuapp.com/J4N1F4UN9J4X316OSG,",
        "shipping_batch_nos": [],
        "customs_documents_status": null,
        "customs_documents": [],
        "weight_recon_records": [
          {
            "length": 30.0,
            "width": 30.0,
            "height": 30.0,
            "weight": 5.0,
            "created_on": "2024-03-15T11:21:58.016675Z",
            "source": "Order"
          }
        ],
        "partner_submission_status": {
          "total_count": 1,
          "success_count": 0,
          "error_count": 0
        },
        "order_exceptions": [],
        "escalation_status": null,
        "is_escalatable": false,
        "public_tracker_status_v2": "DELIVERY_COMPLETED",
        "private_tracker_status_v2": "ORDER_DELIVERED",
        "order_type": "STANDARD",
        "special_instruction": "HS Code: 64039990, Country Of Origin: VN",
        "service_category": "dropoff"
      },
      {
        "order_id": 154926,
        "forward_order_id": null,
        "return_order_id": null,
        "is_return_order": false,
        "is_label_less_order": false,
        "service_id": 40,
        "service_level": null,
        "agent_application_id": 4214,
        "store_id": null,
        "created_by": "Internal Janio",
        "event_type": "Approved",
        "claim_type": "Damaged Parcels",
        "tracking_no": "J4NU1J1H87OIJ16OMY",
        "shipper_sub_account_id": null,
        "shipper_order_id": "JANI92",
        "shipper_sub_order_id": null,
        "consignee_name": "1661 Inc c/o Janio Malaysia",
        "consignee_address": "Lot Warehouse C, 3, Jalan Perindustrian Puchong",
        "consignee_postal": "47100",
        "consignee_country": "Malaysia",
        "consignee_state": "Selangor",
        "consignee_city": "Puchong",
        "consignee_province": "",
        "consignee_number": "+606023253456",
        "consignee_email": "ryan45ang93@gmail.com",
        "order_length": "10.00",
        "order_width": "14.00",
        "order_height": "3.00",
        "order_weight": "23.00",
        "pickup_contact_name": "Dennis Chieng",
        "pickup_contact_number": "+60567435",
        "pickup_country": "Malaysia",
        "pickup_state": "Wilayah Persekutuan Kuala Lumpur",
        "pickup_city": "Pandan",
        "pickup_province": "",
        "pickup_address": "B-11-03A, Z Residence, Jalan Jalil Perwira 2",
        "pickup_postal": "58200",
        "pickup_date": null,
        "pickup_time_from": null,
        "pickup_time_to": null,
        "pickup_notes": null,
        "payment_type": "prepaid",
        "identification_document_name": null,
        "consignee_identifcation_number": null,
        "incoterm": null,
        "cod_amt_to_collect": null,
        "delivery_note": null,
        "print_default_label": true,
        "upload_batch_no": "16O240315112146",
        "is_processing": true,
        "created_on": "2024-03-15T11:21:57.226319Z",
        "updated_on": "2024-03-15T11:21:57.226351Z",
        "order_label_url": null,
        "additional_data": {
          "last_touch": "2024-03-15T11:35:04+00:00",
          "order_direction": "FORWARD",
          "billing_category": "EXWORKS"
        },
        "tracker_status_code": "SUCCESS",
        "tracker_main_text": "Delivered",
        "tracker_updated_on": "2024-03-15T11:35:04Z",
        "private_tracker_status_code": "ORDER_DELIVERED",
        "private_tracker_updated_on": "2024-03-15T11:35:04Z",
        "agent_application_name": "1661. Inc (Demo)",
        "return_tracking_no": null,
        "forward_tracking_no": null,
        "consignee_currency": "MYR",
        "consignee_currency_text": "MYR - Malaysian Ringgit",
        "partner_logo_urls": [],
        "print_urls": [],
        "external_tracking_nos": [],
        "external_order_data": [],
        "label_url_browser": "https://janio-label-demo.herokuapp.com/J4NU1J1H87OIJ16OMY,ryan45ang93@gmail.com",
        "shipping_batch_nos": [],
        "customs_documents_status": null,
        "customs_documents": [],
        "weight_recon_records": [
          {
            "length": 10.0,
            "width": 14.0,
            "height": 3.0,
            "weight": 23.0,
            "created_on": "2024-03-15T11:21:58.016650Z",
            "source": "Order"
          }
        ],
        "partner_submission_status": {
          "total_count": 4,
          "success_count": 0,
          "error_count": 0
        },
        "order_exceptions": [],
        "escalation_status": null,
        "is_escalatable": false,
        "public_tracker_status_v2": "DELIVERY_COMPLETED",
        "private_tracker_status_v2": "ORDER_DELIVERED",
        "order_type": "STANDARD",
        "special_instruction": "HS Code: 64041190, Country Of Origin: CN",
        "service_category": "pickup"
      },
      {
        "order_id": 154925,
        "forward_order_id": null,
        "return_order_id": null,
        "is_return_order": false,
        "is_label_less_order": false,
        "service_id": 20,
        "service_level": null,
        "agent_application_id": 4214,
        "store_id": null,
        "created_by": "Client",
        "event_type": "Escalated",
        "claim_type": "Damaged Parcels",
        "tracking_no": "J4NZWJQUQXS8U16OSG",
        "shipper_sub_account_id": null,
        "shipper_order_id": "JANI91",
        "shipper_sub_order_id": null,
        "consignee_name": "C/O Tj Odom, 1661 Inc.",
        "consignee_address": "31 Alps Avenue, #7",
        "consignee_postal": "498784",
        "consignee_country": "Singapore",
        "consignee_state": "Singapore",
        "consignee_city": "Singapore",
        "consignee_province": "",
        "consignee_number": "+6571234570",
        "consignee_email": "infsdo@goat.com",
        "order_length": "29.00",
        "order_width": "14.00",
        "order_height": "54.00",
        "order_weight": "21.00",
        "pickup_contact_name": "Kevin and Anggi Dropoff",
        "pickup_contact_number": "+628119898922",
        "pickup_country": "Indonesia",
        "pickup_state": "Jakarta",
        "pickup_city": "North Jakarta City",
        "pickup_province": "",
        "pickup_address": "Gudang Janio, JL. Raya Pegangsaan Dua Km 3, RT.5/RW.3, Pegangsaan Dua, Kelapa Gading",
        "pickup_postal": "14250",
        "pickup_date": null,
        "pickup_time_from": null,
        "pickup_time_to": null,
        "pickup_notes": null,
        "payment_type": "prepaid",
        "identification_document_name": null,
        "consignee_identifcation_number": null,
        "incoterm": "DDU",
        "cod_amt_to_collect": null,
        "delivery_note": null,
        "print_default_label": true,
        "upload_batch_no": "16O240315112146",
        "is_processing": false,
        "created_on": "2024-03-15T11:21:57.226124Z",
        "updated_on": "2024-03-15T11:21:57.226159Z",
        "order_label_url": null,
        "additional_data": {
          "last_touch": "2024-03-15T11:35:04+00:00",
          "order_direction": "FORWARD",
          "billing_category": "FOB"
        },
        "tracker_status_code": "SUCCESS",
        "tracker_main_text": "Delivered",
        "tracker_updated_on": "2024-03-15T11:35:04Z",
        "private_tracker_status_code": "ORDER_DELIVERED",
        "private_tracker_updated_on": "2024-03-15T11:35:04Z",
        "agent_application_name": "1661. Inc (Demo)",
        "return_tracking_no": null,
        "forward_tracking_no": null,
        "consignee_currency": "SGD",
        "consignee_currency_text": "SGD - Singapore Dollar",
        "partner_logo_urls": [],
        "print_urls": [],
        "external_tracking_nos": [],
        "external_order_data": [],
        "label_url_browser": "https://janio-label-demo.herokuapp.com/J4NZWJQUQXS8U16OSG,infsdo@goat.com",
        "shipping_batch_nos": [],
        "customs_documents_status": null,
        "customs_documents": [],
        "weight_recon_records": [
          {
            "length": 29.0,
            "width": 14.0,
            "height": 54.0,
            "weight": 21.0,
            "created_on": "2024-03-15T11:21:58.016625Z",
            "source": "Order"
          }
        ],
        "partner_submission_status": {
          "total_count": 1,
          "success_count": 0,
          "error_count": 0
        },
        "order_exceptions": [],
        "escalation_status": null,
        "is_escalatable": false,
        "public_tracker_status_v2": "DELIVERY_COMPLETED",
        "private_tracker_status_v2": "ORDER_DELIVERED",
        "order_type": "STANDARD",
        "special_instruction": "HS Code: 6403.91, Country Of Origin: ID",
        "service_category": "dropoff"
      },
    ]
  }

  // TODO: add get submitted claim api
  const getSubmittedClaims = useQuery(['getSubmittedClaims', secretKey, claimTrackingPage, trackingNumber, shipperOrderId, orderStatus, destinationCountry, claimType, claimStatus, submittedBy, submittedOnStart, submittedOnEnd, lastUpdatedStart, lastUpdatedEnd], async () => {
    console.log(claimTrackingPage, trackingNumber, shipperOrderId, orderStatus, destinationCountry, claimType, claimStatus, submittedBy, submittedOnStart, submittedOnEnd, lastUpdatedStart, lastUpdatedEnd);
  });

  console.log("selectedEscalateOrders", selectedEscalateOrders, key)

  console.log('getsubmittedclaims', getSubmittedClaims.data)
  return (
    <Row className={'main-container'} style={{ position: 'relative' }}>
      <Col span={24}>
        <Row gutter={8} className={'filter-row-wrapper'}>
          <Col span={24} className={'filter-wrapper'}>
            {key === TabKey1 &&
              <Fragment>
                <Col span={selectedClaimOrders.orders.length > 0 ? 24 : 0}>
                  <MultiClaimSelectedHeader selectedOrders={selectedClaimOrders.orders} />
                </Col>
                <Col span={selectedClaimOrders.orders.length > 0 ? 0 : 24}>
                  <Filters
                    status=''
                    hasData={true}
                    shipperOrderIdString={shipperOrderId}
                    orderStatusString={orderStatus}
                    pickupCountryString={pickupCountry}
                    destinationCountryString={destinationCountry}
                    serviceTypeString={serviceType}
                    setShipperOrderIdString={setShipperOrderId}
                    setOrderStatusString={setOrderStatus}
                    setPickupCountryString={setPickupCountry}
                    setDestinationCountryString={setDestinationCountry}
                    setServiceTypeString={setServiceType}
                    setDate_from={setStartDate}
                    setDate_to={setEndDate}
                    date_from={startDate}
                    date_to={endDate}
                    setPage={setClaimSubmissionPage}
                    page={claimSubmissionPage}
                    setCurrent={setCurrent}
                    dataFrom='manageShipment'
                    trackingNumberString={trackingNumber}
                    setTrackingNumberString={setTrackingNumber}
                  />
                </Col>
              </Fragment>
            }
            {key === TabKey2 &&
              <Fragment>
                {/* {console.log(key)} */}
                <Col span={selectedEscalateOrders.orders.length > 0 ? 24 : 0}>
                  <MultiEscalateSelectedHeader selectedOrders={selectedEscalateOrders.orders} />
                </Col>
                <Col span={selectedEscalateOrders.orders.length > 0 ? 0 : 24}>
                  <ClaimsTrackingFilters
                    setPage={setClaimTrackingPage}
                    setShipperOrderId={setShipperOrderId}
                    setTrackingNumber={setTrackingNumber}
                    setOrderStatus={setOrderStatus}
                    setDestinationCountry={setDestinationCountry}
                    setClaimType={setClaimType}
                    setClaimStatus={setClaimStatus}
                    setSubmittedBy={setSubmittedBy}
                    setSubmittedOnStart={setSubmittedOnStart}
                    setSubmittedOnEnd={setSubmittedOnEnd}
                    setLastUpdatedStart={setLastUpdatedStart}
                    setLastUpdatedEnd={setLastUpdatedEnd}
                  />
                </Col>
              </Fragment>
            }
          </Col>
        </Row>
        <Tabs defaultActiveKey={defaultActiveKeyURL} onChange={onTabChange}>
          <TabPane tab={<label style={{cursor: 'pointer'}}>Eligible for Claims</label>} key={TabKey1}>
            <ClaimsSubmissionTable
              data={getClaimsData}
              setPage={setClaimSubmissionPage}
              setPageSize={setPageSize}
              page={claimSubmissionPage}
              pageSize={pageSize}
              setSelectedOrders={setSelectedClaimOrdersCallback}
              // isLoading={getClaimsData.isLoading}
              isLoading={false}
              current={current}
              setCurrent={setCurrent}
            />
          </TabPane>
          <TabPane tab={<label style={{cursor: 'pointer'}}>Submitted Claims & Disputes</label>} key={TabKey2}>
            <ClaimsTrackingTable
              // isLoading={getClaimsData.isLoading}
              isLoading={false}
              data={getClaimsData}
              page={claimTrackingPage}
              setPage={setClaimTrackingPage}
              setSelectedOrders={setSelectedEscalateOrdersCallback}
            />
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
};

export default ClaimsContainer;