import { instance as api } from '../commons/api'
import { instanceForb2c as apib2c } from '../commons/api'

export interface OrderDetailData {
  id: number,
  cod_amount: number
  consignee_address: string
  consignee_city: string
  consignee_country: string
  consignee_email: string
  consignee_name: string
  consignee_number: number
  consignee_postal: number
  consignee_province: null
  consignee_state: string
  incoterm: string
  order_height: number
  order_length: number
  order_weight: number
  order_width: number
  paid_on: null
  payment_type: string
  pickup_address: string
  pickup_city: string
  pickup_contact_name: string
  pickup_contact_number: number
  pickup_country: string
  pickup_date: null
  pickup_postal: number
  pickup_province: ""
  pickup_state: string
  tracking_no: number
  label_url: string | null
  order_type?: string
  order_id?: number
}
export interface OrderDetailData_from_b2c {
  order_id: number,
  forward_order_id: string | null,
  return_order_id: string | null,
  is_return_order: boolean,
  is_label_less_order: boolean,
  service_id: number,
  service_name: string,
  service_level: string,
  agent_application_id: string,
  store_id: number | null,
  tracking_no: string,
  shipper_sub_account_id: number | null,
  shipper_order_id: number | null,
  shipper_sub_order_id: number | null,
  epod_url?: string | null
  consignee_name: string,
  consignee_address: string,
  consignee_postal: string,
  consignee_country: string,
  consignee_state: string,
  consignee_city: string,
  consignee_province: string | null,
  consignee_number: string | null,
  consignee_email: string,
  order_direction: string,
  order_length: string,
  order_width: string,
  order_height: string,
  order_weight: string,
  pickup_contact_name: string,
  pickup_contact_number: string,
  pickup_country: string,
  pickup_state: string,
  pickup_city: string,
  pickup_province: string | null,
  pickup_address: string,
  pickup_postal: string,
  pickup_date: string,
  pickup_time_from: string,
  pickup_time_to: string,
  pickup_notes: string | null,
  payment_type: string,
  identification_document_name: string | null,
  consignee_identifcation_number: number | null,
  incoterm: string | null,
  cod_amt_to_collect: number | null,
  items: [
    {
      item_id: number,
      item_desc: string,
      item_product_id: number,
      item_sku: string,
      item_category: string,
      item_quantity: number,
      item_price_value: string,
      item_price_currency: string,
      hs_code: string | null,
      item_url: string | null,
      lvg: boolean,
      gst_collected: boolean,
      gst_ovr: string | null
    }
  ],
  delivery_note: string | null,
  print_default_label: boolean,
  upload_batch_no: string,
  is_processing: boolean,
  created_on: string,
  updated_on: string,
  order_label_url: string | null,
  additional_data: {
    order_direction: string,
    billing_category: string
  },
  tracker_status_code: string,
  tracker_main_text: string | null,
  tracker_updated_on: string,
  private_tracker_status_code: string,
  private_tracker_updated_on: string,
  agent_application_name: string,
  return_tracking_no: number | null,
  forward_tracking_no: number | null,
  consignee_currency: string,
  consignee_currency_text: string,
  external_tracking_nos: [],
  external_order_data: [],
  label_url_browser: string,
  shipping_batch_nos: [],
  customs_documents_status: string | null,
  customs_documents: [],
  weight_recon_records: [
    {
      length: number,
      width: number,
      height: number,
      weight: number,
      created_on: string,
      source: string
    }
  ],
  partner_submission_status: {
    total_count: number,
    success_count: number,
    error_count: number
  },
  order_exceptions: [],
  escalation_status: string | null,
  is_escalatable: boolean,
  public_tracker_status_v2: string,
  private_tracker_status_v2: string,
  order_type: string,
  special_instruction: string | null,
  service_category: string
}
export interface OrderData {
  count: number,
  results?: Array<OrderDetailData_from_b2c>
}

let removeFalseKeys = (obj:any) => {
  return Object.entries(obj).filter(([_, value]) => !!value).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
}

export const getOrderData = async ( token: string, params: { page: number, page_size: number, public_status_v2_shipper: string | null, tracking_no: string | null | number, shipper_order_id: string | null | number, order_status: string | null, pickup_country: string | null, consignee_country: string | null, created_on_start_date?: string | null, created_on_end_date?: string | null, janio_order_id?: string | null | number, report?: boolean, secret_key?: string, date_to?: string | null, date_from?: string | null, service_category?: string | null, claim_eligible_orders?: boolean }) => {

  let queryString = Object.keys(removeFalseKeys(params)).map(key => key + '=' + (params as any)[key]).join('&');

  let response = await apib2c.get<OrderData>(`order/order/?${queryString}`);

  return response.data
};

export const getOrderDataForReports = async ( token: string, params: { page: number, page_size: number, status: string, searchQuery: string | null | number, order_status: string | null, pickup_country: string | null, consignee_country: string | null, account_ids: string | null, created_on_start_date?: string | null, created_on_end_date?: string | null, janio_order_id?: string | null | number, report?: boolean, email?: string, secret_key?: string }) => {

  Object.keys(params).forEach(key => {
    if ((params as any)[key] === null || (params as any)[key] === '' || (params as any)[key] === undefined || (params as any)[key]?.length === 0) {
      delete (params as any)[key];
      return params
    }
  });
  let response = await api.post(`orders/orders/generate_report/`, 
    {
      ...params,
      // email: 'sasaas@gmail.com'
    },
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    });

  return response.data
};


export const getOrderDataForReportsb2c = async ( token: string, params: { page?: number, page_size?: number, status?: string, searchQuery?: string | null | number, public_status_v2_shipper?: string | null, pickup_country?: string | null, consignee_country?: string | null, account_ids?: string | null, date_from?: string | null, date_to?: string | null, tracking_nos?: string | null | number, report?: boolean, emails?: string, secret_key?: string, service_type?: string | null }) => {

  Object.keys(params).forEach(key => {
    if ((params as any)[key] === null || (params as any)[key] === '' || (params as any)[key] === undefined || (params as any)[key]?.length === 0) {
      delete (params as any)[key];
      return params
    }
  });
  let response = await apib2c.post(`/order/fetch-orders-in-csv/`, 
    {
      ...params,
      secret_key: token,
      // email: 'sasaas@gmail.com'
    });

  return response.data
};

export const getOrderDataFrom_SS = async ( token: string, params: { page: number, page_size: number, status: string, searchQuery: string | null | number, order_status: string | null, pickup_country: string | null, consignee_country: string | null, account_ids: string | null, created_on_start_date?: string | null, created_on_end_date?: string | null, janio_order_id?: string | null | number, report?: boolean }) => {

  let queryString = Object.keys(removeFalseKeys(params)).map(key => key + '=' + (params as any)[key]).join('&');

  let response = await api.get<OrderData>(`orders/orders/?${queryString}`,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    });

  return response.data
};


export const getAllBulkBatchStatus = async (token: string, params: { page: number, page_size: number, status?: string }) => {
  let queryString = Object.keys(removeFalseKeys(params)).map(key => key + '=' + (params as any)[key]).join('&');
  let response = await api.get(`/orders/batch_status/?${queryString}`,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    })
  return response.data
}

export const getPriceEstimateByBulkBatchStatus = async (token: string, params: { page: number, page_size: number, status?: string }) => {
  let queryString = Object.keys(removeFalseKeys(params)).map(key => key + '=' + (params as any)[key]).join('&');
  let response = await api.get(`/orders/bulk/price_estimate_by_batch_id/?batch_id=?${queryString}}`,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    })
  return response.data
}

export const getMarketplaceOrderData = async ( token: string, params: { offset: number, limit: number, searchQuery: string | null | number }) => {
  let queryString = Object.keys(removeFalseKeys(params)).map(key => key + '=' + (params as any)[key]).join('&');

  let response = await api.get<OrderData>(`channels/draft-orders/?${queryString}`,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    });

  return response.data;
};

export const orderCancel = async (token: string, orderId: number) => {
  let response = await api.post(`orders/orders/${orderId}/cancel/`,
    null,
    {
      headers: {
        'Authorization': `Token ${token}`
      }});

  return response.data
};

interface OrderTrackerResponse {
  tracking_no: string,
  updates: Array<{
    status: string
    updated_on: string
    description: string
    country: string
    address: string
  }>
}

export const getOrderTrackers = async (orderId: number, secretKey: string) => {
  let response = await apib2c.get<OrderTrackerResponse>(`/v2/tracker/update/${orderId}/?secret_key=${secretKey}`);
  return response.data;
};

export const getParentDetails = async (orderId: number, secretKey: string) => {
  let response = await apib2c.get(`/order/mps/?secret_key=${secretKey}&parent_id=${orderId}`);
  return response.data;
};

export const getChildDetails = async (orderId: number, secretKey: string) => {
  let response = await apib2c.get(`order/order/${orderId}/?secret_key=${secretKey}`);
  return response.data;
};

interface OrderSummary {
  created: number,
  draft: number,
  channel_draft: number,
  on_hold: number,
  cancelled: number,
  delivered: number
}
interface OrderSummaryFromB2c {
  cancelled: number
  delivered: number
  in_transit: number
  on_hold: number
  out_for_delivery: number
  pending_pickup: number
  channel_draft?: number
  ready_to_ship?: number,
  to_ship?: number
}
export const getOrderSummaryCount = async (token: string) => {
  let response = await api.get<OrderSummary>(`/orders/summary/`,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    });
  return response.data;
};

export const getOrderSummaryCount_from_b2c = async (secret_key: string) => {
  let response = await apib2c.get<OrderSummaryFromB2c>(`/order/summary_count_view/?secret_key=${secret_key}`);
  return response.data;
};

export const getPaymentDetailsForOrder = async (token: string, orderId: number) => {
  let response = await api.get(`/payments/${orderId}/details/`,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    });
  return response.data;
};

interface OrderLogResponse {
  order_activities: [{
    source_type: string,
    source_name: string,
    source_initials: string,
    activity_on:string,
    message_html: string
  }]
}

export const getOrderLogs = async (trackingNumber: string, secretKey: string) => {
  const response = await apib2c.get<OrderLogResponse>(`/activity/client-order-activity/?tracking_no=${trackingNumber}`,
    {
      headers: {
        'Authorization': `Bearer ${secretKey}`
      }
    });
  return response.data.order_activities;
};

export const getBulkOrderLabelPDF = async (orderIds: Array<number>,token: string) => {
  let payload = {
    order_ids: orderIds
  }
  let response = await api.post('/orders/bulk/generate_label_pdf/ ',
    {
      ...payload
    },
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }
  )
  return response.data;
}

export const downloadBulkOrderLabelPDF = async (trackingNos: Array<string>) => {
  let payload = {
    tracking_nos: trackingNos
  }
  let response = await apib2c.post('/label/generate-label-by-olu/',
    {
      ...payload
    },
    {
      responseType: 'arraybuffer',
    }
  )
  return response.data;
}

export const downloadBulkOrderPODs = async (trackingNos: Array<string>, secret_Key: string) => {
  let payload = {
    tracking_nos: trackingNos
  }
  let response = await apib2c.post(`/pod/download-pod-zip/?secret_key=${secret_Key}`,
    {
      ...payload
    }
  )
  return response.data;
}

export const getShipmentData = async  ( token: string, params: {
  ship_status?: string,
  page?: number, page_size?: number, searchQuery?: string | null | number, consignee_countries?: string | null }) => {

  let queryString = Object.keys(removeFalseKeys(params)).map(key => key + '=' + (params as any)[key]).join('&');

  let response = await api.get<OrderData>(`channels/draft-orders/?${queryString}`,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    });
  return response.data;
};

export const getIntegratedShipmentDataFromB2C = async  (secret_key: string, params: {
  status?: string,
  page?: number, page_size?: number, shipper_order_ids?: string | null | number, consignee_countries?: string | null }) => {

  let queryString = Object.keys(removeFalseKeys(params)).map(key => key + '=' + (params as any)[key]).join('&');

  let response = await apib2c.get<OrderData>(`channel/draft-orders/?secret_key=${secret_key}&${queryString}`);
  return response.data;
};

export const downloadReport = async ( token: string, 
  params: { 
    order_status: string | null,
    searchQuery: string | number | null | undefined, 
    pickup_country: string | null, 
    consignee_country: string | null, 
    created_on_start_date: string | null | undefined, 
    created_on_end_date: string | null | undefined,
    janio_order_id?: string | null | number
  }) => {

  let queryString = Object.keys(removeFalseKeys(params)).map(key => key + '=' + (params as any)[key]).join('&');

  let response = await api.get(`orders/orders/generate_report/?${queryString}&report=true`,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    });

  return response.data
};