import { Button, Col, Checkbox, Spin, Radio, message } from 'antd';
import {useState} from 'react';
import { useHistory } from "react-router-dom";
import { getCardData } from "../../services/privateApi";
import {useQuery, useMutation} from "react-query";
import { useLanguage } from '../../../languages/Language';
import { AddCardBtn, CardInterface } from '../../../Postpaid/CardDetails'
import {useAuth} from '../../../auth/services/authContext';
import { v4 as uuidv4 } from 'uuid';
import { makePayment} from "../../services/privateApi";
import {UserCreatedOrderSuccessfully, UserViewedOrderSuccessPage} from "../../../commons/mixpanel"

export const PayFromCards = ({orderData, orderIsStillValid, bulkOrderChargeIds, orderType,promoCode}: any) => {
  const auth = useAuth();
  let history = useHistory();
  let { getText } = useLanguage();
  let orderId = orderType === 'SINGLE' ? orderData.id : orderData.batch_no
  let orderCreationType = orderType === 'SINGLE' ? 'single' : 'bulk'

  const [checked, setChecked] = useState(false);
  const [selectedCard, setSelectedCard] = useState<CardInterface | null>(null);

  // API to get Save Cards details
  const getCardDetails = useQuery('getCardDetailsOnOrder', async () => {
    return getCardData(auth.user.authToken)
  });

  // Pay For Order API Handler
  const orderCharge:any = useMutation(async (token:string) => {
    let card = selectedCard;
    if(!card){
      card = getCardDetails.data!.payment_methods[0]
    }

    let params = {
      charge_ids: orderType === 'SINGLE' ? orderData.charge_ids : bulkOrderChargeIds,
      transaction_id: uuidv4(),
      payment_method_id: card?.id,
      payment_type: 'POSTPAID',
      discount_code: promoCode
    }
    return makePayment(token, params);
  },  {
    onSuccess: (val) => {
      if(orderType === 'SINGLE'){
        UserCreatedOrderSuccessfully('single')
      }
      else{
        UserCreatedOrderSuccessfully('bulk')
      }
      UserViewedOrderSuccessPage()
      history.push('/orders/success')
    },
    onError: (error: { response: { data: { error: string } } }) => {
      let errorResponse: string = error.response.data.error
      message.error(<span><strong>{errorResponse}</strong>. Failed to create shipment.</span>);
    },
  });

  // Pay For Order Button Handler
  const handleOrderCharge = () => {
    orderCharge.mutate(auth.user.authToken);
  }

  // Will set Card payment ID on change
  const onChange = (e: any) => {
    setSelectedCard(e.target.value)
  };

  // Will accept the agreement + will set the cardID if it's empty
  const checkboxOnchangeHandler = (e: any) => {
    setChecked(e.target.checked)
  }

  if(getCardDetails.isLoading) {
    return <Spin />
  }

  // Will Hide paynow button if order contains from past pickup date
  if(!orderIsStillValid) {
    return (
      <Col span={24} style={{'margin': '20px 0px 20px -7px'}}>
        <Col style={{color:"#ff4d4f"}}>{getText('You are updating the order from the past. Please select pickup date again.')}</Col>
      </Col>
    )
  }

  if(getCardDetails.data && getCardDetails.data!.payment_methods.length > 0) {
    let defaultValue = getCardDetails.data.payment_methods[0]
    return(
      <>
        <p style={{marginTop: '30px'}}>
          <strong>{getCardDetails.data.payment_methods.length} Active Card</strong>
        </p>
        <Radio.Group
          size="large"
          id={'select-card'}
          onChange={onChange}
          defaultValue={defaultValue}
        >
          {
            getCardDetails.data.payment_methods.map((card: CardInterface) => {
              return (
                <Radio.Button value={card}>
                  {card.name}
                </Radio.Button>
              )
            })
          }
        </Radio.Group>

        <Checkbox checked={checked} onChange={(e) => checkboxOnchangeHandler(e)} style={{marginTop: '40px'}}>
          {getText('I agree to pay')} <b>{getText('additional courier charges')}</b> {getText('in case shipment details (weight, dimensions, item category, pickup and destination address) are found to be inaccurate.')}
        </Checkbox>
        {!checked ? <Col style={{color:"#ff4d4f"}}>{getText('Please accept agreement to proceed.')}</Col>: ''}
        <div style={{marginTop: '20px'}}>
          <Button type="primary"
            className="blue-button"
            onClick={handleOrderCharge}
            loading={orderCharge.isLoading}
            disabled={!(checked && orderIsStillValid)}>
            {getText('Create Shipment')}
          </Button>
        </div>
      </>
    )
  }
  return (
    <AddCardBtn success={`/orders/pay/${orderCreationType}/${orderId}?result=card-added`} cancel={`/orders/pay/${orderCreationType}/${orderId}?result=card-not-added`} />
  )
}
