import {
  Form,
  Button,
  Col,
  Row,
  Select,
} from 'antd';

import {AddressProps, addLocation_in_b2c, getStates_from_b2c, getCities_from_b2c, addReturnLocation_in_b2c} from "../orders/services/privateApi";
import {countryCodes} from "../commons/utils/constants";
import {sortResponseAlphabatically} from "../commons/utils/utilizer" 
import {useAuth} from "../auth/services/authContext";
import * as Yup from 'yup';
import {Formik, Form as FormikForm, FormikHelpers} from 'formik';
import {FormInput, FormSelect} from "../orders/components/Forms";
import {useState} from 'react';
import {useMutation, useQuery} from 'react-query';
import {validatePostalCode} from "../commons/utils/validator";
import { useLanguage } from '../languages/Language';
import { LabelRadio } from '../ReUsableComponents/ReUsable'

const {Option} = Select;
const validationSchema = (getText: any) => {
  return Yup.object().shape({
    pickup_point_address: Yup.string().required(getText('Address is required')),
    pickup_point_city: Yup.string().required(getText('Please enter City')).max(50, 'Maximum 50 characters are allowed'),
    pickup_point_email: Yup.string().required(getText('Email is required')).email(getText("Please enter a valid email")).max(50, 'Maximum 50 characters are allowed'),
    pickup_point_number: Yup.string().required(getText('Phone Number is required')).max(50, 'Maximum 50 characters are allowed'),
    pickup_point_contact_person: Yup.string().required(getText('Full Name is required')).max(50, 'Maximum 50 characters are allowed'),
    pickup_point_country: Yup.string().required(getText('Please select Country')),
    // type: Yup.string().required(getText('Please select pickup location type')),
    pickup_point_name: Yup.string().required(getText('Address Label is required')).max(50, 'Maximum 50 characters are allowed'),
    pickup_point_state: Yup.string().required(getText('Please select State')),
    pickup_point_postal: Yup.string().required(getText('Please enter Postal Code')).test(
      'postal-check',
      (val: any, context: any) => {
        if(!val){
          return context.createError({
            path: context.path,
            message: getText('Please enter Postal code')
          })
        }
        else{
          const error = validatePostalCode(countryCodes[context.parent.pickup_point_country],val.toString());
          if(error){
            return context.createError({
              path: context.path,
              message: error
            })
          }
        }
        return true
      }
    ),
    // pickup_point_province: Yup.string().required(getText('Please select State')),
    // pickup_point_notes: Yup.string().required(getText('Please select State')),
  })};


type ServerErrors = {[key: string]: Array<string>};
const combineFieldsErrors = (errors: ServerErrors) => {
  let combined_errors: any = {}
  let initial_touched: any = {}

  for(const [key, val] of Object.entries(errors)){
    combined_errors[key] = val.join(',')
    initial_touched[key] = true
  }

  return {
    combined_errors,
    initial_touched
  }
}

export interface AddressError {
  pickup_point_name?: '',
  pickup_point_address?: '',
  pickup_point_country?: '',
  pickup_point_state?: '',
  pickup_point_city?: '',
  // pickup_point_province?: '',
  pickup_point_postal?: '',
  pickup_point_contact_person?: '',
  pickup_point_number?: '',
  // pickup_point_notes?: '',
  pickup_point_email?: ''
}


interface PropsType {
  country?: string
  formId?: string
  onSuccess?: (id:string) => any
  onCancel: () => any
  addressType: string
  onSuccessForReturns?: (location:any) => any
}

export const PickUpAddressForm = (props:PropsType) =>{
  const auth = useAuth();
  const [country, setCountry] = useState<string>(props.country ? props.country : '');
  const [state, setState] = useState<string>('');
  const [freeText, setfreeText] = useState<string>('dropdown');
  const resetFieldValuesOnCountryChange = ['pickup_point_state', 'pickup_point_city'];
  const { getText } = useLanguage();
  const statesListFromb2c = useQuery(['statesListFromb2c', country], () => {
    if(country){
      return getStates_from_b2c(country)
    }
  });
  const cityListFromb2c = useQuery(['cityListFromb2c', state], () => {
    if(state){
      return getCities_from_b2c(country, state)
    }
  });
  
  const formInitialValue: (AddressProps) = {
    pickup_point_name: '',
    pickup_point_address: '',
    pickup_point_country: props.country ? props.country! : '',
    pickup_point_state: '',
    pickup_point_city: '',
    pickup_point_postal: '',
    pickup_point_contact_person: '',
    pickup_point_number: '',
    pickup_point_email: ''
  };

  const [initialTouched, setInitialTouched] = useState({});
  const createLocation = useMutation(async (value: AddressProps) => await props.addressType === 'return' ? addReturnLocation_in_b2c(value, auth.user.secret_key) : addLocation_in_b2c(value, auth.user.secret_key))

  const onFormSubmit = async (value: AddressProps, {setErrors}: FormikHelpers<AddressProps>) => {
    try{
      let location:any = await createLocation.mutateAsync(value)
      if(props.onSuccess){
        props.onSuccess(location.pickup_point_id)
      }
      if(props.onSuccessForReturns){
        props.onSuccessForReturns(location)
      }
    }
    catch(e: any){
      const {combined_errors, initial_touched} = combineFieldsErrors(e.response.data)
      setErrors(combined_errors)
      setInitialTouched(initial_touched)
    }
  };

  const setFreeTextCallback = (val:string) => {
    setfreeText(val);
  };

  return (
    <Formik
      initialValues={formInitialValue}
      initialTouched={initialTouched}
      validationSchema={validationSchema(getText)}
      enableReinitialize={true}
      onSubmit={onFormSubmit}
      validateOnChange={false}
    >
      { formik => (
        <div className={"ant-form-vertical"}>
          <FormikForm>
            <Row gutter={14}>
              <Col span={12}>
                <FormInput label={`${props.addressType.charAt(0).toUpperCase() + props.addressType.slice(1)} ${getText('Address Label')}`} name="pickup_point_name" wrapperClass={"no-margin-item"} />
                
                <Form.Item label={getText('Name will be displayed under address selection')} className={'form-item-info'}>
                </Form.Item>
              </Col>

              {/* <Col span={12}>
                <FormSelect label={getText('Type')} name="type" disabled={true}>
                  <Option key={'FORWARD'} value={'FORWARD'}>{'FORWARD'}</Option>
                  <Option key={'RETURN'} value={'RETURN'}>{'RETURN'}</Option>
                </FormSelect>
              </Col> */}
            </Row>
            <Row gutter={14}>
              <Col span={12}>
                <FormInput label={getText('Full Name')} name="pickup_point_contact_person" />
              </Col>
              <Col span={12}>
                <FormInput label={getText('Phone Number')} name="pickup_point_number" />
              </Col>
            </Row>
            <FormInput label={getText('Email')} name="pickup_point_email" />
            <FormInput label={getText('Address')} name="pickup_point_address" />
            <Row gutter={14}>
              <Col span={12}>
                <FormSelect label={getText('Country')} name="pickup_point_country" onChangeCallback={(country_code:string) => {setCountry(country_code); resetFieldValuesOnCountryChange.forEach(field => formik.setFieldValue(field, ''))}}>
                  {
                    Object.entries(countryCodes)
                      .filter(([key]) => props.country! ? key === props.country! : true)
                      .map(([key]) => (
                        <Option key={key} value={key}>{key}</Option>
                      ))
                  }
                </FormSelect>
              </Col>
              <Col span={12}>
                <FormSelect label={getText('State')} name="pickup_point_state" loading={statesListFromb2c.isLoading} onChangeCallback={(state:string) => {
                  setState(state);
                  formik.setFieldValue('pickup_point_city', '');
                }}>
                  {
                    (sortResponseAlphabatically(statesListFromb2c?.data, 'state_name') || [])
                      .filter((item: {state_id:number, state_name: string}) => (
                        country === 'TH'?
                          (item.state_name === 'Nonthaburi' || item.state_name === 'Bangkok' || item.state_name === 'Samut Prakan')
                          : (item.state_id > 0)
                      ))
                      .map((item: {state_id:number, state_name: string} ) => (
                        <Option key={item.state_id} value={item.state_name}>{item.state_name}</Option>
                      ))
                  }
                </FormSelect>
                {statesListFromb2c.isError && <p style={{color:'red', marginBottom:0}}>{getText("Failed to fetch states, try later")}</p>}
              </Col>
            </Row>
            {/* <Row>
              <Col span={24}>
                <FormInput label={'Province'} name="pickup_point_province" />
              </Col>
            </Row> */}
            <Row gutter={14}>
              <Col span={12}>
                {
                  formik.values.pickup_point_country === 'US' ?
                    freeText === 'dropdown' ? 
                      <>
                        <FormSelect label={<LabelRadio setFreeTextCallback={setFreeTextCallback} freeText={freeText} />}
                          notFoundContent={<p style={{marginBottom: '0px', color: '#525050'}}>City not found. Please select Other</p>} name="pickup_point_city" loading={cityListFromb2c.isLoading}
                          placeholder={'Select a city'}>
                          {
                            (sortResponseAlphabatically(cityListFromb2c?.data, 'city_name') || [])
                              .map((item: {city_id:number, city_name: string, display_name: string} ) => (
                                <Option key={item.city_id} value={item.city_name}>{item.display_name}</Option>
                              ))
                          }
                        </FormSelect>
                        {cityListFromb2c.isError && <p style={{color:'red', marginBottom:0}}>Failed to fetch cities, try later</p>}
                      </>
                      : 
                      <FormInput placeholder='Please type in the city' label={<LabelRadio setFreeTextCallback={setFreeTextCallback} freeText={freeText} />} name="pickup_point_city" /> : 
                    <FormSelect label={getText('City')} name="pickup_point_city" loading={cityListFromb2c.isLoading}>
                      {
                        (sortResponseAlphabatically(cityListFromb2c?.data, 'city_name') || [])
                          .map((item: {city_id:number, city_name: string, display_name: string} ) => (
                            <Option key={item.city_id} value={item.city_name}>{item.display_name}</Option>
                          ))
                      }
                    </FormSelect>
                }
              </Col>
              <Col span={6} style={{marginTop: formik.values.pickup_point_country === 'US' ? '35px' : '0px'}}>
                <FormInput label={getText('Postal code')} name="pickup_point_postal" />
              </Col>
            </Row>
            <Row gutter={14}>
              <Col>
                <Button onClick={props.onCancel}>Cancel</Button>
                <Button type="primary" htmlType="submit" className={"blue-button"} loading={createLocation.isLoading} style={{marginLeft: '10px'}}>
                  {getText('Add address')}
                </Button>
              </Col>
            </Row>
          </FormikForm>
        </div>
      )}
    </Formik>
  );
};
