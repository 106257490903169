import { DamagedParcelForm } from '../Forms/MultiClaimForms/DamagedParcelForm'
import { MissingParcelForm } from '../Forms/MultiClaimForms/MissingParcelForm'

interface ClaimformProp{
  claimType: string
  selectedOrders: any
}
export const MultiClaimsForm = (props: ClaimformProp) => {
  
  if(props.claimType === 'missing_orders'){
    return ( <MissingParcelForm {...props}/>)
  }
  else{
    return ( <DamagedParcelForm {...props}/>)
  }

}

export const MultiClaimFormTitle = () => {
  return (
    <div>
      <p className={'claim-step-title'}>{'Add supporting information'}</p>
    </div>
  )
}