import { Form, Row, Col, Select, Button } from 'antd'
import React, {useState} from "react";
import '../../styles/ReturnOrderForm.css'
import { FormInput, FormSelect, TitleErrorMarker } from '../../../orders/components/Forms';
import { Formik, Form as FormikForm } from 'formik'
import { parseErrors } from '../../../../src/orders/helpers';
import * as Yup from 'yup';
import { useSingleOrderB2C, OrderErrorB2C } from "../../services/returnSingleOrderContext";
import { countryCodes, countryCallingCodes } from '../../../commons/utils/constants'
import { LabelRadio } from '../../../ReUsableComponents/ReUsable'
import { useQuery } from 'react-query'
import { getCities_from_b2c, getStates_from_b2c } from '../../../orders/services/privateApi'
import { validatePostalCode } from '../../../commons/utils/validator'
import {
  getFormatedDate,
  parseDate,
  sortResponseAlphabatically
} from "../../../commons/utils/utilizer";
import moment from 'moment';
interface PropsType{
  current: number,
  setCurrentState: (val: number) => void,
  dataFilled?: number
}

const parseOrderContextError = (error: OrderErrorB2C) => {
  return {
    pickup_contact_name: error.pickup_contact_name,
    pickup_contact_number: error.pickup_contact_number,
    pickup_contact_email: error.pickup_contact_email,
    pickup_address: error.pickup_address,
    pickup_country: error.pickup_country,
    pickup_state: error.pickup_state,
    pickup_city: error.pickup_city,
    pickup_postal: error.pickup_postal,
  }
}

const {Option} = Select;
export const PickupDetailsForm = (props: PropsType) => {
  const {current, setCurrentState} = props;
  const singleOrderContext = useSingleOrderB2C();
  const [state, setState] = useState<string>(singleOrderContext.singleOrder.pickup_state! ? singleOrderContext.singleOrder.pickup_state! : '');
  const [freeText, setfreeText] = useState<string>('dropdown');

  const statesListFromb2c = useQuery(['statesListFromb2c', singleOrderContext.singleOrder.pickup_country], () => {
    if(singleOrderContext.singleOrder.pickup_country!){
      return getStates_from_b2c(singleOrderContext.singleOrder.pickup_country!)
    }
  });

  const cityListFromb2c = useQuery(['cityListFromb2c', state], () => {
    if(state){
      return getCities_from_b2c(singleOrderContext.singleOrder.pickup_country!, state)
    }
  });

  const initialValues={
    pickup_contact_name: singleOrderContext.singleOrder.pickup_contact_name,
    pickup_contact_number: singleOrderContext.singleOrder.pickup_contact_number,
    pickup_contact_email: singleOrderContext.singleOrder.pickup_contact_email ? singleOrderContext.singleOrder.pickup_contact_email : '',
    pickup_address: singleOrderContext.singleOrder.pickup_address,
    pickup_country: singleOrderContext.singleOrder.pickup_country!,
    pickup_state: singleOrderContext.singleOrder.pickup_country.toLowerCase() === 'singapore'? 
      singleOrderContext.singleOrder.pickup_country :
      singleOrderContext.singleOrder.pickup_state,
    pickup_city: singleOrderContext.singleOrder.pickup_country.toLowerCase() === 'singapore'? 
      singleOrderContext.singleOrder.pickup_country :
      singleOrderContext.singleOrder.pickup_city,
    pickup_postal: singleOrderContext.singleOrder.pickup_postal,
    pickup_date: parseDate(singleOrderContext.singleOrder.pickup_date),
  }

  const {initialErrors, initialTouched, hasError, emptyError} = parseErrors(singleOrderContext.errors, parseOrderContextError)

  const validationSchema = () => {
    return Yup.object().shape({
      pickup_contact_name: Yup.string().required('Name is required'),
      pickup_contact_number: Yup.number().required('Phone number is required'),
      pickup_contact_email: Yup.string().email('Please enter in abc@email.com format').required('Email is required'),
      pickup_address: Yup.string().required('Address is required'),
      pickup_country: Yup.string().required('Country is required'),
      pickup_state: Yup.string().required('state is required'),
      pickup_city: Yup.string().required('city is required'),
      pickup_postal: Yup.string().required('Postal code is required').test(
        'postal-check',
        (val: any, context: any) => {
          if(!val){
            return context.createError({
              path: context.path,
              message: 'Please enter Postal Code'
            })
          }
          else{
            const error = validatePostalCode(countryCodes[context.parent.pickup_country],val.toString());
            if(error){
              return context.createError({
                path: context.path,
                message: error
              })
            }
          }
          return true
        }
      ),
      pickup_date: Yup.date().nullable().when(['service_type', 'pickup_country'], {
        is: (service_type: any, pickup_country: any) => {
          let serviceType = singleOrderContext.singleOrder.service_type
          // let singleOrderData = singleOrderContext.singleOrder
          return serviceType === 'Pickup'
          //  && singleOrderData.pickup_country === 'SG'
        },
        then: Yup.date().nullable()
        // .required('Please select Pickup Date')
          .test(
            'valid-date',
            (val: any, context: any) => {
              if (val) {
                let formattedDate = parseDate(val);
                if ((!moment().isBefore(moment({ hour: 8, minute: 0 })) && formattedDate!.isBefore(moment().endOf('day')))) {
                  return context.createError({
                    path: context.path,
                    message: 'Please select valid date'
                  })
                }
              }
              return true
            }
          )
      })
    })
  }
    
  const onSubmit = (values: any) => {
    let pickup_date = getFormatedDate(values.pickup_date)
    singleOrderContext.updateSingleOrderData({
      pickup_contact_name: values.pickup_contact_name,
      pickup_contact_number: values.pickup_contact_number.startsWith('+') ? values.pickup_contact_number : `${countryCallingCodes[countryCodes[singleOrderContext.singleOrder.pickup_country!]]} ${values.pickup_contact_number}`,
      pickup_contact_email: values.pickup_contact_email,
      pickup_address: values.pickup_address,
      pickup_country: values.pickup_country,
      pickup_state: values.pickup_state,
      pickup_city: values.pickup_city,
      pickup_date: pickup_date,
      pickup_location: undefined,
      pickup_postal: values.pickup_postal
    })
    setCurrentState(2)

    singleOrderContext.setErrors({ ...emptyError })
  }

  // const onPickupDateChange = (val:any) => {
  //   if(props.setPickupDateExpiredCallback){
  //     props.setPickupDateExpiredCallback(false)
  //   }
  // }

  const customerAddress = <>
    <p style={{marginBottom: '0px'}}>{singleOrderContext.singleOrder.pickup_contact_name}, {singleOrderContext.singleOrder.pickup_contact_number}</p>
    <p>{singleOrderContext.singleOrder.pickup_address}, {singleOrderContext.singleOrder.pickup_postal}, {singleOrderContext.singleOrder.pickup_city}, {singleOrderContext.singleOrder.pickup_state}</p>
  </>;

  const setFreeTextCallback = (val:string) => {
    setfreeText(val);
  };

  if(current > 1){
    return (
      <div className='return-order-form-container'>               
        <Row>
          <Col span={24}>
            <div style={{fontWeight: 'bold'}}><TitleErrorMarker text={'Customer Overview'} hasError={hasError} /></div>
          </Col>                     
          <Col span={24} className={'mb-10'}>
            <p style={{color: '#808080', marginBottom: '0px'}}>Customer Details</p>
            <p>{customerAddress}</p>
          </Col>                    
          <Col span={24}>
            <p style={{color: '#808080', marginBottom: '0px'}}>Pickup Date</p>
            <p>{singleOrderContext.singleOrder.pickup_date ? singleOrderContext.singleOrder.pickup_date : 'N/A'}</p>
          </Col>
        </Row>                
      </div>
    )
  }

  return (
    <div className='return-order-form-container ant-form-vertical'>
      <Formik
        onSubmit={onSubmit}
        initialErrors={initialErrors}
        initialTouched={initialTouched}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
      >
        {formik => (
          <FormikForm>
            <Form.Item label={<TitleErrorMarker text={'Customer Details'} hasError={hasError} />} style={{marginBottom: '-25px'}}/>
            <Row gutter={20}>
              <Col span={12}>
                <FormInput 
                  required
                  name="pickup_contact_name" 
                  label={'Full Name'}
                  placeholder='Name'
                />
              </Col>
              <Col span={12}>
                <FormInput 
                  required
                  name="pickup_contact_number"
                  prefix={
                    singleOrderContext.singleOrder.pickup_contact_number!.startsWith('+') ? null : countryCallingCodes[singleOrderContext.singleOrder.pickup_country!]
                  }
                  label={'Phone Number'}
                  placeholder='12345678'
                />
              </Col>
            </Row>
            <Row gutter={20} >
              <Col span={24}>
                <FormInput 
                  required
                  name="pickup_contact_email" 
                  label={'Email'}
                  placeholder='Please enter a valid email (Shipping label and instructions will be sent to this email)'
                />
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={24}>
                <FormInput 
                  required
                  name="pickup_address" 
                  label={'Address'}
                  placeholder='Address'
                />
              </Col>
            </Row> 
            <Row gutter={20} >
              <Col span={12}>
                <FormSelect name="pickup_country" required label={'Country'} wrapperClass="no-margin-item" placeholder={'Select a country'} disabled={true}>
                  <Option value={formik.values.pickup_country}>{formik.values.pickup_country}</Option>
                </FormSelect>
              </Col>
              <Col span={12}>
                <FormSelect required label={'State'} name="pickup_state" loading={statesListFromb2c.isLoading} onChangeCallback={(state:string) => {
                  setState(state);
                  formik.setFieldValue('pickup_city', '');
                }}>
                  {
                    (sortResponseAlphabatically(statesListFromb2c?.data, 'state_name') || []).map((item: {id: number, state_name:string} ) => {
                      return  <Option key={item.state_name} value={item.state_name}>{item.state_name}</Option>
                    })
                  }
                </FormSelect>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={12}>
                {
                  formik.values.pickup_country === 'US' ?
                    freeText === 'dropdown' ? 
                      <>
                        <FormSelect label={<LabelRadio setFreeTextCallback={setFreeTextCallback} freeText={freeText} />}
                          notFoundContent={<p style={{marginBottom: '0px', color: '#525050'}}>City not found. Please select Other</p>} name="pickup_city" loading={cityListFromb2c.isLoading}
                          placeholder={'Select a city'}>
                          {
                            (sortResponseAlphabatically(cityListFromb2c?.data, 'city_name') || [])
                              .map((item: {id:number, city_name: string} ) => (
                                <Option key={item.city_name} value={item.city_name}>{item.city_name}</Option>
                              ))
                          }
                        </FormSelect>
                        {cityListFromb2c.isError && <p style={{color:'red', marginBottom:0}}>Failed to fetch cities, try later</p>}
                      </>
                      : 
                      <FormInput placeholder='Please type in the city' label={<LabelRadio setFreeTextCallback={setFreeTextCallback} freeText={freeText} />} name="pickup_city" /> : 
                    <FormSelect label={'City'} name="pickup_city" loading={cityListFromb2c.isLoading}>
                      {
                        (sortResponseAlphabatically(cityListFromb2c?.data, 'city_name') || [])
                          .map((item: {id:number, city_name: string} ) => (
                            <Option key={item.city_name} value={item.city_name}>{item.city_name}</Option>
                          ))
                      }
                    </FormSelect>
                }
              </Col>
              <Col span={12} style={{marginTop: singleOrderContext.singleOrder.pickup_country === 'US' ? '35px' : '0px'}}>
                <FormInput 
                  required
                  name="pickup_postal" 
                  label={'Postal Code'}
                  placeholder='Please enter a valid postal code'
                />
              </Col>
            </Row>

            {/* <Divider />

            <>
              <Form.Item label={<strong>Pickup Information</strong>} style={{marginBottom: '-25px'}} />
              <Col span={12}>
                <DatePicker 
                  disabledDate={disabledDateForReturns} 
                  // required={true}
                  showToday={false} 
                  name="pickup_date" 
                  label={'Pickup Date'} 
                  wrapperClass="no-margin-item"
                  // onChange={onPickupDateChange} 
                  // disabled={canChoosePickupDate(formik.values.pickup_country_code)}
                />
              </Col>
            </> */}
            
            <Row justify='end'>
              <Button htmlType='submit' type='primary'>{'Save & Continue'}</Button>
            </Row>
          </FormikForm>
        )}    
      </Formik>
    </div>
  )

}

export const PickupDetailsFormTitle = (props: PropsType) => {
  const {current, setCurrentState} = props
  return (
    <div className={"title-block"}>
      <p className={'step-title'}>Who are we picking from?</p>
      {current>1 ? <p onClick={()=> setCurrentState(1)} className={"edit-text"}>{'Edit details'}</p>:''}
    </div>
  )
}