import {Switch,Route,Link,useRouteMatch} from "react-router-dom"
import janio_white_logo from "../assets/images/janio_white_logo.png";
import {
  Layout,
  Menu,
  Image
} from "antd";
import './styles/ApiDocsRoute.css';
import {CreateOrder} from '../ApiDocs/CreateOrder';

export const ApiDocsLayout = () => {
  let { path } = useRouteMatch();
  return (
    <Layout>
      <Layout.Header className={'apidocs-custom-header'}>
        <Image preview={false} src={janio_white_logo} className={"janio-logo"}/>
      </Layout.Header>
      <Layout className={"antlayout-custom"}>
        <Layout.Sider className={'apidocs-custom-sider'}>
          <Menu defaultSelectedKeys={['1']} mode="inline" selectedKeys={["/apidocs/create"]}>
            <Menu.Item key={"/apidocs/create"}>
              <Link to={`${path}/create`}>{"Create Orders"}</Link>
            </Menu.Item>
          </Menu>
        </Layout.Sider>
        <Layout.Content className={'apidocs-custom-content'}>
          <Switch>
            <Route path={`${path}/create`}>
              <CreateOrder/>
            </Route>
          </Switch>
        </Layout.Content>
      </Layout>
    </Layout>
  )
};

