import {Col,Table, Button, Tooltip
  // , Modal, message
} from 'antd';
// import {useState} from "react";
// import {useMutation} from "react-query";
// import {deleteConsigneeLocation} from "../../orders/services/privateApi";
// import {useAuth} from "../../auth/services/authContext";
// import {ExclamationCircleOutlined} from "@ant-design/icons/lib";
import { useLanguage } from '../../languages/Language';
// import {ViewAddressModal} from './ViewAddressModal';
import { Link } from 'react-router-dom';

// const {confirm} = Modal;

interface Props {
  consigneeLocations: any
}
const ConsigneeAddressesList = (props:Props) => {
  // const auth = useAuth();
  let { getText } = useLanguage();
  // const [showModal, setShowModal] = useState<boolean>(false);
  // const [addressData, setAddressData] = useState<any>();
  // Replace with consignee address delete api
  // const deleteAddress = useMutation(async (locationId: number) => {return  await deleteConsigneeLocation(locationId, auth.user.authToken)},
  //   {
  //     onSuccess: (val) => {
  //       message.success(getText('Deleted the address successfully'));
  //       props.consigneeLocations.refetch();
  //     },
  //     onError: (val) => {
  //       message.error(getText('Failed to delete the address, please try later'));
  //     },
  //   });

  // const onViewClick = (data:any) => {
  //   setShowModal(true)
  //   setAddressData(data)
  // }

  // const setShowModalCallback = (val: boolean) => {
  //   setShowModal(val)
  // }

  // const showConfirmDeletion = (addressId:number) =>{
  //   return (
  //     confirm({
  //       className: 'custom-confirm-modal',
  //       icon: <ExclamationCircleOutlined />,
  //       content: <p>{getText("Click")} <strong>{getText("OK")}</strong> {getText("to delete the address")}</p>,
  //       onOk() {
  //         deleteAddress.mutate(addressId);
  //       },
  //       onCancel() {},
  //     })
  //   )
  // };

  const columns = [
    {
      title: 'Customer name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: getText('Country'),
      dataIndex: 'country',
      key: 'country'
    },
    {
      title: getText('Address'),
      dataIndex: 'address',
      key: 'address',
      ellipsis: {
        showTitle: false,
      },
      render: (text:any, data:any) => (
        <Tooltip placement="topLeft" title={data.address}>
          {data.address}
        </Tooltip>
      ),
    },
    // {
    //   title: getText('Actions'),
    //   dataIndex: '',
    //   key: '',
    //   render: (text:any, data:any) => (
    //     <Space size="middle">
    //       <Button className={'no-border-btn'} style={{color: '#050593'}} onClick={()=> {onViewClick(data)}}>View</Button>
    //       {/* <Button className={'no-border-btn'} style={{color: 'red'}} onClick={()=> {showConfirmDeletion(data.id)}}>Delete</Button> */}
    //     </Space>
    //   ),
    // },
    {
      title: getText('Actions'),
      dataIndex: '',
      key: '',
      render: (text:any, data:any) => (
        <Button type="link" size={"middle"} htmlType="submit" style={{marginLeft: '8px'}}>
          <Link to={{ pathname: `edit/address/${data.id}`, state: { addressType: 'consignee' } }} style={{color: '#050593', fontSize: '15px'}}>Edit</Link>
        </Button>
      ),
    }
  ];

  return (
    <>
      <Col className={''} span={24}  style={{marginTop: '-15px'}}>
        <Table
          rowKey="id"
          dataSource={props.consigneeLocations.data}
          columns={columns}
          loading={props.consigneeLocations.isLoading}
          pagination={false}
        />
      </Col>
      {/* {showModal && <ViewAddressModal setShowModalCallback={setShowModalCallback} address={addressData}/>} */}
    </>
  )
};

export default ConsigneeAddressesList
