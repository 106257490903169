import { Button, Modal, Space, Spin, message } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { getAttachment, getAttachments } from '../services';
import Attachment from '../interfaces/Attachment';
import Invoice from '../interfaces/Invoice';
import { UserClickedAttachmentButton, UserClickedInvoiceDocumentButton } from '../../commons/mixpanel';

interface Props {
  tab: string;
  invoice: Invoice;
  visible: boolean;
  setVisible(val: boolean): void;
};

const Spinner = () => (
  <div style={{ textAlign: 'center', }}>
    <Spin />
  </div>
)

const DownloadModal = (props: Props) => {
  const { tab, invoice, visible, setVisible } = props;

  const getAttachmentsQuery = useQuery(['getAttachmentsQuery', invoice.invoiceID], () => getAttachments(invoice.invoiceID), {
    enabled: !!invoice.invoiceID && invoice.hasAttachments,
    retry: 1,
    onError: (error: any) => {
      console.error(error);
      message.error('Failed to fetch the attachments');
    },
    onSettled: () => {
      UserClickedInvoiceDocumentButton(tab);
    },
  });

  const downloadAttachment = useMutation(async ({ endpoint, invoice, attachment }: { endpoint: string, invoice: Invoice, attachment: Attachment }) => getAttachment(endpoint, invoice, attachment), {
    retry: 1,
    onError: (error: any) => {
      console.error(error);
      message.error('Failed to download the attachment');
    },
    onSettled: () => {
      UserClickedAttachmentButton(tab);
    },
  });

  const handleFilename = (invoice: Invoice) => {
    return invoice.type === 'INVOICE' ? `Invoice-#${invoice.invoiceNumber}.pdf` : `Creditnote-#${invoice.invoiceNumber}.pdf`;
  };

  const handleDownload = (endpoint: string, attachment: Attachment) => {
    downloadAttachment.mutate({ endpoint, invoice, attachment });
  };

  const onCancel = () => {
    setVisible(false);
  };

  return (
    <Modal title="Invoice & Document" visible={visible} onCancel={onCancel} destroyOnClose footer={null}>
      {getAttachmentsQuery.isLoading && <Spinner />}
      {
        !getAttachmentsQuery.isLoading &&
        <Spin spinning={downloadAttachment.isLoading}>
          <Space direction="vertical" size="small">
            {
              !invoice.isOverpayment &&
              <Button key={invoice.invoiceID} type="link" style={{ paddingLeft: '0px' }} onClick={() => handleDownload('download', { attachmentID: 'static', fileName: handleFilename(invoice), mimeType: 'application/pdf' })}>{handleFilename(invoice)}</Button>
            }
            {
              getAttachmentsQuery.data &&
              getAttachmentsQuery.data.length &&
              getAttachmentsQuery.data.map((attachment) => <Button key={attachment.attachmentID} type="link" style={{ paddingLeft: '0px' }} onClick={() => handleDownload('attachment', attachment)}>{attachment.fileName}</Button>)
            }
          </Space>
        </Spin>
      }
    </Modal>
  );
};

export default DownloadModal;