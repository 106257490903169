export const data:any = {
  'create-api-order': {
    "consignee_name":"Janio",
    "consignee_address":"Pos 5 security komp perumahan PT INALUM tanjung gading., Jln berangin.",
    "consignee_country_code":"ID",
    "consignee_state":"Aceh",
    "consignee_city":"Aceh",
    "consignee_number":"+6223253456",
    "consignee_postal":"23111",
    "consignee_email":"janiotest@gamil.com",
    "pickup_contact_name":"Janio orders",
    "pickup_contact_number":"91234567",
    "pickup_address":"Jurong West Ave 1",
    "pickup_state":"Central Singapore",
    "pickup_city":"Singapore",
    "pickup_province":"",
    "pickup_postal":"640534",
    "pickup_country_code":"SG",
    "pickup_date":"2022-03-30",
    "order_length":10,
    "order_width":10,
    "order_height":10,
    "order_weight":10,
    "payment_type":"prepaid",
    "service_type":"Pickup",
    "consignee_id":"",
    "consignee_id_type":"",
    "items":[
      {
        "item_desc":"Item description",
        "item_category":"Accessory (no-battery)",
        "item_quantity":1,
        "item_price_currency":"SGD",
        "item_price_value":10,
        "product_id":"",
        "item_sku":""
      }
    ]
  },
  'create-api-order-header': {
    "client-api-key": "string",
    "Content-Type": "application/json"
  },
  'create-api-order-response':{
    "order": {
      "id": 6844,
      "tracking_no": "18311922032904",
      "consignee_name": "Janio",
      "consignee_address": "Pos 5 security komp perumahan PT INALUM tanjung gading., Jln berangin.",
      "consignee_postal": "23111",
      "consignee_country": "Indonesia",
      "consignee_state": "Aceh",
      "consignee_city": "Aceh",
      "consignee_province": null,
      "consignee_number": "+6223253456",
      "consignee_email": "janiotest@gamil.com",
      "consignee_id": "",
      "consignee_id_type": "",
      "order_length": "10.00",
      "order_width": "10.00",
      "order_height": "10.00",
      "order_weight": "10.00",
      "pickup_contact_name": "Janio orders",
      "pickup_contact_number": "91234567",
      "pickup_country": "Singapore",
      "pickup_state": "Central Singapore",
      "pickup_city": "Singapore",
      "pickup_province": "",
      "pickup_postal": "640534",
      "pickup_address": "Jurong West Ave 1",
      "pickup_date": "2022-03-30",
      "billing_contact_name": null,
      "billing_contact_number": null,
      "billing_country": "Indonesia",
      "billing_state": "Aceh",
      "billing_city": "Aceh",
      "billing_province": null,
      "billing_postal": "23111",
      "billing_address": "test test",
      "payment_type": "prepaid",
      "incoterm": "DDP",
      "label_url": null,
      "cod_amount": null,
      "paid_on": null,
      "pickup_location": 458,
      "billing_location": null,
      "status": null,
      "payment_status": "UNPAID",
      "janio_order_status": null,
      "created_on": "2022-03-29T04:34:16.407225Z",
      "batch_no": null,
      "janio_order_id": null,
      "order_type": "CLIENT",
      "origin": "INTERNAL",
      "dropoff_vendor_point_id": null,
      "dropoff_vendor_name": null,
      "service_type": "Pickup"
    },
    "rate": {
      "charge_id": 9718,
      "total": "163.73",
      "currency": "SGD",
      "weight_upper_bound": "10.00",
      "chargable_weight": "10.00",
      "total_taxes": "1.93",
      "total_duties": "1.00",
      "gst_amount": "0.00",
      "amount_to_pay": "163.73",
      "duties_and_taxes": "2.93",
      "shipping_charge": "160.80",
      "is_tax_payable": true
    },
    "account_charges": [
      {
        "account_charge_id": 394,
        "amount": "6.00",
        "currency": "SGD",
        "account_charge_type": "SLOT_RESERVATION"
      }
    ]
  },
  'create-api-order-token-error':
   `// Returns when invalid client-api-key is given in request header
   {
      "detail": "Invalid API Key"
   }`,
  'create-api-order-invalid-consignee-country-error':
   `// Returns when invalid consignee_country_code is given in the request payload
   {
       "consignee_country_code": [
           "Invalid country code"
       ]
   }`,
  'create-api-order-past-pickup-date-error':
  `// Returns when past pickup_date is given in the request payload
  {
      "pickup_date": [
          "Pickup date can't be in the past"
      ]
  }`,
  'create-api-order-future-pickup-date-error':
  `// Returns when future pickup_date is given in the request payload. Pickup date cannot be more than 2 weeks from today.
  {
      "error": "Order creartion failed"
  }`,
  'create-api-order-invalid-dimension-error':
  `// Returns when invalid dimensions are given in the request payload
  {
      "order_weight": [
          "Maximum weight allowed is 30 kg."
      ]
  }`,
  'create-api-order-item-category-error':
  `// Returns when invalid item category is given in the request payload
  {
      "error": "Order creartion failed"
  }`
}