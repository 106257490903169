import { useState, useContext, createContext, ReactNode } from "react"
import { instanceForb2c as apib2c } from '../commons/api'
// const singleOrderContext = createContext<SingleOrderContext>({} as SingleOrderContext);

interface TrackingNumberDisputes {
  item_desc: string,
  item_length: string | number,
  item_width: string | number,
  item_height: string | number,
  item_weight: string | number,
  supporting_doc_length: number[],
  supporting_doc_width: number[],
  supporting_doc_height: number[],
  supporting_doc_weight: number[],
}
interface DisputeOrderInterface {
  dispute_type: string,
  tracking_nos: string[],
  items:TrackingNumberDisputes[]
}
interface DisputeOrderContextInterface {
  disputeData: DisputeOrderInterface
  updateDisputeData: (values: any) => any
  submitDisputedData: (token: string) => Promise<any>
}

const disputeContext = createContext<DisputeOrderContextInterface>({} as DisputeOrderContextInterface);

export const useDisputeContext = () =>{
  return useContext(disputeContext);
};

const useDisputeContextProvider = (data?: DisputeOrderInterface) => {
  const [disputeData, setDisputeData] = useState<DisputeOrderInterface>(() =>{
    return {
      dispute_type: '',
      tracking_nos: [],
      items: [{
        item_desc: '',
        item_length: '',
        item_width: '',
        item_height: '',
        item_weight: '',
        supporting_doc_length: [],
        supporting_doc_width: [],
        supporting_doc_height: [],
        supporting_doc_weight: [],
      }]
    }
  });

  const updateDisputeData = (values: any) => {
    console.log('jhkjhkj', values)
    setDisputeData((current: any) => ({...current, ...values}));
    return disputeData
  };

  const submitDisputedData = async (token: string) => {
    let response = await apib2c.post('orders/orders/',
      {
        ...disputeData
      },
      {
        headers: {
          'Authorization': `Token ${token}`
        }});
    return response.data
  };
  
  return {
    disputeData,
    updateDisputeData,
    submitDisputedData
  }
};

export const DisputeContextProvider = ({children, order}: {children: ReactNode, order?: DisputeOrderInterface}) => {
  const disputeData = useDisputeContextProvider(order);

  return (
    <disputeContext.Provider value={disputeData}>
      {children}
    </disputeContext.Provider>
  )
};