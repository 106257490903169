// import React from 'react'
import {Row, Col, Radio, Button, Alert} from 'antd';
import { FastFormInput } from '../../commons/components/FastFormFields/FastFields';
import {Formik, Form as FormikForm} from 'formik';
import * as Yup from 'yup';
import '../disputes.css'
import { useDisputeContext } from '../disputeContext'


interface DisputeInterface{
  current: number
  setCurrent: (val: any) => any
}

const disputeOptions: any = {
  'weight_dispute': 'Weight Dispute (Parcel)'
}

const validationSchema = () => {
  return ( 
    Yup.object().shape({
      // tracking_nos: Yup.array().of(Yup.object().shape({
      //   item_desc: Yup.string().nullable().required(getText('Item Description is required'))
      // })),
      tracking_nos : Yup.string().required('Please enter tracking number(s)'),
      dispute_type: Yup.string().required('Please select Dispute Type')
      // .max(80, 'Must be less than 80 characters'),
    }))
}

export const DisputeType = (props: DisputeInterface) => {
  const disputeContext = useDisputeContext()
  // console.log('disputeContextdisputeContext', disputeContext)
  
  // const saveButton = () => {
  //   props.setCurrent(1)
  //   // claimType === 'weight_dispute' ? props.setCurrent(2) : props.setCurrent(1)
  // }

  const initialValues= {
    tracking_nos: typeof(disputeContext.disputeData.tracking_nos) === 'string' ? disputeContext.disputeData.tracking_nos : disputeContext.disputeData.tracking_nos.toString(),
    dispute_type: disputeContext.disputeData.dispute_type
  }

  if(props.current > 0){
    return (
      <div id="dispute_summary">
        <div className='dispute_summary'>
          <p>{'Tracking number'}</p>
          <p className='dispute-color-gray'>
            {disputeContext.disputeData.tracking_nos.toString()}
          </p>
          <p>{'Dispute Type'}</p>
          <p className='dispute-color-gray'>{disputeOptions[disputeContext.disputeData.dispute_type]}</p>
        </div>
        <Alert
          message={
            <>
              <p style={{margin:0}}><strong>Please ensure that the following documents or images are included in your submission</strong></p>
              
            </>
          }
          description={
            <>
              <span>1. Parcel Length</span>
              <span>2. Parcel Width</span>
              <span>3. Parcel Height</span>
              <span>4. Parcel Weight</span>
            </>
          }
          type="info"
          showIcon
        />
      </div>
    )
  }

  const onFormikSubmit = (values: any) => {
    let tracking_no_arr = values.tracking_nos.split(/[ ,]+/)
    let submittedData = {
      dispute_type: values.dispute_type,
      tracking_nos: tracking_no_arr,
      items: tracking_no_arr.map((tn: string) => (
        {
          item_desc: '',
          item_length: '',
          item_width: '',
          item_height: '',
          item_weight: '',
          supporting_doc_length: [],
          supporting_doc_width: [],
          supporting_doc_height: [],
          supporting_doc_weight: [],
        }
      ))
    }
    console.log('submittedData', submittedData)

    disputeContext.updateDisputeData(submittedData)
    console.log('valuesvalues', submittedData)

    props.setCurrent(1)
  }
  return (
    <Row className='claim-main-container'>
      <Col span={24}>
        <Formik 
          initialValues={initialValues}
          onSubmit={onFormikSubmit}
          validationSchema={validationSchema} 
        >
          {formik => {
            return (
              <FormikForm className='dispute-main-container'>
                <Col span={24}>
                  <Row>
                    <Col span={6}>
                      <Radio.Group 
                        onChange={(e)=> formik.setFieldValue('dispute_type', e.target.value)} 
                        value={formik.values.dispute_type} 
                        style={{display: 'flex', justifyContent: 'start'}}
                        name='dispute_type'
                      >
                        {
                          Object.entries(disputeOptions).map(([disputeKey, disputeValue]: [any, any])=> {
                            return <Col span={12} key={disputeKey}><Radio value={disputeKey} key={disputeKey} className='radio-options'>{disputeValue}</Radio></Col>
                          })
                        }
                      </Radio.Group>
                    </Col>
                    <Col span={18} className='claims-input-width disputes-form'>
                      <FastFormInput 
                        required 
                        label={'Enter Tracking Numbers'} 
                        // inputProps={{disabled: true, placeholder: 'You can only enter a maximum of 20 Tracking Numbers per submission and must be comma or space separated'}} 
                        name='tracking_nos'
                        textArea={true}
                        rows={4}
                        placeholder= {'You can only enter a maximum of 20 Tracking Numbers per submission and must be comma or space separated'}
                      />
                    </Col>
                  </Row>
                  <div style={{textAlign: 'right', margin: '20px 0 -12px 0'}}>
                    <Button htmlType="submit" type='primary' 
                      // onClick={saveButton} 
                      disabled={(formik.values.dispute_type.length === 0 || formik.values.tracking_nos.length === 0)} className='blue-button'>{'Save & Continue'}</Button>
                  </div>
                </Col>
              </FormikForm>
            )
          }
          }
        </Formik>
      </Col>
    </Row>
  )
}

interface disputeTypeInterface{
  current: number,
  setCurrent: (val: number) => any,
}

export const DisputeTypeTitle = (props: disputeTypeInterface) => {
  const {current, setCurrent} = props;
  return (
    <div className='claim-step-header'>
      <p className={'claim-step-title'}>{'Select the type of Dispute to be submitted'}</p>
      {current>0 && <p onClick={()=> setCurrent(0)} className='edit-button'>{'Edit Details'}</p>}
    </div>
  )
}