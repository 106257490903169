import React, { useState } from 'react'
import {Row, Col, Radio, Button, Card, Alert} from 'antd';
import {claimsOptions } from '../../commons/utils/constants';


interface Claim{
  claimType: string
  setClaimType: (val:any)=> any
  current: number
  tracking_no?: string
  selectedOrders?: any
  setCurrent: (val: any) => any
}
export const ClaimsTypeSelection = (props: Claim) => {
  const {claimType, setClaimType} = props
  const [initalClaimType, setInitialClaimType] = useState()

  const saveButton = () => {
    setClaimType(initalClaimType)
    props.setCurrent(1)
  }

  if(props.current > 0){
    return (
      <>
        <Card>
          <p className='claim-type-header'>{'Tracking number'}</p>
          <p className='claim-type-gray'>
            {props.selectedOrders? props.selectedOrders.map((order: any, index: number) => <span key={order.order_id}>{`${order.tracking_no}`}{!(index === props.selectedOrders.length- 1) && ', '}</span>)
              :props.tracking_no}
          </p>
          <p className='claim-type-header'>{'Claim Type'}</p>
          <p className='claim-type-gray'>{claimsOptions[claimType]}</p>
        </Card>
        { claimType === 'damaged_orders' &&
          <Alert type='info' className='claims-card-alert' showIcon message={         
            <p>{'Please ensure that the following documents or images are included in your submission.'}</p>           
          }
          description={
            <ol>
              <li>{'Item packed in protective material.'}</li>
              <li>{'Damaged/torn outer package box.'}</li>
              <li>{'Damaged item inside the box.'}</li>
              <li>{'Undamaged items before shipment.'}</li>
            </ol>
          }
          />
        }
      </>
    )
  }

  return (
    <Row className='claim-main-container'>
      <Col span={24}>
        {/* <h1 className='ant-page-header-heading-title page-title'>{'Claims Form'}</h1> */}
        <Row gutter={[24,24]}>
          <Radio.Group 
            onChange={(e)=>setInitialClaimType(e.target.value)} 
            value={initalClaimType} 
            style={{display: 'flex', justifyContent: 'start'}}
          >
            {
              Object.entries(claimsOptions).map(([claimKey, claimValue]: [any, any])=> {
                return <Col span={12} key={claimKey}><Radio value={claimKey} key={claimKey} className='radio-options'>{claimValue}</Radio></Col>
              })
            }
          </Radio.Group>
        </Row>
      </Col>
      <div className='claim-button'>
        <Button type='primary' onClick={saveButton} disabled={!initalClaimType} className='blue-button'>{'Save & Continue'}</Button>
      </div>
    </Row>
  )
}

interface claimTitle{
  current: number,
  setCurrent: (val: number) => any,
}

export const ClaimTypeSelectionTitle = (props: claimTitle) => {
  const {current, setCurrent} = props;
  return (
    <div className='claim-step-header'>
      <p className={'claim-step-title'}>{'Select the type of Claim to be submitted'}</p>
      {current>0 && <p onClick={()=> setCurrent(0)} className='edit-button'>{'Edit Details'}</p>}
    </div>
  )
}