import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col,
  Alert,
} from 'antd';
import {useState} from "react";
import './styles/AuthForm.css';
import {useMutation} from "react-query";
import {resend_activation} from "./services/authApi";
import {ThanksForm} from "./components/ThanksForm";
import {validateMessages} from "../commons/utils/constants";

interface Resent {
  onResent(val:boolean):void;
}
const ResendForm = (props:Resent) => {
  const [isResendFailed, setIsResendFailed] = useState<boolean>(false);
  const resend = useMutation(async ({email}: {email: string}) => {
    return resend_activation(email)
  },  {
    onSuccess: (val) => {
      props.onResent(true);
    },
    onError: (val) => {
      setIsResendFailed(true);
    },
  });

  return (
    <Form
      onFinish={(val) => {
        resend.mutate(val);
      }}
      layout={'vertical'}
      requiredMark={false}
      validateMessages={validateMessages}
    >
      <Form.Item label="Please re-enter your email address" className={"form-title"}>
      </Form.Item>
      {isResendFailed && <Alert
        className={"form-error"}
        message="Unable to process your request"
        type="error"
        showIcon
      />}
      <Form.Item
        label="Email Address"
        name="email"
        rules={[{ required: true, message: 'Please enter your email' }, {type: 'email'}]}
      >
        <Input />
      </Form.Item>

      <Form.Item className={'form-footer'}>
        <Button type="primary" size={"large"} htmlType="submit" className={"form-button"} loading={resend.isLoading}>
            Resend email
        </Button>
      </Form.Item>
    </Form>
  )
};


export const Resend = () =>  {
  const [isResent, setIsResent] = useState(false);
  const onResent = (val:boolean):void => {
    setIsResent(val);
  };
  return (
    <Row align="middle" justify="center">
      <Col span={12} className={'auth-form-container'}>
        <Card>
          {isResent ? <ThanksForm /> :<ResendForm onResent={onResent}/>}
        </Card>
      </Col>
    </Row>
  )
};
