interface Currency {
  [key: string]: string
};

const CURRENCY_LOCALE: Currency = {
  'IDR': 'id-ID',
  'CNY': 'zh-CN',
  'HKD': 'zh-HK',
  'TWD': 'zh-TW',
  'THB': 'th-TH'
};

/**
 * Display number `1000000.1` as locale currency `1,000,000.10` (without symbol) based on their currency code (USD).
 * If formatted amount does not format properly based on their currency code. Please add currency-locale mapping in `CURRENCY_LOCALE`.
 * @param {number} amount number that will format
 * @param {string} currencyCode currency code that will format based on
 * @returns formatted amount
 */
export const parseLocaleCurrency = (amount: number, currencyCode: string): string => {
  const locale = CURRENCY_LOCALE[currencyCode] || 'en-US';

  const minimumFractionDigits = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode
  }).resolvedOptions().minimumFractionDigits;

  const maximumFractionDigits = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode
  }).resolvedOptions().maximumFractionDigits;

  const value = amount.toLocaleString(locale, {
    minimumFractionDigits,
    maximumFractionDigits
  });

  return value;
}