import { Form, Radio, Row, Col, Select, Button, Alert } from 'antd'
import '../../styles/ReturnOrderForm.css'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../auth/services/authContext'
import { Formik, Form as FormikForm, Field, FieldProps } from 'formik';
import {FormInput, FormSelect, TitleErrorMarker} from '../../../orders/components/Forms';
import { parseErrors } from '../../../../src/orders/helpers';
import * as Yup from 'yup';
import { getAllCountries_from_b2c, returnValidation_in_b2c, getReturnsServiceTypes_from_b2c } from '../../../orders/services/privateApi';
import {useQuery, useMutation} from 'react-query';
import { getForwardOrderDetails_from_b2c } from '../../../orders/services/privateApi';
import { useSingleOrderB2C,  TrackingNumberSuccess, setAllFields, OrderErrorB2C } from "../../services/returnSingleOrderContext";
import { useLocation} from 'react-router-dom';
import {
  getServiceOptions, serviceTypeMapper } from "../../../commons/utils/utilizer";
import { ReturnSingleOrderCreate } from '../../../commons/mixpanel';
interface PropsType{
  current: number,
  setCurrentState: (val: number) => void,
  dataFilled?: number
}
interface FormValues{
  forward_janio_order_id: string
  tracking_number_radio: boolean
  consignee_country?: string
  pickup_country?: string
  service: string
}

const parseOrderContextError = (error: OrderErrorB2C) => {
  let err = {
    forward_janio_order_id: error.forward_tracking_no,
  }

  let pickup_err = [];
  if (error.forward_tracking_no) {
    pickup_err.push(error.forward_tracking_no);
  }
  return err;
}

const {Option} = Select;

export const InformationCheckForm = (props: PropsType) => {
  const location = useLocation()
  const isEdit = location.pathname.includes('/pay/return/')
  const auth = useAuth();
  const authToken = auth.user.authToken; 
  const singleOrderContext = useSingleOrderB2C();
  const { current, setCurrentState } = props;
  const [trackingNumberRadio, setTrackingNumberRadio] = useState<boolean>(isEdit ? false: true)
  const [trackingNumberText, setTrackingNumberText] = useState<string>('')
  const [isErrorBoolean, setErrorBoolean] = useState<boolean>(false)
  const [trackingNoValidated, setTrackingNoValidated] = useState(false)

  let destinationCountryCode = singleOrderContext.singleOrder?.consignee_country! ? singleOrderContext?.singleOrder.consignee_country! : 'Singapore'

  let origin_Country_Code = singleOrderContext.singleOrder.pickup_country! ? singleOrderContext.singleOrder.pickup_country! : 'Singapore'

  const [destCountryCode, setDestCountryCode] = useState<string>(destinationCountryCode)
  const [orginCountryCode, setOriginCountryCode] = useState(origin_Country_Code)
  const [showRestDetails, setShowRestDetails] = useState<boolean>(false)
  const [laneValidationError, setLaneValidationError] = useState<[]>([])
  const [fetchReturnServices, setFetchReturnServices] = useState<boolean>(false)

  const radioChange = (e: any) => {
    setTrackingNumberRadio(e.target.value)
    setErrorBoolean(false)
  }

  const { hasError, emptyError } = parseErrors(
    singleOrderContext.errors, parseOrderContextError
  )

  
  const resetFieldValuesOnPickupChange = ['consignee_country', 'service'];
  const resetFieldValuesOnDestinationChange = ['service'];

  const validationSchema = () => {
    if(trackingNumberRadio) {
      return Yup.object().shape({
        forward_janio_order_id: Yup.string().required('Tracking number is required').matches(/^[0-9a-zA-Z-]*$/,'Only alphanumeric characters are allowed'),
        service: trackingNoValidated ? Yup.string().required('Service type is required') : Yup.string().nullable()
      })
    }else {
      return Yup.object().shape({
        service: Yup.string().required('Service type is required'),
        pickup_country: Yup.string().required('Pickup country is required'),
        consignee_country: Yup.string().required("Destination country is required")
      })
    }
    
  }

  const initialValues = {
    tracking_number_radio: trackingNumberRadio,
    forward_janio_order_id: trackingNumberText,
    service: singleOrderContext.singleOrder.service,
    pickup_country: singleOrderContext.singleOrder.pickup_country,
    consignee_country: singleOrderContext.singleOrder.consignee_country,
  }

  const getAllCountriesData = useQuery('getAllCountriesData', () => {
    return getAllCountries_from_b2c()
  })

  const getReturnServiceTypes = useQuery('getReturnServiceTypes', () => {
    return getReturnsServiceTypes_from_b2c(auth.user.secret_key, orginCountryCode, destCountryCode)
  }, {enabled: fetchReturnServices, cacheTime: 0})

  const fetchForwardOrderDetailsWithTrackingNumber_from_b2c = useMutation(async (value:any) => {
    return getForwardOrderDetails_from_b2c(auth.user.secret_key, value, authToken)
  })

  const returnLaneValidation = useMutation(async (datareq:any) => {
    return returnValidation_in_b2c(datareq)
  })

  const mutateReturnLaneValidation = (pickupCountry: string, consigneeCountry: string) => {
    let data = {
      pickup_country: pickupCountry,
      consignee_country: consigneeCountry,
      secret_key: auth.user.secret_key
    }
    returnLaneValidation.mutate(data, {
      onSuccess: (values: any) => {
        setShowRestDetails(true)
        setLaneValidationError([])
        setFetchReturnServices(true)
        setErrorBoolean(false)
      },
      onError: (error: any) => {
        let errArray: any = []
        let err = error.response?.data!
        if(err) {
          Object.entries(err).map((y: any) => {
            return errArray.push(y[1])
          })
          setLaneValidationError(errArray)
        } else {
          errArray.push('Error in validation. Please try again later')
          setLaneValidationError(errArray)
        }
        setShowRestDetails(false)
        setErrorBoolean(true)
      }
    })
  }

  useEffect(() => {
    if(getReturnServiceTypes.isSuccess && !getReturnServiceTypes.data?.length!) {
      setErrorBoolean(true)
  
      let errArray: any = []
      errArray.push(`${orginCountryCode}-${destCountryCode} This is not a valid returns Lane`)
      setLaneValidationError(errArray)
    }
  }, [getReturnServiceTypes.data]) //eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = (formValues: FormValues) => {
    ReturnSingleOrderCreate(auth?.user?.fullName)
    if(formValues.tracking_number_radio && !trackingNoValidated) {
      fetchForwardOrderDetailsWithTrackingNumber_from_b2c.mutate(formValues.forward_janio_order_id, {
        onSuccess: (values: TrackingNumberSuccess) => {
          singleOrderContext.updateSingleOrderData(
            setAllFields(values, formValues.forward_janio_order_id)
          )
          setDestCountryCode(values.consignee_details.consignee_country)
          setOriginCountryCode(values.pickup_details.pickup_country)
          setTrackingNumberText(formValues.forward_janio_order_id)
          // setCurrentState(1)
          setTrackingNoValidated(true)
          setErrorBoolean(false)
          setFetchReturnServices(true)
        },
        onError: (error: any) => {
          let errArray: any = []
          let err = error.response?.data!
          if(err) {
            Object.entries(err).map((y: any) => {
              return errArray.push(y[1])
            })
            setLaneValidationError(errArray)
          } else {
            errArray.push('Error in validation. Please try again later')
            setLaneValidationError(errArray)
          }
          setShowRestDetails(false)
          setErrorBoolean(true)
        }
      })
    }else {
      singleOrderContext.updateSingleOrderData({
        pickup_country: formValues.pickup_country,
        consignee_country: formValues.consignee_country,
        tracking_no: undefined,
        within_coverage: true,
        service: formValues.service,
        firstmile_type: "Pickup from customer's address",
        service_type: serviceTypeMapper(formValues.service),
        dropoffObject: {},
        is_label_less_order: false
      })
      setCurrentState(1)
    }
    singleOrderContext.setErrors({ ...emptyError })
  }

  const onChangeHandler = (value: any) => {
    setOriginCountryCode(value)
  }

  const onDestinationChangeHandler = (value: any) => {
    setDestCountryCode(value)
  }

  if(current > 0){
    return (
      <div className='return-order-form-container'>               
        <Row>
          <Col span={24}>
            <div style={{fontWeight: 'bold'}}><TitleErrorMarker text={'Information Overview'} hasError={hasError} /></div>
          </Col>                     
          {
            !isEdit && 
              <Col span={24} className={'mb-10'}>
                <p style={{color: '#808080', marginBottom: '0px'}}>Existing Tracking Number</p>
                <p>{trackingNumberText? trackingNumberText: 'No'}</p>
              </Col>  
          }
          {trackingNumberRadio === false && <Col span={24}>
            <p style={{color: '#808080', margin: 0}}>Service Lane</p>
            <p>{initialValues.pickup_country!} to {initialValues.consignee_country!}</p>
          </Col>}                  
          <Col span={24}>
            <p style={{color: '#808080', marginBottom: '0px'}}>Service Type</p>
            <p>{singleOrderContext.singleOrder.service}</p>
          </Col>
        </Row>         
      </div>
    )
  }

  const returnServiceTypes = getReturnServiceTypes.data && getServiceOptions(getReturnServiceTypes.data!).map(type => {
    return (
      <Option key={`service-type-${type}`} value={type}>{type}</Option>
    )
  })
  return (
    <div className='return-order-form-container ant-form-vertical'>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        validateOnChange={true}
      >
        {formik => (
          <FormikForm>
            {
              !trackingNoValidated &&
              <>
                <Form.Item label={'Do you have a Janio tracking number?'} style={{marginBottom: '-40px'}} />
                <Row gutter={14}>
                  <Col span={24}>
                    <label htmlFor='tracking_number_radio' style={{color: '#808080', marginBottom: '0px'}}>A forward tracking number which you sent an order to your customer</label>
                    <Field name={'tracking_number_radio'}>
                      {
                        (field: FieldProps) => {
                          return (
                            <Form.Item style={{marginTop: '15px'}} validateStatus={(field.meta.touched && field.meta.error) ? 'error' : undefined}
                              help={(field.meta.touched && field.meta.error) ? field.meta.error : null}>
                              <Radio.Group defaultValue={trackingNumberRadio} onChange={(e)=>{radioChange(e);setFetchReturnServices(false)}}>
                                <Radio value={true} disabled={isEdit}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                              </Radio.Group>
                            </Form.Item>
                          )
                        }
                      }
                    </Field>
                  </Col>
                </Row>
              </>
            }

            {(isErrorBoolean && laneValidationError.length > 0) &&
              laneValidationError.map((item: any, index: number) => {
                return <Alert
                  message={item.toString()}
                  key={index}
                  // description={val?.response?.data?.error!}
                  type="error"
                  showIcon
                  style={{marginBottom: '20px'}}
                />
              })
            }

            {formik.values.tracking_number_radio ? 
              <Col span={24}>
                <Row gutter={16}  >
                  <Col span={12}>
                    <FormInput 
                      required
                      name="forward_janio_order_id" 
                      label={'Tracking Number'}
                      placeholder='Janio Tracking Number'
                      inputProps={{disabled: trackingNoValidated}}
                    />
                  </Col>
                    
                  {
                    (fetchForwardOrderDetailsWithTrackingNumber_from_b2c.isSuccess) && 
                    <Col span={12}>
                      <FormSelect name="service" required label={'Service Type'} >
                        {returnServiceTypes}
                      </FormSelect>
                    </Col>
                  }
                  
                </Row>
              </Col>
              :
              <Row gutter={14}>
                <Col span={12}>
                  <FormSelect name="pickup_country" loading={getAllCountriesData.isLoading} label={'Origin Country'} required onChangeCallback={(value: any) => {
                    resetFieldValuesOnPickupChange.forEach(field => formik.setFieldValue(field, ''));
                    onChangeHandler(value)
                    setShowRestDetails(false)
                    setFetchReturnServices(false)
                  }}
                  >
                    {
                      getAllCountriesData && getAllCountriesData.data?.map((item: string) => {
                        return <Option key={item} value={item}>{item}</Option>
                      })
                    }
                  </FormSelect>
                  {(getAllCountriesData.isError) && <p style={{ color: 'red', marginBottom: 0, marginTop: '-20px', fontSize: '11px' }}>{'Failed to load origin countries, please try again later'}</p>}
                </Col>
                <Col span={12}>
                  <FormSelect name="consignee_country" disabled={formik.values.pickup_country ? false : true} label={'Destination Country'} loading={getAllCountriesData.isLoading} required onChangeCallback={(value: any) => {
                    resetFieldValuesOnDestinationChange.forEach(field => formik.setFieldValue(field, ''));
                    onDestinationChangeHandler(value)
                    setShowRestDetails(false)
                    setFetchReturnServices(false)
                  }}>
                    {
                      getAllCountriesData && getAllCountriesData.data?.map((item: string) => {
                        return <Option key={item} value={item}>{item}</Option>
                      })
                    }
                  </FormSelect>
                  {(getAllCountriesData.isError) && <p style={{ color: 'red', marginBottom: 0, marginTop: '-20px', fontSize: '11px' }}>{'Failed to load destination countries, please try again later'}</p>}
                </Col>

                {!showRestDetails && 
                  <Col span={24}>
                    <Row justify='end'>
                      <Button type='primary' onClick={() => mutateReturnLaneValidation(formik.values.pickup_country, formik.values.consignee_country)} loading={returnLaneValidation.isLoading} disabled={formik.values.pickup_country === '' || formik.values.consignee_country === ''}>{'Next'}</Button>
                    </Row>
                  </Col>
                }

                {showRestDetails && 
                  <Col span={24}>
                    <label htmlFor='service'>Service Type</label>
                    <Field name={'service'}>
                      {
                        (field: FieldProps) => {
                          return(
                            <Form.Item validateStatus={(field.meta.touched && field.meta.error) ? 'error' :   undefined}
                              help={(field.meta.touched && field.meta.error) ? field.meta.error : null}>
                              <Select value={field.field.value} onChange={(val)=>field.form.setFieldValue('service',val)} placeholder='service type' >
                                {returnServiceTypes}
                              </Select>
                            </Form.Item>
                          )
                        }
                      }
                    </Field>
                  </Col>
                }
                
              </Row>}
            {(showRestDetails || trackingNumberRadio) && 
              <Row justify='end'>
                <Button htmlType='submit' type='primary' loading={fetchForwardOrderDetailsWithTrackingNumber_from_b2c.isLoading}>{'Save & Continue'}</Button>
              </Row>
            }
          </FormikForm>
        )}    
      </Formik>
    </div>
  )
}

export const InformationCheckFormTitle = (props: PropsType) => {
  const {current, setCurrentState} = props;
  return (
    <div className={"title-block"}>
      <p className={'step-title'}>Information Check</p>
      {current>0 ? <p onClick={()=> setCurrentState(0)} className={"edit-text"}>{'Edit details'}</p>:''}
    </div>
  )
}