import { Button, Col, Table, Tag, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import DownloadModal from './DownloadModal';
import Invoice from '../interfaces/Invoice';
import { parseInvoiceAmount } from '../utils/parsing';
import { formatDateOnly } from '../../commons/utils/utilizer';

const { Text } = Typography;

interface Props {
  data: Invoice[]
};

const AllInvoicesTab = (props: Props) => {
  const { data } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const [invoice, setInvoice] = useState<Invoice>();
  const [invoices, setInvoices] = useState<Invoice[]>([]);

  const columns = [
    {
      title: 'Invoice Date',
      dataIndex: 'date',
      key: 'date',
      width: 130,
      render: (date: string) => formatDateOnly(date) || '-'
    },
    {
      title: 'Invoice Number',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      width: 160,
      render: (invoiceNumber: string) => <Text strong>{invoiceNumber}</Text>
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      width: 130,
      render: (date: string) => formatDateOnly(date) || ''
    },
    {
      title: 'Currency',
      dataIndex: 'currencyCode',
      key: 'currencyCode',
      width: 130,
    },
    {
      title: 'Invoice Amount',
      dataIndex: 'total',
      key: 'total',
      width: 130,
      render: (total: number, data: Invoice) => <Text strong>{parseInvoiceAmount(total, data.type, data.currencyCode)}</Text>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 130,
      render: (status: string, data: Invoice) => {
        return data.type === 'INVOICE' && (status === 'PAID'
          ? (<Tag style={{ fontSize: '14px', borderRadius: '10px' }} color='green'>Paid</Tag>)
          : (<Tag style={{ fontSize: '14px', borderRadius: '10px' }} color='orange'>Unpaid</Tag>))
      }
    },
    {
      title: 'Invoice & Document',
      dataIndex: 'hasAttachments',
      key: 'hasAttachments',
      width: 160,
      render: (hasAttachments: boolean, data: Invoice) => (
        !hasAttachments && data.isOverpayment
          ? <Tooltip title="Attachment Not Available"><Button type="primary" disabled>Download</Button></Tooltip>
          : <Button type="primary" onClick={() => handleDownload(data)}>Download</Button>
      )
    },
  ];

  const handleDownload = (invoice: Invoice) => {
    setInvoice(invoice);
    setVisible(true);
  };

  useEffect(() => {
    if (!data.length) {
      return;
    }

    setInvoices(data);
  }, [data]);

  return (
    <Col span={24}>
      { invoice && <DownloadModal tab='All Invoices' invoice={invoice} visible={visible} setVisible={setVisible} /> }
      <Col span={24}>
        <Table
          dataSource={invoices}
          // @ts-ignore
          columns={columns}
          scroll={{ x: 1000 }}
        />
      </Col>
    </Col>
  )
};

export default AllInvoicesTab;