import {Select, Button, Col, Row, DatePicker, Input, Tooltip, message} from 'antd';
import {OrderStatusListForFreight} from '../../commons/utils/constants'
import {useEffect, useState} from 'react';
import {SearchOutlined
} from '@ant-design/icons';
import { useQuery } from "react-query";
// import {useAuth} from "../../auth/services/authContext";
import {getAllCountries_from_b2c } from "../../orders/services/privateApi";
import moment, {Moment} from 'moment';
import { formatDateDefaultFn } from '../ManageShipmentsContainer';
const {Option} = Select;
const { RangePicker } = DatePicker;

interface shipmentFilter{
  shipperOrderIdString: string | null;
  orderStatusString: string | null;
  pickupCountryString: string | null;
  destinationCountryString: string | null;
  serviceTypeString: string | null;
  setShipperOrderIdString(val: string):void;
  setOrderStatusString(val:string):void;
  setPickupCountryString(val:string):void;
  setDestinationCountryString(val:string):void;
  setServiceTypeString(val:string):void;
  status: string,
  hasData: boolean,
  date_from?: string | null
  date_to?: string | null
  setDate_from?: (val:string | null) => void
  setDate_to?: (val:string | null) => void
  // page?: number
  // setPage?: (val: number) => void
  // setCurrent?: (val: number) => void
  // dataFrom?: string
  trackingNumberString: string | null;
  setTrackingNumberString(val: string):void;
}

const FreightFilters = (props:shipmentFilter) => {
  // const auth = useAuth();
  const [pickupCountry, setPickupCountry] = useState<any>([])
  const [destinationCountryCode, setDestinationCountryCode] = useState<any>([]);
  const [shipperOrderId, setShipperOrderId] = useState<any>('')
  const [trackingNo, setTrackingNo] = useState<any>('')
  const [orderStatus, setOrderStatus] = useState<any>([]);
  const [serviceType, setServiceType] = useState<any>([])
  const [open, setOpen] = useState(false)
  const [openPickup, setOpenPickup] = useState(false)
  const [openDestination, setOpenDestination] = useState(false)
  // const [openService, setOpenService] = useState(false)
  // const [serviceListDropdown, setServiceListDropdown] = useState<object>(ServiceTypeList)
  const [startDate, setStartDate] = useState(formatDateDefaultFn('start'))
  const [endDate, setEndDate] = useState(formatDateDefaultFn('end'))
  const [isSearchExceeded, setIsSearchExceeded] = useState<boolean>(false)

  const onChangeHandler = (value: any, type: string) => {
    if(type === 'order_status') {
      setOrderStatus(value)
    }
    if(type === 'pickup') {
      setPickupCountry(value)
    }
    if(type === 'destination') {
      setDestinationCountryCode(value)
    }
    if(type === 'shipper_order_id'){
      setShipperOrderId(value)
      // props.setShipperOrderIdString(value)
    }
    if(type === 'tracking_no'){
      setTrackingNo(value)
      // props.setTrackingNumberString(value)
    }
    if(type === 'service_type'){
      setServiceType(value)
    }
  }

  const onDropdownClear = (type: string) => {
    if(type === 'order_status') {
      setOpen(false)
      setOpenPickup(false)
      setOpenDestination(false)
      // setOpenService(false)
    }
    if(type === 'pickup') {
      setOpen(false)
      setOpenPickup(false)
      setOpenDestination(false)
      // setOpenService(false)
    }
    if(type === 'destination') {
      setOpen(false)
      setOpenPickup(false)
      setOpenDestination(false)
      // setOpenService(false)
    }
    if(type === 'created_by') {
      setOpen(false)
      setOpenPickup(false)
      setOpenDestination(false)
      // setOpenService(false)
    }
    if(type === 'service_type'){
      // setOpenService(false)
      setOpen(false)
      setOpenPickup(false)
      setOpenDestination(false)
    }
    
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement!.blur();
    }
  }
  useEffect(() => {
    resetFilters()
  }, [props.status])// eslint-disable-line react-hooks/exhaustive-deps

  const getAllCountriesData = useQuery('getCountriesData', () => {
    return getAllCountries_from_b2c()
  })

  const setFiltersOnSubmit = () => {
    if(orderStatus){
      const value = orderStatus.join(',')
      props.setOrderStatusString(value)
    }
    if(pickupCountry){
      const value = pickupCountry.join(',')
      props.setPickupCountryString(value)
    }
    if(destinationCountryCode){
      const value = destinationCountryCode.join(',')
      props.setDestinationCountryString(value)
    }
    props.setTrackingNumberString(trackingNo)
    props.setShipperOrderIdString(shipperOrderId)
    if(serviceType) {
      const value = serviceType.join(',')
      props.setServiceTypeString(value)
    }
    if(startDate) {
      props.setDate_from!(startDate)
    }
    if(endDate) {
      props.setDate_to!(endDate)
    }
    // if(props.dataFrom === 'manageShipment'){
    //   props.setPage!(1)
    //   props.setCurrent!(1)
    // }
  }  

  const resetFilters = () => {
    setShipperOrderId('')
    setTrackingNo('')
    setOrderStatus([])
    setPickupCountry([])
    setDestinationCountryCode([])
    setServiceType([])
    setStartDate(formatDateDefaultFn('start'))
    setEndDate(formatDateDefaultFn('end'))

    props.setDestinationCountryString('')
    props.setOrderStatusString('')
    props.setPickupCountryString('')
    props.setServiceTypeString('')
    props.setDate_from!(formatDateDefaultFn('start'))
    props.setDate_to!(formatDateDefaultFn('end'))
    props.setShipperOrderIdString('')
    props.setTrackingNumberString('')

    setIsSearchExceeded(false)

    // if(props.dataFrom === 'manageShipment'){
    //   props.setPage!(1)
    //   props.setCurrent!(1)
    // }
  }

  const formatDate = (date:Moment, type?: string) => {
    if(type === 'start') {
      return  moment(date).startOf('day').format()
    } else {
      return moment(date).endOf('day').format();
    }
  }

  const removeUnderscore = (status: string) => {
    return status.replaceAll('_',' ').toLowerCase()
  }

  const onChangeDate = (data: any) => {
    if(data) {
      let startDate = formatDate(data[0], 'start')
      let endDate = formatDate(data[1], 'end')
      setStartDate(startDate)
      setEndDate(endDate)
      // props.setDate_from!(startDate)
      // props.setDate_to!(endDate)
    }
  }

  // useEffect(() => {
  //   // const b2cId = auth.user.b2c_id
  //   // if(marvableB2cId.includes(b2cId)){
  //   //   setServiceListDropdown((prevState) => ({...prevState, ...MarvableServiceTypeList} ));
  //   // }
  //   // if(pickupExpressB2cId.includes(b2cId)){
  //   //   setServiceListDropdown((prevState) => ({...prevState, ...PickupExpressServiceTypeList}));
  //   // }
  //   // if(pickupExpressPriorityB2cId.includes(b2cId)){
  //   //   setServiceListDropdown((prevState) => ({...prevState, ...PickupExpressPriorityServiceTypeList}));
  //   // } 
  // },[auth.user.b2c_id])

  const maxSearchInputs = (type: string) => {
    if(type === 'tracking_no') {
      if(trackingNo.length > 0) {
        if(trackingNo.includes(',')) {
          let v = trackingNo.split(',')
          if(v.length > 10) {
            setIsSearchExceeded(true)
            return message.error('Please search less than 10 Tracking Numbers, click on Reset button to clear the search', 10)
          } else {
            setIsSearchExceeded(false)
          }
        } else {
          let v = trackingNo.split(' ')
          if(v.length > 10) {
            setIsSearchExceeded(true)
            return message.error('Please search less than 10 Tracking Numbers, click on Reset button to clear the search', 10)
          } else {
            setIsSearchExceeded(false)
          }
        }
      }
    }
    
    if(type === 'shipper_id') {
      if(shipperOrderId.length > 0) {
        if(shipperOrderId.includes(',')) {
          let v = shipperOrderId.split(',')
          if(v.length > 10) {
            setIsSearchExceeded(true)
            return message.error('Please search less than 10 Shipper Order IDs, click on Reset button to clear the search', 10)
          } else {
            setIsSearchExceeded(false)
          }
        } else {
          let v = shipperOrderId.split(' ')
          if(v.length > 10) {
            return message.error('Please search less than 10 Shipper Order IDs, click on Reset button to clear the search', 10)
          } else {
            setIsSearchExceeded(false)
          }
        }
      }
    }
    
  }

  // if(props.hasData) {
  return (
    <Row gutter={[8,8]}>
      <Col span={24}>
        <Row gutter={8}>
          <Col span={props.status==='' ? 8 : 11}>
            <Tooltip title={<><span>Search multiple Tracking numbers(maximum 10 TNs) in the format</span> <strong>TN1 TN2 TN3</strong></>} placement="topLeft">
              <Input id="searchId" placeholder={'Tracking number(s)'} value={trackingNo} onChange={(event) => onChangeHandler(event.target.value, 'tracking_no')} onBlur={() => maxSearchInputs('tracking_no')} suffix={<SearchOutlined style={{fontSize: '18px', color: '#808080'}}/>}/>
            </Tooltip>
            
          </Col>
          {/*Shipper Order Id*/}
          {props.status !== 'draft'?
            <Col span={props.status ==='' ? 8 : 10}>
              <Tooltip title={<><span>Search multiple Shipper Order IDs(maximum 10 IDs) in the format</span> <strong>ID1 ID2 ID3</strong></>} placement="topLeft">
                <Input id="searchId" placeholder={'Shipper Order Id'} value={shipperOrderId} onChange={(event) => onChangeHandler(event.target.value, 'shipper_order_id')} onBlur={() => maxSearchInputs('shipper_id')} suffix={<SearchOutlined style={{fontSize: '18px', color: '#808080'}}/>}/>
              </Tooltip>
              
            </Col>: null
          }
          {/*Order Status*/}
          {props.status===''?
            <Col span={5}>
              <Select
                mode="multiple"
                onChange={(value: any) => onChangeHandler(value, 'order_status')}
                onFocus={() => {
                  return (
                    setOpen(true),
                    setOpenPickup(false),
                    setOpenDestination(false)
                    // setOpenService(false)
                    // setOpenCreatedBy(false)
                  )}
                }
                dropdownClassName={'custom-font'}
                className={"select-wrapper"}
                allowClear
                showArrow
                open={open}
                value={orderStatus}
                onBlur={()=>{onDropdownClear('order_status')}}
                style={{ width: '100%'}}
                placeholder="Order Status"
                maxTagCount={1}
              >
                {
                  Object.entries(OrderStatusListForFreight).map(([id, status]) => {
                    return (
                      <Option key={id} value={id} style={{textTransform: 'capitalize'}}>{removeUnderscore(status)}</Option>
                    )
                  })
                }
              </Select>
            </Col>: null
          }
        </Row>
      </Col>

      <Col span={24}>
        <Row gutter={8}>
          {/*Pickup Country*/}
          <Col span={4}>
            <Select
              mode="multiple"
              onChange={(value: any) => onChangeHandler(value, 'pickup')}
              onFocus={() => {
                return (
                  setOpen(false),
                  setOpenPickup(true),
                  // setOpenCreatedBy(false),
                  setOpenDestination(false)
                  // setOpenService(false)
                )}
              }
              bordered
              className={"select-wrapper"}
              onBlur={()=>{onDropdownClear('pickup')}}
              open={openPickup}
              showArrow
              allowClear
              value={pickupCountry}
              style={{ width: '100%'}}
              placeholder="Origin Country"
              maxTagCount={1}
            >
              {
                getAllCountriesData && getAllCountriesData.data?.map((item: any) => {
                  return <Option key={item} value={item}>{item}</Option>
                })
              }
            </Select>
          </Col>

          {/*Destination Country*/}
          <Col span={4}>
            <Select
              mode="multiple"
              onChange={(value: any) => onChangeHandler(value, 'destination')}
              onFocus={() => {
                return (
                  setOpen(false),
                  setOpenPickup(false),
                  // setOpenCreatedBy(false),
                  setOpenDestination(true)
                  // setOpenService(false)
                )}
              }
              className={"select-wrapper"}
              onBlur={()=>{onDropdownClear('destination')}}
              open={openDestination}
              showArrow
              allowClear
              value={destinationCountryCode}
              style={{ width: '100%'}}
              placeholder="Destination Country"
              maxTagCount={1}
            >
              {
                getAllCountriesData && getAllCountriesData.data?.map((item: any) => {
                  return <Option key={item} value={item}>{item}</Option>
                })
              }
            </Select>
          </Col>

          {/*Service Type*/}
          {/* <Col span={4} 
            // className={props.status.length > 0 ? '' : 'range-picker-wrapper-manage'}
          >
            <Select
              mode="multiple"
              onChange={(value: any) => onChangeHandler(value, 'service_type')}
              onFocus={() => {
                return (
                  setOpenService(true),
                  setOpen(false),
                  setOpenPickup(false),
                  setOpenDestination(false)
                )}
              }
              dropdownClassName={'custom-font'}
              className={"select-wrapper"}
              allowClear
              showArrow
              // onBlur={()=>{setOpenDropdown({openPickup: false, openDestination: false, openTracking: false})}}
              open={openService}
              value={serviceType}
              onBlur={()=>{onDropdownClear('service_type')}}
              style={{ width: '100%'}}
              placeholder="Service Type"
              maxTagCount={1}
            >
              {
                Object.entries(serviceListDropdown).map(([id, serviceType]) => {
                  return (
                    <Option key={id} value={id} style={{textTransform: 'capitalize'}}>{serviceType}</Option>
                  )
                })
              }
            </Select>
          </Col> */}

          {/*Date*/}
          {props.status !== 'draft' &&
            <Col span={8}>
              {/* <span>Date of Creation</span> */}
              <RangePicker
                style={{width: '100%'}}
                value={[startDate ? moment(startDate) : moment(formatDateDefaultFn('start')), endDate ? moment(endDate) :  moment(formatDateDefaultFn('end'))]}
                onFocus={() => {
                  return (
                    setOpen(false),
                    setOpenPickup(false),
                    // setOpenCreatedBy(false),
                    setOpenDestination(false)
                    // setOpenService(false)
                  )}
                }
                // showTime
                onChange={onChangeDate}
                allowClear={false}
              />
            </Col>
          }

          <Col span={5}>
            <Row gutter={8}>
              <Col span={12}>
                <Button type='primary' onClick={() => setFiltersOnSubmit()} style={{width: '100%'}} disabled={isSearchExceeded}>Search</Button>
              </Col>
              <Col span={12}>
                <Button type='default' onClick={() => resetFilters()} style={{width: '100%'}}>Reset</Button>
              </Col>

            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
  // }
  // return null
  
};

export default FreightFilters