import React, { useState } from 'react'
import {Row, Col, Card, Steps} from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {CaretLeftOutlined} from '@ant-design/icons'
import { EscalateTNCard, EscalateTNCardTitle } from './Component/EscalateTNCard';
import { EscalateSupportingInformation, EscalateSupportingInformationTitle } from './Forms/EscalateSupportingInformation';

const {Step} = Steps;
const Escalate = () => {
  const [current] = useState(1)
  const location = useLocation();
  const {tracking_no}: any = location.state

  console.log('escalate', tracking_no)
  const steps = [
    {
      title: <EscalateTNCardTitle/>,
      content: <EscalateTNCard
        tracking_no={tracking_no}/>
    },
    {
      title: <EscalateSupportingInformationTitle/>,
      content: <EscalateSupportingInformation tracking_no={tracking_no} />
    }
  ]

  return (
    <>
      <Steps direction='vertical' current={current}>
        {steps.map((item, index) =>( 
          <Step 
            key={index} 
            title={item.title} 
            description={item.content} /> ))}
      </Steps>
    </>
  )
}


const EscalateSubmission = () => {
  return (
    <Row align='middle' justify='center'>
      <Col span={24} className={'claim-container'}>
        <Link to={'/claims?status=claim_tracking'}>
          <p><CaretLeftOutlined/> Escalations</p>
        </Link>
        <Card className='claim-card'>
          <Escalate/>
        </Card>
      </Col>
    </Row>
  )
}

export default EscalateSubmission