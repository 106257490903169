import { useLanguage } from '../languages/Language';
import {Image} from 'antd';
import order_success_image from '../assets/images/order_success_image.png';
import manage_shipment_image_print_single_label from '../assets/images/manage_shipments_image.png';
import manage_shipment_image_print_multiple_label from '../assets/images/manage_shipments_table.png';
import print_multiple_labels_image from '../assets/images/print_multiple_labels_image.png';
import select_checkboxes_image from '../assets/images/select_checkboxes_image.png';
import edit_order_image from '../assets/images/edit_order_image.png';
import receipt_image from '../assets/images/receipt_image.png';
import rate_calc_image from '../assets/images/rate_calc_image.png';
import price_estimate_image from '../assets/images/price_estimate_image.png';
import fragile1 from '../assets/images/fragile1.png';
import fragile2 from '../assets/images/fragile2.png';
// @ts-ignore
import singleOrderPickupCreationVideo from '../assets/videos/Single_order_creation.mp4';
// @ts-ignore
import bulkOrderCreationVideo from '../assets/videos/Bulk_order_creation.mp4';
// @ts-ignore
import singleOrderDropoffCreationVideo from '../assets/videos/Single_Order_dropoff.mp4';
import { DROP_OFF_SERVICE_SUPPORT_EMAIL } from "../commons/utils/constants";
// @ts-ignore

const CLAIM_REDIRECT_URL = "https://formfaca.de/sm/s7aCfkBKL"
export const CLAIM_SOP = "https://docs.google.com/document/d/1R45jxaYkDrxjnMWjtTDesFP6l3gU0X_zYak18W1m00k/edit"
export const OrderRelated = () => {
  let { getText } = useLanguage()
  return [
    {
      "header": getText('Printing Single Label'),
      "content": <p>
        <ol>
          <li key={1}>
            <p>{getText("Click on")} "{getText("Go to Manage Shipments to Print Label")}".</p>
            <Image style={{paddingBottom:'40px'}} width={800} preview={false} src={order_success_image} />
          </li>
          <li key={2}>
            <p>{getText("Click on")} "{getText("Print Shipment Label")}" {getText("to download and print the label")}.</p>
            <Image style={{paddingBottom:'40px'}} width={800} preview={false} src={manage_shipment_image_print_single_label} />
          </li>
          <li key={3}>
            <p>{getText("Paste the Label on the outer package parcel")}.</p>
            <Image style={{paddingBottom:'40px'}} width={800} preview={false} src={receipt_image} />
          </li>
        </ol>
      </p>
    },
    {
      "header": getText('Printing Multiple Labels together'),
      "content": <p>
        <ol>
          <li key={1}>
            <p>{getText("Go to")} "{getText("Manage Shipments")}" {getText("table")}.</p>
            <Image style={{paddingBottom:'40px'}} width={800} preview={false} src={manage_shipment_image_print_multiple_label} />
          </li>
          <li key={2}>
            <p>{getText("Select the checkbox for the orders you want to print labels together and click on")} "{getText("Print Label")}" {getText("as highlighted below")}.</p>
            <Image style={{paddingBottom:'40px'}} width={800} preview={false} src={select_checkboxes_image} />
          </li>
          <li key={3}>
            <p>{getText("Click on")} "{getText("Print Label")}" {getText("to confirm printing multiple labels together")}.</p>
            <Image style={{paddingBottom:'40px'}} width={800} preview={false} src={print_multiple_labels_image} />
          </li>
          <li key={4}>
            <p>{getText("Download the label from the new tab, Print and paste on the correct parcel")}.</p>
          </li>
        </ol>
      </p>
    },
    {
      "header": getText('Not able to Print Label'),
      "content": <p>
        <ol>
          <li key={1}>
            <p>{getText("Please drop an email to")} <a href={'mailto:clientsupport@janio.asia'} style={{color: '#050593'}}>clientsupport@janio.asia</a> {getText("or create a ticket by clicking on")} "{getText("Help")}" {getText("as highlighted on the right bottom corner, select the issue as")} "{getText("Print Label not working")}" {getText("with details of tracking ID for faster resolution")}.</p>
          </li>
          <li key={2}>
            <p>{getText("Retry Print Label in half hour")}.</p>
          </li>
        </ol>
      </p>
    },
    {
      "header": getText('Edit Order'),
      "content": <p>
        <ol>
          <li key={1}>
            <p>{getText("Go to")} "{getText("Manage Orders")}" {getText("on left side panel")}.</p>
          </li>
          <li key={2}>
            <p>{getText("Click on")} "{getText("Edit Order")}" {getText("as displayed")}.</p>
            <Image style={{paddingBottom:'40px'}} width={800} preview={false} src={edit_order_image} />
          </li>
          <li key={3}>
            <p>{getText("Make modifications to the order")}.</p>
          </li>
          <li key={4}>
            <p>{getText("Complete payment (if any)")}.</p>
          </li>
          <li key={5}>
            <p>{getText("Print New Label for the modified order and paste on package")}.</p>
          </li>
          <li key={6}>
            <p>
              {getText("In case you are facing any issues with the process, please drop an email to")} <a href={'mailto:clientsupport@janio.asia'} style={{color: '#050593'}}>clientsupport@janio.asia</a> {getText("or create a ticket by clicking on")} "{getText("Help")}" {getText("as highlighted on the right bottom corner")}.
            </p>
          </li>
        </ol>
      </p>
    },
    {
      "header": getText('Cancel Order'),
      "content": <p>
        <ol>
          <li key={1}>
            <p>{getText("Please drop an email to")} <a href={'mailto:clientsupport@janio.asia'} style={{color: '#050593'}}>clientsupport@janio.asia</a> {getText("or create a ticket by clicking on")} "{getText("Help")}" {getText("as highlighted on the right bottom corner")}.</p>
          </li>
        </ol>
      </p>
    },
    {
      "header": getText('RateCard'),
      "content": <p>
        <ol>
          <li key={1}>
            <p>{getText("Click on")} "{getText("Rate Calculator")}" {getText("on left panel")}.</p>
          </li>
          <li key={2}>
            <p>{getText("Enter Source, Destination and Parcel weight information")}.</p>
            <Image style={{paddingBottom:'40px'}} width={800} preview={false} src={rate_calc_image} />
          </li>
          <li key={3}>
            <p>{getText("Click on")} "{getText("Get Price Estimate")}".</p>
            <Image style={{paddingBottom:'40px'}} width={800} preview={false} src={price_estimate_image} />
          </li>
          <li key={4}>
            <p>{getText("In case you are looking for a complete ratecard, please drop an email to")} <a href={'mailto:clientsupport@janio.asia'} style={{color: '#050593'}}>clientsupport@janio.asia</a> {getText("or create a ticket by clicking on")} "{getText("Help")}" {getText("as highlighted on the right bottom corner")}.</p>
          </li>
        </ol>
      </p>
    }
  ]
}

export const PackagingRelated = () => {
  let { getText } = useLanguage()
  return [
    {
      "header": getText('Packaging guideline'),
      "content": <p>
        <ol>
          <li key={1}>
            <p>{getText("Please find the packaging guideline")} <a href={"https://janio.asia/articles/packaging-101-how-to-package-products-for-delivery/"} target="_blank" rel='noopener noreferrer'>{getText("here")}</a>.</p>
          </li>
        </ol>
      </p>
    },
    {
      "header": getText('Download Fragile sticker'),
      "content": <p>
        <a href={fragile2} download="fragile1.png">
          <img width={250} src={fragile2} alt="fragile1"></img>
        </a>
        <a href={fragile1} download="fragile2.png">
          <img width={300} src={fragile1} alt="fragile2"></img>
        </a>
      </p>
    },
    {
      "header": getText('Ship Parcel without Label?'),
      "content": <p>
        <ol>
          <li key={1}>
            <p>{getText("No, our pickup executive will not be able to identify the parcel without a label and the parcel will not be picked up")}.</p>
          </li>
        </ol>
      </p>
    }
  ]
}

export const PickupRelated = () => {
  let { getText } = useLanguage();
  return [
    {
      "header": getText('Not able to Print Label'),
      "content": <p>
        <ol>
          <li key={1}>
            <p>{getText("Raise a CS ticket by using the")} "{getText("Help")}" {getText("widget at bottom right corner")}.</p>
          </li>
          <li key={2}>
            <p>{getText("Select the issue as")} "{getText("Print Label")}" {getText("not working")}.</p>
          </li>
          <li key={3}>
            <p>{getText("Provide Tracking ID for faster resolution")}.</p>
          </li>
          <li key={4}>
            <p>{getText("Retry Print Label in half hour")}.</p>
          </li>
        </ol>
      </p>
    },
    {
      "header": getText('What time will the driver come for pickup?'),
      "content": <p>
        <ol>
          <li key={1}>
            <p>{getText("Order pickup time: 9am to 7pm on working days only")}.</p>
          </li>
        </ol>
      </p>
    },
    {
      "header": getText("I missed the pickup driver, when will Janio pickup my parcel?"),
      "content": <p>
        <ol>
          <li key={1}>
            <p>{getText("In case of missed pickup, the order will be attempted next working day")}.</p>
          </li>
        </ol>
      </p>
    },
    {
      "header": getText("Can I schedule pickup on weekends and holidays?"),
      "content": <p>
        <ol>
          <li key={1}>
            <p>{getText("Order pickup time: 9am to 7pm on working days only")}.</p>
          </li>
        </ol>
      </p>
    },
    {
      "header": getText("Can I send parcels without pasting the Janio label?"),
      "content": <p>
        <ol>
          <li key={1}>
            <p>{getText("Our Pickup executives will not collect the parcel without the label securely pasted on the parcel. Please print the label and paste securely on parcel to receive correct update on order tracking status")}.</p>
          </li>
        </ol>
      </p>
    },
    {
      "header": getText("The pickup executive collected my parcel but the order status says pickup failed/pickup pending"),
      "content": <p>
        <ol>
          <li key={1}>
            <p>{getText("Our systems might take a couple of hours to reflect the order status. In case the status of order is not update within 24 hours, please drop an email to")} <a href={'mailto:clientsupport@janio.asia'} style={{color: '#050593'}}>clientsupport@janio.asia</a> {getText("or create a ticket by clicking on")} "{getText("Help")}" {getText("as highlighted on the right bottom corner with details of tracking ID for faster resolution")}.</p>
          </li>
        </ol>
      </p>
    },
    {
      "header": getText("Can the Pickup executive bring packaging material for my parcel?"),
      "content": <p>
        <ol>
          <li key={1}>
            <p>{getText("We currently do not provide packing/unpacking of parcels at doorstep")}.</p>
          </li>
        </ol>
      </p>
    }
  ]
}

export const DeliveryRelated = () => {
  let { getText } = useLanguage()
  return [
    {
      "header": getText('I want to select delivery time slot for my customer'),
      "content": <p>
        <ol>
          <li key={1}>
            <p>{getText("Janio doesn’t offer this service currently")}.</p>
          </li>
          <li key={2}>
            <p>{getText("Order delivery time: 9am to 10pm on working days only")}.</p>
          </li>
        </ol>
      </p>
    },
    {
      "header": getText('Order status is marked as delivered but customer complained they did not receive parcel'),
      "content": <p>
        <ol>
          <li key={1}>
            <p>{getText("Please drop an email to")} <a href={'mailto:clientsupport@janio.asia'} style={{color: '#050593'}}>clientsupport@janio.asia</a> {getText("or create a ticket by clicking on")} "{getText("Help")}" {getText("as highlighted on the right bottom corner with details of tracking ID for faster resolution")}.</p>
          </li>
        </ol>
      </p>
    },
    {
      "header": getText('Parcel is stuck for long, there is no update on order status'),
      "content": <p>
        <ol>
          <li key={1}>
            <p>{getText("Please drop an email to")} <a href={'mailto:clientsupport@janio.asia'} style={{color: '#050593'}}>clientsupport@janio.asia</a> {getText("or create a ticket by clicking on")} "{getText("Help")}" {getText("as highlighted on the right bottom corner with details of tracking ID for faster resolution")}.</p>
          </li>
        </ol>
      </p>
    },
    {
      "header": getText("I want to provide special instructions for parcel delivery"),
      "content": <p>
        <ol>
          <li key={1}>
            <p>{getText("Please drop an email to")} <a href={'mailto:clientsupport@janio.asia'} style={{color: '#050593'}}>clientsupport@janio.asia</a> {getText("or create a ticket by clicking on")} "{getText("Help")}" {getText("as highlighted on the right bottom corner with details of tracking ID for faster resolution")}.</p>
          </li>
        </ol>
      </p>
    }
  ]
}

export const ShippingChargesRelated = () => {
  let { getText } = useLanguage()
  return [
    {
      "header": 'Why am I being charged Pickup Fee?',
      "content": <p>
        <ol>
          <li key={1}>
            <p>Effective from 1st Feb 2022, 6 SGD pickup fee is applicable if the number of parcels handed over to Janio's Pickup Partner is below 5.</p>
          </li>
        </ol>
      </p>
    },
    {
      "header": 'How can I avoid Pickup fee?',
      "content": <p>
        <ol>
          <li key={1}>
            <p>If you want to ship less than 5 parcels, please choose our Drop-off Service and select your nearest drop location to avoid Pickup fee.</p>
          </li>
        </ol>
      </p>
    },
    {
      "header": 'I created more than 5 orders but I was charged 6 SGD on my first order, when will I get refund?',
      "content": <p>
        <ol>
          <li key={1}>
            <p>Janio will re-evaluate the pickup attempt fee the next working day if you successfully handed over at least 5 shipments on your scheduled pickup date.</p>
          </li>
        </ol>
      </p>
    },
    {
      "header": getText('How are shipping charges calculated?'),
      "content": <p>
        <ol>
          <li key={1}>
            <p>{getText("Max of weight and volumetric weight of parcel is used to determine the shipping charges")}.</p>
          </li>
        </ol>
      </p>
    },
    {
      "header": getText('How are D&T/VAT/GST applied?'),
      "content": <p>
        <ol>
          <li key={1}>
            <p>{getText("Based on destination country, item value and category, D&T/VAT/GAST is estimated. To get better understanding, continue reading this")} <a href={"https://janio.asia/articles/what-is-de-minimis-value/"} target="_blank" rel='noopener noreferrer'>{getText("article")}</a>.</p>
          </li>
        </ol>
      </p>
    },
    {
      "header": getText('Can my consignee pay for D&T?'),
      "content": <p>
        <ol>
          <li key={1}>
            <p>{getText("We currently do not provide this service")}.</p>
          </li>
        </ol>
      </p>
    }
  ]
}

export const RefundRelated = () => {
  let { getText } = useLanguage();
  return [
    {
      "header": getText('Refund'),
      "content": <p>Related </p>
    }
  ]
}
export const ProhibitedItemList = () => {
  return [
    {
      "header": 'Singapore',
      "content": <a href={"https://docs.google.com/spreadsheets/d/1SWRmJPRDXLcS2h6skwaLF_jJTcVTnkcUxP7QK6qCUX8/edit#gid=656365738"} target="_blank" rel='noopener noreferrer'>Singapore</a>
    },
    {
      "header": 'Indonesia',
      "content": <a href={"https://docs.google.com/spreadsheets/d/1SWRmJPRDXLcS2h6skwaLF_jJTcVTnkcUxP7QK6qCUX8/edit#gid=649434099"} target="_blank" rel='noopener noreferrer'>Indonesia</a>
    },
    {
      "header": 'Malaysia',
      "content": <a href={"https://docs.google.com/spreadsheets/d/1SWRmJPRDXLcS2h6skwaLF_jJTcVTnkcUxP7QK6qCUX8/edit#gid=761681417"} target="_blank" rel='noopener noreferrer'>Malaysia</a>
    },
    {
      "header": 'Thailand',
      "content": <a href={"https://docs.google.com/spreadsheets/d/1SWRmJPRDXLcS2h6skwaLF_jJTcVTnkcUxP7QK6qCUX8/edit#gid=7285386"} target="_blank" rel='noopener noreferrer'>Thailand</a>
    },
    {
      "header": 'Philippines',
      "content": <a href={"https://docs.google.com/spreadsheets/d/1SWRmJPRDXLcS2h6skwaLF_jJTcVTnkcUxP7QK6qCUX8/edit#gid=805150426"} target="_blank" rel='noopener noreferrer'>Philippines</a>
    },
    {
      "header": 'Vietnam',
      "content": <a href={"https://docs.google.com/spreadsheets/d/1SWRmJPRDXLcS2h6skwaLF_jJTcVTnkcUxP7QK6qCUX8/edit#gid=1009850045"} target="_blank" rel='noopener noreferrer'>Vietnam</a>
    },
    {
      "header": 'Hong Kong',
      "content": <a href={"https://docs.google.com/spreadsheets/d/1SWRmJPRDXLcS2h6skwaLF_jJTcVTnkcUxP7QK6qCUX8/edit#gid=1371660821"} target="_blank" rel='noopener noreferrer'>Hong Kong</a>
    },
    {
      "header": 'Taiwan',
      "content": <a href={"https://docs.google.com/spreadsheets/d/1SWRmJPRDXLcS2h6skwaLF_jJTcVTnkcUxP7QK6qCUX8/edit#gid=248435466"} target="_blank" rel='noopener noreferrer'>Taiwan</a>
    },
    {
      "header": 'USA',
      "content": <a href={"https://docs.google.com/spreadsheets/d/1SWRmJPRDXLcS2h6skwaLF_jJTcVTnkcUxP7QK6qCUX8/edit#gid=1316987786"} target="_blank" rel='noopener noreferrer'>USA</a>
    },
    {
      "header": 'Australia',
      "content": <a href={"https://docs.google.com/spreadsheets/d/1SWRmJPRDXLcS2h6skwaLF_jJTcVTnkcUxP7QK6qCUX8/edit#gid=1788377411"} target="_blank" rel='noopener noreferrer'>Australia</a>
    },
    {
      "header": 'Japan',
      "content": <a href={"https://docs.google.com/spreadsheets/d/1SWRmJPRDXLcS2h6skwaLF_jJTcVTnkcUxP7QK6qCUX8/edit#gid=1519024736"} target="_blank" rel='noopener noreferrer'>Japan</a>
    }
  ]
}

export const TutorialVideos = () => {
  return [
    {
      "header": 'Single pickup order creation',
      "content":
        <div>
          <video width="320" height="240" controls>
            <source src={singleOrderPickupCreationVideo} type="video/mp4"></source>
          </video>
        </div>
    },
    {
      "header": 'Single drop-off order creation',
      "content":
         <div>
           <video width="320" height="240" controls>
             <source src={singleOrderDropoffCreationVideo} type="video/mp4"></source>
           </video>
         </div>
    },
    {
      "header": 'Bulk pickup/drop-off order creation',
      "content":
        <div>
          <video width="320" height="240" controls>
            <source src={bulkOrderCreationVideo} type="video/mp4"></source>
          </video>
        </div>
    },
  ]
}

export const DropOffRelated = () => {
  return [
    {
      "header": "I arrived drop-off location but the store is closed",
      "content": <p>
        <ol>
          <li key={1}>
            <p>{"Please drop a message to"} <a href={`mailto:${DROP_OFF_SERVICE_SUPPORT_EMAIL}`} style={{color: '#050593'}}>{DROP_OFF_SERVICE_SUPPORT_EMAIL}.</a></p>
          </li>
        </ol>
      </p>
    },
    {
      "header": "The store in-charge is not accepting my parcel",
      "content": <p>
        <ol>
          <li key={1}>
            <p>{"Please drop a message to"} <a href={`mailto:${DROP_OFF_SERVICE_SUPPORT_EMAIL}`} style={{color: '#050593'}}>{DROP_OFF_SERVICE_SUPPORT_EMAIL}</a></p>
          </li>
        </ol>
      </p>
    },
  ]
}

export const ClaimsRelated = () => {
  return [
    {
      "header": "Submitting a claim",
      "content": <p>
        <ol>
          <li key={1} style={{fontWeight: 'normal'}}>
            <p>{"Please visit"} <a style={{fontSize: "16px", fontWeight: 'bold'}} href={CLAIM_REDIRECT_URL} target="_blank" rel='noopener noreferrer'>here </a> if you wish to submit a claim for your shipment.</p>
          </li>
        </ol>
      </p>
    },
    {
      "header": "Janio Claim Standard Operating Procedure",
      "content": <p>
        <ol>
          <li key={1} style={{fontWeight: 'normal'}}>
            <p>Please find the Claim Standard Operating Procedure (SOP) <a style={{fontSize: "16px", fontWeight: 'bold'}} href={CLAIM_SOP} target="_blank" rel='noopener noreferrer'>here </a> </p>
          </li>
          <li key={2} style={{fontWeight: 'normal'}}>
            <p>This SOP is effective from <strong>December 12, 2022.</strong></p>
          </li>
        </ol>
      </p>
    }
  ]
}