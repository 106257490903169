import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import Balance from '../interfaces/Balance';
import Client from '../interfaces/Client';
import Invoice from '../interfaces/Invoice';
import Statement from '../interfaces/Statement';
import { JANIO_LOGO_BASE64 } from '../assets/JanioBase64';
import { parseAmount, parseBalance, parseCreditedAmountCreditNote, parseCreditedAmountInvoice, parseInvoiceAmount } from './parsing';

pdfMake.fonts = {
  NotoSerif: {
    normal: 'https://cdn.jsdelivr.net/fontsource/fonts/noto-serif-sc@latest/chinese-simplified-400-normal.ttf',
    bold: 'https://cdn.jsdelivr.net/fontsource/fonts/noto-serif-sc@latest/chinese-simplified-700-normal.ttf',
    italics: 'https://cdn.jsdelivr.net/fontsource/fonts/noto-serif@latest/latin-400-italic.ttf',
    bolditalics: 'https://cdn.jsdelivr.net/fontsource/fonts/noto-serif@latest/latin-700-italic.ttf'
  },
};

export const calculateOutstandingBalance = (invoices: Invoice[], currency: string) => {
  let balance = 0;
  for (const invoice of invoices) {
    if (invoice.type === 'CREDIT_NOTE') {
      balance += -(invoice.remainingCredit || 0);
    } else {
      balance += (invoice.amountDue || 0);
    }
  }
  return parseAmount(balance, currency);
};

export const generateStatement = (invoices: Invoice[], currencies: string[], client: Client) => {
  // Seperating invoices based on currency
  const currenciesData: Invoice[][] = [];
  for (const currency of currencies) {
    const result = invoices.filter(({ currencyCode }) => currencyCode === currency);
    currenciesData.push(result);
  }

  const balances: Balance[] = [];
  const statementData: Statement[][] = [];
  for (const currencyData of currenciesData) {
    if (currencyData.length) {
      let balance = 0;
      // Calculation balance due based on currency
      for (const invoice of currencyData) {
        if (invoice.type === 'CREDIT_NOTE') {
          balance += -(invoice.remainingCredit || 0);
        } else {
          balance += (invoice.amountDue || 0);
        }
      }
      balances.push({ currency: currencyData[0].currencyCode, amount: parseAmount(balance, currencyData[0].currencyCode) });
      // Parsing data value
      const result = currencyData.map<Statement>((invoice) => ({
        date: moment(invoice.date).format('DD-MM-YYYY'),
        activity: invoice.type === 'INVOICE' ? `Invoice # ${invoice.invoiceNumber}` : `Credit Note # ${invoice.invoiceNumber}`,
        dueDate: invoice.dueDate ? moment(invoice.dueDate).format('DD-MM-YYYY') : '',
        amount: parseInvoiceAmount(invoice.total, invoice.type,invoice.currencyCode),
        payments: invoice.type === 'INVOICE'
          ? parseCreditedAmountInvoice(invoice.amountCredited || 0, invoice.amountPaid || 0, invoice.currencyCode)
          : parseCreditedAmountCreditNote(invoice.total, invoice.remainingCredit || 0, invoice.currencyCode),
        balance: parseBalance(invoice.amountDue || 0, invoice.remainingCredit || 0, invoice.type, invoice.currencyCode)
      }));
      statementData.push(result);
    }
  }

  // Generating janio logo
  const content = [];
  const logo = { image: JANIO_LOGO_BASE64, width: 100, style: 'image' }
  content.push(logo);

  // Generating client's statement detail
  const statement = {
    layout: 'noBorders',
    table: {
      widths: [250, 80, '*'],
      body: [
        [
          { text: 'STATEMENT OF ACCOUNT', style: 'header2' },
          [
            { text: 'As at:', style: ['bold', 'alignmentLeft'] },
            { text: moment().format('DD MMM YYYY'), style: 'alignmentLeft' }
          ],
          { text: 'Taurus One Private Limited', style: 'alignmentRight' }
        ],
        [
          [
            { text: client.name, style: 'alignmentLeft' },
            { text: client.address.addressLine1, style: 'alignmentLeft' },
            { text: client.address.addressLine2, style: 'alignmentLeft' },
            { text: client.address.postalCode, style: 'alignmentLeft' },
            { text: client.address.country, style: 'alignmentLeft' }
          ],
          [
            { text: 'GST Reg. No:', style: ['bold', 'alignmentLeft'] },
            { text: client.taxNumber, style: 'alignmentLeft' }
          ],
          '',
        ]
      ],
    }
  };
  content.push(statement);

  // Generating table data of currencies
  for (const [index, statements] of statementData.entries()) {
    const body = [
      [
        { text: 'Date', style: ['bold', 'alignmentLeft'] },
        { text: 'Activity', style: ['bold', 'alignmentLeft'] },
        { text: 'Due Date', style: ['bold', 'alignmentLeft'] },
        { text: 'Amount', style: ['bold', 'alignmentRight'] },
        { text: 'Payments', style: ['bold', 'alignmentRight'] },
        { text: `Balance ${balances[index].currency}`, style: ['bold', 'alignmentRight'] },
      ],
      [{ text: `${client.name}`, colSpan: 5 }, '', '', '', '', '']
    ];

    for (const statement of statements) {
      const value = [
        { text: statement.date, style: ['alignmentLeft'] },
        { text: statement.activity, style: ['alignmentLeft'] },
        { text: statement.dueDate, style: ['alignmentLeft'] },
        { text: statement.amount, style: ['alignmentRight'] },
        { text: statement.payments, style: ['alignmentRight'] },
        { text: statement.balance, style: ['alignmentRight'] },
      ];
      body.push(value);
    }

    const table = {
      table: {
        headerRows: 1,
        widths: [45, 150, 45, '*', '*', '*'],
        body,
      },
      layout: {
        hLineWidth: (index: number, node: { table: { body: { length: number } } }) => (index === 1 || index === 2 || index === node.table.body.length) ? 1 : 0,
        vLineWidth: () => 0,
      },
      style: 'table'
    };
    content.push(table);
    const balance = { text: `Balance Due ${balances[index].currency} ${balances[index].amount}`, style: ['header1', 'alignmentRight'] };
    content.push(balance);
  };

  // Defining the pdf file
  const docDefinition = {
    header: { text: moment().format('DD/MM/YYYY, HH:mm'), fontSize: 8, alignment: 'left', margin: [30, 10] },
    footer: (page: number, size: number) => ({ text: `${page}/${size}`, fontSize: 8, alignment: 'right', margin: [30, 0] }),
    content,
    pageSize: 'A4',
    pageMargins: [70, 30, 70, 30],
    defaultStyle: { font: 'NotoSerif', fontSize: 8 },
    styles: {
      bold: {
        bold: true
      },
      alignmentLeft: {
        alignment: 'left'
      },
      alignmentRight: {
        alignment: 'right'
      },
      image: {
        margin: [0, 0, 0, 20],
        alignment: 'right'
      },
      table: {
        margin: [0, 20, 0, 20]
      },
      header1: {
        fontSize: 18,
        bold: true
      },
      header2: {
        fontSize: 16,
        bold: true
      },
    },

  };

  // @ts-ignore
  // Download the pdf
  pdfMake.createPdf(docDefinition).download(`statement_${moment().format('YYYY-MM-DD')}.pdf`);
};
