import React from "react";
import { MERCHANT_API_URL } from "../commons/utils/constants";

import "./styles/IntegrationsList.page.css";
export const DetectPlatformPage = () => {
  const params = new URLSearchParams(window.location.search);

  const queryString = "?hmac=" + params.get("hmac") +
      "&shop=" + params.get("shop") + "&timestamp=" + params.get("timestamp");

  window.location.href = MERCHANT_API_URL + "api/channel/shopify/auth/" + queryString;

  return <h1 style={{padding: "50px 0", textAlign: "center" }}>Redirecting...</h1>
};
