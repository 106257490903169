import {
  Form,
  Button,
  Col,
  Row,
  Select,
  message
} from 'antd';

import {AddressProps, getStates_from_b2c, getCities_from_b2c, getAddressById_from_b2c, updateLocation_in_b2c, deleteLocation_in_b2c, getReturnAddressById_from_b2c, updateReturnLocation_in_b2c, updateConsigneeLocation_in_b2c,  deleteReturnLocation_in_b2c, getConsigneeAddressById_from_b2c} from "../../orders/services/privateApi";
import {countries, countryCodes} from "../../commons/utils/constants";
import {sortResponseAlphabatically} from "../../commons/utils/utilizer" 
import {useAuth} from "../../auth/services/authContext";
import * as Yup from 'yup';
import {Formik, Form as FormikForm, FormikHelpers} from 'formik';
import {FormInput, FormSelect} from "../../orders/components/Forms";
import {useState} from 'react';
import {useMutation, useQuery} from 'react-query';
import {validatePostalCode} from "../../commons/utils/validator";
import { useLanguage } from '../../languages/Language';
import { LabelRadio } from '../../ReUsableComponents/ReUsable'
import { CaretLeftOutlined } from '@ant-design/icons'
import {Link, useParams, useHistory, useLocation} from "react-router-dom";

const {Option} = Select;
const validationSchema = (getText: any) => {
  return Yup.object().shape({
    pickup_point_address: Yup.string().required(getText('Address is required')),
    pickup_point_city: Yup.string().required(getText('Please enter City')).max(50, 'Maximum 50 characters are allowed'),
    pickup_point_email: Yup.string().required(getText('Email is required')).email(getText("Please enter a valid email")).max(50, 'Maximum 50 characters are allowed'),
    pickup_point_number: Yup.string().required(getText('Phone Number is required')).max(50, 'Maximum 50 characters are allowed'),
    pickup_point_contact_person: Yup.string().required(getText('Full Name is required')).max(50, 'Maximum 50 characters are allowed'),
    pickup_point_country: Yup.string().required(getText('Please select Country')),
    // type: Yup.string().required(getText('Please select pickup location type')),
    pickup_point_name: Yup.string().required(getText('Address Label is required')).max(50, 'Maximum 50 characters are allowed'),
    pickup_point_state: Yup.string().required(getText('Please select State')),
    pickup_point_postal: Yup.number().typeError('Postal code must be a number').required(getText('Please enter Postal Code')).test(
      'postal-check',
      (val: any, context: any) => {
        if(!val){
          return context.createError({
            path: context.path,
            message: getText('Please enter Postal code')
          })
        }
        else{
          const error = validatePostalCode(countryCodes[context.parent.country_code],val);
          if(error){
            return context.createError({
              path: context.path,
              message: error
            })
          }
        }
        return true
      }
    ),
    // pickup_point_province: Yup.string().required(getText('Please select State')),
    // pickup_point_notes: Yup.string().required(getText('Please select State')),
  })};


type ServerErrors = {[key: string]: Array<string>};
const combineFieldsErrors = (errors: ServerErrors) => {
  let combined_errors: any = {}
  let initial_touched: any = {}

  for(const [key, val] of Object.entries(errors)){
    combined_errors[key] = val.join(',')
    initial_touched[key] = true
  }

  return {
    combined_errors,
    initial_touched
  }
}

export interface AddressError {
  pickup_point_name?: '',
  pickup_point_address?: '',
  pickup_point_country?: '',
  pickup_point_state?: '',
  pickup_point_city?: '',
  // pickup_point_province?: '',
  pickup_point_postal?: '',
  pickup_point_contact_person?: '',
  pickup_point_number?: '',
  // pickup_point_notes?: '',
  pickup_point_email?: ''
}


interface PropsType {
  countryCode?: string
  formId?: string
  onSuccess?: (id:string) => any
  onCancel?: () => any
  addressType?: string
  onSuccessForReturns?: (location:any) => any
}

export const EditAddress = (props:PropsType) =>{
  const auth = useAuth();
  const params: {id: string} = useParams()
  const history = useHistory()
  const location = useLocation()
  let locationState = location.state as any

  const [country, setCountry] = useState<string>(props.countryCode ? props.countryCode : '');
  const [initialTouched, setInitialTouched] = useState({});
  const [state, setState] = useState<string>('');
  const [freeText, setfreeText] = useState<string>('dropdown');
  const resetFieldValuesOnCountryChange = ['pickup_point_state', 'pickup_point_city'];
  const { getText } = useLanguage();
  
  const fetchAddressDetailByIdFromb2c = useQuery(['fetchAddressDetailByIdFromb2c', params.id], () => {
    return getAddressById_from_b2c(auth.user.secret_key, params.id)
  }, {enabled: locationState.addressType === 'pickup'});

  const fetchReturnAddressByIdFromb2c = useQuery(['fetchReturnAddressByIdFromb2c', params.id], () => {
    return getReturnAddressById_from_b2c(auth.user.secret_key, params.id)
  }, {enabled: locationState.addressType === 'return'});
  
  const fetchConsigneeAddressByIdFromb2c = useQuery(['fetchConsigneeAddressByIdFromb2c', params.id], () => {
    return getConsigneeAddressById_from_b2c(auth.user.secret_key, params.id)
  }, {enabled: locationState.addressType === 'consignee'});

  const statesListFromb2c = useQuery(['statesListFromb2c', country], () => {
    if(country){
      return getStates_from_b2c(country)
    }
  });

  const cityListFromb2c = useQuery(['cityListFromb2c', state], () => {
    if(state){
      return getCities_from_b2c(country, state)
    }
  });
  
  let formInitialValue!: AddressProps;
  if(locationState.addressType === 'pickup') {
    formInitialValue = {
      pickup_point_name: fetchAddressDetailByIdFromb2c.data?.pickup_point_name,
      pickup_point_address: fetchAddressDetailByIdFromb2c.data?.pickup_point_address,
      pickup_point_country: fetchAddressDetailByIdFromb2c.data?.pickup_point_country,
      pickup_point_state: fetchAddressDetailByIdFromb2c.data?.pickup_point_state,
      pickup_point_city: fetchAddressDetailByIdFromb2c.data?.pickup_point_city,
      pickup_point_postal: fetchAddressDetailByIdFromb2c.data?.pickup_point_postal,
      pickup_point_contact_person: fetchAddressDetailByIdFromb2c.data?.pickup_point_contact_person,
      pickup_point_number: fetchAddressDetailByIdFromb2c.data?.pickup_point_number,
      pickup_point_email: fetchAddressDetailByIdFromb2c.data?.pickup_point_email
    }
  }
  if(locationState.addressType === 'return') {
    formInitialValue = {
      pickup_point_name: fetchReturnAddressByIdFromb2c.data?.return_point_name,
      pickup_point_address: fetchReturnAddressByIdFromb2c.data?.address,
      pickup_point_country: fetchReturnAddressByIdFromb2c.data?.country,
      pickup_point_state: fetchReturnAddressByIdFromb2c.data?.state,
      pickup_point_city: fetchReturnAddressByIdFromb2c.data?.city,
      pickup_point_postal: fetchReturnAddressByIdFromb2c.data?.postal,
      pickup_point_contact_person: fetchReturnAddressByIdFromb2c.data?.contact_name,
      pickup_point_number: fetchReturnAddressByIdFromb2c.data?.contact_number,
      pickup_point_email: fetchReturnAddressByIdFromb2c.data?.contact_email
    }
  }
  if(locationState.addressType === 'consignee') {
    formInitialValue = {
      pickup_point_name: fetchConsigneeAddressByIdFromb2c.data?.name,
      pickup_point_address: fetchConsigneeAddressByIdFromb2c.data?.address,
      pickup_point_country: fetchConsigneeAddressByIdFromb2c.data?.country,
      pickup_point_state: fetchConsigneeAddressByIdFromb2c.data?.state,
      pickup_point_city: fetchConsigneeAddressByIdFromb2c.data?.city,
      pickup_point_postal: fetchConsigneeAddressByIdFromb2c.data?.postal,
      pickup_point_contact_person: fetchConsigneeAddressByIdFromb2c.data?.contact_name,
      pickup_point_number: fetchConsigneeAddressByIdFromb2c.data?.contact_number,
      pickup_point_email: fetchConsigneeAddressByIdFromb2c.data?.contact_email
    }
  }
  

  const updateLocation = useMutation(async (value: AddressProps) => updateLocation_in_b2c(value, auth.user.secret_key, params.id))

  const updateReturnLocation = useMutation(async (value: AddressProps) => updateReturnLocation_in_b2c(value, auth.user.secret_key, params.id))

  const updateConsigneeLocation = useMutation(async (value: AddressProps) => updateConsigneeLocation_in_b2c(value, auth.user.secret_key, params.id))

  const deleteLocation = useMutation(async () => deleteLocation_in_b2c(auth.user.secret_key, params.id))

  const deleteReturnLocation = useMutation(async () => deleteReturnLocation_in_b2c(auth.user.secret_key, params.id))

  const onFormSubmit = async (value: AddressProps, {setErrors}: FormikHelpers<AddressProps>) => {
    if(locationState.addressType === 'pickup') {
      updateLocation.mutate(value, {
        onSuccess: (response: any) => {
          message.success('Pickup Address Updated Successfully');
        },
        onError: (error: any) => {
          const {combined_errors, initial_touched} = combineFieldsErrors(error.response.data)
          setErrors(combined_errors)
          setInitialTouched(initial_touched)
          message.error(<p>Something went wrong. Please reach out to us at <a href={'mailto:clientsupport@janio.asia'}><strong>clientsupport@janio.asia</strong></a> for more help.</p>, 10)
        }
      })
    }
    if(locationState.addressType === 'return') {
      updateReturnLocation.mutate(value, {
        onSuccess: (response: any) => {
          message.success('Return Address Updated Successfully');
        },
        onError: (error: any) => {
          const {combined_errors, initial_touched} = combineFieldsErrors(error.response.data)
          setErrors(combined_errors)
          setInitialTouched(initial_touched)
          message.error(<p>Something went wrong. Please reach out to us at <a href={'mailto:clientsupport@janio.asia'}><strong>clientsupport@janio.asia</strong></a> for more help.</p>, 10)
        }
      })
    }
    if(locationState.addressType === 'consignee') {
      updateConsigneeLocation.mutate(value, {
        onSuccess: (response: any) => {
          message.success('Consignee Address Updated Successfully');
        },
        onError: (error: any) => {
          const {combined_errors, initial_touched} = combineFieldsErrors(error.response.data)
          setErrors(combined_errors)
          setInitialTouched(initial_touched)
          message.error(<p>Something went wrong. Please reach out to us at <a href={'mailto:clientsupport@janio.asia'}><strong>clientsupport@janio.asia</strong></a> for more help.</p>, 10)
        }
      })
    }
  }

  const deleteClicked = () => {
    if(locationState.addressType === 'pickup') {
      deleteLocation.mutate(undefined, {
        onSuccess: (response: any) => {
          message.success('Address Successfully Deleted')
          history.push('/settings/locations')
        },
        onError: (error: any) => {
          message.error(<p>Something went wrong. Please reach out to us at <a href={'mailto:clientsupport@janio.asia'}><strong>clientsupport@janio.asia</strong></a> for more help.</p>, 10)
        }
      })
    }
    if(locationState.addressType === 'return') {
      deleteReturnLocation.mutate(undefined, {
        onSuccess: (response: any) => {
          message.success('Address Successfully Deleted')
          history.push('/settings/locations')
        },
        onError: (error: any) => {
          message.error(<p>Something went wrong. Please reach out to us at <a href={'mailto:clientsupport@janio.asia'}><strong>clientsupport@janio.asia</strong></a> for more help.</p>, 10)
        }
      })
    }
    
  }

  const setFreeTextCallback = (val:string) => {
    setfreeText(val);
  };

  return (
    <Col span={24}>
      <Row  align="middle" justify="center">
        <Col span={18}>
          <Link to={"/settings/locations"}>
            <p className="go-back-btn" style={{margin: '0px 0px -10px 0px'}}><CaretLeftOutlined/ > {getText('Back')} </p>
          </Link>
        </Col>
      </Row>
      <Row  align="middle" justify="center">
        <Col span={18} style={{marginTop:24, padding: '20px'}} className={"single-order-form-container ant-form-vertical add-sub-account"}>
          <h2 style={{margin: '-25px 0 10px 0', fontWeight: 'bold', fontSize: '18px'}}>{`Update ${locationState.addressType === 'pickup' ? 'Pickup' : locationState.addressType === 'return' ? 'Return' : 'Consignee'} Address`}</h2>
          <Formik
            initialValues={formInitialValue}
            validationSchema={validationSchema(getText)}
            initialTouched={initialTouched}
            enableReinitialize={true}
            onSubmit={onFormSubmit}
            validateOnChange={false}
          >
            { formik => (
              <div className={"ant-form-vertical"}>
                <FormikForm>
                  <Row gutter={14} style={{marginBottom: '15px'}}>
                    <Col span={12}>
                      {/* <FormInput label={`${props.addressType.charAt(0).toUpperCase() + props.addressType.slice(1)} ${getText('Address Label')}`} name="pickup_point_name" wrapperClass={"no-margin-item"} /> */}
                      <FormInput label={` ${getText('Address Label')}`} name="pickup_point_name" wrapperClass={"no-margin-item"} />
                      
                      <Form.Item label={getText('Name will be displayed under address selection')} className={'form-item-info'}>
                      </Form.Item>
                    </Col>

                    {/* <Col span={12}>
                      <FormSelect label={getText('Type')} name="type" disabled={true}>
                        <Option key={'FORWARD'} value={'FORWARD'}>{'FORWARD'}</Option>
                        <Option key={'RETURN'} value={'RETURN'}>{'RETURN'}</Option>
                      </FormSelect>
                    </Col> */}
                  </Row>
                  <Row gutter={14} style={{marginBottom: '15px'}}>
                    <Col span={12}>
                      <FormInput label={getText('Full Name')} name="pickup_point_contact_person" />
                    </Col>
                    <Col span={12}>
                      <FormInput label={getText('Phone Number')} name="pickup_point_number" />
                    </Col>
                  </Row>
                  <FormInput label={getText('Email')} name="pickup_point_email" />
                  <FormInput label={getText('Address')} name="pickup_point_address" />
                  <Row gutter={14} style={{marginBottom: '15px'}}>
                    <Col span={12}>
                      <FormSelect label={getText('Country')} name="pickup_point_country" onChangeCallback={(country_code:string) => {setCountry(country_code); resetFieldValuesOnCountryChange.forEach(field => formik.setFieldValue(field, ''))}}>
                        {
                          Object.entries(countries)
                            .filter(([key]) => props.countryCode ? key === countryCodes[props.countryCode] : true)
                            .map(([key]) => (
                              <Option key={key} value={countries[key]}>{countries[key]}</Option>
                            ))
                        }
                      </FormSelect>
                    </Col>
                    <Col span={12}>
                      <FormSelect label={getText('State')} name="pickup_point_state" loading={statesListFromb2c.isLoading} onChangeCallback={(state:string) => {
                        setState(state);
                        formik.setFieldValue('pickup_point_city', '');
                      }}>
                        {
                          (sortResponseAlphabatically(statesListFromb2c?.data, 'state_name') || [])
                            .filter((item: {state_id:number, state_name: string}) => (
                              country === 'TH'?
                                (item.state_name === 'Nonthaburi' || item.state_name === 'Bangkok' || item.state_name === 'Samut Prakan')
                                : (item.state_id > 0)
                            ))
                            .map((item: {state_id:number, state_name: string} ) => (
                              <Option key={item.state_id} value={item.state_name}>{item.state_name}</Option>
                            ))
                        }
                      </FormSelect>
                      {statesListFromb2c.isError && <p style={{color:'red', marginBottom:0}}>{getText("Failed to fetch states, try later")}</p>}
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col span={24}>
                      <FormInput label={'Province'} name="pickup_point_province" />
                    </Col>
                  </Row> */}
                  <Row gutter={14} style={{marginBottom: '15px'}}>
                    <Col span={12}>
                      {
                        formik.values.pickup_point_country === 'US' ?
                          freeText === 'dropdown' ? 
                            <>
                              <FormSelect label={<LabelRadio setFreeTextCallback={setFreeTextCallback} freeText={freeText} />}
                                notFoundContent={<p style={{marginBottom: '0px', color: '#525050'}}>City not found. Please select Other</p>} name="pickup_point_city" loading={cityListFromb2c.isLoading}
                                placeholder={'Select a city'}>
                                {
                                  (sortResponseAlphabatically(cityListFromb2c?.data, 'city_name') || [])
                                    .map((item: {city_id:number, city_name: string, display_name: string} ) => (
                                      <Option key={item.city_id} value={item.city_name}>{item.display_name}</Option>
                                    ))
                                }
                              </FormSelect>
                              {cityListFromb2c.isError && <p style={{color:'red', marginBottom:0}}>Failed to fetch cities, try later</p>}
                            </>
                            : 
                            <FormInput placeholder='Please type in the city' label={<LabelRadio setFreeTextCallback={setFreeTextCallback} freeText={freeText} />} name="pickup_point_city" /> : 
                          <FormSelect label={getText('City')} name="pickup_point_city" loading={cityListFromb2c.isLoading}>
                            {
                              (sortResponseAlphabatically(cityListFromb2c?.data, 'city_name') || [])
                                .map((item: {city_id:number, city_name: string, display_name: string} ) => (
                                  <Option key={item.city_id} value={item.city_name}>{item.display_name}</Option>
                                ))
                            }
                          </FormSelect>
                      }
                    </Col>
                    <Col span={6} style={{marginTop: formik.values.pickup_point_country === 'US' ? '35px' : '0px'}}>
                      <FormInput label={getText('Postal code')} name="pickup_point_postal" />
                    </Col>
                  </Row>
                  <Row gutter={14} style={{marginBottom: '15px'}}>
                    <Col>
                      {/* <Button onClick={props.onCancel}>Cancel</Button> */}
                      <Button type="primary" htmlType="submit" className={"blue-button"} loading={updateLocation.isLoading || updateReturnLocation.isLoading || updateConsigneeLocation.isLoading} style={{marginLeft: '10px'}}>
                        {'Edit address'}
                      </Button>
                      {locationState.addressType !== 'consignee' && <Button type="primary" danger onClick={deleteClicked} className={"blue-button"} loading={deleteLocation.isLoading} style={{marginLeft: '10px', backgroundColor: '#dc3545 !important'}}>
                        {'Delete address'}
                      </Button>}
                    </Col>
                  </Row>
                </FormikForm>
              </div>
            )}
          </Formik>
        </Col>
      </Row>
    </Col>
    
  );
};
