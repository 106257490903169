import React, { useState, useRef } from 'react'
import {Row, Col, Button, Card, Checkbox, message} from 'antd';
import * as Yup from 'yup';
import {Formik, Form as FormikForm} from 'formik';
import { useMutation } from 'react-query';
import { FormInput, DatePicker, UploadFile } from '../../orders/components/Forms';

import { useB2BOrderContext } from "../b2bOrderCreationContext"
import { fileUpload, removeUploadedFile } from '../../orders/services/privateApi';
// import { useQuery } from 'react-query';
import { useAuth } from '../../auth/services/authContext';
import { getFormatedDate, parseDate } from '../../commons/utils/utilizer';
import { 
  // disabledDate, 
  disabledDateForB2B } from '../../orders/helpers';
import { validateFileSize, validateFileTypeXlsx } from '../../commons/utils/validator';
interface PropTypes {
  current: number,
  setCurrentState: (val: number) => void,
}

interface FormValues {
  goods_value: string | number,
  goods_ready_date: any,
  hazardous_good: boolean,
  manifest_type_id: string,
  manifest_type_name: string,
  commercial_invoice_id: string,
  commercial_invoice_name: string,
}

const validationSchema = (props: any) => {
  return (
    Yup.object().shape({
      goods_value: Yup.string().required('Please Input goods value in USD'),
      goods_ready_date: Yup.date().required('Please select Shipment ready date').typeError('Please enter valid shipment ready date'),
      manifest_type_name: Yup.mixed().required('Please add Manifest file'),
      commercial_invoice_name: Yup.mixed().required('Please add Commercial Invoice file')
    })
  )
}

export const GoodsDetailsForm = (props: PropTypes) => {
  const auth = useAuth()
  const orderContextData = useB2BOrderContext()
  const fileType = useRef('')
  const [isHazardousGood, setIsHazardousGood] = useState<boolean>(false)
  const [uploadManifestUrl, setUploadManifestUrl] = useState<any>([])
  const [uploadCommercialInvoiceUrl, setUploadCommercialInvoiceUrl] = useState<any>([])

  const initialValues = {
    goods_value: orderContextData.b2bOrderData.goods_value,
    goods_ready_date: parseDate(orderContextData.b2bOrderData.goods_ready_date),
    hazardous_good: orderContextData.b2bOrderData.hazardous_good,
    manifest_type_id: orderContextData.b2bOrderData.manifest_type_id,
    manifest_type_name: orderContextData.b2bOrderData.manifest_type_name,
    commercial_invoice_id: orderContextData.b2bOrderData.commercial_invoice_id,
    commercial_invoice_name: orderContextData.b2bOrderData.commercial_invoice_name
  }

  const onFormikSubmit = (values: FormValues) => {
    let shipment_rady_date = getFormatedDate(values.goods_ready_date)
    let updateData = {
      commercial_invoice_id: uploadCommercialInvoiceUrl[0].toString(),
      goods_ready_date: shipment_rady_date,
      goods_value: values.goods_value,
      hazardous_good: isHazardousGood,
      manifest_type_id: uploadManifestUrl[0].toString(),
      manifest_type_name: values.manifest_type_name,
      commercial_invoice_name: values.commercial_invoice_name
    }
    orderContextData.updateB2BOrderData(updateData)

    props.setCurrentState(4)
  }

  const fileUploadQuery:any =  useMutation(async (file: any) => {
    return fileUpload(file, auth.user.secret_key);
  },  {
    onSuccess: (val) => {
      if(fileType.current === 'manifest_type_name') {
        setUploadManifestUrl([...uploadManifestUrl, val.id])
      }
      if(fileType.current === 'commercial_invoice_name') {
        setUploadCommercialInvoiceUrl([...uploadCommercialInvoiceUrl, val.id])
      }
      message.success('File uploaded successfully')
    },
    onError: (error: any) => {
      message.error('error in uploading. Please again later')
    },
  })

  const fileRemoveQuery:any = useMutation(async (id: any) => {
    return removeUploadedFile(auth.user.secret_key, id);
  },  {
    onSuccess: (val: any) => {
      message.success('File removed successfully')
    },
    onError: (error: any) => {
      message.error('error in uploading. Please again later')
    },
  })

  const onFileAddition = (event: any, formik: any, type: string) => {
    const isValidFileType = validateFileTypeXlsx(event)
    const isValidFileSize = validateFileSize(event)
    if(!isValidFileType){
      message.error('Only .xlsx and .pdf files are accepted.')
    }
    if(!isValidFileSize){
      message.error('Each file needs to be less than 10mb.')
    }
    if(isValidFileType && isValidFileSize){
      fileType.current = type;
      formik.setFieldValue(type, event.name)
      fileUploadQuery.mutate(event)
    }
    return false
  }

  const onFileRemove = (event: any, type: string, formik: any) => {
    formik.setFieldValue(type, '')
    fileType.current = type;
    let get_remove_id;
    if(type === 'manifest_type_name') {
      get_remove_id = uploadManifestUrl[0].toString()
      setUploadManifestUrl([])
    }
    if(type === 'commercial_invoice_name') {
      get_remove_id = uploadCommercialInvoiceUrl[0].toString()
      setUploadCommercialInvoiceUrl([])
    }

    fileRemoveQuery.mutate(get_remove_id)

  }

  if (props.current > 3) {
    return (
      <>
        {<Card title={'Goods Overview'} className={"form-summary-container"} style={{fontSize: '19px'}}>
          <p style={{ margin: 0 }} className={'gray-text'}>Goods Value: <span style={{color: '#000', fontSize: '17px'}}>{orderContextData.b2bOrderData.goods_value!}</span></p>
          <p className={'gray-text'}>Shipment Ready Date: <span style={{color: '#000', fontSize: '17px'}}>{orderContextData.b2bOrderData.goods_ready_date}</span></p>
          <p className={'gray-text'}>Is Hazardous Goods: <span style={{color: '#000', fontSize: '17px'}}>{orderContextData.b2bOrderData.hazardous_good.toString()}</span></p>
        </Card>}
      </>
    )
  }
  return (
    <Row className='claim-main-container single-order-form-container ant-form-vertical'>
      <Col span={24}>
        <Formik 
          initialValues={initialValues}
          onSubmit={onFormikSubmit}
          validationSchema={validationSchema}
        >
          {formik => {
            return (
              <FormikForm className='dispute-main-container'>
                <Col span={24}>
                  <Row gutter={32}>
                    <Col span={5}>
                      <Row>
                        <Col span={24}>
                          <FormInput required name={'goods_value'} label={"Value (USD)"} inputProps={{type: 'number', min: 0, step:1.00}} placeholder='Please input value in USD' />
                        </Col>
                        <Col span={24} style={{alignItems: 'center', display: 'flex'}}>
                          <Checkbox onChange={(e: any) => setIsHazardousGood(e.target.checked)} name='hazardous_good' checked={isHazardousGood}><strong>Shipment contains hazardous goods?</strong></Checkbox>
                        </Col>
                      </Row>
                      
                    </Col>

                    <Col span={5}>
                      <DatePicker
                        required
                        disabledDate={disabledDateForB2B}
                        showToday={false} 
                        name="goods_ready_date" 
                        label={"Shipment Ready by?"}
                        wrapperClass="no-margin-item"
                      />
                    </Col>
                    <Col span={4}>
                      <UploadFile
                        accept=".xlsx,.pdf"
                        required
                        wrapperClass={`${uploadManifestUrl.length > 0 ? 'uploaded_manifest_file' : ''}`}
                        label={'Manifest'}
                        name={'manifest_type_name'} 
                        beforeUpload={(e) => onFileAddition(e, formik, 'manifest_type_name')}
                        defaultFileList= {orderContextData.b2bOrderData.manifest_type_name.length > 0 ? 
                          [
                            {
                              uid: '1',
                              size: 0,
                              name: orderContextData.b2bOrderData.manifest_type_name,
                              type: '',
                            }
                          ] : []
                        }
                        showRemove
                        showUploadList
                        onRemove={(e) => onFileRemove(e, 'manifest_type_name', formik)}
                      />
                    </Col>
                    <Col span={4}>
                      <UploadFile
                        accept=".xlsx,.pdf"
                        required
                        wrapperClass={`${uploadCommercialInvoiceUrl.length > 0 ? 'uploaded_manifest_file' : ''}`}
                        label={'Commercial Invoice'} 
                        name={'commercial_invoice_name'} 
                        beforeUpload={(e) =>onFileAddition(e, formik, 'commercial_invoice_name')}
                        showRemove
                        defaultFileList= {orderContextData.b2bOrderData.commercial_invoice_name.length > 0 ? 
                          [
                            {
                              uid: '1',
                              size: 0,
                              name: orderContextData.b2bOrderData.commercial_invoice_name,
                              type: '',
                            }
                          ] : []
                        }
                        showUploadList
                        onRemove={(e) => onFileRemove(e, 'commercial_invoice_name', formik)}
                      />
                    </Col>
                  </Row>

                  {props.current === 3 &&
                    <div className={`button-block`}>
                      <Button htmlType="submit" type="primary" className={"blue-button"} disabled={fileUploadQuery.isLoading}>
                        {'Save & Continue'}
                      </Button>
                    </div>
                  }
                </Col>
              </FormikForm>
            )
          }
          }
        </Formik>
      </Col>
    </Row>
  )

}

export const GoodsDetailsFormTitle = (props: PropTypes) => {
  return (
    <div className={"title-block"}>
      <p className={'step-title'}>{'Tell us about your goods'}</p>
      {props.current > 3 ? <p onClick={() => props.setCurrentState(3)} className={"edit-text"}>{'Edit details'}</p> : ''}
    </div>
  )
}