import {
  Card,
  Row,
  Col,
  Steps,
  Spin,
  Alert,
  Button
} from 'antd'
import React, {useEffect, useState} from 'react';
import './styles/ReturnOrderForm.css';
import {Link, useLocation} from "react-router-dom";
import {CaretLeftOutlined} from '@ant-design/icons'
import { useQuery } from "react-query";
import {useAuth} from "../auth/services/authContext";
import { useParams } from 'react-router-dom';
import { UploadOrderForm, UploadOrderFormTitle } from "./components/bulkOrder/UploadOrderForm";
import { ReceiverDetailsForm, ReceiverDetailsFormTitle } from "./components/bulkOrder/ReceiverDetailsForm"
import { PriceEstimateForm, PriceEstimateFormTitle } from "./components/bulkOrder/PriceEstimateForm";
import { MakePaymentForm, MakePaymentFormTitle } from "./components/bulkOrder/MakePayment";
import { BulkOrderContextProvider } from './services/returnBulkOrderContext'
import { getBulkOrderPriceEstimatesByBatchId, getErrosByBatchId, get_batch_orders } from '../orders/services/privateApi';
  
const {Step} = Steps;
  
interface LocationState {
  renderOnlyEstimates?: boolean,
  renderErrors?: boolean
}
const OrderForm = () => {
  const location = useLocation()
  const authToken = useAuth().user.authToken;
  const params = useParams<any>();
  const [current, setCurrent] = useState(3);
  const [progress, setProgress] = useState(0);
  const setCurrentState = (val: number) => {
    setCurrent(val);
  };
  const bulkOrderRates = useQuery(['getBulkOrderRates', params.batch_no], async () => {
    if(location.state) {
      if((location.state as any).renderOnlyEstimates) {
        return getBulkOrderPriceEstimatesByBatchId(authToken, params.batch_no)
      }
      if((location.state as any).renderErrors) {
        return getErrosByBatchId(authToken, params.batch_no)
      }
    }
    return get_batch_orders(authToken, params.batch_no)
  }, {cacheTime: 0, retry: false});

  const orderFormat = (rates: any) => {
    return {...rates}
  };

  const steps = [
    {
      title: <UploadOrderFormTitle current={current} setCurrentState={setCurrentState} dataFilled={1}/>,
      content: <UploadOrderForm current={current} setCurrentState={setCurrentState} dataFilled={1}/>,
    },
    {
      title: <ReceiverDetailsFormTitle current={current} setCurrentState={setCurrentState} dataFilled={1}/>,
      content: <ReceiverDetailsForm current={current} setCurrentState={setCurrentState} dataFilled={1}/>,
    },
    {
      title: <PriceEstimateFormTitle current={current} setCurrentState={setCurrentState} dataFilled={1} renderPriceEstimateData={location.state ? true : false} />,
      content: <PriceEstimateForm current={current} setCurrentState={setCurrentState} dataFilled={1} renderPriceEstimateData={location.state ? true : false}/>,
    },
    {
      title: <MakePaymentFormTitle current={current} setCurrentState={setCurrentState}/>,
      content: <MakePaymentForm current={current} setCurrentState={setCurrentState} isEditing/>,
    }
  ];

  useEffect(() => {
    if (current > progress) {
      setProgress(current);
    }
  }, [current, progress]);

  const parseValidationErrors = (data:any) =>{
    
    let errObject = data[0].orders ? data[0].orders : data
    if(errObject) {
      return Object.values(errObject).map((item: any) => {
        if(typeof(item) == 'string'){
          return (
            <ul>
              <li>{item}</li>
            </ul>
          )
        }
        return (
          <ul>
            <span>For Tracking Number: <strong>{item.tracking_no}</strong></span>
            {
              Object.entries(item).map((y: any) => {
                return <li><span>{y[0]}:</span> <q>{y[1]}</q></li>
              })
            }
          </ul>
        )
      })
    }
  };
  const noOrdersError = (data: any) => {
    return (
      <>
        <p>{data} </p>
        <p>OR</p>
        <p>There might be some issue with order processing. Please click on Create button to create new order</p>
        <Button type="primary">
          <Link to={{ pathname: `/orders/create/bulk`}}>Create</Link>
        </Button>
      </>
    )
  }
  if(location.state && (location.state as LocationState).renderErrors) {
    if(bulkOrderRates.data && bulkOrderRates.data.error) {
      return (
        <div className={'bulk_upload_errors'}>
          <h3>Errors:</h3>
          {parseValidationErrors(bulkOrderRates.data.error)}
        </div>        
      )
    }
    return <Spin style={{'position': 'absolute','left': '50%', 'top': '50%'}} />
  }

  if(bulkOrderRates.isLoading) {
    return <Spin style={{'position': 'absolute','left': '50%', 'top': '50%'}} />
  }else if(bulkOrderRates.data) {
    return <BulkOrderContextProvider orders={orderFormat(bulkOrderRates.data)} renderOnlyEstimates={location.state ? true : false}>
      {
        current === 3 ? 
          <Link to={"/orders?status=draft"}>
            <p className="go-back-btn"><CaretLeftOutlined/> Manage Shipments </p>
          </Link> : 
          <Link to={"/orders?status=bulkUpload"}>
            <p className="go-back-btn"><CaretLeftOutlined/> Back to Bulk Upload Status </p>
          </Link>
      }
      <Steps direction="vertical" current={current} className={"form-progress-bar"}>
        {steps.map((item, index) => (
          <Step key={index} title={item.title}
            description={(current >= index || progress >= index) ? item.content : ''}/>
        ))}
      </Steps>
    </BulkOrderContextProvider>
  }else {
    let val:any = bulkOrderRates.error
    return (
      <div className="bulk-payment-error">
        <Alert
          message="Error in Bulk Order"
          description={noOrdersError(val.response.data.error)
            // val.response.data.error + ' Or you might be using duplicate tracking numbers'
          }
          type="error"
          showIcon
        />
      </div>
    )
  }
};

export const PayReturnBulkOrder = () => {
  return (
    <Row align="middle" justify="center">
      <Col span={16} className={'order-form-container'}>
        <Card className={'form-card'}>
          <OrderForm/>
        </Card>
      </Col>
    </Row>
  )
};
  