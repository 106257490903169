import { Checkbox, Col, Row, Button } from 'antd'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
// import { useB2BOrderContext } from '../b2bOrderCreationContext'

interface PropTypes {
  current: number,
  setCurrentState: (val: number) => void,
}

export const TermsAndConditions = (props: PropTypes) => {
  const history = useHistory()
  // const orderContextData = useB2BOrderContext()
  const [isTermsChecked, setIsTermsChecked] = useState(false)

  const bookShipment = () => {
    // orderContextData.b2bOrderData
    history.push('/orders/success/b2b-freight')
  }
  return (
    <Row className='claim-main-container single-order-form-container ant-form-vertical'>
      <Col span={24}>
        <Row>
          <Col span={4}>
            <span style={{marginTop: '10px', fontWeight: 'bold'}}>Selling Rate per KG:</span>
          </Col>
          <Col span={3}>
            <span style={{marginTop: '10px'}}>10</span>
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <span style={{marginTop: '10px', fontWeight: 'bold'}}>Estimated rate (USD):</span>
          </Col>
          <Col span={3}>
            <span style={{marginTop: '10px'}}>55</span>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{alignItems: 'center', display: 'flex', marginTop: '10px'}}>
            <Checkbox onChange={(e: any) => setIsTermsChecked(e.target.checked)} name='terms_and_condition' checked={isTermsChecked}>Agreement, Terms and Conditions for Buyers and Privacy Policy</Checkbox>
          </Col>
          <Col span={12}>
            {props.current === 5 && <div className={`button-block`}>
              <Button htmlType="submit" type="primary" className={"blue-button"} onClick={bookShipment} disabled={!isTermsChecked}>
                {'Confirm & Book'}
              </Button>
            </div>}
          </Col>
        </Row>
        
      </Col>
    </Row>
  )
}

export const TermsAndConditionsTitle = (props: PropTypes) => {
  return (
    <div className={"title-block"}>
      <p className={'step-title'}>{'Confirm & Book'}</p>
    </div>
  )
}