import { Card, Col, Image, Row, Spin } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { UserViewedManageShipmentsPage } from '../../commons/mixpanel'
import home_toShip from '../../assets/icons/home_toShip.svg';
import home_readyToShip from '../../assets/icons/home_readyToShip.svg';
import home_returnToSender from '../../assets/icons/home_returnToSender.svg';
import home_exception from '../../assets/icons/home_exception.svg'

interface ShipmentProps{
  b2cSummaryData: any
}

const AttentionShipment = (props: ShipmentProps) => {
  const {b2cSummaryData} = props;
  const {data, isLoading} = b2cSummaryData;
  return (
    <Row gutter={[16,16]} className={'summary-container'}>
      {data && <Col span={6} className={'ant-col-row-2'}>
        <Link to={'/orders?status=EXCEPTION'} onClick={() => UserViewedManageShipmentsPage('Exception')}>
          <Card className={'active-shipment-card'}>
            <Row>
              <Col className={'icon-background'}>
                <Image className='active-shipment-icon' preview={false} src={home_exception} />
              </Col>
              <Col>
                {data ? <>
                  <p className={'active-shipment-count'}>{data?.exception || 0}</p>
                  <p className={'shipment-summary-type'}>{'Exception'}</p></>
                  : <Spin spinning={isLoading}/>}
                
              </Col>
            </Row>
          </Card>
        </Link>
      </Col>}
      {data && <Col span={6} className={'ant-col-row-2'}>
        <Link to={'/orders?status=RETURNED_TO_SENDER'} onClick={() => UserViewedManageShipmentsPage('RETURNED_TO_SENDER')}>
          <Card className={'active-shipment-card'}>
            <Row>
              <Col className={'icon-background'}>
                <Image className='active-shipment-icon' preview={false} src={home_returnToSender} />
              </Col>
              <Col>
                {data ? <>
                  <p className={'active-shipment-count'}>{data?.returned || 0}</p>
                  <p className={'shipment-summary-type'}>{'Returned'}</p></>
                  : <Spin spinning={isLoading}/>}
                
              </Col>
            </Row>
          </Card>
        </Link>
      </Col>}
      {data?.to_ship! > 0 && <Col span={6} className={'ant-col-row-2'}>
        <Link to={'/orders/integrated-shipments?status=notReady'} onClick={() => UserViewedManageShipmentsPage('toship')}>
          <Card className={'active-shipment-card'}>
            <Row>
              <Col className={'icon-background'}>
                <Image className='active-shipment-icon' preview={false} src={home_toShip} />
              </Col>
              <Col>
                {data ? <>
                  <p className={'active-shipment-count'}>{data?.to_ship! || 0}</p>
                  <p className={'shipment-summary-type'}>{"To Ship"}</p></>
                  : <Spin spinning={isLoading}/>}
                
              </Col>
            </Row>
          </Card>
        </Link>
      </Col>}
      {data?.ready_to_ship! > 0 && <Col span={6} className={'ant-col-row-2'}>
        <Link to={'/orders/integrated-shipments?status=ready'} onClick={() => UserViewedManageShipmentsPage('readytoship')}>
          <Card className={'active-shipment-card'}>
            <Row>
              <Col className={'icon-background'}>
                <Image className='active-shipment-icon' preview={false} src={home_readyToShip} />
              </Col>
              <Col>
                {data ? <>
                  <p className={'active-shipment-count'}>{data?.ready_to_ship! || 0}</p>
                  <p className={'shipment-summary-type'}>{"Ready To Ship"}</p></>
                  : <Spin spinning={isLoading}/>}
                
              </Col>
            </Row>
          </Card>
        </Link>
      </Col>}
      
      {/* {!isProduction && 
        <Col span={6}>
          <Link to={'/orders?status=on_hold'} onClick={() => UserViewedManageShipmentsPage('onhold')}>
            <Card className={'active-shipment-card'}>
              <Row>
                <Col className={'icon-background'}>
                  <Image className='active-shipment-icon' preview={false} src={home_onHold} />
                </Col>
                <Col>
                  {getFlaggedOrderDataQuery.data ? <>
                    <p className={'active-shipment-count'}>{getFlaggedOrderDataQuery.data.meta?.count || 0}</p>
                    <p className={'shipment-summary-type'}>{'On Hold'}</p></>
                    : <Spin spinning={summaryFromb2c.isLoading}/>}
                  
                </Col>
              </Row>
            </Card>
          </Link>
        </Col>    
      }    */}
    </Row>
  )
}

export default AttentionShipment