import {
  Form as F,
  Button,
  Col,
  Row,
  Select,
  Divider,
  Card,
  message,
  Alert,
} from 'antd';
import '../../styles/SingleOrderForm.css';
import React, {useEffect, useState, useRef} from "react";
import {useMutation} from "react-query";
import {Formik, FieldArray, Form, FormikHelpers, Field } from 'formik';
import {useAuth} from "../../../auth/services/authContext";
import {useSingleOrderB2C, ItemsB2C, OrderError, formatItemErrorsFromB2C
} from "../../services/singleOrderContext";
import {ItemCategoriesFromB2C, countryCurrencies, mostUsedCurrencies,  GoodsListSheetIds, countries, contactCustomerService,countryCodeCurrencies, countryCodes} from "../../../commons/utils/constants";
import {FormInput, FormSelect, RadioGroup, TitleErrorMarker} from '../Forms';
import {parseErrors} from '../../helpers';
import * as Yup from 'yup';
import {SingleOrderCreate} from "../../../commons/mixpanel"
import {validateVolumetricWeight} from "../../../commons/utils/validator";
import {fetch_b2c_price_estimates, B2CPriceEstimates, B2CPriceEstimatesResponse} from "../../../orders/services/privateApi";
import {useRouteLeavingGuard} from "../../../commons/components/RouteGuard/routeGuardContext";
import { useLanguage } from '../../../languages/Language';
import ItemProductUrlModal from '../../ItemProductUrlModal';
import { verifyItemUrl } from '../../fileHelpers';
// @ts-ignore
import prohibitedItemsToIndonesia from  '../../../assets/static/prohibitedItemsToIndonesia.pdf'
// @ts-ignore
import prohibitedItemsToVietnam from '../../../assets/static/prohibitedItemsToVietnam.pdf'

const { Option, OptGroup } = Select;
interface PropsType {
  current: number,
  setCurrentState: (val:number)=> void,
  dataFilled:number,
  isMarketplaceOrder?: boolean,
  setRefetchQuery?: (val: boolean)=> void
}

interface OrderProps {
  items: ItemsB2C[]
  order_weight: number | null
  order_length: number | null
  order_width: number | null
  order_height: number | null
  isCod?: string
  cod_amt_to_collect_currency?: string
  cod_amt_to_collect?: number | string
  incoterm?: string
  custom_tn?: any
  special_instruction?: string
}

const ItemForm = ({isDomestic, remove, index, contextData, marketPlaceOrder}: {isDomestic: boolean, remove: () => any, index: number, contextData: any, marketPlaceOrder?: boolean}) => {
  let { getText } = useLanguage();
  return (
    <>
      <FormInput name={`items.[${index}].item_desc`} required label={getText('Item Description')}/>

      <Row gutter={14}>
        <Col span={12}>
          <FormSelect name={`items.[${index}].item_category`} required label={getText('Item Category')} placeholder={'Please select item category'}>
            {ItemCategoriesFromB2C.map(item => (
              <Option key={item} value={item}>{item}</Option>
            ))}
          </FormSelect>
        </Col>

        <Col span={12}>
          <Row gutter={14}>
            <Col span={8}>
              <FormInput name={`items.[${index}].item_quantity`} required label={getText('Quantity')} inputProps={{type: 'number', min: 1, step:1}}/>
            </Col>
            <Col span={8}>
              <FormSelect name={`items.[${index}].item_price_currency`} required label={getText('Currency')} showSearch={"true"}>
                <>
                  <OptGroup label="Most Used">
                    {mostUsedCurrencies.map(item => (
                      <Option key={item} value={item}>{item}</Option>
                    ))}
                  </OptGroup>
                  <OptGroup label="All">
                    {countryCurrencies.map(item => (
                      <Option key={item} value={item}>{item}</Option>
                    ))}
                  </OptGroup>
                </>
              </FormSelect>
            </Col>
            <Col span={8}>
              <FormInput name={`items.[${index}].item_price_value`} required label={<label className='text-wrap' title={'Per Item Value'}>{getText('Per Item Value')}</label>} inputProps={{type: 'number', min: marketPlaceOrder ? 0 : 1, step:0.01}} />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={14}>
        <Col span={12}>
          <FormInput name={`items.[${index}].product_id`} label={getText('Product ID (Optional)')}/>
        </Col>
        <Col span={12}>
          <FormInput name={`items.[${index}].item_url`} label={getText('Product URL (Optional)')}/>
        </Col>
      </Row>

      <Row gutter={14}>
        <Col span={12}>
          <FormInput name={`items.[${index}].item_sku`} label={getText('Item SKU (Optional)')}/>
        </Col>

        {
          ((contextData.singleOrder.consignee_country === 'Singapore' && contextData.singleOrder.pickup_country === 'Singapore' && contextData.singleOrder.serviceType === 'Customs clearance and last mile') || (contextData.singleOrder.pickup_country !== 'Singapore' && contextData.singleOrder.consignee_country === 'Singapore')) && 
          <Col span={12}>
            <Row gutter={24}>
              <Col span={12}>
                <FormInput name={`items.[${index}].gst_ovr`} label={<label className='text-wrap' title={'GST/OVR (Optional)'}>GST/OVR (Optional)</label>}/>
              </Col>
              <Col span={12} id={'gst-wrapper'}>
                <div id="gst-radio-group" className='text-wrap' title={'GST Collected? (Optional)'}>GST Collected? (Optional)</div>
                <div role="group" aria-labelledby="gst-radio-group" className='gst-radio-wrapper'>
                  <Col span={24}>
                    <Row>
                      <Col span={12}>
                        <label>
                          <Field type="radio" name={`items.[${index}].gst_collected`} value={'false'} />
                          No
                        </label>
                      </Col>
                      <Col span={12}>
                        <label>
                          <Field type="radio" name={`items.[${index}].gst_collected`} value={'true'} />
                          Yes
                        </label>
                      </Col>
                    </Row>
                  </Col>
                </div>
              </Col>
            </Row>
          </Col>
        }
      </Row>

      {index > 0 && <div className={"button-block"}>
        <Button type="primary" onClick={remove} className={"no-border-btn"}>
          {getText('Remove item')}
        </Button>
      </div>
      }
    </>)
}

const empty_item = {
  item_desc: '',
  item_category: '',
  item_quantity: '',
  item_price_currency: '',
  item_price_value: '',
  item_product_id: '',
  item_sku: '',
  item_url: '',
  gst_ovr: '',
  gst_collected: false
}

const parseOrderContextError = (error: OrderError) => ({
  items: error.items,
  order_weight: error.order_weight,
  order_length: error.order_length,
  order_height: error.order_height,
  order_width: error.order_width,
  custom_tn: error.custom_tn
})

const validationSchema = (getText:any, client_billing: string) => {
  return Yup.object().shape({
    order_weight: Yup.string().nullable().required(getText('Weight is required')).test(
      'volumetric_weight',
      (val: any, context: any) => {
        const result = validateVolumetricWeight({weight:val, length:context.parent.order_length, width:context.parent.order_width, height:context.parent.order_height}, client_billing);
        if(result && result === 'weight'){
          message.error(<p>{getText("Your parcel exceeds our volumetric weight. Please reach out to us at")} <a href={'mailto:clientsupport@janio.asia'}>clientsupport@janio.asia</a> {getText("should you wish to ship with us.")}</p>, 5);
          return context.createError({
            path: context.path,
            message: getText('Exceeded volumetric weight')
          })
        }
        return true
      }
    ),
    order_length: Yup.string().nullable().required(getText('Length is required')).test(
      'volumetric_weight',
      (val: any, context: any) => {
        const result = validateVolumetricWeight({weight:context.parent.order_weight, length:val, width:context.parent.order_width, height:context.parent.order_height}, client_billing);
        if(result && result === 'volumetric weight'){
          message.error(<p>{getText("Your parcel exceeds our volumetric weight. Please reach out to us at")} <a href={'mailto:clientsupport@janio.asia'}>clientsupport@janio.asia</a> {getText("should you wish to ship with us.")}"</p>, 5);
          return context.createError({
            path: context.path,
            message: getText('Exceeded volumetric weight')
          })
        }
        return true
      }
    ),
    order_width: Yup.string().nullable().required(getText('Width is required')).test(
      'volumetric_weight',
      (val: any, context: any) => {
        const result = validateVolumetricWeight({weight:context.parent.order_weight, length:context.parent.order_length, width:val, height:context.parent.order_height}, client_billing);
        if(result && result === 'volumetric weight'){
          return context.createError({
            path: context.path,
            message: getText('Exceeded volumetric weight')
          })
        }
        return true
      }
    ),
    order_height: Yup.string().nullable().required(getText('Height is required')).test(
      'volumetric_weight',
      (val: any, context: any) => {
        const result = validateVolumetricWeight({weight:context.parent.order_weight, length:context.parent.order_length, width:context.parent.order_width, height:val}, client_billing);
        if(result && result === 'volumetric weight'){
          return context.createError({
            path: context.path,
            message: getText('Exceeded volumetric weight')
          })
        }
        return true
      }
    ),
    custom_tn: Yup.string().nullable().notRequired().when(
      ['custom_tn'], {
        is: (value: any) => value?.length,
        then: (rule) => {
          return rule.matches(
            /^[a-zA-Z0-9-]*$/,
            'Custom Tracking numbers must be alphanumeric (a-z, A-Z, 0-9, hyphen(-))'
          )
        }
      }
    ).max(30),
    items: Yup.array().of(Yup.object().shape({
      item_desc: Yup.string().nullable().required(getText('Item Description is required')),
      item_category: Yup.string().nullable().required(getText('Item Category is required')),
      item_quantity: Yup.number().integer('Quantity must be an Integer').nullable().required(getText('Item Quantity is required')),
      // item_price_currency: Yup.string().nullable().required(getText('Required')),
      item_price_currency: Yup.string().nullable().required(getText('Required')).test(
        'item_price_currency',
        (val: any, context: any) => {
          if(!mostUsedCurrencies.includes(val)){
            return context.createError({
              path: context.path,
              message: `${val} is currently not a supported currency`
            })
          }
          return true
        }
      ),
      item_price_value: Yup.string().nullable().required(getText('Required')),
      item_url: Yup.string().nullable().max(200,'Cannot be more than 200 characters'),
      gst_ovr: Yup.string().nullable().max(200,'Cannot be more than 200 characters'),
      gst_collected: Yup.boolean(),
      special_instruction: Yup.string(),
    })),
  }, 
  // cyclic dependency
  [['custom_tn', 'custom_tn']])
}

export const ItemDetailsForm = (props:PropsType) =>{
  let { getText } = useLanguage();
  const auth = useAuth();
  // const orderID = useParams<any>()
  const singleOrderContext = useSingleOrderB2C();
  
  const isCustomTNPermission = JSON.parse(localStorage.getItem('allow_custom_tracking_no')!)
  const routeContext = useRouteLeavingGuard();
  const {current, setCurrentState, dataFilled} = props;
  const [orderData, setOrderData] = useState<OrderProps | null>(singleOrderContext.singleOrder);
  const [showItemProductUrlModal, setShowItemProductUrlModal] = useState(false)
  const isDomestic = singleOrderContext.singleOrder.consignee_country === singleOrderContext.singleOrder.pickup_country;
  const consigneeCurrency = countryCodeCurrencies[countryCodes[singleOrderContext.singleOrder.consignee_country!]]
  const initialValues: any = {
    items: singleOrderContext.singleOrder.items,
    order_weight: singleOrderContext.singleOrder.order_weight,
    order_length: singleOrderContext.singleOrder.order_length,
    order_height: singleOrderContext.singleOrder.order_height,
    order_width: singleOrderContext.singleOrder.order_width,
    incoterm: singleOrderContext.singleOrder.incoterm === 'DDU' ? 'DDU':'DDP',
    cod_amt_to_collect_currency: consigneeCurrency,
    cod_amt_to_collect: singleOrderContext.singleOrder.cod_amt_to_collect,
    isCod: singleOrderContext.singleOrder.payment_type === 'cod'?'true':'false',
    custom_tn: singleOrderContext.singleOrder.tracking_no,
    special_instruction: singleOrderContext.singleOrder.special_instruction
  }
  
  const ItemDetailsFormRef: any = useRef();
  const resetFormikValues = () => {
    if (ItemDetailsFormRef.current) {
      ItemDetailsFormRef.current.setFieldValue('isCod', 'false')
      ItemDetailsFormRef.current.setFieldValue('cod_amt_to_collect_currency', '')
      ItemDetailsFormRef.current.setFieldValue('cod_amt_to_collect', null)
    }
  }
  const {initialErrors, initialTouched, emptyError, hasError} = parseErrors(singleOrderContext.errors, parseOrderContextError)
  useEffect(() => {
    resetFormikValues()
  }, [singleOrderContext.singleOrder.consignee_country]) // eslint-disable-line react-hooks/exhaustive-deps

  const errData = (errors: any) => {
    let errorsList: Array<any> = [errors];
    if(errors.hasOwnProperty("items")){
      const itemErrors = formatItemErrorsFromB2C(errors)
      delete errors["items"];
      itemErrors.map((itemError:any) => {
        return errorsList.push(itemError);
      })
    }
    let errorList = <ul style={{textAlign: 'left'}}>
      {
        errorsList.map((error) =>
          Object.values(error).map((val:any, index: number) => {
            return <li key={index} style={{fontSize: '12px',color: '#4f4a4a', fontWeight: 600}}>{(Object.keys(error)[index]).replace(/_/g, ' ')}: {val} </li>
          })
        )
      }
    </ul>
    return message.error(
      <span>Error: Please correct the following details and try again. {errorList}</span>,
      10
    )
  }

  const validateSingleOrder_shopify = useMutation(async (value:any) => {
    return singleOrderContext.UpdateSingleOrderB2C_Shopify()
  })

  const validateSingleOrder = useMutation(async (value:any) => {
    return singleOrderContext.UpdateSingleOrderB2C()
  })

  const fetchPriceEstimatesInB2cSingleOrder = useMutation(async () => {
    let formData = singleOrderContext.singleOrder
    let service_type;
    if(formData.serviceType === 'Customs clearance and last mile') {
      service_type = 'CC_LM'
    } else if(formData.serviceType === 'Pickup Express') {
      service_type = 'Pickup_Express'
    } else if(formData.serviceType === 'Dropoff Express') {
      service_type = 'Dropoff_Express'
    } else {
      service_type = formData.serviceType
    }
    const data: (B2CPriceEstimates) = {
      pickup_country: formData.pickup_country,
      consignee_country: formData.consignee_country,
      consignee_state: formData.consignee_state,
      order_weight: formData.order_weight,
      order_height: formData.order_height,
      order_length: formData.order_length,
      order_width: formData.order_weight,
      pickup_state: formData.pickup_state,
      currency: singleOrderContext.singleOrder.pickup_country === 'Indonesia' && singleOrderContext.singleOrder.consignee_country === 'Indonesia' ? 'IDR' : countryCodeCurrencies[auth.user.billingCountry],
      service_type: service_type,
      order_direction: "FORWARD",
      pickup_postal: formData.pickup_postal,
      consignee_postal: formData.consignee_postal,
      dropoff_vendor_name: formData.serviceDefinition?.source,
      addresses: [
        {
          request_id: "5156857e4ef7_pickup",
          address_type: "pickup",
          country: formData.pickup_country,
          state: formData.pickup_state,
          city: formData.pickup_city,
          postal_code: formData.pickup_postal,
          zone_type: "pricing"
        },
        {
          request_id: "68f621ca261a_consignee",
          address_type: "consignee",
          country: formData.consignee_country,
          state: formData.consignee_state,
          city: formData.consignee_city,
          postal_code: formData.consignee_postal,
          zone_type: "pricing"
        }
      ]
    }
    return fetch_b2c_price_estimates(auth.user.secret_key, data)
  },  {
    onSuccess: (values: B2CPriceEstimatesResponse) => {
      singleOrderContext.updateSingleOrderDataB2C(
        {
          PriceEstimates: [{
            amount: values.total,
            currency: values.currency,
            gst_amount: values.gst_amount,
            total_duties: values.total_duties,
            total_taxes: values.total_taxes,
            shipping_charge: values.shipping_charge,
            total: values.total,
            // pickup_charge: values.rate.pickup_charge
          }]
        }
      )
      singleOrderContext.updateErrorInPriceEstimate(false)
      setCurrentState(3);
    },
    onError: (error: any) => {
      singleOrderContext.updateSingleOrderDataB2C(
        {
          PriceEstimates: [{
            amount: 0,
            currency: '',
            gst_amount: 0,
            total_duties: 0,
            total_taxes: 0,
            shipping_charge: 0,
            total: 0,
            // pickup_charge: values.rate.pickup_charge
          }]
        }
      )
      singleOrderContext.updateErrorInPriceEstimate(true)
      setCurrentState(3);
    },
  })

  const updateContextAndValidate = () => {

    const handleError = (error: any) => {
      if(error.response) {
        singleOrderContext.setErrors(error.response.data)
        // const combined = combineFieldsErrorsFromB2C(error.response.data)
        // singleOrderContext.setErrors(combined);

        // const parsedError = parseOrderContextError(combined)
        // helpers.setErrors(parsedError)
        
        if(error.response.data['non_field_errors']){
          
          let errors = error.response.data['non_field_errors'].join(',');
          message.error(getText('Failed to create shipment, please try again:')  + errors, 10);
        }
        else{
          errData(error.response.data)
        }
      }
      else{
        message.error(
          <span>Failed to create shipment, please contact {contactCustomerService()}, {error.message} </span>,
          10
        );
      }
    }

    const handleSuccess = () => {
      return (
        message.success('Order details updated Succesfully', 10),
        // history.push('/orders/integrated-shipments?status=ready')
        props.setRefetchQuery!(true),
        setCurrentState(3)
      )
    }
    
    if(props.isMarketplaceOrder) {
      validateSingleOrder_shopify.mutate({}, {
        onSuccess: handleSuccess,
        onError: handleError
      })
    } else {
      validateSingleOrder.mutate({}, {
        onSuccess: (values: any) => {
          SingleOrderCreate('shippingCharges')
          if(auth.user.hide_price_estimation) {
            singleOrderContext.updateSingleOrderDataB2C(
              {
                PriceEstimates: [{
                  amount: 0,
                  currency: '',
                  gst_amount: 0,
                  total_duties: 0,
                  total_taxes: 0,
                  shipping_charge: 0,
                  total: 0,
                  // pickup_charge: values.rate.pickup_charge
                }]
              }
            )
            setCurrentState(3);
          } else {
            fetchPriceEstimatesInB2cSingleOrder.mutate()
          }
          if(dataFilled === 0) {
            routeContext.setShouldBlockNavigation(false);
          }
        },
        onError: handleError
      })
    }
  }

  const onProductUrlModalSubmit = () => {
    updateContextAndValidate()
  }

  const submitForm = (values: OrderProps, helpers: FormikHelpers<OrderProps>) => {
    const isProductUrlEmpty = verifyItemUrl(values.items, singleOrderContext.singleOrder.consignee_country)
    setOrderData({...values});
    let Items = values.items.map((item: any, index: any) => {
      let removeObject = {
        ...item
      }
      delete removeObject.gst_collected
      delete removeObject.gst_ovr

      if(props.isMarketplaceOrder) {
        delete removeObject.item_desc
        delete removeObject.item_quantity
        return {
          'item_description': item.item_desc,
          'item_qty': item.item_quantity,
          ...removeObject  
        }
      }
      return {
        'gst_collected': item.gst_collected === '' ? false : item.gst_collected === 'true' ? true : false,
        'gst_ovr': item.gst_ovr === '' ? null : item.gst_ovr,
        ...removeObject
      }
    })

    singleOrderContext.updateSingleOrderDataB2C({
      order_weight: values.order_weight,
      order_length: values.order_length,
      order_width: values.order_width,
      order_height: values.order_height,
      payment_type: values.isCod === 'true'?'cod':'prepaid',
      cod_amt_to_collect_currency: consigneeCurrency,
      cod_amt_to_collect: values.isCod === 'true' ? values.cod_amt_to_collect :null,
      items: Items,
      tracking_no: values.custom_tn,
      secret_key: auth.user.secret_key,
      incoterm: (singleOrderContext.singleOrder.pickup_country !== singleOrderContext.singleOrder.consignee_country) || ((singleOrderContext.singleOrder.pickup_country === singleOrderContext.singleOrder.consignee_country) &&singleOrderContext.singleOrder.serviceType === 'Customs clearance and last mile')? values.incoterm : null,
      special_instruction: values.special_instruction
    });
    singleOrderContext.setErrors({
      ...emptyError
    })

    if(props.isMarketplaceOrder) {
      // delete removeObject.serviceType
      singleOrderContext.updateSingleOrderDataB2C({
        'service_type': singleOrderContext.singleOrder.serviceType
      });
    }
    if(isProductUrlEmpty){
      setShowItemProductUrlModal(true)
    }else{
      updateContextAndValidate()
    }
  }

  const isCodOptions = [
    { key: 'Yes', value: 'true'},
    { key: 'No', value: 'false' }
  ]

  const incotermRadioOptions = [
    { key: 'DDP (You will pay for customs, duties and taxes)', value: 'DDP'},
    { key: 'DDU (Customer will pay for customs, duties and taxes)', value: 'DDU'}
  ]
  return (
    <>
      {current < 3 && <div ref={r => {ItemDetailsFormRef.current = ItemDetailsFormRef.current ? ItemDetailsFormRef.current : r; }}>
        <Formik
          innerRef={ItemDetailsFormRef}
          validationSchema={validationSchema(getText, auth.user.payment_type)}
          initialValues={initialValues}
          initialTouched={initialTouched}
          onSubmit={submitForm}
          validateOnChange={true}
          // isInitialValid={editOrderStatus()}
          initialErrors={initialErrors}>
          { formik => (
            <div className={"single-order-form-container ant-form-vertical"}>
              <Form >
                
                {['Vietnam','Indonesia'].includes(singleOrderContext.singleOrder.consignee_country) ? <Alert
                  message={<p style={{marginBottom:0}}>{getText('Please take note that you cannot ship')} <a href={singleOrderContext.singleOrder.consignee_country==='Vietnam'? prohibitedItemsToVietnam: prohibitedItemsToIndonesia} style={{fontWeight: "bold",color: '#050593',
                    textDecoration: 'underline'}} target={"_blank"} rel="noreferrer">{getText('these')}</a> {getText('prohibited and restricted goods to')} <strong>{countries[countryCodes[singleOrderContext.singleOrder.consignee_country]]}</strong>.</p>}
                  type="info"
                  showIcon
                  style={{marginBottom:24}}
                />
                  :
                  <Alert
                    message={<p style={{marginBottom:0}}>{getText('Please take note that you cannot ship')} <a href={`https://docs.google.com/spreadsheets/d/1SWRmJPRDXLcS2h6skwaLF_jJTcVTnkcUxP7QK6qCUX8/edit#gid=${GoodsListSheetIds[countryCodes[singleOrderContext.singleOrder.consignee_country]]}`} style={{fontWeight: "bold",color: '#050593',
                      textDecoration: 'underline'}} target={"_blank"} rel="noreferrer">{getText('these')}</a> {getText('prohibited and restricted goods to')} <strong>{countries[countryCodes[singleOrderContext.singleOrder.consignee_country]]}</strong>.</p>}
                    type="info"
                    showIcon
                    style={{marginBottom:24}}
                  />
                }
                <FieldArray name="items"
                  render={arrayHelpers => (
                    <>
                      {formik.values.items.map((item, index) => (
                        <div key={index}>
                          <F.Item label={<TitleErrorMarker text={`${getText('Item')} ${index+1}`} hasError={hasError} />} className={"single-order-form-title"}></F.Item>
                          <ItemForm isDomestic={isDomestic} index={index} remove={() => arrayHelpers.remove(index)} contextData={singleOrderContext} marketPlaceOrder={props.isMarketplaceOrder} />
                          {index > 0 && <Divider/>}
                        </div>
                      ))}

                      <F.Item>
                        <Button className={'outline-btn'} onClick={() => arrayHelpers.push(empty_item)} disabled={((!formik.isValid && formik.errors.hasOwnProperty("items"))) ? true: false}>
                          {getText('Add another item')}
                        </Button>
                      </F.Item>
                    </>
                  )} />

                <Divider/>
                <F.Item label={getText('Parcel Details')} className={"single-order-form-title"}>
                </F.Item>
                <F.Item label={getText('Make sure weight and dimensions of your box/package are correctly declared else shipment will be put on hold till repayment is made or it will be disposed.')} className={"form-label-info"}>
                </F.Item>

                <Row gutter={14}>
                  <Col span={7}>
                    <FormInput name="order_weight" required label={getText('Weight (kg)')} inputProps={{type: 'number', min: 0.1, step:0.01}}/>
                  </Col>
                  <Col span={5}>
                    <FormInput name="order_length" required label={getText('Length (cm)')} inputProps={{type: 'number', min: 1, step:0.01}}/>
                  </Col>
                  <Col span={1} style={{display: 'flex', justifyContent: 'center'}}>
                    <div className={"cross-mark"}>x</div>
                  </Col>
                  <Col span={5}>
                    <FormInput name="order_width" required label={getText('Width (cm)')} inputProps={{type: 'number', min: 1, step:0.01}}/>
                  </Col>
                  <Col span={1} style={{display: 'flex', justifyContent: 'center'}}>
                    <div className={"cross-mark"}>x</div>
                  </Col>
                  <Col span={5}>
                    <FormInput name="order_height" required label={getText('Height (cm)')} inputProps={{type: 'number', min: 1, step:0.01}}/>
                  </Col>
                </Row>
                <Row gutter={14}>
                  <Col span={12}>
                    <FormInput name="special_instruction" rows={3} label={"Special Instruction"} textArea={true} maxLength={80}/>
                  </Col>
                </Row>

                {isCustomTNPermission && 
                  <Row gutter={14}>
                    <Col span={12}>
                      <FormInput name="custom_tn" label={'Custom Tracking Number (optional)'} 
                      // inputProps={{disabled: disableCustomTrackingNumberField()}} 
                      />
                    </Col>      
                  </Row>
                }

                {
                  <>
                    <Row>
                      <Col span={24} style={{marginTop: '10px'}}>
                        <RadioGroup
                          label={'Is this a Cash On Delivery (COD)?'}
                          name={'isCod'}
                          options={isCodOptions}
                          visualisation={'buttons'}
                          disabled={false}
                        />
                      
                        {countryCurrencies.map(item => {
                          return <Option key={item} value={item}>{item}</Option>
                        })}
                      </Col>
                    
                      {
                        formik.values.isCod === 'true' &&
                      <>
                        <Col span={24} style={{marginTop: '20px'}}>Cash to collect from consignee</Col>
                        <Col span={24}>
                          <Row gutter={8}>
                            <Col span={4}>
                              <FormSelect name={'cod_amt_to_collect_currency'} label={''} showSearch={"true"} disabled={true} defaultValue={consigneeCurrency}>
                                {countryCurrencies.map(item => (
                                  <Option key={item} value={item}>{item}</Option>
                                ))}
                              </FormSelect>
                            </Col>
                            <Col span={6}>
                              <FormInput name={'cod_amt_to_collect'} label={''} inputProps={{type: 'number', min: 0}}/>
                            </Col>
                          </Row>
                        </Col>
                      </>
                      }
                    </Row>

                    {((singleOrderContext.singleOrder.pickup_country !== singleOrderContext.singleOrder.consignee_country) || ((singleOrderContext.singleOrder.pickup_country === singleOrderContext.singleOrder.consignee_country) &&singleOrderContext.singleOrder.serviceType === 'Customs clearance and last mile')) &&
                    <Row>
                      <Col span={24} style={formik.values.isCod === 'true' ? {marginTop: '0px'} : {marginTop: '30px'}}>
                        <RadioGroup
                          label={'Incoterm'}
                          name={'incoterm'}
                          options={incotermRadioOptions}
                          visualisation='dots'
                          disabled={false}
                        />
                      </Col>
                    </Row>
                    }
                  </>
                }
                
                {current === 2 && (
                  <div className={"button-block"}>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className={"blue-button"}
                      loading={validateSingleOrder.isLoading || fetchPriceEstimatesInB2cSingleOrder.isLoading || validateSingleOrder_shopify.isLoading} 
                    >
                      {getText('Save & Continue')}
                    </Button>
                  </div>
                )}
              </Form>
            </div>
          )}
        </Formik>
      </div>}
      <>
        {((orderData && current > 2) || dataFilled === 3) && <Card title={<TitleErrorMarker text={getText('Item & Parcel Overview')} hasError={hasError} />} className={"form-summary-container"}>
          {initialValues.items && orderData?.items.map((order,index) => {
            return <p key={index} style={{margin: 0}}> {`${index+1}. ${order.item_desc}`}</p>
          })}
          <p className={'gray-text'}>{initialValues.order_weight} kg,{initialValues.order_length}x{initialValues.order_width}x{initialValues.order_height} cm</p>
        </Card>}
      </>
      <ItemProductUrlModal isVisible={showItemProductUrlModal} setIsVisible={setShowItemProductUrlModal} onSubmit={onProductUrlModalSubmit}/>
    </>
  )
};

export const ItemDetailsFormTitle = (props:PropsType) =>{
  let { getText } = useLanguage();
  const {current, setCurrentState} = props;
  return (
    <div className={"title-block"}>
      <p className={'step-title'}>{getText('What are you delivering?')}</p>
      {current>2 ? <p onClick={()=> setCurrentState(2)} className={"edit-text"}>{getText('Edit details')}</p>:''}
    </div>
  )
};