import {
  Card,
  Row,
  Col,
  Breadcrumb
} from 'antd'
import {Link} from "react-router-dom";
import './styles/AuthForm.css'
import {ProfileForm} from "./components/ProfileForm";
import {ChangePasswordForm} from "./components/ChangePasswordForm";
import { useLanguage } from '../languages/Language';

export const UpdateProfile = () =>  {
  let { getText } = useLanguage();
  return (
    <>
      <Breadcrumb className="bread-crumbs">
        <Breadcrumb.Item>
          <Link to={`/settings`} >
            <span style={{border: 'none',background: 'none'}} >
              {getText('Settings')}
            </span>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{getText('Profile')}</Breadcrumb.Item>
      </Breadcrumb>
      <Row align="middle" justify="center">
        <Col span={16} className={'auth-form-container'}>
          <Card>
            <ProfileForm />
          </Card>
        </Col>
      </Row>
      <Row align="middle" justify="center" style={{marginTop: 50}}>
        <Col span={16} className={'auth-form-container'}>
          <Card>
            <ChangePasswordForm />
          </Card>
        </Col>
      </Row>
    </>
  )
};
