import {
  Modal
} from 'antd';
import '../../orders/styles/SingleOrderForm.css';
import {PickUpAddressFormForShopify} from '../../locations/PickUpAddressFormForShopify';
import { useLanguage } from '../../languages/Language';

interface PropsType {
    setShowAddAddressModalCallback: (val:boolean)=> void
    countryCode?: string
    addressType :string
    setCreatedLocationIdCallback ?: (id:string) => void
    setCreatedLocationIdCallbackForReturns?: (id:any) => void
}

export const PickUpAddressForShopify = (props:PropsType) =>{
  const onSuccess = (id: string) => {
    props.setShowAddAddressModalCallback(false);
    if(props.setCreatedLocationIdCallback){
      props.setCreatedLocationIdCallback(id)
    }
  }
  const onSuccessInReturns = (location: any) => {
    props.setShowAddAddressModalCallback(false);
    if(props.setCreatedLocationIdCallbackForReturns){
      props.setCreatedLocationIdCallbackForReturns(location)
    }
  }
  let { getText } = useLanguage();
  const handleCancel = () => {
    props.setShowAddAddressModalCallback(false);
  };
  return (
    <Modal width={650} title={`${getText('Add new')} ${props.addressType} ${getText('address')}`}
      visible={true}
      onCancel={handleCancel}
      footer={null}
    >
      <PickUpAddressFormForShopify
        onSuccess={onSuccess}
        countryCode={props.countryCode}
        onCancel={handleCancel}
        addressType={props.addressType}
        onSuccessForReturns={onSuccessInReturns}
      />
    </Modal>
  );
};