import {
  Modal
} from 'antd';
import '../../orders/styles/SingleOrderForm.css';
import { useLanguage } from '../../languages/Language';
import { ConsigneeAddressFormForShopify } from '../../locations/ConsigneeAddressFormForShopify';

interface PropsType {
  setShowAddressModalCallback: (val:boolean)=> void
  countryCode?: string
  addressType :string
  setCreatedLocationIdCallback ?: (location:any) => void
}

export const ConsigneeAddressModalForShopify = (props:PropsType) =>{
  const onSuccess = (location: any) => {
    props.setShowAddressModalCallback(false);
    if(props.setCreatedLocationIdCallback){
      props.setCreatedLocationIdCallback(location)
    }
  }
  let { getText } = useLanguage();
  const handleCancel = () => {
    props.setShowAddressModalCallback(false);
  };
  return (
    <Modal width={650} title={`${getText('Add new')} ${props.addressType} ${getText('address')}`}
      visible={true}
      onCancel={handleCancel}
      footer={null}
    >
      <ConsigneeAddressFormForShopify
        onSuccess={onSuccess}
        countryCode={props.countryCode}
        onCancel={handleCancel}
        addressType={props.addressType}
      />
    </Modal>
  );
};

