import {
  Alert,
  Button, Col, Divider,
  Form, Input, message, Row,
} from 'antd';
import {useAuth} from "../services/authContext";
import {useState} from "react";
import {useMutation} from "react-query";
import {change_password as changePassword} from "../services/authApi";
import { useLanguage } from '../../languages/Language';

export const ChangePasswordForm = () => {
  const auth = useAuth();
  const authToken = auth.user.authToken;
  const { getText } = useLanguage();
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const confirmReset = useMutation(async ({current_password, new_password}: {current_password: string, new_password: string}) => {
    message.loading(getText('Saving information'));
    return changePassword(authToken, new_password, new_password, current_password)
  },  {
    onSuccess: (val) => {
      message.destroy();
      message.success(getText('Successfully updated'));
    },
    onError: (val:any) => {
      message.destroy();
      message.error(getText('Failed to update'));
      if(val.response){
        if(val.response.data.hasOwnProperty('current_password')) {
          setErrorMessage(errorMessage => [...errorMessage, `Current password is ${val.response.data.current_password}`])
        }
        if(val.response.data.hasOwnProperty('new_password')) {
          setErrorMessage(errorMessage => [...errorMessage,`New password is ${val.response.data.new_password}`])
        }
      }
      else{
        setErrorMessage(errorMessage => [...errorMessage,`Failed to update the password`])
      }
    },
  });
  return (
    <Form
      onFinish={(val) => {
        confirmReset.mutate(val);
      }}
      layout={'vertical'}
      requiredMark={false}
    >
      <Form.Item label={getText('Password')} className={"form-title form-left-title"}>
      </Form.Item>
      <Divider/>
      {confirmReset.isError && <Alert
        className={"form-error"}
        message={errorMessage.map((item, index) => {
          return (
            <ul className="error-alert">
              <li>{index + 1}. {item}</li>
            </ul>
          )
        })}
        type="error"
        showIcon
      />}
      <Row gutter={14}>
        <Col span={12}>
          <Form.Item
            label={getText('Current Password')}
            name="current_password"
            rules={[{ required: true, message: getText('Please enter current password') }]}
          >
            <Input.Password/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={getText('New Password')}
            name="new_password"
            rules={[{ required: true, message: getText('Please enter new password') }]}
          >
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>
      <div className={"button-block"}>
        <Button htmlType="submit" type="primary" className={"blue-button"} disabled={confirmReset.isLoading}>
          {getText('Save')}
        </Button>
      </div>
    </Form>
  )
};
