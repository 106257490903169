import {
  Form,
  Button,
  Col,
  Row,
  Select, Card, Alert
} from 'antd';
import '../../styles/SingleOrderForm.css';
import React, {useEffect, useRef, useState} from "react";
import {useSingleOrderB2C, OrderErrorB2C} from '../../services/singleOrderContext';
import {featureFlag, sortResponseAlphabatically} from "../../../commons/utils/utilizer" 
import {countryCallingCodes, countryCodes, deliveryTimeSlotB2cId, deliveryTimeSlotB2cIdStaging, deliveryTimeSlots, IndonesiaIdentificationTypes, SouthKoreaIdentificationTypes} from "../../../commons/utils/constants";
import {validatePostalCode} from "../../../commons/utils/validator";
import {FormInput, FormSelect, TitleErrorMarker} from '../Forms';
import {Formik, Form as FormikForm} from 'formik';
import {parseErrors} from '../../helpers';
import * as Yup from 'yup';
import {SingleOrderCreate} from "../../../commons/mixpanel"
import {useQuery} from "react-query";
import {getConsigneeLocation_from_b2c, getStates_from_b2c, getCities_from_b2c } from "../../services/privateApi";
import {useAuth} from "../../../auth/services/authContext";
import { useLanguage } from '../../../languages/Language';
import { ConsigneeAddressModal } from "../../../UserSettings/components/ConsigneeAddressModal";
import { LabelRadio } from '../../../ReUsableComponents/ReUsable'

const {Option} = Select;

interface PropsType {
    current: number,
    setCurrentState: (val:number)=> void,
    dataFilled: number
}
interface CustomerProps {
  consignee_name?: string,
  consignee_email?: string,
  consignee_number?: string,
  consignee_state?: string,
  consignee_postal?: string,
  consignee_address?: string,
  consignee_city?: string,
  consignee_country?: string,
  consignee_address_id?: string | number,
  identification_document_name?: string,
  consignee_identifcation_number?: string
  delivery_time_slot?: string
}

const validationSchema = (getText: any) => {
  return Yup.object().shape({
    consignee_name: Yup.string().required(getText('Please enter Full Name')),
    consignee_number: Yup.string().required(getText('Please enter phone number')),
    consignee_email: Yup.string().email(getText('Please enter a valid email')).required(getText('Please enter a valid email')),
    consignee_address: Yup.string().required(getText('Please enter Address')),
    consignee_state: Yup.string().required(getText('Please select State')),
    consignee_city: Yup.string().required(getText('Please enter City')),
    consignee_country: Yup.string().required(getText('Please select Destination Country')),
    consignee_postal: Yup.string().required(getText('Please enter Postal Code')).test(
      'postal-check',
      (val: any, context: any) => {
        if(!val){
          return context.createError({
            path: context.path,
            message: getText('Please enter Postal Code')
          })
        }
        else{
          const error = validatePostalCode(countryCodes[context.parent.consignee_country],val);
          if(error){
            return context.createError({
              path: context.path,
              message: error
            })
          }
        }
        return true
      }
    )
  })};

const parseOrderContextError = (error: OrderErrorB2C) => {
  return {
    consignee_name: error.consignee_name,
    consignee_number: error.consignee_number,
    consignee_email: error.consignee_email,
    consignee_address: error.consignee_address,
    consignee_country: error.consignee_country,
    consignee_state: error.consignee_state,
    consignee_city: error.consignee_city,
    consignee_postal: error.consignee_postal,
    consignee_identifcation_number: error.consignee_identifcation_number,
    identification_document_name: error.identification_document_name
  }
}

export const ConsigneeDetailsForm = (props:PropsType) =>{
  let { getText } = useLanguage();
  const auth = useAuth();
  const b2cId = auth.user.b2c_id
  const ConsigneeDetailsFormRef:any = useRef();
  const singleOrderContext = useSingleOrderB2C();
  const {current, setCurrentState, dataFilled} = props;
  const [customerData, setCustomerData] = useState<CustomerProps | null>({});
  const [showConsigneeAddressModal, setShowConsigneeAddressModal] = useState<boolean>(false);
  const [consigneeFieldsDisabled, setConsigneeFieldDisabled] = useState<boolean>(false)
  const [freeText, setfreeText] = useState<string>('dropdown');
  const [state, setState] = useState<string>('');
  const setShowConsigneeAddressModalCallback = (val: boolean) => {
    setShowConsigneeAddressModal(val)
    consigneeLocationsFromB2c.refetch();
  }

  useEffect(() => {
    setConsigneeFieldDisabled(false)
  }, [current])
  const statesListFromb2c = useQuery(['statesListFromb2c', singleOrderContext.singleOrder.consignee_country], () => {
    if(singleOrderContext.singleOrder.consignee_country!){
      return getStates_from_b2c(singleOrderContext.singleOrder.consignee_country!)
    }
  });

  const cityListFromb2c = useQuery(['cityListFromb2c', state], () => {
    if(state){
      return getCities_from_b2c(singleOrderContext.singleOrder.consignee_country!, state)
    }
  });

  // Call consignee address api
  const consigneeLocationsFromB2c = useQuery('consigneeLocationsFromB2c', () => {
    return getConsigneeLocation_from_b2c(auth.user.secret_key)
  }, {cacheTime: 0})

  const setCreatedLocationIdCallback = (location: any) => {
    if (ConsigneeDetailsFormRef.current) {
      ConsigneeDetailsFormRef.current.setFieldValue('consignee_address_id', location.id)     
      ConsigneeDetailsFormRef.current.setFieldValue('consignee_name',location.contact_name || '')
      ConsigneeDetailsFormRef.current.setFieldValue('consignee_number',location.contact_number || '')
      ConsigneeDetailsFormRef.current.setFieldValue('consignee_email',location.contact_email || '')
      ConsigneeDetailsFormRef.current.setFieldValue('consignee_address',location.address || '')
      ConsigneeDetailsFormRef.current.setFieldValue('consignee_state',location.state || '')
      ConsigneeDetailsFormRef.current.setFieldValue('consignee_city',location.city || '')
      ConsigneeDetailsFormRef.current.setFieldValue('consignee_postal',location.postal || '')
      ConsigneeDetailsFormRef.current.setFieldValue('consignee_identifcation_number',location.consignee_id || '')
      ConsigneeDetailsFormRef.current.setFieldValue('identification_document_name',location.consignee_id_type || '')
      setConsigneeFieldDisabled(true)
    }
        
  }

  const formInitialValue: CustomerProps = {
    consignee_name: singleOrderContext.singleOrder.consignee_name,
    consignee_number: singleOrderContext.singleOrder.consignee_number,
    consignee_email: singleOrderContext.singleOrder.consignee_email,
    consignee_address: singleOrderContext.singleOrder.consignee_address,
    consignee_country: singleOrderContext.singleOrder.consignee_country!,
    consignee_state: singleOrderContext.singleOrder.consignee_country.toLowerCase() === 'singapore'?
      singleOrderContext.singleOrder.consignee_country :
      singleOrderContext.singleOrder.consignee_state,
    consignee_city: singleOrderContext.singleOrder.consignee_country.toLowerCase() === 'singapore'?
      singleOrderContext.singleOrder.consignee_country :
      singleOrderContext.singleOrder.consignee_city,
    consignee_postal: singleOrderContext.singleOrder.consignee_postal,
    consignee_address_id: singleOrderContext.singleOrder.consignee_address,
    identification_document_name: singleOrderContext.singleOrder.identification_document_name,
    consignee_identifcation_number: singleOrderContext.singleOrder.consignee_identifcation_number
  }

  const {initialErrors, initialTouched, hasError, emptyError} = parseErrors(singleOrderContext.errors, parseOrderContextError)
  // if(hasError){
  //   if(current > 1){
  //     setCurrentState(1);
  //   }
  //   if(ConsigneeDetailsFormRef.current != null){
  //     window.scrollTo(0, ConsigneeDetailsFormRef.current.offsetTop);
  //   }
  // }
  const submitForm = (values: CustomerProps) => {
    SingleOrderCreate('parcelDetails');
    setCurrentState(2);
    setCustomerData({...values});
    singleOrderContext.updateSingleOrderDataB2C({
      consignee_name: values.consignee_name,
      consignee_number: values.consignee_number,
      consignee_email: values.consignee_email,
      consignee_address: values.consignee_address,
      consignee_state: values.consignee_state,
      consignee_city: values.consignee_city,
      consignee_postal: values.consignee_postal,
      consignee_identifcation_number: values.consignee_identifcation_number,
      identification_document_name: values.identification_document_name,
      delivery_start_time: values.delivery_time_slot?.split('-')[0],
      delivery_end_time: values.delivery_time_slot?.split('-')[1],
    })
    singleOrderContext.setErrors({...emptyError})
  }

  const onConsigneeAddressSelect = (value:any, formik:any) => {
    let selectedAddress:any = consigneeLocationsFromB2c.data?.filter(address => {
      return  address.id === value;
    })
    formik.setFieldValue('consignee_name',selectedAddress[0].contact_name || '')
    formik.setFieldValue('consignee_number',selectedAddress[0].contact_number || '')
    formik.setFieldValue('consignee_email',selectedAddress[0].contact_email || '')
    formik.setFieldValue('consignee_address',selectedAddress[0].address || '')
    formik.setFieldValue('consignee_state',selectedAddress[0].state || '')
    formik.setFieldValue('consignee_city',selectedAddress[0].city || '')
    formik.setFieldValue('consignee_postal',selectedAddress[0].postal || '')
    formik.setFieldValue('consignee_identifcation_number',selectedAddress[0].consignee_identifcation_number || '')
    formik.setFieldValue('identification_document_name',selectedAddress[0].identification_document_name || '')
  }
  const onConsigneeAddressRemove = (formik:any) => {
    formik.setFieldValue('consignee_address_id','')
    formik.setFieldValue('consignee_name','')
    formik.setFieldValue('consignee_number','')
    formik.setFieldValue('consignee_email','')
    formik.setFieldValue('consignee_address','')
    formik.setFieldValue('consignee_state','')
    formik.setFieldValue('consignee_city','')
    formik.setFieldValue('consignee_postal','')
    formik.setFieldValue('consignee_identifcation_number','')
    formik.setFieldValue('identification_document_name','')
  }

  const setFreeTextCallback = (val:string) => {
    setfreeText(val);
  };
  return (
    <div ref={r => {ConsigneeDetailsFormRef.current = ConsigneeDetailsFormRef.current ? ConsigneeDetailsFormRef.current :r}}>
      { current < 2 && <>
        <Formik
          innerRef={ConsigneeDetailsFormRef}
          initialValues={formInitialValue}
          initialErrors={initialErrors}
          initialTouched={initialTouched}
          validationSchema={validationSchema(getText)}
          enableReinitialize= {true}
          onSubmit={submitForm}
          validateOnChange={false}>
          { formik => (
            <div className={"single-order-form-container ant-form-vertical"}>
              {formInitialValue.consignee_country === "Indonesia" ?
                <div style={{marginBottom:20}}>
                  <Alert
                    message={<p style={{margin:0}}> {getText("Please provide your")} <strong> {getText("customer's identification number")} </strong> {getText("to ensure a smooth delivery")}</p>}
                    type="info"
                    showIcon
                  />
                </div> : ''
              }
              <FormikForm>
                <Form.Item label={<TitleErrorMarker text={getText('Customer Details')} hasError={hasError}/>} className={"single-order-form-title"}></Form.Item>
                <Row gutter={36}>
                  <Col span={12}>
                    <FormSelect name="consignee_address_id" label={"Select Customer Address"} placeholder={'Select customer'} wrapperClass="no-margin-item"
                      onChangeCallback={(value: any) => {
                        onConsigneeAddressSelect(value, formik);
                        setConsigneeFieldDisabled(true)
                      }}>
                      {
                        // Display consignee locations of the selected consignee country
                        consigneeLocationsFromB2c.data?.filter(l => l.country === singleOrderContext.singleOrder.consignee_country!).map(item => <Option key={item.id} value={item.id}>{`${item.name} - ${item.address}`}</Option>)
                      }
                    </FormSelect>
                    <Form.Item>
                      <Button type="primary" className={"no-border-btn"} onClick={() => {setShowConsigneeAddressModal(true)}}>
                        {'Add new customer'}
                      </Button>
                    </Form.Item>
                  </Col>
                  {formik.values.consignee_address_id !== ''&&
                    <Col span={6} offset={4}>
                      <Button type="primary" className={"no-border-btn consignee-remove-btn"} onClick={() => {onConsigneeAddressRemove(formik); setConsigneeFieldDisabled(false)}}>
                        {'Remove customer'}
                      </Button>
                    </Col>
                  }
                </Row>
                <Row gutter={14}>
                  <Col span={12}>
                    <FormInput label={getText('Full Name')} inputProps={{disabled:consigneeFieldsDisabled}} name="consignee_name" />
                  </Col>
                  <Col span={12}>
                    <FormInput label={getText('Phone Number')} name="consignee_number"
                      inputProps={{
                        disabled:consigneeFieldsDisabled,
                        prefix: countryCallingCodes[singleOrderContext.singleOrder.consignee_country!]
                      }}/>
                  </Col>
                </Row>
                { formInitialValue.consignee_country === "Indonesia" ?
                  <Row gutter={14}>
                    <Col span={12}>
                      <FormSelect label={getText('Identification Type')} disabled={consigneeFieldsDisabled} name="identification_document_name" onChangeCallback={(value: any) => {formik.setFieldValue("consignee_id", '')}}>
                        {
                          Object.entries(IndonesiaIdentificationTypes).map((type:any) => {
                            return  <Option key={type[0]} value={type[0]}>{type[1]}</Option>
                          })
                        }
                      </FormSelect>
                    </Col>
                    {formik.values.identification_document_name ?
                      <Col span={12}>
                        <FormInput inputProps={{disabled:consigneeFieldsDisabled}} label={`${IndonesiaIdentificationTypes[formik.values.identification_document_name]}`} name="consignee_identifcation_number" />
                      </Col> : ''
                    }
                  </Row> : ''
                }
                { formInitialValue.consignee_country === "South Korea" ?
                  <Row gutter={14}>
                    <Col span={12}>
                      <FormSelect label={getText('Identification Type')} disabled={consigneeFieldsDisabled} name="identification_document_name" onChangeCallback={(value: any) => {formik.setFieldValue("consignee_id", '')}}>
                        {
                          Object.entries(SouthKoreaIdentificationTypes).map((type:any) => {
                            return  <Option key={type[0]} value={type[0]}>{type[1]}</Option>
                          })
                        }
                      </FormSelect>
                    </Col>
                    {formik.values.identification_document_name ?
                      <Col span={12}>
                        <FormInput inputProps={{disabled:consigneeFieldsDisabled}} label={`${SouthKoreaIdentificationTypes[formik.values.identification_document_name]}`} name="consignee_identifcation_number" />
                      </Col> : ''
                    }
                  </Row> : ''
                }
                <FormInput label={getText('Email')} inputProps={{disabled:consigneeFieldsDisabled}} name="consignee_email" />
                <FormInput label={getText('Address')} inputProps={{disabled:consigneeFieldsDisabled}} name="consignee_address" />

                <Row gutter={14}>
                  {formInitialValue.consignee_country ?
                    <Col span={12}>
                      <FormInput label={getText('Country')} inputProps={{disabled:true}} name="consignee_country"/>
                    </Col>
                    : ''}
                  <Col span={12}>
                    {consigneeFieldsDisabled? 
                      <FormInput inputProps={{disabled:consigneeFieldsDisabled}} label={getText('State')} name="consignee_state" ></FormInput> :
                      <FormSelect required label={getText('State')} name="consignee_state" loading={statesListFromb2c.isLoading} onChangeCallback={(state:string) => {
                        setState(state);
                        formik.setFieldValue('consignee_city', '');
                      }}>
                        {
                          (sortResponseAlphabatically(statesListFromb2c?.data, 'state_name') || []).map((item: {state_id: number, state_name:string} ) => {
                            return  <Option key={item.state_id} value={item.state_name}>{item.state_name}</Option>
                          })
                        }
                      </FormSelect>}
                    {statesListFromb2c.isError && <p style={{color:'red', marginBottom:0}}>{getText("Failed to fetch states, try later")}</p>}
                  </Col>
                </Row>
                <Row gutter={14}>
                  <Col span={12}>
                    {
                      formik.values.consignee_country === 'US' ?
                        freeText === 'dropdown' ? 
                          <>
                            <FormSelect label={<LabelRadio setFreeTextCallback={setFreeTextCallback} freeText={freeText} />}
                              notFoundContent={<p style={{marginBottom: '0px', color: '#525050'}}>City not found. Please select Other</p>} name="consignee_city" loading={cityListFromb2c.isLoading} disabled={consigneeFieldsDisabled}
                              placeholder={'Select a city'}>
                              {
                                (sortResponseAlphabatically(cityListFromb2c?.data, 'city_name') || [])
                                  .map((item: {city_id:number, city_name: string, display_name: string} ) => (
                                    <Option key={item.city_id} value={item.city_name}>{item.display_name}</Option>
                                  ))
                              }
                            </FormSelect>
                            {cityListFromb2c.isError && <p style={{color:'red', marginBottom:0}}>Failed to fetch cities, try later</p>}
                          </>
                          : 
                          <FormInput inputProps={{disabled:consigneeFieldsDisabled}} placeholder='Please type in the city' label={<LabelRadio setFreeTextCallback={setFreeTextCallback} freeText={freeText} />} name="consignee_city" /> : 
                        <FormSelect disabled={consigneeFieldsDisabled} label={getText('City')} name="consignee_city" loading={cityListFromb2c.isLoading}>
                          {
                            (sortResponseAlphabatically(cityListFromb2c?.data, 'city_name') || [])
                              .map((item: {city_id:number, city_name: string, display_name: string} ) => (
                                <Option key={item.city_id} value={item.city_name}>{item.display_name}</Option>
                              ))
                          }
                        </FormSelect>
                    }
                  </Col>
                  <Col span={4} style={{marginTop: singleOrderContext.singleOrder.consignee_country! === 'US' ? '35px' : '0px'}}>
                    <FormInput label={getText('Postal code')} inputProps={{disabled:consigneeFieldsDisabled}} name="consignee_postal" />
                  </Col>
                  {(featureFlag() ? deliveryTimeSlotB2cIdStaging.includes(b2cId) : deliveryTimeSlotB2cId.includes(b2cId)) && (singleOrderContext.singleOrder.consignee_country === 'Singapore' && singleOrderContext.singleOrder.pickup_country === 'Singapore') && <Col span={8}>
                    <FormSelect label={'Delivery time slot (Optional)'} name={'delivery_time_slot'}>
                      {
                        Object.entries(deliveryTimeSlots)?.map(([timeSlotKey, timeSlotValue]:[timeSlotKey: string, timeSlotValue: any]) => (
                          <Option key={timeSlotKey} value={timeSlotValue}>{timeSlotKey}</Option>
                        ))
                      }
                    </FormSelect>
                  </Col>}
                </Row>

                {current === 1 && (
                  <div className="button-block">
                    <Button htmlType="submit" type="primary" className={"blue-button"}>
                      {getText('Save & Continue')}
                    </Button>
                  </div>
                )}
              </FormikForm>
            </div>
          )}
        </Formik>
      </>}
      {showConsigneeAddressModal && 
      <ConsigneeAddressModal 
        setShowAddressModalCallback={setShowConsigneeAddressModalCallback} 
        addressType={"customer"} 
        countryCode={singleOrderContext.singleOrder.consignee_country!} 
        setCreatedLocationIdCallback={setCreatedLocationIdCallback}/>}
      <>
        {((customerData && current > 1) || (current > 1 && dataFilled === 2)) && <Card title={<TitleErrorMarker text={getText('Customer Details')} hasError={hasError}/>} className={"form-summary-container"}>
          <p style={{margin: 0}}>{formInitialValue.consignee_name}, {formInitialValue.consignee_number}</p>
          <p className={'gray-text'}>{formInitialValue.consignee_address}</p>
          <p className={'gray-text'}>{formInitialValue.consignee_state}, {formInitialValue.consignee_city}, {formInitialValue.consignee_postal}</p>
        </Card>}
      </>
    </div>
  )
};
export const ConsigneeDetailsFormTitle = (props:PropsType) =>{
  let { getText } = useLanguage();
  const {current, setCurrentState} = props;
  return (
    <div className={"title-block"}>
      <p className={'step-title'}>{getText('Who are you delivering to?')}</p>
      {current>1 ? <p onClick={()=> setCurrentState(1)} className={"edit-text"}>{getText('Edit details')}</p>:''}
    </div>
  )
};
