import React from 'react';
import {Select} from 'antd'
import { useLanguage, LanguagePreferences } from './Language';
import {CaretDownOutlined} from '@ant-design/icons'

const { Option } = Select;

export const AuthLanguageSelector = () => {
  const { userLanguage, setLanguage } = useLanguage()

  // set selected language by calling context method
  const handleLanguageChange = (lang: string) => {
    setLanguage(lang);
  }

  return (
    <div style={{position:'absolute',top: '-130px',right: '18%',zIndex:1,width:'auto',
      display: 'none' // hide language selector
    }}>
      <Select
        placeholder="Select language"
        suffixIcon={<CaretDownOutlined style={{color:"#050593",fontWeight:"bold"}}/>}
        onChange={handleLanguageChange}
        value={userLanguage}
        bordered={false}
        dropdownMatchSelectWidth={false}
        style={{color:"#050593",fontWeight:"bold"}}
      >
        {
          Object.entries(LanguagePreferences).map(([id, language]) => {
            return (
              <Option key={id} value={id}>{language}</Option>
            )
          })
        }
      </Select>
    </div>
  );
};
