import React, { useRef, useState } from 'react'
import {FieldArray, Formik, Form as FormikForm} from 'formik';
import {Row, Col, Select, Checkbox, Button, message, Modal} from 'antd';
import { useMutation } from 'react-query';
import * as Yup from 'yup';
import { useAuth } from '../../auth/services/authContext';
import { emptyArrayForFileUpload } from '../../commons/utils/utilizer';
import { fileUpload, removeUploadedFile } from '../../orders/services/privateApi';
import { validateFileSize, validateFileType } from '../../commons/utils/validator';
import { FastFormInput, FastFormSelect } from '../../commons/components/FastFormFields/FastFields';
import { UploadFile } from '../../orders/components/Forms';
import TermsAndCondition from '../../Claims/Component/TermsAndCondition';
import { useHistory } from 'react-router';

const {Option} = Select;

interface ClaimformProp{
  tracking_no: any
}

export const EscalateSupportingInformation = (props: ClaimformProp) => {
  const { tracking_no} = props;
  const history = useHistory();
  // const {submitClaimFormQuery, error, isLoading} = useSubmitClaim()
  const auth = useAuth()
  const secretKey = auth.user.secret_key

  const [checked, setChecked] = useState(false)
  
  const [uploadUrlFirst, setUploadUrlFirst] = useState<any>(JSON.stringify(emptyArrayForFileUpload(tracking_no)))
  const [uploadUrlSecond, setUploadUrlSecond] = useState<any>(JSON.stringify(emptyArrayForFileUpload(tracking_no)))
  const [uploadUrlThird, setUploadUrlThird] = useState<any>(JSON.stringify(emptyArrayForFileUpload(tracking_no)))
  const [uploadUrlFourth, setUploadUrlFourth] = useState<any>(JSON.stringify(emptyArrayForFileUpload(tracking_no)))

  const uploadUrlType = useRef('')
  const fileIndex = useRef(0)

  const [isShowModal, setIsShowModal] = useState(false)
  const termsAndConditionModal = () => {
    setIsShowModal(!isShowModal)
  }

  const initialValueData = tracking_no.map((order: any) => {
    return { 
      description: '',
      file_first: '',
      file_second: '',
      file_third: '',
      file_fourth: '',
      fileType_first: '',
      fileType_second: '',
      fileType_third: '',
      fileType_fourth: '',
    }
  })
  const validationSchema = () => {
    return (
      Yup.object().shape({
        supportingInformation: Yup.array().of(Yup.object().shape({
          description: Yup.string().max(80, 'Must be less than 80 characters'),
          // file_first: Yup.mixed().required('Please add file'),
          // file_second: Yup.mixed().required('Please add file'),
          // file_third: Yup.mixed().required('Please add file'),
          // file_fourth: Yup.mixed().required('Please add file'),
          fileType_first: Yup.string().when("file_first", (file_first: any, schema: any)=>{
            if(file_first)
              return schema.required('Please select file type')
            return schema
          }),
          fileType_second: Yup.string().when("file_second", (file_second: any, schema: any)=>{
            if(file_second)
              return schema.required('Please select file type')
            return schema
          }),
          fileType_third: Yup.string().when("file_third", (file_third: any, schema: any)=>{
            if(file_third)
              return schema.required('Please select file type')
            return schema
          }),
          fileType_fourth: Yup.string().when("file_fourth", (file_fourth: any, schema: any)=>{
            if(file_fourth)
              return schema.required('Please select file type')
            return schema
          }),
        }))
      })
    )
  }

  const initialValues= {
    supportingInformation: initialValueData
    
  }
  const onSubmit = (values: any) => {
    // const formData = values.supportingInformation.map((value: any, index: number)=> {
    //   return {
    //     incident_desc: value.description,
    //     supporting_docs: [...JSON.parse(uploadUrlFirst)[index], ...JSON.parse(uploadUrlSecond)[index], ...JSON.parse(uploadUrlThird)[index], ...JSON.parse(uploadUrlFourth)[index]],
    //     tracking_no: tracking_no[index].tracking_number,
    //     order: tracking_no[index].order
    //   }
    // })
    history.push('/escalate/success/')
    // submitClaimFormQuery.mutate(formData)
  }

  const fileUploadQuery:any = useMutation(async (file: any) => {
    return fileUpload(file, secretKey);
  },  {
    onSuccess: (val) => {
      if(uploadUrlType.current === 'file_first'){
        const uploadData = JSON.parse(uploadUrlFirst);
        uploadData[fileIndex.current] = [val.id]
        setUploadUrlFirst(JSON.stringify(uploadData))
      }
      if(uploadUrlType.current === 'file_second'){
        const uploadData = JSON.parse(uploadUrlSecond);
        uploadData[fileIndex.current] = [val.id]
        setUploadUrlSecond(JSON.stringify(uploadData))
      }
      if(uploadUrlType.current === 'file_third'){
        const uploadData = JSON.parse(uploadUrlThird);
        uploadData[fileIndex.current] = [val.id]
        setUploadUrlThird(JSON.stringify(uploadData))
      }
      if(uploadUrlType.current === 'file_fourth'){
        const uploadData = JSON.parse(uploadUrlFourth);
        uploadData[fileIndex.current] = [val.id]
        setUploadUrlFourth(JSON.stringify(uploadData))
      }
      message.success('File uploaded successfully.')
    },
    onError: (error: any) => {
      message.error('error in uploading. Please again later')
    },
  })

  const fileRemoveQuery:any = useMutation(async (id: any) => {
    return removeUploadedFile(auth.user.secret_key, id);
  },  {
    onSuccess: (val: any) => {
      message.success('File removed successfully.')
    },
    onError: (error: any) => {
      message.error('error in uploading. Please again later')
    },
  })

  const onFileAddition = (event: any, formik: any, index: any, formikFileName: string) => {
    const isValidFileType = validateFileType(event)
    const isValidFileSize = validateFileSize(event)
    if(!isValidFileType){
      message.error('Only .png, .jpg and .pdf files are accepted.')
    }
    if(!isValidFileSize){
      message.error('Each file needs to be less than 10mb.')
    }
    if(isValidFileType && isValidFileSize){
      formik.setFieldValue(`supportingInformation.[${index}].${formikFileName}`, event.name)
      fileIndex.current = index;
      uploadUrlType.current = formikFileName
      fileUploadQuery.mutate(event)
    }
    return false
  }
    
  const onFileRemove = (event: any, formik: any, index: any, formikFileName: any) => {
    if(!event.uid) return false;
    if(formikFileName === 'file_first'){
      const uploadData = JSON.parse(uploadUrlFirst);
      fileRemoveQuery.mutate(uploadData[index][0])
      uploadData[index] = []
      setUploadUrlFirst(JSON.stringify(uploadData))
    }
    if(formikFileName === 'file_second'){
      const uploadData = JSON.parse(uploadUrlSecond);
      fileRemoveQuery.mutate(uploadData[index][0])
      uploadData[index] = []
      setUploadUrlSecond(JSON.stringify(uploadData))
    }
    if(formikFileName === 'file_third'){
      const uploadData = JSON.parse(uploadUrlThird);
      fileRemoveQuery.mutate(uploadData[index][0])
      uploadData[index] = []
      setUploadUrlThird(JSON.stringify(uploadData))
    }
    if(formikFileName === 'file_fourth'){
      const uploadData = JSON.parse(uploadUrlFourth);
      fileRemoveQuery.mutate(uploadData[index][0])
      uploadData[index] = []
      setUploadUrlFourth(JSON.stringify(uploadData))
    }
    formik.setFieldValue(`supportingInformation.[${index}].${formikFileName}`, '')
  }

  console.log('uploadUrl', uploadUrlFirst, uploadUrlSecond, uploadUrlThird, uploadUrlFourth)

  return (
    <>
      <Formik 
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {formik => (
          <FormikForm>
            <FieldArray name="supportingInformation"
              render={fieldArrayProps => (
                <div className='claim-main-container multi-claim-group claim-form-padding-left-zero'>
                  {/* {Object.keys(error).length>0 && <Alert
                    message={<ErrorMessage errorMessage={error}/>}
                    type="error"
                    showIcon
                    className='error-message-margin'
                  />} */}
                  {tracking_no?.map((number: any, index: number) =>
                    <>
                      <Row style={{marginBottom: '10px'}}>
                        <Col span={6} style={{marginLeft: '30px'}}><span style={{fontSize: '14px'}}>Description for <strong>{number.tracking_no}</strong></span></Col>
                        {index===0 && <Col span={15}><span className='text-wrap' title={"Upload Files: Only .png, .jpg and .pdf files are accepted.Each file needs to be less than 10mb. You can add up to a maximum of 4 files."}>{"Upload Files: Only .png, .jpg and .pdf files are accepted. Each file needs to be less than 10mb. You can add up to a maximum of 4 files."}</span></Col>}
                      </Row>                  
                      <Row gutter={6} className='claim-form-horizontal claim-form-padding-left-thirty'>
                        <Col span={8} className='claim-input-padding claims-input-width claims-input-display'>
                          <FastFormInput required name={`supportingInformation.[${index}].description`} textArea={true} rows={7} height={170} resize={'none'}/>
                        </Col>
                        <Col span={5} className='claim-input-padding claims-input-width claims-upload-display'>
                          <Row className='supporting-doc-upload supporting-doc-full-width'>
                            <UploadFile
                              accept=".png,.jpg,.pdf"
                              fileList={formik.values.supportingInformation[index].file_first ? null: []}
                              required
                              name={`supportingInformation.[${index}].file_first`} 
                              beforeUpload={(e)=> onFileAddition(e, formik, index, 'file_first')}
                              showRemove
                              listType={'picture'}
                              onRemove={(e) => onFileRemove(e, formik, index, 'file_first')}
                            />
                          </Row>
                          <Row className='supporting-doc-type-dropdowm'>
                            <FastFormSelect required name={`supportingInformation.[${index}].fileType_first`} placeholder='Select File Type'>                   
                              <Option key={'others'} value={'others'} >{'Others'}</Option>                   
                            </FastFormSelect>
                          </Row>
                        </Col>
                        <Col span={5} className='claim-input-padding claims-input-width claims-upload-display'>
                          <Row className='supporting-doc-upload supporting-doc-full-width'>
                            <UploadFile
                              accept=".png,.jpg,.pdf"
                              fileList={formik.values.supportingInformation[index].file_second ? null: []}
                              required
                              name={`supportingInformation.[${index}].file_second`} 
                              beforeUpload={(e)=> onFileAddition(e, formik, index, 'file_second')}
                              showRemove
                              listType={'picture'}
                              onRemove={(e) => onFileRemove(e, formik, index, 'file_second')}
                            />
                          </Row>
                          <Row className='supporting-doc-type-dropdowm'>
                            <FastFormSelect required name={`supportingInformation.[${index}].fileType_second`} placeholder='Select File Type'>                   
                              <Option key={'others'} value={'others'} >{'Others'}</Option>                   
                            </FastFormSelect>
                          </Row>
                        </Col>
                        <Col span={5} className='claim-input-padding claims-input-width claims-upload-display'>
                          <Row className='supporting-doc-upload supporting-doc-full-width'>
                            <UploadFile
                              accept=".png,.jpg,.pdf"
                              fileList={formik.values.supportingInformation[index].file_third ? null: []} 
                              required
                              name={`supportingInformation.[${index}].file_third`} 
                              beforeUpload={(e)=> onFileAddition(e, formik, index, 'file_third')}
                              showRemove
                              listType={'picture'}
                              onRemove={(e) => onFileRemove(e, formik, index, 'file_third')} 
                            />
                          </Row>
                          <Row className='supporting-doc-type-dropdowm'>
                            <FastFormSelect required name={`supportingInformation.[${index}].fileType_third`} placeholder='Select File Type'>                   
                              <Option key={'others'} value={'others'} >{'Others'}</Option>                   
                            </FastFormSelect>
                          </Row>
                        </Col>
                        <Col span={5} className='claim-input-padding claims-input-width claims-upload-display'>
                          <Row className='supporting-doc-upload supporting-doc-full-width'>
                            <UploadFile
                              accept=".png,.jpg,.pdf"
                              fileList={formik.values.supportingInformation[index].file_fourth ? null: []} 
                              required
                              name={`supportingInformation.[${index}].file_fourth`} 
                              beforeUpload={(e)=> onFileAddition(e, formik, index, 'file_fourth')}
                              showRemove
                              listType={'picture'}
                              onRemove={(e) => onFileRemove(e, formik, index, 'file_fourth')} 
                            />
                          </Row>
                          <Row className='supporting-doc-type-dropdowm'>
                            <FastFormSelect required name={`supportingInformation.[${index}].fileType_fourth`} placeholder='Select File Type'>                   
                              <Option key={'others'} value={'others'} >{'Others'}</Option>                   
                            </FastFormSelect>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row className='claim-submit-checkbox'>
                    <Col className='claim-form-padding-left-thirty'>
                      <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)}>
                        I agree to the <a onClick={termsAndConditionModal}>terms and conditions</a> mentioned
                      </Checkbox>
                    </Col>
                    <Col className='claim-submit-button'>
                      <Button type='primary' htmlType='submit' disabled={!checked} className='blue-button'>{'Escalate'}</Button>
                    </Col>
                  </Row>
                  <Modal title={'Terms and Condition'} visible={isShowModal} onCancel={termsAndConditionModal} onOk={termsAndConditionModal}>
                    <TermsAndCondition/>
                  </Modal>
                </div>
              )}  
            />
          </FormikForm>
        )}
      </Formik>
    </>
  )
}

export const EscalateSupportingInformationTitle = () => {
  return (
    <div className='claim-step-header'>
      <p className={'claim-step-title'}>{'Add supporting information'}</p>
    </div>
  )
}