import { Form, Divider, notification } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons'
import { useAuth } from '../../../auth/services/authContext';
import { useSingleOrderB2C } from "../../services/returnSingleOrderContext";
import {SelectedPaymentMethod} from '../../../orders/components/PayForOrders/SelectPaymentMethod';
import { pickupDateHasExpired } from '../../../orders/helpers';
import { useLanguage } from '../../../languages/Language';

interface PropsType {
  current: number,
  setCurrentState: (val:number)=> void,
  isEditing?:boolean
}

const openNotificationWithIcon = (type:any, getText: any) => {
  (notification as any)[type]({
    message: getText('DISCLAIMER'),
    description: getText('Shipping charges are subject to change based on weight and/or D&T of items if different from declared info'),
    className: 'disclaimer-area',
    style: {
      width: 400,
    },
    duration: 7,
  });
}

export const PriceEstimateForm = (props:PropsType) =>{
  let { getText } = useLanguage();
  const auth = useAuth();
  const {current} = props;
  const singleOrderContext = useSingleOrderB2C();
  let orderData:any = {
    amount: singleOrderContext.singleOrder?.PriceEstimates![0]!.total ? singleOrderContext.singleOrder?.PriceEstimates![0].total : '0',
    total: singleOrderContext.singleOrder?.PriceEstimates![0]!.total ? singleOrderContext.singleOrder?.PriceEstimates![0].total : '0',
    currency: singleOrderContext.singleOrder?.PriceEstimates![0]!.currency,
    // id: singleOrderContext.singleOrder.id!,
    duties: singleOrderContext.singleOrder?.PriceEstimates![0]!.total_duties ? singleOrderContext.singleOrder?.PriceEstimates![0].total_duties: 0,
    taxes: singleOrderContext.singleOrder?.PriceEstimates![0]!.total_taxes ? singleOrderContext.singleOrder?.PriceEstimates![0].total_taxes : 0,
    gst_amount: singleOrderContext.singleOrder?.PriceEstimates![0]!.gst_amount ? singleOrderContext.singleOrder?.PriceEstimates![0].gst_amount : 0,
    amountToPay: singleOrderContext.singleOrder?.PriceEstimates![0]!.total ? singleOrderContext.singleOrder?.PriceEstimates![0].total : 0,
    // charge_ids: singleOrderContext.singleOrder.charge_id?[singleOrderContext.singleOrder.charge_id]:[],
    // pickup_charge: pickup_charge(),
    // is_tax_payable: singleOrderContext.singleOrder.is_tax_payable
  }

  if(current !== 4) return null

  const orderIsStillValid = () => {
    // currently only checks for pickup date
    // should add more in the future, and consider more messages
    if(pickupDateHasExpired(singleOrderContext.singleOrder.pickup_date)){
      return false
    }

    return true
  }

  const renderPriceBreakUp = () => {
    let taxes = parseFloat(orderData.duties) + parseFloat(orderData.taxes) + parseFloat(orderData.gst_amount)
    let shipping_charges = (parseFloat(orderData.total) - taxes)
    // taxes = orderData.is_tax_payable?taxes:0;
    return (
      <>
        <div className="title-block price-breakup">
          <span>{getText('Shipping & Handling')}: </span>
          <span>{`${orderData!.currency} ${shipping_charges.toFixed(2)}`}</span>
        </div>
        {/* <div className="title-block price-breakup">
          <span style={{color: '#050593'}}>{getText('GST/VAT/D&T')}: </span>
          <span>{`${orderData!.currency} ${taxes.toFixed(2)}`}</span>
        </div> */}
      </>
    )
  }

  let amountToPay = orderData.amountToPay
  return (
    <Form
      layout={'vertical'}
      requiredMark={false}
    >
      <div className="single-order-form-container price-form">
        {(!auth.user.hide_price_estimation && !singleOrderContext.errorInPriceEstimate) && 
          <div>
            <Form.Item label={<label className="info-disclaimer">{getText('Total Estimated Cost')}:<InfoCircleOutlined  onClick={() => openNotificationWithIcon('info', getText)}/></label>} className={"single-order-form-title"}>
            </Form.Item>
            <div className="title-block price-breakup">
              <span>{getText('Total Items')}: </span>
              <span>{singleOrderContext.singleOrder.items.length}</span>
            </div>

            {renderPriceBreakUp()}

            <Divider style={{margin: '15px 0'}} />
            <div className="title-block price-breakup" style={{marginBottom: '10px'}}>
              <strong>{getText('Total Charges')}: </strong>
              <strong>{`${orderData!.currency} ${(amountToPay).toFixed(2)}`}</strong>
            </div>

            <Divider style={{margin: '15px 0'}} />

          </div>
        }
        
        <SelectedPaymentMethod 
          orderData={orderData}
          orderType={'SINGLE'}
          orderIsStillValid={orderIsStillValid()}
          context={singleOrderContext.singleOrder}
          orderDirection={'RETURNS'}
        />
        
      </div>
    </Form>
  )
};
export const PriceEstimateFormTitle = (props:PropsType) =>{
  const auth = useAuth();
  let { getText } = useLanguage();
  // const {current, setCurrentState} = props;
  return (
    <div className={"title-block"}>
      <p className={'step-title'}>{auth.user.hide_price_estimation ? 'Create Order' : getText('Price estimate')}</p>
      {/* {current>3 ? <p onClick={()=> setCurrentState(3)} className={"edit-text"}>{getText('Edit details')}</p>:''} */}
    </div>
  )
};