import React from "react";
import moment, {Moment} from 'moment';
import { Button, Form } from "antd";
import {Link} from "react-router-dom";
import {contactCustomerService, countryCodes, serviceDefinitions} from "./constants";
import { useAuth } from "../../auth/services/authContext";
import * as _ from 'lodash';

export const getFormatedDate = (date: Moment | null) => {
  if (date) {
    return date.format('YYYY-MM-DD');
  }
  return null
};

export const getReadableFormatDateForCalendar = (date: Moment | null) => {
  if(date){
    return date.format('ll');
  }
  return null;
}

export const parseDate = (date: string | null ) => {
  if(date){
    return moment(date, 'YYYY-MM-DD')
  }
  return null
}

export const getDateInReadableFormat = (date:string) => {
  if (date) {
    return moment(date).format('YYYY-MM-DD, h:mm A');
  }
  return 'N/A'
}

export const formatDate = (date: any, locale: string) => {
  if(date) {
    return moment(date).format('DD/MM/YY HH:mm');
  }
  return null
}

export const formatDateOnly = (date: string) => {
  if(date) {
    return moment(date).format('DD/MM/YYYY');
  }
  return null
}

export const getPickUpAddressDataForForward = (id: number, pickUpLocations: any) => {
  const data = pickUpLocations?.filter((location: any) => {
    return location.pickup_point_id === id
  });
  const pickupAddresData: any = {};
  pickupAddresData.pickup_location = data[0].pickup_point_id;
  pickupAddresData.pickup_contact_name = data[0].pickup_point_name;
  pickupAddresData.pickup_contact_number = data[0].pickup_point_number;
  pickupAddresData.pickup_address = data[0].pickup_point_address;
  pickupAddresData.pickup_state = data[0].pickup_point_state;
  pickupAddresData.pickup_city = data[0].pickup_point_city;
  pickupAddresData.pickup_postal = data[0].pickup_point_postal;
  pickupAddresData.pickup_country_code = data[0].pickup_point_country;
  return pickupAddresData;
};

export const getPickUpAddressData = (id: number, pickUpLocations: any) => {
  const data = pickUpLocations?.filter((location: any) => {
    return location.id === id
  });
  const pickupAddresData: any = {};
  pickupAddresData.pickup_location = data[0].id;
  pickupAddresData.pickup_contact_name = data[0].contact_person;
  pickupAddresData.pickup_contact_number = data[0].contact_number;
  pickupAddresData.pickup_address = data[0].address;
  pickupAddresData.pickup_state = data[0].state;
  pickupAddresData.pickup_city = data[0].city;
  pickupAddresData.pickup_postal = data[0].postal;
  pickupAddresData.pickup_country_code = data[0].country_code;
  return pickupAddresData;
};

export const getReceiverAddressData = (id: number, receiverLocations: any) => {
  const data = receiverLocations?.filter((location: any) => {
    return location.id === id
  });
  const receiverAddresData: any = {};
  receiverAddresData.consignee_location = data[0].id;
  receiverAddresData.consignee_contact_name = data[0].contact_person;
  receiverAddresData.consignee_contact_number = data[0].contact_number;
  receiverAddresData.consignee_address = data[0].address;
  receiverAddresData.consignee_state = data[0].state;
  receiverAddresData.consignee_city = data[0].city;
  receiverAddresData.consignee_postal = data[0].postal;
  receiverAddresData.consignee_country_code = data[0].country_code;
  receiverAddresData.pickup_date = '2022-08-14';
  return receiverAddresData;
};

export const getReturnsBillingAddressData = (address: any) => {
  const billingAddressData: any = {};
  billingAddressData.billing_contact_name = address.consignee_name;
  billingAddressData.billing_contact_number = address.consignee_number;
  billingAddressData.billing_address = address.consignee_address;
  billingAddressData.billing_state = address.consignee_state;
  billingAddressData.billing_city = address.consignee_city;
  billingAddressData.billing_postal = address.consignee_postal;
  billingAddressData.billing_country = address.consignee_country;
  billingAddressData.billing_country_code =  countryCodes[address.consignee_country];
  return billingAddressData;
}

export const getBillingAddressDataForForward = (id: number, locations: any) => {
  const data = locations?.filter((location: any) => {
    return location.pickup_point_id === id
  });
  const billingAddressData: any = {};
  billingAddressData.billing_location = data[0].pickup_point_id;
  billingAddressData.billing_contact_name = data[0].pickup_point_contact_person;
  billingAddressData.billing_contact_number = data[0].pickup_point_number;
  billingAddressData.billing_address = data[0].pickup_point_address;
  billingAddressData.billing_state = data[0].pickup_point_state;
  billingAddressData.billing_city = data[0].pickup_point_city;
  billingAddressData.billing_postal = data[0].pickup_point_postal;
  billingAddressData.billing_country_code = data[0].pickup_point_country;
  return billingAddressData;
};

export const getBillingAddressData = (id: number, locations: any) => {
  const data = locations?.filter((location: any) => {
    return location.id === id
  });
  const billingAddressData: any = {};
  billingAddressData.billing_location = data[0].id;
  billingAddressData.billing_contact_name = data[0].contact_person;
  billingAddressData.billing_contact_number = data[0].contact_number;
  billingAddressData.billing_address = data[0].address;
  billingAddressData.billing_state = data[0].state;
  billingAddressData.billing_city = data[0].city;
  billingAddressData.billing_postal = data[0].postal;
  billingAddressData.billing_country_code = data[0].country_code;
  return billingAddressData;
};

export const findWordInString = (s:any, word:string) => new RegExp('\\b' + word + '\\b', 'i').test(s);

export const getAuthErrorContent = (errorData:any) => {
  let emailExists = 'This email is already registered with us, please try logging in'
  let activateEmail = 'This email is already registered with us, please check your email inbox, spam or junk folder for a verification link. If you do not receive the verification email, click here to'
  return (
    <ul className={'error-list-body'}>
      {
        Object.keys(errorData).map((key) => {
          let errorMessage='';
          errorData[key].forEach((error:any) => {
            errorMessage = errorMessage + error
          });
          if(findWordInString(Object.values(errorData), 'registered with our system')) {
            return (
              <>
                <li className={'error-list-item'} key={key}>
                  <strong>{key}</strong>{': '+ emailExists}
                </li>
                <Link to={`/auth/login`}>
                  <Button type="primary" size={"small"} style={{background: '#050593', borderColor: '#050593'}}>Login</Button>
                </Link>
              </>
            )
          }
          if(findWordInString(Object.values(errorData), 'not activated')) {
            return (
              <>
                <li className={'error-list-item'} key={key}>
                  <strong>{key}</strong>{': '+ activateEmail} <Link  style={{color: '#050593', fontWeight: 600}} to={`/auth/resend_verification`}><span> resend.</span></Link>
                </li>
              </>
            )
          }

          if(findWordInString(Object.keys(errorData), 'non_field_errors')) {
            return (
              <>
                <li className={'error-list-item'} key={key}>
                  <strong>Error</strong>{': '+ errorMessage}
                </li>

                { findWordInString(Object.values(errorData), 'with provided credentials') ? 
                  <>
                    <Form.Item className={'form-error-desc'}>
                      Do you need help <a href="/auth/reset_password" className={"form-text"}> logging in?</a>
                    </Form.Item> 
                  </> : 
                  <>
                    <p>Please contact us at {contactCustomerService()}</p>
                  </> 
                }
              </>
            )
          }
          return (
            <li className={'error-list-item'} key={key}>
              <strong>{key}</strong>{': '+ errorMessage}
            </li>
          )
        })
      }
    </ul>
  );
};
export const getTableRow = (fName:any,orderIndex:number,order:any, orderRates:any, index:number, extraData:any) => {
  return {
    key:orderIndex,
    index: index,
    orderIndex: orderIndex,
    fName:fName,
    consignee_name: order.consignee_name,
    item_quantity: extraData.item_quantity,
    item_description: extraData.item_description,
    weight: order.order_weight,
    dimension: order.order_length+'x'+order.order_width+'x'+order.order_height+' cm',
    price_estimate: `${(parseFloat(orderRates[orderIndex].total) + orderRates[orderIndex].pickup_charge).toFixed(2)} ${orderRates[orderIndex].currency} `,
    total_price: orderRates[orderIndex].total,
    chargable_weight: orderRates[orderIndex].chargable_weight,
    gst_amount: orderRates[orderIndex].gst_amount,
    currency: orderRates[orderIndex].currency,
    total_duties: orderRates[orderIndex].total_duties,
    total_taxes: orderRates[orderIndex].total_taxes,
    is_tax_payable: orderRates[orderIndex].is_tax_payable,
    weight_upper_bound: orderRates[orderIndex].weight_upper_bound,
    pickup_charge: orderRates[orderIndex].pickup_charge,
    incoterm: order.incoterm || 'DDP',
    payment_type: order.payment_type || 'prepaid',
    cod_amount: order.payment_type==='cod' ?order.cod_amount : undefined
  }
};

export const getTableData = (orders:any, orderRates:any) => {
  if(orders){
    const tableData:any = {};
    const selectedOrders:any = {};
    const overallEstimates:any = {shipmentsCount:0, estimatedPrice:0.00};
    orders.forEach((order: any, orderIndex: number) => {
      const fName = `${order.pickup_country}-${order.consignee_country}`;
      let extraData = {
        item_quantity: 0,
        item_description: '',
        price_estimate:''
      };
      order.items?.map((item:any) =>{
        extraData.item_quantity = extraData.item_quantity + parseInt(item.item_quantity);
        extraData.item_description =  extraData.item_description ?  `${extraData.item_description}, ${item.item_desc}`: item.item_desc;
        return extraData
      });
      let totalDutiesAndTaxes = parseFloat(orderRates[orderIndex].total_taxes)+ parseFloat(orderRates[orderIndex].total_duties)
      if (tableData.hasOwnProperty(fName) && selectedOrders.hasOwnProperty(fName)) {
        Object.assign(selectedOrders[fName].indexes, {[orderIndex]: true});
        selectedOrders[fName].totalPrice = selectedOrders[fName].totalPrice + (parseFloat(orderRates[orderIndex].total) + parseFloat(orderRates[orderIndex].pickup_charge?orderRates[orderIndex].pickup_charge:0)) - (orderRates[orderIndex].is_tax_payable?0:totalDutiesAndTaxes);
        selectedOrders[fName].priceBreakUp = {
          gst_amount: selectedOrders[fName].priceBreakUp.gst_amount + parseFloat(orderRates[orderIndex].gst_amount),
          pickup_charge: selectedOrders[fName].priceBreakUp.pickup_charge + parseFloat(orderRates[orderIndex].pickup_charge?orderRates[orderIndex].pickup_charge:0),
          total_duties: selectedOrders[fName].priceBreakUp.total_duties + (orderRates[orderIndex].is_tax_payable?parseFloat(orderRates[orderIndex].total_duties):0),
          total_taxes: selectedOrders[fName].priceBreakUp.total_taxes + (orderRates[orderIndex].is_tax_payable?parseFloat(orderRates[orderIndex].total_taxes):0),
          total: selectedOrders[fName].priceBreakUp.total + (orderRates[orderIndex].is_tax_payable?parseFloat(orderRates[orderIndex].total):(parseFloat(orderRates[orderIndex].total)-parseFloat(orderRates[orderIndex].total_taxes)-parseFloat(orderRates[orderIndex].total_duties)))
        }
        tableData[fName].push(getTableRow(fName,orderIndex, order, orderRates,tableData[fName].length +1, extraData))
      } else {
        tableData[fName] = [];
        selectedOrders[fName] = {
          indexes:{},
          is_tax_payable: orderRates[orderIndex].is_tax_payable,
          totalPrice: (parseFloat(orderRates[orderIndex].total) + parseFloat(orderRates[orderIndex].pickup_charge?orderRates[orderIndex].pickup_charge:0))- (orderRates[orderIndex].is_tax_payable?0:totalDutiesAndTaxes),
          priceBreakUp: {
            gst_amount: parseFloat(orderRates[orderIndex].gst_amount) ? parseFloat(orderRates[orderIndex].gst_amount) : 0,
            pickup_charge: parseFloat(orderRates[orderIndex].pickup_charge),
            total_duties: orderRates[orderIndex].is_tax_payable?parseFloat(orderRates[orderIndex].total_duties):0,
            total_taxes: orderRates[orderIndex].is_tax_payable?parseFloat(orderRates[orderIndex].total_taxes):0,
            total: orderRates[orderIndex].is_tax_payable?parseFloat(orderRates[orderIndex].total):(parseFloat(orderRates[orderIndex].total)-parseFloat(orderRates[orderIndex].total_duties)-parseFloat(orderRates[orderIndex].total_taxes))
          },
          currency: orderRates[orderIndex].currency
        };

        Object.assign(selectedOrders[fName].indexes, {[orderIndex]: true});
        tableData[fName].push(getTableRow(fName,orderIndex, order,orderRates, 1, extraData));
      }
      overallEstimates.estimatedPrice = overallEstimates.estimatedPrice + (parseFloat(orderRates[orderIndex].total) + parseFloat(orderRates[orderIndex].pickup_charge?orderRates[orderIndex].pickup_charge:0)) - (orderRates[orderIndex].is_tax_payable?0:totalDutiesAndTaxes)
    });
    overallEstimates.shipmentsCount = orderRates.length;
    const result = {tableData: tableData, selectedOrders: selectedOrders, overallEstimates: overallEstimates};

    return(result)
  }
  return null;
};

export const round = (amount: any, precision: number) => {
  let multiplier = Math.pow(10, precision || 0);
  return Math.ceil(amount * multiplier) / multiplier;
}

export const getCurrentTimeStamp = () => {
  return moment().format('yyyyMMDD HH:mm:ss.SSS')
}

export const formatOpeningHours = (openingHours:any) => {
  if(openingHours && openingHours.length>0){
    let formattedHours= openingHours.map((item:any) => {
      let splitOpeningHours = item.split(" ");
      return splitOpeningHours[1]+' ('+splitOpeningHours[0].substr(0,3)+')';
    })
    return formattedHours;
  }
}

export const getDropOffAddressDataForBulkOrder = (id:any, locations: any) => {
  const data = locations?.filter((location: any) => {
    return location.dropoff_id === parseInt(id)
  });
  const DropOffAddressData: any = {};
  DropOffAddressData.id = data? data[0]?.dropoff_id: null;
  DropOffAddressData.name = data? `${data[0]?.dropoff_point_contact_person} ${data[0]?.service_type}`: null;
  DropOffAddressData.vendor_name = data? data[0]?.dropoff_point_contact_person: null;
  DropOffAddressData.type = data? data[0]?.service_type: null;
  DropOffAddressData.contact_number = data? data[0]?.dropoff_point_number: null;
  DropOffAddressData.address = data? data[0]?.dropoff_address: null;
  DropOffAddressData.dropOff_city = data? data[0]?.dropoff_point_city: null;
  DropOffAddressData.dropOff_postal = data? data[0]?.dropoff_postal: null;
  DropOffAddressData.dropOff_state = data? data[0]?.dropoff_point_state: null;
  DropOffAddressData.dropOff_country = data? data[0]?.dropoff_point_country: null;
  // DropOffAddressData.opening_hours = formatOpeningHours(data[0]?.opening_hours)
  return DropOffAddressData;
};

export const getDropOffAddressData = (id:any, locations: any) => {
  const data = locations?.filter((location: any) => {
    return location.dropoff_id === parseInt(id)
  });
  const DropOffAddressData: any = {};
  DropOffAddressData.id = data[0]?.dropoff_id;
  DropOffAddressData.name = `${data[0]?.name} ${data[0]?.type}`;
  DropOffAddressData.vendor_name = data[0]?.type;
  DropOffAddressData.type = data[0]?.name;
  DropOffAddressData.contact_number = data[0]?.contact_number;
  DropOffAddressData.address = data[0]?.address;
  DropOffAddressData.dropOff_city = data[0]?.city;
  DropOffAddressData.dropOff_postal = data[0]?.postal;
  DropOffAddressData.dropOff_state = data[0]?.state;
  DropOffAddressData.dropOff_country = data[0]?.country;
  DropOffAddressData.opening_hours = formatOpeningHours(data[0]?.opening_hours)
  return DropOffAddressData;
};

export const getDropOffWarehouseAddressData = (id:any, locations: any) => {
  const data = locations?.filter((location: any) => {
    return location.dropoff_id === parseInt(id)
  });
  
  const DropOffAddressData: any = {};
  DropOffAddressData.id = data[0]?.dropoff_id;
  DropOffAddressData.name = `${data[0]?.dropoff_point_contact_person}`;
  DropOffAddressData.contact_number = data[0]?.dropoff_point_number;
  DropOffAddressData.address = data[0]?.dropoff_address;
  DropOffAddressData.dropOff_city = data[0]?.dropoff_point_city;
  DropOffAddressData.dropOff_postal = data[0]?.dropoff_postal;
  DropOffAddressData.dropOff_state = data[0]?.dropoff_point_state;
  DropOffAddressData.dropOff_country = data[0]?.dropoff_point_country;
  return DropOffAddressData;
};

export const featureFlag = () => {
  let location = window.location.hostname
  let locationCheck = location.includes('localhost') || location.includes('staging')
  return locationCheck
}

export const AuthTokenFlag = () => {
  let auth = useAuth()
  const authToken = auth.user.authToken;

  let allowedTokens = [
    'cf93deb1f1ea51b3e09556b63d17c69821c9db64', // meg.bits@gmail.com
    '1cb782794cf92615036f24fd1fe1203c37324c7f', // ashish.raghav@janio.asia
    'e338b185c4018ee60db449dddd0286b4f80df078', // Ashish Prod account ashish.raghav@janio.asia
    '690d5951ec62c31cdccb8174f5b20fb9fa5d7bcd' // Justina prod account florance.arogyaswamy+1@janio.asia
  ]

  if(allowedTokens.includes(authToken) || featureFlag()) {
    return true
  }
  return false
}

export const capitalizeString = (text:string) => {
  return _.capitalize(text);
}

export const capitalizeEveryFirstLetterString = (text: string) => {
  if(!text){
    return ''
  }
  if(text.toLowerCase() === 'us' || text.toLowerCase() === 'uk'){
    return text.toUpperCase();
  }
  const words = text.split(" ");
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substring(1).toLowerCase();
  }
  return words.join(" ");
}

export const replaceString = (text:string,pattern:string,replaceWith:string) => {
  return text.replaceAll(pattern,replaceWith);
}

export const getFormattedServiceTypeText = (service:string) => {
  if(service === 'Pickup'){
    return "Pickup from your selected address"
  }
  // else if(service === 'Dropoff'){
  //   return "Drop-off at our outlet address near you"
  // }
  else if(service === 'Pickup_Express' || service === 'Pickup Express'){
    return "Pickup Express"
  }
  else if(service === 'Dropoff_Express' || service === 'Dropoff Express'){
    return "Dropoff Express"
  }
  else if(service === 'Pickup Express Priority'){
    return "Express Priority: Pickup from your selected address"
  }
  else if(service === 'Dropoff Express Priority'){
    return "Express Priority: Drop-off at our warehouses"
  }
  else if(service === 'Dropoff' || service === 'Dropoff_WH'){
    return "Drop-off at our warehouses"
  }
  else if(service === 'CC_LM' || service === 'Customs clearance and last mile'){
    return "Customs clearance and last mile"
  }
  else if(service === 'Pickup_FM'){
    return "First Mile Only (Pickup)"
  }
  else if(service === 'DELIVERY_CHAIR'){
    return 'Delivery (Chair)'
  }
  else if(service === 'DELIVERY_AND_ASSEMBLY_CHAIR'){
    return 'Delivery and Assembly (Chair)'
  }
  else if(service === 'ASSEMBLY_CHAIR'){
    return 'Assembly (Chair)'
  }
  else if(service === 'EXCHANGE_DELIVERY_CHAIR'){
    return 'Exchange Delivery (Chair)'
  }
  else if(service === 'EXCHANGE_DEL_AND_ASM_CHAIR'){
    return 'Exchange Delivery and Assembly (Chair)'
  }
  else if(service === 'RETURNS_CHAIR'){
    return 'Returns (Chair)'
  }
  else if(!service){
    return 'Pickup from your selected address'
  }
  else {
    return service.replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  }
}

export const getFormattedServiceTypeTextForShipmentRules = (service:string) => {
  if(service === 'Pickup'){
    return "Pickup from your selected address"
  }
  // else if(service === 'Dropoff'){
  //   return "Drop-off at our outlet address near you"
  // }
  else if(service === 'Pickup_Express' || service === 'Pickup Express'){
    return "Pickup Express"
  }
  else if(service === 'Dropoff_Express' || service === 'Dropoff Express'){
    return "Dropoff Express"
  }
  else if(service === 'Pickup Express Priority'){
    return "Express Priority: Pickup from your selected address"
  }
  else if(service === 'Dropoff Express Priority'){
    return "Express Priority: Drop-off at our warehouses"
  }
  else if(service === 'Dropoff' || service === 'Dropoff_WH'){
    return "Drop-off at our warehouses"
  }
  else if(service === 'CC_LM' || service === 'Customs clearance and last mile'){
    return "Customs clearance and last mile"
  }
  else if(service === 'Pickup_FM'){
    return "First Mile Only (Pickup)"
  }
  else if(service === 'DELIVERY_CHAIR'){
    return 'Delivery (Chair)'
  }
  else if(service === 'DELIVERY_AND_ASSEMBLY_CHAIR'){
    return 'Delivery and Assembly (Chair)'
  }
  else if(service === 'ASSEMBLY_CHAIR'){
    return 'Assembly (Chair)'
  }
  else if(service === 'EXCHANGE_DELIVERY_CHAIR'){
    return 'Exchange Delivery (Chair)'
  }
  else if(service === 'EXCHANGE_DEL_AND_ASM_CHAIR'){
    return 'Exchange Delivery and Assembly (Chair)'
  }
  else if(service === 'RETURNS_CHAIR'){
    return 'Returns (Chair)'
  }
  else {
    return "Pickup from your selected address"
  }
}
export const getFormattedServiceTypeTextForSS = (service:string) => {
  if(service === 'Pickup'){
    return "Pickup from your selected address"
  }
  else if(service === 'Dropoff'){
    return "Drop-off at our outlet address near you"
  }
  else if(service === 'Pickup_Express' || service === 'Pickup Express'){
    return "Pickup Express"
  }
  else if(service === 'Dropoff_Express' || service === 'Dropoff Express'){
    return "Dropoff Express"
  }
  else if(service === 'Pickup Express Priority'){
    return "Express Priority: Pickup from your selected address"
  }
  else if(service === 'Dropoff Express Priority'){
    return "Express Priority: Drop-off at our warehouses"
  }
  else if(service === 'Dropoff_WH'){
    return "Drop-off at our warehouses"
  }
  else if(service === 'CC_LM' || service === 'Customs clearance and last mile'){
    return "Customs clearance and last mile"
  }
  else if(service === 'Pickup_FM'){
    return "First Mile Only (Pickup)"
  }
  else if(service === 'DELIVERY_CHAIR'){
    return 'Delivery (Chair)'
  }
  else if(service === 'DELIVERY_AND_ASSEMBLY_CHAIR'){
    return 'Delivery and Assembly (Chair)'
  }
  else if(service === 'ASSEMBLY_CHAIR'){
    return 'Assembly (Chair)'
  }
  else if(service === 'EXCHANGE_DELIVERY_CHAIR'){
    return 'Exchange Delivery (Chair)'
  }
  else if(service === 'EXCHANGE_DEL_AND_ASM_CHAIR'){
    return 'Exchange Delivery and Assembly (Chair)'
  }
  else if(service === 'RETURNS_CHAIR'){
    return 'Returns (Chair)'
  }
  else {
    return "Pickup from your selected address"
  }
}

export const filterOnlyRequiredData = (serviceDefinitionWholeData: any, originCountry?: string, destinationCountry?: string, serviceType?: string) => {
  let filteredData = serviceDefinitionWholeData?.filter((address: any) => {
    return (address.source === 'parknparcel' || address.source === 'service')
  }).filter((address: any) => {
    return (originCountry === address?.origin_country && destinationCountry === address?.destination_country && serviceType === address.service_type)
  })
  return filteredData
}

export const filterOnlyRequiredDataFromB2c = (serviceDefinitionWholeData: any, originCountry?: string, destinationCountry?: string, serviceType?: string) => {
  let filteredData = serviceDefinitionWholeData?.filter((address: any) => {
    return (address.source === 'parknparcel' || address.source === 'service')
  }).filter((address: any) => {
    return (originCountry === address?.origin_country && destinationCountry === address?.destination_country && serviceType === address.service_type)
  })
  return filteredData
}

export const getShopifyURL = () => {
  let location = window.location.hostname
  if(location.includes('localhost') || location.includes('staging')){
    return "https://www.shopify.com/partners";
  }
  else{
    return "https://apps.shopify.com/janio-asia";
  }
}

export const sortResponseAlphabatically = (res: any, keyName: any) => {
  return res?.sort(function(a: any, b: any) {
    let key_name = keyName
    let textA = a[key_name].toUpperCase();
    let textB = b[key_name].toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
}

export function getServiceOptions(serviceValues: any) {
  const services = Object.entries(serviceDefinitions)
    .filter(([serviceValue, _]) => serviceValues.includes(serviceValue))
    .map(([_, serviceDef]) => serviceDef.service)

  return services.filter((value, index, array) => array.indexOf(value) === index);
}

export const serviceTypeMapper = (serviceType: string) => {
  if(serviceType === 'First Mile (pickup/dropoff) + Airfreight') {
    return 'PICKUP_AF'
  }
  if(serviceType === 'First Mile (pickup/dropoff) + Airfreight + Customs + Lastmile') {
    return 'PICKUP_AF_CC_LM'
  }
  if(serviceType === 'First Mile Only (pickup/dropoff)') {
    return 'PICKUP'
  }
  if(serviceType === 'First Mile (pickup/dropoff) + Delivery') {
    return 'PICKUP_LM'
  }
  
  if(serviceType.includes('No Consol')) {
    return 'PICKUP_AF_CC_LM_NO_CONSO' 
  }
}
export const reverseServiceMapper = (service: string) => {
  if(service === 'PICKUP_AF') {
    return 'First Mile (pickup/dropoff) + Airfreight'
  }
  if(service === 'PICKUP_AF_CC_LM') {
    return 'First Mile (pickup/dropoff) + Airfreight + Customs + Lastmile'
  }
  if(service === 'PICKUP') {
    return 'First Mile Only (pickup/dropoff)'
  }
  if(service === 'PICKUP_LM') {
    return 'First Mile (pickup/dropoff) + Delivery'
  }
  if(service === 'PICKUP_AF_CC_LM_NO_CONSO') {
    return 'PICKUP_AF_CC_LM_NO_CONSO'
  }
}

export const isPickupServiceEnabled = (service: string | null) => {
  return (service==='Pickup' || service==='Pickup Express'  || service==='Pickup Express Priority'
  || service==='DELIVERY_CHAIR' || service==='DELIVERY_AND_ASSEMBLY_CHAIR'
  || service==='ASSEMBLY_CHAIR' || service==='EXCHANGE_DELIVERY_CHAIR'
  || service==='EXCHANGE_DEL_AND_ASM_CHAIR' || service==='RETURNS_CHAIR')
}

export const fileToBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader: any = new FileReader();

    reader.onload = () => {
      const base64String = reader?.result?.split(',')[1];
      resolve(base64String);
    };

    reader.onerror = (error: any) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export const emptyArrayForFileUpload = (selectedOrders: any) => {
  return selectedOrders.map((order: any) => {
    return []
  })
}

export const emptyArrayForFileUploadDamagedParcel = (selectedOrders: any) => {
  return selectedOrders.map((order: any) => {
    return {type:'', id:[]}
  })
}

export const createUploadData =(uploadArray: any) => {
  const uploadObject: any = {}
  uploadArray.forEach((element:any) => {
    uploadObject[element.type] = element.id
  });
  return uploadObject;
}