import { Button, Col, Row, Spin, Tabs, message } from 'antd'
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { getClientInfo, getInvoices } from './services';
import AllInvoicesTab from './components/AllInvoicesTab';
import BalanceTab from './components/BalanceTab';
import InvoicesPaidTab from './components/InvoicesPaidTab';
import Client from './interfaces/Client';
import Invoice from './interfaces/Invoice';
import { generateStatement } from './utils/generating';
import { useAuth } from '../auth/services/authContext';
import { UserClickedGetStatement, UserViewedBillingInvoicesTabs } from '../commons/mixpanel';

const { TabPane } = Tabs;

export const BillingInvoices = () => {
  const auth = useAuth();
  const [tabKey, setTabKey] = useState<string>('balance');
  const [unpaidData, setUnpaidData] = useState<Invoice[]>([]);
  const [paidData, setpaidData] = useState<Invoice[]>([]);
  const [allData, setAllData] = useState<Invoice[]>([]);
  const [unpaidCurrency, setUnpaidCurrency] = useState<string>('');
  const [paidCurrency, setPaidCurrency] = useState<string>('');
  const [unpaidCurrencies, setUnpaidCurrencies] = useState<string[]>([]);
  const [paidCurrencies, setPaidCurrencies] = useState<string[]>([]);
  const [lastFetch, setLastFetch] = useState<string>('');
  const { isLoading, data } = useQuery(['invoices', auth.user.b2c_id], () => getInvoices(), {
    retry: 1,
    onError: (error: any) => {
      console.error(error);
      message.error('Failed to fetch the billing & invoices');
    },
  });

  const clientInfo = useMutation(async () => getClientInfo(), {
    retry: 1,
    onSuccess: (client: Client) => {
      generateStatement(unpaidData, unpaidCurrencies, client);
    },
    onError: (error: any) => {
      console.error(error);
      message.error('Failed to fetch the client info');
    },
    onSettled: () => {
      UserClickedGetStatement();
    }
  });

  const handleTabChange = (key: string) => {
    switch (key) {
    case 'balance':
      setTabKey('balance');
      UserViewedBillingInvoicesTabs('balance');
      break;
    case 'invoicesPaid':
      setTabKey('invoicesPaid');
      UserViewedBillingInvoicesTabs('invoicesPaid');
      break;
    default:
      setTabKey('allInvoices');
      UserViewedBillingInvoicesTabs('allInvoices');
      break;
    };
  };

  const handleTitle = () => {
    const storage = localStorage.getItem('AUTH_SESSION');
    const profile = JSON.parse(storage || '');
    const clientName = <b>{profile.fullName}</b>;
    const displayTime = lastFetch ? <p style={{ fontSize: '14px', fontWeight: 'bold', color: 'gray', paddingTop: '5px'}}>Data Last Updated At: {lastFetch}</p> : <p></p>;
    switch (tabKey) {
    case 'balance':
      return <h3>{clientName} — Payable to Taurus One Private Limited {displayTime}</h3>;
    case 'invoicesPaid':
      return <h3>{clientName} — Paid to Taurus One Private Limited {displayTime}</h3>;
    default:
      return <h3>{clientName} — All Invoices and Credit Notes {displayTime}</h3>;
    }
  };

  const handleGetStatement = () => {
    clientInfo.mutate();
  }

  useEffect(() => {
    if (isLoading || !data) {
      return;
    }

    setUnpaidData(data.unpaid);
    setpaidData(data.paid);
    setAllData(data.all);
    setUnpaidCurrency(data.unpaidCurrencies[0]);
    setPaidCurrency(data.paidCurrencies[0]);
    setUnpaidCurrencies(data.unpaidCurrencies);
    setPaidCurrencies(data.paidCurrencies);
    setLastFetch(data.lastFetch);
  }, [data, isLoading]);

  return (
    <Row style={{ background: '#fff', padding: '25px', position: 'relative' }}>
      <Col span={24}>
        <Row>
          <Col>{handleTitle()}</Col>
          <Col style={{ marginLeft: 'auto', paddingTop: '16pt' }}>
            <Button type="primary" loading={clientInfo.isLoading} disabled={!unpaidData.length} onClick={() => handleGetStatement()}>Get Statement</Button>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Spin spinning={isLoading}>
          <Tabs defaultActiveKey="balance" onChange={handleTabChange}>
            <TabPane tab="Balance" key="balance">
              <BalanceTab data={unpaidData} currency={unpaidCurrency} currencies={unpaidCurrencies} />
            </TabPane>
            <TabPane tab="Invoices Paid" key="invoicesPaid">
              <InvoicesPaidTab data={paidData} currency={paidCurrency} currencies={paidCurrencies} />
            </TabPane>
            <TabPane tab="All Invoices" key="allInvoices">
              <AllInvoicesTab data={allData} />
            </TabPane>
          </Tabs>
        </Spin>
      </Col>
    </Row>
  )
};
