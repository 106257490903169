// import React, { useState, useRef } from 'react'
import {Row, Col, Select, Button, Card} from 'antd';
import * as Yup from 'yup';
import {Formik, Form as FormikForm} from 'formik';
import { FormSelect } from '../../orders/components/Forms';

import { useB2BOrderContext } from "../b2bOrderCreationContext"
import { getAllCountries_from_b2c } from '../../orders/services/privateApi';
import { useQuery } from 'react-query';

const { Option } = Select;
interface PropTypes {
  current: number,
  setCurrentState: (val: number) => void,
}

interface FormValues {
  origin_country: string
  origin_type: string
  origin_address: string
}

const DummyAddresses = [
  {
    "pickup_point_id": 700,
    "pickup_point_name": "Singapore-hub-test-1",
    "pickup_point_address": "SG_client_hub",
    "pickup_point_country": "Singapore",
    "pickup_point_state": "Singapore",
    "pickup_point_city": "Singapore",
    "pickup_point_province": null,
    "pickup_point_postal": "061234",
    "pickup_point_contact_person": "000000",
    "pickup_point_number": "+6523253456",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 701,
    "pickup_point_name": "Singapore-hub-test-2",
    "pickup_point_address": "SG_ninjavan_hub",
    "pickup_point_country": "Singapore",
    "pickup_point_state": "Singapore",
    "pickup_point_city": "Singapore",
    "pickup_point_province": null,
    "pickup_point_postal": "061234",
    "pickup_point_contact_person": "000000",
    "pickup_point_number": "+6523253456",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 702,
    "pickup_point_name": "Singapore-hub-test-3",
    "pickup_point_address": "SG_qxpress_hub",
    "pickup_point_country": "Singapore",
    "pickup_point_state": "Singapore",
    "pickup_point_city": "Singapore",
    "pickup_point_province": null,
    "pickup_point_postal": "061234",
    "pickup_point_contact_person": "000000",
    "pickup_point_number": "+6523253456",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 703,
    "pickup_point_name": "Singapore-hub-test-4",
    "pickup_point_address": "SG_dhl_hub",
    "pickup_point_country": "Singapore",
    "pickup_point_state": "Singapore",
    "pickup_point_city": "Singapore",
    "pickup_point_province": null,
    "pickup_point_postal": "061234",
    "pickup_point_contact_person": "000000",
    "pickup_point_number": "+6523253456",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 704,
    "pickup_point_name": "Singapore-hub-test-5",
    "pickup_point_address": "SG_fedex_hub",
    "pickup_point_country": "Singapore",
    "pickup_point_state": "Singapore",
    "pickup_point_city": "Singapore",
    "pickup_point_province": null,
    "pickup_point_postal": "061234",
    "pickup_point_contact_person": "000000",
    "pickup_point_number": "+6523253456",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 705,
    "pickup_point_name": "Singapore-hub-test-6",
    "pickup_point_address": "SG_singpost_alps_hub",
    "pickup_point_country": "Singapore",
    "pickup_point_state": "Singapore",
    "pickup_point_city": "Singapore",
    "pickup_point_province": null,
    "pickup_point_postal": "061234",
    "pickup_point_contact_person": "000000",
    "pickup_point_number": "+6523253456",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 706,
    "pickup_point_name": "Singapore-port-test-1",
    "pickup_point_address": "SIN",
    "pickup_point_country": "Singapore",
    "pickup_point_state": "Singapore",
    "pickup_point_city": "Singapore",
    "pickup_point_province": null,
    "pickup_point_postal": "650348",
    "pickup_point_contact_person": "65 postal code",
    "pickup_point_number": "+6523253456",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Port"
  },
  {
    "pickup_point_id": 707,
    "pickup_point_name": "Malaysia-hub-test-1",
    "pickup_point_address": "MY_client_hub",
    "pickup_point_country": "Malaysia",
    "pickup_point_state": "Selangor",
    "pickup_point_city": "Shah Alam",
    "pickup_point_province": null,
    "pickup_point_postal": "40400",
    "pickup_point_contact_person": "000000",
    "pickup_point_number": "+60125318196",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 708,
    "pickup_point_name": "Malaysia-hub-test-2",
    "pickup_point_address": "MY_ninjavan_hub",
    "pickup_point_country": "Malaysia",
    "pickup_point_state": "Selangor",
    "pickup_point_city": "Shah Alam",
    "pickup_point_province": null,
    "pickup_point_postal": "40400",
    "pickup_point_contact_person": "000000",
    "pickup_point_number": "+60125318196",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 709,
    "pickup_point_name": "Malaysia-hub-test-3",
    "pickup_point_address": "MY_qxpress_hub",
    "pickup_point_country": "Malaysia",
    "pickup_point_state": "Selangor",
    "pickup_point_city": "Shah Alam",
    "pickup_point_province": null,
    "pickup_point_postal": "40400",
    "pickup_point_contact_person": "000000",
    "pickup_point_number": "+60125318196",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 710,
    "pickup_point_name": "Malaysia-hub-test-4",
    "pickup_point_address": "MY_dhl_hub",
    "pickup_point_country": "Malaysia",
    "pickup_point_state": "Selangor",
    "pickup_point_city": "Shah Alam",
    "pickup_point_province": null,
    "pickup_point_postal": "40400",
    "pickup_point_contact_person": "000000",
    "pickup_point_number": "+60125318196",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 711,
    "pickup_point_name": "Malaysia-hub-test-5",
    "pickup_point_address": "MY_fedex_hub",
    "pickup_point_country": "Malaysia",
    "pickup_point_state": "Selangor",
    "pickup_point_city": "Shah Alam",
    "pickup_point_province": null,
    "pickup_point_postal": "40400",
    "pickup_point_contact_person": "000000",
    "pickup_point_number": "+60125318196",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 712,
    "pickup_point_name": "Malaysia-hub-test-6",
    "pickup_point_address": "MY_pos_malaysia_hub",
    "pickup_point_country": "Malaysia",
    "pickup_point_state": "Selangor",
    "pickup_point_city": "Shah Alam",
    "pickup_point_province": null,
    "pickup_point_postal": "40400",
    "pickup_point_contact_person": "000000",
    "pickup_point_number": "+60125318196",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 713,
    "pickup_point_name": "Malaysia-port-test-1",
    "pickup_point_address": "KUL",
    "pickup_point_country": "Malaysia",
    "pickup_point_state": "Selangor",
    "pickup_point_city": "Shah Alam",
    "pickup_point_province": null,
    "pickup_point_postal": "40400",
    "pickup_point_contact_person": "000000",
    "pickup_point_number": "+60125318196",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Port"
  },
  {
    "pickup_point_id": 714,
    "pickup_point_name": "Malaysia-port-test-2",
    "pickup_point_address": "BKI",
    "pickup_point_country": "Malaysia",
    "pickup_point_state": "Selangor",
    "pickup_point_city": "Shah Alam",
    "pickup_point_province": null,
    "pickup_point_postal": "40400",
    "pickup_point_contact_person": "000000",
    "pickup_point_number": "+60125318196",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Port"
  },
  {
    "pickup_point_id": 715,
    "pickup_point_name": "Malaysia-port-test-3",
    "pickup_point_address": "KCH",
    "pickup_point_country": "Malaysia",
    "pickup_point_state": "Selangor",
    "pickup_point_city": "Shah Alam",
    "pickup_point_province": null,
    "pickup_point_postal": "40400",
    "pickup_point_contact_person": "000000",
    "pickup_point_number": "+60125318196",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Port"
  },
  {
    "pickup_point_id": 716,
    "pickup_point_name": "Indonesia-hub-test-1",
    "pickup_point_address": "ID_client_hub",
    "pickup_point_country": "Indonesia",
    "pickup_point_state": "Aceh",
    "pickup_point_city": "Aceh Barat Daya",
    "pickup_point_province": null,
    "pickup_point_postal": "23111",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "+6223253456",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 717,
    "pickup_point_name": "Indonesia-hub-test-2",
    "pickup_point_address": "ID_ninjavan_hub",
    "pickup_point_country": "Indonesia",
    "pickup_point_state": "Aceh",
    "pickup_point_city": "Aceh Barat Daya",
    "pickup_point_province": null,
    "pickup_point_postal": "23111",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "+6223253456",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 718,
    "pickup_point_name": "Indonesia-hub-test-3",
    "pickup_point_address": "ID_qxpress_hub",
    "pickup_point_country": "Indonesia",
    "pickup_point_state": "Aceh",
    "pickup_point_city": "Aceh Barat Daya",
    "pickup_point_province": null,
    "pickup_point_postal": "23111",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "+6223253456",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 719,
    "pickup_point_name": "Indonesia-hub-test-4",
    "pickup_point_address": "ID_dhl_hub",
    "pickup_point_country": "Indonesia",
    "pickup_point_state": "Aceh",
    "pickup_point_city": "Aceh Barat Daya",
    "pickup_point_province": null,
    "pickup_point_postal": "23111",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "+6223253456",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 720,
    "pickup_point_name": "Indonesia-hub-test-5",
    "pickup_point_address": "ID_fedex_hub",
    "pickup_point_country": "Indonesia",
    "pickup_point_state": "Aceh",
    "pickup_point_city": "Aceh Barat Daya",
    "pickup_point_province": null,
    "pickup_point_postal": "23111",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "+6223253456",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 721,
    "pickup_point_name": "Indonesia-hub-test-6",
    "pickup_point_address": "ID_pos_indo_hub",
    "pickup_point_country": "Indonesia",
    "pickup_point_state": "Aceh",
    "pickup_point_city": "Aceh Barat Daya",
    "pickup_point_province": null,
    "pickup_point_postal": "23111",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "+6223253456",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 722,
    "pickup_point_name": "Indonesia-port-test-1",
    "pickup_point_address": "CGK",
    "pickup_point_country": "Indonesia",
    "pickup_point_state": "Aceh",
    "pickup_point_city": "Aceh Barat Daya",
    "pickup_point_province": null,
    "pickup_point_postal": "23111",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "+6223253456",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Port"
  },
  {
    "pickup_point_id": 723,
    "pickup_point_name": "Philippines-hub-test-1",
    "pickup_point_address": "PH_client_hub",
    "pickup_point_country": "Philippines",
    "pickup_point_state": "Metro Manila",
    "pickup_point_city": "Paranaque City",
    "pickup_point_province": null,
    "pickup_point_postal": "1705",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "+63 917 520 1752",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 724,
    "pickup_point_name": "Philippines-hub-test-2",
    "pickup_point_address": "PH_ninjavan_hub",
    "pickup_point_country": "Philippines",
    "pickup_point_state": "Metro Manila",
    "pickup_point_city": "Paranaque City",
    "pickup_point_province": null,
    "pickup_point_postal": "1705",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "+63 917 520 1752",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 725,
    "pickup_point_name": "Philippines-hub-test-3",
    "pickup_point_address": "PH_dhl_hub",
    "pickup_point_country": "Philippines",
    "pickup_point_state": "Metro Manila",
    "pickup_point_city": "Paranaque City",
    "pickup_point_province": null,
    "pickup_point_postal": "1705",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "+63 917 520 1752",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 726,
    "pickup_point_name": "Philippines-hub-test-4",
    "pickup_point_address": "PH_fedex_hub",
    "pickup_point_country": "Philippines",
    "pickup_point_state": "Metro Manila",
    "pickup_point_city": "Paranaque City",
    "pickup_point_province": null,
    "pickup_point_postal": "1705",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "+63 917 520 1752",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 727,
    "pickup_point_name": "Philippines-port-test-1",
    "pickup_point_address": "MNL",
    "pickup_point_country": "Philippines",
    "pickup_point_state": "Metro Manila",
    "pickup_point_city": "Paranaque City",
    "pickup_point_province": null,
    "pickup_point_postal": "1705",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "+63 917 520 1752",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Port"
  },
  {
    "pickup_point_id": 728,
    "pickup_point_name": "Thailand-hub-test-1",
    "pickup_point_address": "TH_client_hub",
    "pickup_point_country": "Thailand",
    "pickup_point_state": "Samut Prakarn",
    "pickup_point_city": "T.Bangsao Thong",
    "pickup_point_province": null,
    "pickup_point_postal": "10540",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "66944792226",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 729,
    "pickup_point_name": "Thailand-hub-test-2",
    "pickup_point_address": "TH_ninjavan_hub",
    "pickup_point_country": "Thailand",
    "pickup_point_state": "Samut Prakarn",
    "pickup_point_city": "T.Bangsao Thong",
    "pickup_point_province": null,
    "pickup_point_postal": "10540",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "66944792226",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 730,
    "pickup_point_name": "Thailand-hub-test-3",
    "pickup_point_address": "TH_dhl_hub",
    "pickup_point_country": "Thailand",
    "pickup_point_state": "Samut Prakarn",
    "pickup_point_city": "T.Bangsao Thong",
    "pickup_point_province": null,
    "pickup_point_postal": "10540",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "66944792226",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 731,
    "pickup_point_name": "Thailand-hub-test-4",
    "pickup_point_address": "TH_fedex_hub",
    "pickup_point_country": "Thailand",
    "pickup_point_state": "Samut Prakarn",
    "pickup_point_city": "T.Bangsao Thong",
    "pickup_point_province": null,
    "pickup_point_postal": "10540",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "66944792226",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 732,
    "pickup_point_name": "Thailand-port-test-1",
    "pickup_point_address": "BKK",
    "pickup_point_country": "Thailand",
    "pickup_point_state": "Samut Prakarn",
    "pickup_point_city": "T.Bangsao Thong",
    "pickup_point_province": null,
    "pickup_point_postal": "10540",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "66944792226",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Port"
  },
  {
    "pickup_point_id": 734,
    "pickup_point_name": "Asutralia-hub-test-1",
    "pickup_point_address": "AU_client_hub",
    "pickup_point_country": "Australia",
    "pickup_point_state": "NSW",
    "pickup_point_city": "Sydney",
    "pickup_point_province": null,
    "pickup_point_postal": "2036",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "91234567",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 735,
    "pickup_point_name": "Asutralia-hub-test-2",
    "pickup_point_address": "AU_aupost_hub",
    "pickup_point_country": "Australia",
    "pickup_point_state": "NSW",
    "pickup_point_city": "Sydney",
    "pickup_point_province": null,
    "pickup_point_postal": "2036",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "91234567",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 736,
    "pickup_point_name": "Asutralia-hub-test-3",
    "pickup_point_address": "AU_dhl_hub",
    "pickup_point_country": "Australia",
    "pickup_point_state": "NSW",
    "pickup_point_city": "Sydney",
    "pickup_point_province": null,
    "pickup_point_postal": "2036",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "91234567",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 737,
    "pickup_point_name": "Asutralia-hub-test-4",
    "pickup_point_address": "AU_fedex_hub",
    "pickup_point_country": "Australia",
    "pickup_point_state": "NSW",
    "pickup_point_city": "Sydney",
    "pickup_point_province": null,
    "pickup_point_postal": "2036",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "91234567",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 738,
    "pickup_point_name": "Asutralia-port-test-1",
    "pickup_point_address": "MEL",
    "pickup_point_country": "Australia",
    "pickup_point_state": "NSW",
    "pickup_point_city": "Sydney",
    "pickup_point_province": null,
    "pickup_point_postal": "2036",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "91234567",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Port"
  },
  {
    "pickup_point_id": 739,
    "pickup_point_name": "Asutralia-port-test-2",
    "pickup_point_address": "SYD",
    "pickup_point_country": "Australia",
    "pickup_point_state": "NSW",
    "pickup_point_city": "Sydney",
    "pickup_point_province": null,
    "pickup_point_postal": "2036",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "91234567",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Port"
  },
  {
    "pickup_point_id": 740,
    "pickup_point_name": "China-hub-test-1",
    "pickup_point_address": "CN_client_hub",
    "pickup_point_country": "China",
    "pickup_point_state": "Guangdong",
    "pickup_point_city": "Shenzhen",
    "pickup_point_province": null,
    "pickup_point_postal": "518000",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 741,
    "pickup_point_name": "China-hub-test-2",
    "pickup_point_address": "CN_sfexpress_hub",
    "pickup_point_country": "China",
    "pickup_point_state": "Guangdong",
    "pickup_point_city": "Shenzhen",
    "pickup_point_province": null,
    "pickup_point_postal": "518000",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 742,
    "pickup_point_name": "China-hub-test-3",
    "pickup_point_address": "CN_dhl_hub",
    "pickup_point_country": "China",
    "pickup_point_state": "Guangdong",
    "pickup_point_city": "Shenzhen",
    "pickup_point_province": null,
    "pickup_point_postal": "518000",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 743,
    "pickup_point_name": "China-hub-test-4",
    "pickup_point_address": "CN_fedex_hub",
    "pickup_point_country": "China",
    "pickup_point_state": "Guangdong",
    "pickup_point_city": "Shenzhen",
    "pickup_point_province": null,
    "pickup_point_postal": "518000",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 744,
    "pickup_point_name": "China-port-test-1",
    "pickup_point_address": "PEK",
    "pickup_point_country": "China",
    "pickup_point_state": "Guangdong",
    "pickup_point_city": "Shenzhen",
    "pickup_point_province": null,
    "pickup_point_postal": "518000",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Port"
  },
  {
    "pickup_point_id": 745,
    "pickup_point_name": "China-port-test-2",
    "pickup_point_address": "CAN",
    "pickup_point_country": "China",
    "pickup_point_state": "Guangdong",
    "pickup_point_city": "Shenzhen",
    "pickup_point_province": null,
    "pickup_point_postal": "518000",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Port"
  },
  {
    "pickup_point_id": 746,
    "pickup_point_name": "China-port-test-3",
    "pickup_point_address": "CTU",
    "pickup_point_country": "China",
    "pickup_point_state": "Guangdong",
    "pickup_point_city": "Shenzhen",
    "pickup_point_province": null,
    "pickup_point_postal": "518000",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Port"
  },
  {
    "pickup_point_id": 747,
    "pickup_point_name": "China-port-test-4",
    "pickup_point_address": "KMG",
    "pickup_point_country": "China",
    "pickup_point_state": "Guangdong",
    "pickup_point_city": "Shenzhen",
    "pickup_point_province": null,
    "pickup_point_postal": "518000",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Port"
  },
  {
    "pickup_point_id": 748,
    "pickup_point_name": "China-port-test-5",
    "pickup_point_address": "SZX",
    "pickup_point_country": "China",
    "pickup_point_state": "Guangdong",
    "pickup_point_city": "Shenzhen",
    "pickup_point_province": null,
    "pickup_point_postal": "518000",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Port"
  },
  {
    "pickup_point_id": 749,
    "pickup_point_name": "China-port-test-6",
    "pickup_point_address": "XMN",
    "pickup_point_country": "China",
    "pickup_point_state": "Guangdong",
    "pickup_point_city": "Shenzhen",
    "pickup_point_province": null,
    "pickup_point_postal": "518000",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Port"
  },
  {
    "pickup_point_id": 750,
    "pickup_point_name": "SouthKorea-hub-test-1",
    "pickup_point_address": "KR_client_hub",
    "pickup_point_country": "South Korea",
    "pickup_point_state": "Seoul",
    "pickup_point_city": "Gangseo-gu",
    "pickup_point_province": null,
    "pickup_point_postal": "518000",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 751,
    "pickup_point_name": "SouthKorea-hub-test-2",
    "pickup_point_address": "KR_cj_hub",
    "pickup_point_country": "South Korea",
    "pickup_point_state": "Seoul",
    "pickup_point_city": "Gangseo-gu",
    "pickup_point_province": null,
    "pickup_point_postal": "518000",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 752,
    "pickup_point_name": "SouthKorea-hub-test-3",
    "pickup_point_address": "KR_dhl_hub",
    "pickup_point_country": "South Korea",
    "pickup_point_state": "Seoul",
    "pickup_point_city": "Gangseo-gu",
    "pickup_point_province": null,
    "pickup_point_postal": "518000",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 753,
    "pickup_point_name": "SouthKorea-hub-test-4",
    "pickup_point_address": "KR_fedex_hub",
    "pickup_point_country": "South Korea",
    "pickup_point_state": "Seoul",
    "pickup_point_city": "Gangseo-gu",
    "pickup_point_province": null,
    "pickup_point_postal": "518000",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 754,
    "pickup_point_name": "SouthKorea-port-test-1",
    "pickup_point_address": "ICN",
    "pickup_point_country": "South Korea",
    "pickup_point_state": "Seoul",
    "pickup_point_city": "Gangseo-gu",
    "pickup_point_province": null,
    "pickup_point_postal": "518000",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Port"
  },
  {
    "pickup_point_id": 755,
    "pickup_point_name": "Taiwan-hub-test-1",
    "pickup_point_address": "TW_client_hub",
    "pickup_point_country": "Taiwan",
    "pickup_point_state": "Seoul",
    "pickup_point_city": "Gangseo-gu",
    "pickup_point_province": null,
    "pickup_point_postal": "518000",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 756,
    "pickup_point_name": "Taiwan-hub-test-2",
    "pickup_point_address": "TW_dhl_hub",
    "pickup_point_country": "Taiwan",
    "pickup_point_state": "Seoul",
    "pickup_point_city": "Gangseo-gu",
    "pickup_point_province": null,
    "pickup_point_postal": "518000",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 757,
    "pickup_point_name": "Taiwan-hub-test-3",
    "pickup_point_address": "TW_fedex_hub",
    "pickup_point_country": "Taiwan",
    "pickup_point_state": "Seoul",
    "pickup_point_city": "Gangseo-gu",
    "pickup_point_province": null,
    "pickup_point_postal": "518000",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 758,
    "pickup_point_name": "Taiwan-port-test-1",
    "pickup_point_address": "TPE",
    "pickup_point_country": "Taiwan",
    "pickup_point_state": "Seoul",
    "pickup_point_city": "Gangseo-gu",
    "pickup_point_province": null,
    "pickup_point_postal": "518000",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Port"
  },
  {
    "pickup_point_id": 759,
    "pickup_point_name": "NZ-hub-test-1",
    "pickup_point_address": "NZ_client_hub",
    "pickup_point_country": "New Zealand",
    "pickup_point_state": "Seoul",
    "pickup_point_city": "Gangseo-gu",
    "pickup_point_province": null,
    "pickup_point_postal": "518000",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 760,
    "pickup_point_name": "NZ-hub-test-2",
    "pickup_point_address": "NZ_dhl_hub",
    "pickup_point_country": "New Zealand",
    "pickup_point_state": "Seoul",
    "pickup_point_city": "Gangseo-gu",
    "pickup_point_province": null,
    "pickup_point_postal": "518000",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 761,
    "pickup_point_name": "NZ-hub-test-3",
    "pickup_point_address": "NZ_fedex_hub",
    "pickup_point_country": "New Zealand",
    "pickup_point_state": "Seoul",
    "pickup_point_city": "Gangseo-gu",
    "pickup_point_province": null,
    "pickup_point_postal": "518000",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 762,
    "pickup_point_name": "NZ-port-test-1",
    "pickup_point_address": "AKL",
    "pickup_point_country": "New Zealand",
    "pickup_point_state": "Seoul",
    "pickup_point_city": "Gangseo-gu",
    "pickup_point_province": null,
    "pickup_point_postal": "518000",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Port"
  },
  {
    "pickup_point_id": 763,
    "pickup_point_name": "JP-hub-test-1",
    "pickup_point_address": "JP_client_hub",
    "pickup_point_country": "Japan",
    "pickup_point_state": "Seoul",
    "pickup_point_city": "Gangseo-gu",
    "pickup_point_province": null,
    "pickup_point_postal": "518000",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 764,
    "pickup_point_name": "JP-hub-test-2",
    "pickup_point_address": "JP_dhl_hub",
    "pickup_point_country": "Japan",
    "pickup_point_state": "Seoul",
    "pickup_point_city": "Gangseo-gu",
    "pickup_point_province": null,
    "pickup_point_postal": "518000",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 765,
    "pickup_point_name": "JP-hub-test-3",
    "pickup_point_address": "JP_fedex_hub",
    "pickup_point_country": "Japan",
    "pickup_point_state": "Seoul",
    "pickup_point_city": "Gangseo-gu",
    "pickup_point_province": null,
    "pickup_point_postal": "518000",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 766,
    "pickup_point_name": "JP-port-test-1",
    "pickup_point_address": "HND",
    "pickup_point_country": "Japan",
    "pickup_point_state": "Seoul",
    "pickup_point_city": "Gangseo-gu",
    "pickup_point_province": null,
    "pickup_point_postal": "518000",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Port"
  },
  {
    "pickup_point_id": 767,
    "pickup_point_name": "India-hub-test-1",
    "pickup_point_address": "IN_client_hub",
    "pickup_point_country": "India",
    "pickup_point_state": "Andhra Pradesh",
    "pickup_point_city": "Visakhapatnam",
    "pickup_point_province": null,
    "pickup_point_postal": "530040",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 768,
    "pickup_point_name": "India-hub-test-2",
    "pickup_point_address": "IN_dhl_hub",
    "pickup_point_country": "India",
    "pickup_point_state": "Andhra Pradesh",
    "pickup_point_city": "Visakhapatnam",
    "pickup_point_province": null,
    "pickup_point_postal": "530040",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 769,
    "pickup_point_name": "India-hub-test-3",
    "pickup_point_address": "IN_fedex_hub",
    "pickup_point_country": "India",
    "pickup_point_state": "Andhra Pradesh",
    "pickup_point_city": "Visakhapatnam",
    "pickup_point_province": null,
    "pickup_point_postal": "530040",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 770,
    "pickup_point_name": "India-port-test-1",
    "pickup_point_address": "BLR",
    "pickup_point_country": "India",
    "pickup_point_state": "Andhra Pradesh",
    "pickup_point_city": "Visakhapatnam",
    "pickup_point_province": null,
    "pickup_point_postal": "530040",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Port"
  },
  {
    "pickup_point_id": 771,
    "pickup_point_name": "India-port-test-2",
    "pickup_point_address": "HYD",
    "pickup_point_country": "India",
    "pickup_point_state": "Andhra Pradesh",
    "pickup_point_city": "Visakhapatnam",
    "pickup_point_province": null,
    "pickup_point_postal": "530040",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Port"
  },
  {
    "pickup_point_id": 772,
    "pickup_point_name": "India-port-test-3",
    "pickup_point_address": "JLR",
    "pickup_point_country": "India",
    "pickup_point_state": "Andhra Pradesh",
    "pickup_point_city": "Visakhapatnam",
    "pickup_point_province": null,
    "pickup_point_postal": "530040",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Port"
  },
  {
    "pickup_point_id": 773,
    "pickup_point_name": "HK-hub-test-1",
    "pickup_point_address": "HK_client_hub",
    "pickup_point_country": "Hong Kong",
    "pickup_point_state": "Kwai Chung",
    "pickup_point_city": "55 Wing Kei Road",
    "pickup_point_province": null,
    "pickup_point_postal": "530040",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 774,
    "pickup_point_name": "HK-hub-test-2",
    "pickup_point_address": "HK_dhl_hub",
    "pickup_point_country": "Hong Kong",
    "pickup_point_state": "Kwai Chung",
    "pickup_point_city": "55 Wing Kei Road",
    "pickup_point_province": null,
    "pickup_point_postal": "530040",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 775,
    "pickup_point_name": "HK-hub-test-3",
    "pickup_point_address": "HK_fedex_hub",
    "pickup_point_country": "Hong Kong",
    "pickup_point_state": "Kwai Chung",
    "pickup_point_city": "55 Wing Kei Road",
    "pickup_point_province": null,
    "pickup_point_postal": "530040",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Hub"
  },
  {
    "pickup_point_id": 776,
    "pickup_point_name": "HK-port-test-1",
    "pickup_point_address": "HKG",
    "pickup_point_country": "Hong Kong",
    "pickup_point_state": "Kwai Chung",
    "pickup_point_city": "55 Wing Kei Road",
    "pickup_point_province": null,
    "pickup_point_postal": "530040",
    "pickup_point_contact_person": "eleena Marina",
    "pickup_point_number": "13410732580",
    "pickup_point_email": "florance.arogyaswamy@janio.asia",
    "pickup_point_notes": null,
    "is_active": true,
    "pickup_address_type": "Port"
  },
]

const validationSchema = (props: any) => {
  return (
    Yup.object().shape({
      origin_country: Yup.string().required('Please select Origin Country'),
      origin_type: Yup.string().required('Please select Type'),
      origin_address: Yup.string().required('Please select Address')
    })
  )
}

export const ShippingOriginForm = (props: PropTypes) => {
  const orderContextData = useB2BOrderContext()

  const resetFieldValuesOnPickupCountryChange = ['origin_type', 'origin_address'];
  const resetFieldValuesOnOriginTypeChange = ['origin_address'];

  const getAllCountriesData = useQuery('getAllCountriesDataForB2B', () => {
    return getAllCountries_from_b2c()
  })
  const initialValues = {
    origin_country: orderContextData.b2bOrderData.origin_country,
    origin_type: orderContextData.b2bOrderData.origin_type,
    origin_address: orderContextData.b2bOrderData.origin_address
  }

  const onFormikSubmit = (values: FormValues) => {
    props.setCurrentState(1)

    orderContextData.updateB2BOrderData({
      origin_country: values.origin_country,
      origin_type: values.origin_type,
      origin_address: values.origin_address
    })
  }

  if (props.current !== 0) {
    return (
      <>
        {<Card title={'Origin Overview'} className={"form-summary-container"} style={{fontSize: '19px'}}>
          <p style={{ margin: 0 }} className={'gray-text'}>Origin Country: <span style={{color: '#000', fontSize: '17px'}}>{orderContextData.b2bOrderData.origin_country!}</span></p>
          <p className={'gray-text'}>Type: <span style={{color: '#000', fontSize: '17px'}}>{orderContextData.b2bOrderData.origin_type!}</span></p>
          <p className={'gray-text'}>Destination Address: <span style={{color: '#000', fontSize: '17px'}}>{orderContextData.b2bOrderData.origin_address!}</span></p>
        </Card>}
      </>
    )
  }

  return (
    <Row className='claim-main-container single-order-form-container ant-form-vertical'>
      <Col span={24}>
        <Formik 
          initialValues={initialValues}
          onSubmit={onFormikSubmit}
          validationSchema={validationSchema}
        >
          {formik => {
            return (
              <FormikForm className='dispute-main-container'>
                <Col span={24}>
                  <Row gutter={32}>
                    <Col span={8}>
                      <FormSelect name="origin_country" loading={getAllCountriesData.isLoading} label={'Origin Country'} required
                        onChangeCallback={(value: any) => {
                          resetFieldValuesOnPickupCountryChange.forEach(field => formik.setFieldValue(field, ''))
                        }} placeholder='Please select Origin Country'
                      >
                        {
                          getAllCountriesData && getAllCountriesData.data?.map((item: any) => {
                            return <Option key={item} value={item}>{item}</Option>
                          })
                        }
                      </FormSelect>
                      {(getAllCountriesData.isError) && <p style={{ color: 'red', marginBottom: 0, marginTop: '-20px', fontSize: '11px' }}>{"Failed to load origin countries, please try again later"}</p>}
                    </Col>

                    <Col span={8}>
                      <FormSelect name="origin_type" label={'Type'} required onChangeCallback={(value: any) => {
                        resetFieldValuesOnOriginTypeChange.forEach(field => formik.setFieldValue(field, ''))
                      }} placeholder='Please select Type'>
                        {
                          DummyAddresses.filter((item: any) => {
                            return item.pickup_point_country === formik.values.origin_country
                          })
                            .map(address => address.pickup_address_type)
                            .filter((value, index, self) => self.indexOf(value) === index)
                            .map((address: any) => {
                              return <Option key={address} value={address}>{address}</Option>
                            })
                        }
                      </FormSelect>
                    </Col>

                    <Col span={8}>
                      <FormSelect name="origin_address" label={'Address'} required
                        placeholder='Please select Origin Address'>
                        {
                          DummyAddresses.filter((item: any) => {
                            return (item.pickup_address_type === formik.values.origin_type && item.pickup_point_country === formik.values.origin_country)
                          }).map((address: any) => {
                            return <Option key={address.pickup_point_id} value={address.pickup_point_address}>{address.pickup_point_address}</Option>
                          })
                        }
                      </FormSelect>
                    </Col>
                  </Row>

                  <div className={`button-block`}>
                    <Button htmlType="submit" type="primary" className={"blue-button"}>
                      {'Save & Continue'}
                    </Button>
                  </div>
                </Col>
              </FormikForm>
            )
          }
          }
        </Formik>
      </Col>
    </Row>
  )

}

export const ShippingOriginFormTitle = (props: PropTypes) => {
  return (
    <div className={"title-block"}>
      <p className={'step-title'}>{'Where are you shipping from (origin)?'}</p>
      {props.current > 0 ? <p onClick={() => props.setCurrentState(0)} className={"edit-text"}>{'Edit details'}</p> : ''}
    </div>
  )
}