import {
  Card,
  Row,
  Col,
  Spin,
  Image
} from 'antd'
import { Link } from 'react-router-dom'
import home_shipmentCreated from '../../assets/icons/home_shipmentCreated.svg';
import home_inTransit from '../../assets/icons/home_inTransit.svg';
import home_delivered from '../../assets/icons/home_delivered.svg';

interface ShipmentProps{
  b2cSummaryData: any
}

export const ActiveShipment = (props: ShipmentProps) => {
  const {b2cSummaryData} = props
  const {data, isLoading} = b2cSummaryData
  return (
    <>
      {/* <h3 className={'shipment-header'}>Active Shipments</h3> */}
      <Row gutter={[16,16]} className={'summary-container'} style={{marginBottom: '0px'}}>
        <Col span={6} className={'ant-col-row-2'}>
          <Link to={'/orders?status=INFO_RECEIVED'}>
            <Card className={'active-shipment-card'}>
              <Row>
                <Col className={'icon-background'}>
                  <Image className='active-shipment-icon' preview={false} src={home_shipmentCreated}/>
                </Col>
                <Col>
                  {data? <>
                    <p className={'active-shipment-count'}>{data?.order_info_received || 0}</p>
                    <p className={'shipment-summary-type'}>{"Info Received"}</p></>
                    : <Spin spinning={isLoading}/>}                
                </Col>
              </Row>
            </Card>
          </Link>
        </Col>
        <Col span={6} className={'ant-col-row-2'}>
          <Link to={'/orders?status=IN_TRANSIT'}>
            <Card className={'active-shipment-card'}>
              <Row>
                <Col className={'icon-background'}>
                  <Image className='active-shipment-icon' preview={false} src={home_inTransit}/>
                </Col>
                <Col>
                  {data? <>
                    <p className={'active-shipment-count'}>{data?.in_transit || 0}</p>
                    <p className={'shipment-summary-type'}>{"In Transit"}</p></>
                    : <Spin spinning={isLoading}/>}
                </Col>
              </Row>  
            </Card>
          </Link>
        </Col>
        <Col span={6} className={'ant-col-row-2'}>
          <Link to={'/orders?status=COMPLETED'}>
            <Card className={'active-shipment-card'}>
              <Row>
                <Col className={'icon-background'}>
                  <Image className='active-shipment-icon' preview={false} src={home_delivered} />
                </Col>
                <Col>
                  {data? <>
                    <p className={'active-shipment-count'}>{data?.delivered || 0}</p>
                    <p className={'shipment-summary-type'}>{"Delivered"}</p></>
                    : <Spin spinning={isLoading}/>}
                </Col> 
              </Row>
            </Card>
          </Link>
        </Col>   
      </Row>
    </>
  )
}
