import XLSX from "xlsx";
// @ts-ignore
import csv from "csvtojson";
import { countryCodes, paymentTypes,IndonesiaIdentificationTypes, SouthKoreaIdentificationTypes, mostUsedCurrencies } from "../commons/utils/constants";
import { returnDummyConsigneeCountryMapping } from "./helpers";
import { capitalizeEveryFirstLetterString } from "../commons/utils/utilizer";
import { validateBulkOrderPostalCode } from "../commons/utils/validator";
import { v4 as uuidv4 } from 'uuid';

const cleanValue = (value: any) => {
  if (typeof value === "string") {
    return value.trim()
  }
  return value
};

const cleanKey = (k: string) => {
  let cleanedKey = k;
  if (k.includes("(")) {
    cleanedKey = k.substring(0, k.indexOf("(")).trim();
  }
  if (/[\s]+/g.test(k)) {
    cleanedKey = k.substring(0, k.indexOf(" ")).trim();
  }
  return cleanedKey;
};

const removeCommaFromPostalCode: any = (postalCode: string) => {
  const index = postalCode.indexOf(',');
  let output: string = postalCode.slice(0,index) + postalCode.slice(index+1);
  if(output.includes(',')){
    return removeCommaFromPostalCode(output)
  }
  return output;
}


const readCsvAsync = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onloadend = () => {
      resolve(reader.result as string)
    }

    reader.readAsText(file)
  })
}

export const readFileAsJSON = async (file: File) => {
  const filenameLower = file.name.toLowerCase();

  try{
    if (filenameLower.endsWith(".csv")){
      const csvString = await readCsvAsync(file)
      const jsonArray = await csv().fromString(csvString)
      return jsonArray
    }
    else if (filenameLower.endsWith(".xlsx") || filenameLower.endsWith(".xls")) {
      const arrayBuffer = await file.arrayBuffer()

      const wb = XLSX.read(arrayBuffer, {type: 'array', bookVBA: true})
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      const jsonArray = XLSX.utils.sheet_to_json(ws, {defval: ''})
      return jsonArray
    }
    else{
      throw new Error('Unknown file extension')
    }
  }catch (e: any) {
    if(e.message === 'File is password-protected'){
      throw new Error('File is password protected. Please upload file without password protection')
    }
    throw new Error('Failed to parse the file')
  }
}

export interface CleanedRowForward {
  forward_janio_order_id?: string
  shipper_order_id?: string   //shipper_order_id
  tracking_no?: string
  custom_tn?: string
  item_desc?: string
  item_quantity?: string
  item_product_id?: string
  item_product_url?: string
  item_sku?: string
  item_category?: string
  item_price_value?: string
  item_price_currency?: string
  gst_collected?: boolean
  gst_ovr?: string
  pickup_country?: string
  pickup_address?: string
  pickup_city?: string
  pickup_contact_email?: string
  pickup_contact_name?: string
  pickup_contact_number?: string
  pickup_country_code?: string
  pickup_postal?: string
  pickup_province?: string
  pickup_state?: string
  consignee_name?: string
  consignee_number?: string
  consignee_address?: string
  consignee_postal?: string
  consignee_country_code?: string
  consignee_country?: string
  consignee_state?: string
  consignee_city?: string
  consignee_province?: string
  consignee_identifcation_number?: string
  identification_document_name?: string
  consignee_id?: string
  consignee_id_type?: string
  consignee_email?: string
  order_direction?: string
  order_length?: string
  order_width?: string
  order_height?: string
  order_weight?: string
  origin_country?: string
  incoterm?: string
  payment_type?: string
  cod_amt_to_collect?: number | string
  special_instruction?: string
}

export interface CleanedRowReturns {
  forward_janio_order_id?: string
  shipper_order_id?: string
  tracking_no?: string
  item_desc?: string
  item_quantity?: string
  item_product_id?: string
  item_sku?: string
  item_category?: string
  item_price_value?: string
  item_price_currency?: string  
  pickup_country?: string
  pickup_address?: string
  pickup_city?: string
  pickup_contact_email?: string
  pickup_contact_name?: string
  pickup_contact_number?: string
  pickup_country_code?: string
  pickup_postal?: string
  pickup_province?: string
  pickup_state?: string
  consignee_name?: string
  consignee_number?: string
  consignee_address?: string
  consignee_postal?: string
  consignee_country_code?: string
  consignee_country?: string
  consignee_state?: string
  consignee_city?: string
  consignee_province?: string
  consignee_id?: string
  consignee_id_type?: string
  consignee_email?: string
  order_direction?: string
  order_length?: string
  order_width?: string
  order_height?: string
  order_weight?: string
  origin_country?: string
}

export interface CleanedRow {
  forward_janio_order_id?: string
  tracking_no?: string
  custom_tn?: string
  item_desc?: string
  item_quantity?: string
  item_product_id?: string
  item_product_url?: string
  item_sku?: string
  item_category?: string
  item_price_value?: string
  item_price_currency?: string
  gst_collected?: boolean
  gst_ovr?: string
  pickup_country?: string
  pickup_address?: string
  pickup_city?: string
  pickup_contact_email?: string
  pickup_contact_name?: string
  pickup_contact_number?: string
  pickup_country_code?: string
  pickup_postal?: string
  pickup_province?: string
  pickup_state?: string
  consignee_name?: string
  consignee_number?: string
  consignee_address?: string
  consignee_postal?: string
  consignee_country_code?: string
  consignee_country?: string
  consignee_state?: string
  consignee_city?: string
  consignee_province?: string
  consignee_id?: string
  consignee_id_type?: string
  consignee_email?: string
  order_direction?: string
  order_length?: string
  order_width?: string
  order_height?: string
  order_weight?: string
  origin_country?: string
  incoterm?: string
  payment_type?: string
  cod_amount?: number
}

export interface CleanedMpsRow {
  package_tracking_no:string
  package_custom_tn:string
  cod_amount:number
  consignee_address:string
  consignee_city:string
  consignee_country:string
  consignee_country_code:string
  consignee_email:string
  consignee_identifcation_number:string
  consignee_name:string
  consignee_number:string
  consignee_postal:string
  consignee_province:string
  consignee_state:string
  custom_tn:string
  identification_document_name:string
  incoterm:string 
  item_category:string
  item_desc:string
  item_price_currency:string
  item_price_value:string
  item_product_id:string
  item_quantity:string
  item_sku:string
  gst_collected?: boolean
  gst_ovr?: string
  order_height:string
  order_length:string
  order_weight:string
  order_width:string
  package_no:string
  payment_type:string
  pickup_country:string
  tracking_no:string
}

export const cleanMpsColumns = (rows: Array<Record<string, any>>) => {
  const cleanedObjects: Array<CleanedMpsRow> = [];
  for (let entry of rows) {
    const cleanedObject: any= {};

    for (let key in entry) {
      let cleanedKey = cleanKey(key);
      let cleanedValue = cleanValue(entry[key]);
      if(cleanedKey === 'child_tracking_no'){
        cleanedObject['package_tracking_no'] = cleanedValue
      }
      if(cleanedKey === 'shipper_order_id'){
        cleanedObject['tracking_no'] = cleanedValue
      }
      if(cleanedKey === 'order_length'){
        cleanedObject['package_length'] = cleanedValue
      }
      if(cleanedKey === 'order_weight'){
        cleanedObject['package_weight'] = cleanedValue
      }
      if(cleanedKey === 'order_height'){
        cleanedObject['package_height'] = cleanedValue
      }
      if(cleanedKey === 'order_width'){
        cleanedObject['package_width'] = cleanedValue
      }
      if(cleanedKey === 'package_order_id'){
        cleanedObject['package_no'] = cleanedValue
      }
      if(cleanedKey === 'consignee_country'){
        let country_code = countryCodes[cleanedValue]
        cleanedObject['consignee_country_code'] = country_code
      }
      if(cleanedKey === 'custom_tn'){
        cleanedValue = String(cleanedValue)
      }
      if(cleanedKey === "identification_document_name") {
        const idTypeIndoEnum = Object.keys(IndonesiaIdentificationTypes).find(key => IndonesiaIdentificationTypes[key] === cleanedValue)
        const idTypeSouthKoreaEnum = Object.keys(SouthKoreaIdentificationTypes).find(key => SouthKoreaIdentificationTypes[key] === cleanedValue)
        cleanedValue = idTypeIndoEnum? idTypeIndoEnum : idTypeSouthKoreaEnum? idTypeSouthKoreaEnum : cleanedValue;
      }
      if(cleanedKey === 'incoterm') {
        if(cleanedValue.toUpperCase() === 'DDP'){
          cleanedValue = "DDP"
        }
        else if(cleanedValue.toUpperCase() === 'DDU'){
          cleanedValue = "DDU"
        }
        else {
          cleanedValue = "DDP"
        }
      }

      if(cleanedKey === 'payment_type'){
        if(cleanedValue.toLowerCase() === 'prepaid'){
          cleanedValue = "prepaid"
        }
        else if(cleanedValue.toLowerCase() === 'cod'){
          cleanedValue = "cod"
        }
        else{
          cleanedValue = "prepaid"
        }
      }

      // For GST Ovr value related
      if(cleanedKey === 'gst_ovr') {
        if(cleanedValue === ''){
          cleanedValue = null
        }
      }

      // For GST collected related
      if(cleanedKey === 'gst_collected') {
        if(cleanedValue === ''){
          cleanedValue = null
        }
        if(cleanedValue?.toUpperCase() === 'NO'){
          cleanedValue = false
        }else if(cleanedValue?.toUpperCase() === 'YES'){
          cleanedValue = true
        } else {
          cleanedValue = false
        }
      }

      if(cleanedKey === 'cod_amt_to_collect'){
        let codAmount = (cleanedObject['payment_type'] === 'prepaid'? undefined:(cleanedValue === ""?0:cleanedValue))
        cleanedKey = "cod_amount"
        cleanedValue = codAmount
      }

      cleanedObject[cleanedKey] = cleanedValue
    }
    if(!Object.keys(cleanedObject).includes('payment_type')){
      cleanedObject['payment_type'] = 'prepaid'
    }
    cleanedObjects.push(cleanedObject as CleanedMpsRow);
  }
  return cleanedObjects;
}

export const cleanColumnsForwardOrder = (rows: Array<Record<string, any>>, order_direction?: string) => {
  const cleanedObjects: Array<CleanedRowForward> = [];
  for (let entry of rows) {
    const cleanedObject: any= {};
    let csvType: string;
    if(entry.hasOwnProperty('tracking_no (dont leave blank)') || entry.hasOwnProperty('tracking_no (shipper_order_id  dont leave blank)') || entry.hasOwnProperty('tracking_no (shipper_order_id dont leave blank)')){
      csvType = 'mp2'
    }else{
      csvType = 'mp1'
    }
    for (let key in entry) {
      let cleanedKey = cleanKey(key);
      let cleanedValue = cleanValue(entry[key]);
      if (["tracking_no", "shipper_order_id"].includes(cleanedKey) && csvType === 'mp2') {
        cleanedValue = String(cleanedValue)
        cleanedKey = "shipper_order_id"
      }
      if(cleanedKey === "consignee_id_type" || cleanedKey === "identification_document_name") {
        const idTypeIndoEnum = Object.keys(IndonesiaIdentificationTypes).find(key => IndonesiaIdentificationTypes[key] === cleanedValue)
        const idTypeSouthKoreaEnum = Object.keys(SouthKoreaIdentificationTypes).find(key => SouthKoreaIdentificationTypes[key] === cleanedValue)
        cleanedValue = idTypeIndoEnum? idTypeIndoEnum : idTypeSouthKoreaEnum? idTypeSouthKoreaEnum : cleanedValue;
        cleanedKey = 'identification_document_name'
      }
      if(cleanedKey === 'consignee_id'){
        cleanedKey = 'consignee_identifcation_number'
      }
      if(cleanedKey === 'consignee_country'){
        cleanedValue = capitalizeEveryFirstLetterString(String(cleanedValue))
        let country_code = countryCodes[cleanedValue]
        cleanedObject['consignee_country_code'] = country_code
      }
      if(cleanedKey === 'consignee_postal' && String(cleanedValue).includes(',')){
        cleanedValue = Number(removeCommaFromPostalCode(cleanedValue));
      }
      if(cleanedKey === 'custom_tn'){
        cleanedValue = String(cleanedValue)
        cleanedKey = "tracking_no"
      }
      if(cleanedKey === 'origin_country' || cleanedKey === 'pickup_country'){
        cleanedKey = 'pickup_country'
        cleanedValue = capitalizeEveryFirstLetterString(String(cleanedValue))
      }
      if(cleanedKey === 'incoterm') {
        if(/^[a-zA-Z]+$/.test(cleanedValue) && cleanedValue.toUpperCase() === 'DDP'){
          cleanedValue = "DDP"
        }
        else if(/^[a-zA-Z]+$/.test(cleanedValue) && cleanedValue.toUpperCase() === 'DDU'){
          cleanedValue = "DDU"
        }
      }

      if(cleanedKey === 'payment_type'){
        if(cleanedValue.toLowerCase() === 'prepaid'){
          cleanedValue = "prepaid"
        }
        else if(cleanedValue.toLowerCase() === 'cod'){
          cleanedValue = "cod"
        }
        else{
          cleanedValue = "prepaid"
        }
      }

      // For GST Ovr value related
      if(cleanedKey === 'gst_ovr') {
        if(cleanedValue === ''){
          cleanedValue = null
        }
      }

      // For GST collected related
      if(cleanedKey === 'gst_collected') {
        if(cleanedValue === ''){
          cleanedValue = null
        }
        if(cleanedValue?.toString().toUpperCase() === 'NO' || cleanedValue === false){
          cleanedValue = false
        }else if(cleanedValue?.toString().toUpperCase() === 'YES' || cleanedValue === true){
          cleanedValue = true
        } else {
          cleanedValue = false
        }
      }

      if(cleanedKey === 'cod_amt_to_collect'){
        let codAmount = (cleanedObject['payment_type'] === 'prepaid'? undefined:(cleanedValue === ""?0:cleanedValue))
        // cleanedKey = "cod_amount"
        cleanedValue = codAmount
      }

      if(order_direction === 'RETURNS') {
        let dataObject = returnDummyConsigneeCountryMapping(cleanedObject['consignee_country_code'])
        cleanedObject['consignee_name'] = dataObject.consignee_name
        cleanedObject['consignee_number'] = dataObject.consignee_number
        cleanedObject['consignee_address'] = dataObject.consignee_address
        cleanedObject['consignee_state'] = dataObject.consignee_state
        cleanedObject['consignee_postal'] = dataObject.consignee_postal
      }


      cleanedObject[cleanedKey] = cleanedValue
    }
    if(!Object.keys(cleanedObject).includes('payment_type')){
      cleanedObject['payment_type'] = 'prepaid'
    }
    cleanedObjects.push(cleanedObject as CleanedRowForward);
  }
  return cleanedObjects;
}

export const cleanColumns = (rows: Array<Record<string, any>>, order_direction?: string) => {
  const cleanedObjects: Array<CleanedRowReturns> = [];
  for (let entry of rows) {
    const cleanedObject: any= {};
    let csvType: string;
    if(entry.hasOwnProperty('shipper_order_id (optional)') && entry.hasOwnProperty('tracking_no (leave blank for Janio system to auto-generate)')){
      csvType = 'mp1'
    }else{
      csvType = 'mp2'
    }
    for (let key in entry) {
      let cleanedKey = cleanKey(key);
      let cleanedValue = cleanValue(entry[key]);
      if (["tracking_no", "shipper_order_id"].includes(cleanedKey) && csvType === 'mp2') {
        cleanedValue = String(cleanedValue)
        cleanedKey = "shipper_order_id"
      }
      if(['pickup_email', 'pickup_contact_email'].includes(cleanedKey)){
        cleanedKey = 'pickup_contact_email'
      }
      if(['consignee_country', 'return_country'].includes(cleanedKey)){
        cleanedKey = 'consignee_country'
        cleanedValue = cleanedValue ? capitalizeEveryFirstLetterString(String(cleanedValue)): ''
        let country_code = countryCodes[cleanedValue]
        cleanedObject['consignee_country_code'] = country_code
      }
      if(cleanedKey === 'pickup_country'){
        cleanedValue = cleanedValue ? capitalizeEveryFirstLetterString(String(cleanedValue)): ''
      }
      if(['pickup_name', 'pickup_contact_name'].includes(cleanedKey)){
        cleanedKey = 'pickup_contact_name'
      }
      cleanedObject[cleanedKey] = cleanedValue
    }
    cleanedObjects.push(cleanedObject as CleanedRow);
  }
  return cleanedObjects;
}

const REQUIRED_COLUMNS = [
  'pickup_country',
  'consignee_number',
  'consignee_name',
  'consignee_address',
  'consignee_postal',
  'consignee_state',
  'consignee_country',
  'consignee_city',
  'item_desc',
  'item_quantity',
  'item_category',
  'item_price_currency',
  'item_price_value',
  'order_length',
  'order_width',
  'order_height',
  'order_weight'
];

const REQUIRED_COLUMNS_FOR_RETURNS = [
  'pickup_contact_number',
  'pickup_contact_name',
  'pickup_address',
  'pickup_postal',
  'pickup_state',
  'pickup_country',
  'pickup_city',
  'pickup_contact_email',
  'consignee_country',
  'item_desc',
  'item_quantity',
  'item_category',
  'item_price_currency',
  'item_price_value',
  'order_length',
  'order_width',
  'order_height',
  'order_weight'
];

const REQUIRED_COLUMNS_FOR_MPS = [
  'tracking_no',
  'package_no',
  'consignee_number',
  'consignee_name',
  'consignee_address',
  'consignee_postal',
  'consignee_state',
  'pickup_country',
  'consignee_email',
  'consignee_country',
  'item_desc',
  'item_quantity',
  'item_category',
  'item_price_currency',
  'item_price_value',
  'order_length',
  'order_width',
  'order_height',
  'order_weight'
];


const getMissingRequiredColumns = (cleanedRows: Array<CleanedRow | CleanedRowForward | CleanedRowReturns>, order_direction?: string) => {
  const keys = Object.keys(cleanedRows[0])
  if(order_direction === 'RETURNS') {
    return REQUIRED_COLUMNS_FOR_RETURNS.filter( (column) => {
      return !keys.includes(column)
    })
  }else if(order_direction === 'MPS'){
    return REQUIRED_COLUMNS_FOR_MPS.filter((column) => {
      return !keys.includes(column)
    })
  }
  else {
    return REQUIRED_COLUMNS.filter( (column) => {
      return !keys.includes(column)
    })
  }  
}

const getEmptyRequiredColumns = (cleanedRows: Array<Record<string, any>>, order_direction?: string) => {
  let columns: any= {};
  if(order_direction === 'RETURNS')
  {
    cleanedRows.forEach((entry: any, index: any) => {
      for(let key in entry){
        if(entry[key] === ''){
          columns[key]? columns[key].push(index+2) : columns[key]= [index+2];
        }
      }
    });
  }
  return Object.entries(columns).filter(([key, value]) => {
    return REQUIRED_COLUMNS_FOR_RETURNS.includes(key)
  })
}

const getEmptyRequiredColumnsForward = (cleanedRows: Array<Record<string, any>>, order_direction?: string) => {
  let columns: any= {};
  cleanedRows.forEach((entry: any, index: any) =>{
    for(let key in entry){
      if(entry[key] === ''){
        columns[key]? columns[key].push(index+2) : columns[key]= [index+2];
      }
    }
  })
  return Object.entries(columns).filter(([key, value]) => {
    return REQUIRED_COLUMNS.includes(key)
  })
}

const getEmptyRequiredMpsColumns = (cleanedRows: Array<Record<string, any>>, order_direction?: string) => {
  let columns: any= [];
  if(order_direction === 'MPS')
  {
    for(let entry of cleanedRows){
      for(let key in entry){
        if(entry[key] === ''){
          columns.push(key);
        }
      }
    }
  }
  return REQUIRED_COLUMNS_FOR_MPS.filter( (column) => {
    return columns.includes(column)
  })
}

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const getAddressMismatch = (cleanedRows: Array<CleanedMpsRow>) => {
  for (let i=1; i<cleanedRows.length; i++){
    if((cleanedRows[i].tracking_no === cleanedRows[i-1].tracking_no) && (cleanedRows[i].pickup_country !== cleanedRows[i-1].pickup_country || cleanedRows[i].consignee_country !== cleanedRows[i-1].consignee_country)){
      return cleanedRows[i].tracking_no
    }
  }
  return false
}

export const performValidationForward = (cleanedRows: Array<CleanedRowForward>, order_direction?: string) => {
  let missingColumns;
  let emptyColumns;
  let emptyList: any = [];
  missingColumns = getMissingRequiredColumns(cleanedRows, order_direction)
  emptyColumns = getEmptyRequiredColumnsForward(cleanedRows, order_direction)
  
  if( missingColumns.length > 0 ){
    throw new Error(
      `The columns ${missingColumns.join(',')} are missing. Please make sure to add them.`
    )
  }

  if( emptyColumns.length > 0){
    emptyColumns.forEach((column: any) => {
      emptyList.push(`(${column[0]}: row ${column[1].join()})`)
    })
    throw new Error(
      `The columns ${emptyList.join(', ')} are empty. Please make sure to add them.`
    )
  }

  let productUrlExceed: Array<number> = []
  let contactNumberError: Array<number> = []
  let invalidEmail: Array<number> = []
  let invalidCountry: Array<number> = []
  let dduOnly: Array<number> = []
  let postalCodeError: Array<number> = []
  let consigneeIdTypeError: Array<number> = []
  let customTnNoError: Array<number> = []
  let itemQuantityError: Array<number> = []
  let itemPriceError: Array<number> = []
  let itemCurrencyError: Array<number> = []
  let codAmountError: Array<number> = []
  let consigneeInvalidEmail: Array<number> = []
  let consigneeStateError: Array<number> = []
  let consigneeCityError: Array<number> = []
  let consigneePostalCodeError: Array<number> = []
  let codAmountZeroError: Array<number> = []
  let incotermInvalidError: Array<number> = []
  let orderWeightError: Array<number> = []
  let orderHeightError: Array<number> = []
  let orderWidthError: Array<number> = []
  let orderLenghtError: Array<number> = []
  let specialInstructionError: Array<number> = []
  cleanedRows.forEach( (row, i) => {
    if(row.consignee_country && !countryCodes[capitalizeEveryFirstLetterString(row.consignee_country!)]){
      invalidCountry.push(i +2)
    }
    if(row.pickup_country && !countryCodes[capitalizeEveryFirstLetterString(row.pickup_country!)]){
      invalidCountry.push(i +2)
    }
    if(row.pickup_contact_email && !validateEmail(row.pickup_contact_email)){
      invalidEmail.push(i +2)
    }
    if(row.consignee_email && !validateEmail(row.consignee_email)){
      consigneeInvalidEmail.push(i +2)
    }
    if(row.pickup_contact_number && isNaN((row.pickup_contact_number as any))){
      contactNumberError.push(i +2)
    }
    if(row.pickup_postal && isNaN((row.pickup_postal as any))){
      postalCodeError.push(i +2)
    }
    if(row.consignee_postal && !validateBulkOrderPostalCode(row.consignee_postal as any)){
      consigneePostalCodeError.push(i +2)
    }
    if(row.item_price_value && isNaN((row.item_price_value as any))){
      itemPriceError.push(i +2)
    }
    if(!mostUsedCurrencies.includes(row.item_price_currency as any)){
      itemCurrencyError.push(i+2)
    }
    if(row.cod_amt_to_collect && isNaN(row.cod_amt_to_collect as any)){
      codAmountError.push(i+2)
    }
    if((countryCodes[row.origin_country!] === 'TH' && row.consignee_country_code === 'US') && row.incoterm === 'DDP'){
      dduOnly.push(i + 2)
    }
    if((countryCodes[row.origin_country!] === 'TH' && row.consignee_country_code === 'AU')  && row.incoterm === 'DDP'){
      dduOnly.push(i + 2)
    }
    if(row.identification_document_name && countryCodes[row.consignee_country!] === 'ID' && !IndonesiaIdentificationTypes.hasOwnProperty(row.identification_document_name)){
      consigneeIdTypeError.push(i + 2)
    }
    if(row.identification_document_name && countryCodes[row.consignee_country!] === 'KR'  && !SouthKoreaIdentificationTypes.hasOwnProperty(row.identification_document_name)){
      consigneeIdTypeError.push(i + 2)
    }
    if(row.custom_tn && (!row.custom_tn.match(/^[0-9a-zA-Z]+$/) || row.custom_tn.length > 30)){
      customTnNoError.push(i+2)
    }
    if(row.consignee_state && (!row.consignee_state.match(/^[a-zA-Z ]+$/))){
      consigneeStateError.push(i+2)
    }
    if(row.consignee_city && (!row.consignee_city.match(/^[a-zA-Z\- ]+$/))){
      consigneeCityError.push(i+2)
    }
    if(isNaN(row.item_quantity as any)){
      itemQuantityError.push(i+2)
    }
    if(row.payment_type?.toLowerCase() === 'cod' && row.cod_amt_to_collect === '0'){
      codAmountZeroError.push(i+2)
    }
    if(row.incoterm && /^[a-zA-Z]+$/.test(row.incoterm)){
      if( row.incoterm?.toLowerCase() !== 'ddp' && row.incoterm?.toLowerCase() !== 'ddu'){
        incotermInvalidError.push(i+2)
      }}
    if(row.incoterm && !/^[a-zA-Z]+$/.test(row.incoterm)){
      incotermInvalidError.push(i+2)
    }
    if(row.order_height && isNaN(row.order_height as any)){
      orderHeightError.push(i+2)
    }
    if(row.order_length && isNaN(row.order_length as any)){
      orderLenghtError.push(i+2)
    }
    if(row.order_weight && isNaN(row.order_weight as any)){
      orderWeightError.push(i+2)
    }
    if(row.order_width && isNaN(row.order_width as any)){
      orderWidthError.push(i+2)
    }
    if(row.special_instruction && row.special_instruction.length > 80){
      specialInstructionError.push(i+2)
    }
    if (row.item_product_url)
      if( row.item_product_url.length > 200 ){
        productUrlExceed.push(i +2)
      }
  })

  if(invalidCountry.length> 0){
    throw new Error(
      `Pickup/Consignee country name is invalid for row ${invalidCountry.join(',')}. Please enter a valid country name.`
    ) 
  }
  if(postalCodeError.length> 0){
    throw new Error(
      `Pickup postal code is invalid. Please make sure you enter valid postal code.`
    )
  }
  if(consigneePostalCodeError.length> 0){
    throw new Error(
      `Consignee postal code is invalid for row ${consigneePostalCodeError.join(',')}. Please make sure you enter valid postal code.`
    )
  }
  if(invalidEmail.length> 0){
    throw new Error(
      `Please enter a valid pickup_contact_email number for row ${invalidEmail.join(',')}. please make sure you enter in abc@email.com format`
    )
  }
  if(consigneeInvalidEmail.length> 0){
    throw new Error(
      `Please enter a valid consignee_email id for row ${consigneeInvalidEmail.join(',')}. please make sure you enter in abc@email.com format`
    )
  }
  if(contactNumberError.length > 0){
    throw new Error(
      `Please enter a valid Pickup contact number for row ${contactNumberError.join(',')}.`
    )
  }
  if(productUrlExceed.length > 0){
    throw new Error(
      `The maximum character for product URL is exceeding for row ${productUrlExceed.join(',')}. Please make sure you do not exceed 200 characters for product URL.`
    )
  }
  if(dduOnly.length > 0 ){
    throw new Error(
      `Incoterm must be DDU for this service. Please enter it as DDU for row ${dduOnly.join(',')}.`
    )
  }
  if(consigneeIdTypeError.length > 0 ){
    throw new Error(
      `The consignee_id_type field is having invalid values for row ${consigneeIdTypeError.join(',')}. Please make sure to correct them.`
    )
  }
  if(customTnNoError.length > 0){
    throw new Error(
      `Please enter a valid Custom TN for row ${customTnNoError.join(',')}. It should be alphanumeric with less than 30 characters.`
    )
  }
  if(itemQuantityError.length > 0){
    throw new Error(
      `Please enter a valid item_quantity for row ${itemQuantityError.join(',')}. It should be numeric.`
    )
  }
  if(itemPriceError.length > 0){
    throw new Error(
      `Please enter a valid item_price_value for row ${itemPriceError.join(',')}. It should be numeric.`
    )
  }
  if(itemCurrencyError.length > 0){
    throw new Error(
      `Please enter a valid item_price_currency for row ${itemCurrencyError.join(',')}. It should be ${mostUsedCurrencies.join(',')}.`
    )
  }
  if(codAmountError.length > 0){
    throw new Error(
      `Please enter a valid Cod Amount for row ${codAmountError.join(',')}. It should be number.`
    )
  }
  if(consigneeStateError.length > 0){
    throw new Error(
      `Please enter a valid Consignee State for row ${consigneeStateError.join(',')}. It should be Alphabets.`
    )
  }
  if(consigneeCityError.length > 0){
    throw new Error(
      `Please enter a valid Consignee city for row ${consigneeCityError.join(',')}. It should be Alphabets.`
    )
  }
  if(codAmountZeroError.length > 0){
    throw new Error(
      `Please enter a valid COD amount for row ${codAmountZeroError.join(',')}. It should be greater than zero.`
    )
  }
  if(incotermInvalidError.length > 0){
    throw new Error(
      `Please enter a valid incoterm for row ${incotermInvalidError.join(',')}. It should be DDP or DDU.`
    )
  }
  if(orderHeightError.length > 0){
    throw new Error(
      `Please enter a valid order height for row ${orderHeightError.join(',')}. It should be numeric.`
    )
  }
  if(orderLenghtError.length > 0){
    throw new Error(
      `Please enter a valid order length for row ${orderLenghtError.join(',')}. It should be numeric.`
    )
  }
  if(orderWeightError.length > 0){
    throw new Error(
      `Please enter a valid order weight for row ${orderWeightError.join(',')}. It should be numeric.`
    )
  }
  if(orderWidthError.length > 0){
    throw new Error(
      `Please enter a valid order width for row ${orderWidthError.join(',')}. It should be numeric.`
    )
  }
  if(specialInstructionError.length > 0){
    throw new Error(
      `Please enter special instruction under 80 characters for row ${specialInstructionError.join(',')}`
    )
  }
}

export const performValidationReturns = (cleanedRows: Array<CleanedRowReturns>, order_direction?: string) => {
  let missingColumns;
  let emptyColumns;
  let emptyList : any=[];
  missingColumns = getMissingRequiredColumns(cleanedRows, order_direction)
  emptyColumns = getEmptyRequiredColumns(cleanedRows, order_direction)
  
  if( missingColumns.length > 0 ){
    throw new Error(
      `The columns ${missingColumns.join(',')} are missing. Please make sure to add them.`
    )
  }

  if( emptyColumns.length > 0){
    emptyColumns.forEach((column: any) => {
      emptyList.push(`(${column[0]}: row ${column[1].join()})`)
    })
    throw new Error(
      `The columns ${emptyList.join(', ')} are empty. Please make sure to add them.`
    )
  } 

  let itemQuantityError: Array<number> = []
  let itemPriceError: Array<number> = []
  let itemCurrencyError: Array<number> = []
  let contactNumberError: Array<number> = []
  let invalidEmail: Array<number> = []
  let invalidCountry: Array<number> = []
  let pickupStateError: Array<number> = []
  let pickupCityError: Array<number> = []
  let postalCodeError: Array<number> = []
  let orderWeightError: Array<number> = []
  let orderHeightError: Array<number> = []
  let orderWidthError: Array<number> = []
  let orderLenghtError: Array<number> = []

  cleanedRows.forEach( (row, i) => {
    if(isNaN(row.item_quantity as any) || (Number(row.item_quantity) !== parseInt(row.item_quantity as any))){
      itemQuantityError.push(i+2)
    }
    if(row.item_price_value && isNaN((row.item_price_value as any))){
      itemPriceError.push(i +2)
    }
    if(!mostUsedCurrencies.includes(row.item_price_currency as any)){
      itemCurrencyError.push(i+2)
    }
    if(row.consignee_country && !countryCodes[capitalizeEveryFirstLetterString(row.consignee_country!)]){
      invalidCountry.push(i +2)
    }
    if(row.pickup_country && !countryCodes[capitalizeEveryFirstLetterString(order_direction? row.pickup_country!: row.origin_country!)]){
      invalidCountry.push(i +2)
    }
    if(row.pickup_contact_email && !validateEmail(row.pickup_contact_email)){
      invalidEmail.push(i +2)
    }
    if(row.pickup_contact_number && isNaN((row.pickup_contact_number as any))){
      contactNumberError.push(i +2)
    }
    if(row.pickup_postal && !validateBulkOrderPostalCode(row.pickup_postal)){
      postalCodeError.push(i +2)
    }
    if(row.pickup_state && (!row.pickup_state.match(/^[a-zA-Z ]+$/))){
      pickupStateError.push(i+2)
    }
    if(row.pickup_city && (!row.pickup_city.match(/^[a-zA-Z\- ]+$/))){
      pickupCityError.push(i+2)
    }
    if(row.order_height && isNaN(row.order_height as any)){
      orderHeightError.push(i+2)
    }
    if(row.order_length && isNaN(row.order_length as any)){
      orderLenghtError.push(i+2)
    }
    if(row.order_weight && isNaN(row.order_weight as any)){
      orderWeightError.push(i+2)
    }
    if(row.order_width && isNaN(row.order_width as any)){
      orderWidthError.push(i+2)
    }
  })

  if(itemQuantityError.length > 0){
    throw new Error(
      `Please enter a valid item_quantity for row ${itemQuantityError.join(',')}. It should be integer.`
    )
  }
  if(itemPriceError.length > 0){
    throw new Error(
      `Please enter a valid item_price_value for row ${itemPriceError.join(',')}. It should be numeric.`
    )
  }
  if(itemCurrencyError.length > 0){
    throw new Error(
      `Please enter a valid item_price_currency for row ${itemCurrencyError.join(',')}. It should be ${mostUsedCurrencies.join(',')}.`
    )
  }
  if(invalidCountry.length> 0){
    throw new Error(
      `Pickup/Consignee country name is invalid for row ${invalidCountry.join(',')}. Please enter a valid country name.`
    ) 
  }
  if(postalCodeError.length> 0){
    throw new Error(
      `Pickup postal code is invalid for row ${postalCodeError.join(',')}. Please make sure you enter valid postal code.`
    )
  }
  if(invalidEmail.length> 0){
    throw new Error(
      `Please enter a valid pickup_contact_email number for row ${invalidEmail.join(',')}. please make sure you enter in abc@email.com format`
    )
  }
  if(contactNumberError.length > 0){
    throw new Error(
      `Please enter a valid Pickup contact number for row ${contactNumberError.join(',')}.`
    )
  }
  if(pickupStateError.length > 0){
    throw new Error(
      `Please enter a valid Pickup State for row ${pickupStateError.join(',')}. It should be Alphabets.`
    )
  }
  if(pickupCityError.length > 0){
    throw new Error(
      `Please enter a valid Pickup city for row ${pickupCityError.join(',')}. It should be Alphabets.`
    )
  }
  if(orderHeightError.length > 0){
    throw new Error(
      `Please enter a valid order height for row ${orderHeightError.join(',')}. It should be numeric.`
    )
  }
  if(orderLenghtError.length > 0){
    throw new Error(
      `Please enter a valid order length for row ${orderLenghtError.join(',')}. It should be numeric.`
    )
  }
  if(orderWeightError.length > 0){
    throw new Error(
      `Please enter a valid order weight for row ${orderWeightError.join(',')}. It should be numeric.`
    )
  }
  if(orderWidthError.length > 0){
    throw new Error(
      `Please enter a valid order width for row ${orderWidthError.join(',')}. It should be numeric.`
    )
  }
}

export const performValidation = (cleanedRows: Array<CleanedRow>, order_direction?: string) => {
  let missingColumns;
  let emptyColumns;
  missingColumns = getMissingRequiredColumns(cleanedRows, order_direction)
  emptyColumns = getEmptyRequiredColumns(cleanedRows, order_direction)
  
  if( missingColumns.length > 0 ){
    throw new Error(
      `The columns ${missingColumns.join(',')} are missing. Please make sure to add them.`
    )
  }

  if( emptyColumns.length > 0){
    throw new Error(
      `The columns ${emptyColumns.join(',')} are empty. Please make sure to add them.`
    )
  } 

  let missingTrackingNo: Array<number> = []
  let productUrlExceed: Array<number> = []
  let contactNumberError: Array<number> = []
  let invalidEmail: Array<number> = []
  let invalidCountry: Array<number> = []
  let dduOnly: Array<number> = []
  let postalCodeError: Array<number> = []
  let consigneeIdTypeError: Array<number> = []
  let customTnNoError: Array<number> = []
  cleanedRows.forEach( (row, i) => {
    if (!row.tracking_no){
      missingTrackingNo.push(i + 2)
    }
    if(!countryCodes[row.consignee_country!] || !countryCodes[order_direction? row.pickup_country!: row.origin_country!]){
      invalidCountry.push(i +2)
    }
    if(row.pickup_contact_email && !validateEmail(row.pickup_contact_email)){
      invalidEmail.push(i +2)
    }
    if(row.pickup_contact_number && isNaN((row.pickup_contact_number as any))){
      contactNumberError.push(i +2)
    }
    if(row.pickup_postal && isNaN((row.pickup_postal as any))){
      postalCodeError.push(i +2)
    }
    if((countryCodes[row.origin_country!] === 'TH' && row.consignee_country_code === 'US') && row.incoterm === 'DDP'){
      dduOnly.push(i + 2)
    }
    if((countryCodes[row.origin_country!] === 'TH' && row.consignee_country_code === 'AU')  && row.incoterm === 'DDP'){
      dduOnly.push(i + 2)
    }
    if(row.consignee_id_type && countryCodes[row.consignee_country!] === 'ID' && !IndonesiaIdentificationTypes.hasOwnProperty(row.consignee_id_type)){
      consigneeIdTypeError.push(i + 2)
    }
    if(row.consignee_id_type && countryCodes[row.consignee_country!] === 'KR'  && !SouthKoreaIdentificationTypes.hasOwnProperty(row.consignee_id_type)){
      consigneeIdTypeError.push(i + 2)
    }
    if(row.custom_tn && (!row.custom_tn.match(/^[0-9a-zA-Z]+$/) || row.custom_tn.length > 30)){
      customTnNoError.push(i+2)
    }
    if (row.item_product_url)
      if( row.item_product_url.length > 200 ){
        productUrlExceed.push(i +2)
      }
  })

  if(invalidCountry.length> 0){
    throw new Error(
      `Pickup/Consignee country name is invalid for row ${invalidCountry.join(',')}. Please enter a valid country name.`
    ) 
  }
  if(postalCodeError.length> 0){
    throw new Error(
      `Pickup postal code is invalid. Please make sure you enter valid postal code.`
    )
  }
  if(invalidEmail.length> 0){
    throw new Error(
      `Please enter a valid pickup_contact_email number for row ${invalidEmail.join(',')}. please make sure you enter in abc@email.com format`
    )
  }
  if(contactNumberError.length > 0){
    throw new Error(
      `Please enter a valid Pickup contact number for row ${contactNumberError.join(',')}.`
    )
  }
  if(productUrlExceed.length > 0){
    throw new Error(
      `The maximum character for product URL is exceeding for row ${productUrlExceed.join(',')}. Please make sure you do not exceed 200 characters for product URL.`
    )
  }
  if(dduOnly.length > 0 ){
    throw new Error(
      `Incoterm must be DDU for this service. Please enter it as DDU for row ${dduOnly.join(',')}.`
    )
  }
  if(missingTrackingNo.length > 1 ){
    throw new Error(
      `The tracking_number is missing from row ${missingTrackingNo.join(',')}. Please make sure every row includes a tracking_number value.`
    )
  }
  if(consigneeIdTypeError.length > 0 ){
    throw new Error(
      `The consignee_id_type field is having invalid values for row ${consigneeIdTypeError.join(',')}. Please make sure to correct them.`
    )
  }
  if(customTnNoError.length > 0){
    throw new Error(
      `Please enter a valid Custom TN for row ${customTnNoError.join(',')}. It should be alphanumeric with less than 30 characters.`
    )
  }
}

export const performMpsValidation = (cleanedRows: Array<CleanedMpsRow>, order_direction?: string) => {
  let missingColumns;
  let emptyColumns;
  let addressMismatch;
  missingColumns = getMissingRequiredColumns(cleanedRows, order_direction)
  emptyColumns = getEmptyRequiredMpsColumns(cleanedRows, order_direction)
  addressMismatch = getAddressMismatch(cleanedRows)
  
  if( missingColumns.length > 0 ){
    throw new Error(
      `The columns ${missingColumns.join(',')} are missing. Please make sure to add them.`
    )
  }

  if( emptyColumns.length > 0){
    throw new Error(
      `The columns ${emptyColumns.join(',')} are empty. Please make sure to add them.`
    )
  }
  let contactNumberError: Array<number> = []
  let invalidEmail: Array<number> = []
  let invalidConsigneeCountry: Array<number> = []
  let invalidPickupCountry: Array<number> = []
  let postalCodeError: Array<number> = []
  let packageOrderIdError: Array<number> = []
  let trackingNoError: Array<number> = []
  let packageTrackingNoError: Array<number> = []
  let customTnNoError: Array<number> = []
  let packageCustomTnError: Array<number> = []
  let consigneeIdTypeError: Array<number> = []
  cleanedRows.forEach( (row, i) => {
    if(!countryCodes[row.consignee_country]){
      invalidConsigneeCountry.push(i +2)
    }
    if(!countryCodes[row.pickup_country]){
      invalidPickupCountry.push(i +2)
    }
    if(row.consignee_email && !validateEmail(row.consignee_email)){
      invalidEmail.push(i +2)
    }
    if(row.consignee_number && isNaN((row.consignee_number as any))){
      contactNumberError.push(i +2)
    }
    if(row.consignee_postal && isNaN((row.consignee_postal as any))){
      postalCodeError.push(i +2)
    }
    if(row.package_no && isNaN((row.package_no as any))){
      packageOrderIdError.push(i+2)
    }
    if(row.tracking_no && (!row.tracking_no.match(/^[0-9a-zA-Z]+$/) || row.tracking_no.length > 30)){
      trackingNoError.push(i+2)
    }
    if(row.package_tracking_no && (!row.package_tracking_no.match(/^[0-9a-zA-Z]+$/) || row.package_tracking_no.length > 30)){
      packageTrackingNoError.push(i+2)
    }
    if(row.custom_tn && (!row.custom_tn.match(/^[0-9a-zA-Z]+$/) || row.custom_tn.length > 30)){
      customTnNoError.push(i+2)
    }
    if(row.package_custom_tn && (!row.package_custom_tn.match(/^[0-9a-zA-Z]+$/) || row.package_custom_tn.length > 30)){
      packageCustomTnError.push(i+2)
    }
    if(row.identification_document_name && countryCodes[row.consignee_country!] === 'ID' && !IndonesiaIdentificationTypes.hasOwnProperty(row.identification_document_name)){
      consigneeIdTypeError.push(i + 2)
    }
    if(row.identification_document_name && countryCodes[row.consignee_country!] === 'KR'  && !SouthKoreaIdentificationTypes.hasOwnProperty(row.identification_document_name)){
      consigneeIdTypeError.push(i + 2)
    }
  })
  if(invalidPickupCountry.length> 0){
    throw new Error(
      `Pickup country name is invalid for row ${invalidPickupCountry.join(',')}. Please enter a valid country name.`
    ) 
  }
  if(invalidConsigneeCountry.length> 0){
    throw new Error(
      `Consignee country name is invalid for row ${invalidConsigneeCountry.join(',')}. Please enter a valid country name.`
    ) 
  }
  if(postalCodeError.length> 0){
    throw new Error(
      `Pickup postal code is invalid. Please make sure you enter valid postal code.`
    )
  }
  if(invalidEmail.length> 0){
    throw new Error(
      `Please enter a valid pickup_contact_email number for row ${invalidEmail.join(',')}. please make sure you enter in abc@email.com format`
    )
  }
  if(contactNumberError.length > 0){
    throw new Error(
      `Please enter a valid Pickup contact number for row ${contactNumberError.join(',')}.`
    )
  }
  if(packageOrderIdError.length > 0){
    throw new Error(
      `Please enter a valid Package number for row ${packageOrderIdError.join(',')}.`
    )
  }
  if(trackingNoError.length > 0){
    throw new Error(
      `Please enter a valid Tracking number for row ${trackingNoError.join(',')}. It should be alphanumeric with less than 30 characters.`
    )
  }
  if(packageTrackingNoError.length > 0){
    throw new Error(
      `Please enter a valid Package Tracking number for row ${packageTrackingNoError.join(',')}. It should be alphanumeric with less than 30 characters.`
    )
  }
  if(customTnNoError.length > 0){
    throw new Error(
      `Please enter a valid Custom TN for row ${customTnNoError.join(',')}. It should be alphanumeric with less than 30 characters.`
    )
  }
  if(packageCustomTnError.length > 0){
    throw new Error(
      `Please enter a valid Package Custom TN for row ${packageCustomTnError.join(',')}. It should be alphanumeric with less than 30 characters.`
    )
  }
  if(addressMismatch){
    throw new Error(
      `The pickup and consignee countries for tracking_no ${addressMismatch} is not same. Please make sure to correct them.`
    )
  }
  if(consigneeIdTypeError.length > 0 ){
    throw new Error(
      `The identification_document_name field is having invalid values for row ${consigneeIdTypeError.join(',')}. Please make sure to correct them.`
    )
  }
}


const extractItem = (rawObject: CleanedRow | CleanedRowForward ) => {
  return {
    item_desc: rawObject.item_desc || '',
    item_quantity: rawObject.item_quantity || '',
    item_product_id: rawObject.item_product_id || '',
    item_sku: rawObject.item_sku || '',
    item_category: rawObject.item_category || '',
    item_price_value: rawObject.item_price_value || '',
    item_price_currency: rawObject.item_price_currency || '',
    item_product_url: rawObject.item_product_url || '',
    gst_ovr: rawObject.gst_ovr || '',
    gst_collected: rawObject.gst_collected ? true : false,
  }
};

const extractMpsItem = (rawObject: CleanedMpsRow) => {
  return {
    item_desc: rawObject.item_desc || '',
    item_quantity: rawObject.item_quantity || '',
    item_product_id: rawObject.item_product_id || '',
    item_sku: rawObject.item_sku || '',
    item_category: rawObject.item_category || '',
    item_price_value: rawObject.item_price_value || '',
    item_price_currency: rawObject.item_price_currency || '',
    gst_ovr: rawObject.gst_ovr || '',
    gst_collected: rawObject.gst_collected ? true : false,
  }
};

const extractPackageDetails = (rawObject: CleanedMpsRow, item: any) => {
  return {
    // package_tracking_no: rawObject.package_tracking_no,
    package_custom_tn: rawObject.package_custom_tn,
    tracking_no: rawObject.package_tracking_no,
    package_no: rawObject.package_no,
    package_length: rawObject.order_length,
    package_weight: rawObject.order_weight,
    package_height: rawObject.order_height,
    package_width: rawObject.order_width,
    items: [item]
  }
}

interface ConsolidatedForwardOrder{
  forward_janio_order_id?: string
  shipper_order_id: string
  tracking_no: string
  custom_tn?: string
  pickup_country: string
  pickup_address?: string
  pickup_city?: string
  pickup_contact_email?: string
  pickup_contact_name?: string
  pickup_contact_number?: string
  pickup_country_code?: string
  pickup_postal?: string
  pickup_province?: string
  pickup_state?: string
  consignee_name: string
  consignee_number: string
  consignee_address: string
  consignee_postal: string
  consignee_country_code: string
  consignee_country: string
  consignee_state: string
  consignee_city: string
  consignee_province: string
  consignee_email: string
  consignee_identifcation_number: string
  identification_document_name: string
  order_direction: string
  order_length: string
  order_width: string
  order_height: string
  order_weight: string
  payment_type: string
  incoterm: string | null
  cod_amt_to_collect: string | number | null
  special_instruction: string | undefined
  items: Array<{
    item_desc: string
    item_quantity: string
    item_product_id: string
    item_sku: string
    item_category: string
    item_price_value: string
    item_price_currency: string
    item_product_url: string
  }>
}

interface ConsolidatedOrderReturns{
  forward_janio_order_id?: string
  shipper_order_id: string
  tracking_no?: string
  pickup_country: string
  pickup_address?: string
  pickup_city?: string
  pickup_contact_email?: string
  pickup_contact_name?: string
  pickup_contact_number?: string
  pickup_country_code?: string
  pickup_postal?: string
  pickup_province?: string
  pickup_state?: string
  consignee_name: string
  consignee_number: string
  consignee_address: string
  consignee_postal: string
  consignee_country_code: string
  consignee_country: string
  consignee_state: string
  consignee_city: string
  consignee_province: string
  consignee_email: string
  consignee_id: string
  consignee_id_type: string
  order_direction: string
  order_length: string
  order_width: string
  order_height: string
  order_weight: string
  items: Array<{
    item_desc: string
    item_quantity: string
    item_product_id: string
    item_sku: string
    item_category: string
    item_price_value: string
    item_price_currency: string
    item_product_url: string
  }>
}

interface ConsolidatedMpsOrder{
  order_direction:string
  cod_amount:number | null
  consignee_address:string
  consignee_city:string
  consignee_country:string
  consignee_country_code:string
  consignee_email:string
  consignee_identifcation_number:string
  consignee_name:string
  consignee_number:string
  consignee_postal:string
  consignee_province:string
  consignee_state:string
  custom_tn:string
  identification_document_name:string
  incoterm:string
  packages: Array<{
    // package_tracking_no:string,
    package_custom_tn:string,
    tracking_no:string,
    package_no:string,
    package_length:string,
    package_weight:string,
    package_height:string,
    package_width:string,
    items: Array<{
      item_category:string
      item_desc:string
      item_price_currency:string
      item_price_value:string
      item_product_id:string
      item_quantity:string
      item_sku:string
    }>
  }>, 
  order_height:string
  order_length:string
  order_weight:string
  order_width:string
  payment_type:string
  pickup_country:string
  tracking_no:string
}

export const getCodDduOrders =(cleanedRows: Array<CleanedRow>)=> {
  var checkOrders = cleanedRows.filter(order=> (order.incoterm==='DDU'|| order.payment_type==='cod'));
  let requestData = checkOrders.map((order:any) => {
    return {
      pickup_country:countryCodes[order.origin_country] || 'SG',
      consignee_country:countryCodes[order.consignee_country] || 'SG'
    }
  })
  return {request:requestData};
}

export const getCodDduValidationError = (cleanedRows: any, validationResponse:any) => {
  const checkIfCodIsEligible = (order:any) => {
    let data = validationResponse.filter((record:any) => {
      return (record.pickup_country === countryCodes[order.origin_country] && record.consignee_country === order.consignee_country_code)
    })
    return data[0]?.is_cod_enabled;
  }
  const checkIfDduIsEligible = (order:any) => {
    let data = validationResponse.filter((record:any) => {
      return (record.pickup_country === countryCodes[order.origin_country] && record.consignee_country === order.consignee_country_code)
    })
    return data[0]?.is_ddu_enabled;
  }
  let errors:any = {};
  cleanedRows.map((record:any,index:any)=>{
    let line_no = parseInt(index) + 2;
    if(record.payment_type === 'cod'&&!checkIfCodIsEligible(record)){
      if("COD" in errors){
        errors["COD"]["You are not eligible for COD. Please mark your order as prepaid at line"].push(line_no);
      }
      else{
        errors["COD"] = {"You are not eligible for COD. Please mark your order as prepaid at line":[]};
        errors["COD"]["You are not eligible for COD. Please mark your order as prepaid at line"].push(line_no);
      }
    }
    if(record.incoterm === 'DDU'&&!checkIfDduIsEligible(record)){
      if("incoterm" in errors){
        errors["incoterm"]["You are not eligible for DDU. Please mark your order as DDP at line"].push(line_no);
      }
      else{
        errors["incoterm"] = {"You are not eligible for DDU. Please mark your order as DDP at line":[]};
        errors["incoterm"]["You are not eligible for DDU. Please mark your order as DDP at line"].push(line_no);
      }
    }
    return errors
  })
  return errors;
}

export const consolidateForwardOrderItems = (cleanedRows: Array<CleanedRowForward>) => {
  let orderMap: Record<string, ConsolidatedForwardOrder> = {}

  cleanedRows.forEach(row => {
    const key = row.shipper_order_id?.length !== 0 ? row.shipper_order_id! : uuidv4();
    const item = extractItem(row)

    if(key in orderMap){
      orderMap[key].items.push(item)
    }
    else{
      orderMap[key] = {
        forward_janio_order_id: row.forward_janio_order_id!,
        shipper_order_id: row.shipper_order_id!,
        tracking_no: row.tracking_no!,
        custom_tn: row.custom_tn!,
        pickup_country: row.pickup_country || '',
        pickup_address: row.pickup_address || '',
        pickup_city: row.pickup_city || '',
        pickup_contact_email: row.pickup_contact_email || '',
        pickup_contact_name: row.pickup_contact_name || '',
        pickup_contact_number: row.pickup_contact_number || '',
        pickup_country_code: row.pickup_country_code || '',
        pickup_postal: row.pickup_postal || '',
        pickup_province: row.pickup_province || '',
        pickup_state: row.pickup_state || '',
        consignee_name: row.consignee_name || '',
        consignee_number: row.consignee_number || '',
        consignee_address: row.consignee_address || '',
        consignee_postal: row.consignee_postal || '',
        consignee_country_code: row.consignee_country_code || '',
        consignee_country: row.consignee_country || '',
        consignee_state: row.consignee_state || '',
        consignee_city: row.consignee_city || '',
        consignee_province: row.consignee_province || '',
        consignee_email: row.consignee_email || '',
        consignee_identifcation_number: row.consignee_identifcation_number || '',
        identification_document_name: row.identification_document_name || '',
        order_direction: row.order_direction || '',
        order_length: row.order_length || '',
        order_width: row.order_width || '',
        order_height: row.order_height || '',
        order_weight: row.order_weight || '',
        payment_type: row.payment_type || paymentTypes.prepaid,
        // incoterm: row.incoterm || 'DDP',
        incoterm: row.incoterm || null,
        cod_amt_to_collect: (row.payment_type === 'cod' ? row.cod_amt_to_collect : null) || null,
        items: [item],
        special_instruction: row.special_instruction
      }
    }
  })

  return Object.values(orderMap)
}

export const consolidateOrderItems = (cleanedRows: Array<CleanedRowReturns>) => {
  let orderMap: Record<string, ConsolidatedOrderReturns> = {}
  cleanedRows.forEach(row => {
    const key = row.shipper_order_id?.length !== 0 ? row.shipper_order_id! : uuidv4();
    const item = extractItem(row)

    if(key in orderMap){
      orderMap[key].items.push(item)
    }
    else{
      orderMap[key] = {
        forward_janio_order_id: row.forward_janio_order_id!,
        tracking_no: row.tracking_no!,
        shipper_order_id: row.shipper_order_id!,
        // custom_tn: row.custom_tn!,
        pickup_country: row.pickup_country || '',
        pickup_address: row.pickup_address || '',
        pickup_city: row.pickup_city || '',
        pickup_contact_email: row.pickup_contact_email || '',
        pickup_contact_name: row.pickup_contact_name || '',
        pickup_contact_number: row.pickup_contact_number || '',
        pickup_country_code: row.pickup_country_code || '',
        pickup_postal: row.pickup_postal || '',
        pickup_province: row.pickup_province || '',
        pickup_state: row.pickup_state || '',
        consignee_name: row.consignee_name || '',
        consignee_number: row.consignee_number || '',
        consignee_address: row.consignee_address || '',
        consignee_postal: row.consignee_postal || '',
        consignee_country_code: row.consignee_country_code || '',
        consignee_country: row.consignee_country || '',
        consignee_state: row.consignee_state || '',
        consignee_city: row.consignee_city || '',
        consignee_province: row.consignee_province || '',
        consignee_email: row.consignee_email || '',
        consignee_id: row.consignee_id || '',
        consignee_id_type: row.consignee_id_type || '',
        order_direction: row.order_direction || '',
        order_length: row.order_length || '',
        order_width: row.order_width || '',
        order_height: row.order_height || '',
        order_weight: row.order_weight || '',
        // payment_type: row.payment_type || paymentTypes.prepaid,
        // incoterm: row.incoterm || 'DDP',
        // cod_amount: (row.payment_type === 'cod' ? row.cod_amount : null) || null,
        items: [item]
      }
    }
  })

  return Object.values(orderMap)
}

export const consolidateMpsOrderItems = (cleanedRows: Array<CleanedMpsRow>) => {
  let orderMap: Record<string, ConsolidatedMpsOrder> = {}

  cleanedRows.forEach(row => {
    const key = row.tracking_no!
    const package_order_id = row.package_no
    const item = extractMpsItem(row)
    const packageDetails = extractPackageDetails(row, item)

    if(key in orderMap){
      let packageAvailable = false
      orderMap[key].packages.forEach(eachPackage => {
        if(eachPackage.package_no === package_order_id){
          eachPackage.items.push(item)
          packageAvailable = true
        }
      })
      if(!packageAvailable){
        orderMap[key].packages.push(packageDetails)
      }      
    }
    else{
      orderMap[key] = {
        order_direction: "FORWARD",
        tracking_no: row.tracking_no || '',
        consignee_address: row.consignee_address || '',
        consignee_city: row.consignee_city || '',
        consignee_country: row.consignee_country || '',
        consignee_country_code: row.consignee_country_code || '',
        consignee_email: row.consignee_email || '',
        consignee_identifcation_number: row.consignee_identifcation_number || '',
        consignee_name: row.consignee_name || '',
        consignee_number: row.consignee_number || '',
        consignee_postal: row.consignee_postal || '',
        consignee_province: row.consignee_province || '',
        consignee_state: row.consignee_state || '',
        pickup_country: row.pickup_country || '',
        custom_tn: row.custom_tn || '',
        identification_document_name: row.identification_document_name || '',
        incoterm: row.incoterm || 'DDP', 
        order_length: row.order_length || '',
        order_width: row.order_width || '',
        order_height: row.order_height || '',
        order_weight: row.order_weight || '',
        payment_type: row.payment_type || paymentTypes.prepaid,
        packages: [packageDetails],
        cod_amount: (row.payment_type === 'cod' ? row.cod_amount : null) || null,
      }
    }
  })

  return Object.values(orderMap)
}

export const verifyItemUrl = (items: Array<any>, consigneeCountry: string) => {
  if(consigneeCountry !== "Vietnam"){
    return false
  }
  const isEmpty = items.some(item => item?.item_url?.trim().length === 0 || item?.item_product_url?.trim().length === 0)
  return isEmpty
}

export const verifyCountry = (country: string, lanes: any) => {
  const hasCountry = lanes.some((lane: any) => lane.consignee_country === country)
  return hasCountry
}

export const verifyItemUrlBulk = (lanes: any) => {
  const isEmpty = lanes.some((lane: any) => lane?.items.some((item: any) => item.item_product_url?.trim().length === 0) && lane.consignee_country === 'Vietnam');
  return isEmpty
}