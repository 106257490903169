import {
  Form,
  Button,
  Card,
  Row,
  Col,
  Image,
  Alert,
  Spin,
} from 'antd';
import {useParams} from 'react-router-dom';
import mail_activation from '../assets/images/mail_activation.svg';
import {useMutation} from "react-query";
import {activation} from "./services/authApi";
import {useEffect, useState} from "react";
import './styles/AuthForm.css';
import {UserSignedUpAndVerified} from "../commons/mixpanel"
import {Link} from "react-router-dom";

interface ParamTypes {
  uid: string,
  token: string
}
const ActivationForm = () => {
  const [isActivationFailed, setIsActivationFailed] = useState<boolean>(false);
  const [isActivated, setIsActivated] = useState<boolean>(false)
  const [openingExpiredLink, setOpeningExpiredLink] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const { uid, token } = useParams<ParamTypes>();

  const activate = useMutation(async () => {
    return activation(uid,token)
  },  {
    onSuccess: (val) => {
      setIsActivated(true)
    },
    onError: (error: any) => {
      if(error.response){
        if(error.response.data.detail){
          setErrorMessage(error.response.data.detail)
        }
        if(error.response.data.token){
          setErrorMessage(error.response.data.token[0])
        }
        if(error.response.data.detail.includes('Stale token' || 'stale token')) {
          setOpeningExpiredLink(true)
          setIsActivationFailed(false)
          return
        }

        setIsActivationFailed(true)
      }
      else{
        setErrorMessage(error.toString())
        setIsActivationFailed(true)
      }
    },
  });
  useEffect(() =>{
    if(uid && token && activate.isIdle){
      activate.mutate();
      UserSignedUpAndVerified()
    }
  },[uid, token, activate]);

  return (
    <Form
      layout={'vertical'}
      requiredMark={false}
    >
      <Form.Item className={"form_image"}>
        <Image preview={false} src={mail_activation}/>
        {activate.isLoading && <Spin style={{display: 'block'}}/>}
      </Form.Item>
      { isActivationFailed && <Alert message={`Activation failed: ${errorMessage}`} type="warning" />}
      
      { openingExpiredLink && <>
        <Form.Item label="Your email is already Activated" className={"form-title"}>
        </Form.Item>
        <Form.Item className={"form-footer-desc"}>
          Please Login here! 
          <Button type="primary" size={"middle"} htmlType="submit" loading={activate.isLoading} style={{marginLeft: '8px'}}>
            <Link to={'/auth/login'} style={{color: '#fff'}}>Login</Link>
          </Button>
        </Form.Item>
      </> }
      
      { isActivated && <>
        <Form.Item label="Thank you for confirming your email!" className={"form-title"}>
        </Form.Item>
        <Form.Item className={"form-footer-desc"}>
          You may now login into Janio Merchant Portal.
        </Form.Item>
        <Form.Item className={'form-footer'}>
          <Button type="primary" size={"large"} htmlType="submit" className={"form-button"} loading={false} >
            <a href="/auth/login"> Login to Merchant Portal </a>
          </Button>
        </Form.Item>
      </>}
    </Form>
  )
};


export const Activation = () =>  {
  return (
    <Row align="middle" justify="center">
      <Col span={12} className={'auth-form-container'}>
        <Card>
          <ActivationForm />
        </Card>
      </Col>
    </Row>
  )
};
