import {Button, Modal} from 'antd';
import React, {useState} from "react";
// @ts-ignore
import Cookies from 'universal-cookie';

interface PropsType{
  email: string
  parent_email: string
  setSubAccountCallback: (val: any) => void
}
export const YouAreSubAccountModal = (props:PropsType) =>{
  const cookies = new Cookies();
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleOK = () => {
    setShowModal(false)
    props.setSubAccountCallback(false)
    cookies.set(`sub_aacount_alert_shown_${props.email}`, true)
  }

  return (
    <>
      <Modal width={420}
        visible={!showModal}
        // onCancel={handleOK}
        closable={false}
        className={'tour-container sub-account-popup'}
        footer={[
          <Button key="submit" type="primary" className={"blue-button"} 
            onClick={handleOK}
          >
            OK
          </Button>,
        ]}>
        <div style={{paddingTop:30}}>
          <p className={'tour-label'}>You are now under <span style={{'color': '#050593'}}>{props.parent_email}</span> as a sub account!</p>
          <p className={'tour-sub-label'}>Should this be a mistake, please reach out to us at  <a href={'mailto:clientsupport@janio.asia'}><strong>clientsupport@janio.asia</strong></a></p>
        </div>
      </Modal>
    </>
  )
};
