import {
  Card,
  Row,
  Col,
  Button
} from 'antd'
import { Link } from 'react-router-dom';
import '../../wallet/styles/wallet.css'
// @ts-ignore
import { useLanguage } from '../../languages/Language';

export const FreightSuccess = () =>  {
  let { getText } = useLanguage()

  return (
    <>
      <Row gutter={24}>
        <Col span={18} className="success_container wallet_container" style={{marginTop: '60px'}}>
          <Card>
            <div className="check_mark">
              <main></main>
            </div>

            <p>{getText("Shipment Created Successfully")}</p>
            {/* <Col style={{margin: 'auto',marginBottom:10}}>
              <PickupThresholdAlert />
            </Col>
            <Col span="16" style={{margin: 'auto'}}>
              <Alert
                message={<span className="packing-alerts">{getText("Don't know how to pack your package")}? <a href={"https://janio.asia/articles/packaging-101-how-to-package-products-for-delivery/"} target="_blank" rel='noopener noreferrer'> {getText('Click here')}!</a></span>}
                type="info"
                showIcon
              />
            </Col> */}

            <Col span="24">
              <Row gutter={12} justify='center'>
                <Col span={12} >
                  <Link to={`/b2borders/create`}  id="success_container">
                    <Button style={{color: '#fff', height: '35px'}} type={'default'}>{'Create another shipment'}</Button>
                  </Link>
                </Col>
              </Row>
            </Col>

          </Card>
        </Col>
      </Row>
    </>
  )
};
