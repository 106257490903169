import {ApiHeader} from './components/ApiHeader';
import {CodeJsonText} from './components/CodeText';
import {StatusBadge} from './components/StatusBadge'
import "./styles/CreateOrder.css";

export const CreateOrder = () =>{
  return(
    <div>
      <ApiHeader apiName={"Create Orders"} apiMethod={"POST"} devApiEndPoint={"https://ss.staging.janio.asia/api/orders/client-orders/"} prodApiEndPoint={"https://ss.janio.asia/api/orders/client-orders/"}/>
      <h3>Request Headers</h3>
      <CodeJsonText apiName={"create-api-order-header"}/>
      <h3>Request Payload</h3>
      <CodeJsonText apiName={"create-api-order"}/>
      <h2 className="response-example-text">Response Example</h2>
      <div className="success-response">
        <StatusBadge color={'#28a745'} statusCode={'200'} statusText={'Success'}/>
        <CodeJsonText apiName={"create-api-order-response"}/>
      </div>
      <div>
        <StatusBadge color={'#dc3545'} statusCode={'403'} statusText={'Failure'}/>
        <CodeJsonText apiName={"create-api-order-token-error"}/>
      </div>
      <div>
        <StatusBadge color={'#dc3545'} statusCode={'400'} statusText={'Failure'}/>
        <CodeJsonText apiName={"create-api-order-invalid-consignee-country-error"}/>
      </div>
      <div>
        <StatusBadge color={'#dc3545'} statusCode={'400'} statusText={'Failure'}/>
        <CodeJsonText apiName={"create-api-order-past-pickup-date-error"}/>
      </div>
      <div>
        <StatusBadge color={'#dc3545'} statusCode={'400'} statusText={'Failure'}/>
        <CodeJsonText apiName={"create-api-order-future-pickup-date-error"}/>
      </div>
      <div>
        <StatusBadge color={'#dc3545'} statusCode={'400'} statusText={'Failure'}/>
        <CodeJsonText apiName={"create-api-order-invalid-dimension-error"}/>
      </div>
      <div>
        <StatusBadge color={'#dc3545'} statusCode={'400'} statusText={'Failure'}/>
        <CodeJsonText apiName={"create-api-order-item-category-error"}/>
      </div>
    </div>
  )
}