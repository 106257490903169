import {Col,Table, 
  Button, 
  Tooltip, 
  // Modal, message
} from 'antd';
import React from 'react';

// import {countries} from "../../commons/utils/constants";
// import {useMutation} from "react-query";
// import {deleteLocation} from "../../orders/services/privateApi";
// import {useAuth} from "../../auth/services/authContext";
// import {ExclamationCircleOutlined} from "@ant-design/icons/lib";
import { useLanguage } from '../../languages/Language';
import { Link } from 'react-router-dom';

// const {confirm} = Modal;
interface Props {
  pickUpLocations: any,
  activeTab: string
}
const PickUpAddressesList = (props:Props) => {
  // const auth = useAuth();
  let { getText } = useLanguage()
  // const deleteAddress = useMutation(async (locationId: number) => {return  await deleteLocation(locationId, auth.user.authToken)},
  //   {
  //     onSuccess: (val) => {
  //       message.success(getText('Deleted the address successfully'));
  //       props.pickUpLocations.refetch();
  //     },
  //     onError: (val) => {
  //       message.error(getText('Failed to delete the address, please try later'));
  //     },
  //   });

  // const showConfirmDeletion = (addressId:number) =>{
  //   return (
  //     confirm({
  //       className: 'custom-confirm-modal',
  //       icon: <ExclamationCircleOutlined />,
  //       content: <p>{getText("Click")} <strong>{getText("OK")}</strong> {getText("to delete the address")}</p>,
  //       onOk() {
  //         deleteAddress.mutate(addressId);
  //       },
  //       onCancel() {},
  //     })
  //   )
  // };

  const columns = [
    {
      title: getText('Pickup Name'),
      dataIndex: 'pickup_point_name',
      key: 'pickup_point_name',
    },
    // {
    //   title: getText('Type'),
    //   dataIndex: 'type',
    //   key: 'type',
    // },
    {
      title: getText('Country'),
      dataIndex: 'pickup_point_country',
      key: 'pickup_point_country',
      render: (pickup_point_country :any) =>{
        return pickup_point_country
      }
    },
    {
      title: getText('Address'),
      dataIndex: 'pickup_point_address',
      key: 'pickup_point_address',
      ellipsis: {
        showTitle: false,
      },
      render: (text:any, data:any) => (
        <Tooltip placement="topLeft" title={data.pickup_point_address}>
          {data.pickup_point_address}
        </Tooltip>
      ),
    },
    {
      title: getText('Actions'),
      dataIndex: '',
      key: '',
      render: (text:any, data:any) => (
        <Button type="link" size={"middle"} htmlType="submit" style={{marginLeft: '8px'}}>
          <Link to={{ pathname: `edit/address/${data.pickup_point_id}`, state: { addressType: 'pickup' } }} style={{color: '#050593', fontSize: '15px'}}>Edit/Delete </Link>
        </Button>
      ),
    }
  ];
  const columnsForReturns = [
    {
      title: 'Return Point Name',
      dataIndex: 'return_point_name',
      key: 'return_point_name',
    },
    {
      title: getText('Country'),
      dataIndex: 'country',
      key: 'country',
      render: (country :any) =>{
        return country
      }
    },
    {
      title: getText('Address'),
      dataIndex: 'address',
      key: 'address',
      ellipsis: {
        showTitle: false,
      },
      render: (text:any, data:any) => (
        <Tooltip placement="topLeft" title={data.address}>
          {data.address}
        </Tooltip>
      ),
    },
    {
      title: getText('Actions'),
      dataIndex: '',
      key: '',
      render: (text:any, data:any) => (
        <Button type="link" size={"middle"} htmlType="submit" style={{marginLeft: '8px'}}>
          <Link to={{ pathname: `edit/address/${data.id}`, state: { addressType: 'return' } }} style={{color: '#050593', fontSize: '15px'}}>Edit/Delete </Link>
        </Button>
      ),
    }
  ];
  return (
    <Col className={''} span={24}  style={{marginTop: '-15px'}}>
      <Table
        rowKey="id"
        dataSource={props.pickUpLocations?.data!}
        columns={props.activeTab === 'pickup_address' ? columns : columnsForReturns}
        loading={props.pickUpLocations.isLoading}
        pagination={false}
      />
    </Col>
  )
};

export default PickUpAddressesList
