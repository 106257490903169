import { Col, Row, Button, Result } from "antd"
import { useParams } from "react-router-dom"
import { useState } from "react";
import Spin from "antd/lib/spin";
import {CheckCircleFilled, QuestionCircleFilled} from '@ant-design/icons';

export const ChangeAccount = () => {
  const params: any = useParams()
  const [boolean, setboolean] = useState<boolean>(false)
  const [showSpin, setShowSpin] = useState<boolean>(false)
  
  const switchAccount = () => {
    setShowSpin(true)
    let existingLocalStorageObject = JSON.parse(localStorage.getItem('AUTH_SESSION')!)
    let targetObject = {
      authToken: params.token,
      fullName: params.name
    }
    let newLocalStorageObject = Object.assign(existingLocalStorageObject, targetObject);

    localStorage.setItem('AUTH_SESSION', JSON.stringify(newLocalStorageObject))

    setTimeout(() => {
      setboolean(true)
      setShowSpin(false)
    }, 1200)
  }

  if(showSpin) {
    return (
      <Row justify="center">
        <Col span={16} style={{textAlign: 'center', background: 'white', minHeight: '550px'}}>
          <Spin tip="Performing Action...." style={{top: '46%', position: 'relative'}} />
        </Col>
      </Row>
    )
  }

  return (
    <Row justify="center">
      <Col span={16} style={{background: 'white', minHeight: '550px'}}>
        {
          boolean ? 
            <Result
              title="Great, we have done all the operations!"
              extra={<Button type="primary" size="large"><a href={"/"}>Next</a></Button>}
              status="success"
              icon={<CheckCircleFilled style={{fontSize: '155px'}} />}
            /> : 
            <Result
              icon={<QuestionCircleFilled style={{fontSize: '155px'}} />}
              title="Are you sure to make the switch"
              subTitle="Please click on Yes button to proceed"
              extra={<Button type="primary" onClick={switchAccount} size="large">Yes</Button>}
            />
        }
      </Col>
    </Row>
  )
}