import { Button, Col, Form, Row, Radio, message, Spin, Alert, Result } from 'antd';
import React, { useState, useEffect } from "react";
import { CaretLeftOutlined } from '@ant-design/icons'
import {useLocation, Link, useHistory} from "react-router-dom";
import { addSubAccount, updateSubAccount } from "../../../orders/services/privateApi";
import {countryCallingCodes} from "../../../commons/utils/constants";
import ReactFlagsSelect from "react-flags-select/build";
import { useAuth } from "../../../auth/services/authContext";
import { useMutation } from "react-query";
import { Formik, Form as FormikForm } from 'formik';
import { FormInput } from '../../../orders/components/Forms';
import * as Yup from 'yup';
import { useLanguage } from '../../../languages/Language';
import { AccountAddedSuccessfully, AccountTransferredSuccessfully } from './AccountAddedSuccessfully';

interface FormValue {
  is_existing_user: boolean,
  email: string,
  password: string,
  full_name: string,
  combined_billing: boolean,
  can_master_view_shipments: boolean,
  can_view_master_shipments: boolean,
  can_view_siblings_shipments: boolean,
  hide_price_estimation: boolean,
  billing_country: string,
  phone_number: string
}
interface EditData {
  combined_billing: boolean,
  can_master_view_shipments: boolean,
  can_view_master_shipments: boolean,
  can_view_siblings_shipments: boolean,
  hide_price_estimation: boolean,
  id: number
}
interface LocationState {
  editData: EditData
}

export const AddSubAccount = () => {
  const location = useLocation()
  //const isMasterAccount = false
  const isEdit = location.pathname.includes('edit') ? true : false
  let { getText } = useLanguage();
  const auth = useAuth();
  const authToken = auth.user.authToken;
  const history = useHistory()

  const phoneNumberWithCode = auth.user.phoneNumber ? auth.user.phoneNumber.split(" ") : '';
  const [isExistingUser, setIsExistingUser] = useState<boolean>(false)
  const [canMasterViewShipments, setCanMasterViewShipments] = useState<boolean>(false)
  const [canChildViewMasterShipments, setCanChildViewMasterShipments] = useState<boolean>(false)
  const [canViewSiblingsShipments, setCanViewSiblingsShipments] = useState<boolean>(false)
  const [hidePriceEstimation, setHidePriceEstimation] = useState<boolean>(false)
  const [accountAddedSuccessFully, setAccountAddedSuccessFully] = useState<boolean>(false)
  const [emailSuccess, setEmailSuccess] = useState<string>('')
  const [updatedDirectly, setUpdatedDirectly] = useState<boolean>(false)
  const countryPrefix = Object.keys(countryCallingCodes).find(key => countryCallingCodes[key] === phoneNumberWithCode[0]);
  const [selectedCountry, setSelectedCountry] = useState(countryPrefix ? countryPrefix : '');

  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(isEdit) {
      let locationState = location.state as LocationState
      if(locationState && locationState.editData) {
        setCanMasterViewShipments(locationState.editData.can_master_view_shipments)
        setCanViewSiblingsShipments(locationState.editData.can_view_siblings_shipments)
        setCanChildViewMasterShipments(locationState.editData.can_view_master_shipments)
        setHidePriceEstimation(locationState.editData.hide_price_estimation)
      }else{
        setUpdatedDirectly(true)
      }
    }
  }, [isEdit]) //eslint-disable-line react-hooks/exhaustive-deps
  const validationSchema = (getText: any) => {
    if(isExistingUser) {
      return (
        Yup.object().shape({
          is_existing_user: Yup.string().required(getText('Please select one option')),
          email: Yup.string().email(getText('Please enter a valid email')).required(getText('Please enter a valid email')),
          password: Yup.string().required('Please enter a password'),
          combined_billing: Yup.string().required(getText('Please select one option')),
          can_master_view_shipments: Yup.string().required(getText('Please select one option')),
          can_view_master_shipments: Yup.string().required(getText('Please select one option')),
          can_view_siblings_shipments: Yup.string().required(getText('Please select one option')),
          hide_price_estimation: Yup.string().required(getText('Please select one option')),
          billing_country: Yup.string().required(getText('Please select one option'))
        })
      )
    }
    if(isEdit) {
      return (
        Yup.object().shape({
          combined_billing: Yup.string().required(getText('Please select one option')),
          can_master_view_shipments: Yup.string().required(getText('Please select one option')),
          can_view_master_shipments: Yup.string().required(getText('Please select one option')),
          can_view_siblings_shipments: Yup.string().required(getText('Please select one option')),
          hide_price_estimation: Yup.string().required(getText('Please select one option')),
        })
      )
    }
    return (
      Yup.object().shape({
        is_existing_user: Yup.string().required(getText('Please select one option')),
        full_name: Yup.string()
          .required('Please enter full name')
          .max(100, 'Name should be less than 100 characters')
          .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        email: Yup.string().email(getText('Please enter a valid email')).required(getText('Please enter a valid email')),
        phone_number: Yup.string()
          .required('Phone number is a required field')
          .matches(
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
            "Phone number is not valid"),
        password: Yup.string()
          .required('Please enter a password')
          .max(16, 'Maximum 16 characters allowed')
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),//eslint-disable-line
        combined_billing: Yup.string().required(getText('Please select one option')),
        can_master_view_shipments: Yup.string().required(getText('Please select one option')),
        can_view_master_shipments: Yup.string().required(getText('Please select one option')),
        can_view_siblings_shipments: Yup.string().required(getText('Please select one option')),
        billing_country: Yup.string().required(getText('Please select one option')),
        hide_price_estimation: Yup.string().required(getText('Please select one option'))
      })
    )
  }
  
  const initialValue = {
    is_existing_user: false,
    email: "",
    password: "",
    full_name: "",
    combined_billing: true,
    can_master_view_shipments: true,
    can_view_master_shipments: true,
    can_view_siblings_shipments: false,
    hide_price_estimation: false,
    billing_country: "SG",
    phone_number: "",
  }

  const addSubAccountMutate= useMutation(async (params: FormValue) => {
    return addSubAccount(authToken, params);
  },
  {
    onSuccess: (val:any) => {
      message.success('Sub Acccount added successfully')
      // history.goBack()
      setAccountAddedSuccessFully(true)
    },
    onError: (error:any) => {
      let errors= error.response
      errData(errors.data)
      setAccountAddedSuccessFully(false)
    }}
  );

  const updateSubAccountMutate= useMutation(async (params: Omit<EditData, "id">) => {
    let id = (location.state as LocationState).editData.id
    return updateSubAccount(authToken, params, id);
  },
  {
    onSuccess: (val:any) => {
      message.success('Sub Acccount updated successfully')
      history.goBack()
    },
    onError: (error:any) => {
      let errors= error.response
      errData(errors.data)
      setAccountAddedSuccessFully(false)
    }}
  );
  
  const onFormikSubmit = (values: any) => {
    if(isEdit) {
      let req = {
        combined_billing: true,
        can_master_view_shipments: canMasterViewShipments,
        can_view_master_shipments: canChildViewMasterShipments,
        can_view_siblings_shipments: canViewSiblingsShipments,
        hide_price_estimation: hidePriceEstimation,
      }
      updateSubAccountMutate.mutate(req)
    } else {
      let req = {
        is_existing_user: isExistingUser,
        email: values.email,
        password: values.password,
        full_name: values.full_name,
        combined_billing: true,
        can_master_view_shipments: canMasterViewShipments,
        can_view_master_shipments: canChildViewMasterShipments,
        can_view_siblings_shipments: canViewSiblingsShipments,
        hide_price_estimation: hidePriceEstimation,
        billing_country: auth.user.billingCountry,
        phone_number: countryCallingCodes[selectedCountry] + '-' + values.phone_number,
      }
      setEmailSuccess(values.email)
      addSubAccountMutate.mutate(req)
    }
  }
  
  const errData = (errors: any) => {
    let errorList = <ul style={{textAlign: 'left', maxWidth: '400px'}}>
      {
        Object.values(errors).map((val:any, index: number) => {
          return <li key={index} style={{fontSize: '12px',color: '#4f4a4a', fontWeight: 600, margin: '10px 0'}}>{(Object.keys(errors)[index]).replace(/_/g, ' ')}: {val} </li>
        })
      }
    </ul>
    return message.error(
      <span>Error: Please correct the following details and try again. {errorList}</span>,
      10
    )
  }

  if(addSubAccountMutate.isLoading) {
    return <Spin className="center-align-spin" />
  }

  if(accountAddedSuccessFully) {
    return isExistingUser ? <AccountTransferredSuccessfully emailSuccess={emailSuccess} /> : <AccountAddedSuccessfully emailSuccess={emailSuccess} />
  }

  if(updatedDirectly) {
    return (
      <Row align="middle" justify="center">
        <Col span={14}>
          <Alert
            message="Error"
            description={
              <>
                <span>Invalid ID provided. Click here to </span>
                <Link to={"/settings/manage_accounts"}><Button type='primary'>Go back</Button></Link>
              </>
            }
            type="error"
            showIcon
          />
        </Col>
      </Row>
    )
  }
  if(auth.user.account_type === "Sub Account" || auth.user.payment_type === "PayInstantly") {
    return (
      <>
        <Row justify='center'>
          <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
          />
        </Row>
      </>
    )
  }
  return (
    <div>
      <Row align="middle" justify="center">
        <Col span={14}>
          <Link to={"/settings/manage_accounts"}>
            <p className="go-back-btn"><CaretLeftOutlined/> {getText('Manage Account')} </p>
          </Link>
        </Col>
        <Col span={14} style={{marginTop:24}}>
          <Formik
            initialValues={initialValue}
            enableReinitialize={true}
            onSubmit={onFormikSubmit}
            validateOnChange={true}
            validationSchema={() => validationSchema(getText)}>
            {formik => {
              return (
                <div className={"single-order-form-container ant-form-vertical add-sub-account"}>
                  <FormikForm>
                    {!isEdit && 
                      <>
                        <Row>
                          <h3 style={{'paddingTop': '10px'}}>{getText('Background Check')}</h3>
                          <Col span={24}>
                            <label>{getText('Does the user previously register an account with Janio Asia before?')}</label>
                            <Radio.Group name="is_existing_user" defaultValue={isExistingUser} onChange={(e:any)=> {setIsExistingUser(e.target.value)}} className={'radio-grp-add'}>
                              <Radio value={true}>Yes</Radio>
                              <Radio value={false}>No</Radio>
                            </Radio.Group>
                          </Col>
                        </Row>
                        <>
                          {
                            isExistingUser ? 
                              <Row gutter={[20,14]}>
                                <Col span={24}><h3 style={{marginBottom: '-6px'}}>Exisiting Account Details</h3></Col>
                                
                                <Col span={12} style={{'marginBottom': '0px'}}>
                                  <FormInput name="email" label={getText("Email")} placeholder={'Enter the email use to register with Janio Asia'} />
                                </Col>
      
                                <Col span={12}>
                                  <FormInput name="password" label={getText("Password")} placeholder={'Current password that the user use for login'} isPassword={true} />
                                </Col>
                              </Row> : 
                              <Row gutter={[20,14]}>
                                <Col span={24}><h3 style={{marginBottom: '-6px'}}>{getText('New sub-account Details')}</h3></Col>
                                <Col span={12}>
                                  <FormInput name="full_name" label={getText("Full Name")} placeholder={'Please enter full name'} />
                                </Col>
      
                                <Col span={12} style={{'marginBottom': '0px'}}>
                                  <FormInput name="email" label={getText("Email")} placeholder={'Please enter a valid email'} />
                                  <Form.Item  style={formik.errors.email ? {'marginTop': '0px'} : {'marginTop': '-20px'}} label={''} className={'form-item-info'}>
                                  </Form.Item>
                                </Col>
      
                                <Col span={12} style={{'marginTop': '2px'}}>
                                  <span style={{'display': 'block', 'marginBottom': '6px'}}>Phone Number</span>
                                  <Row gutter={8}>
                                    <Col span={selectedCountry ? 6 : 9} >
                                      <ReactFlagsSelect
                                        selected={selectedCountry}
                                        searchPlaceholder="Search countries"
                                        onSelect={code => setSelectedCountry(code)}
                                        searchable
                                        className="menu-flags"
                                        showSelectedLabel={false}
                                        selectButtonClassName="menu-flags-button"
                                        selectedSize={10}
                                        optionsSize={10}
                                        id="flags-select"
                                      />
                                    </Col>
                                    <Col span={selectedCountry ? 18 : 15}>
                                      <FormInput name="phone_number" prefix={selectedCountry ? countryCallingCodes[selectedCountry] : null} inputProps={{disabled: selectedCountry ? false : true}} placeholder={' Please enter a valid phone number'}/>
                                    </Col>
                                  </Row>
                                </Col>
      
                                <Col span={12}>
                                  <FormInput name="password" label={getText("Password")} placeholder={'Please enter a password'} isPassword={true} />
                                </Col>
                              </Row>
                          }
                        </>
                      </>
                    }

                    <Col span={24}>
                      <h3>{getText('Shipment Visibility')}</h3>
                      <label>{getText('Do you want to see this sub-account shipment on your portal?')}</label>
                      <Radio.Group name="can_master_view_shipments" value={canMasterViewShipments} onChange={(e:any)=> {setCanMasterViewShipments(e.target.value)}} className={'radio-grp-add'}>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Col>

                    <Col span={24}>
                      <label>{getText('Do you want the sub-account to be able to view your shipments?')}</label>
                      <Radio.Group name="can_view_master_shipments" value={canChildViewMasterShipments} onChange={(e:any)=> {setCanChildViewMasterShipments(e.target.value)}} className={'radio-grp-add'}>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Col>

                    <Col span={24}>
                      <label>{getText('Will this sub-account be able to view other sub-account shipments?')}</label>
                      <Radio.Group name="can_view_siblings_shipments" value={canViewSiblingsShipments} onChange={(e:any)=> {setCanViewSiblingsShipments(e.target.value)}} className={'radio-grp-add'}>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Col>

                    <Col span={24}>
                      <label>{'Do you want to hide price-estimation for this sub-account?'}</label>
                      <Radio.Group name="hide_price_estimation" value={hidePriceEstimation} onChange={(e:any)=> {setHidePriceEstimation(e.target.value)}} className={'radio-grp-add'}>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Col>

                    <div className={"button-block"}>
                      <Button htmlType="submit" type="primary" className={"blue-button"} loading={addSubAccountMutate.isLoading}>
                        {isExistingUser ? 'Proceed to Transfer' : 'Save Changes'}
                      </Button>
                    </div>

                  </FormikForm>
                </div>
              )
            }}
          </Formik>
        </Col>
      </Row>
    </div>
  )
};