import React, { useEffect } from "react";
import Prism from 'prismjs'
import 'prismjs/components/prism-json'
import 'prismjs/themes/prism-okaidia.css'

import {data} from './ApiPayloads';

interface PropTypes {
  apiName: string
}

export const CodeJsonText = (props:PropTypes) =>{
  useEffect(() => {
    Prism.highlightAll();
  });
  let apiData = data[props.apiName];
  let stringifyJson = typeof apiData !== "string"? JSON.stringify(apiData,null,2):apiData

  return(
    <pre>
      <code className="language-json">{stringifyJson}</code>
    </pre>
  )
}