import React, { useRef, useState } from 'react'
import {FieldArray, Formik, Form as FormikForm} from 'formik';
import {Row, Col, Checkbox, Button, message, Modal, Select} from 'antd';
import { FastFormInput } from '../../../commons/components/FastFormFields/FastFields';
import { useMutation } from 'react-query';
import { fileUpload, removeUploadedFile } from '../../../orders/services/privateApi';
import * as Yup from 'yup';
import { useAuth } from '../../../auth/services/authContext';
import TermsAndCondition from '../../Component/TermsAndCondition';
import { emptyArrayForFileUploadDamagedParcel } from '../../../commons/utils/utilizer';
import { FormSelect, UploadFile } from '../../../orders/components/Forms';
import { damagedParcelFileDropdownOptions } from '../../../commons/utils/constants';
import { validateFileSize, validateFileType } from '../../../commons/utils/validator';
import { useHistory } from 'react-router';

const {Option} = Select;

interface ClaimformProp{
  claimType: string
  selectedOrders: any
}

export const DamagedParcelForm = (props: ClaimformProp) => {
  // const {claimType, selectedOrders} = props
  const {selectedOrders} = props
  const history = useHistory();
  // const {submitClaimFormQuery, error, isLoading} = useSubmitClaim()
  const auth = useAuth()
  const secretKey = auth.user.secret_key
  const [checked, setChecked] = useState(false)

  const [uploadUrlFirst, setUploadUrlFirst] = useState<any>(JSON.stringify(emptyArrayForFileUploadDamagedParcel(selectedOrders)))
  const [uploadUrlSecond, setUploadUrlSecond] = useState<any>(JSON.stringify(emptyArrayForFileUploadDamagedParcel(selectedOrders)))
  const [uploadUrlThird, setUploadUrlThird] = useState<any>(JSON.stringify(emptyArrayForFileUploadDamagedParcel(selectedOrders)))
  const [uploadUrlFourth, setUploadUrlFourth] = useState<any>(JSON.stringify(emptyArrayForFileUploadDamagedParcel(selectedOrders)))

  const uploadUrlType = useRef('')
  const fileIndex = useRef(0)

  const [isShowModal, setIsShowModal] = useState(false)
  const termsAndConditionModal = () => {
    setIsShowModal(!isShowModal)
  }

  const initialValueData = selectedOrders.map((order: any) => {
    return { description: '',
      file_first: '',
      file_second: '',
      file_third: '',
      file_fourth: '',
      fileType_first: '',
      fileType_second: '',
      fileType_third: '',
      fileType_fourth: '',
    }
  })

  const validationSchema = () => {
    return (
      Yup.object().shape({
        damageParcel: Yup.array().of(Yup.object().shape({
          description: Yup.string().max(80, 'Must be less than 80 characters'),
          // file_first: Yup.mixed().required('Please add file'),
          // file_second: Yup.mixed().required('Please add file'),
          // file_third: Yup.mixed().required('Please add file'),
          // file_fourth: Yup.mixed().required('Please add file'),
          fileType_first: Yup.string().when("file_first", (file_first: any, schema: any)=>{
            if(file_first)
              return schema.required('Please select file type')
            return schema
          }),
          fileType_second: Yup.string().when("file_second", (file_second: any, schema: any)=>{
            if(file_second)
              return schema.required('Please select file type')
            return schema
          }),
          fileType_third: Yup.string().when("file_third", (file_third: any, schema: any)=>{
            if(file_third)
              return schema.required('Please select file type')
            return schema
          }),
          fileType_fourth: Yup.string().when("file_fourth", (file_fourth: any, schema: any)=>{
            if(file_fourth)
              return schema.required('Please select file type')
            return schema
          }),
        }))
      })
    )
  }
  const initialValues= {
    damageParcel: initialValueData 
  }
  const onSubmit = (values: any) => {
    // const formData = values.damageParcel.map((value: any, index: number)=> {
    //   const uploadData = createUploadData([JSON.parse(uploadUrlFirst)[index], JSON.parse(uploadUrlSecond)[index], JSON.parse(uploadUrlThird)[index], JSON.parse(uploadUrlFourth)[index]])
    //   return {
    //     incident_desc: value.description,
    //     order: selectedOrders[index].order,
    //     ...uploadData,
    //     tracking_no: selectedOrders[index].tracking_number, 
    //     claim_type: claimType.toUpperCase(), 
    //   }
    // })
    // submitClaimFormQuery.mutate(formData)
    history.push(`/claims/success`)
  }

  const fileUploadQuery:any = useMutation(async (file: any) => {
    return fileUpload(file, secretKey);
  },  {
    onSuccess: (val) => {
      if(uploadUrlType.current === 'file_first'){
        const uploadData = JSON.parse(uploadUrlFirst);
        uploadData[fileIndex.current].id = [val.id]
        setUploadUrlFirst(JSON.stringify(uploadData))
      }
      if(uploadUrlType.current === 'file_second'){
        const uploadData = JSON.parse(uploadUrlSecond);
        uploadData[fileIndex.current].id = [val.id]
        setUploadUrlSecond(JSON.stringify(uploadData))
      }
      if(uploadUrlType.current === 'file_third'){
        const uploadData = JSON.parse(uploadUrlThird);
        uploadData[fileIndex.current].id = [val.id]
        setUploadUrlThird(JSON.stringify(uploadData))
      }
      if(uploadUrlType.current === 'file_fourth'){
        const uploadData = JSON.parse(uploadUrlFourth);
        uploadData[fileIndex.current].id = [val.id]
        setUploadUrlFourth(JSON.stringify(uploadData))
      }
      message.success('File uploaded successfully.')
    },
    onError: (error: any) => {
      message.error('error in uploading. Please again later')
    },
  })

  const fileRemoveQuery:any = useMutation(async (id: any) => {
    return removeUploadedFile(auth.user.secret_key, id);
  },  {
    onSuccess: (val: any) => {
      message.success('File removed successfully.')
    },
    onError: (error: any) => {
      message.error('error in uploading. Please again later')
    },
  })

  const onFileAddition = (event: any, formik: any, index: number,  fileType: string) => {
    const isValidFileType = validateFileType(event)
    const isValidFileSize = validateFileSize(event)
    if(!isValidFileType){
      message.error('Only .png, .jpg and .pdf files are accepted.')
    }
    if(!isValidFileSize){
      message.error('Each file needs to be less than 10mb.')
    }
    if(isValidFileType && isValidFileSize){
      fileIndex.current = index;
      uploadUrlType.current = fileType
      fileUploadQuery.mutate(event)
      formik.setFieldValue(`damageParcel.[${index}].[${fileType}]`, event.name)
    }
    return false
  }
  
  const onFileRemove = (event: any, formik: any, index: number, fileType: string) => {
    if(!event.uid) return false;
    if(fileType === 'file_first'){
      const uploadData = JSON.parse(uploadUrlFirst);
      fileRemoveQuery.mutate(uploadData[index].id[0])
      uploadData[index].id = []
      setUploadUrlFirst(JSON.stringify(uploadData))
    }
    if(fileType === 'file_second'){
      const uploadData = JSON.parse(uploadUrlSecond);
      fileRemoveQuery.mutate(uploadData[index].id[0])
      uploadData[index].id = []
      setUploadUrlSecond(JSON.stringify(uploadData))
    }
    if(fileType === 'file_third'){
      const uploadData = JSON.parse(uploadUrlThird);
      fileRemoveQuery.mutate(uploadData[index].id[0])
      uploadData[index].id = []
      setUploadUrlThird(JSON.stringify(uploadData))
    }
    if(fileType === 'file_fourth'){
      const uploadData = JSON.parse(uploadUrlFourth);
      fileRemoveQuery.mutate(uploadData[index].id[0])
      uploadData[index].id = []
      setUploadUrlFourth(JSON.stringify(uploadData))
    }
    formik.setFieldValue(`damageParcel.[${index}].${fileType}`, '')
  }
  const updateDropdownType = (val: any, formik: any, index: number, uploadFileType: string) => {
    if(uploadFileType === 'fileType_first'){
      const uploadDataFirst = JSON.parse(uploadUrlFirst);
      uploadDataFirst[index].type = val
      setUploadUrlFirst(JSON.stringify(uploadDataFirst))
    }
    if(uploadFileType === 'fileType_second'){
      const uploadDataSecond = JSON.parse(uploadUrlSecond); 
      uploadDataSecond[index].type = val
      setUploadUrlSecond(JSON.stringify(uploadDataSecond))
    }
    if(uploadFileType === 'fileType_third'){
      const uploadDataThird = JSON.parse(uploadUrlThird);
      uploadDataThird[index].type = val
      setUploadUrlThird(JSON.stringify(uploadDataThird))
    }
    if(uploadFileType === 'fileType_fourth'){
      const uploadDataFourth = JSON.parse(uploadUrlFourth);
      uploadDataFourth[index].type = val
      setUploadUrlFourth(JSON.stringify(uploadDataFourth))
    }
    formik.setFieldValue(`damageParcel.[${index}].${uploadFileType}`, val)
  }

  console.log('uploadUrl', JSON.parse(uploadUrlFirst), JSON.parse(uploadUrlSecond), JSON.parse(uploadUrlThird), JSON.parse(uploadUrlFourth))

  return (
    <>
      <Formik 
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}  
      >
        {formik => (
          <FormikForm>
            <FieldArray name='damageParcel'
              render={fieldArrayProps => (
                <div className='claim-main-container multi-claim-group claim-form-padding-left-zero'>
                  {/* {Object.keys(error).length>0 && <Alert
                    message={<ErrorMessage errorMessage={error}/>}
                    type="error"
                    showIcon
                    className='error-message-margin'
                  />} */}
                  {selectedOrders?.map((number: any, index: number) =>
                    <>
                      <Row style={{marginBottom: '10px'}}>
                        <Col span={6} style={{marginLeft: '30px'}}><span style={{fontSize: '14px'}}>Description for <strong>{number.tracking_number}</strong></span></Col>
                        {index===0 && <Col span={15}><span className='text-wrap' title={"Upload Files: Only .png, .jpg and .pdf files are accepted.Each file needs to be less than 10mb. You can add up to a maximum of 4 files."}>{"Upload Files: Only .png, .jpg and .pdf files are accepted. Each file needs to be less than 10mb. You can add up to a maximum of 4 files."}</span></Col>}
                      </Row>
                      <Row gutter={6} className='claim-form-horizontal claim-form-padding-left-thirty'>
                        <Col span={8} className='claim-input-padding claims-input-width claims-input-display'>
                          <FastFormInput required name={`damageParcel.[${index}].description`} textArea={true} rows={7} height={170} resize={'none'}/>
                        </Col>
                        <Col span={5} className='claim-input-padding claims-input-width claims-upload-display'>
                          <Row className='supporting-doc-upload supporting-doc-full-width'>
                            <UploadFile
                              accept=".png,.jpg,.pdf"
                              fileList={formik.values.damageParcel[index].file_first ? null: []}
                              required 
                              name={`damageParcel.[${index}].file_first`} 
                              beforeUpload={(e) => onFileAddition(e, formik, index, 'file_first')}
                              showRemove
                              listType={'picture'}
                              multiple={false}
                              onRemove={(e) => onFileRemove(e, formik, index, 'file_first')}
                            />
                          </Row>
                          <Row className='supporting-doc-type-dropdowm'>
                            <FormSelect required name={`damageParcel.[${index}].fileType_first`} onChangeCallback={(val) => updateDropdownType(val, formik, index, 'fileType_first')} placeholder='Select File Type'> 
                              {
                                Object.entries(damagedParcelFileDropdownOptions).map(([optionKey, optionValue]:[any, any])=>{
                                  return <Option key={optionKey} value={optionKey}>{optionValue}</Option>
                                })
                              }
                            </FormSelect>
                          </Row>
                        </Col>
                        <Col span={5} className='claim-input-padding claims-input-width claims-upload-display'>
                          <Row className='supporting-doc-upload supporting-doc-full-width'>
                            <UploadFile
                              accept=".png,.jpg,.pdf"
                              fileList={formik.values.damageParcel[index].file_second ? null: []}
                              required  
                              name={`damageParcel.[${index}].file_second`} 
                              beforeUpload={(e) => onFileAddition(e, formik, index, 'file_second')}
                              showRemove
                              listType={'picture'}
                              multiple={false}
                              onRemove={(e) => onFileRemove(e, formik, index, 'file_second')}
                            />
                          </Row>
                          <Row className='supporting-doc-type-dropdowm'>
                            <FormSelect required name={`damageParcel.[${index}].fileType_second`} onChangeCallback={(val) => updateDropdownType(val, formik, index, 'fileType_second')} placeholder='Select File Type'> 
                              {
                                Object.entries(damagedParcelFileDropdownOptions).map(([optionKey, optionValue]:[any, any])=>{
                                  return <Option key={optionKey} value={optionKey}>{optionValue}</Option>
                                })
                              }
                            </FormSelect>
                          </Row>
                        </Col>
                        <Col span={5} className='claim-input-padding claims-input-width claims-upload-display'>
                          <Row className='supporting-doc-upload supporting-doc-full-width'>
                            <UploadFile
                              accept=".png,.jpg,.pdf"
                              fileList={formik.values.damageParcel[index].file_third ? null: []}
                              required 
                              name={`damageParcel.[${index}].file_third`} 
                              beforeUpload={(e) => onFileAddition(e, formik, index, 'file_third')}
                              showRemove
                              listType={'picture'}
                              multiple={false}
                              onRemove={(e) => onFileRemove(e, formik, index, 'file_third')}
                            />
                          </Row>
                          <Row className='supporting-doc-type-dropdowm'>
                            <FormSelect required name={`damageParcel.[${index}].fileType_third`} onChangeCallback={(val) => updateDropdownType(val, formik, index, 'fileType_third')} placeholder='Select File Type'> 
                              {
                                Object.entries(damagedParcelFileDropdownOptions).map(([optionKey, optionValue]:[any, any])=>{
                                  return <Option key={optionKey} value={optionKey}>{optionValue}</Option>
                                })
                              }
                            </FormSelect>
                          </Row>
                        </Col>
                        <Col span={5} className='claim-input-padding claims-input-width claims-upload-display'>
                          <Row className='supporting-doc-upload supporting-doc-full-width'>
                            <UploadFile
                              accept=".png,.jpg,.pdf"
                              fileList={formik.values.damageParcel[index].file_fourth ? null: []}
                              required
                              name={`damageParcel.[${index}].file_fourth`} 
                              beforeUpload={(e) => onFileAddition(e, formik, index, 'file_fourth')}
                              showRemove
                              listType={'picture'}
                              multiple={false}
                              onRemove={(e) => onFileRemove(e, formik, index, 'file_fourth')}
                            />
                          </Row>
                          <Row className='supporting-doc-type-dropdowm'>
                            <FormSelect required name={`damageParcel.[${index}].fileType_fourth`} onChangeCallback={(val) => updateDropdownType(val, formik, index, 'fileType_fourth')} placeholder='Select File Type'> 
                              {
                                Object.entries(damagedParcelFileDropdownOptions).map(([optionKey, optionValue]:[any, any])=>{
                                  return <Option key={optionKey} value={optionKey}>{optionValue}</Option>
                                })
                              }
                            </FormSelect>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row className='claim-submit-checkbox'>
                    <Col className='claim-form-padding-left-thirty'>
                      <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)}>
                        I agree to the <a onClick={termsAndConditionModal}>terms and conditions</a> mentioned
                      </Checkbox>
                    </Col>
                    <Col className='claim-submit-button'>
                      <Button type='primary' htmlType='submit' disabled={!checked} className='blue-button'>{'Submit Claim'}</Button>
                    </Col>
                  </Row>
                  <Modal title={'Terms and Condition'} visible={isShowModal} onCancel={termsAndConditionModal} onOk={termsAndConditionModal}>
                    <TermsAndCondition/>
                  </Modal>
                </div>
              )}
            />
            
          </FormikForm>
        )}
      </Formik>
    </>
  )
}