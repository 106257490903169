import { Button, Card, Col, Form, Row, Select, Tooltip } from 'antd';
import React, { useRef, useState, useEffect } from "react";
import { PickUpAddressForShopify as AddAddressModal } from "../../../UserSettings/components/PickUpAddressForShopify";
import { InfoCircleOutlined, EnvironmentOutlined } from '@ant-design/icons'
import moment from 'moment';
import { getLocations, getDestinationCountries, getServiceDefinitions, getAllCountries_from_b2c } from "../../services/privateApi";
import { useSingleOrder, OrderError } from "../../services/singleOrderContext";
import { useAuth } from "../../../auth/services/authContext";
import { countries, countryCodes, estimatedDays } from "../../../commons/utils/constants";
import { useQuery } from "react-query";
import '../../styles/SingleOrderForm.css';
import {
  getPickUpAddressData,
  getBillingAddressData,
  getFormatedDate,
  parseDate,
  getDropOffAddressData,
  getDropOffWarehouseAddressData,
  getFormattedServiceTypeTextForSS,
  filterOnlyRequiredData
} from "../../../commons/utils/utilizer";
import { Formik, Form as FormikForm } from 'formik';
import { FormInput, FormSelect, DatePicker, TitleErrorMarker } from '../Forms';
import { disabledDate, parseErrors, pickupDateHasExpired,canChoosePickupDate, PickupDays} from '../../helpers';
import * as Yup from 'yup';
import { SingleOrderCreate } from "../../../commons/mixpanel"
import { useLanguage } from '../../../languages/Language';
import {DropOffAddressModal} from '../DropOffAddressModal';
import {getPUDOPoints} from '../../../GoogleMaps/services';
import {PickupChargeAlert} from '../../../commons/components/PickupFeeAlerts/PickupChargeAlert';
const { Option } = Select;
interface PropsType {
  current: number,
  setCurrentState: (val: number) => void,
  dataFilled: number,
  setPickupDateExpiredCallback?: (val: boolean) => any
 }

interface FormValue {
  pickup_country_code: string | undefined
  consignee_country_code: string | undefined
  service_type: string | null
  pickup_address_id: number | null
  pickup_date: moment.Moment | null
  pickup_time: string
  tracking_no: string | undefined
  dropoff_vendor_point_id: number | null
}

const parseOrderContextError = (error: OrderError) => {
  let err = {
    pickup_address_id: '',
    billing_address_id: '',
    consignee_country_code: error.consignee_country_code,
    service_type: error.service_type,
    pickup_date: error.pickup_date,
    tracking_no: error.tracking_no
  }

  let pickup_err = [];
  if (error.pickup_contact_name) {
    pickup_err.push(error.pickup_contact_name);
  }
  if (error.pickup_contact_number) {
    pickup_err.push(error.pickup_contact_number);
  }
  if (error.pickup_address) {
    pickup_err.push(error.pickup_address);
  }
  if (error.pickup_state) {
    pickup_err.push(error.pickup_state);
  }
  if (error.pickup_city) {
    pickup_err.push(error.pickup_city);
  }
  if (error.pickup_province) {
    pickup_err.push(error.pickup_province);
  }
  if (error.pickup_postal) {
    pickup_err.push(error.pickup_postal);
  }

  if (pickup_err.length > 0) {
    err.pickup_address_id = pickup_err.join(',')
  }

  let billing_err = [];
  if (error.billing_contact_name) {
    billing_err.push(error.billing_contact_name)
  }
  if (error.billing_contact_number) {
    billing_err.push(error.billing_contact_number)
  }
  if (error.billing_address) {
    billing_err.push(error.billing_address)
  }
  if (error.billing_state) {
    billing_err.push(error.billing_state)
  }
  if (error.billing_city) {
    billing_err.push(error.billing_city)
  }
  if (error.billing_postal) {
    billing_err.push(error.billing_postal)
  }
  if (error.billing_country_code) {
    billing_err.push(error.billing_country_code)
  }

  if (billing_err.length > 0) {
    err.billing_address_id = billing_err.join(',')
  }

  return err;
}

const validationSchema = (props: any,getText: any) => {
  return (
    Yup.object().shape({
      pickup_country_code: Yup.string().required(getText('Please select Origin Country')),
      consignee_country_code: Yup.string().required(getText('Please select Destination Country')),
      service_type: Yup.string().required(getText('Please select Service Type')),
      pickup_address_id: Yup.string().nullable().when('service_type',{
        is: 'Pickup',
        then: Yup.string().nullable().required(getText('Please select Pickup Address'))
      }),
      dropoff_vendor_point_id: Yup.string().nullable().when('service_type', {
        is: 'Dropoff',
        then: Yup.string().nullable().required('Please select DropOff Address')
      }),
      pickup_date: Yup.date().nullable().when(['service_type', 'pickup_country_code'], {
        is: (service_type: any, pickup_country_code: any) => service_type === 'Pickup' && pickup_country_code === 'SG',
        then: Yup.date().nullable().required('Please select Pickup Date')
          .test(
            'valid-date',
            (val: any, context: any) => {
              if (val) {
                let formattedDate = parseDate(val);
                if ((!moment().isBefore(moment({ hour: 8, minute: 0 })) && formattedDate!.isBefore(moment().endOf('day')))) {
                  return context.createError({
                    path: context.path,
                    message: getText('Please select valid date')
                  })
                }
              }
              return true
            }
          )
      }),
    })
  )
}

export const ShipmentDetailsForm = (props: PropsType) => {

  let { getText } = useLanguage();
  const resetFieldValuesOnPickupChange = ['consignee_country_code', 'service_type', 'pickup_address_id','pickup_date','dropoff_vendor_point_id'];
  const resetFieldValuesOnDestinationChange = ['service_type', 'pickup_address_id','dropoff_vendor_point_id'];
  const resetFieldValuesOnServiceTypeChange = ['dropoff_vendor_point_id','dropoff_vendor_name','pickup_address_id','pickup_date'];
  const auth = useAuth();
  const singleOrderContext = useSingleOrder();
  const ShipmentDetailsFormRef: any = useRef();
  const authToken = auth.user.authToken;
  let pickupDate = singleOrderContext.singleOrder.pickup_date ? singleOrderContext.singleOrder.pickup_date : null;
  const [showMapModal, setShowMapModal] = useState<boolean>(false);
  const setShowMapModalCallback = (val: boolean) => {
    setShowMapModal(val)
  }
  const handleSelectedDropOffPointChange = (val: any) => {
    if (val && ShipmentDetailsFormRef.current) {
      ShipmentDetailsFormRef.current.setFieldValue('dropoff_vendor_point_id', (val?.dropoff_id || null))
    }
  }
  // let temp_auth_token = 'cf93deb1f1ea51b3e09556b63d17c69821c9db64'
  const pickupLocations = useQuery('pickupLocations', () => {
    return getLocations(authToken)
  })
  let pickupCountryCode = singleOrderContext.singleOrder.pickup_country_code ? singleOrderContext.singleOrder.pickup_country_code : 'SG'

  let destinationCountryCode = singleOrderContext.singleOrder.consignee_country_code ? singleOrderContext.singleOrder.consignee_country_code : 'SG'

  const initialValue = {
    pickup_country_code: singleOrderContext.singleOrder.pickup_country_code,
    consignee_country_code: singleOrderContext.singleOrder.consignee_country_code,
    service_type: singleOrderContext.formContext.service_type,
    pickup_date: pickupDateHasExpired(pickupDate) ? null : parseDate(singleOrderContext.singleOrder.pickup_date),
    pickup_address_id: singleOrderContext.singleOrder.pickup_location,
    dropoff_vendor_point_id: singleOrderContext.singleOrder.dropoff_vendor_point_id,
    tracking_no: singleOrderContext.singleOrder.tracking_no,
    pickup_time: '9am - 7pm'
  }
  const { initialErrors, initialTouched, hasError, emptyError } = parseErrors(
    singleOrderContext.errors, parseOrderContextError
  )
  const { current, setCurrentState, dataFilled } = props;
  const [showAddAddressModal, setShowAddAddressModal] = useState<boolean>(false);
  const [shipmentData, setShipmentData] = useState<FormValue | null>(null);
  const [addressType, setAddressType] = useState<string>('');
  const [originCountryCode, setOriginCountryCode] = useState<string>(pickupCountryCode)

  const [destCountryCode, setDestCountryCode] = useState<string>(destinationCountryCode)

  //   if(hasError){
  //     setCurrentState(0);
  //     if (ShipmentDetailsFormRef.current != null) {
  //       window.scrollTo(0, ShipmentDetailsFormRef.current.offsetTop);
  //     }
  //   }

  const getAllCountriesData = useQuery('getAllCountriesData', () => {
    return getAllCountries_from_b2c()
  })

  const getDestinationCountriesData = useQuery(['getDestinationCountriesData', originCountryCode], () => {
    return getDestinationCountries(authToken, originCountryCode)
  })

  // Fetch service Definitions Data 
  const getServiceDefinitionsData = useQuery(['serviceDefinitions'], () => {
    return getServiceDefinitions(authToken)
  })

  const setShowAddAddressModalCallback = (val: boolean) => {
    setShowAddAddressModal(val);
    setAddressType('');

    // This will refetch the pickup locations every time the modal is triggered.
    // Ideally there should be an indicator if a new location is added and only
    // refetch then.
    pickupLocations.refetch()
  };

  const setCreatedLocationIdCallback = (id: string) => {
    if (ShipmentDetailsFormRef.current) {
      ShipmentDetailsFormRef.current.setFieldValue('pickup_address_id', id)
    }
  }

  const getDropOffLocations = useQuery(['getPUDOPoints'], async () => {
    return getPUDOPoints(auth.user.authToken)
  })

  useEffect(()=>{
    let pickupDateExpired = pickupDateHasExpired(pickupDate);
    if(pickupDateExpired){
      singleOrderContext.setErrors({pickup_date:'Please select new pickup date'})
      setCurrentState(0)
      if(props.setPickupDateExpiredCallback){
        props.setPickupDateExpiredCallback(true)
      }
    }
  },[pickupDate]) // eslint-disable-line react-hooks/exhaustive-deps

  const onFormikSubmit = (values: FormValue) => {
    let dropOffData;
    if(values.service_type === 'Dropoff') {
      dropOffData = getDropOffAddressData(values.dropoff_vendor_point_id!, getDropOffLocations.data.dropoff_locations)
    }
    if(values.service_type === 'Dropoff_WH' || values.service_type === 'CC_LM' || values.service_type === 'Dropoff_Express') {
      dropOffData = getDropOffWarehouseAddressData(values.dropoff_vendor_point_id!, getServiceDefinitionsData?.data!)
    }
    SingleOrderCreate('shipmentOverview')
    SingleOrderCreate('customerDetails')
    setCurrentState(1);
    let pickup_date = getFormatedDate(values.pickup_date)
    setShipmentData({ ...values });
    
    if(values.pickup_address_id&&(values.service_type === 'Pickup' || values.service_type === 'Pickup_Express' || values.service_type === 'Pickup Express' || values.service_type === 'Pickup Express Priority')) {
      singleOrderContext.updateSingleOrderData({
        pickup_country_code: values.pickup_country_code,
        consignee_country_code: values.consignee_country_code,
        tracking_no: values.tracking_no?values.tracking_no:undefined,
        pickup_date: pickup_date,
        ...getPickUpAddressData(values.pickup_address_id!, pickupLocations.data),
        ...getBillingAddressData(values.pickup_address_id!, pickupLocations.data)
      });
    }else if(values.service_type === 'Dropoff_WH' || values.service_type === 'CC_LM' || values.service_type === 'Dropoff_Express') {
      singleOrderContext.updateSingleOrderData({
        pickup_country_code: values.pickup_country_code,
        consignee_country_code: values.consignee_country_code,
        dropoff_vendor_point_id: dropOffData.id,
        pickup_contact_name: dropOffData.name,
        pickup_contact_number: dropOffData.contact_number,
        pickup_address: dropOffData.address,
        pickup_city: dropOffData.dropOff_city,
        billing_contact_name: dropOffData.name,
        billing_contact_number:dropOffData.contact_number,
        billing_country_code: values.pickup_country_code,
        pickup_state: dropOffData.dropOff_state,
        billing_address: dropOffData.address,
        pickup_location: undefined,
        billing_location: undefined,
        pickup_date: null,
        billing_postal: dropOffData.dropOff_postal,
        pickup_postal: dropOffData.dropOff_postal,
        tracking_no: values.tracking_no?values.tracking_no:undefined
      });
    }else {
      singleOrderContext.updateSingleOrderData({
        pickup_country_code: values.pickup_country_code,
        consignee_country_code: values.consignee_country_code,
        dropoff_vendor_name: dropOffData.vendor_name,
        dropoff_vendor_point_id: dropOffData.id,
        pickup_contact_name: dropOffData.name,
        pickup_contact_number: dropOffData.contact_number,
        pickup_address: dropOffData.address,
        pickup_city: dropOffData.dropOff_city,
        billing_contact_name: dropOffData.name,
        billing_contact_number:dropOffData.contact_number,
        billing_country_code: values.pickup_country_code,
        pickup_state: dropOffData.dropOff_state,
        billing_address: dropOffData.address,
        pickup_location: undefined,
        billing_location: undefined,
        pickup_date: null,
        billing_postal: dropOffData.dropOff_postal,
        pickup_postal: dropOffData.dropOff_postal,
        tracking_no: values.tracking_no?values.tracking_no:undefined
      });
    }
    singleOrderContext.setFormContext({
      service_type: values.service_type!,
    })

    singleOrderContext.setErrors({ ...emptyError })
  }
  if (current !== 0 && dataFilled === 0) {
    return (
      <>
        {shipmentData && <Card title={<TitleErrorMarker text={getText('Shipment Overview')} hasError={hasError} />} className={"form-summary-container"}>
          <p style={{ margin: 0 }}>{countries[shipmentData.pickup_country_code!]} to {countries[shipmentData.consignee_country_code!]}</p>
          <p className={'gray-text'}>{getFormattedServiceTypeTextForSS(shipmentData?.service_type!)} service</p>
        </Card>}
      </>
    )
  }
  else if (current !== 0 && dataFilled === 1) {
    return (
      <>
        {singleOrderContext.singleOrder && <Card title={<TitleErrorMarker text={getText('Shipment Overview')} hasError={hasError} />} className={"form-summary-container"}>
          <p style={{ margin: 0 }}>{countries[initialValue.pickup_country_code!]} to {countries[initialValue.consignee_country_code!]}</p>
          <p className={'gray-text'}>{getFormattedServiceTypeTextForSS(initialValue?.service_type!)}</p>
        </Card>}
      </>
    )
  }

  const onChangeHandler = (value: any) => {
    setOriginCountryCode(value)
  }
  const onDestinationCountryChange = (value:any) => {
    if(destCountryCode !== value){
      setDestCountryCode(value)
      singleOrderContext.updateSingleOrderData({
        consignee_state: '',
        consignee_city: '',
        consignee_postal: '',
        cod_amount: null,
        cod_currency: '',
        payment_type: 'prepaid',
        incoterm:'DDP'
      });
    }
    
  }

  const filterServices = getDestinationCountriesData.data?.filter((countryObj) => {
    return destCountryCode === countryObj.destination_country_code
  })[0]

  //remove duplicates from service types and byepass exclusive service type and remove duplicate service type for exclusive and standard lanes
  const filterServiceTypes = filterServices?.service_type!.filter((service_type, index) => {
    return filterServices?.service_type!.indexOf(service_type) === index;
  });
  
  const sortPUDOPointsByPostal = (data:any) => {
    return data?.sort((a:any,b:any)=> (a.postal > b.postal ? 1 : -1))
  }

  const onPickupDateChange = (val:any) => {
    if(props.setPickupDateExpiredCallback){
      props.setPickupDateExpiredCallback(false)
    }
  }

  const showPickupDate = (originCountry: string) => {
    let countryArr = ['SG', 'TH']
    if(countryArr.includes(originCountry)) {
      return true
    } else {
      return false
    }
  }

  const getWarehouseDropOffPoints = (originCountry?: string, destinationCountry?: string, serviceType?: string) => {
    if(getServiceDefinitionsData.data) {
      return filterOnlyRequiredData(getServiceDefinitionsData.data, originCountry, destinationCountry, serviceType)  
    }
    return []
  }

  // @ts-ignore
  return (
    <div ref={r => { ShipmentDetailsFormRef.current = ShipmentDetailsFormRef.current ? ShipmentDetailsFormRef.current : r; }}>
      <Formik
        innerRef={ShipmentDetailsFormRef}
        initialValues={initialValue}
        initialErrors={initialErrors}
        initialTouched={initialTouched}
        enableReinitialize={true}
        onSubmit={onFormikSubmit}
        validateOnChange={true}
        validationSchema={() => validationSchema(props, getText)}>
        {formik => (
          <div className={"single-order-form-container ant-form-vertical"}>
            <FormikForm>

              <Form.Item label={<TitleErrorMarker text={getText('Shipment Overview')} hasError={hasError} />} className={"single-order-form-title"}></Form.Item>
              <Row gutter={14}>
                <Col span={12}>
                  <FormInput name="tracking_no" label={getText("Shipper Order Id (Optional)")}/>
                </Col>
              </Row>
              <Row gutter={14}>
                <Col span={12}>
                  <FormSelect name="pickup_country_code" loading={getAllCountriesData.isLoading} label={getText('Origin Country')} required onChangeCallback={(value: any) => {
                    resetFieldValuesOnPickupChange.forEach(field => formik.setFieldValue(field, ''));
                    onChangeHandler(value)
                  }}
                  >
                    {
                      getAllCountriesData && getAllCountriesData.data?.map((item: any) => {
                        return <Option key={item} value={countryCodes[item]}>{item}</Option>
                      })
                    }
                  </FormSelect>
                  {(getAllCountriesData.isError) && <p style={{ color: 'red', marginBottom: 0, marginTop: '-20px', fontSize: '11px' }}>{getText("Failed to load origin countries, please try again later")}</p>}
                </Col>
                <Col span={12}>
                  <FormSelect name="consignee_country_code" disabled={formik.values.pickup_country_code ? false : true} label={getText('Destination Country')} loading={getAllCountriesData.isLoading} required
                    onChangeCallback={(value: any) => {
                      resetFieldValuesOnDestinationChange.forEach(field => formik.setFieldValue(field, '')); onDestinationCountryChange(value)
                    }}
                  >
                    {
                      getAllCountriesData && getAllCountriesData.data?.map((item: any) => {
                        return <Option key={item} value={countryCodes[item]}>{item}</Option>
                      })
                    }
                  </FormSelect>
                  {(getAllCountriesData.isError) && <p style={{ color: 'red', marginBottom: 0, marginTop: '-20px', fontSize: '11px' }}>{getText("Failed to load destination countries, please try again later")}</p>}
                </Col>
              </Row>
              <Row gutter={14}>
                {/* Adding this for testing only will remove this code and uncomment the below one */}
                {/* {AuthTokenFlag() ? */}
                <Col span={12}>
                  <FormSelect name="service_type" required onChangeCallback={(value: any) => {resetFieldValuesOnServiceTypeChange.forEach(field => formik.setFieldValue(field, ''));}} label={<label className="info-disclaimer">{getText('Service Type')}:<Tooltip title={getText('Our delivery team will come to your address to have the parcel pick up.')}><InfoCircleOutlined />
                  </Tooltip></label>} >
                    {
                      filterServiceTypes?.map((type: any, index: number) => {
                        if(auth.user.payment_type === 'PayInstantly') {
                          return (type !== 'CC_LM') && <Option key={index} value={type}>{getFormattedServiceTypeTextForSS(type)}</Option>
                        } else {
                          return <Option key={index} value={type}>{getFormattedServiceTypeTextForSS(type)}</Option>
                        }
                      })
                    }
                    {/* {pickupExpressPriority(formik.values.pickup_country_code, formik.values.consignee_country_code, auth.user.b2c_id) && 
                      <Option key={'index-1'} value={'Pickup Express Priority'}>{'Express Priority: Pickup from your selected address'}</Option>
                    }
                    {
                      pickupExpress(formik.values.pickup_country_code, formik.values.consignee_country_code, auth.user.b2c_id) && 
                        <Option key={'index-11'} value={'Pickup Express'}>{'Pickup Express'}</Option>
                    } */}
                  </FormSelect>
                </Col>
                {/* :
                   <Col span={12}>
                     <FormSelect name="service_type" required label={<label className="info-disclaimer">{getText('Pickup Type')}:<Tooltip title={getText('Our delivery team will come to your address to have the parcel pick up.')}><InfoCircleOutlined />
                     </Tooltip></label>} >
                     <Option value={'Pickup'}>Pickup</Option>
                     </FormSelect>
                   </Col>}
                */}
                <Col span={12}>
                  {(formik.values.service_type === 'Pickup' || formik.values.service_type === 'Pickup_Express' || formik.values.service_type === 'Pickup Express' || formik.values.service_type === 'Pickup Express Priority') && (
                    <FormSelect name="pickup_address_id" required label={getText('Pickup Address')} loading={pickupLocations.isLoading} wrapperClass="no-margin-item" placeholder={'Select an address'} showSearch={'false'} defaultValue={formik.values.pickup_address_id?.toString()}>
                      {
                        pickupLocations.data?.filter(l => l.country_code === formik.values.pickup_country_code && l.type === 'FORWARD').map(item => <Option key={item.id} value={item.id}>{`${item.name} - ${item.address}`}</Option>)
                      }
                    </FormSelect>)
                  }
                  {formik.values.service_type === 'Dropoff' && (
                    <FormSelect name="dropoff_vendor_point_id" required
                      label={<label>{'Drop-off Address'} <EnvironmentOutlined style={{color: 'red', fontSize: '14px', position: 'relative', top: '1px'}} /></label>}
                      loading={getDropOffLocations.isLoading} wrapperClass="no-margin-item" placeholder={'Select a drop-off Address'}>
                      {
                        getDropOffLocations&&sortPUDOPointsByPostal(getDropOffLocations.data?.dropoff_locations)?.map((item:any) => <Option className={"dropoff-dropdown"} key={item.dropoff_id} value={item.dropoff_id}>{`${item.name} - ${item.address}, ${item.postal}`}</Option>)
                      }
                    </FormSelect>)
                  }


                  {(formik.values.service_type === 'Dropoff_WH' || formik.values.service_type === 'Dropoff_Express') && (
                    <FormSelect name="dropoff_vendor_point_id" required
                      label={<label>{'Warehouse Drop-off Address'} <EnvironmentOutlined style={{color: 'red', fontSize: '14px', position: 'relative', top: '1px'}} /></label>}
                      loading={getDropOffLocations.isLoading} wrapperClass="no-margin-item" placeholder={'Select a drop-off Address'}>
                      {
                        getWarehouseDropOffPoints(countries[formik.values?.pickup_country_code!], countries[formik.values?.consignee_country_code!], 'Dropoff')?.map((item:any) => item.dropoff_address &&  <Option className={"dropoff-dropdown"} key={item.dropoff_address} value={item.dropoff_id}>{`${item.service_id ? item.dropoff_address : 'No Data'}`}</Option>)
                      }
                    </FormSelect>)
                  }

                  {formik.values.service_type === 'CC_LM' && (
                    <FormSelect name="dropoff_vendor_point_id" required
                      label={<label>{'Pickup Address'}</label>}
                      loading={getDropOffLocations.isLoading} wrapperClass="no-margin-item">
                      {
                        getWarehouseDropOffPoints(countries[formik.values?.pickup_country_code!], countries[formik.values?.consignee_country_code!], 'Customs clearance and last mile')?.map((item:any) => item.dropoff_address &&  <Option className={"dropoff-dropdown"} key={item.dropoff_address} value={item.dropoff_id}>{`${item.service_id ? item.dropoff_address : 'No Data'}`}</Option>)
                      }
                    </FormSelect>)
                  }


                  {(formik.values.service_type === 'Pickup' || formik.values.service_type === 'Pickup_Express' || formik.values.service_type === 'Pickup Express' || formik.values.service_type === 'Pickup Express Priority') && <Form.Item className={'no-margin-item'}>
                    <Button type="primary" onClick={() => {
                      setShowAddAddressModal(true);
                      setAddressType('pickup')
                    }} className={"no-border-btn"}>
                      {getText('Add new address')}
                    </Button>
                    {(showAddAddressModal && addressType === 'pickup') &&
                    <AddAddressModal
                      setShowAddAddressModalCallback={setShowAddAddressModalCallback}
                      setCreatedLocationIdCallback={setCreatedLocationIdCallback}
                      countryCode={formik.values.pickup_country_code}
                      addressType={'pickup'}
                    />}
                  </Form.Item>}
                  {formik.values.service_type === 'Dropoff' && <Form.Item className={'no-margin-item'}>
                    <Button type="primary" onClick={() => {
                      setShowMapModal(true);
                      setAddressType('dropoff')
                    }} className={"no-border-btn"}>
                      {'See and select from a map'}
                    </Button>
                    {(showMapModal && addressType === 'dropoff') &&
                    <DropOffAddressModal setSelectedDropOffPoint={handleSelectedDropOffPointChange} setShowMapModalCallback={setShowMapModalCallback} dropOffLocations={getDropOffLocations} showModal={showMapModal}/>}
                  </Form.Item>}
                </Col>
              </Row>
              {
                showPickupDate(formik.values.pickup_country_code!) && 
                  <Row gutter={14}>
                    <Col span={12}>
                      {(formik.values.service_type === 'Pickup' || formik.values.service_type === 'Pickup_Express' || formik.values.service_type === 'Pickup Express' || formik.values.service_type === 'Pickup Express Priority') && (<>
                        <DatePicker disabledDate={disabledDate} required={formik.values.pickup_country_code === 'SG'} showToday={false} name="pickup_date" label={'Pickup Date'} wrapperClass="no-margin-item"
                          onChange={onPickupDateChange} disabled={canChoosePickupDate(formik.values.pickup_country_code)}
                        />
                        <Form.Item label={canChoosePickupDate(formik.values.pickup_country_code)?`Your order will be picked up within ${PickupDays[formik.values.pickup_country_code]} working days`:getText('If not selected, it will be pickup the next business day')} className={'form-item-info'}></Form.Item>
                      </>)}
                    </Col>
                    <Col span={12}>
                      {(formik.values.service_type === 'Pickup' || formik.values.service_type === 'Pickup_Express' || formik.values.service_type === 'Pickup Express' || formik.values.service_type === 'Pickup Express Priority') && <>
                        <FormInput name="pickup_time" label={<label className="info-disclaimer">{getText('Pickup Time')}:<Tooltip title={getText('If order is created before 8 AM then pickup will be same day else next day')}><InfoCircleOutlined />
                        </Tooltip></label>} wrapperClass="no-margin-item" inputProps={{ disabled: true }} />
                        <Form.Item label={getText('Timing are fixed and cannot be selected')} className={'form-item-info'}></Form.Item>
                      </>}
                    </Col>
                  </Row>
              }
              
              {
                (formik.values.service_type === 'Dropoff' && formik.values.dropoff_vendor_point_id) &&
                <div className="dropoff-gray-text">
                  <p><strong>Drop-off Address</strong></p>
                  <p>{getDropOffAddressData(formik.values.dropoff_vendor_point_id!, getDropOffLocations.data.dropoff_locations).type}</p>
                  <p>{getDropOffAddressData(formik.values.dropoff_vendor_point_id!, getDropOffLocations.data.dropoff_locations).address}</p>
                  <p>{getDropOffAddressData(formik.values.dropoff_vendor_point_id!, getDropOffLocations.data.dropoff_locations).dropOff_country} {getDropOffAddressData(formik.values.dropoff_vendor_point_id!, getDropOffLocations.data.dropoff_locations).dropOff_postal}</p>
                  <p>{getDropOffAddressData(formik.values.dropoff_vendor_point_id!, getDropOffLocations.data.dropoff_locations).opening_hours?.join(', ')}</p>
                </div>
              }
              {
                (formik.values.pickup_country_code && formik.values.consignee_country_code) && estimatedDays[`${formik.values.pickup_country_code}${formik.values.consignee_country_code}`] &&
                <Form.Item label={`${countries[formik.values.pickup_country_code]} to ${countries[formik.values.consignee_country_code]} will take ${estimatedDays[`${formik.values.pickup_country_code}${formik.values.consignee_country_code}`]} delivery.`} className={"single-order-form-title"}>
                </Form.Item>
              }
              {(formik.values.service_type === 'Pickup'&&formik.values.pickup_country_code==='SG') &&
                 <PickupChargeAlert/>
              }
              <div className={"button-block"}>
                <Button htmlType="submit" type="primary" className={"blue-button"}>
                  {getText('Save & Continue')}
                </Button>
              </div>

            </FormikForm>
          </div>
        )}
      </Formik>
    </div>
  )
};

export const ShipmentDetailsFormTitle = (props: PropsType) => {
  let { getText } = useLanguage();
  const { current, setCurrentState } = props;
  return (
    <div className={"title-block"}>
      <p className={'step-title'}>{getText('Where are we picking up from?')}</p>
      {current > 0 ? <p onClick={() => setCurrentState(0)} className={"edit-text"}>{getText('Edit details')}</p> : ''}
    </div>
  )
};