import {  Table, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { UserSelectedAnalyticsGranularity } from '../../../commons/mixpanel';
import { AnalyticsButton } from "../../../commons/components/AnalyticsButton/AnalyticsButton"

const createColumns = (tableData: any) => {
  let column: any = [{
    title: 'Origin Country',
    dataIndex: 'pickup_country',
    key: 'pickup_country',
    fixed: 'left',
  },
  {
    title: 'Destination Country',
    dataIndex: 'consignee_country',
    key: 'consignee_country',
    fixed: 'left',
  },
  {
    title: 'Destination Zone',
    dataIndex: 'destination_zone',
    key: 'destination_zone',
    fixed: 'left',
  }];
  let date: Array<string> = []
  tableData.forEach((data: any) => {
    if(date.indexOf(data.created) === -1){
      date.push(data.created)
      let tempColumn = {
        title: data.created.slice(0, 10).split("-").reverse().join("-"),
        dataIndex: data.created,
        key: data.created,
      }
      column.push(tempColumn)
    }
  });
  return column;
}

const formTabularData = (tableData: any) => {
  let modifiedTabularData: any = []
  let tempRow: any = {}
  tableData.forEach((data: any) => {
    tempRow = {
      pickup_country: data.pickup_country,
      consignee_country: data.consignee_country,
      destination_zone: data.destination_zone,
    }
    tableData.forEach((subData: any) => {
      if(subData.consignee_country === data.consignee_country && subData.destination_zone === data.destination_zone){
        tempRow[`${subData.created}`] = Math.round(subData.sla*100) + '%';
      }
    });
    modifiedTabularData.push(tempRow);
  });
  return modifiedTabularData;
}

const scrollData = (column: any) => {
  if(column.length <= 7){
    return {y: 377}
  }else if(column.length > 25){
    return { x: 5000, y: 377 }
  }else if(column.length > 10){
    return { x: 2500, y: 377 }
  }
  return { x: 1500, y: 377 }
}

interface slaPerformanceTableProps{
  slaPerformanceTableData: any
  granularity: string
  setGranularity(val: string): void
  range: string
}
export const SlaPerformanceTable = (props: slaPerformanceTableProps) => {
  const {granularity, setGranularity, range} = props
  const [slaTableData, setSlaTableData] = useState([])
  const [tableColumns, setTableColumns] = useState([])
  useEffect(() => {
    if(props.slaPerformanceTableData){
      setTableColumns(createColumns(props.slaPerformanceTableData))
      setSlaTableData(formTabularData(props.slaPerformanceTableData))
    }    
  },[props.slaPerformanceTableData])
  return (
    <div className='chart-header-custom-table'>
      <h2>SLA Performance by Destination Zone</h2>
      <div className='granularity'>
        <AnalyticsButton onButtonClick={()=>setGranularity('weekly')} mixpanelAnalytics={() => UserSelectedAnalyticsGranularity('SLA Performance by Destination Zone', 'weekly')} className={'granularity-button'} type={granularity==='weekly'? 'primary':'default'} disabled={range === 'last_day' || range === 'last_week'}>Weekly</AnalyticsButton>
        <AnalyticsButton onButtonClick={()=>setGranularity('monthly')} mixpanelAnalytics={() => UserSelectedAnalyticsGranularity('SLA Performance by Destination Zone', 'monthly')} className={'granularity-button'} type={granularity==='monthly'? 'primary':'default'} disabled={range === 'last_day' || range === 'last_week'}>Monthly</AnalyticsButton>
      </div>
      {props.slaPerformanceTableData? <Table columns={tableColumns} dataSource={slaTableData} pagination={false} scroll={scrollData(tableColumns)}  /> : <div className={'sub-charts chart-spinner'}><Spin/></div>  }
    </div>
  )
}
