import { Bar } from '@ant-design/plots';
import { useEffect, useState } from 'react';
import { Spin, Empty } from 'antd';
interface barProps{
  barPlotData: any
  type: string
}

const modifyKey = (key: string, type: string) => {
  if(type === 'shipmentNotMoving'){
    if(key === 'zero_to_two'){
      return '0 - 2 days ago'
    }else if(key === 'three_to_four'){
      return '3 - 4 days ago'
    }else if(key === 'five_to_nine'){
      return '5 - 9 days ago'
    }else if(key === 'more_than_ten'){
      return '> 10 days ago'
    }
  }else if(type === 'breachedSla'){
    if(key === 'one_to_four'){
      return '1 - 4 days'
    }else if(key === 'five_to_nine'){
      return '5 - 9 days'
    }else if(key === 'more_than_ten'){
      return '> 10 days'
    }
  }else{
    return key;
  } 
}

export const BarPlot = (props: barProps) => {
  const [chartData, setChartData] = useState([])

  useEffect(() => {
    let chartArray: any = []
    if(props.barPlotData.data){
      Object.entries(props.barPlotData.data).forEach(([key, value]) => {
        let data = {
          type: modifyKey(key, props.type),
          value: value
        }
        chartArray.push(data)
      });
      props.type === 'breachedSla' ? setChartData(chartArray.reverse()) : setChartData(chartArray)
    }
    
  },[props.barPlotData.data, props.type])

  const config = {
    data: chartData,
    xField: 'value',
    yField: 'type',
    barWidthRatio: 0.5,
    meta: {
      type: {
        alias: 'Days',
      },
      value: {
        alias: 'Shipments',
      },
    },
  };
  return (
    <div className='chart-header-custom'>
      {props.type === 'shipmentNotMoving' ? 
        <h2>Shipments that have not moved since the last update </h2> :
        <h2>Number of shipments that have breached SLA</h2>
      }      
      {!props.barPlotData.isLoading? 
        Object.keys(props.barPlotData.data).length !== 0 ? <Bar {...config}  className={'sub-charts'} /> : <div className={'sub-charts no-data-image'}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/></div>
        : <div className={'sub-charts chart-spinner'}>
          <Spin/>
        </div>  
      }
    </div>
  )
};