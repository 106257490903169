import { Link, useHistory, useLocation } from "react-router-dom"
import { Spin, message, Alert, Image, Layout as L, Button, } from "antd";
import {useMutation} from "react-query";
import { contactCustomerService } from "../commons/utils/constants";
import { useAuth } from "../auth/services/authContext";
import { onboardShopifytoB2C } from "./services";
import { useEffect, useState, ReactElement } from "react";
import janio_logo from "../assets/images/janio_logo.svg";

export const IntegrationAuthCallback = () => {
  const history = useHistory()
  const location = useLocation()
  console.log('locationlocation', location)
  const auth = useAuth();
  const authToken = auth.user.authToken;
  const params = new URLSearchParams(window.location.search);
  const [callbackResponse, setCallbackResponse] = useState<string | ReactElement>("");
  let alertMsg: string | ReactElement = callbackResponse;

  const onBoardIntegration = useMutation(async (id: string) => {
    let newParams = {
      secret_key: auth.user.secret_key,
      referrer_shopify: id
    }
    return onboardShopifytoB2C(newParams)
  },  {
    onSuccess: (val: any) => {
      localStorage.removeItem('referrer_shopify')
      setTimeout(() => {
        history.push('/integrations')
      }, 500)
      message.success('Store added successfully')
    },
    onError: (error: any) => {
      localStorage.removeItem('referrer_shopify')
      if (error.response.data && error.response.data.error) {
        // Something happened in setting up the request that triggered an Error
        setCallbackResponse(error.response.data.error)
      } else {
        setCallbackResponse(<span>Something went wrong, please contact us at {contactCustomerService()}</span>)
      }
    }
  });
  useEffect(() => {
    if(localStorage.getItem('referrer_shopify') && authToken) {
      onBoardIntegration.mutate(localStorage.getItem('referrer_shopify')!)  
    }
    if(location.search.includes('?referrer_shopify') && authToken) {
      onBoardIntegration.mutate(params.get('referrer_shopify')!)
    }
  },[authToken]) // eslint-disable-line react-hooks/exhaustive-deps

  if(!authToken && location.search.includes('?referrer_shopify')) {
    localStorage.setItem('referrer_shopify', params.get("referrer_shopify")!)
    setTimeout(() => {
      history.push('/auth/login')
    }, 1500)
  }

  return (
    <>
      {!location.pathname.includes('shopify-integration') &&
        <>
          <L.Sider className={'custom-sider'} width={240}>
            <div className="logo">
              <a href="#">
                <Image preview={false} src={janio_logo} className={"site-logo"}/>
              </a>
            </div>
          </L.Sider>
          <L>
            <L.Header className={'custom-header'}>
            </L.Header>
          </L>
        </>
      }
      <div
        style={{
          width: '30%',
          margin: '40px auto'
        }}
      >
        {!callbackResponse &&
          <Spin style={{'position': 'absolute','left': '50%', 'top': '50%'}} />
        }
        {callbackResponse && (
          <>
            <Alert
              message="Error"
              description={alertMsg}
              type="warning"
              showIcon
              closable
            />
            {!location.pathname.includes('shopify-integration') &&
              <Link to={`/integrations`} style={{textAlign: 'center', display: 'block', marginTop: '20px'}}>
                <Button type="primary" >
                  Click here to go to your Integrations
                </Button>
              </Link>
            }
          </>
        )}
      </div>
    </>
    
  );
};
