import {
  Alert,
  Button, Col, Spin,
  Form, message, Row, Checkbox 
} from 'antd';
import {useAuth} from "../services/authContext";
import {useEffect, useState} from "react";
import {useMutation, useQuery} from "react-query";
import {change_notification_settings_in_b2c, getProfileData_from_B2C} from "../services/authApi";
import { useLanguage } from '../../languages/Language';

export const UserNotificationsForm = () => {
  const auth = useAuth();
  const { getText } = useLanguage();
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [isEmailOpted, setIsEmailOpted] = useState<boolean>(false)
  const [isSMSOpted, setIsSMSOpted] = useState<boolean>(false)

  const fetchProfileFromB2C = useQuery(['fetchProfileFromB2C'], async () => {
    return getProfileData_from_B2C(auth.user.secret_key)
  },{cacheTime:0})

  const confirmNotificationSettings = useMutation(async () => {
    message.loading(getText('Saving information'));
    return change_notification_settings_in_b2c(auth.user.b2c_id, isSMSOpted, isEmailOpted, auth.user.secret_key)
  },  {
    onSuccess: (val) => {
      message.destroy();
      message.success(getText('Successfully updated'));
      fetchProfileFromB2C.refetch()
    },
    onError: (val:any) => {
      message.destroy();
      message.error(getText('Failed to update'));
      setErrorMessage(errorMessage => [...errorMessage,`Failed to update the preference.`])
    },
  });

  useEffect(() => {
    if(fetchProfileFromB2C.isFetched) {
      setIsEmailOpted(fetchProfileFromB2C.data?.send_notification_email!)
      setIsSMSOpted(fetchProfileFromB2C.data?.send_notification_sms!)
    }  
  }, [fetchProfileFromB2C.isFetched]) //eslint-disable-line react-hooks/exhaustive-deps
  
  if(fetchProfileFromB2C.isLoading || fetchProfileFromB2C.isFetching) {
    return <Spin style={{minHeight: '170px', display: 'flex', justifyContent: 'center', alignItems: 'center'}} />
  }

  return (
    <Form
      onFinish={(val) => {
        confirmNotificationSettings.mutate(val);
      }}
      layout={'vertical'}
      requiredMark={false}
    >
      <Form.Item label={'Shipment notifications'} className={"form-title form-left-title"}></Form.Item>
      <Form.Item label={<label className='notifications-page-label'>Please select the notification method to inform your customers about any shipment updates.</label>} className={"form-title form-left-title notifications-page"}></Form.Item>
      {confirmNotificationSettings.isError && <Alert
        className={"form-error"}
        message={errorMessage.map((item, index) => {
          return (
            <ul className="error-alert">
              <li>{index + 1}. {item}</li>
            </ul>
          )
        })}
        type="error"
        showIcon
      />}
      <Row gutter={14}>
        <Col span={12}>
          <Checkbox
            className='notifications-checkbox'
            checked={isEmailOpted}
            name={"email_enabled"}
            onChange={(e) => setIsEmailOpted(e.target.checked)}
          >
            Email
          </Checkbox>
          <br />
          <Checkbox
            className='notifications-checkbox'
            checked={isSMSOpted}
            name={"sms_enabled"}
            onChange={(e: any) => {
              setIsSMSOpted(e.target.checked)
            }}
          >
            SMS
          </Checkbox>
        </Col>
      </Row>
      <div className={"button-block"}>
        <Button htmlType="submit" style={{'position': 'absolute', 'right': 0, 'bottom': '-50px'}} type="primary" className={"blue-button"} loading={confirmNotificationSettings.isLoading}>
          {getText('Save')}
        </Button>
      </div>
    </Form>
  )
};
