import {Tooltip} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons'
import { useLanguage } from '../../languages/Language';
export const CeilSignificance = 0.5;
export const OrderWeightLimit = 30;
export const OrderWeightLimitForPostpaidCLients = 100;
export const validateMessages = {
  types: {
    email: 'Email address is not valid',
  },
};

export const paymentTypes = {
  prepaid: 'prepaid'
};

export const countries: any = {
  SG: "Singapore",
  ID: "Indonesia",
  MY: "Malaysia",
  TH: "Thailand",
  VN: "Vietnam",
  US: "US",
  PH: "Philippines",
  TW: "Taiwan",
  HK: "Hong Kong",
  AF: "Afghanistan",
  AX: "Aland Islands",
  AL: "Albania",
  DZ: "Algeria",
  AS: "American Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctica",
  AG: "Antigua And Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BS: "Bahamas",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Belarus",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia",
  BA: "Bosnia And Herzegovina",
  BW: "Botswana",
  BV: "Bouvet Island",
  BR: "Brazil",
  IO: "British Indian Ocean Territory",
  BN: "Brunei Darussalam",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Cambodia",
  CM: "Cameroon",
  CA: "Canada",
  CV: "Cape Verde",
  KY: "Cayman Islands",
  CF: "Central African Republic",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CX: "Christmas Island",
  CC: "Cocos (Keeling) Islands",
  CO: "Colombia",
  KM: "Comoros",
  CG: "Congo",
  CD: "Congo, Democratic Republic",
  CK: "Cook Islands",
  CR: "Costa Rica",
  CI: "Cote D\"Ivoire",
  HR: "Croatia",
  CU: "Cuba",
  CY: "Cyprus",
  CZ: "Czech Republic",
  DK: "Denmark",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominican Republic",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  GQ: "Equatorial Guinea",
  ER: "Eritrea",
  EE: "Estonia",
  ET: "Ethiopia",
  FK: "Falkland Islands (Malvinas)",
  FO: "Faroe Islands",
  FJ: "Fiji",
  FI: "Finland",
  FR: "France",
  GF: "French Guiana",
  PF: "French Polynesia",
  TF: "French Southern Territories",
  GA: "Gabon",
  GM: "Gambia",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Greece",
  GL: "Greenland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard Island & Mcdonald Islands",
  VA: "Holy See (Vatican City State)",
  HN: "Honduras",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  IR: "Iran, Islamic Republic Of",
  IQ: "Iraq",
  IE: "Ireland",
  IM: "Isle Of Man",
  IL: "Israel",
  IT: "Italy",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  KR: "South Korea",
  KP: "North Korea",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: "Lao People\"s Democratic Republic",
  LV: "Latvia",
  LB: "Lebanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libyan Arab Jamahiriya",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MO: "Macao",
  MK: "Macedonia",
  MG: "Madagascar",
  MW: "Malawi",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshall Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexico",
  FM: "Micronesia, Federated States Of",
  MD: "Moldova",
  MC: "Monaco",
  MN: "Mongolia",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Morocco",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Netherlands",
  AN: "Netherlands Antilles",
  NC: "New Caledonia",
  NZ: "New Zealand",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  MP: "Northern Mariana Islands",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestinian Territory, Occupied",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PN: "Pitcairn",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  RE: "Reunion",
  RO: "Romania",
  RU: "Russian Federation",
  RW: "Rwanda",
  BL: "Saint Barthelemy",
  SH: "Saint Helena",
  KN: "Saint Kitts And Nevis",
  LC: "Saint Lucia",
  MF: "Saint Martin",
  PM: "Saint Pierre And Miquelon",
  VC: "Saint Vincent And Grenadines",
  WS: "Samoa",
  SM: "San Marino",
  ST: "Sao Tome And Principe",
  SA: "Saudi Arabia",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SK: "Slovakia",
  SI: "Slovenia",
  SB: "Solomon Islands",
  SO: "Somalia",
  ZA: "South Africa",
  GS: "South Georgia And Sandwich Isl.",
  ES: "Spain",
  LK: "Sri Lanka",
  SD: "Sudan",
  SR: "Suriname",
  SJ: "Svalbard And Jan Mayen",
  SZ: "Swaziland",
  SE: "Sweden",
  CH: "Switzerland",
  SY: "Syrian Arab Republic",
  TJ: "Tajikistan",
  TZ: "Tanzania",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad And Tobago",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TC: "Turks And Caicos Islands",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  AE: "United Arab Emirates",
  GB: "UK",
  UM: "United States Outlying Islands",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VE: "Venezuela",
  VG: "Virgin Islands, British",
  VI: "Virgin Islands, U.S.",
  WF: "Wallis And Futuna",
  EH: "Western Sahara",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabwe"
};

export const billingSupportedCountries: any = {
  "Indonesia": "ID",
  "Singapore": "SG",
  "Malaysia": "MY",
  "Thailand": "TH",
  "Vietnam": "VN",
  "US": "US",
  "Philippines": "PH",
  "Taiwan": "TW",
  "Hong Kong": "HK",
}

export const countryCodes: any = {
  "Afghanistan": "AF",
  "Aland Islands": "AX",
  "Albania": "AL",
  "Algeria": "DZ",
  "American Samoa": "AS",
  "Andorra": "AD",
  "Angola": "AO",
  "Anguilla": "AI",
  "Antarctica": "AQ",
  "Antigua And Barbuda": "AG",
  "Argentina": "AR",
  "Armenia": "AM",
  "Aruba": "AW",
  "Australia": "AU",
  "Austria": "AT",
  "Azerbaijan": "AZ",
  "Bahamas": "BS",
  "Bahrain": "BH",
  "Bangladesh": "BD",
  "Barbados": "BB",
  "Belarus": "BY",
  "Belgium": "BE",
  "Belize": "BZ",
  "Benin": "BJ",
  "Bermuda": "BM",
  "Bhutan": "BT",
  "Bolivia": "BO",
  "Bosnia And Herzegovina": "BA",
  "Botswana": "BW",
  "Bouvet Island": "BV",
  "Brazil": "BR",
  "British Indian Ocean Territory": "IO",
  "Brunei Darussalam": "BN",
  "Bulgaria": "BG",
  "Burkina Faso": "BF",
  "Burundi": "BI",
  "Cambodia": "KH",
  "Cameroon": "CM",
  "Canada": "CA",
  "Cape Verde": "CV",
  "Cayman Islands": "KY",
  "Central African Republic": "CF",
  "Chad": "TD",
  "Chile": "CL",
  "China": "CN",
  "Christmas Island": "CX",
  "Cocos (Keeling) Islands": "CC",
  "Colombia": "CO",
  "Comoros": "KM",
  "Congo": "CG",
  "Congo, Democratic Republic": "CD",
  "Cook Islands": "CK",
  "Costa Rica": "CR",
  "Cote D'Ivoire": "CI",
  "Croatia": "HR",
  "Cuba": "CU",
  "Cyprus": "CY",
  "Czech Republic": "CZ",
  "Denmark": "DK",
  "Djibouti": "DJ",
  "Dominica": "DM",
  "Dominican Republic": "DO",
  "Ecuador": "EC",
  "Egypt": "EG",
  "El Salvador": "SV",
  "Equatorial Guinea": "GQ",
  "Eritrea": "ER",
  "Estonia": "EE",
  "Ethiopia": "ET",
  "Falkland Islands (Malvinas)": "FK",
  "Faroe Islands": "FO",
  "Fiji": "FJ",
  "Finland": "FI",
  "France": "FR",
  "French Guiana": "GF",
  "French Polynesia": "PF",
  "French Southern Territories": "TF",
  "Gabon": "GA",
  "Gambia": "GM",
  "Georgia": "GE",
  "Germany": "DE",
  "Ghana": "GH",
  "Gibraltar": "GI",
  "Greece": "GR",
  "Greenland": "GL",
  "Grenada": "GD",
  "Guadeloupe": "GP",
  "Guam": "GU",
  "Guatemala": "GT",
  "Guernsey": "GG",
  "Guinea": "GN",
  "Guinea-Bissau": "GW",
  "Guyana": "GY",
  "Haiti": "HT",
  "Heard Island & Mcdonald Islands": "HM",
  "Holy See (Vatican City State)": "VA",
  "Honduras": "HN",
  "Hong Kong": "HK",
  "Hungary": "HU",
  "Iceland": "IS",
  "India": "IN",
  "Indonesia": "ID",
  "Iran, Islamic Republic Of": "IR",
  "Iraq": "IQ",
  "Ireland": "IE",
  "Isle Of Man": "IM",
  "Israel": "IL",
  "Italy": "IT",
  "Jamaica": "JM",
  "Japan": "JP",
  "Jersey": "JE",
  "Jordan": "JO",
  "Kazakhstan": "KZ",
  "Kenya": "KE",
  "Kiribati": "KI",
  "South Korea": "KR",
  "Kuwait": "KW",
  "Kyrgyzstan": "KG",
  "Lao People's Democratic Republic": "LA",
  "Latvia": "LV",
  "Lebanon": "LB",
  "Lesotho": "LS",
  "Liberia": "LR",
  "Libyan Arab Jamahiriya": "LY",
  "Liechtenstein": "LI",
  "Lithuania": "LT",
  "Luxembourg": "LU",
  "Macao": "MO",
  "Macedonia": "MK",
  "Madagascar": "MG",
  "Malawi": "MW",
  "Malaysia": "MY",
  "Maldives": "MV",
  "Mali": "ML",
  "Malta": "MT",
  "Marshall Islands": "MH",
  "Martinique": "MQ",
  "Mauritania": "MR",
  "Mauritius": "MU",
  "Mayotte": "YT",
  "Mexico": "MX",
  "Micronesia, Federated States Of": "FM",
  "Moldova": "MD",
  "Monaco": "MC",
  "Mongolia": "MN",
  "Montenegro": "ME",
  "Montserrat": "MS",
  "Morocco": "MA",
  "Mozambique": "MZ",
  "Myanmar": "MM",
  "Namibia": "NA",
  "Nauru": "NR",
  "Nepal": "NP",
  "Netherlands": "NL",
  "Netherlands Antilles": "AN",
  "New Caledonia": "NC",
  "New Zealand": "NZ",
  "Nicaragua": "NI",
  "Niger": "NE",
  "Nigeria": "NG",
  "Niue": "NU",
  "Norfolk Island": "NF",
  "North Korea": "KP",
  "Northern Mariana Islands": "MP",
  "Norway": "NO",
  "Oman": "OM",
  "Pakistan": "PK",
  "Palau": "PW",
  "Palestinian Territory, Occupied": "PS",
  "Panama": "PA",
  "Papua New Guinea": "PG",
  "Paraguay": "PY",
  "Peru": "PE",
  "Philippines": "PH",
  "Pitcairn": "PN",
  "Poland": "PL",
  "Portugal": "PT",
  "Puerto Rico": "PR",
  "Qatar": "QA",
  "Reunion": "RE",
  "Romania": "RO",
  "Russian Federation": "RU",
  "Rwanda": "RW",
  "Saint Barthelemy": "BL",
  "Saint Helena": "SH",
  "Saint Kitts And Nevis": "KN",
  "Saint Lucia": "LC",
  "Saint Martin": "MF",
  "Saint Pierre And Miquelon": "PM",
  "Saint Vincent And Grenadines": "VC",
  "Samoa": "WS",
  "San Marino": "SM",
  "Sao Tome And Principe": "ST",
  "Saudi Arabia": "SA",
  "Senegal": "SN",
  "Serbia": "RS",
  "Seychelles": "SC",
  "Sierra Leone": "SL",
  "Singapore": "SG",
  "Slovakia": "SK",
  "Slovenia": "SI",
  "Solomon Islands": "SB",
  "Somalia": "SO",
  "South Africa": "ZA",
  "South Georgia And Sandwich Isl.": "GS",
  "Spain": "ES",
  "Sri Lanka": "LK",
  "Sudan": "SD",
  "Suriname": "SR",
  "Svalbard And Jan Mayen": "SJ",
  "Swaziland": "SZ",
  "Sweden": "SE",
  "Switzerland": "CH",
  "Syrian Arab Republic": "SY",
  "Taiwan": "TW",
  "Tajikistan": "TJ",
  "Tanzania": "TZ",
  "Thailand": "TH",
  "Timor-Leste": "TL",
  "Togo": "TG",
  "Tokelau": "TK",
  "Tonga": "TO",
  "Trinidad And Tobago": "TT",
  "Tunisia": "TN",
  "Turkey": "TR",
  "Turkmenistan": "TM",
  "Turks And Caicos Islands": "TC",
  "Tuvalu": "TV",
  "US": "US",
  "Uganda": "UG",
  "Ukraine": "UA",
  "United Arab Emirates": "AE",
  "UK": "GB",
  "United States Outlying Islands": "UM",
  "Uruguay": "UY",
  "Uzbekistan": "UZ",
  "Vanuatu": "VU",
  "Venezuela": "VE",
  "Vietnam": "VN",
  "Virgin Islands, British": "VG",
  "Virgin Islands, U.S.": "VI",
  "Wallis And Futuna": "WF",
  "Western Sahara": "EH",
  "Yemen": "YE",
  "Zambia": "ZM",
  "Zimbabwe": "ZW"
};

export const countryCallingCodes: any = {
  "AD": "+376",
  "AE": "+971",
  "AF": "+93",
  "AG": "+1-268",
  "AI": "+1-264",
  "AL": "+355",
  "AM": "+374",
  "AO": "+244",
  "AQ": "+",
  "AR": "+54",
  "AS": "+1-684",
  "AT": "+43",
  "AU": "+61",
  "AW": "+297",
  "AX": "+358-18",
  "AZ": "+994",
  "BA": "+387",
  "BB": "+1-246",
  "BD": "+880",
  "BE": "+32",
  "BF": "+226",
  "BG": "+359",
  "BH": "+973",
  "BI": "+257",
  "BJ": "+229",
  "BL": "+590",
  "BM": "+1-441",
  "BN": "+673",
  "BO": "+591",
  "BQ": "+599",
  "BR": "+55",
  "BS": "+1-242",
  "BT": "+975",
  "BV": "+",
  "BW": "+267",
  "BY": "+375",
  "BZ": "+501",
  "CA": "+1",
  "CC": "+61",
  "CD": "+243",
  "CF": "+236",
  "CG": "+242",
  "CH": "+41",
  "CI": "+225",
  "CK": "+682",
  "CL": "+56",
  "CM": "+237",
  "CN": "+86",
  "CO": "+57",
  "CR": "+506",
  "CU": "+53",
  "CV": "+238",
  "CW": "+599",
  "CX": "+61",
  "CY": "+357",
  "CZ": "+420",
  "DE": "+49",
  "DJ": "+253",
  "DK": "+45",
  "DM": "+1-767",
  "DO": "+1-809 and 1-829",
  "DZ": "+213",
  "EC": "+593",
  "EE": "+372",
  "EG": "+20",
  "EH": "+212",
  "ER": "+291",
  "ES": "+34",
  "ET": "+251",
  "FI": "+358",
  "FJ": "+679",
  "FK": "+500",
  "FM": "+691",
  "FO": "+298",
  "FR": "+33",
  "GA": "+241",
  "GB": "+44",
  "GD": "+1-473",
  "GE": "+995",
  "GF": "+594",
  "GG": "+44-1481",
  "GH": "+233",
  "GI": "+350",
  "GL": "+299",
  "GM": "+220",
  "GN": "+224",
  "GP": "+590",
  "GQ": "+240",
  "GR": "+30",
  "GS": "+",
  "GT": "+502",
  "GU": "+1-671",
  "GW": "+245",
  "GY": "+592",
  "HK": "+852",
  "HM": "+ ",
  "HN": "+504",
  "HR": "+385",
  "HT": "+509",
  "HU": "+36",
  "ID": "+62",
  "IE": "+353",
  "IL": "+972",
  "IM": "+44-1624",
  "IN": "+91",
  "IO": "+246",
  "IQ": "+964",
  "IR": "+98",
  "IS": "+354",
  "IT": "+39",
  "JE": "+44-1534",
  "JM": "+1-876",
  "JO": "+962",
  "JP": "+81",
  "KE": "+254",
  "KG": "+996",
  "KH": "+855",
  "KI": "+686",
  "KM": "+269",
  "KN": "+1-869",
  "KP": "+850",
  "KR": "+82",
  "KW": "+965",
  "KY": "+1-345",
  "KZ": "+7",
  "LA": "+856",
  "LB": "+961",
  "LC": "+1-758",
  "LI": "+423",
  "LK": "+94",
  "LR": "+231",
  "LS": "+266",
  "LT": "+370",
  "LU": "+352",
  "LV": "+371",
  "LY": "+218",
  "MA": "+212",
  "MC": "+377",
  "MD": "+373",
  "ME": "+382",
  "MF": "+590",
  "MG": "+261",
  "MH": "+692",
  "MK": "+389",
  "ML": "+223",
  "MM": "+95",
  "MN": "+976",
  "MO": "+853",
  "MP": "+1-670",
  "MQ": "+596",
  "MR": "+222",
  "MS": "+1-664",
  "MT": "+356",
  "MU": "+230",
  "MV": "+960",
  "MW": "+265",
  "MX": "+52",
  "MY": "+60",
  "MZ": "+258",
  "NA": "+264",
  "NC": "+687",
  "NE": "+227",
  "NF": "+672",
  "NG": "+234",
  "NI": "+505",
  "NL": "+31",
  "NO": "+47",
  "NP": "+977",
  "NR": "+674",
  "NU": "+683",
  "NZ": "+64",
  "OM": "+968",
  "PA": "+507",
  "PE": "+51",
  "PF": "+689",
  "PG": "+675",
  "PH": "+63",
  "PK": "+92",
  "PL": "+48",
  "PM": "+508",
  "PN": "+870",
  "PR": "+1-787 and 1-939",
  "PS": "+970",
  "PT": "+351",
  "PW": "+680",
  "PY": "+595",
  "QA": "+974",
  "RE": "+262",
  "RO": "+40",
  "RS": "+381",
  "RU": "+7",
  "RW": "+250",
  "SA": "+966",
  "SB": "+677",
  "SC": "+248",
  "SD": "+249",
  "SE": "+46",
  "SG": "+65",
  "SH": "+290",
  "SI": "+386",
  "SJ": "+47",
  "SK": "+421",
  "SL": "+232",
  "SM": "+378",
  "SN": "+221",
  "SO": "+252",
  "SR": "+597",
  "SS": "+211",
  "ST": "+239",
  "SV": "+503",
  "SX": "+599",
  "SY": "+963",
  "SZ": "+268",
  "TC": "+1-649",
  "TD": "+235",
  "TF": "+",
  "TG": "+228",
  "TH": "+66",
  "TJ": "+992",
  "TK": "+690",
  "TL": "+670",
  "TM": "+993",
  "TN": "+216",
  "TO": "+676",
  "TR": "+90",
  "TT": "+1-868",
  "TV": "+688",
  "TW": "+886",
  "TZ": "+255",
  "UA": "+380",
  "UG": "+256",
  "UM": "+1",
  "US": "+1",
  "UY": "+598",
  "UZ": "+998",
  "VA": "+379",
  "VC": "+1-784",
  "VE": "+58",
  "VG": "+1-284",
  "VI": "+1-340",
  "VN": "+84",
  "VU": "+678",
  "WF": "+681",
  "WS": "+685",
  "XK": "+",
  "YE": "+967",
  "YT": "+262",
  "ZA": "+27",
  "ZM": "+260",
  "ZW": "+263"
};

export const countryCurrencies = [
  "AFN","ALL","AMD","ANG","AOA","ARS","AWG","AZN","BAM","BBD","BDT","BGN","BHD","BIF","BMD","BND","BOB","BRL","BSD","BTN","BWP","BYN","BYR","BZD","CDF","CLF","CLP","COP","CRC","CUC","CUP","CVE","CZK","DJF","DKK","DOP","DZD","EGP","ERN","ETB","FJD","FKP","GEL","GHS","GIP","GMD","GNF","GTQ","GYD","HNL","HRK","HTG","HUF","ILS","IQD","IRR","ISK","JMD","JOD","KES","KGS","KHR","KMF","KPW","KWD","KYD","KZT","LAK","LBP","LKR","LRD","LSL","LYD","MAD","MDL","MGA","MKD","MMK","MNT","MOP","MRO","MUR","MVR","MWK","MXN","MXV","MZN","NAD","NGN","NIO","NOK","NPR","OMR","PAB","PEN","PGK","PKR","PLN","PYG","QAR","RON","RSD","RUB","RWF","SBD","SCR","SDG","SEK","SHP","SLL","SOS","SRD","SSP","STD","SVC","SYP","SZL","TJS","TMT","TND","TOP","TRY","TTD","TZS","UAH","UGX","UYI","UYU","UZS","VEF","VUV","WST","XAF","XCD","XOF","XPF","XXX","YER","ZAR","ZMW","ZWL"
];

export const mostUsedCurrencies = ["SGD", "IDR", "MYR", "THB", "VND", "USD", "PHP", "TWD", "HKD", "AUD", "AED","CHF","SAR","QAR","NZD","KRW","JPY","INR","CNY","CAD","GBP","EUR"
]

export const countryCodeCurrencies: any = {
  "SG" : "SGD",
  "ID" : "IDR",
  "MY" : "MYR",
  "TH" : "THB",
  "VN" : "VND",
  "US" : "USD",
  "PH" : "PHP",
  "TW" : "TWD",
  "HK" : "HKD",
  "AU" : "AUD"
}

export const estimatedDays: any = {
  SGSG : '1-3 working days',
  SGID : '5-7 working days',
  SGMY : '4-8 working days',
  SGTH : '3-6 working days',
  SGVN : '3-7 working days',
  SGUS : '4-8 working days',
  SGPH : '7-11 working days',
  SGTW : '3-6 working days',
  SGHK : '3-6 working days',
  SGAU : '10-20 working days',
  SGCH : '3-6 working days',
  IDSG : '4-6 working days',
  IDID : '2-7 working days',
  IDMY : '5-11 working days',
  IDTH : '4-7 working days',
  IDVN : '6-13 working days',
  IDUS : '5-11 working days',
  IDPH : '5-15 working days',
  IDTW : '4-9 working days',
  IDHK : '3-6 working days',
  IDAU : '10-20 working days',
  IDCH : '4-9 working days',
  MYSG : '5-9 working days',
  MYID : '5-10 working days',
  MYPH : '4-14 working days',
  MYTH : '4-8 working days',
  MYHK : '5-9 working days',
  MYVN : '6-10 working days',
  MYUS : '4-11 working days',
  MYTW : '4-9 working days',
  MYMY : '4-7 working days',
  MYAU : '5-16 working days',
  THUS : '8-15 working days',
  THSG : '5-10 working days',
  THAU : '10-15 working days'
};


export const itemCategories = [
  "Accessory (no-battery)",
  "Accessory (with battery)",
  "Audio Video",
  "Bags & Luggages",
  "Board Games",
  "Books & Collectibles",
  "Cameras",
  "Computers & Laptops",
  "Documents",
  "Dry Food & Supplements",
  "Fashion",
  "Gaming",
  "Health & Beauty",
  "Home Appliances",
  "Home Decor",
  "Jewelry",
  "Mobile Phones",
  "Pet Accessory",
  "Shoes",
  "Sport & Leisure",
  "Tablets",
  "Toys",
  "Watches"
];

export const ItemCategoriesFromB2C = [
  "Fashion Accessories",
  "Fashion Footwear",
  "Fashion Apparels (Men)",
  "Fashion Apparels (Women)",
  "Fashion Apparels (Babies, Toddlers and Children)",
  "Fashion Apparel",
  // "Electronics",
  "Electronics (Non-Telecommunications)",
  "Electronics (Telecommunications)",
  "Others",
  "Printed Matters",
  "Lifestyle Products",
  "Lifestyle (Health Related)",
  "Lifestyle (Beauty Related)",
  "Lifestyle (Home & Living)",
  "Lifestyle (Hobbies & Collection)",
  "Lifestyle (Pantry & Packaged Food & Beverages)",
  // "Covid 19 tax-exempted items (Approval not required)",
  // "Covid 19 tax-exempted items (Approval required)",
  "Fashion Bags"
]

export const TextMap = {
  ErrorHeading: 'This field may not be blank. Please make the necessary changes at Line ',
  SGPostalError: 'Singapore postal code should be 4 or 6 digits.',
  IDPostalError: 'Indonesia postal code should be 5 digits.',
  CountryPostalError: 'Country postal code should be of 5 digits.',
  HKPostalError: 'Country postal code should be of 6 digits.',
  PHPostalError: 'Country postal code should be of 4 digits.',
  InvalidEmail: 'Invalid email. Please make the necessary changes at Line ',
  InvalidLane: 'Service is not available at given countries, Please make the necessary changes at Line ',
  InvalidAddress: 'Address can not be only numbers. Please make the necessary changes at Line ',
  FileTypesAccepted: 'You can only upload .xls / .xlsx / .csv file!',
  WeightLimitExceededBulk: 'Exceeded volumetric weight. Please make the necessary changes at Line ',
  JPPOstalError: 'Japan postal code should be in XXX-XXXX format and must be number',
  NotANumberError: 'Postal code must be a number',
  TWPostalError: 'Country postal code should be of 3, 5 or 6 digits.',
  VNPostalError: 'Country postal code should be of 5 or 6 digits.',
};

export const contactCustomerService = () => {
  return <a href={'mailto:clientsupport@janio.asia'} style={{color: '#050593'}}>clientsupport@janio.asia</a>
}

const priceBreakUp = (data: any) => {
  let total_duties = data.total_duties ? parseFloat(data.total_duties) : 0
  let total_taxes = data.total_taxes ? parseFloat(data.total_taxes) : 0
  let gst_amount = data.gst_amount ? parseFloat(data.gst_amount) : 0
  let pickup_fee = data.pickup_charge ? data.pickup_charge : 0

  let taxes = total_duties + total_taxes
  let shipping_charges = (parseFloat(data.total_price) - taxes) - gst_amount
  return (
    <div className={'amount-breakup'}>
      <p>Shipping Rate: <span>{shipping_charges.toFixed(2)} {data.currency }</span></p>
      <p>Duties: <span>{total_duties} {data.currency } </span></p>
      <p>Taxes: <span>{total_taxes} {data.currency } </span></p>
      <p>GST: <span>{gst_amount} {data.currency } </span></p>
      <p>Pickup Fee: <span>{pickup_fee.toFixed(2)} {data.currency } </span></p>
      <p>Total Amount: <span>{data.price_estimate}</span></p>
    </div>
  )
}

export const PriceTableColumns: any = () => {
  let { getText } = useLanguage();
  return [
    {
      title: getText('Row'),
      dataIndex: 'index',
      key: 1,
      width: 70
    },
    {
      title: getText('Consignee Name'),
      dataIndex: 'consignee_name',
      key: 2,
    },
    {
      title: getText('Item Qty'),
      dataIndex: 'item_quantity',
      key: 3,
    },
    {
      title: getText('Item Description'),
      dataIndex: 'item_description',
      key: 4,
    },
    {
      title: getText('Weight (kg)'),
      dataIndex: 'weight',
      key: 5,
    },
    {
      title: getText('Dimension'),
      dataIndex: 'dimension',
      key: 6,
    },
    {
      title: 'Incoterm',
      dataIndex: 'incoterm',
      key: 7,
    },
    {
      title: 'COD Amount',
      dataIndex: 'cod_amount',
      key: 8,
    },
    {
      title: getText('Price estimate'),
      dataIndex: 'price_estimate',
      key: 9,
      fixed: 'right',
      width: 150,
      render: (price_estimate: any, allData:any) => {
        return <label className="info-disclaimer">{price_estimate}<Tooltip color={"#fff"} title={priceBreakUp(allData)}><InfoCircleOutlined className={'info-price-break'}/></Tooltip></label>
      }
    }
  ];
}


export const RegistrationPurpose: Array<string> = ['Personal','Business']

export const GoodsListSheetIds:any = {
  SG : 656365738,
  ID : 649434099,
  MY : 761681417,
  US : 1316987786,
  VN : 1009850045,
  PH : 805150426,
  AU : 1788377411,
  TH : 7285386,
  JP : 1519024736,
  TW : 248435466,
  HK : 1371660821
};

export const IndonesiaIdentificationTypes: any = {
  "LOCALTAXID": "Local Tax ID (NPWP)",
  "KTP": "Kartu Tanda Penduduk (KTP)",
  "PASSPORT": "Passport Number",
  "LICENSE": "Indonesia's Driver's Licence"
}

export const SouthKoreaIdentificationTypes: any = {
  "PCCC": "Personal Custom Clearance Code"
}

export const ShippingCharges: any = {
  'OT': {
    'Singapore':'SGD 4.00',
    'Malaysia':'SGD 3.70',
    'Indonesia':'SGD 5.60',
    'US':'SGD 26.90'
  },
  'ID': {
    'Singapore':'IDR 71400',
    'Malaysia':'IDR 69000',
    'Indonesia':'IDR 7400',
    'US':'IDR 316600'
  },
  'TH': {
    'Singapore':'THB 189.20',
    'Malaysia':'THB 58.10',
    'Indonesia':'THB 232.90',
    'US':'THB 768.70'
  },
  'MY': {
    'Singapore':'MYR 23.10',
    'Malaysia':'MYR 6.10',
    'Indonesia':'MYR 28.40',
    'US':'MYR 93.60'
  }
}

// production URL by default
export let SS_API_URL = "https://ss.janio.asia/";
export let MERCHANT_API_URL = "https://api.janio.asia/";

export let DROP_OFF_SERVICE_SUPPORT_EMAIL = "support@parknparcel.com.sg";

const url = window.location.href;
if (url.includes("staging")) {
  // staging url
  SS_API_URL = "https://ss.staging.janio.asia/"
  MERCHANT_API_URL = "https://api.staging.janio.asia/"
} else if (url.includes("localhost")) {
  // localhost url
  SS_API_URL = "https://ss.staging.janio.asia/"
  MERCHANT_API_URL = "https://api.staging.janio.asia/"
}

export const OrderStatusList = {
  ORDER_CREATED: 'ORDER_CREATED',
  PENDING_PICKUP: 'PENDING_PICKUP',
  FAILED_PICKUP: 'FAILED_PICKUP',
  READY_FOR_COLLECTION: 'READY_FOR_COLLECTION',
  IN_TRANSIT_WITHIN_ORIGIN_COUNTRY: 'IN_TRANSIT_WITHIN_ORIGIN_COUNTRY',
  CROSS_BORDER_TRANSIT: 'CROSS_BORDER_TRANSIT',
  IN_TRANSIT_WITHIN_DESTINATION_COUNTRY: 'IN_TRANSIT_WITHIN_DESTINATION_COUNTRY',
  DESTINATION_COUNTRY_CUSTOMS: 'DESTINATION_COUNTRY_CUSTOMS',
  DELIVERY_IN_PROGRESS: 'DELIVERY_IN_PROGRESS',
  FAILED_DELIVERY: 'FAILED_DELIVERY',
  RETURN_TO_SENDER: 'RETURN_TO_SENDER',
  EXCEPTION: 'EXCEPTION'
}

export const OrderStatusListForAllStatus = {
  ORDER_CREATED: 'ORDER_CREATED',
  PENDING_PICKUP: 'PENDING_PICKUP',
  FAILED_PICKUP: 'FAILED_PICKUP',
  READY_FOR_COLLECTION: 'READY_FOR_COLLECTION',
  IN_TRANSIT_WITHIN_ORIGIN_COUNTRY: 'IN_TRANSIT_WITHIN_ORIGIN_COUNTRY',
  CROSS_BORDER_TRANSIT: 'CROSS_BORDER_TRANSIT',
  IN_TRANSIT_WITHIN_DESTINATION_COUNTRY: 'IN_TRANSIT_WITHIN_DESTINATION_COUNTRY',
  DESTINATION_COUNTRY_CUSTOMS: 'DESTINATION_COUNTRY_CUSTOMS',
  DELIVERY_IN_PROGRESS: 'DELIVERY_IN_PROGRESS',
  FAILED_DELIVERY: 'FAILED_DELIVERY',
  RETURN_TO_SENDER: 'RETURN_TO_SENDER',
  EXCEPTION: 'EXCEPTION',
  DELIVERY_COMPLETED: 'DELIVERY_COMPLETED',
  CANCELLED: 'CANCELLED'
}

export const OrderStatusListForFreight = {
  'Created': 'Created',
  'Freight Processing At Freight Forwarder': 'Freight Processing At Freight Forwarder',
  'Freight Uplift Complete': 'Freight Uplift Complete',
  'Freight Arrived Complete': 'Freight Arrived Complete',
  'Delivered': 'Delivered',
  'Cancelled': 'Cancelled',
}

export const ServiceTypeList = {
  'pickup': 'Pickup',
  'dropoff': 'Dropoff',
  'customs clearance and last mile':'Customs Clearance and Last Mile',
}

export const MarvableServiceTypeList = {
  'DELIVERY_CHAIR': 'Delivery (Chair)',
  'DELIVERY_AND_ASSEMBLY_CHAIR': 'Delivery and Assembly (Chair)',
  'ASSEMBLY_CHAIR': 'Assembly (Chair)',
  'EXCHANGE_DEL_AND_ASM_CHAIR': 'Exchange Delivery and Assembly (Chair)',
  'RETURNS_CHAIR': 'Returns (Chair)',
  'EXCHANGE_DELIVERY_CHAIR': 'Exchange Delivery (Chair)',
}

export const PickupExpressServiceTypeList = {
  'pickup express': 'Pickup Express',
  // 'dropoff express': 'Dropoff Express',
}

export const PickupExpressPriorityServiceTypeList = {
  'Pickup Express Priority': 'Pickup Express Priority',
  // 'Dropoff Express Priority': 'Dropoff Express Priority',
}

export const domesticServices = {
  FIRSTMILE_ONLY: 'First Mile Only (pickup/dropoff)',
  FIRSTMILE_DELIVERY: 'First Mile (pickup/dropoff) + Delivery',
  RETURNS_CHAIRS: 'Returns (Chair)'
}

export const crossBorderServices = {
  FIRSTMILE_AIRFREIGHT: 'First Mile (pickup/dropoff) + Airfreight',
  FIRSTMILE_CUSTOMS_LASTMILE: 'First Mile (pickup/dropoff) + Airfreight + Customs + Lastmile'
}

export const serviceTypes = {
  PICKUP_FROM_CUSTOMER: "Pickup from customer's address",
  DROPOFF: 'Dropoff',
  LETS_CUSTOMER_DECIDE: 'Let customer decide (pickup/dropoff)',

}

export const domesticServiceEnums = {
  PICKUP: 'PICKUP',
  PICKUP_NO_CONSO: 'PICKUP_NO_CONSO',
  DROPOFF: 'DROPOFF',
  DROPOFF_NO_CONSO: 'DROPOFF_NO_CONSO',
  SHOPPERCHOICE: 'SHOPPERCHOICE',
  SHOPPERCHOICE_NO_CONSO: 'SHOPPERCHOICE_NO_CONSO',
  PICKUP_DELIVERY: 'PICKUP_LM',
  PICKUP_DELIVERY_NO_CONSO: 'PICKUP_LM_NO_CONSO',
  DROPOFF_DELIVERY: 'DROPOFF_LM',
  DROPOFF_DELIVERY_NO_CONSO: 'DROPOFF_LM_NO_CONSO',
  SHOPPERCHOICE_DELIVERY: 'SHOPPERCHOICE_LM',
  SHOPPERCHOICE_DELIVERY_NO_CONSO: 'SHOPPERCHOICE_LM_NO_CONSO',
  RETURNS_CHAIR: 'RETURNS_CHAIR',
}
const crossBorderServiceEnums =  {
  PICKUP_AIRFREIGHT: 'PICKUP_AF',
  PICKUP_AIRFREIGHT_NO_CONSO: 'PICKUP_AF_NO_CONSO',
  DROPOFF_AIRFREIGHT: 'DROPOFF_AF',
  DROPOFF_AIRFREIGHT_NO_CONSO: 'DROPOFF_AF_NO_CONSO',
  SHOPPERCHOICE_AIRFREIGHT: 'SHOPPERCHOICE_AF',
  SHOPPERCHOICE_AIRFREIGHT_NO_CONSO: 'SHOPPERCHOICE_AF_NO_CONSO',
  PICKUP_AIRFREIGHT_CUSTOMS_DELIVERY: 'PICKUP_AF_CC_LM',
  PICKUP_AIRFREIGHT_CUSTOMS_DELIVERY_NO_CONSO: 'PICKUP_AF_CC_LM_NO_CONSO',
  DROPOFF_AIRFREIGHT_CUSTOMS_DELIVERY: 'DROPOFF_AF_CC_LM',
  DROPOFF_AIRFREIGHT_CUSTOMS_DELIVERY_NO_CONSO: 'DROPOFF_AF_CC_LM_NO_CONSO',
  SHOPPERCHOICE_AIRFREIGHT_CUSTOMS_DELIVERY: 'SHOPPERCHOICE_AF_CC_LM',
  SHOPPERCHOICE_AIRFREIGHT_CUSTOMS_DELIVERY_NO_CONSO: 'SHOPPERCHOICE_AF_CC_LM_NO_CONSO'
}

export const serviceDefinitions = {
  [domesticServiceEnums.PICKUP]: {
    service: domesticServices.FIRSTMILE_ONLY,
    serviceType: serviceTypes.PICKUP_FROM_CUSTOMER
  },
  [domesticServiceEnums.PICKUP_NO_CONSO]: {
    service: domesticServices.FIRSTMILE_ONLY,
    serviceType: serviceTypes.PICKUP_FROM_CUSTOMER
  },
  [domesticServiceEnums.DROPOFF]: {
    service: domesticServices.FIRSTMILE_ONLY,
    serviceType: serviceTypes.DROPOFF
  },
  [domesticServiceEnums.DROPOFF_NO_CONSO]: {
    service: domesticServices.FIRSTMILE_ONLY,
    serviceType: serviceTypes.DROPOFF
  },
  [domesticServiceEnums.SHOPPERCHOICE]: {
    service: domesticServices.FIRSTMILE_ONLY,
    serviceType: serviceTypes.LETS_CUSTOMER_DECIDE
  },
  [domesticServiceEnums.SHOPPERCHOICE_NO_CONSO]: {
    service: domesticServices.FIRSTMILE_ONLY,
    serviceType: serviceTypes.LETS_CUSTOMER_DECIDE
  },
  // ===
  [domesticServiceEnums.PICKUP_DELIVERY]: {
    service: domesticServices.FIRSTMILE_DELIVERY,
    serviceType: serviceTypes.PICKUP_FROM_CUSTOMER
  },
  [domesticServiceEnums.PICKUP_DELIVERY_NO_CONSO]: {
    service: domesticServices.FIRSTMILE_DELIVERY,
    serviceType: serviceTypes.PICKUP_FROM_CUSTOMER
  },
  [domesticServiceEnums.DROPOFF_DELIVERY]: {
    service: domesticServices.FIRSTMILE_DELIVERY,
    serviceType: serviceTypes.DROPOFF
  },
  [domesticServiceEnums.DROPOFF_DELIVERY_NO_CONSO]: {
    service: domesticServices.FIRSTMILE_DELIVERY,
    serviceType: serviceTypes.DROPOFF
  },
  [domesticServiceEnums.SHOPPERCHOICE_DELIVERY]: {
    service: domesticServices.FIRSTMILE_DELIVERY,
    serviceType: serviceTypes.LETS_CUSTOMER_DECIDE
  },
  [domesticServiceEnums.SHOPPERCHOICE_DELIVERY_NO_CONSO]: {
    service: domesticServices.FIRSTMILE_DELIVERY,
    serviceType: serviceTypes.LETS_CUSTOMER_DECIDE
  },
  [domesticServiceEnums.RETURNS_CHAIR]: {
    service: domesticServices.RETURNS_CHAIRS,
    serviceType: serviceTypes.PICKUP_FROM_CUSTOMER
  },
  // ===
  [crossBorderServiceEnums.PICKUP_AIRFREIGHT]: {
    service: crossBorderServices.FIRSTMILE_AIRFREIGHT,
    serviceType: serviceTypes.PICKUP_FROM_CUSTOMER,
  },
  [crossBorderServiceEnums.PICKUP_AIRFREIGHT_NO_CONSO]: {
    service: crossBorderServices.FIRSTMILE_AIRFREIGHT,
    serviceType: serviceTypes.PICKUP_FROM_CUSTOMER,
  },
  [crossBorderServiceEnums.DROPOFF_AIRFREIGHT]: {
    service: crossBorderServices.FIRSTMILE_AIRFREIGHT,
    serviceType: serviceTypes.DROPOFF,
  },
  [crossBorderServiceEnums.DROPOFF_AIRFREIGHT_NO_CONSO]: {
    service: crossBorderServices.FIRSTMILE_AIRFREIGHT,
    serviceType: serviceTypes.DROPOFF,
  },
  [crossBorderServiceEnums.SHOPPERCHOICE_AIRFREIGHT]: {
    service: crossBorderServices.FIRSTMILE_AIRFREIGHT,
    serviceType: serviceTypes.LETS_CUSTOMER_DECIDE,
  },
  [crossBorderServiceEnums.SHOPPERCHOICE_AIRFREIGHT_NO_CONSO]: {
    service: crossBorderServices.FIRSTMILE_AIRFREIGHT,
    serviceType: serviceTypes.LETS_CUSTOMER_DECIDE,
  },
  // ===
  [crossBorderServiceEnums.PICKUP_AIRFREIGHT_CUSTOMS_DELIVERY]: {
    service: crossBorderServices.FIRSTMILE_CUSTOMS_LASTMILE,
    serviceType: serviceTypes.PICKUP_FROM_CUSTOMER,
  },
  [crossBorderServiceEnums.PICKUP_AIRFREIGHT_CUSTOMS_DELIVERY_NO_CONSO]: {
    service: crossBorderServices.FIRSTMILE_CUSTOMS_LASTMILE,
    serviceType: serviceTypes.PICKUP_FROM_CUSTOMER,
  },
  [crossBorderServiceEnums.DROPOFF_AIRFREIGHT_CUSTOMS_DELIVERY]: {
    service: crossBorderServices.FIRSTMILE_CUSTOMS_LASTMILE,
    serviceType: serviceTypes.DROPOFF,
  },
  [crossBorderServiceEnums.DROPOFF_AIRFREIGHT_CUSTOMS_DELIVERY_NO_CONSO]: {
    service: crossBorderServices.FIRSTMILE_CUSTOMS_LASTMILE,
    serviceType: serviceTypes.DROPOFF,
  },
  [crossBorderServiceEnums.SHOPPERCHOICE_AIRFREIGHT_CUSTOMS_DELIVERY]: {
    service: crossBorderServices.FIRSTMILE_CUSTOMS_LASTMILE,
    serviceType: serviceTypes.LETS_CUSTOMER_DECIDE,
  },
  [crossBorderServiceEnums.SHOPPERCHOICE_AIRFREIGHT_CUSTOMS_DELIVERY_NO_CONSO]: {
    service: crossBorderServices.FIRSTMILE_CUSTOMS_LASTMILE,
    serviceType: serviceTypes.LETS_CUSTOMER_DECIDE,
  },
}
export const ShopifyAccountsToken = [
  'abf1c0a4dcd5e6f1e4c79fcf1cbb5abf1b6a60d1',
  '5fce9e25e9573ce8ab3c46f066ddf1116d0875e9',
  'e25ed187de58ad1f1dba02c261525577fd4bd877',
  '393f54df6ba31a8d74a7be9a2b87c3a759ab94bd',
  'e95a0a30a4bd390423bbeae6bfc2c2773bfe6daf',
  '36f2a4c642b716cd0a8b01f397d9297337108e1d',
  '21267057288efda536ab64bea56c7157e728bfe2',
  '8e70662202bc76ea85f8341ebb77f71bb0cac47d',
  'dbf27f6c6dc7678a02e7c6555c1d3787b6ce26aa',
  '446d301739a549ddae42a6e3aba0ad11239dc6c0',
  'b817a5bc453acb056658bfb62f2e62fa4d85fe56',
  '4724b6165b07e502cbb049e2f1da2a973462daa9',
  '3c1fc94a32d53caba7d648d3ea50a498ee0ba163',
  'e37802df779ea743dc91414b888ae0baf179264f',
  '07b9b217290ac646aa31b66ab70119539c7c6c4f',
  'd30522ae6350621e03df561e4f432d9e56f82b07',
  'ce25adbf817bbee7057369e94ed91683d5808f93',
  'c9d6e47f9513a1910495de055b52e8b564cb22e7',
  '9ad6655589958737bc63dbb04ecc440721f1ddb2',
  'e338b185c4018ee60db449dddd0286b4f80df078', //ashish.raghav@janio.asia
  '96c036484f2ec11ae03cc135547f14fe14e9d647', //florancejustina1998@gmail.com,
  'b5b036e2e5bd2f82016451e20966886087c480f6', //christoper.darmawan+2@janio.asia,
  '1cb782794cf92615036f24fd1fe1203c37324c7f', //ashish.raghav@janio.asia staging,
  'eb674766c7d4673018c4a4c939ac9aa3547fa08a', //christoper.darmawan+1@janio.asia staging
]

export const dispositionOptions: any = {
  'Resume order pickup' : 'Resume parcel pickup',
  'Cancel Order' : 'Cancel Order',
  'Redeliver Order' : 'Redeliver the parcel to new address',
  'Return to original address' : 'Return the parcel to original address',
  'Dispose Order' : 'Dispose the parcel',
  'Return to new address' : 'Return the parcel to new address'
}
export const marvableB2cId = ['8566', '3521', '6274', '8090']
export const pickupExpressB2cId = ['7352', '8558', '3521', '6274', '8090', '8583']
export const pickupExpressPriorityB2cId = ['8558', '1337', '3521', '6274', '8090', '8583']
export const deliveryTimeSlotB2cIdStaging = ['3521']
export const deliveryTimeSlotB2cId = ['6274', '8623']

export const claimsOptions: any = {
  'missing_orders': 'Missing Parcels',
  'damaged_orders': 'Damaged Parcels',
}

export const claimFiltersOptions: any = {
  'missing_orders': 'Missing Parcels',
  'damaged_orders': 'Damaged Parcels',
  'weight_dispute': 'Weight Dispute (Parcel)',
}

export const damagedParcelFileDropdownOptions: any = {
  'undamaged_items_before_shipment_photos': 'Undamaged Items before shipment',
  'items_packged_in_protective_material_photos': 'Item packed in protective material',
  'damaged_outer_package_box_photos': 'Damaged/torn outer package box',
  'damaged_items_inside_parcel_photos': 'Damaged item inside the box',
}

export const claimStatuses: any = {
  submitted: 'Submitted',
  in_review: 'In Review',
  start_investigation: 'Start Investigation',
  end_investigation: 'End Investigation',
  approved: 'Approved',
  rejected: 'Rejected',
  escalated: 'Escalated',
};

export const claimUsers: any = {
  client: 'Client',
  system: 'System',
  internal_janio: 'Internal Janio',
};

export const claimsMissingOrderOptions: any = {
  'partial_parcel_lost': 'Partial Parcel Lost',
  'disputed_successful_delivery': 'Disputed Successful Delivery',
  'handover_dispute': 'Handover Dispute',
}

export const claimsDamagedOrderOptions: any = {
  'full_parcel_damage': 'Full Parcel Damage',
  'partial_parcel_damage': 'Partial Parcel Damage',
}

export const extension: any = {
  'image/png': 'png',
  'text/plain': 'txt',
  'application/msword': 'doc',
  'application/pdf': 'pdf'
}

export const deliveryTimeSlots: any = {
  '10am-2pm SGT (UTC+8)' : '10:00+08:00-14:00+08:00',
  '2pm-6pm SGT (UTC+8)' : '14:00+08:00-18:00+08:00',
  '6pm-10pm SGT (UTC+8)' : '18:00+08:00-22:00+08:00',
}

export const pickupTimeSlots: any = {
  '8am-7pm SGT (UTC+8)' : '08:00+08:00-19:00+08:00'
}

export const existingServices: any = ['Pickup', 'Pickup Express', 'Pickup Express Priority', 'DELIVERY_CHAIR', 'DELIVERY_AND_ASSEMBLY_CHAIR', 'ASSEMBLY_CHAIR', 'EXCHANGE_DELIVERY_CHAIR', 'EXCHANGE_DEL_AND_ASM_CHAIR', 'RETURNS_CHAIR', 'Dropoff', 'Dropoff Express', 'Dropoff Express Priority', 'Customs clearance and last mile']