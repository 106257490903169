import { Col, Table } from 'antd';

const DummyData = {
  "count": 472,
  "next": "http://api.staging.janio.asia/api/order/order/?date_from=2023-11-21T00%3A00%3A00%2B05%3A30&date_to=2024-02-21T23%3A59%3A59%2B05%3A30&page=2&page_size=10&secret_key=FbTx2t4eXSQYYeTPO4icbj33Rxq7SkPz",
  "previous": null,
  "results": [
    {
      "order_id": 183312,
      "status": "Created",
      "confirmed_quotation": "N/A",
      "client_name": "1661. Inc (Demo)",
      "order_creation_date": "2024-03-14",
      "origin_ountry": "Singapore",
      "destination_country": "Singapore",
      "mawb": null,
      "shipment_consolidation": null,
      "hawb": null,
      "package_type": "Pallets",
      "no_of_units": 55,
      "gross_weight": "43kg",
      "chargeable_weight": "45kg",
      "creator_email": "demo-account@janio.asia",
      "remark": "No Remark"
    },
    {
      "order_id": 183118,
      "status": "Freight Uplift Complete",
      "confirmed_quotation": "N/A",
      "client_name": "1661. Inc (Demo)",
      "order_creation_date": "2024-03-10",
      "origin_ountry": "Singapore",
      "destination_country": "Hong Kong",
      "mawb": null,
      "shipment_consolidation": null,
      "hawb": null,
      "package_type": "Pallets",
      "no_of_units": 10,
      "gross_weight": "24kg",
      "chargeable_weight": "28kg",
      "creator_email": "demo-account@janio.asia",
      "remark": "No Remark"
    },
    {
      "order_id": 183073,
      "status": "Freight Arrived Complete",
      "confirmed_quotation": "N/A",
      "client_name": "1661. Inc (Demo)",
      "order_creation_date": "2024-03-05",
      "origin_ountry": "Malaysia",
      "destination_country": "Singapore",
      "mawb": null,
      "shipment_consolidation": null,
      "hawb": null,
      "package_type": "Boxes",
      "no_of_units": 8,
      "gross_weight": "12kg",
      "chargeable_weight": "15kg",
      "creator_email": "demo-account@janio.asia",
      "remark": "No Remark"
    },
    {
      "order_id": 182911,
      "status": "Created",
      "confirmed_quotation": "N/A",
      "client_name": "1661. Inc (Demo)",
      "order_creation_date": "2024-03-02",
      "origin_ountry": "Malaysia",
      "destination_country": "Indonesia",
      "mawb": null,
      "shipment_consolidation": null,
      "hawb": null,
      "package_type": "Pallets",
      "no_of_units": 45,
      "gross_weight": "97kg",
      "chargeable_weight": "101kg",
      "creator_email": "demo-account@janio.asia",
      "remark": "No Remark"
    },
    {
      "order_id": 182845,
      "status": "Delivered",
      "confirmed_quotation": "N/A",
      "client_name": "1661. Inc (Demo)",
      "order_creation_date": "2024-02-25",
      "origin_ountry": "Indonesia",
      "destination_country": "Hong Kong",
      "mawb": null,
      "shipment_consolidation": null,
      "hawb": null,
      "package_type": "Boxes",
      "no_of_units": 16,
      "gross_weight": "32kg",
      "chargeable_weight": "389g",
      "creator_email": "demo-account@janio.asia",
      "remark": "No Remark"
    },
    {
      "order_id": 182868,
      "status": "Delivered",
      "confirmed_quotation": "N/A",
      "client_name": "1661. Inc (Demo)",
      "order_creation_date": "2024-02-22",
      "origin_ountry": "Indonesia",
      "destination_country": "Singapore",
      "mawb": null,
      "shipment_consolidation": null,
      "hawb": null,
      "package_type": "Boxes",
      "no_of_units": 18,
      "gross_weight": "32kg",
      "chargeable_weight": "34kg",
      "creator_email": "demo-account@janio.asia",
      "remark": "No Remark"
    },
    {
      "order_id": 182864,
      "status": "Created",
      "confirmed_quotation": "N/A",
      "client_name": "1661. Inc (Demo)",
      "order_creation_date": "2024-02-22",
      "origin_ountry": "Singapore",
      "destination_country": "Singapore",
      "mawb": null,
      "shipment_consolidation": null,
      "hawb": null,
      "package_type": "Boxes",
      "no_of_units": 55,
      "gross_weight": "43kg",
      "chargeable_weight": "45kg",
      "creator_email": "demo-account@janio.asia",
      "remark": "No Remark"
    },
    {
      "order_id": 182852,
      "status": "Freight Uplift Complete",
      "confirmed_quotation": "N/A",
      "client_name": "1661. Inc (Demo)",
      "order_creation_date": "2024-02-20",
      "origin_ountry": "Hong Kong",
      "destination_country": "Malaysia",
      "mawb": null,
      "shipment_consolidation": null,
      "hawb": null,
      "package_type": "Pallets",
      "no_of_units": 41,
      "gross_weight": "77kg",
      "chargeable_weight": "80kg",
      "creator_email": "demo-account@janio.asia",
      "remark": "No Remark"
    },
    {
      "order_id": 182830,
      "status": "Freight Arrived Complete",
      "confirmed_quotation": "N/A",
      "client_name": "1661. Inc (Demo)",
      "order_creation_date": "2024-02-12",
      "origin_ountry": "Malaysia",
      "destination_country": "Singapore",
      "mawb": null,
      "shipment_consolidation": null,
      "hawb": null,
      "package_type": "Boxes",
      "no_of_units": 12,
      "gross_weight": "12kg",
      "chargeable_weight": "15kg",
      "creator_email": "demo-account@janio.asia",
      "remark": "No Remark"
    },
    {
      "order_id": 182828,
      "status": "Delivered",
      "confirmed_quotation": "N/A",
      "client_name": "1661. Inc (Demo)",
      "order_creation_date": "2024-02-02",
      "origin_ountry": "Malaysia",
      "destination_country": "Indonesia",
      "mawb": null,
      "shipment_consolidation": null,
      "hawb": null,
      "package_type": "Pallets",
      "no_of_units": 15,
      "gross_weight": "27kg",
      "chargeable_weight": "31kg",
      "creator_email": "demo-account@janio.asia",
      "remark": "No Remark"
    },
    {
      "order_id": 182814,
      "status": "Freight Uplift Complete",
      "confirmed_quotation": "N/A",
      "client_name": "1661. Inc (Demo)",
      "order_creation_date": "2024-01-31",
      "origin_ountry": "Indonesia",
      "destination_country": "Hong Kong",
      "mawb": null,
      "shipment_consolidation": null,
      "hawb": null,
      "package_type": "Boxes",
      "no_of_units": 16,
      "gross_weight": "32kg",
      "chargeable_weight": "389g",
      "creator_email": "demo-account@janio.asia",
      "remark": "No Remark"
    },
    {
      "order_id": 182810,
      "status": "Delivered",
      "confirmed_quotation": "N/A",
      "client_name": "1661. Inc (Demo)",
      "order_creation_date": "2024-01-28",
      "origin_ountry": "Indonesia",
      "destination_country": "Singapore",
      "mawb": null,
      "shipment_consolidation": null,
      "hawb": null,
      "package_type": "Boxes",
      "no_of_units": 22,
      "gross_weight": "32kg",
      "chargeable_weight": "34kg",
      "creator_email": "demo-account@janio.asia",
      "remark": "No Remark"
    },



    {
      "order_id": 181813,
      "status": "Created",
      "confirmed_quotation": "N/A",
      "client_name": "1661. Inc (Demo)",
      "order_creation_date": "2024-01-25",
      "origin_ountry": "Singapore",
      "destination_country": "Singapore",
      "mawb": null,
      "shipment_consolidation": null,
      "hawb": null,
      "package_type": "Pallets",
      "no_of_units": 55,
      "gross_weight": "43kg",
      "chargeable_weight": "45kg",
      "creator_email": "demo-account@janio.asia",
      "remark": "No Remark"
    },
    {
      "order_id": 181742,
      "status": "Freight Uplift Complete",
      "confirmed_quotation": "N/A",
      "client_name": "1661. Inc (Demo)",
      "order_creation_date": "2024-01-25",
      "origin_ountry": "Singapore",
      "destination_country": "Hong Kong",
      "mawb": null,
      "shipment_consolidation": null,
      "hawb": null,
      "package_type": "Pallets",
      "no_of_units": 10,
      "gross_weight": "24kg",
      "chargeable_weight": "28kg",
      "creator_email": "demo-account@janio.asia",
      "remark": "No Remark"
    },
    {
      "order_id": 181645,
      "status": "Freight Arrived Complete",
      "confirmed_quotation": "N/A",
      "client_name": "1661. Inc (Demo)",
      "order_creation_date": "2024-01-24",
      "origin_ountry": "Malaysia",
      "destination_country": "Singapore",
      "mawb": null,
      "shipment_consolidation": null,
      "hawb": null,
      "package_type": "Boxes",
      "no_of_units": 8,
      "gross_weight": "12kg",
      "chargeable_weight": "15kg",
      "creator_email": "demo-account@janio.asia",
      "remark": "No Remark"
    },
    {
      "order_id": 181511,
      "status": "Created",
      "confirmed_quotation": "N/A",
      "client_name": "1661. Inc (Demo)",
      "order_creation_date": "2024-01-23",
      "origin_ountry": "Malaysia",
      "destination_country": "Indonesia",
      "mawb": null,
      "shipment_consolidation": null,
      "hawb": null,
      "package_type": "Pallets",
      "no_of_units": 45,
      "gross_weight": "97kg",
      "chargeable_weight": "101kg",
      "creator_email": "demo-account@janio.asia",
      "remark": "No Remark"
    },
    {
      "order_id": 181445,
      "status": "Delivered",
      "confirmed_quotation": "N/A",
      "client_name": "1661. Inc (Demo)",
      "order_creation_date": "2024-01-22",
      "origin_ountry": "Indonesia",
      "destination_country": "Hong Kong",
      "mawb": null,
      "shipment_consolidation": null,
      "hawb": null,
      "package_type": "Boxes",
      "no_of_units": 16,
      "gross_weight": "32kg",
      "chargeable_weight": "389g",
      "creator_email": "demo-account@janio.asia",
      "remark": "No Remark"
    },
    {
      "order_id": 181368,
      "status": "Delivered",
      "confirmed_quotation": "N/A",
      "client_name": "1661. Inc (Demo)",
      "order_creation_date": "2024-01-22",
      "origin_ountry": "Indonesia",
      "destination_country": "Singapore",
      "mawb": null,
      "shipment_consolidation": null,
      "hawb": null,
      "package_type": "Boxes",
      "no_of_units": 18,
      "gross_weight": "32kg",
      "chargeable_weight": "34kg",
      "creator_email": "demo-account@janio.asia",
      "remark": "No Remark"
    },


    {
      "order_id": 181364,
      "status": "Created",
      "confirmed_quotation": "N/A",
      "client_name": "1661. Inc (Demo)",
      "order_creation_date": "2024-01-20",
      "origin_ountry": "Singapore",
      "destination_country": "Singapore",
      "mawb": null,
      "shipment_consolidation": null,
      "hawb": null,
      "package_type": "Boxes",
      "no_of_units": 55,
      "gross_weight": "43kg",
      "chargeable_weight": "45kg",
      "creator_email": "demo-account@janio.asia",
      "remark": "No Remark"
    },
    {
      "order_id": 181352,
      "status": "Freight Uplift Complete",
      "confirmed_quotation": "N/A",
      "client_name": "1661. Inc (Demo)",
      "order_creation_date": "2024-01-18",
      "origin_ountry": "Hong Kong",
      "destination_country": "Malaysia",
      "mawb": null,
      "shipment_consolidation": null,
      "hawb": null,
      "package_type": "Pallets",
      "no_of_units": 41,
      "gross_weight": "77kg",
      "chargeable_weight": "80kg",
      "creator_email": "demo-account@janio.asia",
      "remark": "No Remark"
    },
    {
      "order_id": 181230,
      "status": "Freight Arrived Complete",
      "confirmed_quotation": "N/A",
      "client_name": "1661. Inc (Demo)",
      "order_creation_date": "2024-01-12",
      "origin_ountry": "Malaysia",
      "destination_country": "Singapore",
      "mawb": null,
      "shipment_consolidation": null,
      "hawb": null,
      "package_type": "Boxes",
      "no_of_units": 12,
      "gross_weight": "12kg",
      "chargeable_weight": "15kg",
      "creator_email": "demo-account@janio.asia",
      "remark": "No Remark"
    },
    {
      "order_id": 181228,
      "status": "Delivered",
      "confirmed_quotation": "N/A",
      "client_name": "1661. Inc (Demo)",
      "order_creation_date": "2024-01-12",
      "origin_ountry": "Malaysia",
      "destination_country": "Indonesia",
      "mawb": null,
      "shipment_consolidation": null,
      "hawb": null,
      "package_type": "Pallets",
      "no_of_units": 15,
      "gross_weight": "27kg",
      "chargeable_weight": "31kg",
      "creator_email": "demo-account@janio.asia",
      "remark": "No Remark"
    },
    {
      "order_id": 181214,
      "status": "Freight Uplift Complete",
      "confirmed_quotation": "N/A",
      "client_name": "1661. Inc (Demo)",
      "order_creation_date": "2024-01-10",
      "origin_ountry": "Indonesia",
      "destination_country": "Hong Kong",
      "mawb": null,
      "shipment_consolidation": null,
      "hawb": null,
      "package_type": "Boxes",
      "no_of_units": 16,
      "gross_weight": "32kg",
      "chargeable_weight": "389g",
      "creator_email": "demo-account@janio.asia",
      "remark": "No Remark"
    },
    {
      "order_id": 181210,
      "status": "Delivered",
      "confirmed_quotation": "N/A",
      "client_name": "1661. Inc (Demo)",
      "order_creation_date": "2024-01-10",
      "origin_ountry": "Indonesia",
      "destination_country": "Singapore",
      "mawb": null,
      "shipment_consolidation": null,
      "hawb": null,
      "package_type": "Boxes",
      "no_of_units": 22,
      "gross_weight": "32kg",
      "chargeable_weight": "34kg",
      "creator_email": "demo-account@janio.asia",
      "remark": "No Remark"
    }
  ]
}
const DummyDataTable = (props: any) => {
  // const auth = useAuth();
  let columns = [
    {
      title: 'Order Id',
      dataIndex: 'order_id',
      key: 'order_id',
      width: 100,
      render: (order_id: any) => {
        return order_id ? order_id : 'N/A'
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 120,
      render: (status: any, data: any) => {
        return status
      }
    },
    // {
    //   title: 'Confirmed Quotation',
    //   dataIndex: 'confirmed_quotation',
    //   key: 'confirmed_quotation',
    //   render: (confirmed_quotation: string) => {
    //     return confirmed_quotation
    //   }
    // },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      width: 130,
      render: (client_name: any) => {
        return client_name
      }
    },
    {
      title: 'Order Creation Date',
      dataIndex: 'order_creation_date',
      key: 'order_creation_date',
      width: 160,
      render: (order_creation_date: any) => {
        return order_creation_date
      }
    },
    {
      title: 'Origin Country',
      dataIndex: 'origin_ountry',
      key: 'origin_ountry',
      width: 130,
      render: (origin_ountry: any) => {
        return origin_ountry
      }
    },
    {
      title: 'Destination Country',
      dataIndex: 'destination_country',
      key: 'destination_country',
      width: 160,
      render: (destination_country: any, allData: any) => {
        return destination_country
      }
    },
    // {
    //   title: 'MAWB',
    //   dataIndex: 'mawb',
    //   key: 'mawb',
    //   render: (mawb: string, allData: any) => {
    //     return mawb
    //   }
    // },
    // {
    //   title: 'Shipment Consolidation',
    //   dataIndex: 'shipment_consolidation',
    //   key: 'shipment_consolidation',
    //   render: (shipment_consolidation: string, allData: any) => {
    //     return shipment_consolidation
    //   }
    // },
    // {
    //   title: 'HAWB',
    //   dataIndex: 'hawb',
    //   key: 'hawb',
    //   render: (hawb: string, allData: any) => {
    //     return hawb
    //   }
    // },
    {
      title: 'Package Type',
      dataIndex: 'package_type',
      key: 'package_type',
      width: 115,
      render: (package_type: string, allData: any) => {
        return package_type
      }
    },
    {
      title: '# of Units',
      dataIndex: 'no_of_units',
      key: 'no_of_units',
      width: 100,
      render: (no_of_units: string, allData: any) => {
        return no_of_units
      }
    },
    {
      title: 'Gross Weight',
      dataIndex: 'gross_weight',
      key: 'gross_weight',
      width: 120,
      render: (gross_weight: string, allData: any) => {
        return gross_weight
      }
    },
    {
      title: 'Chargeable Weight',
      dataIndex: 'chargeable_weight',
      key: 'chargeable_weight',
      width: 155,
      render: (chargeable_weight: string, allData: any) => {
        return chargeable_weight
      }
    },
    {
      title: 'Creator Email',
      dataIndex: 'creator_email',
      key: 'creator_email',
      render: (creator_email: string, allData: any) => {
        return creator_email
      }
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
      render: (remark: string, allData: any) => {
        return remark
      }
    }
  ];
  
  // const handleExpandRow = (expanded: any, record: any) => {
  //   const expandedRows: any = [];
  //   if (expanded) {
  //     expandedRows.push(record.order_id);
  //   }
  //   setExpandedRows(expandedRows)
  // }
  
  return (
    <Col className={(props.hasData && !props.ordersSelected) ? 'mt-85 for-delivered-bulk' : (props.hasData && props.ordersSelected) ? 'mt-50 for-delivered-bulk' : 'mt-0 for-delivered-bulk'} span={24} >
      <Table
        className='pagination-select-wrapper'
        rowClassName='table_body'
        rowKey="order_id"
        // rowSelection={props.filterInReports ? undefined : {preserveSelectedRowKeys:true,...rowSelection}}
        // expandable={
        //   { 
        //     expandedRowRender: expandedRowRender,
        //     rowExpandable: record => record.order_type === 'MPS',
        //     expandedRowKeys: expandedRows,
        //     onExpand: handleExpandRow
        //   }
        // }
        dataSource={DummyData?.results!}
        columns={columns}
        // loading={isLoading}
        scroll={{ x: 1300 }}
        // pagination={{
        //   onChange: onSwitchPage,
        //   position:['bottomRight'],
        //   defaultPageSize: pageSize,
        //   pageSize: pageSize,
        //   total: data && data.count,
        //   showSizeChanger: true,
        //   onShowSizeChange: onPageSizeChange,
        //   pageSizeOptions: ['10', '20', '50', '100'],
        //   current : props.current 
        // }}
      />
    </Col>
  )
};

export default DummyDataTable
