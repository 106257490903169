import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col,
  Alert, Image,
} from 'antd';
import {useState} from "react";
import {useMutation} from "react-query";
import {reset_password} from "./services/authApi";
import './styles/AuthForm.css';
import mail_sent from "../assets/images/mail_sent.svg";
import {validateMessages} from "../commons/utils/constants";

interface MailSent {
  onMailSent(val:boolean):void;
}
const ResetForm = (props:MailSent) => {
  const [isResetFailed, setIsResetFailed] = useState<boolean>(false);
  const reset = useMutation(async ({email}: {email: string}) => {
    return reset_password(email)
  },  {
    onSuccess: (val) => {
      props.onMailSent(true);
    },
    onError: (val) => {
      setIsResetFailed(true);
    },
  });

  return (
    <Form
      onFinish={(val) => {
        reset.mutate(val)
      }}
      layout={'vertical'}
      requiredMark={false}
      validateMessages={validateMessages}
    >
      <Form.Item label="Need help with your password?" className={"form-title"}>
      </Form.Item>
      <Form.Item label="Enter your email, and we’ll send you instructions on how to reset your password." className={"form-sub-title"}>
      </Form.Item>
      {isResetFailed && <Alert
        className={"form-error"}
        message="Unable to process your request"
        type="error"
        showIcon
      />}
      <Form.Item
        label="Email Address"
        name="email"
        rules={[{ required: true, message: 'Please enter your email' }, {type:'email'}]}
      >
        <Input />
      </Form.Item>

      <Form.Item className={'form-footer'}>
        <Button type="primary" size={"large"} htmlType="submit" className={"form-button"} loading={reset.isLoading}>
            Send me reset instructions
        </Button>
          Remember your password?  <a href="/auth/login" className={"form-text"}> Login here</a>
      </Form.Item>
    </Form>
  )
};
const EmailSentForm = () => {

  return (
    <Form
      layout={'vertical'}
      requiredMark={false}
    >
      <Form.Item label="Recovery email sent!" className={"form-title"}>
      </Form.Item>
      <Form.Item className={"form_image"}>
        <Image preview={false} src={mail_sent}/>
      </Form.Item>
      <Form.Item className={"form-footer-desc"}>
          Please check your email for a recovery link. If you don’t recieve the verification email, <a href="/auth/reset_password" className={"form-text"}> click here to resend.</a>
      </Form.Item>
      <Form.Item className={'form-footer'}>
        <a href="/auth/login" className={"form-text"}> Return to login</a>
      </Form.Item>
    </Form>
  )
};



export const Reset = () =>  {
  const [isMailSent, setIsMailSent] = useState(false);
  const onMailSent = (val:boolean):void => {
    setIsMailSent(val);
  };
  return (
    <Row align="middle" justify="center">
      <Col span={12} className={'auth-form-container'}>
        <Card>
          {isMailSent ? <EmailSentForm /> :<ResetForm onMailSent={onMailSent}/>}
        </Card>
      </Col>
    </Row>
  )
};
