import {Button,Modal,message,Space} from 'antd';
import {useState} from "react";
import {useMutation} from "react-query";
import {downloadBulkOrderLabelPDF } from '../services';
import { useLanguage } from '../../languages/Language';
import { InfoCircleOutlined } from '@ant-design/icons';
import {OrderDetailData_from_b2c} from '../services';

interface Props {
   selectedOrders: Array<OrderDetailData_from_b2c>
   isDisplayingInBulk?: boolean
}

const PrintLabelHeader = (props:Props) => {
  let { getText } = useLanguage();
  const [showModal, setShowModal] = useState<boolean>(false);
  const closeModal = () => {
    setShowModal(false)
  };
  let trackningsNos = props.selectedOrders.map((order:OrderDetailData_from_b2c) => order.tracking_no)

  let invalidLabelOrderIds = props.selectedOrders.filter((value:OrderDetailData_from_b2c) => (value.order_label_url == null && value.private_tracker_status_code !== 'RETURN_ORDER_PENDING_CONSIGNEE_CONFIRMATION')).map((record:OrderDetailData_from_b2c) => record.tracking_no)

  let consigneeAddressPendingOrderIds = props.selectedOrders.filter((value:OrderDetailData_from_b2c) => value.private_tracker_status_code === 'RETURN_ORDER_PENDING_CONSIGNEE_CONFIRMATION').map((record:OrderDetailData_from_b2c) => record.tracking_no)

  const getPDFData = useMutation(async () => {
    // if(auth.user.authToken === 'e338b185c4018ee60db449dddd0286b4f80df078') {
    //   return getBulkOrderLabelPDF(orderIds,auth.user.authToken)
    // }
    return downloadBulkOrderLabelPDF(trackningsNos)
  }, {
    onSuccess: (response:any) => {
      try{
        generatePDF(response)
        setShowModal(false)
      }
      catch(error){
        setShowModal(false)
        message.error(getText("Failed to print labels"))
      }
    },
    onError: (error: any) => {
      setShowModal(false)
      if(error.response?.data?.error?.message){
        message.error(error.response.data.error.message)
      }
      else{
        message.error(getText("Failed to print labels"))
      }
    }
  })

  const onClickPrintLabels = () => {
    getPDFData.mutate();
  }

  const generatePDF = (data:any) => {
    const blob = new Blob([data],{type: "application/pdf"});
    const fileName = "shipment-labels.pdf";
    if (navigator.msSaveOrOpenBlob) {
      // IE
      navigator.msSaveOrOpenBlob(blob, fileName);
    }
    else{
      const url = URL.createObjectURL(blob);
      window.open(url,"_blank");
    }
  }

  const PrintLabelModal = () =>{
    return(
      <>
        <Modal title={`${getText("Print")} ${props.selectedOrders.length} ${getText("shipment labels")}`}
          width={700}
          visible={showModal}
          onCancel={closeModal}
          className={'modal-right-footer'}
          footer={[
            <Button onClick={closeModal}>{getText("Cancel")}</Button>,
            <Button type="primary" onClick={onClickPrintLabels} disabled={getPDFData.isLoading} loading={getPDFData.isLoading}>{getText("Print labels")}</Button>
          ]}>
          <p style={{marginBottom: 0}}>{getText("Do you want to print the selected shipment labels?")}</p>
        </Modal>
      </>

    )
  };

  const LabelPrintFailedModal = () => {
    Modal.info({
      // title: `Shipment label cannot be printed for below`,
      icon: null,
      width: 700,
      content: (
        <>
          <div style={{marginTop: 30}}>
            {
              invalidLabelOrderIds.length > 0 &&
              <div style={{border: '1px solid #eeeaea', padding: '0 10px', marginTop: '-40px'}}>
                <h3 style={{fontSize: '16px'}}><InfoCircleOutlined style={{color:'#00BFFF', marginRight: '5px'}}/>Shipment label cannot be printed for below</h3>
                {
                  invalidLabelOrderIds.map((id:any) =>
                  {
                    return <p style={{fontSize: '14px', marginBottom: 0}}>- {id}</p>
                  }
                  )
                }
                <Space/>
                <div style={{marginTop: 25}}>
                  <p>Please try again<strong> after 10 minutes</strong> and <strong>refresh your browser.</strong> If the problem persists, please contact us at {<a href={'mailto:clientsupport@janio.asia'} style={{color: '#050593'}}>clientsupport@janio.asia</a>} to rectify this issue.</p>
                  
                </div>
              </div>
            }
            {
              consigneeAddressPendingOrderIds.length > 0 &&
              <div style={{border: '1px solid #eeeaea', padding: '0 10px', marginTop: '15px'}}>
                <h3 style={{fontSize: '16px'}}><InfoCircleOutlined style={{color:'#00BFFF', marginRight: '5px'}}/>Shipment label generation is pending on consignee confirmation</h3>
                {
                  consigneeAddressPendingOrderIds.map((id:any) =>
                  {
                    return <p style={{fontSize: '14px', marginBottom: 0}}>- {id}</p>
                  }
                  )
                }
                <strong style={{margin: '15px 0', display: 'block'}}>An email has been sent to your consignee to confirm the pickup</strong>
                <div>
                  <p>If the shipment labels do not appear after consignee confirmation, please contact us at {<a href={'mailto:clientsupport@janio.asia'} style={{color: '#050593'}}>clientsupport@janio.asia</a>}</p>
                  
                </div>
              </div>
            }
          </div>
        </>
      ),
      onOk() {},
    },
    );
  }

  const onClick = () => {
    if(invalidLabelOrderIds.length > 0 || consigneeAddressPendingOrderIds.length > 0) {
      LabelPrintFailedModal()
    }
    else{
      setShowModal(true);
    }
  }

  if(props.isDisplayingInBulk && props.selectedOrders.length >0) {
    return (
      <>
        <div style={{background: 'rgb(255, 255, 255)', textAlign: 'center', width: '124px', marginLeft: '15px'}}>
          <Button className="button-label" type="text" onClick={onClick} style={{color: '#050593', fontWeight: 'bold'}}>{"Print labels"}</Button>
        </div>
        {showModal && <PrintLabelModal/>}
      </>
    )
  }

  return (
    <>
      {(props.selectedOrders.length >0) ?
        <div className="main-print-container" style={{padding: '0 22px'}}>
          <div className="print-content" style={{height: '60px', justifyContent: 'unset'}}>
            <div>
              {props.selectedOrders.length} {getText("Shipments selected")}
            </div>
            <div style={{color: '#050593', background: '#fff', marginLeft: '35px', fontWeight: 600, minWidth: '125px', textAlign: 'center'}}>
              <Button className="button-label" type="text" onClick={onClick} style={{color: '#050593', fontWeight: 'bold'}}>{getText("Print labels")}</Button>
              {showModal && <PrintLabelModal/>}
            </div>
          </div>
          <div className="arrow-down" style={{marginLeft: '8px'}}></div>
        </div>
        : <></>}
    </>
  )
};

export default PrintLabelHeader
