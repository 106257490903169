import {Divider, Spin} from 'antd';
import { useState } from 'react';
import { useQuery } from "react-query";
import { RightToggleSidebar } from '../commons/components/ToggleSidebar';
import { ManageShipmentsContainer } from "../ManageShipments/ManageShipmentsContainer";
import { getOrderTrackers } from './services'
import { useAuth } from "../auth/services/authContext";
import './styles/ManageShipments.css';
import moment from 'moment';
import { useLanguage } from '../languages/Language';
import {PickupThresholdAlert} from "../commons/components/PickupFeeAlerts/PickupThresholdAlert";
// import {DropoffSupportNotification} from "./Components/DropoffSupportNotification";

const OrderTracking = ({orderId}: {orderId: number}) => {
  let { getText } = useLanguage();
  const auth = useAuth();
  const trackingQuery = useQuery(['tracking', orderId], () => {
    if(orderId > 0){
      return getOrderTrackers(orderId, auth.user.secret_key);
    }
    return null
  })

  if(trackingQuery.isLoading){
    return (
      <div style={{textAlign: 'center', marginTop: 200}}>
        <Spin />
      </div>
    )
  }

  if(!trackingQuery.data || trackingQuery.data.updates!.length < 1){
    return <p style={{marginTop: '2em'}}>{getText('No tracking updates found')}</p>
  }

  return (
    <div style={{overflowY: 'auto'}}>
      { trackingQuery.data!.updates!.map( (result, index) => {
        return (
          <div>
            <p className={'regular-bold-text'}>{result.status}</p>
            <div className={'tracked-order-details'}>
              <div className={'date-details'}>
                <p style={{marginBottom: 0}}>{moment(result.updated_on).format( 'MMM DD, YYYY')}</p>
                <p>{moment(result.updated_on).format( 'hh:mm a')}</p>
              </div>
              <div className={'description-details'}>
                {result.description && <p>{result.description}</p>}
                {result.address && <p>{result.address}</p>}
              </div>
            </div>
            {index+1 !== trackingQuery.data!.updates!.length && <Divider/>}
          </div>
        )
      })
      }
    </div>
  )
}

export const ShipmentsPage = () => {
  let auth = useAuth()
  let { getText } = useLanguage();
  const [trackedOrder, setTrackedOrder] = useState<number>(0)
  const [openSidebar, setOpenSidebar] = useState(false)

  const displayTrackingSidebar = (orderId: number) => {
    setTrackedOrder(orderId)
    setOpenSidebar(true)
  }

  const onSideBarClose = (isOpen: boolean) => {
    setOpenSidebar(isOpen)
    setTrackedOrder(0)
  }

  return (
    <div>
      {/* <div className="manage-shipments-notifications">
        <BillingAddressAlert/>
      </div> */}
      {auth.user.b2c_id !== '4214' && <div className="manage-shipments-notifications">
        <PickupThresholdAlert/>
      </div>  
      }
        
      {/* <DropoffSupportNotification/> */}
      <div>
        <h1 className={`ant-page-header-heading-title page-title ${auth.user.b2c_id === '4214' ? 'negative-mb-12' : ''}`} >{getText('Manage Shipments')}</h1>
        <ManageShipmentsContainer displayTrackingSidebar={displayTrackingSidebar}/>
      </div>
      <RightToggleSidebar isOpen={openSidebar} setIsOpen={onSideBarClose} >
        <OrderTracking orderId={trackedOrder} />
      </RightToggleSidebar>
    </div>
  )
}
