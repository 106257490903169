import {createContext, ReactNode, useContext, useState} from "react"

interface RouteLeavingGuardContext {
  shouldBlockNavigation: boolean
  setShouldBlockNavigation : (val:boolean) => any
  confirmedNavigation: boolean,
  setConfirmedNavigation: (val:boolean) => any
  lastLocation: any
  setLastLocation: (val:any) => any
  showAlert:boolean
  setShowAlert: (val:boolean) => any
  handleBlockedNavigation: (val:any)=> any
}

const routeLeavingGuardContext = createContext<RouteLeavingGuardContext>({} as RouteLeavingGuardContext);

export const RouteLeavingGuardContextProvider = ({children}: { children: ReactNode}) => {

  const useRouteLeavingGuard = useRouteLeavingGuardContextProvider();
  return (
    <routeLeavingGuardContext.Provider value={useRouteLeavingGuard}>
      {children}
    </routeLeavingGuardContext.Provider>
  )
};

export const useRouteLeavingGuard = () => {
  return useContext(routeLeavingGuardContext);
};

const useRouteLeavingGuardContextProvider = () => {
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(true);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [lastLocation, setLastLocation] = useState<any>(null);
  const [showAlert, setShowAlert] = useState(false);
  const handleBlockedNavigation = (nextLocation: any): any => {
    if (!confirmedNavigation && shouldBlockNavigation) {
      setShowAlert(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };
  return {
    shouldBlockNavigation,
    setShouldBlockNavigation,
    confirmedNavigation,
    setConfirmedNavigation,
    lastLocation,
    setLastLocation,
    showAlert,
    setShowAlert,
    handleBlockedNavigation
  }
};
