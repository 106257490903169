import { instance as api } from '../commons/api'
import { useQuery } from "react-query";

interface WalletDetail {
  id: number;
  account: number;
  currency: string;
  currency_type: string;
  wallet_balance: number;
}

export const getWalletDetail = async ( token: string) => {
  let response = await api.get<WalletDetail>('payments/account/details/',
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    });
  return response.data
};

export const useWalletDetail = (token: string) => {
  return useQuery('getWalletDetails', async () => {
    return getWalletDetail(token)
  })
}



interface TransactionHistory {
  id: number,
  amount: number,
  cashflow_type: number,
  description: string,
  transaction_type: number,
  created_at: string,
  updated_at: string,
}
interface WalletHistory {
  count: number,
  results?: Array<TransactionHistory>
}

export const getTransactionHistory = async ( token: string, params: { limit: number, offset: number }) => {
  let response = await api.get<WalletHistory>(`payments/transaction/history/?limit=${params.limit}&offset=${params.offset}`,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    });
  return response.data
};
