import React, { useState, useRef } from 'react'
import {Row, Col, Button, message,Checkbox, Divider} from 'antd';
import { useDisputeContext } from '../disputeContext'
import { FastFormInput } from '../../commons/components/FastFormFields/FastFields';
import { useMutation } from 'react-query';
import {Formik, Form as FormikForm, FieldArray} from 'formik';
import { UploadFile } from '../../orders/components/Forms';
import { useAuth } from '../../auth/services/authContext';
import { fileUpload, removeUploadedFile } from '../../orders/services/privateApi';
import { validateFileSize, validateFileType } from '../../commons/utils/validator';
import * as Yup from 'yup';
// import { PartialParcelLostForm } from '../Forms/PartialParcelLostForm'
// import DisputedSuccessfulDeliveryForm from '../Forms/DisputedSuccessfulDeliveryForm'
// import HandoverDisputeForm from '../Forms/HandoverDisputeForm'
// import FullParcelDamageForm from '../Forms/FullParcelDamageForm'
// import PartialParcelDamageForm from '../Forms/PartialParcelDamageForm'
// import WeightDisputeForm from '../Forms/WeightDisputeForm'

interface ClaimformProp{
  // claimType: string
  // claimSubType: string
  // tracking_no: string
  // order_id: any
}

interface SupportingDocumentsProps {
  contextData: any, 
  index: number, 
  disputeContext: any,
  formikData: any
}

const SupportingDocuments = (data: SupportingDocumentsProps) => {
  const auth = useAuth()
  const {contextData, index, disputeContext,formikData} = data
  const fileIndex = useRef(0)
  const fileType = useRef('')

  const fileUploadQuery:any = useMutation(async (file: any) => {
    return fileUpload(file, auth.user.secret_key);
  },  {
    onSuccess: (val: any) => {
      if(fileType.current === 'supporting_doc_length'){
        formikData.setFieldValue(`items.[${index}].supporting_doc_length`, [val.id])
      }
      if(fileType.current === 'supporting_doc_width'){
        formikData.setFieldValue(`items.[${index}].supporting_doc_width`, [val.id])
      }
      if(fileType.current === 'supporting_doc_height'){
        formikData.setFieldValue(`items.[${index}].supporting_doc_height`, [val.id])
      }
      if(fileType.current === 'supporting_doc_weight'){
        formikData.setFieldValue(`items.[${index}].supporting_doc_weight`, [val.id])
      }
      // if(fileType.current === 'supporting_doc_length'){
      //   console.log('valvalavs', val)
      //   formikData.setFieldValue(`items.[${index}].supporting_doc_length`, [val.id])
      //   console.log('formikformi', formikData)
      //   // const uploadDataLength = JSON.parse(lengthUploadUrl);
      //   // uploadDataLength[fileIndex.current] = [...uploadDataLength[fileIndex.current], val.id]
      //   // setLengthUploadUrl(JSON.stringify(uploadDataLength))
  
      //   // const fileListLength = JSON.parse(fileUploadListLength);
      //   // fileListLength[fileIndex.current] = [...fileListLength[fileIndex.current], {uid: val.id, name: val.key, status: 'done', url: val.public_url}]
      //   // setFileUploadListLength(JSON.stringify(fileListLength))
      // }
      // if(fileType.current === 'height_photographic_evidence'){
      //   const uploadDataHeight = JSON.parse(heightUploadUrl);
      //   uploadDataHeight[fileIndex.current] = [...uploadDataHeight[fileIndex.current], val.id]
      //   setHeightUploadUrl(JSON.stringify(uploadDataHeight))
  
      //   const fileListHeight = JSON.parse(fileUploadListHeight);
      //   fileListHeight[fileIndex.current] = [...fileListHeight[fileIndex.current], {uid: val.id, name: val.key, status: 'done', url: val.public_url}]
      //   setFileUploadListHeight(JSON.stringify(fileListHeight))
      // }
      // if(fileType.current === 'weight_photographic_evidence'){
      //   const uploadDataWeight = JSON.parse(weightUploadUrl);
      //   uploadDataWeight[fileIndex.current] = [...uploadDataWeight[fileIndex.current], val.id]
      //   setWeightUploadUrl(JSON.stringify(uploadDataWeight))
  
      //   const fileListWeight = JSON.parse(fileUploadListWeight);
      //   fileListWeight[fileIndex.current] = [...fileListWeight[fileIndex.current], {uid: val.id, name: val.key, status: 'done', url: val.public_url}]
      //   setFileUploadListWeight(JSON.stringify(fileListWeight))
      // }
      // if(fileType.current === 'width_photographic_evidence'){
      //   const uploadDataWidth = JSON.parse(widthtUploadUrl);
      //   uploadDataWidth[fileIndex.current] = [...uploadDataWidth[fileIndex.current], val.id]
      //   setWidthUploadUrl(JSON.stringify(uploadDataWidth))
  
      //   const fileListWidth = JSON.parse(fileUploadListWidth);
      //   fileListWidth[fileIndex.current] = [...fileListWidth[fileIndex.current], {uid: val.id, name: val.key, status: 'done', url: val.public_url}]
      //   setFileUploadListWidth(JSON.stringify(fileListWidth))
      // }
      message.success('File uploaded successfully')
    },
    onError: (error: any) => {
      message.error('error in uploading. Please again later')
    },
  })

  const fileRemoveQuery:any = useMutation(async (id: any) => {
    return removeUploadedFile(auth.user.secret_key, id);
  },  {
    onSuccess: (val: any) => {
      console.log('fileType.currentfileType.current', fileType.current)
      if(fileType.current === 'supporting_doc_length'){
        formikData.setFieldValue(`items.[${index}].supporting_doc_length`, [])
      }
      if(fileType.current === 'supporting_doc_width'){
        formikData.setFieldValue(`items.[${index}].supporting_doc_width`, [])
      }
      if(fileType.current === 'supporting_doc_height'){
        formikData.setFieldValue(`items.[${index}].supporting_doc_height`, [])
      }
      if(fileType.current === 'supporting_doc_weight'){
        formikData.setFieldValue(`items.[${index}].supporting_doc_weight`, [])
      }
      message.success('File removed successfully')
    },
    onError: (error: any) => {
      message.error('error in uploading. Please again later')
    },
  })

  const onFileRemove = (event: any, type: string, index: number, formik: any, fileName: string) => {
    fileType.current = type;
    let get_remove_id = formik.values.items[index][type].toString()

    fileRemoveQuery.mutate(get_remove_id)

  }
  const onFileAddition = (event: any, type: string, index: number, formik: any, fileName: string) => {
    const isValidFileType = validateFileType(event)
    const isValidFileSize = validateFileSize(event)
    if(!isValidFileType){
      message.error('Only .png, .jpg and .pdf files are accepted.')
    }
    if(!isValidFileSize){
      message.error('Each file needs to be less than 10mb.')
    }
    if(isValidFileType && isValidFileSize){
      
      fileIndex.current = index;
      fileType.current = type;
      fileUploadQuery.mutate(event)
    }
    return false
  }
  
  return (
    <>
      <Row style={{marginBottom: '10px'}}>
        <Col span={7}><span style={{fontSize: '14px'}}>Description for <strong>{contextData}</strong></span></Col>
        <Col span={16} style={{marginLeft: '6px'}}><span className='text-wrap' title={"Upload Files: Only .png, .jpg and .pdf files are accepted.Each file needs to be less than 10mb. You can add up to a maximum of 4 files."}>{"Upload Files: Only .png, .jpg and .pdf files are accepted.Each file needs to be less than 10mb. You can add up to a maximum of 4 files."}</span></Col>
      </Row>
      
      <Row gutter={16} style={{marginTop: '8px'}}>
        <Col span={7}>
          <FastFormInput
            required 
            label={''} 
            inputProps={{disabled: false}} 
            name={`items.[${index}].item_desc`}
            textArea={true}
            rows={7}
            height={170}
          />
        </Col>
        <Col span={17}>
          <Row gutter={16}>
            <Col span={6}>
              <Row gutter={[12, 16]} wrap>
                <Col span={24} className='supporting-doc-upload'>
                  <UploadFile
                    accept=".png,.jpg,.pdf"
                    required 
                    label={''} 
                    name={`items.[${index}].supporting_doc_length`}
                    beforeUpload={(e) => onFileAddition(e, 'supporting_doc_length', index, formikData, 'length_file')}
                    showRemove
                    listType={"picture"}
                    showUploadList={formikData.values.items[index]?.supporting_doc_length?.length>0 ? true : false}
                    onRemove={(e) => onFileRemove(e, 'supporting_doc_length', index, formikData, 'length_file')}
                    customText='Length'
                  />
                </Col>
                <Col span={24} className='supporting-doc-input'>
                  <FastFormInput 
                    label={""}
                    inputProps={{addonBefore : 'Length', addonAfter: 'cm'}} 
                    name={`items.[${index}].item_length`}
                    wrapperClass={"no-margin-item"} 
                  />
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row gutter={[12, 16]}>
                <Col span={24} className='supporting-doc-upload'>
                  <UploadFile
                    accept=".png,.jpg,.pdf"
                    required 
                    label={''} 
                    name={`items.[${index}].supporting_doc_width`}
                    beforeUpload={(e) => onFileAddition(e, 'supporting_doc_width', index, formikData, 'width_file')}
                    showRemove
                    listType={"picture"}
                    showUploadList={formikData.values.items[index]?.supporting_doc_width?.length>0 ? true : false}
                    onRemove={(e) => onFileRemove(e, 'supporting_doc_width', index, formikData, 'length_file')}
                    customText='Width'
                  />
                </Col>
                <Col span={24} className='supporting-doc-input'>
                  <FastFormInput 
                    label={""}
                    inputProps={{addonBefore : 'Width', addonAfter: 'cm'}} 
                    name={`items.[${index}].item_width`}
                    wrapperClass={"no-margin-item"} 
                  />
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row gutter={[12, 16]}>
                <Col span={24} className='supporting-doc-upload'>
                  <UploadFile
                    accept=".png,.jpg,.pdf"
                    required 
                    label={''} 
                    name={`items.[${index}].supporting_doc_height`} 
                    beforeUpload={(e) => onFileAddition(e, 'supporting_doc_height', index, formikData, 'height_file')}
                    showRemove
                    listType={"picture"}
                    showUploadList={formikData.values.items[index]?.supporting_doc_height?.length>0 ? true : false}
                    onRemove={(e) => onFileRemove(e, 'supporting_doc_height', index, formikData, 'length_file')}
                    customText='Height'
                  />
                </Col>
                <Col span={24} className='supporting-doc-input'>
                  <FastFormInput 
                    label={""}
                    inputProps={{addonBefore : 'Height', addonAfter: 'cm'}} 
                    name={`items.[${index}].item_height`}
                    wrapperClass={"no-margin-item"} 
                  />
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row gutter={[12, 16]}>
                <Col span={24} className='supporting-doc-upload'>
                  <UploadFile
                    accept=".png,.jpg,.pdf"
                    required 
                    label={''} 
                    name={`items.[${index}].supporting_doc_weight`}
                    beforeUpload={(e) => onFileAddition(e, 'supporting_doc_weight', index, formikData, 'weight_file')}
                    showRemove
                    listType={"picture"}
                    showUploadList={formikData.values.items[index]?.supporting_doc_weight?.length>0 ? true : false}
                    onRemove={(e) => onFileRemove(e, 'supporting_doc_weight', index, formikData, 'length_file')}
                    customText='Weight'
                  />
                </Col>
                <Col span={24} className='supporting-doc-input'>
                  <FastFormInput 
                    label={""}
                    inputProps={{addonBefore : 'Weight', addonAfter: 'kg'}} 
                    name={`items.[${index}].item_weight`}
                    wrapperClass={"no-margin-item"} 
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      {disputeContext.tracking_nos.length > 0 && <Divider />}
    </>
  )
}

const validationSchema = () => {
  return (
    Yup.object().shape({
      items: Yup.array().of(Yup.object().shape({
        item_desc: Yup.string().required('Please enter Incident description').max(80, 'Must be less than 80 characters'),
        supporting_doc_length: Yup.array().min(1, "Please add file").required(),
        supporting_doc_width: Yup.array().min(1, "Please add file").required(),
        supporting_doc_height: Yup.array().min(1, "Please add file").required(),
        supporting_doc_weight: Yup.array().min(1, "Please add file").required(),
        item_length: Yup.string().required('Please select file type'),
        item_width: Yup.string().required('Please select file type'),
        item_height: Yup.string().required('Please select file type'),
        item_weight: Yup.string().required('Please select file type'),
      }))
    })
  )
}
export const AddSupportingDocs = (props: ClaimformProp) => {
  const disputeContext = useDisputeContext()
  const [checked, setChecked] = useState(false)
  // const [isShowModal, setIsShowModal] = useState(false)

  // console.log('disputeContextdisputeContextform2', disputeContext)
  // switch(props.claimSubType){
  //   case 'partial_parcel_lost': return ( <PartialParcelLostForm />)
  // }
  // if(props.claimSubType === 'partial_parcel_lost'){
  //   return ( <PartialParcelLostForm {...props}/>)
  // }else if(props.claimSubType === 'disputed_successful_delivery'){
  //   return ( <DisputedSuccessfulDeliveryForm {...props}/>)
  // }else if(props.claimSubType === 'handover_dispute'){
  //   return ( <HandoverDisputeForm {...props}/>)
  // }else if(props.claimSubType === 'full_parcel_damage'){
  //   return ( <FullParcelDamageForm {...props}/>)
  // }else if(props.claimSubType === 'partial_parcel_damage'){
  //   return ( <PartialParcelDamageForm {...props}/>)
  // }else{
  //   return ( <WeightDisputeForm {...props}/>)
  // }

  const initialValues = {
    items: disputeContext.disputeData.items
  }

  const onFormikSubmit = (values: any) => {
    console.log('valuessabjbas', values)
  }

  // const termsAndConditionModal = () => {
  //   setIsShowModal(!isShowModal)
  // }

  return (
    <Row className='claim-main-container'>
      <Col span={24}>
        <Formik 
          initialValues={initialValues}
          onSubmit={onFormikSubmit}
          validationSchema={validationSchema}
        >
          {formik => {
            return (
              <FormikForm className='dispute-main-container'>
                <Col span={24}>
                  <FieldArray name="items"
                    render={arrayHelpers => (
                      <>                    
                        {disputeContext.disputeData.tracking_nos.map((fulldata, index) => (
                          <div key={index}>
                            <SupportingDocuments contextData={fulldata} index={index} disputeContext={disputeContext.disputeData} formikData={formik} />
                          </div>
                        ))}
                      </>
                    )} />
                  <div className='accept_submit'>
                    <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)} style={{margin: '0 10px'}} />
                    <div style={{marginRight: '20px'}}>
                      I agree to the 
                      <a
                        href="/terms_conditions" target='_blank' rel='noreferrer'
                        // onClick={termsAndConditionModal}
                      > terms and conditions</a> mentioned
                    </div>
                      
                    <div>
                      <Button htmlType="submit" type='primary' 
                        // onClick={saveButton} 
                        disabled={!checked} 
                        className='blue-button'>{'Save & Continue'}
                      </Button>
                    </div>
                  </div>
                </Col>
                {/* <Modal title={'Terms and Condition'} visible={isShowModal} onCancel={termsAndConditionModal} onOk={termsAndConditionModal} width={"70%"}>
                  <NewTermsAndConditionsPage />
                </Modal> */}
              </FormikForm>
            )
          }
          }
        </Formik>
      </Col>
    </Row>
  )

}

export const AddSupportingDocsTitle = () => {
  return (
    <div>
      <p className={'claim-step-title'}>{'Add supporting information'}</p>
    </div>
  )
}