import axios, { AxiosError } from 'axios'
import { useAuth } from '../auth/services/authContext';
// import { signOutForDisabledAccount } from '../auth/services/authContext'

export const instance = axios.create({
  baseURL: '/api/',
  withCredentials: true
})

// Add a response interceptor
instance.interceptors.response.use((response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, (error) => {
  let value: any = error
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (value.response.status === 403) {
    // signOutForDisabledAccount()
    return (
      Promise.reject(error)
    )
  }
  return Promise.reject(error);
});

export type RequestError = Error | AxiosError

export const isApiError = axios.isAxiosError
export const instanceForb2c = axios.create({
  baseURL: (window.location.hostname.includes('staging') || window.location.hostname.includes('localhost')) ? 'https://api.staging.janio.asia/api' : 'https://api.janio.asia/api'
})

// Add a response interceptor
instanceForb2c.interceptors.response.use((response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, (error) => {
  let value: any = error
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (value.response.status === 403) {
    // signOutForDisabledAccount()
    return (
      Promise.reject(error)
    )
  }
  return Promise.reject(error);
});

export type RequestErrorForb2c = Error | AxiosError

export const isApiErrorforb2c = axios.isAxiosError

export const instanceForSuperset = axios.create({
  baseURL: (window.location.hostname.includes('staging') || window.location.hostname.includes('localhost')) ? 'https://embedapp.janio.asia/api' : 'https://embedapp.janio.asia/api'
})

// Add a response interceptor
instanceForSuperset.interceptors.response.use((response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, (error) => {
  let value: any = error
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (value.response.status === 403) {
    // signOutForDisabledAccount()
    return (
      Promise.reject(error)
    )
  }
  return Promise.reject(error);
});

export type RequestErrorForSuperset = Error | AxiosError

export const isApiErrorForSuperset = axios.isAxiosError

export const instanceForFinance = axios.create({
  baseURL: (window.location.hostname.includes('staging') || window.location.hostname.includes('localhost')) ? 'https://staging-api.finance.janio.asia' : 'https://api.finance.janio.asia',
  headers: { 
    Authorization: 'Bearer q2AD8INgpp37ziy06B0nTb76p7eLIoqE4t10QJwr8RrSbEJQO7gwG9gpGNw4Xhyg', // TODO need to store outside project (in environment variable)
  }
})

instanceForFinance.interceptors.response.use((response) => {
  return response.data;
}, (error) => {
  const value: any = error

  if (value.response.status === 401) {
    const auth = useAuth();
    auth.signOut();
  }

  return Promise.reject(error);
});

export type RequestErrorForFinance = Error | AxiosError

export const isApiErrorforFinance = axios.isAxiosError