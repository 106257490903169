import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col,
  Image, Alert
} from 'antd'
import {useParams} from "react-router-dom";
import './styles/AuthForm.css'
import secure_login from "../assets/images/secure_login.svg";
import {useState} from "react";
import {useMutation} from "react-query";
import {confirm_reset_password} from "./services/authApi";

interface Resent {
    onResent(val:boolean):void;
}
interface ParamTypes {
    uid: string,
    token: string
}
const CreatePasswordForm = (props:Resent) => {
  const [resetFailedData, setResetFailedData] = useState<any>({});
  const { uid, token } = useParams<ParamTypes>();

  const errData = () => {
    let errorStrings: string[] = []
    for (const key in resetFailedData){
      Object.values(resetFailedData[key]).forEach((val:any) => {
        return errorStrings.push(val)
      })
    }
    return (
      <ul style={{paddingLeft: '0px'}}>
        {
          errorStrings.map((error: string, index: number) => {
            return <li key={index} style={{fontSize: '12px',color: '#000', margin: 0}}>{error}</li>
          })
        }
      </ul>
    )
  }

  const confirm_reset = useMutation(async ({password, re_password}: {password: string, re_password: string}) => {
    return confirm_reset_password(uid, token, password, re_password)
  },  {
    onSuccess: (val) => {
      setResetFailedData({})
      props.onResent(true)
    },
    onError: (error:any) => {
      setResetFailedData(error.response.data)
    },
  });

  return (
    <Form
      onFinish={(val) => {
        confirm_reset.mutate(val);
      }}
      layout={'vertical'}
      requiredMark={false}
    >
      <Form.Item label="Create new password" className={"form-title"}>
      </Form.Item>
      <Form.Item label="Please create your Janio merchant portal account password" className={"form-sub-title"}>
      </Form.Item>
      {Object.keys(resetFailedData).length > 0 && <Alert
        className={"form-error"}
        message="Unable to process your request"
        description= {errData()}
        type="error"
        showIcon
      />}
      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please enter new password' }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Re-Type Password"
        name="re_password"
        dependencies={['password']}
        rules={[{ required: true, message: 'Please re-enter new password' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject('Password not the same');
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item className={'form-footer'}>
        <Button type="primary" size={"large"} htmlType="submit" className={"form-button"} loading={confirm_reset.isLoading}>
            Create new password
        </Button>
      </Form.Item>
    </Form>
  )
};

const SuccessForm = () => {

  return (
    <Form
      layout={'vertical'}
      requiredMark={false}
    >
      <Form.Item label="Password successfully created!" className={"form-title"}>
      </Form.Item>
      <Form.Item className={"form_image"}>
        <Image preview={false} src={secure_login}/>
      </Form.Item>
      <Form.Item className={'form-footer'}>
        <Button type="primary" size={"large"} htmlType="submit" className={"form-button"} loading={false} >
          <a href="/auth/login"> Return to login page</a>
        </Button>
      </Form.Item>
    </Form>
  )
};

export const CreatePassword = () =>  {
  const [isResent, setIsResent] = useState(false);
  const onResent= (val:boolean):void => {
    setIsResent(val);
  };
  return (
    <Row align="middle" justify="center">
      <Col span={12} className={'auth-form-container'}>
        <Card>
          {isResent ? <SuccessForm /> : <CreatePasswordForm onResent={onResent} /> }
        </Card>
      </Col>
    </Row>
  )
};
