import {Alert, Button, Card, Col, Divider, Form as F, Row, Select,Tooltip} from 'antd';
import {useState,useRef} from "react";
import { EnvironmentOutlined } from '@ant-design/icons'
import moment from 'moment';
import {PickUpAddressModal as AddAddressModal} from "../../../UserSettings/components/PickUpAddressModal";
import { getLocations_from_b2c, getServiceDefinitions_from_b2c} from "../../services/privateApi";
import {useAuth} from "../../../auth/services/authContext";
import {parseDate,getFormattedServiceTypeText, getDropOffAddressDataForBulkOrder, filterOnlyRequiredDataFromB2c, isPickupServiceEnabled} from "../../../commons/utils/utilizer";
import '../../styles/SingleOrderForm.css';
import {useBulkOrder} from "../../services/bulkOrderContext";
import {countryCodes, existingServices} from "../../../commons/utils/constants";
// import {getDropOffAddressData} from "../../../commons/utils/utilizer";
import { disabledDate,canChoosePickupDate,PickupDays, createLanesForFoward, assignAddressForBulk, getServiceId} from "../../helpers";
import {Formik, FieldArray, Form } from 'formik';
import {FormSelect, DatePicker, FormInput} from "../Forms";
import {InfoCircleOutlined} from "@ant-design/icons/lib";
import * as Yup from 'yup';
import { useLanguage } from '../../../languages/Language';
// import {DropOffAddressModal} from '../DropOffAddressModal';
import {PickupChargeAlert} from '../../../commons/components/PickupFeeAlerts/PickupChargeAlert';
import { useQuery } from 'react-query';
// import { consolidateOrderItems } from '../../fileHelpers';
// @ts-ignore
import prohibitedItemsToIndonesia from  '../../../assets/static/prohibitedItemsToIndonesia.pdf'
// @ts-ignore
import prohibitedItemsToVietnam from '../../../assets/static/prohibitedItemsToVietnam.pdf'
import { verifyCountry } from '../../fileHelpers';

const {Option} = Select;

interface PropsType {
    current: number,
    setCurrentState: (val: number) => void,
    dataFilled: number
}

const validationSchema = (getText: any) => {
  return Yup.object().shape({
    distinctLanes: Yup.array().of(Yup.object().shape({
      service_type: Yup.string().nullable().required('Service Type is required'),
      pickup_address_id: Yup.string().nullable().when('service_type',{
        is: ('Pickup' || 'Pickup_Express'),
        then: Yup.string().nullable().required(getText('Please select Pickup Address'))
      }),
      dropoff_vendor_point_id: Yup.string().nullable().when('service_type', {
        is: 'Dropoff',
        then: Yup.string().nullable().required('Please select DropOff Address')
      }),
      pickup_date: Yup.date().nullable().when(['service_type', 'origin_country'], {
        is: (service_type: any, origin_country: any) => service_type === 'Pickup' && origin_country === 'Singapore',
        then: Yup.date().nullable().required('Please select Pickup Date')
          .test(
            'valid-date',
            (val: any, context: any) => {
              if (val) {
                let formattedDate = parseDate(val);
                if ((!moment().isBefore(moment({ hour: 8, minute: 0 })) && formattedDate!.isBefore(moment().endOf('day')))) {
                  return context.createError({
                    path: context.path,
                    message: getText('Please select valid date')
                  })
                }
              }
              return true
            }
          )
      }),
    }))
  })};


const empty_lane = (lane:any) =>{
  return{
    origin_country: lane.origin,
    service_type: '',
    pickup_address_id: '',
    dropoff_vendor_point_id:'',
    pickup_date: '',
    pickup_time: '9am - 7pm'
  }
};

export const PickUpDetailsForm = (props: PropsType) => {
  let isEditing = window.location.pathname.includes('/pay/bulk')
  let { getText } = useLanguage();
  const auth = useAuth();
  const bulkOrderContext = useBulkOrder();
  const {current, setCurrentState} = props;
  const [addressInfo, setAddressInfo] = useState<any>([]);
  const [currentOrderType, setCurrentOrderType] = useState(-1);
  const [addressType, setAddressType] = useState<string>('');
  const [showAddAddressModal, setShowAddAddressModal] = useState<boolean>(false);
  // const [showMapModal, setShowMapModal] = useState<boolean>(false);

  const ServiceDetailsFormRef: any = useRef();
  //   let temp_auth_token = 'cf93deb1f1ea51b3e09556b63d17c69821c9db64'
  
  // const locations = useQuery('pickupLocations', () => {
  //   return getLocations(auth.user.authToken);
  // });

  const pickupLocationsFromb2c = useQuery('pickupLocationsFromb2c', () => {
    return getLocations_from_b2c(auth.user.secret_key)
  })

  // const getDropOffLocations = useQuery(['getPUDOPoints'], async () => {
  //   return getPUDOPoints(auth.user.authToken)
  // })
  
  // Fetch service Definitions Data 
  // const getServiceDefinitionsData = useQuery(['serviceDefinitions'], () => {
  //   return getServiceDefinitions(auth.user.authToken)
  // })

  const getServiceDefinitionsDataFromb2c = useQuery('serviceDefinitions', () => {
    return getServiceDefinitions_from_b2c(auth.user.secret_key)
  },{cacheTime:0})

  const pickUpContext =  createLanesForFoward(
    isEditing ? bulkOrderContext.parsedJson : bulkOrderContext.parsedJson.consolidatedOrderItems,
    auth.user.secret_key
  );

  const getCountOfShipments = () => {
    let count = 0;
    pickUpContext?.lanes?.map((obj:any)=>{
      return count = count+(obj?obj.count:0);
    })
    return count
  }

  const setShowAddAddressModalCallback = (val: boolean) => {
    setShowAddAddressModal(val);
    setCurrentOrderType(-1);
    pickupLocationsFromb2c.refetch();
  };

  // const setShowMapModalCallback = (val: boolean) => {
  //   setShowMapModal(val)
  // }

  const onFormSubmit = (values:any) => {
    let file_name = bulkOrderContext.parsedJson.file_name
    pickUpContext.lanes.map((lane:any, index:number)=>{
      const fName = `${lane.origin}-${lane.destination}`;
      const value = {
        pickup_address_id: values['distinctLanes'][index].pickup_address_id,
        service_type: values['distinctLanes'][index].service_type,
        billing_address_id: values['distinctLanes'][index].pickup_address_id,
        dropoff_vendor_point_id: values['distinctLanes'][index].dropoff_vendor_point_id,
        pickup_date: values['distinctLanes'][index].pickup_date,
        service_id: '',
      };
      const servicesList = getServiceId(lane.origin, lane.destination, value, getServiceDefinitionsDataFromb2c)
      // service_id: serviceDefinition![0].service_id,
      value.service_id = servicesList![0].service_id;
      let temp = addressInfo;
      temp[fName] = value;
      setAddressInfo(temp);
      return value;
    });
    
    const orders = assignAddressForBulk(bulkOrderContext.parsedJson.consolidatedOrderItems, addressInfo, pickupLocationsFromb2c.data,pickUpContext?.getDropOffLocations, getServiceDefinitionsDataFromb2c?.data!);
    bulkOrderContext.setPickUpFormContext({form: values['distinctLanes']});

    bulkOrderContext.setBulkOrder({file_name: file_name, orders: orders});
    // createMonthlyBillingBulkOrderInB2C.mutate(orders)
    bulkOrderContext.setPriceContext(null)
    //SetShowPriceEstimationModalHere
    //pop-up on record count more than 20
    setCurrentState(2);
  };
  const sortPUDOPointsByPostal = (data:any) => {
    return data?.sort((a:any,b:any)=> (a.postal > b.postal ? 1 : -1))
  }

  // const handleSelectedDropOffPointChangeFromMap = (val: any) => {
  //   if (val && ServiceDetailsFormRef.current) {
  //     ServiceDetailsFormRef.current.setFieldValue(`distinctLanes.[${currentOrderType}].dropoff_vendor_point_id`, (val?.dropoff_id || ""))
  //   }
  //   setCurrentOrderType(-1);
  // }

  const showPickupDate = (originCountry: string) => {
    let countryArr = ['SG', 'TH']
    if(countryArr.includes(originCountry)) {
      return true
    } else {
      return false
    }
  }

  const renderDropoffAddressData = (id:any, locations: any) => {
    const data = locations?.filter((location: any) => {
      return location.dropoff_id === parseInt(id)
    });

    return (
      <div className="dropoff-gray-text warehouse-summary">
        <p><strong>Address Information</strong></p>
        <p><span>Address: </span>{data && data[0]?.dropoff_address}</p>
        <p><span>Postal Code: </span>{data && data[0]?.dropoff_postal}</p>
        <p><span>State: </span>{data && data[0]?.dropoff_point_state}</p>
        <p><span>City: </span>{data && data[0]?.dropoff_point_city}</p>

        <p style={{margin: '10px 0 0 0', fontSize: '16px'}}><strong>Contact Person</strong></p>
        <p><span>Name: </span>{data && data[0]?.dropoff_point_contact_person}</p>
        <p><span>Phone: </span>{data && data[0]?.dropoff_point_number}</p>
      </div>
    )
  };

  const LaneForm = ({index, orderType, fName, formik}:{index:number, orderType: any, fName:string, formik:any}) =>{
    return (
      <div key={index} ref={r => { ServiceDetailsFormRef.current = ServiceDetailsFormRef.current ? ServiceDetailsFormRef.current : r; }}>
        <F.Item
          label={`${orderType.origin} to ${orderType.destination} : ${orderType.count} ${getText('shipments in total')}`}
          className={"single-order-form-title"}>
        </F.Item>
        <Row gutter={14}>
          <Col span={12}>
            {/*{AuthTokenFlag() ? */}
            <FormSelect name={`distinctLanes.[${index}].service_type`}
              label={getText('Service Type')}
              onChangeCallback={(value: any) => {
                formik.setFieldValue(`distinctLanes.[${index}].dropoff_vendor_point_id`, '');
                formik.setFieldValue(`distinctLanes.[${index}].pickup_address_id`, '');
              }}
              placeholder={'Select...'}>
              {
                orderType.services?.map((type: any, index: number) => {
                  if(auth.user.payment_type === 'PayInstantly') {
                    return (type !== 'CC_LM') && <Option key={index} value={type}>{getFormattedServiceTypeText(type)}</Option>
                  } else {
                    return <Option key={index} value={type}>{getFormattedServiceTypeText(type)}</Option>
                  }
                })
              }
            </FormSelect>
            {/* :<FormSelect name={`distinctLanes.[${index}].service_type`}
                   label={getText('Service Type')}
                   placeholder={'Select...'}>
                   <Option value={'Pickup'}>Pickup</Option>
                 </FormSelect> }
            */}
          </Col>

          {/* condition when service type is pickup or pickup_express */}
          {((isPickupServiceEnabled(formik.values.distinctLanes[index].service_type)) || (formik.values.distinctLanes[index].service_type && !existingServices.includes(formik.values.distinctLanes[index].service_type))) &&
            <Col span={12}>
              <FormSelect name={`distinctLanes.[${index}].pickup_address_id`}
                label={getText('Pickup Address')}
                wrapperClass="no-margin-item"
                placeholder={'Select an address'}
                showSearch={'false'}
                loading={pickupLocationsFromb2c.isLoading}>
                {
                  
                  pickupLocationsFromb2c.data?.filter((l: { pickup_point_country?: string; }) => countryCodes[l.pickup_point_country!] === countryCodes[orderType.origin])
                    .map((item:any) => <Option key={item.pickup_point_id} value={item.pickup_point_id}>{`${item.pickup_point_name} - ${item.pickup_point_address}`}</Option>)
                }
              </FormSelect>
              <F.Item className={'no-margin-item'}>
                <Button type="primary" onClick={() => {setShowAddAddressModal(true); setCurrentOrderType(index); setAddressType('pickup')}}
                  className={"no-border-btn"}>
                  {getText('Add new address')}
                </Button>
                {(showAddAddressModal && currentOrderType===index && addressType==='pickup') && <AddAddressModal setShowAddAddressModalCallback={setShowAddAddressModalCallback} addressType={'pickup'} countryCode={orderType.origin}/>}
              </F.Item>
            </Col>
          }

          {/* condition when service type selected is dropoff PUDO */}
          {formik.values.distinctLanes[index].service_type === 'Dropoff' &&
            <Col span={12}>
              <FormSelect name={`distinctLanes.[${index}].dropoff_vendor_point_id`}
                label={<label>{'Drop-off Location'} <EnvironmentOutlined style={{color: 'red', fontSize: '14px', position: 'relative', top: '1px'}} /></label>}
                wrapperClass="no-margin-item"
                placeholder={'Select a drop-off location'}
                // loading={getDropOffLocations.isLoading}
              >
                {/* {
                  getDropOffLocations&&sortPUDOPointsByPostal(getDropOffLocations.data?.dropoff_locations)?.map((item:any) => <Option className={"dropoff-dropdown"} key={item.dropoff_id} value={item.dropoff_id}>{`${item.name} - ${item.address}, ${item.postal}`}</Option>)
                } */}
                {
                  pickUpContext&&sortPUDOPointsByPostal(pickUpContext?.getDropOffLocations[fName])?.map((item:any, index: any) => <Option className={"dropoff-dropdown"} key={index} value={item.dropoff_id}>{`${item.dropoff_point_contact_person} - ${item.dropoff_address}, ${item.dropoff_postal}`}</Option>)
                }
              </FormSelect>
              {/* <F.Item className={'no-margin-item'}>
                <Button type="primary"
                  onClick={() => {
                    setShowMapModal(true)
                    setCurrentOrderType(index)
                    setAddressType('dropoff')
                  }}
                  className={"no-border-btn"}>
                  {'See and select from a map'}
                </Button>
                {(showMapModal && currentOrderType===index && addressType === 'dropoff') &&
                <DropOffAddressModal setSelectedDropOffPoint={handleSelectedDropOffPointChangeFromMap} setShowMapModalCallback={setShowMapModalCallback} dropOffLocations={pickUpContext?.getDropOffLocations[fName]} showModal={showMapModal}/>}
              </F.Item> */}
            </Col>
          }
          {
            (formik.values.distinctLanes[index].service_type === 'Dropoff' && formik.values.distinctLanes[index].dropoff_vendor_point_id!) &&
               <div className="dropoff-gray-text">
                 <p><strong>Drop-off Address</strong></p>
                 <p>{getDropOffAddressDataForBulkOrder(formik.values.distinctLanes[index].dropoff_vendor_point_id!, pickUpContext?.getDropOffLocations[fName]).type}</p>
                 <p>{getDropOffAddressDataForBulkOrder(formik.values.distinctLanes[index].dropoff_vendor_point_id!, pickUpContext?.getDropOffLocations[fName]).address}</p>
                 <p>
                   <span>{getDropOffAddressDataForBulkOrder(formik.values.distinctLanes[index].dropoff_vendor_point_id!, pickUpContext?.getDropOffLocations[fName]).dropOff_country}</span>
                   <span> - {getDropOffAddressDataForBulkOrder(formik.values.distinctLanes[index].dropoff_vendor_point_id!, pickUpContext?.getDropOffLocations[fName]).dropOff_postal}</span>
                 </p>
                 {/* <p>{getDropOffAddressDataForBulkOrder(formik.values.distinctLanes[index].dropoff_vendor_point_id!, getDropOffLocations.data?.dropoff_locations).opening_hours?.join(', ')}</p> */}
               </div>
          }

          {/* condition when service type selected is dropoff warehouse OR dropoff_express */}
          {(formik.values.distinctLanes[index].service_type === 'Dropoff_WH'|| formik.values.distinctLanes[index].service_type === 'Dropoff Express' || formik.values.distinctLanes[index].service_type === 'Dropoff Express Priority') &&
            <Col span={12}>
              <FormSelect name={`distinctLanes.[${index}].dropoff_vendor_point_id`}
                label={<label>{'Warehouse Drop-off Address'} <EnvironmentOutlined style={{color: 'red', fontSize: '14px', position: 'relative', top: '1px'}} /></label>}
                wrapperClass="no-margin-item"
                placeholder={'Select a drop-off location'}
                // loading={getDropOffLocations.isLoading}
              >
                {
                  getWarehouseDropOffPoints((pickUpContext.lanes as any)[index].origin, (pickUpContext.lanes as any)[index].destination, 'Dropoff')?.map((item:any) => item.dropoff_address &&  <Option className={"dropoff-dropdown"} key={item.dropoff_address} value={item.dropoff_id}>{`${item.service_id ? item.dropoff_address : 'No Data'}`}</Option>)
                }
              </FormSelect>
            </Col>
          }
          {/* Drop-off warehouse summary */}
          {
            ((formik.values.distinctLanes[index].service_type === 'Dropoff_WH'|| formik.values.distinctLanes[index].service_type === 'Dropoff Express' || formik.values.distinctLanes[index].service_type === 'Dropoff Express Priority') &&formik.values.distinctLanes[index].dropoff_vendor_point_id!) &&
            renderDropoffAddressData(formik.values.distinctLanes[index].dropoff_vendor_point_id!, getServiceDefinitionsDataFromb2c?.data!)
          }

          {/* condition when service type selected is CCLM */}
          {(formik.values.distinctLanes[index].service_type === 'Customs clearance and last mile') &&
            <Col span={12}>
              <FormSelect name={`distinctLanes.[${index}].dropoff_vendor_point_id`}
                label={<label>{'Pickup Address'} <EnvironmentOutlined style={{color: 'red', fontSize: '14px', position: 'relative', top: '1px'}} /></label>}
                wrapperClass="no-margin-item"
                placeholder={'Select a drop-off location'}
                // loading={getDropOffLocations.isLoading}
              >
                {
                  getWarehouseDropOffPoints((pickUpContext.lanes as any)[index].origin, (pickUpContext.lanes as any)[index].destination, 'Customs clearance and last mile')?.map((item:any) => item.dropoff_address && <Option className={"dropoff-dropdown"} key={item.dropoff_address} value={item.dropoff_id}>{`${item.service_id ? item.dropoff_address : 'No Data'}`}</Option>)
                }
              </FormSelect>
            </Col>
          }

          {/* CC_LM summary */}
          {
            (formik.values.distinctLanes[index].service_type === 'Customs clearance and last mile' &&formik.values.distinctLanes[index].dropoff_vendor_point_id!) &&
              renderDropoffAddressData(formik.values.distinctLanes[index].dropoff_vendor_point_id!, getServiceDefinitionsDataFromb2c?.data!)
          }
        </Row>
        {((isPickupServiceEnabled(formik.values.distinctLanes[index].service_type)) || (formik.values.distinctLanes[index].service_type && !existingServices.includes(formik.values.distinctLanes[index].service_type)) ) && showPickupDate(countryCodes[orderType.origin]) &&
        <Row gutter={14}>
          <Col span={12}>
            <>
              <DatePicker
                disabled={canChoosePickupDate(countryCodes[orderType.origin])} 
                disabledDate={disabledDate} 
                showToday={false} 
                name={`distinctLanes.[${index}].pickup_date`} 
                label={orderType.origin === 'Singapore' ? 'Pickup Date' : getText('Pickup Date (Optional)')} 
                wrapperClass="no-margin-item"
              />
              <F.Item label={canChoosePickupDate(countryCodes[orderType.origin])? `Your order will be picked up within ${PickupDays[countryCodes[orderType.origin]]} working days`:getText('If not selected, it will be pickup the next business day')} className={'form-item-info'}/>
            </>
          </Col>
          <Col span={12}>
            <>
              <FormInput name={`distinctLanes.[${index}].pickup_time`} label={<label className="info-disclaimer">{getText('Pickup Time')}:<Tooltip title={getText('If order is created before 8 AM then pickup will be same day else next day')}><InfoCircleOutlined />
              </Tooltip></label>} wrapperClass="no-margin-item" inputProps={{disabled:true}} />
              <F.Item label={getText('Timing are fixed and cannot be selected')} className={'form-item-info'}/>
            </>
          </Col>
        </Row>
        }
        {(orderType.origin === 'Singapore' && (formik.values.distinctLanes[index].service_type === 'Pickup' || formik.values.distinctLanes[index].service_type === 'Pickup_Express'))&&<PickupChargeAlert/>
        }
        {index + 1 < pickUpContext.lanes.length && <Divider/>}
      </div>
    )
  };

  const initialValues = {
    distinctLanes: bulkOrderContext.pickUpFormContext.form ?bulkOrderContext.pickUpFormContext.form :
      pickUpContext.lanes.map((lane:any)=>{
        return {...empty_lane(lane)}
      })
  };

  const getWarehouseDropOffPoints = (originCountry?: string, destinationCountry?: string, serviceType?: string) => {
    if(getServiceDefinitionsDataFromb2c.data) {
      return filterOnlyRequiredDataFromB2c(getServiceDefinitionsDataFromb2c.data, originCountry, destinationCountry, serviceType)  
    }
    return []
  }

  return (
    <>
      {current === 1 ? <div className={"single-order-form-container ant-form-vertical"} ref={r => { ServiceDetailsFormRef.current = ServiceDetailsFormRef.current ? ServiceDetailsFormRef.current : r; }}>
        <Formik
          innerRef={ServiceDetailsFormRef}
          validationSchema={validationSchema(getText)}
          initialValues={initialValues}
          onSubmit={onFormSubmit}
          validateOnChange={true}
        >
          {formik =>(
            <Form>
              {verifyCountry('Vietnam', bulkOrderContext.parsedJson.consolidatedOrderItems) && <Alert
                message={<p style={{marginBottom:0}}>{getText('Please take note that you cannot ship')} <a href={prohibitedItemsToVietnam} style={{fontWeight: "bold",color: '#050593',
                  textDecoration: 'underline'}} target={"_blank"} rel="noreferrer">{getText('these')}</a> {getText('prohibited and restricted goods to')} <strong>Vietnam</strong>.</p>}
                type="info"
                showIcon
                style={{marginBottom:24}}
              />}
              {verifyCountry('Indonesia', bulkOrderContext.parsedJson.consolidatedOrderItems) && <Alert
                message={<p style={{marginBottom:0}}>{getText('Please take note that you cannot ship')} <a href={prohibitedItemsToIndonesia} style={{fontWeight: "bold",color: '#050593',
                  textDecoration: 'underline'}} target={"_blank"} rel="noreferrer">{getText('these')}</a> {getText('prohibited and restricted goods to')} <strong>Indonesia</strong>.</p>}
                type="info"
                showIcon
                style={{marginBottom:24}}
              />}
              <p><strong>{getCountOfShipments()?getCountOfShipments():0} shipments in total</strong></p>
              <FieldArray name="distinctLanes"
                render={() => (
                  <>
                    {
                      pickUpContext.lanes?.map((orderType: any, index: number) => {
                        const fName = `${orderType.origin}-${orderType.destination}`;
                        return <LaneForm key={index} index={index} orderType={orderType} fName={fName} formik={formik}/>
                      })}</>
                )}/>
              <div className={"button-block"}>
                <Button htmlType="submit" type="primary" className={"blue-button"}>
                  {getText('Save & Continue')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
        :
        <>
          {pickUpContext.lanes &&
                    <Card title={"Shipment Overview"} className={"form-summary-container"}>
                      {
                        pickUpContext.lanes?.map((order: any,index:any) => {
                          return <div style={{marginBottom: 10}}>
                            <p style={{margin: 0}}>{`${order.origin} to ${order.destination} : ${order.count} ${getText('shipments in total')}`}</p>
                            <p className={'gray-text'}>{getFormattedServiceTypeText(bulkOrderContext.pickUpFormContext?.form[index].service_type)} service</p>
                          </div>
                        })
                      }
                    </Card>
          }
        </>
      }
    </>
  )
};
export const PickUpDetailsFormTitle = (props: PropsType) => {
  let { getText } = useLanguage();
  const {current, setCurrentState} = props;
  const bulkOrderContext = useBulkOrder();
  return (
    <div className={"title-block"}>
      <p className={'step-title'}>{getText('Where are we picking up from?')}</p>
      {current > 0 && (!bulkOrderContext.canEditOrder) ? <p onClick={() => setCurrentState(1)} className={"edit-text"}>{getText('Edit details')}</p> : ''}
    </div>
  )
};