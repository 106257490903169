import { useState, useContext, createContext, ReactNode } from "react"
import {instance as api} from "../../commons/api";
import { instanceForb2c as apib2c } from '../../commons/api'
import { countryCallingCodes, countryCodes } from "../../commons/utils/constants";

interface CreateOrderResult {
  order: SingleOrder;
  rate: {
    total: number;
    currency: string;
  }
}

interface SingleOrderContextForB2C {
  singleOrder: SingleOrderB2C
  updateSingleOrderDataB2C: (values: any) => any
  submitSingleOrderB2C: (token: string) => Promise<CreateOrderResult>
  submitMarketplaceOrderB2C: (token: string, orderId: number) => Promise<CreateOrderResult>
  UpdateSingleOrderB2C: () => Promise<any>
  UpdateSingleOrderB2C_Shopify: () => Promise<any>
  CheckOrderPaymentStatusB2C: (token: string, orderId: number) => Promise<CreateOrderResult>
  setErrors: (error: OrderError) => any
  errors: OrderError
  updateErrorInPriceEstimate: (values: boolean) => any
  errorInPriceEstimate: boolean
}
interface SingleOrderContext {
  singleOrder: SingleOrder
  updateSingleOrderData: (values: any) => any
  submitSingleOrder: (token: string) => Promise<CreateOrderResult>
  submitMarketplaceOrder: (token: string, orderId: number) => Promise<CreateOrderResult>
  UpdateSingleOrder: (token: string, orderId: number) => Promise<CreateOrderResult>
  CheckOrderPaymentStatus: (token: string, orderId: number) => Promise<CreateOrderResult>
  setErrors: (error: OrderError) => any
  errors: OrderError
  formContext: FormContext
  setFormContext: (values: FormContext) => any
}


interface Service {
  service_id: string | number,
  pickup_country: string,
  consignee_country: string,
  service_category: string,
  service_name: string,
  allow_cod: boolean,
  allow_pickup: boolean,
  pickup_contact_name: string | null,
  pickup_contact_number: string | null,
  pickup_state: string | null,
  pickup_city: string | null,
  pickup_province: string | null,
  pickup_address: string | null,
  pickup_postal: string | null
}
interface ServiceDefinition {
  dropoff_id?: number,
  dropoff_address?: string,
  dropoff_postal?: string,
  dropoff_point_country?: string,
  dropoff_point_state?: string,
  dropoff_point_city?: string,
  dropoff_point_province?: string,
  dropoff_point_contact_person?: string,
  dropoff_point_number?: string,
  source?: string,
  additional_data?: string,
  service_id?: string | number,
  origin_country?: string,
  destination_country?: string,
  service_type?: string,
  exclusive_agents?: [
    
  ],
  exclusive_agents_ids?: [
    
  ]
}
interface PickupPoint {
  pickup_point_id: string | number,
  pickup_point_name: string,
  pickup_point_address: string,
  pickup_point_country: string,
  pickup_point_state: string,
  pickup_point_city: string,
  pickup_point_province: string,
  pickup_point_postal: string,
  pickup_point_contact_person: string,
  pickup_point_number: string,
  pickup_point_email: string,
  pickup_point_notes: string,
  is_active: boolean
}
export interface ItemsB2C {
  item_desc: string,
  item_category: string,
  item_price_currency: string,
  item_product_id?: string,
  item_quantity: string | number,
  item_price_value: string,
  item_sku: string,
  item_url: string
}
interface SingleOrderB2C {
  id?: string | number,
  order_id?: string | number,
  submitted: boolean,
  pickup_country: string,
  consignee_country: string,
  service_id: string | number,
  pickup_point_id: string | number,
  pickup_address: string,
  service_level: string,
  service: Service,
  serviceDefinition?: ServiceDefinition,
  pickup_state: string,
  pickup_city: string,
  pickup_postal: string,
  pickup_point: PickupPoint,
  serviceType: string,
  pickup_notes: string,
  pickup_date: string,
  consignee_name: string,
  consignee_email: string,
  consignee_number: string,
  consignee_state: string,
  consignee_province: string,
  consignee_postal: string,
  consignee_address: string,
  consignee_address1: string,
  consignee_city: string,
  consignee_address_id: string | number,
  consignee_identifcation_number?: string,
  identification_document_name?: string,
  items: ItemsB2C[],
  item_price_currency: string,
  shipper_order_id: string,
  declaration_checkbox: true,
  indonesia_npwp: string,
  order_weight: number | null,
  order_height: number | null,
  order_width: number | null,
  order_length: number | null,
  payment_type: string,
  cod_amt_to_collect_currency: string,
  cod_amt_to_collect: string | number,
  incoterm: string,
  tracking_no: string,
  allow_hyphen_in_tracking_number: boolean,
  special_instruction: string,
  secret_key: string,
  PriceEstimates?: AccountChargesForB2c[],
  pickup_start_time?: string,
  pickup_end_time?: string,
  delivery_start_time?: string,
  delivery_end_time?: string,
  validation_result?: ValiationResultForMarketplace,
}
interface SingleOrder {
  id?: number
  tracking_no?: string
  custom_tn?: any
  consignee_name: string
  consignee_address: string
  consignee_country_code: string
  consignee_country?: string
  consignee_state: string
  consignee_city: string
  consignee_number: string
  consignee_postal: string
  consignee_email: string
  consignee_id?: string
  consignee_id_type?: string
  dropoff_vendor_point_id: number | null
  dropoff_vendor_name?: string
  pickup_contact_name: string
  pickup_contact_number: string
  pickup_address: string
  pickup_state: string
  pickup_city: string
  pickup_province: string
  pickup_postal: string
  pickup_country_code: string
  pickup_country?: string
  pickup_date: string | null
  pickup_location: number | null
  billing_location: number | null
  billing_contact_name: string
  billing_contact_number: string
  billing_address: string
  billing_state: string
  billing_city: string
  billing_postal: string
  billing_country_code: string
  order_id?: number
  order_length: string | null
  order_width: string | null
  order_height: string | null
  order_weight: string | null
  payment_type: string
  cod_amount?: number | null
  incoterm?:string
  cod_currency?:string
  payment_status: string
  currency: string
  amount: any | null
  amount_to_pay: any | null
  items: Item[]
  gst_amount: string
  total_duties: string
  total_taxes: string
  shipping_charge: string
  total: any | null
  is_tax_payable: boolean
  charge_id: number | null
  pickup_charge?: string | null
  account_charges?: AccountCharges[]
}

interface FormContext{
  service_type: string | null
}

export interface Item {
  item_desc: string
  item_category: string
  item_quantity: string | null
  item_price_currency: string
  item_price_value: string | null
  product_id?: string
  item_sku: string
  gst_ovr: string
  gst_collected : string
}

export interface AccountCharges {
  account_charge_id: number
  amount: string
  currency: string
  type: string
}
export interface AccountChargesForB2c {
  total: number,
  currency: string,
  weight_upper_bound: string,
  chargable_weight: string,
  total_taxes: number,
  total_duties: number,
  gst_amount: number,
  shipping_charge: string,
  duties_and_taxes: number
}

interface ValiationResultForMarketplace{
  errors: any,
  params?: any
}

interface ItemErrorB2C {
  item_desc?: string,
  item_category?: string,
  item_price_currency?: string,
  item_product_id?: string,
  item_quantity?: string,
  item_price_value?: string,
  item_sku?: string,
  item_url?: string,
  gst_ovr?: string
  gst_collected? : string
}

interface ItemServerErrorB2C {
  item_desc?: Array<string>
  item_category?: Array<string>
  item_quantity?: Array<string>
  item_price_currency?: Array<string>
  item_price_value?: Array<string>
  item_product_id?: Array<string>
  item_sku?: Array<string>
  gst_ovr?: Array<string>
  gst_collected?: Array<string>
  item_url?: Array<string>,
}
interface ItemError {
  item_desc?: string
  item_category?: string
  item_quantity?: string
  item_price_currency?: string
  item_price_value?: string
  product_id?: string
  item_sku?: string
  gst_ovr?: string
  gst_collected? : string
}

interface ItemNonFieldError extends ItemError {
  non_field_errors?: string
}

interface ItemServerError {
  item_desc?: Array<string>
  item_category?: Array<string>
  item_quantity?: Array<string>
  item_price_currency?: Array<string>
  item_price_value?: Array<string>
  product_id?: Array<string>
  item_sku?: Array<string>
  gst_ovr?: Array<string>
  gst_collected?: Array<string>
}

interface ItemServerNonFieldError extends ItemServerError {
  non_field_errors?: Array<string>
}

interface PickupPointErrorB2C {
  pickup_point_name?: string,
  pickup_point_address?: string,
  pickup_point_country?: string,
  pickup_point_state?: string,
  pickup_point_city?: string,
  pickup_point_province?: string,
  pickup_point_postal?: string,
  pickup_point_contact_person?: string,
  pickup_point_number?: string,
  pickup_point_email?: string,
  pickup_point_notes?: string
}
interface PickupPointServerErrorB2C {
  pickup_point_name?: Array<string>,
  pickup_point_address?: Array<string>,
  pickup_point_country?: Array<string>,
  pickup_point_state?: Array<string>,
  pickup_point_city?: Array<string>,
  pickup_point_province?: Array<string>,
  pickup_point_postal?: Array<string>,
  pickup_point_contact_person?: Array<string>,
  pickup_point_number?: Array<string>,
  pickup_point_email?: Array<string>,
  pickup_point_notes?: Array<string>
}
interface ServiceErrorB2C {
  service_id: number,
  pickup_country: string,
  consignee_country:string,
  service_category: string,
  service_name: string,
  allow_cod: boolean,
  allow_pickup: boolean,
  pickup_contact_name?: string,
  pickup_contact_number?: string,
  pickup_state?: string,
  pickup_city?: string,
  pickup_province?: string,
  pickup_address?: string,
  pickup_postal?: string
}
interface ServiceServerErrorB2C {
  service_id: Array<number>,
  pickup_country: Array<string>,
  consignee_country: Array<string>,
  service_category: Array<string>,
  service_name: Array<string>,
  allow_cod: Array<boolean>,
  allow_pickup: Array<boolean>,
  pickup_contact_name?: Array<string>,
  pickup_contact_number?: Array<string>,
  pickup_state?: Array<string>,
  pickup_city?: Array<string>,
  pickup_province?: Array<string>,
  pickup_address?: Array<string>,
  pickup_postal?: Array<string>
}
interface ServiceDefinitionErrorB2C {
  service_id: number,
  origin_country: string,
  destination_country: string,
  service_type: string,
  exclusive_agents: [
    
  ],
  exclusive_agents_ids: [
    
  ]
}
interface ServiceDefinitionServerErrorB2C {
  service_id: Array<number>,
  origin_country: Array<string>,
  destination_country: Array<string>,
  service_type: Array<string>,
  exclusive_agents: [
    
  ],
  exclusive_agents_ids: [
    
  ]
}

export interface OrderErrorB2C {
  submitted?: boolean,
  pickup_country?: string,
  consignee_country?: string,
  service_id?: number,
  pickup_point_id?: number,
  pickup_address?: string,
  service_level?: string,
  service?: ServiceErrorB2C,
  serviceDefinition?: ServiceDefinitionErrorB2C,
  pickup_state?: string,
  pickup_city?: string,
  pickup_postal?: string,
  pickup_point?: PickupPointErrorB2C,
  serviceType?: string,
  pickup_notes?: string,
  pickup_date?: string,
  consignee_name?: string,
  consignee_email?: string,
  consignee_number?: string,
  consignee_state?: string,
  consignee_province?: string,
  consignee_postal?: string,
  consignee_address?: string,
  consignee_address1?: string,
  consignee_city?: string,
  consignee_address_id?: number,
  consignee_identifcation_number?: string,
  identification_document_name?: string,
  items?: Array<ItemErrorB2C>,
  item_price_currency?: string,
  shipper_order_id?: string,
  declaration_checkbox?: true,
  indonesia_npwp?: string,
  order_weight?: string,
  order_height?: string,
  order_width?: string,
  order_length?: string,
  payment_type?: string,
  cod_amt_to_collect_currency?: string,
  cod_amt_to_collect?: number,
  incoterm?: string,
  tracking_no?: string,
  allow_hyphen_in_tracking_number?: boolean,
  special_instruction?: string,
  secret_key?: string
}
export interface OrderError {
  tracking_no?: string
  consignee_name?: string
  custom_tn?: any
  service_type?: string
  consignee_address?: string
  consignee_country_code?: string
  consignee_state?: string
  consignee_city?: string
  consignee_number?: string
  consignee_postal?: string
  consignee_email?: string
  consignee_id?: string
  consignee_id_type?: string
  dropoff_vendor_point_id?: number | null
  dropoff_vendor_name?: string
  pickup_contact_name?: string
  pickup_contact_number?: string
  pickup_address?: string
  pickup_state?: string
  pickup_city?: string
  pickup_province?: string
  pickup_postal?: string
  pickup_country_code?: string
  pickup_country?: string
  pickup_date?: string
  billing_contact_name?: string
  billing_contact_number?: string
  billing_address?: string
  billing_state?: string
  billing_city?: string
  billing_postal?: string
  billing_country_code?: string
  order_length?: string
  order_width?: string
  order_height?: string
  order_weight?: string
  payment_type?: string
  items?: Array<ItemError>
  cod_amount?: number
  incoterm?:string
  cod_currency?:string
}

interface ServerErrorsB2C {
  submitted: Array<boolean>,
  pickup_country: Array<string>,
  consignee_country: Array<string>,
  service_id: Array<number>,
  pickup_point_id: Array<number>,
  pickup_address: Array<string>,
  service_level: Array<string>,
  service: ServiceServerErrorB2C,
  serviceDefinition: ServiceDefinitionServerErrorB2C,
  pickup_state: Array<string>,
  pickup_city: Array<string>,
  pickup_postal: Array<string>,
  pickup_point: PickupPointServerErrorB2C,
  serviceType: Array<string>,
  pickup_notes: Array<string>,
  pickup_date: Array<string>,
  consignee_name: Array<string>,
  consignee_email: Array<string>,
  consignee_number: Array<string>,
  consignee_state: Array<string>,
  consignee_province: Array<string>,
  consignee_postal: Array<string>,
  consignee_address: Array<string>,
  consignee_address1: Array<string>,
  consignee_city: Array<string>,
  consignee_address_id: Array<number>,
  consignee_identifcation_number?: Array<string>,
  identification_document_name?: Array<string>,
  items: {[key: number] : ItemServerErrorB2C},
  item_price_currency: Array<string>,
  shipper_order_id: Array<string>,
  declaration_checkbox: Array<boolean>,
  indonesia_npwp: Array<string>,
  order_weight: Array<string>,
  order_height: Array<string>,
  order_width: Array<string>,
  order_length: Array<string>,
  payment_type: Array<string>,
  cod_amt_to_collect_currency: Array<string>,
  cod_amt_to_collect: Array<number>,
  incoterm?: Array<string>,
  tracking_no: Array<string>,
  allow_hyphen_in_tracking_number: Array<boolean>,
  special_instruction: Array<string>,
  secret_key: Array<string>
}
interface ServerErrors {
  tracking_no?: Array<string>
  consignee_name?: Array<string>
  custom_tn?: Array<string>
  service_type?: Array<string>
  consignee_address?: Array<string>
  consignee_country_code?: Array<string>
  consignee_state?: Array<string>
  consignee_city?: Array<string>
  consignee_number?: Array<string>
  consignee_postal?: Array<string>
  consignee_email?: Array<string>
  consignee_id?: Array<string>
  consignee_id_type?: Array<string>
  dropoff_vendor_point_id?: Array<number>
  dropoff_vendor_name?: Array<string>
  pickup_contact_name?: Array<string>
  pickup_contact_number?: Array<string>
  pickup_address?: Array<string>
  pickup_state?: Array<string>
  pickup_city?: Array<string>
  pickup_province?: Array<string>
  pickup_postal?: Array<string>
  pickup_country_code?: Array<string>
  pickup_country?: Array<string>
  pickup_date?: Array<string>
  order_length?: Array<string>
  order_width?: Array<string>
  order_height?: Array<string>
  order_weight?: Array<string>
  payment_type?: Array<string>
  items: {[key: number] : ItemServerNonFieldError}
  cod_amount?: Array<number>
  incoterm?:Array<string>
  cod_currency?:Array<string>
}

export const combineFieldsErrorsFromB2C = (serverErrors: ServerErrorsB2C) => {
  let errors: OrderErrorB2C = {}
  if(serverErrors.items){
    errors.items = []
    let itemErrors = formatItemErrors(serverErrors)
    Object.keys(itemErrors).forEach((key: any) => {
    // @ts-ignore
      errors.items[key] = itemErrors[key]
    })
  }
  if(serverErrors.tracking_no){
    errors.tracking_no = serverErrors.tracking_no.join(',');
  }
  if(serverErrors.consignee_name){
    errors.consignee_name = serverErrors.consignee_name.join(',');
  }
  if(serverErrors.consignee_address){
    errors.consignee_address = serverErrors.consignee_address.join(',');
  }
  if(serverErrors.consignee_country){
    errors.consignee_country = serverErrors.consignee_country.join(',');
  }
  if(serverErrors.consignee_state){
    errors.consignee_state = serverErrors.consignee_state.join(',');
  }
  if(serverErrors.consignee_city){
    errors.consignee_city = serverErrors.consignee_city.join(',');
  }
  if(serverErrors.consignee_number){
    errors.consignee_number = serverErrors.consignee_number.join(',');
  }
  if(serverErrors.consignee_postal){
    errors.consignee_postal = serverErrors.consignee_postal.join(',');
  }
  if(serverErrors.consignee_email){
    errors.consignee_email = serverErrors.consignee_email.join(',');
  }
  if(serverErrors.identification_document_name){
    errors.identification_document_name = serverErrors.identification_document_name.join(',');
  }
  if(serverErrors.consignee_identifcation_number){
    errors.consignee_identifcation_number = serverErrors.consignee_identifcation_number.join(',');
  }
  if(serverErrors.pickup_point.pickup_point_name){
    errors.pickup_point!.pickup_point_name = serverErrors.pickup_point.pickup_point_name.join(',');
  }
  if(serverErrors.pickup_point.pickup_point_number){
    errors.pickup_point!.pickup_point_number = serverErrors.pickup_point.pickup_point_number.join(',');
  }
  if(serverErrors.pickup_point.pickup_point_address){
    errors.pickup_point!.pickup_point_address = serverErrors.pickup_point.pickup_point_address.join(',');
  }
  if(serverErrors.pickup_point.pickup_point_state){
    errors.pickup_point!.pickup_point_state = serverErrors.pickup_point.pickup_point_state.join(',');
  }
  if(serverErrors.pickup_point.pickup_point_city){
    errors.pickup_point!.pickup_point_city = serverErrors.pickup_point.pickup_point_city.join(',');
  }
  if(serverErrors.pickup_point.pickup_point_postal){
    errors.pickup_point!.pickup_point_postal = serverErrors.pickup_point.pickup_point_postal.join(',');
  }
  if(serverErrors.pickup_country){
    errors.pickup_country = serverErrors.pickup_country.join(',');
  }
  if(serverErrors.pickup_date){
    errors.pickup_date = serverErrors.pickup_date.join(',');
  }
  if(serverErrors.serviceType){
    errors.serviceType = serverErrors.serviceType.join(',');
  }
  if(serverErrors.order_length){
    errors.order_length = serverErrors.order_length.join(',');
  }
  if(serverErrors.order_width){
    errors.order_width = serverErrors.order_width.join(',');
  }
  if(serverErrors.order_height){
    errors.order_height = serverErrors.order_height.join(',');
  }
  if(serverErrors.order_weight){
    errors.order_weight = serverErrors.order_weight.join(',');
  }
  if(serverErrors.payment_type){
    errors.payment_type = serverErrors.payment_type.join(',');
  }

  return errors
}
export const combineFieldsErrors = (serverErrors: ServerErrors) => {
  let errors: OrderError = {}
  if(serverErrors.items){
    errors.items = []
    let itemErrors = formatItemErrors(serverErrors)
    Object.keys(itemErrors).forEach((key: any) => {
    // @ts-ignore
      errors.items[key] = itemErrors[key]
    })
  }
  if(serverErrors.tracking_no){
    errors.tracking_no = serverErrors.tracking_no.join(',');
  }
  if(serverErrors.consignee_name){
    errors.consignee_name = serverErrors.consignee_name.join(',');
  }
  if(serverErrors.custom_tn){
    errors.custom_tn = serverErrors.custom_tn.join(',');
  }
  if(serverErrors.consignee_address){
    errors.consignee_address = serverErrors.consignee_address.join(',');
  }
  if(serverErrors.consignee_country_code){
    errors.consignee_country_code = serverErrors.consignee_country_code.join(',');
  }
  if(serverErrors.consignee_state){
    errors.consignee_state = serverErrors.consignee_state.join(',');
  }
  if(serverErrors.consignee_city){
    errors.consignee_city = serverErrors.consignee_city.join(',');
  }
  if(serverErrors.consignee_number){
    errors.consignee_number = serverErrors.consignee_number.join(',');
  }
  if(serverErrors.consignee_postal){
    errors.consignee_postal = serverErrors.consignee_postal.join(',');
  }
  if(serverErrors.consignee_email){
    errors.consignee_email = serverErrors.consignee_email.join(',');
  }
  if(serverErrors.consignee_id){
    errors.consignee_id = serverErrors.consignee_id.join(',');
  }
  if(serverErrors.consignee_id_type){
    errors.consignee_id_type = serverErrors.consignee_id_type.join(',');
  }
  if(serverErrors.pickup_contact_name){
    errors.pickup_contact_name = serverErrors.pickup_contact_name.join(',');
  }
  if(serverErrors.pickup_contact_number){
    errors.pickup_contact_number = serverErrors.pickup_contact_number.join(',');
  }
  if(serverErrors.pickup_address){
    errors.pickup_address = serverErrors.pickup_address.join(',');
  }
  if(serverErrors.pickup_state){
    errors.pickup_state = serverErrors.pickup_state.join(',');
  }
  if(serverErrors.pickup_city){
    errors.pickup_city = serverErrors.pickup_city.join(',');
  }
  if(serverErrors.pickup_province){
    errors.pickup_province = serverErrors.pickup_province.join(',');
  }
  if(serverErrors.pickup_postal){
    errors.pickup_postal = serverErrors.pickup_postal.join(',');
  }
  if(serverErrors.pickup_country_code){
    errors.pickup_country_code = serverErrors.pickup_country_code.join(',');
  }
  if(serverErrors.pickup_date){
    errors.pickup_date = serverErrors.pickup_date.join(',');
  }
  if(serverErrors.service_type){
    errors.service_type = serverErrors.service_type.join(',');
  }
  if(serverErrors.order_length){
    errors.order_length = serverErrors.order_length.join(',');
  }
  if(serverErrors.order_width){
    errors.order_width = serverErrors.order_width.join(',');
  }
  if(serverErrors.order_height){
    errors.order_height = serverErrors.order_height.join(',');
  }
  if(serverErrors.order_weight){
    errors.order_weight = serverErrors.order_weight.join(',');
  }
  if(serverErrors.payment_type){
    errors.payment_type = serverErrors.payment_type.join(',');
  }

  return errors
}

export const formatItemErrorsFromB2C = (serverErrors: ServerErrorsB2C) => {
  let itemErrors : Array<ItemErrorB2C> = []
  if(serverErrors.items){
    Object.keys(serverErrors.items).forEach((key: any) => {
      let itemError: ItemErrorB2C = {}
      let val = serverErrors.items[key]
      if(val.item_desc){
        itemError.item_desc = val.item_desc.join(',');
      }
      if(val.item_category){
        itemError.item_category = val.item_category.join(',');
      }
      if(val.item_quantity){
        itemError.item_quantity = val.item_quantity.join(',');
      }
      if(val.item_price_currency){
        itemError.item_price_currency = val.item_price_currency.join(',');
      }
      if(val.item_price_value){
        itemError.item_price_value = val.item_price_value.join(',');
      }
      if(val.item_product_id){
        itemError.item_product_id = val.item_product_id.join(',');
      }
      if(val.item_url){
        itemError.item_url = val.item_url.join(',');
      }
      if(val.item_sku){
        itemError.item_sku = val.item_sku.join(',');
      }
      if(val.gst_ovr){
        itemError.gst_ovr = val.gst_ovr.join(',');
      }
      if(val.gst_collected){
        itemError.gst_collected = val.gst_collected.join(',');
      }
      itemErrors.push(itemError);
    })
  }
  return itemErrors;
}
export const formatItemErrors = (serverErrors: ServerErrors) => {
  let itemErrors : Array<ItemNonFieldError> = []
  if(serverErrors.items){
    Object.keys(serverErrors.items).forEach((key: any) => {
      let itemError: ItemNonFieldError = {}
      let val = serverErrors.items[key]
      if(val.item_desc){
        itemError.item_desc = val.item_desc.join(',');
      }
      if(val.item_category){
        itemError.item_category = val.item_category.join(',');
      }
      if(val.item_quantity){
        itemError.item_quantity = val.item_quantity.join(',');
      }
      if(val.item_price_currency){
        itemError.item_price_currency = val.item_price_currency.join(',');
      }
      if(val.item_price_value){
        itemError.item_price_value = val.item_price_value.join(',');
      }
      if(val.product_id){
        itemError.product_id = val.product_id.join(',');
      }
      if(val.item_sku){
        itemError.item_sku = val.item_sku.join(',');
      }
      if(val.gst_ovr){
        itemError.gst_ovr = val.gst_ovr.join(',');
      }
      if(val.gst_collected){
        itemError.gst_collected = val.gst_collected.join(',');
      }
      if(val.non_field_errors) {
        itemError.non_field_errors = val.non_field_errors.join(',');
      }
      itemErrors.push(itemError);
    })
  }
  return itemErrors;
}

const prependCountryCode = (country_code: string, phone_no: string) => {
  if(phone_no.startsWith('+')){
    return phone_no
  }
  return countryCallingCodes[country_code] + phone_no
}

const singleOrderContextForB2C = createContext<SingleOrderContextForB2C>({} as SingleOrderContextForB2C);
const singleOrderContext = createContext<SingleOrderContext>({} as SingleOrderContext);

export const SingleOrderContextProviderForB2C = ({children, order}: {children: ReactNode, order?: SingleOrderB2C}) => {
  let serverData = () => {
    if (order && order.id) {
      return order
    }
  }
  const singleOrder = useSingleOrderContextProviderForB2C(serverData());

  return (
    <singleOrderContextForB2C.Provider value={singleOrder}>
      {children}
    </singleOrderContextForB2C.Provider>
  )
};

export const SingleOrderContextProvider = ({children, order}: {children: ReactNode, order?: SingleOrder & FormContext}) => {
  let serverData = () => {
    if (order && order.id) {
      return order
    }
  }
  const singleOrder = useSingleOrderContextProvider(serverData());
  return (
    <singleOrderContext.Provider value={singleOrder}>
      {children}
    </singleOrderContext.Provider>
  )
};

export const useSingleOrderB2C = () =>{
  return useContext(singleOrderContextForB2C);
};

export const useSingleOrder = () =>{
  return useContext(singleOrderContext);
};

const useSingleOrderContextProviderForB2C = (serverData?: SingleOrderB2C) => {
  const [errorInPriceEstimate, setErrorInPriceEstimate] = useState<boolean>(false)
  const [singleOrder, setSingleOrder] = useState<SingleOrderB2C>(() =>{
    if(serverData && serverData !== undefined) {
      return serverData
    }

    return {
      order_id: "",
      submitted: true,
      pickup_country: "",
      consignee_country: "",
      service_id: "",
      pickup_point_id: "",
      pickup_address: "",
      service_level: "",
      service: {
        service_id: "",
        pickup_country: "",
        consignee_country: "",
        service_category: "",
        service_name: "",
        allow_cod: false,
        allow_pickup: true,
        pickup_contact_name: "",
        pickup_contact_number: "",
        pickup_state: "",
        pickup_city: "",
        pickup_province: "",
        pickup_address: "",
        pickup_postal: ""
      },
      serviceDefinition: {
        service_id: "",
        origin_country: "",
        destination_country: "",
        service_type: "",
        exclusive_agents: [

        ],
        exclusive_agents_ids: [

        ]
      },
      pickup_state: "",
      pickup_city: "",
      pickup_postal: "",
      pickup_point: {
        pickup_point_id: "",
        pickup_point_name: "",
        pickup_point_address: "",
        pickup_point_country: "",
        pickup_point_state: "",
        pickup_point_city: "",
        pickup_point_province: "",
        pickup_point_postal: "",
        pickup_point_contact_person: "",
        pickup_point_number: "",
        pickup_point_email: "",
        pickup_point_notes: "",
        is_active: true
      },
      serviceType: "",
      pickup_notes: "",
      pickup_date: "",
      consignee_name: "",
      consignee_email: "",
      consignee_number: "",
      consignee_state: "",
      consignee_province: "",
      consignee_postal: "",
      consignee_address: "",
      consignee_address1: "",
      consignee_city: "",
      consignee_address_id: "",
      consignee_identifcation_number: "",
      identification_document_name: "",
      items: [{
        item_desc: '',
        item_category: '',
        item_quantity: '',
        item_price_currency: '',
        item_price_value: '',
        item_product_id: '',
        item_sku: '',
        item_url: '',
        gst_ovr: '',
        gst_collected : ''
      }],
      item_price_currency: "",
      shipper_order_id: "",
      declaration_checkbox: true,
      indonesia_npwp: "",
      order_weight: null,
      order_height: null,
      order_width: null,
      order_length: null,
      payment_type: "",
      cod_amt_to_collect_currency: "",
      cod_amt_to_collect: "",
      incoterm: "",
      tracking_no: "",
      allow_hyphen_in_tracking_number: false,
      special_instruction: "",
      secret_key: ""
    }
  });
  
  const [errors, setErrors] = useState<OrderErrorB2C>({})

  const updateSingleOrderDataB2C = (values: any) => {
    setSingleOrder((current) => ({...current, ...values}));
    return singleOrder
  };

  const updateErrorInPriceEstimate = (value: boolean) => {
    setErrorInPriceEstimate(value)
  };

  const submitSingleOrderB2C = async (token: string) => {
    let response = await apib2c.post('orders/orders/',
      {
        ...singleOrder,
        consignee_number: prependCountryCode(countryCodes[singleOrder.consignee_country], singleOrder.consignee_number),
      },
      {
        headers: {
          'Authorization': `Token ${token}`
        }});
    return response.data
  };
  
  const submitMarketplaceOrderB2C = async (token: string, orderId: number) => {
    let response = await api.post(`channels/draft-orders/${orderId}/validate/`,
      {
        ...singleOrder,
        consignee_number: prependCountryCode(countryCodes[singleOrder.consignee_country], singleOrder.consignee_number),
      },
      {
        headers: {
          'Authorization': `Token ${token}`
        }});
    return response.data
  };

  const UpdateSingleOrderB2C = async () => {
    let singleOrderPayload:any = singleOrder;
    let response = await apib2c.post(`order/order-validation/`,
      {
        ...singleOrderPayload
      });

    return response.data
  };

  const UpdateSingleOrderB2C_Shopify = async () => {
    let singleOrderPayload:any = singleOrder;
    let response = await apib2c.post(`channel/draft-orders/${singleOrderPayload.id}/validate/`,
      {
        ...singleOrderPayload
      });

    return response.data
  };

  const CheckOrderPaymentStatusB2C = async (token: string, orderId: number) => {
    let singleOrderPayload:any = singleOrder;
    singleOrderPayload.pickup_location = (singleOrder.pickup_point.pickup_point_id === null?undefined:singleOrder.pickup_point.pickup_point_id);
    let response = await api.post(`orders/orders/${orderId}/calculate_amount_to_pay/`,
      {
        ...singleOrderPayload,
        consignee_number: prependCountryCode(countryCodes[singleOrder.consignee_country], singleOrder.consignee_number),
      },
      {
        headers: {
          'Authorization': `Token ${token}`
        }});

    return response.data
  };

  return {
    singleOrder,
    updateSingleOrderDataB2C,
    updateErrorInPriceEstimate,
    submitSingleOrderB2C,
    UpdateSingleOrderB2C,
    UpdateSingleOrderB2C_Shopify,
    submitMarketplaceOrderB2C,
    CheckOrderPaymentStatusB2C,
    errors,
    errorInPriceEstimate,
    setErrors
  }
};
const useSingleOrderContextProvider = (serverData?: SingleOrder & FormContext) => {
  const [singleOrder, setSingleOrder] = useState<SingleOrder>(() =>{
    if(serverData && serverData !== undefined) {
      return serverData
    }

    return {
      tracking_no:'',
      consignee_name: '',
      custom_tn: '',
      consignee_address: '',
      consignee_country_code: '',
      consignee_state: '',
      consignee_city: '',
      consignee_number: '',
      consignee_postal: '',
      consignee_email: '',
      consignee_id: '',
      consignee_id_type: '',
      dropoff_vendor_point_id: null,
      dropoff_vendor_name: '',
      pickup_contact_name: '',
      pickup_contact_number: '',
      pickup_address: '',
      pickup_state: '',
      pickup_city: '',
      pickup_province: '',
      pickup_postal: '',
      pickup_country_code: '',
      pickup_country: '',
      pickup_date: null,
      pickup_location: null,
      billing_location: null,
      billing_contact_name: '',
      billing_contact_number: '',
      billing_address: '',
      billing_state: '',
      billing_city: '',
      billing_postal: '',
      billing_country_code: '',
      order_length: null,
      order_width: null,
      order_height: null,
      order_weight: null,
      payment_type: '',
      payment_status: '',
      currency: '',
      amount: null,
      amount_to_pay: null,
      gst_amount: '',
      total_duties: '',
      total_taxes: '',
      shipping_charge: '',
      charge_id: null,
      is_tax_payable: false,
      total:null,
      pickup_charge: null,
      cod_amount: null,
      incoterm:'',
      cod_currency:'',
      items: [{
        item_desc: '',
        item_category: '',
        item_quantity: null,
        item_price_currency: '',
        item_price_value: null,
        product_id: '',
        item_sku: '',
        item_product_url: '',
        gst_ovr: '',
        gst_collected : ''
      }],
    }
  });
  const [formContext, setFormContext] = useState<FormContext>(() => {
    if(serverData && serverData !== undefined) {
      return (
        {
          service_type: serverData.service_type,
        }
      )
    }
    return (
      {
        service_type: '',
      }
    )
  });
  const [errors, setErrors] = useState<OrderError>({})

  const updateSingleOrderData = (values: any) => {
    setSingleOrder((current) => ({...current, ...values}));
    return singleOrder
  };

  const submitSingleOrder = async (token: string) => {
    let response = await api.post('orders/orders/',
      {
        ...singleOrder,
        service_type: formContext.service_type,
        consignee_number: prependCountryCode(singleOrder.consignee_country_code, singleOrder.consignee_number),
      },
      {
        headers: {
          'Authorization': `Token ${token}`
        }});
    return response.data
  };
  
  const submitMarketplaceOrder = async (token: string, orderId: number) => {
    singleOrder.consignee_country = Object.keys(countryCodes).find(key => countryCodes[key] === singleOrder.consignee_country_code);
    singleOrder.pickup_country = Object.keys(countryCodes).find(key => countryCodes[key] === singleOrder.pickup_country_code);
    let response = await api.post(`channels/draft-orders/${orderId}/validate/`,
      {
        ...singleOrder,
        cod_amt_to_collect: singleOrder.cod_amount,
        service_type: formContext.service_type,
        consignee_number: prependCountryCode(singleOrder.consignee_country_code, singleOrder.consignee_number),
      },
      {
        headers: {
          'Authorization': `Token ${token}`
        }});
    return response.data
  };

  const UpdateSingleOrder = async (token: string, orderId: number) => {
    let singleOrderPayload:any = singleOrder;
    singleOrderPayload.pickup_location = (singleOrder.pickup_location === null?undefined:singleOrder.pickup_location);
    singleOrderPayload.billing_location = (singleOrder.billing_location === null?undefined:singleOrder.billing_location);
    let response = await api.put(`orders/orders/${orderId}/`,
      {
        ...singleOrderPayload,
        service_type: formContext.service_type,
        consignee_number: prependCountryCode(singleOrder.consignee_country_code, singleOrder.consignee_number),
      },
      {
        headers: {
          'Authorization': `Token ${token}`
        }});

    return response.data
  };

  const CheckOrderPaymentStatus = async (token: string, orderId: number) => {
    let singleOrderPayload:any = singleOrder;
    singleOrderPayload.pickup_location = (singleOrder.pickup_location === null?undefined:singleOrder.pickup_location);
    singleOrderPayload.billing_location = (singleOrder.billing_location === null?undefined:singleOrder.billing_location);
    let response = await api.post(`orders/orders/${orderId}/calculate_amount_to_pay/`,
      {
        ...singleOrderPayload,
        service_type: formContext.service_type,
        consignee_number: prependCountryCode(singleOrder.consignee_country_code, singleOrder.consignee_number),
      },
      {
        headers: {
          'Authorization': `Token ${token}`
        }});

    return response.data
  };

  return {
    singleOrder,
    updateSingleOrderData,
    submitSingleOrder,
    UpdateSingleOrder,
    submitMarketplaceOrder,
    CheckOrderPaymentStatus,
    errors,
    setErrors,
    formContext,
    setFormContext
  }
};