import {
  Modal
} from 'antd';
import {GoogleMaps} from "../../GoogleMaps/GoogleMaps";
import {useState} from "react";

export const DropOffAddressModal = (props:any) =>{
  const [showModal, setShowModal] = useState<boolean>(props.showModal);
  const handleCancel = () => {
    setShowModal(false)
    props.setShowMapModalCallback(false)
    props.setSelectedDropOffPoint(undefined)
  }
  const setSelectedDropOffPoint = (val:any) => {
    props.setSelectedDropOffPoint(val)
    setShowModal(false)
    props.setShowMapModalCallback(false)
  }

  return (
    <Modal width={950} title={'Search and select a drop-off point'}
      visible={showModal}
      footer={null}
      onCancel={handleCancel}
    >
      <GoogleMaps setSelectedDropOffPoint={setSelectedDropOffPoint} dropOffLocations={props.dropOffLocations}/>
    </Modal>
  );
};

