import { Card, Col, Row, Image } from 'antd';
import janio_logo from "../assets/images/janio_logo.svg";

export const SuccessPage = () => {
  return (
    <Row align="middle" justify="center" style={{ paddingTop: '30px', paddingBottom: '30px' }} >
      <Col span={12} className="auth-form-container">
        <div className="logo" style={{textAlign: 'center', marginTop: '30px'}}>
          <a href="#">
            <Image preview={false} src={janio_logo} className={"site-logo"}/>
          </a>
        </div>
        <Card style={{ minWidth: '380px', borderTop: 'none' }}>
          <h3 style={{ color: 'rgb(5, 5, 147)', fontSize: '16px', fontWeight: 'bold', textAlign: 'center', marginTop: '-20px', marginBottom: '20px' }}>
            Janio Return Order Confirmation
          </h3>

          <Col span={24}>
            <p>Thank you for your response.</p>
          </Col>
        </Card>
      </Col>
    </Row>
  )
};
