import { Col, Select } from 'antd';
import '../styles/index.css';

interface Props {
  currency: string | undefined;
  currencies: string[] | undefined;
  setCurrency(val: string): void;
};

const { Option } = Select;

const Filters = (props: Props) => {
  const { currency, currencies, setCurrency } = props;

  return (
    <Col style={{ paddingTop: '0px' }}>
      <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>Currency</span>
      <Select
        className='currency-selector'
        style={{ marginTop: '4px', width: '100%', fontSize: '24px' }}
        value={currency}
        bordered={false}
        onChange={(value: any) => setCurrency(value)}
      >
        {currencies && currencies.length && currencies?.map((value) => <Option key={value} value={value}>{value}</Option>)}
      </Select>
    </Col>
  )
};

export default Filters;