import { Form, Input, Button, Card, Row, Col, Alert, Checkbox, Modal, Select  } from 'antd';
import ReactFlagsSelect from 'react-flags-select';
import { signup } from "./services/authApi";
import { useMutation } from "react-query";
import React, {useState} from 'react'
import { ThanksForm } from "./components/ThanksForm";
import {validateMessages, RegistrationPurpose, billingSupportedCountries, countryCallingCodes, countries} from "../commons/utils/constants";
import './styles/AuthForm.css';
import {getAuthErrorContent} from "../commons/utils/utilizer";
import {NewTermsAndConditionsPage} from '../commons/components/TermsAndConditions/NewTermsAndConditions.page';
import { useLanguage } from '../languages/Language';
import { NotFound } from './NotFound.page';
import { featureFlag } from "../commons/utils/utilizer";

const { Option } = Select;
interface UserData {
  email: string; 
  full_name: string;
}
interface SignUp {
  onSignUp(val:boolean):void;
  getUserDetail(val:UserData):void
}
const RegistrationForm = (props:SignUp) => {
  let { getText } = useLanguage()
  const [selectedCountry, setSelectedCountry] = useState('');
  const [isSignupFailed, setIsSignupFailed] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [errorData, setErrorData] = useState<any>({});
  const referral_code = new URLSearchParams(window.location.search).get('utm') || '';
  const register = useMutation(async ({name , email, password, phone_number, purpose, billing_country}: {name: string, email: string, password: string, phone_number: string, purpose: string,  billing_country:  string}) => {
    let phone_number_with_code  = phone_number ? (countryCallingCodes[selectedCountry]+phone_number) : '';
    return signup(name, email, password, phone_number_with_code, referral_code, purpose, billing_country)
  },  {
    onSuccess: (val) => {
      props.onSignUp(true);
      props.getUserDetail(val)
    },
    onError: (error) => {
      let val: any;
      val = error;
      setErrorData({...val.response.data});
      setIsSignupFailed(true);
      window.scrollTo({
        top: 200,
        left: 0,
        behavior: "smooth"
      })
    },

  });
  
  const showTermsAndConditions = () => {
    setIsModalVisible(true);
  };
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const getCountriesFlagArr = () => {
    let countryFlags = {}
    for (let code in countries) {
      Object.assign(countryFlags, { [code]: {
        "primary": countries[code],
        "secondary": countryCallingCodes[code]
      }})
    }
    return countryFlags
  }

  const unSortedCountries = () => {
    let CountryCodes = []
    for (let code in countries) {
      CountryCodes.push(code)   
    }
    return CountryCodes
  }
  return (
    <Form
      onFinish={(val) => {
        register.mutate(val);
      }}
      layout={'vertical'}
      requiredMark={false}
      validateMessages={validateMessages}
    >
      <Form.Item label={getText("Sign up for an account")} className={"form-title"}>
      </Form.Item>
      {isSignupFailed && <Alert
        className={"form-error"}
        message={getText("Unable to process your request")}
        description={getAuthErrorContent(errorData)}
        type="error"
        showIcon
      />}
      <Form.Item
        label={getText("Full Name/Company Name")}
        className={"form-main-label"}
      >
        <Form.Item
          label={getText("Name will be printed on the shipping label")}
          name="name"
          rules={[{ required: true, message: getText('Please enter your full name') }]}
          className={"form-sub-label"}
        >
          <Input />
        </Form.Item>
      </Form.Item>
      <Form.Item
        label={getText("Email Address")}
        className={"form-main-label"}
      >
        <Form.Item
          label={getText("This will be used as your login ID")}
          name="email"
          rules={[{ required: true, message: getText('Please enter your email address') }, { type: 'email' }]}
          className={"form-sub-label"}
        >
          <Input />
        </Form.Item>
      </Form.Item>

      <Form.Item
        label={getText("Phone Number")}
        className={"form-main-label"}
      >
        <Row gutter={4}>
          <Col span={selectedCountry ? 3 : 8}>
            <Form.Item 
              className={"form-sub-label"}
            >
              <ReactFlagsSelect
                countries={unSortedCountries()} // For unsorting the countries and showing desired countries on top 
                customLabels={getCountriesFlagArr()} // For creating object to show country flag with country code
                showSecondarySelectedLabel={false}
                showSelectedLabel={false}
                selected={selectedCountry}
                searchPlaceholder={getText("Search countries")}
                onSelect={code => setSelectedCountry(code)}
                searchable
                className="menu-flags"
                selectButtonClassName="menu-flags-button"
                selectedSize={12}
                optionsSize={12}
                id="flags-select"
              />
            </Form.Item>
          </Col>
          <Col span={selectedCountry ? 21 : 16} >
            <Form.Item
              name="phone_number"
              className={"form-sub-label show-prefix"}
              rules={[{ required: true, message: getText('Please enter Phone number')}]}
            >
              <Input disabled={selectedCountry ? false : true} prefix={selectedCountry ? countryCallingCodes[selectedCountry] : null} />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>

      <Form.Item
        label={getText("Password")}
        name="password"
        rules={[{ required: true, message: getText('Please enter your password')}]}
      >
        <Input.Password />
      </Form.Item>
      
      <Form.Item name="billing_country" label={getText("Billing Country")} className={"form-main-label"} style={{ marginBottom: "20px" }} rules={[{ required: true, message: getText('Please select billing country') }]}>
        <Select
          placeholder={getText("Please select billing country")}
          allowClear
        >
          {Object.entries(billingSupportedCountries).map((item:any) =><Option key={item[0]} value={item[1]}>{item[0]}</Option>)}
        </Select>
      </Form.Item>

      <Form.Item name="purpose" label={getText("What is this account used for?")} className={"form-main-label"} style={{ marginBottom: "20px" }}>
        <Select
          placeholder={getText("Please select purpose")}
          allowClear
        >
          {RegistrationPurpose.map((item, index) =><Option key={index} value={item}>{item}</Option>)}
        </Select>
      </Form.Item>
      
      <Form.Item
        name="agreement"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(getText('Please accept Janio Terms & Conditions')),
          },
        ]}>
        <Checkbox>
          {getText("I agree to")} <a onClick={showTermsAndConditions} className={"form-text"}> {getText("Janio Terms & Conditions")}</a>
        </Checkbox>
      </Form.Item>
      <Modal width={700} title={getText("Terms of Service")} visible={isModalVisible} onCancel={handleModalCancel} footer={null} className={'custom-modal'} >
        <div style={{color: '#000000'}} >
          <NewTermsAndConditionsPage/>
        </div>
      </Modal>
      <Form.Item className={'form-footer'}>
        <Button type="primary" size={"large"} htmlType="submit" className={"form-button"} loading={register.isLoading} >
          {getText("Sign up")}
        </Button>
        {getText("Already have an account?")}  <a href="/auth/login" className={"form-text"}> {getText("Log In")}</a>
      </Form.Item>
    </Form>
  )
}

export const Register = () =>  {
  interface UserData {
    email: string; 
    full_name: string;
  }
  const [isRegistered, setIsRegistered] = useState(false);
  const [userDetail, setUserDetail] = useState<UserData>({email: '', full_name: ''});
  const onSignUp = (val:boolean):void => {
    setIsRegistered(val);
  };
  const getUserDetail = (val: UserData) => {
    setUserDetail(val);
  };

  if(featureFlag()) {
    return (
      <Row align="middle" justify="center">
        <Col span={12} className={'auth-form-container'}>
          <Card>
            {isRegistered ? <ThanksForm userDetail={userDetail} /> :<RegistrationForm onSignUp={onSignUp} getUserDetail={getUserDetail} />}
          </Card>
        </Col>
      </Row>
    )
  }

  return (
    <Row align="middle" justify="center">
      <Col span={14} className={'auth-form-container'}>
        <NotFound />
      </Col>
    </Row>
  )
}
