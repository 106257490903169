import {
  Form,
  Button,
  Col,
  Row,
  Select, 
  Alert,
  message
} from 'antd';
import React, {useState} from "react";
import {countryCodes, IndonesiaIdentificationTypes, SouthKoreaIdentificationTypes} from "../commons/utils/constants";
import {FormInput, FormSelect} from "../orders/components/Forms";
import {sortResponseAlphabatically} from "../commons/utils/utilizer" 
import {Formik, Form as FormikForm,FormikHelpers} from 'formik';
import * as Yup from 'yup';
import {validatePostalCode} from "../commons/utils/validator";
import {useQuery,useMutation} from "react-query";
import {
  // getStates, addConsigneeLocation, getCities, addLocation_in_b2c, 
  getStates_from_b2c, getCities_from_b2c, addConsigneeLocation_in_b2c} from "../orders/services/privateApi";
import {useAuth} from "../auth/services/authContext";
import { useLanguage } from '../languages/Language';
import './styles/ConsigneeAddressForm.css';
import { LabelRadio } from '../ReUsableComponents/ReUsable'

const {Option} = Select;

interface CustomerProps {
  // consignee_name?: string,
  // consignee_number?: string,
  // consignee_email?: string,
  // consignee_address?: string,
  // consignee_country?: string,
  // consignee_state?: string,
  // consignee_city?: string,
  // consignee_postal?: string,
  // consignee_id?: string,
  // consignee_id_type?: string,
  // name?: string

  name?: string,
  contact_name?: string,
  contact_number?: string,
  contact_phone?: string,
  contact_email?: string,
  address?: string,
  country?: string,
  state?: string,
  city?: string,
  postal?: string
  identification_document_name?: string,
  consignee_identifcation_number?: string
}

const validationSchema = (getText: any) => {
  return Yup.object().shape({
    contact_name: Yup.string().required(getText('Please enter Full Name')),
    contact_number: Yup.string().required(getText('Please enter phone number')),
    contact_email: Yup.string().email(getText('Please enter a valid email')).required(getText('Please enter a valid email')),
    address: Yup.string().required(getText('Please enter Address')),
    state: Yup.string().required(getText('Please select State')),
    city: Yup.string().required(getText('Please enter City')),
    country: Yup.string().required(getText('Please select Destination Country')),
    postal: Yup.string().required(getText('Please enter Postal Code')).test(
      'postal-check',
      (val: any, context: any) => {
        if(!val){
          return context.createError({
            path: context.path,
            message: getText('Please enter Postal Code')
          })
        }
        else{
          const error = validatePostalCode(countryCodes[context.parent.country],val.toString());
          if(error){
            return context.createError({
              path: context.path,
              message: error
            })
          }
        }
        return true
      }
    )
  })};

type ServerErrors = {[key: string]: Array<string>};
const combineFieldsErrors = (errors: ServerErrors) => {
  let combined_errors: any = {}
  let initial_touched: any = {}

  for(const [key, val] of Object.entries(errors)){
    combined_errors[key] = val.join(',')
    initial_touched[key] = true
  }

  return {
    combined_errors,
    initial_touched
  }
}

export const ConsigneeAddressForm = (props:any) =>{
  const auth = useAuth();
  const resetFieldValuesOnCountryChange = ['city','postal','state'];
  const { getText } = useLanguage();
  const [country, setCountry] = useState<string>(props.countryCode! ? props.countryCode! : '');
  const [state, setState] = useState<string>('');
  const [freeText, setfreeText] = useState<string>('dropdown');
  const [initialTouched, setInitialTouched] = useState({});

  const statesListFromb2c = useQuery(['statesListFromb2c', country], () => {
    if(country){
      return getStates_from_b2c(country)
    }
  });

  const cityListFromb2c = useQuery(['cityListFromb2c', state], () => {
    if(state){
      return getCities_from_b2c(country, state)
    }
  });

  const formInitialValue: CustomerProps = {
    name: "",
    contact_name: "",
    contact_number: "",
    contact_phone: "",
    contact_email: "",
    address: "",
    country: props.countryCode ? props.countryCode! : '',
    state: "",
    city: "",
    postal: "",
    identification_document_name: "",
    consignee_identifcation_number: ""
  }

  const formatErrors = (errors: any) => {
    let formattedError: CustomerProps = {};
    for(const key in errors){
      if(key === 'contact_number'){
        formattedError['contact_number'] = errors[key]
      }else if(key === 'postal'){
        formattedError['postal'] = errors[key]
      }else if(key === 'address'){
        formattedError['address'] = errors[key]
      }else if(key === 'city'){
        formattedError['city'] = errors[key]
      }else if(key === 'contact_email'){
        formattedError['contact_email'] = errors[key]
      }else if(key === 'contact_name'){
        formattedError['contact_name'] = errors[key]
      }else if(key === 'country'){
        formattedError['country'] = errors[key]
      }else if(key === 'state'){
        formattedError['state'] = errors[key]
      }else if(key === 'name'){
        formattedError['name'] = errors[key]
      }else{
        message.error(`${key}: ${errors[key]}`)
      }
    }
    return formattedError
  }

  // Change add consignee address api
  const createLocation = useMutation(async (value: CustomerProps) => await addConsigneeLocation_in_b2c(value, auth.user.secret_key))
  
  const onSubmit = async (value: CustomerProps, {setErrors}: FormikHelpers<CustomerProps>) => {
    try{
      // Call consignee address api
      let location:any = await createLocation.mutateAsync(value)
      if(props.onSuccess){
        props.onSuccess(location)
        message.success("Successfully updated the consignee address")
      }
    }
    catch(e){
      const {combined_errors, initial_touched} = combineFieldsErrors(e.response.data)
      message.error("Failed to update the consignee address")
      const formatted_errors = formatErrors(combined_errors)
      setErrors(formatted_errors)
      setInitialTouched(initial_touched)
    }
  };

  const setFreeTextCallback = (val:string) => {
    setfreeText(val);
  };

  return (
    <Formik
      initialValues={formInitialValue}
      validationSchema={validationSchema(getText)}
      initialTouched={initialTouched}
      enableReinitialize= {true}
      onSubmit={onSubmit}
      validateOnChange={false}>
      { formik => {
        // console.log('formikformik', formik)
        return (
          <div className={"consignee-address-form-container ant-form-vertical"}>
            <div style={{marginBottom:20,marginTop:-20}}>
              <Alert
                message={<p style={{margin:0}}> {getText("Please provide your")} <strong> {"customer's identification number if customer is from Indonesia"} </strong> {getText("to ensure a smooth delivery")}</p>}
                type="info"
                showIcon
              />
            </div>
            <FormikForm>
              <Row gutter={14}>
                <Col span={12}>
                  <FormInput label={`${props.addressType.charAt(0).toUpperCase() + props.addressType.slice(1)} ${getText('Address Label')}`} name="name" wrapperClass={"no-margin-item"} />
  
                  <Form.Item label={getText('Name will be displayed under address selection')} className={'form-item-info'}>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={14}>
                <Col span={12}>
                  <FormInput required label={getText('Full Name')} name="contact_name" />
                </Col>
                <Col span={12}>
                  <FormInput required label={getText('Phone Number')} name="contact_number"/>
                </Col>
              </Row>
              {(props.countryCode === 'Indonesia' || formik.values.country === 'ID') && <Row gutter={14}>
                <Col span={12}>
                  <FormSelect label={getText('Identification Type')} name="identification_document_name" onChangeCallback={(value: any) => {formik.setFieldValue("consignee_identifcation_number", '')}}>
                    {
                      Object.entries(IndonesiaIdentificationTypes).map((type:any) => {
                        return  <Option key={type[0]} value={type[0]}>{type[1]}</Option>
                      })
                    }
                  </FormSelect>
                </Col>
                {formik.values.identification_document_name ?
                  <Col span={12}>
                    <FormInput label={`${IndonesiaIdentificationTypes[formik.values.identification_document_name]}`} name="consignee_identifcation_number" />
                  </Col> : ''
                }
              </Row>}
              {props.countryCode === 'South Korea' && <Row gutter={14}>
                <Col span={12}>
                  <FormSelect label={getText('Identification Type')} name="identification_document_name" onChangeCallback={(value: any) => {formik.setFieldValue("consignee_identifcation_number", '')}}>
                    {
                      Object.entries(SouthKoreaIdentificationTypes).map((type:any) => {
                        return  <Option key={type[0]} value={type[0]}>{type[1]}</Option>
                      })
                    }
                  </FormSelect>
                </Col>
                {formik.values.identification_document_name ?
                  <Col span={12}>
                    <FormInput label={`${SouthKoreaIdentificationTypes[formik.values.identification_document_name]}`} name="consignee_identifcation_number" />
                  </Col> : ''
                }
              </Row>}
              <FormInput required label={getText('Email')} name="contact_email" />
              <FormInput required label={getText('Address')} name="address" />
  
              <Row gutter={14}>
                <Col span={12}>
                  <FormSelect required label={getText('Country')} name="country" onChangeCallback={(country_code:string) => {setCountry(country_code); resetFieldValuesOnCountryChange.forEach(field => formik.setFieldValue(field, ''))}}>
                    {
                      Object.entries(countryCodes)
                        .filter(([key]) => props.countryCode ? key === props.countryCode! : true)
                        .map(([key]) => (
                          <Option key={key} value={key}>{key}</Option>
                        ))
                    }
                  </FormSelect>
                </Col>
                <Col span={12}>
                  <FormSelect required label={getText('State')} name="state" onChangeCallback={(state:string) => {
                    setState(state);
                    formik.setFieldValue('city', '');
                    // setfreeText(false)
                  }} loading={statesListFromb2c.isLoading}>
                    {
                      (sortResponseAlphabatically(statesListFromb2c?.data, 'state_name') || []).map((item: {state_id: number, state_name:string} ) => {
                        return  <Option key={item.state_id} value={item.state_name}>{item.state_name}</Option>
                      })
                    }
                  </FormSelect>
                  {statesListFromb2c.isError && <p style={{color:'red', marginBottom:0}}>{getText("Failed to fetch states, try later")}</p>}
                </Col>
              </Row>
              <Row gutter={14}>
                <Col span={12}>
                  {
                    formik.values.country === 'US' ?
                      freeText === 'dropdown' ? 
                        <>
                          <FormSelect label={<LabelRadio setFreeTextCallback={setFreeTextCallback} freeText={freeText} />}
                            notFoundContent={<p style={{marginBottom: '0px', color: '#525050'}}>City not found. Please select Other</p>} name="city" loading={cityListFromb2c.isLoading}
                            placeholder={'Select a city'}>
                            {
                              (sortResponseAlphabatically(cityListFromb2c?.data, 'city_name') || [])
                                .map((item: {city_id:number, city_name: string, display_name: string} ) => (
                                  <Option key={item.city_id} value={item.city_name}>{item.display_name}</Option>
                                ))
                            }
                          </FormSelect>
                          {cityListFromb2c.isError && <p style={{color:'red', marginBottom:0}}>Failed to fetch cities, try later</p>}
                        </>
                        : 
                        <FormInput placeholder='Please type in the city' label={<LabelRadio setFreeTextCallback={setFreeTextCallback} freeText={freeText} />} name="city" /> : 
                      <FormSelect label={getText('City')} name="city" loading={cityListFromb2c.isLoading}>
                        {
                          (sortResponseAlphabatically(cityListFromb2c?.data, 'city_name') || [])
                            .map((item: {city_id:number, city_name: string, display_name: string} ) => (
                              <Option key={item.city_id} value={item.city_name}>{item.display_name}</Option>
                            ))
                        }
                      </FormSelect>
                  }
                  
                </Col>
                <Col span={6} style={{marginTop: formik.values.country === 'US' ? '35px' : '0px'}}>
                  <FormInput required label={getText('Postal code')} name="postal" />
                </Col>
              </Row>
              <Row gutter={14}>
                <Col>
                  <Button onClick={props.onCancel}>Cancel</Button>
                  <Button type="primary" htmlType="submit" className={"blue-button"} style={{marginLeft: '10px'}}>
                    {'Add customer'}
                  </Button>
                </Col>
              </Row>
            </FormikForm>
          </div>
        )
      }}
    </Formik>
  )
};
