import { Pie /*measureTextWidth*/ } from '@ant-design/plots';
import { useEffect, useState } from 'react';
import { Spin, Empty } from 'antd';

interface DonutProps{
  donutData: any
}
export const Donut = (props: DonutProps) => {
  const [chartData, setChartData] = useState([])
  const removeUnderscore = (status: string) => {
    const capitalize = status.charAt(0).toUpperCase() + status.slice(1)
    return capitalize.replaceAll('_',' ')
  }

  useEffect(() => {
    let chartArray: any = []
    if(props.donutData.data){
      Object.entries(props.donutData.data).forEach(([key, value]) => {
        if(key!== 'shipment_created'){
          let data = {
            type: removeUnderscore(key),
            value: value
          }
          chartArray.push(data)
        }
      });
      setChartData(chartArray)
    }
    
  },[props.donutData.data])

  const config = {
    appendPadding: 10,
    data: chartData,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.64,
    color: ['#5AD8A6','#5B8FF9'],
    meta: {
      value: {
        formatter: (v: any) => `${v}`,
      },
    },
    label: {
      type: 'inner',
      offset: '-50%',
      style: {
        textAlign: 'center',
      },
      autoRotate: false,
      content: '{value}',
    },
    statistic: {
      content: {
        offsetY: 4,
        style: {
          fontSize: '20px',
          fontWeight: '300'
        },
      },
    },
    
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
      {
        type: 'pie-statistic-active',
      },
    ],
  };
  return (
    <div className='chart-header-custom'>
      <h2>Total Shipment Breakdown</h2>
      {!props.donutData.isLoading? 
        Object.keys(props.donutData.data).length !== 0 ? <Pie {...config} className={'sub-charts'} /> : <div className={'sub-charts no-data-image'}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/></div>
        : <div className={'sub-charts chart-spinner'}>
          <Spin/>
        </div>
      }
    </div>
  )
};

