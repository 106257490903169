import moment from "moment";

/**
 * Return added moment date excluding weekends (Saturday & Sunday)
 * @param date Starting date
 * @param days How many days to be added
 * @returns Moment date
 */
export const addBusinessDays = (date: string, days: number) => {
  let d = moment(date, 'YYYY-MM-DD').add(Math.floor(days / 5) * 7, 'days');
  let remaining = days % 5;

  while (remaining) {
    d.add(1, 'days');
    if (d.day() !== 0 && d.day() !== 6)
      remaining--;
  }

  return d;
};