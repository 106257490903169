import { instance as api } from '../../commons/api'
import { instanceForb2c as apib2c } from '../../commons/api'
import { instanceForSuperset as apiSuperset } from '../../commons/api'
import {useQuery} from "react-query";
import { message } from 'antd';
import { fileToBase64 } from '../../commons/utils/utilizer';
import { extension } from '../../commons/utils/constants';
export interface AddressProps {
  pickup_point_name?: string,
  pickup_point_address?: string,
  pickup_point_country?: string,
  pickup_point_state?: string,
  pickup_point_city?: string,
  pickup_point_province?: string,
  pickup_point_postal?: string,
  pickup_point_contact_person?: string,
  pickup_point_number?: string,
  pickup_point_notes?: string,
  pickup_point_email?: string
}

export interface AddressPropsForReturns {
  id?: number,
  country?: string,
  contact_email?: string,
  default?: boolean,
  return_point_name?: string,
  contact_name?: string,
  contact_number?: string,
  state?: string,
  city?: string,
  province?: string,
  notes?: string,
  address?: string,
  postal?: string,
  shipper_profile?: number
}

export interface BillingAddressProps {
  contact_person?: string,
  contact_number?: string,
  contact_email?: string,
  address?: string,
  country_code?: string,
  city?: string,
  state?: string,
  postal?: string,
}

interface CustomerAddressProps {
  consignee_name?: string,
  consignee_number?: string,
  consignee_email?: string,
  consignee_address?: string,
  consignee_country?: string,
  consignee_state?: string,
  consignee_city?: string,
  consignee_postal?: string,
  consignee_id?: string,
  consignee_id_type?: string,
  name?: string
}

interface CustomerAddressPropsInB2C {
  name?: string,
  contact_name?: string,
  contact_number?: string,
  contact_phone?: string,
  contact_email?: string,
  address?: string,
  country?: string,
  state?: string,
  city?: string,
  postal?: string
  identification_document_name?: string,
  consignee_identifcation_number?: string
}

export const addLocation_in_b2c = async (values: AddressProps, secretKey: string) => {
  let response = await apib2c.post<AddressProps>(`shipper/pickup-point/`, {
    pickup_point_name: values.pickup_point_name,
    pickup_point_address: values.pickup_point_address,
    pickup_point_country: values.pickup_point_country!,
    pickup_point_state: values.pickup_point_state,
    pickup_point_city: values.pickup_point_city,
    pickup_point_province: values.pickup_point_province,
    pickup_point_postal: values.pickup_point_postal,
    pickup_point_contact_person: values.pickup_point_contact_person,
    pickup_point_number: values.pickup_point_number,
    pickup_point_notes: values.pickup_point_notes,
    pickup_point_email: values.pickup_point_email,
    type: 'FORWARD',
    secret_key: secretKey
  });
  return response.data
};

export const updateLocation_in_b2c = async (values: AddressProps, secretKey: string, address_id: string) => {
  let response = await apib2c.patch<AddressProps>(`shipper/pickup-point/${address_id}/`, {
    pickup_point_name: values.pickup_point_name,
    pickup_point_address: values.pickup_point_address,
    pickup_point_country: values.pickup_point_country!,
    pickup_point_state: values.pickup_point_state,
    pickup_point_city: values.pickup_point_city,
    pickup_point_province: values.pickup_point_province,
    pickup_point_postal: values.pickup_point_postal,
    pickup_point_contact_person: values.pickup_point_contact_person,
    pickup_point_number: values.pickup_point_number,
    pickup_point_notes: values.pickup_point_notes,
    pickup_point_email: values.pickup_point_email,
    type: 'FORWARD',
    secret_key: secretKey
  });
  return response.data
};

export const updateReturnLocation_in_b2c = async (values: AddressProps, secretKey: string, address_id: string) => {
  let response = await apib2c.patch<AddressPropsForReturns>(`shipper/return-point/${address_id}/`, {
    return_point_name: values.pickup_point_name,
    address: values.pickup_point_address,
    country: values.pickup_point_country!,
    state: values.pickup_point_state,
    city: values.pickup_point_city,
    province: values.pickup_point_province,
    postal: values.pickup_point_postal,
    contact_name: values.pickup_point_contact_person,
    contact_number: values.pickup_point_number,
    notes: values.pickup_point_notes,
    contact_email: values.pickup_point_email,
    type: 'RETURN',
    secret_key: secretKey
  });
  return response.data
};

export const updateConsigneeLocation_in_b2c = async (values: AddressProps, secretKey: string, address_id: string) => {
  let response = await apib2c.patch<AddressPropsForReturns>(`shipper/consignee-address/${address_id}/`, {
    return_point_name: values.pickup_point_name,
    address: values.pickup_point_address,
    country: values.pickup_point_country!,
    state: values.pickup_point_state,
    city: values.pickup_point_city,
    province: values.pickup_point_province,
    postal: values.pickup_point_postal,
    contact_name: values.pickup_point_contact_person,
    contact_number: values.pickup_point_number,
    notes: values.pickup_point_notes,
    contact_email: values.pickup_point_email,
    type: 'CONSIGNEE',
    secret_key: secretKey
  });
  return response.data
};

export const deleteLocation_in_b2c = async (secretKey: string, address_id: string) => {
  let response = await apib2c.delete<AddressProps>(`shipper/pickup-point/${address_id}/?secret_key=${secretKey}`);
  return response.data
};

export const deleteReturnLocation_in_b2c = async (secretKey: string, address_id: string) => {
  let response = await apib2c.delete<AddressProps>(`shipper/return-point/${address_id}/?secret_key=${secretKey}`);
  return response.data
};

export const addReturnLocation_in_b2c = async (values: AddressProps, secretKey: string) => {
  let response = await apib2c.post<AddressPropsForReturns>(`shipper/return-point/`, {
    country: values.pickup_point_country!,
    contact_email: values.pickup_point_email,
    default: false,
    return_point_name: values.pickup_point_name,
    contact_name: values.pickup_point_contact_person,
    contact_number: values.pickup_point_number,
    state: values.pickup_point_state,
    city: values.pickup_point_city,
    province: values.pickup_point_province,
    notes: values.pickup_point_notes,
    address: values.pickup_point_address,
    postal: values.pickup_point_postal,
    secret_key: secretKey
  });
  return response.data
};

export interface AddressPropsForShopify {
  address?: string,
  city?: string,
  contact_email?: string,
  contact_number?: string,
  contact_person?: string,
  country_code?: string,
  name?: string,
  state?: string,
  postal?: string,
  type?: string
}
export const addLocation = async (values: AddressPropsForShopify, token:string) => {
  let response = await api.post<AddressPropsForShopify>('locations/pickup_locations/', {
    address: values.address,
    city: values.city,
    contact_email: values.contact_email,
    contact_number: values.contact_number,
    contact_person: values.contact_person,
    country_code: values.country_code,
    name: values.name,
    state: values.state,
    postal: values.postal,
    type: values.type
  }, {
    headers: {
      'Authorization': `Token ${token}`
    }});
  return response.data
};

export const addConsigneeLocation_in_b2c = async (values: CustomerAddressPropsInB2C, secretKey: string) => {
  let response = await apib2c.post<CustomerAddressPropsInB2C>('shipper/consignee-address/', {
    address: values.address,
    city: values.city,
    contact_email: values.contact_email,
    contact_number: values.contact_number,
    contact_name: values.contact_name,
    country: values.country!,
    consignee_identifcation_number: values.consignee_identifcation_number,
    identification_document_name: values.identification_document_name,
    name: values.name,
    state: values.state,
    postal: values.postal,
    secret_key: secretKey
  });
  return response.data
};

export const addConsigneeLocation = async (values: CustomerAddressProps, token:string) => {
  let response = await api.post<CustomerAddressProps>('locations/consignee_locations/', {
    address: values.consignee_address,
    city: values.consignee_city,
    contact_email: values.consignee_email,
    contact_number: values.consignee_number,
    contact_person: values.name,
    country_code: values.consignee_country,
    consignee_id: values.consignee_id,
    consignee_id_type: values.consignee_id_type,
    name: values.consignee_name,
    state: values.consignee_state,
    postal: values.consignee_postal
  }, {
    headers: {
      'Authorization': `Token ${token}`
    }});
  return response.data
};

export const addBillingAddress = async (values: BillingAddressProps, token:string) => {
  let response = await api.put<BillingAddressProps>('/auth/profile/update_billing_address/', {
    address: values.address,
    city: values.city,
    contact_email: values.contact_email,
    contact_number: values.contact_number,
    contact_person: values.contact_person,
    country_code: values.country_code,
    state: values.state,
    postal: values.postal,
    province: values.country_code
  }, {
    headers: {
      'Authorization': `Token ${token}`
    }});
  return response.data
};

// export const getBillingAddress = async (token:string) => {
//   let response = await api.get('/auth/profile/billing_address/',{
//     headers: {
//       'Authorization': `Token ${token}`
//     }});
//   return response.data
// };

export const getIntegrationHistory = async ( token: string, params: { page: number, page_size: number }) => {
  let response = await api.get(`channels/channels/?page=${params.page}&page_size=${params.page_size}`,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    });
  return response.data
}

export const getApiIntegrationHistory = async ( token: string, params: { page: number, page_size: number }) => {

  let response = await api.get(`auth/account-auth/?page=${params.page}&page_size=${params.page_size}`,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    });
  return response.data
}

export const getApiIntegrationHistoryb2c = async ( token: string, params: { page: number, page_size: number }) => {

  let response = await apib2c.get(`/channel/channels/?secret_key=${token}&page=${params.page}&page_size=${params.page_size}`);
  return response.data
}

export const deleteLocation = async (locationId: number, token:string) => {
  let response = await api.delete(`locations/pickup_locations/${locationId}`,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    });
  return response.data
};

export const deleteConsigneeLocation = async (locationId: number, token:string) => {
  let response = await api.delete(`locations/consignee_locations/${locationId}`,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    });
  return response.data
};


interface PickupLocation {
    id: number,
    name: string,
    contact_person: string,
    contact_number: string,
    contact_email: string,
    address: string,
    country_code: string,
    city: string,
    state: string,
    postal: string,
    province: string,
    created_on: string,
    updated_on: string,
    type: string
}

interface ConsigneeLocation {
  id: number,
  name: string,
  contact_person: string,
  contact_number: string,
  contact_email: string,
  address: string,
  country_code: string,
  city: string,
  state: string,
  postal: string,
  province: string,
  created_on: string,
  updated_on: string
}

interface OriginCountryList {
  id: number,
  country_code: string,
  country_name: string,
}

interface DestinationCountryList {
  destination_country_id: number,
  destination_country_name: string,
  destination_country_code: string,
  service_type: Array<[]>
}

interface ReturnsDestinationCountryList {
  destination_country_id: number,
  destination_country_name: string,
  destination_country_code: string,
  service_type: Array<[]>
}

interface ServiceDefinitionsData {
  "dropoff_id": number,
  "dropoff_address": string,
  "dropoff_postal": string,
  "dropoff_point_country": string,
  "dropoff_point_state": string,
  "dropoff_point_city": string,
  "dropoff_point_province": string,
  "dropoff_point_contact_person": string,
  "dropoff_point_number": string,
  "source": string,
  "additional_data": string,
  "service_id": number,
  "origin_country": string,
  "destination_country": string,
  "service_type": string,
}

interface ServiceDefinitionsDataFromB2c {
  dropoff_id: number,
  dropoff_address: string,
  dropoff_postal: string,
  dropoff_point_country: string,
  dropoff_point_state: string,
  dropoff_point_city: string,
  dropoff_point_province: string,
  dropoff_point_contact_person: string,
  dropoff_point_number: string,
  source: string,
  additional_data: string,
  service_id: number,
  origin_country: string,
  destination_country: string,
  service_type: string,
  exclusive_agents: [],
  exclusive_agents_ids: []
}

export const getLocations = async (token:string) => {
  let response = await api.get<Array<PickupLocation>>('locations/pickup_locations/',
    {
      headers: {
        'Authorization': `Token ${token}`
      }});
  return response.data
};

interface PickupLocationFromB2C extends AddressProps{
  pickup_point_id: number,
  is_active: boolean
}

interface GetConsigneeLocationsFromB2C extends CustomerAddressPropsInB2C {
  id: number,
  default: boolean,
  created_at: string,
  updated_at: string,
  shipper_profile: number
}
export const getLocations_from_b2c = async (secret_key:string) => {
  let response = await apib2c.get<Array<PickupLocationFromB2C>>(`shipper/pickup-point/?secret_key=${secret_key}`);
  return response.data
};

export const geReturntLocations_from_b2c = async (secret_key:string) => {
  let response = await apib2c.get<Array<AddressPropsForReturns>>(`shipper/return-point/?secret_key=${secret_key}`);
  return response.data
};

export const getLocationsByCountry_from_b2c = async (secret_key:string, country: string) => {
  let response = await apib2c.get<Array<PickupLocationFromB2C>>(`shipper/pickup-point/?secret_key=${secret_key}&pickup_point_country=${country}`);
  return response.data
};

export const geReturntLocationsByCountry_from_b2c = async (secret_key:string, country: string) => {
  let response = await apib2c.get<Array<AddressPropsForReturns>>(`shipper/return-point/?secret_key=${secret_key}&country=${country}`);
  return response.data
};

export const getConsigneeLocation_from_b2c = async (secret_key:string) => {
  let response = await apib2c.get<Array<GetConsigneeLocationsFromB2C>>(`shipper/consignee-address/?secret_key=${secret_key}`);
  return response.data
};

export const getConsigneeLocation = async (token:string) => {
  let response = await api.get<Array<ConsigneeLocation>>('locations/consignee_locations/',
    {
      headers: {
        'Authorization' : `Token ${token}`
      }});
  return response.data
}

export const getOriginCountries = async (token:string) => {
  let response = await api.get<Array<OriginCountryList>>('orders/service/origin_countries/',
    {
      headers: {
        'Authorization': `Token ${token}`
      }});
  return response.data
};

export const getAllCountries_from_b2c = async () => {
  let response = await apib2c.get('/data/places/');
  return response.data
};

export const getReturnsOriginCountries = async (token:string) => {
  let response = await api.get<Array<OriginCountryList>>('/orders/service/origin_countries/?return=true',
    {
      headers: {
        'Authorization': `Token ${token}`
      }});
  return response.data
};

export const getReturnsDestinationCountries = async (token: string, originCountryCode: string) => {
  let response = await api.get<Array<ReturnsDestinationCountryList>>(`/orders/service/service_origin_country/?return=true&origin_country=${originCountryCode}`,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    });
  return response.data
}

export const getReturnsServiceTypes_from_b2c = async (secret_Key: string, p_Country: string, c_Country: string) => {
  let response = await apib2c.get<[]>(`/order/returns-service-types/?secret_key=${secret_Key}&pickup_country=${p_Country}&consignee_country=${c_Country}`);
  return response.data
}

export const getDestinationCountries = async (token:string, originCountryCode: string, returns?: boolean) => {
  let queryString = `orders/service/service_origin_country/?origin_country=${originCountryCode}`
  queryString = returns? queryString + `&return=true`: queryString
  let response = await api.get<Array<DestinationCountryList>>(queryString,
    {
      headers: {
        'Authorization': `Token ${token}`
      }});
  return response.data
};

export const getServiceDefinitions = async (token:string) => {
  let response = await api.get<Array<ServiceDefinitionsData>>('/orders/service/service_definitions/',
    {
      headers: {
        'Authorization': `Token ${token}`
      }});
  return response.data
};

export const getServiceDefinitions_from_b2c = async (secret_key:string) => {
  let response = await apib2c.get<Array<ServiceDefinitionsDataFromB2c>>(`/order/service-definitions/?secret_key=${secret_key}`);
  return response.data
};

export const getForwardOrderDetails = async (token: string, trackingNumber: string) => {
  let response = await api.get(`/orders/orders/get_pickup_consignee_details_for_return_order/?janio_order_id=${trackingNumber}`,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }
  )
  return response.data
}

export const getForwardOrderDetails_from_b2c = async (secret_key: string, trackingNumber: string, token: string) => {
  let response = await apib2c.get(`/order/forward-order-details/?tracking_no=${trackingNumber}&secret_key=${secret_key}`)
  return response.data
}

interface ReturnOrderB2CInterface {
  pickup_country?: string,
  within_coverage?: boolean,
  pickup_contact_name?: string,
  pickup_contact_email?: string,
  pickup_contact_number?: string,
  pickup_address?: string,
  pickup_state?: string,
  pickup_city?: string,
  pickup_postal?: string,
  pickup_date?: string | null
  service?: string,
  firstmile_type?: string,
  service_type?: string,
  dropoffObject?: {},
  is_label_less_order?: boolean,
  order_weight?: string,
  order_height?: string,
  order_width?: string,
  order_length?: string,
  items?: {
    item_desc?: string,
    item_category?: string,
    item_quantity?: string,
    item_product_id?: string,
    item_sku?: string,
    item_price_currency?: string,
    item_price_value?: string,
  },
  consignee_country?: string,
  consignee_address?: string,
  consignee_state?: string,
  consignee_city?: string,
  consignee_postal?: string,
  delivery_notes?: string,
  addressValue?: string,
  consignee_name?: string,
  consignee_email?: string,
  consignee_number?: string,
  forward_tracking_no?: string,
  tracking_no?: string
}
export const returnValidation_in_b2c = async (data: ReturnOrderB2CInterface) => {
  let response = await apib2c.post<ReturnOrderB2CInterface>(`/order/return-order-validation/`, data)
  return response.data
}

export const makePayment = async (token: string, params: object) => {
  let response = await api.post('payments/transaction/charge/',
    params,
    {
      headers: {
        'Authorization': `Token ${token}`
      }});
  return response.data
};

interface RechargeResponse {
  transaction_id: string
  gateway_transaction_id: string
}
interface XenditResponse extends RechargeResponse{
  payment_gateway: 'Xendit'
  gateway_payload: {
    invoice_url: string
  }
}
interface StripeResponse extends RechargeResponse{
  payment_gateway: 'Stripe'
  gateway_payload: {
    session_id: string
  }
}
interface NetsResponse extends RechargeResponse{
  payment_gateway: string
  gateway_payload: {
    payload: string
    signature: string
  }
}

type WalletRechargeResponse = StripeResponse | XenditResponse | NetsResponse

export const createWalletRecharge = async ( token: string, params: object) => {
  let response = await api.post<WalletRechargeResponse>(`payments/transaction/recharge/`, params,
    {
      headers: {
        'Authorization': `Token ${token}`,
        'content-type': 'application/json'
      }
    });
  return response.data

};

export const paymentMethod = async ( token: string, params: object) => {
  let response = await api.post(`payments/payment_methods/`, params,
    {
      headers: {
        'Authorization': `Token ${token}`,
        'content-type': 'application/json'
      }
    });
  return response.data

};

interface CardDetails {
  payment_methods: [
    {
      name: string,
      gateway_payment_method_id: string,
      id: number
    }
  ]
}
interface CardDetailBills {
  count: number,
  results: [
    {
      type: string,
      status: string,
      amount: string,
      currency: string,
      created_at: string,
      updated_at: string,
      transaction_id: string
    }
  ]
}
export const getCardData = async ( token: string) => {
  let response = await api.get<CardDetails>(`payments/payment_methods`,
    {
      headers: {
        'Authorization': `Token ${token}`,
        'content-type': 'application/json'
      }
    });
  return response.data

};

export const getCardDataBills = async (cardID:number, token: string, params: { limit: number, offset: number }) => {
  let response = await api.get<CardDetailBills>(`payments/payment_methods/${cardID}?limit=${params.limit}&offset=${params.offset}`,
    {
      headers: {
        'Authorization': `Token ${token}`,
        'content-type': 'application/json'
      }
    });
  return response.data

};

export const deleteCardData = async (cardID: number, token:string) => {
  let response = await api.delete(`payments/payment_methods/${cardID}`,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    });
  return response.data
};

export const createOrder = async (token: string, params: {}) => {
  return await api.post('orders/orders/', params,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }
  );

};

type Channel = {
  name: string,
  type: string,
}

type ExtraData = {
  location_id: number,
}

export interface DiscountRequest{
  discount_code: string
  order_charges: Array<{
    order_id: number|undefined,
    amount: string
  }>
}

export interface FetchOrderResponse{
  id: number,
  tracking_no: string,
  channel?: Channel,
  consignee_name: string,
  consignee_address: string,
  consignee_country: string,
  consignee_state: string,
  consignee_city: string,
  consignee_number: string,
  consignee_postal: string,
  consignee_email: string,
  consignee_id: string,
  consignee_id_type: string,
  dropoff_vendor_point_id: number,
  dropoff_vendor_name?: string,
  extra_data?: ExtraData,
  is_editable: boolean,
  janio_order_id?:string,
  pickup_contact_name: string,
  pickup_contact_number: string,
  pickup_address: string,
  pickup_state: string,
  pickup_city: string,
  pickup_province: string,
  pickup_postal: string,
  pickup_country: string,
  pickup_date: string,
  pickup_location: number | null,
  billing_location: number | null,
  billing_contact_name: string,
  billing_contact_number: string,
  billing_address: string,
  billing_state: string,
  billing_city: string,
  billing_postal: string,
  billing_country_code: string,
  billing_country: string,
  order_id?: number,
  order_length: string,
  order_width: string,
  order_height: string,
  order_weight: string,
  order_type: string,
  origin: string,
  payment_type: string,
  cod_currency:string,
  incoterm: string,
  cod_amount: string,
  label_url: string,
  payment_status: string,
  amount: number,
  amount_to_pay: number,
  service_type: string,
  currency: string,
  gst_amount: string
  total_duties: string
  total_taxes: string
  is_tax_payable: boolean
  total: number
  items: Array<{
    id?: string,
    item_desc: string,
    item_quantity: string,
    item_sku: string,
    item_category: string,
    item_price_currency: string,
    item_price_value: string,
    item_product_url: string,
    gst_ovr: string,
    gst_collected: boolean,
    product_id?: string,
    item_product_id: string
  }>
  packages?: Array<{
    items: Array<{
      id?: string,
      item_desc: string,
      item_quantity: string,
      item_sku: string,
      item_category: string,
      item_price_currency: string,
      item_price_value: number,
      item_product_url: string,
      gst_ovr?: string,
      gst_collected?: boolean,
    }>
    janio_order_id: string,
    package_height: string,
    package_length: string,
    package_no: number,
    package_weight: string,
    package_width: string,
    tracking_no : string
  }>
}

export interface FetchOrderResponseFromB2C_Shopify {
  id: number,
  order_id: number | null,
  service_id: number | null,
  tracking_no: string,
  shipper_order_id: string,
  status: string,
  consignee_name: string,
  consignee_address: string,
  consignee_postal: string,
  consignee_country: string,
  consignee_state: string,
  consignee_city: string,
  consignee_province: string | null,
  consignee_number: string,
  consignee_email: string,
  order_length: number | null,
  order_width: number | null,
  order_height: number | null,
  order_weight: number | null,
  pickup_contact_name: string,
  pickup_contact_number: string,
  pickup_country: string,
  pickup_state: string,
  pickup_city: string,
  pickup_province: string | null,
  pickup_postal: string,
  pickup_address: string,
  delivery_note: string | null,
  incoterm: string | null,
  payment_type: string,
  payment_status: string,
  service_type: string,
  pickup_date: string,
  service_level: string,
  fulfillment_status: string,
  cod_amt_to_collect: number | null,
  cod_currency: string | null,
  ref_cod_amt_to_collect: string | null,
  ref_cod_currency: string,
  channel: {
      id: number,
      channel_name: string,
      channel_type: string,
      shop_name: string,
      created_on: string,
      channel_data: []
  },
  channel_order_id: string,
  other_status: string,
  items: [
      {
          id: string | null,
          item_sku: string,
          item_description: string,
          item_qty: number,
          item_category: string,
          item_price_currency: string,
          item_price_value: number
      }
  ],
  validation_result: string | null,
  extra_data: {pickup_point_id: number | null, dropoff_id: number | null}
}
export interface FetchOrderResponseFromB2CAPI{
  order_id: number,
  forward_order_id: string | null,
  return_order_id: string | null,
  is_return_order: boolean,
  is_label_less_order: boolean,
  service_id: number,
  service_name: string,
  service_level: string,
  agent_application_id: string,
  store_id: number | null,
  tracking_no: string,
  shipper_sub_account_id: number | null,
  shipper_order_id: number | null,
  shipper_sub_order_id: number | null,
  consignee_name: string,
  consignee_address: string,
  consignee_postal: string,
  consignee_country: string,
  consignee_state: string,
  consignee_city: string,
  consignee_province: string | null,
  consignee_number: string | null,
  consignee_email: string,
  order_direction: string,
  order_length: string,
  order_width: string,
  order_height: string,
  order_weight: string,
  pickup_contact_name: string,
  pickup_contact_number: string,
  pickup_country: string,
  pickup_state: string,
  pickup_city: string,
  pickup_province: string | null,
  pickup_address: string,
  pickup_postal: string,
  pickup_date: string,
  pickup_time_from: string,
  pickup_time_to: string,
  pickup_notes: string | null,
  payment_type: string,
  identification_document_name: string | null,
  consignee_identifcation_number: number | null,
  incoterm: string | null,
  cod_amt_to_collect: number | null,
  items: [
    {
      item_id: number,
      item_desc: string,
      item_product_id: number,
      item_sku: string,
      item_category: string,
      item_quantity: number,
      item_price_value: string,
      item_price_currency: string,
      hs_code: string | null,
      item_url: string | null,
      lvg: boolean,
      gst_collected: boolean,
      gst_ovr: string | null
    }
  ],
  delivery_note: string | null,
  print_default_label: boolean,
  upload_batch_no: string,
  is_processing: boolean,
  created_on: string,
  updated_on: string,
  order_label_url: string | null,
  additional_data: {
    order_direction: string,
    billing_category: string
  },
  tracker_status_code: string,
  tracker_main_text: string | null,
  tracker_updated_on: string,
  private_tracker_status_code: string,
  private_tracker_updated_on: string,
  agent_application_name: string,
  return_tracking_no: number | null,
  forward_tracking_no: number | null,
  consignee_currency: string,
  consignee_currency_text: string,
  external_tracking_nos: [],
  external_order_data: [],
  label_url_browser: string,
  shipping_batch_nos: [],
  customs_documents_status: string | null,
  customs_documents: [],
  weight_recon_records: [
    {
      length: number,
      width: number,
      height: number,
      weight: number,
      created_on: string,
      source: string
    }
  ],
  partner_submission_status: {
    total_count: number,
    success_count: number,
    error_count: number
  },
  order_exceptions: [],
  escalation_status: string | null,
  is_escalatable: boolean,
  public_tracker_status_v2: string,
  private_tracker_status_v2: string,
  order_type: string,
  special_instruction: string | null,
  service_category: string
}

export interface FetchOrderResponseFromB2CAPIArray extends Array<FetchOrderResponseFromB2CAPI>{}
export interface AdditionalResponseInReturnOrders extends FetchOrderResponse {
  order_direction: string,
  pickup_contact_email: string,
  forward_janio_order_id : string,
  within_coverage: boolean,
  service: string,
  firstmile_type: string,
  dropoffObject: {},
  is_label_less_order: boolean,
  delivery_notes: string,
  addressValue: string,
  forward_tracking_no: string
}
export interface FetchOrderRateResponse{
  total: number,
  currency: string,
  amount_to_pay: number
  gst_amount: string
  shipping_charge: string
  duties_and_taxes: string
  total_duties: string
  total_taxes: string
  charge_id: number
  pickup_charge: string
  is_tax_payable: boolean
}

export interface TopUpBonusRequest{
  code: string
  topup_size: number
}

export const fetchOrder = async (token: string, orderId: {}) => {
  
  let response = await apib2c.get(`order/order/${orderId}/?secret_key=${token}`,
  );
  return response.data[0];
};

export const fetchOrder_from_b2c = async (orderId: {}, secretKey: string) => {
  let response = await apib2c.get<FetchOrderResponseFromB2CAPIArray>(`order/order/${orderId}/?secret_key=${secretKey}`);
  return response.data;
};

// fetching order details from SS
export const fetchOrderFromSS = async (token: string, orderId: {}) => {
  let response = await api.get<AdditionalResponseInReturnOrders>(`orders/orders/${orderId}/`,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }
  );
  return response.data;
};

export const deleteOrder = async (token: string, orderId: number) => {
  let response = await api.delete(`orders/orders/${orderId}/`,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }
  );
  return response.data;
};

export const cancelOrder_on_b2c = async (secret_key: string, tracking_no: string) => {
  let data = {secret_key: secret_key, tracking_nos: [tracking_no]}
  let response = await apib2c.post(`order/cancel-orders/`, {...data}
  );
  return response.data;
};

export const fetchMarketplaceOrder = async (token: string, orderId: {}) => {
  let response = await api.get<FetchOrderResponse>(`/channels/draft-orders/${orderId}/`,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }
  );
  return response.data;
};

export const fetchMarketplaceOrderFromB2C = async (secret_key: string, orderId: {}) => {
  let response = await apib2c.get<FetchOrderResponseFromB2C_Shopify>(`/channel/draft-orders/${orderId}/?secret_key=${secret_key}`);
  return response.data;
};

export const fetchOrderRate = async (token: string, orderId: {}) => {
  let response = await api.get<FetchOrderRateResponse>(`orders/orders/${orderId}/get_amount_to_pay/`,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }
  );

  return response.data;
};

export const getOrdersSummary = async (token: string) => {
  let response = await api.get('/orders/summary/',
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    });
  return response.data;
};

export const submitReturnOrderForGetRate = async (token: any, fileName: string, orderData: any) => {
  let data = {file_name: fileName, orders: [...orderData], order_direction: 'RETURNS'};
  let response = await api.post('orders/bulk/submit_orders_for_get_rate/', {...data}, {
    headers: {
      'Authorization': `Token ${token}`
    }
  });
  return response.data;
};

export const submitOrderForGetRate = async (token: any, fileName: string, orderData: any) => {
  let data = {file_name: fileName, orders: [...orderData]};
  let response = await api.post('orders/bulk/submit_orders_for_get_rate/', {...data}, {
    headers: {
      'Authorization': `Token ${token}`
    }
  });
  return response.data;
};

export const submitMpsOrderForGetRate = async (token: any, fileName: string, orderData: any) => {
  let data = {file_name: fileName, orders: [...orderData]};
  let response = await api.post('orders/bulk_mps/submit_orders_for_get_rate/', {...data}, {
    headers: {
      'Authorization': `Token ${token}`
    }
  });
  return response.data;
};

export const validateAndGetRates = async (token: any, fileName: string, orderData: any) => {
  let data = {file_name: fileName, orders: [...orderData]};
  let response = await api.post('orders/bulk/validate_and_get_rate/', {...data}, {
    headers: {
      'Authorization': `Token ${token}`
    }
  });
  return response.data;
};

export const submitBulkOrder = async (token: any, orderData: any) => {
  let data = {orders: [...orderData]};
  let response = await api.post('orders/bulk/', {...data}, {
    headers: {
      'Authorization': `Token ${token}`
    }
  });
  return response.data;
};

export const get_bulk_orders_data = async (token:any, batch_no:any) =>{
  let response = await api.get(`orders/bulk/get_orders_by_batch_no/?batch_no=${batch_no}`,{
    headers: {
      'Authorization': `Token ${token}`
    }
  });
  return response.data;
};

export const get_bulk_orders_rates = async (token:any, batch_no:any) =>{
  let response = await api.get(`orders/bulk/get_rate_by_batch_no/?batch_no=${batch_no}`,{
    headers: {
      'Authorization': `Token ${token}`
    }
  });
  return response.data;
};

export const get_batch_orders = async (token:any, batch_no:any) =>{
  let response = await api.get(`orders/bulk/get_batch_orders/?batch_no=${batch_no}`,{
    headers: {
      'Authorization': `Token ${token}`
    }
  });
  return response.data;
};

export const getBulkOrderPriceEstimatesByBatchId = async (token:any, batch_no:any) =>{
  let response = await api.get(`orders/bulk/price_estimate_by_batch_id/?batch_id=${batch_no}`,{
    headers: {
      'Authorization': `Token ${token}`
    }
  });
  return response.data;
};

export const getErrosByBatchId = async (token:any, batch_no:any) =>{
  let response = await api.get(`/orders/bulk/get_reason_for_failure/?batch_id=${batch_no}`,{
    headers: {
      'Authorization': `Token ${token}`
    }
  });
  return response.data;
};

export const getStates = async  (token:string, country_code:string) => {
  let response = await api.get(`locations/${country_code}/states/`,{
    headers: {
      'Authorization': `Token ${token}`
    }
  });
  return response.data;
};

export const getCities = async  (token:string, state:string) => {
  let response = await api.get(`locations/${state}/cities/`,{
    headers: {
      'Authorization': `Token ${token}`
    }
  });
  return response.data;
};

interface StateList {
  state_id: number,
  state_name: string,
  foreign_translation: string,
  is_available: boolean
}
export const getAddressById_from_b2c = async (token: string, address_id:string) => {
  let response = await apib2c.get<PickupLocationFromB2C>(`shipper/pickup-point/${address_id}/?secret_key=${token}`);
  return response.data;
};

export const getReturnAddressById_from_b2c = async (token: string, address_id:string) => {
  let response = await apib2c.get<AddressPropsForReturns>(`shipper/return-point/${address_id}/?secret_key=${token}`);
  return response.data;
};

export const getConsigneeAddressById_from_b2c = async (token: string, address_id:string) => {
  let response = await apib2c.get<GetConsigneeLocationsFromB2C>(`shipper/consignee-address/${address_id}/?secret_key=${token}`);
  return response.data;
};
interface StateList {
  state_id: number,
  state_name: string,
  foreign_translation: string,
  is_available: boolean
}
export const getStates_from_b2c = async  (country:string) => {
  let response = await apib2c.get<Array<StateList>>(`location/states/?countries=${country}`);
  return response.data;
};

interface CityList {
  city_id: number,
  city_name: string,
  foreign_translation: string,
  country: string,
  display_name: string,
  is_available: boolean
}
export const getCities_from_b2c = async  (country: string, state:string) => {
  let response = await apib2c.get<Array<CityList>>(`location/cities/?countries=${country}&states=${state}`);
  return response.data;
};

export const updateBulkOrder =  async (token: any, orderData: any) => {
  let response = await api.put(`orders/bulk/update_orders/?batch_no=${orderData['orders'][0].batch_no}`, {...orderData}, {
    headers: {
      'Authorization': `Token ${token}`
    }
  });
  return response.data;
};

export const createBatchOrder =  async (token: any, params: {batch_id: string}) => {
  let response = await api.post(`orders/bulk/create_batch_order/`, params, {
    headers: {
      'Authorization': `Token ${token}`
    }
  });
  return response.data;
};

export const orderPartialValidate = async (token: string, orderData: any, order_direction?: any) => {
  let payload = {}
  if(order_direction){
    payload = {
      is_returns: true,
      is_mps: false,
      orders: orderData,
      secret_key: token,
    }
  }else{
    payload = {
      is_mps: false,
      orders: orderData,
      secret_key: token,
    }
  }
  let response = await apib2c.post(`/order/validate-csv/`,
    payload,
  );
  return response.data
};

export const mpsOrderPartialValidate = async (token: string, orderData: any) => {
  let response = await api.post(`orders/bulk_mps/partial_validate/`,
    orderData,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }
  );
  return response.data
};

interface RateEstimateRequest{
  pickup_country?: string,
  pickup_state?: string,
  consignee_country?: string,
  consignee_state?: string,
  order_weight: number | null,
}

export const getPriceEstimates = async (token: string, params: RateEstimateRequest) => {
  let response = await api.post('orders/orders/get_rate_estimate/',
    params,
    {
      headers: {
        'Authorization': `Token ${token}`
      }});
  return response.data
};

export const getPriceEstimatesb2c = async (token: string, params: RateEstimateRequest) => {
  let response = await apib2c.post(`/order/rates/?secret_key=${token}`,
    params);
  return response.data
};

export const getDiscount = async (token: string, payload: DiscountRequest) => {
  let response = await api.post('orders/promotion/get_discounts/',
    payload,
    {
      headers: {
        'Authorization': `Token ${token}`
      }});
  return response.data
};

export const createMonthlyBillingBulkOrder_in_b2c = async (token: string, orderData: any) => {
  let response = await apib2c.post(`/order/orders/`,{
    secret_key: token,
    orders: orderData
  });
  return response.data;
}

export const createMonthlyBillingReturnBulkOrder_in_b2c = async (token: string, orderData: any) => {
  let response = await apib2c.post(`order/return-orders/`,{
    secret_key: token,
    orders: orderData
  });
  return response.data;
}

export const createMonthlyBillingShopifyOrder_in_b2c = async (orderData: any) => {
  let response = await apib2c.post(`channel/draft-orders/create-shipments/`, orderData);
  return response.data;
}
export const createMonthlyBillingSingleOrder_in_b2c = async (token: string, orderData: any) => {
  let response = await apib2c.post(`order/order/`, orderData);
  return response.data;
}

export const createReturnSingleOrder_in_b2c = async (secret_key: string, orderData: any) => {
  let requestData = {
    orders: [orderData],
    secret_key: secret_key
  }
  let response = await apib2c.post(`order/return-orders/`, requestData);
  return response.data;
}

export const confirmMonthlyBillingSingleOrder = async (token: string, orderId: number) => {
  let response = await api.post(`orders/orders/${orderId}/confirm_order_for_monthly_billing/`, {}, {
    headers: {
      'Authorization': `Token ${token}`
    }
  });
  return response.data;
}

export const confirmMonthlyBillingForMultipleSingleOrders = async (token: string, orderIds: any) => {
  let data = {order_ids: orderIds};
  let response = await api.post(`orders/bulk/confirm_order_for_monthly_billing/`,
    data, {
      headers: {
        'Authorization': `Token ${token}`
      }
    });
  return response.data;
}

export const confirmMonthlyBillingBulkOrder = async (token: string, batch_no:string) => {
  let response = await api.post(`orders/bulk/confirm_order_for_monthly_billing/?batch_no=${batch_no}`, {}, {
    headers: {
      'Authorization': `Token ${token}`
    }
  });
  return response.data;
}

export const submitClaimForm = async (secret_key: string, data:any) => {
  let response = await apib2c.post(`/order_claims/claim_submissions/?secret_key=${secret_key}`, {data}, {
  });
  return response.data;
}

export const fileUpload = async (file: any, secret_key: string) => {
  return fileToBase64(file)
    .then((base64String) => {
      return apib2c.post (`/attachments/client-file/?secret_key=${secret_key}`,{
        "extension": extension[file.type] ?? 'png', 
        "file_name": file.name, 
        "bucket_name": "janio-order-claims", 
        "file": base64String
      },{});
    })
    .then((response)=> {
      return response.data
    })
    .catch((error) => {
      console.error('Error converting file to base64:', error);
    });  
}

export const removeUploadedFile = async (secret_key: string, id: any) => {
  let requestBody = {
    "bucket_name": "janio-order-claims",
  }
  let response = await apib2c.delete(`attachments/client-file/${id}/?secret_key=${secret_key}`, {data: requestBody});
  return response.data
};

export const getTopUpBonus = async (token: string, payload: TopUpBonusRequest) => {
  let response = await api.post('promotions/topup_bonus/check_topup_bonus/',
    payload,
    {
      headers: {
        'Authorization': `Token ${token}`
      }});
  return response.data
};

// interface CreateShipmentRule{
//   pickup_country_code: string,
//   pickup_state?: string,
//   consignee_country_code: string,
//   consignee_state: string,
//   order_weight: number | null,
// }

export const createShipmentRules = async (token: string, params: any) => {
  let response = await api.post('shipment_rules/draft-order-rules/',
    params,
    {
      headers: {
        'Authorization': `Token ${token}`
      }});
  return response.data
};

export interface CreateShipmentRules {
  rule_name: string,
  action_type: string,
  rule_source_fields: Array<{
    source_field_name: string,
    operator: string,
    matches_to_value: string
  }>,
    rule_target_fields: Array<
      {
        target_name: string,
        target_value: string
      }
    >,
    apply_on_existing_order: boolean
}
export const createShipmentRulesInB2C = async (secret_key: string, params: any) => {
  let response = await apib2c.post<CreateShipmentRules>(`channel/draft-order-rules/?secret_key=${secret_key}`,
    params
  );
  return response.data
};

export const getShipmentRuleDataFromB2C = async  (secret_key:string, rule_id:string) => {
  let response = await apib2c.get(`channel/draft-order-rules/${rule_id}/?secret_key=${secret_key}`);
  return response.data;
};

export const getShipmentRuleData = async  (token:string, rule_id:string) => {
  let response = await api.get(`shipment_rules/draft-order-rules/${rule_id}/`,{
    headers: {
      'Authorization': `Token ${token}`
    }
  });
  return response.data;
};

export const updateShipmentRuleData = async  (token:string, params: any, rule_id:string) => {
  let response = await api.put(`shipment_rules/draft-order-rules/${rule_id}/`,
    params,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }
  );
  return response.data;
};

export const updateShipmentRuleDatainB2C = async  (secret_key:string, params: any, rule_id:string) => {
  let response = await apib2c.put(`channel/draft-order-rules/${rule_id}/?secret_key=${secret_key}`,
    params
  );
  return response.data;
};

export const updateSubAccountStatusData = async  (token:string, params: any, rule_id:string) => {
  let response = await api.put(`auth/sub-accounts/${rule_id}/`,
    {
      sub_account_disabled: !params.is_active,
      combined_billing: params.combined_billing,
    },
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }
  );
  return response.data;
};
interface ShipmentRulesDataFromB2C {
  count: number,
  next: number | null,
  previous: number | null,
  results: Array<{
    action_type: string,
    rule_source_fields: Array<{
      source_field_name: string,
      operator: string,
      matches_to_value: string,
      id: number
    }>,
    rule_target_fields: Array<{
      target_name: string,
      target_value: string,
      id: number
    }>,
    id: number,
    created_on: string,
    updated_on: string,
    rule_name: string,
    is_active: boolean,
    apply_on_existing_order: boolean,
    meta_data: null,
    sequence_no: number
  }>
}
export const getShipmentRuleListDataFromB2C = async  (secret_key:string) => {
  let response = await apib2c.get<ShipmentRulesDataFromB2C>(`channel/draft-order-rules/?secret_key=${secret_key}&page_size=100`);
  return response.data;
};

export const getShipmentRuleList = async  (token:string) => {
  let response = await api.get(`shipment_rules/draft-order-rules/?page_size=100`,{
    headers: {
      'Authorization': `Token ${token}`
    }
  });
  return response.data;
};

export const deleteShipmentRule = async  (token:string, id: number) => {
  let response = await api.delete(`shipment_rules/draft-order-rules/${id}`,{
    headers: {
      'Authorization': `Token ${token}`
    }
  });
  return response.data;
};

export const deleteShipmentRuleInB2C = async  (secret_key:string, id: number) => {
  let response = await apib2c.delete(`channel/draft-order-rules/${id}/?secret_key=${secret_key}`);
  return response.data;
};

export const swapShipmentRuleSequenceInB2C = async  (secret_key:string, moveUp: number, moveDown: number) => {
  let response = await apib2c.put(`channel/draft-order-rules/${moveUp}/swap-sequence/${moveDown}/?secret_key=${secret_key}`,{});
  return response.data;
};

export const swapShipmentRuleSequence = async  (token:string, moveUp: number, moveDown: number) => {
  let response = await api.put(`shipment_rules/draft-order-rules/${moveUp}/swap-sequence/${moveDown}/`,{}, {
    headers: {
      'Authorization': `Token ${token}`
    }
  });
  return response.data;
};

export const FetchDestinationCountries = (token: string,country:string, returns?:boolean) => {
  return useQuery(['destinations',country], async () => {
    return getDestinationCountries(token,country, returns)
  },{cacheTime:0})
}

export const GetServiceDefinitionsDataFromb2c = (token: string) => {
  return useQuery('serviceDefinitions', () => {
    return getServiceDefinitions_from_b2c(token)
  },{cacheTime:0})
} 

export const FetchDestinationStates = (token: string, country: string) => {
  return useQuery(['statesList', country], () => {
    return getStates(token, country)
  });
}

export const FetchDestinationStatesb2c = (country: string) => {
  return useQuery(['statesList', country], () => {
    return getStates_from_b2c(country)
  });
}

interface CodDdu {
  "pickup_country": string | undefined,
  "consignee_country": string | undefined,
}

interface CodDduRequestData{
  request: Array<CodDdu>
}

interface CheckCodDduResponse {
  consignee_country: string
  is_cod_enabled: boolean
  is_ddu_enabled: boolean
  pickup_country: string
  service: number
  service_type: string
}
export const fetchExtraUserData = async (token: string, data: CodDduRequestData) => {
  let response = await api.post<CheckCodDduResponse>('/orders/orders/get_cod_ddu_status/', data.request,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    });
  return response.data
};

interface AddSubAccountReq {
  is_existing_user: boolean,
  email: string,
  password: string,
  full_name: string,
  combined_billing: boolean,
  can_master_view_shipments: boolean,
  can_view_master_shipments: boolean,
  can_view_siblings_shipments: boolean,
  billing_country: string,
  phone_number: string
}
interface UpdateSubAccountReq {
  combined_billing: boolean,
  can_master_view_shipments: boolean,
  can_view_master_shipments: boolean,
  can_view_siblings_shipments: boolean,
}

export const addSubAccount = async (token: string, data: AddSubAccountReq ) => {
  let response = await api.post
  ('/auth/sub-accounts/', data,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    });
  return response.data
};

export const updateSubAccount = async (token: string, data: UpdateSubAccountReq, id: number ) => {
  let response = await api.put
  (`/auth/sub-accounts/${id}/`, data,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    });
  return response.data
};

interface RuleListInterface {
  allow_postpaid: boolean | null
  b2c_id: string
  billing_address: null
  billing_country: string
  can_master_view_shipments: boolean | null
  can_view_master_shipments: boolean | null
  can_view_siblings_shipments: boolean | null
  combined_billing: boolean | null
  disabled: boolean
  id: number
  label_type: string
  name: string
  notes: string | null
  parent: number
  payment_type: string
  tnc: boolean
  user_data: {
    email: string, 
    id: number
  }
}

interface SubAccountsOrderData {
  count: number,
  results?: Array<RuleListInterface>
}

export const getSubAccounts = async  (token:string, params?: { page_size: number, page: number }) => {
  let response = await api.get<SubAccountsOrderData>(`auth/sub-accounts/?page=${params?.page}&page_size=${params?.page_size}`,{
    headers: {
      'Authorization': `Token ${token}`
    }
  });
  return response.data;
};

export const setFuturePickupDate = async (token: string, params: any) => {
  let data = params;
  let response = await api.post(`orders/orders/update_pickup_date/`,
    data, {
      headers: {
        'Authorization': `Token ${token}`
      }
    });
  return response.data;
}

// let removeFalseKeys = (obj:any) => {
//   return Object.entries(obj).filter(([_, value]) => !!value).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
// }

export const getExceptionListQuery = async (token: string, params: { secret_key: string, agent_application_ids: any[], page_size: number, page?: number, tracking_no?: string, cursor?: string }) => {
  let response = await apib2c.post(`order/flagged-orders/`,
    params
  );
  return response.data
}

export const getExceptionByTrackingIdMutation = async (token: string, tracking_no: any, flag: any) => {
  let req = {
    secret_key: token,
    tracking_no: tracking_no,
    flag: flag
  }
  let response = await apib2c.post(`order/flagged-order/`,
    req
  );
  return response.data
}

export const submitDisposition = async (token: string, tracking_no: any, params: any) => {
  let req = {
    ...params,
    secret_key: token,
    tracking_no: tracking_no
  }
  let response = await apib2c.post(`order/disposition/`, req);
  return response.data
}

interface PriceEstimatesAddress {
  request_id: string,
  address_type: string,
  country: string,
  state: string,
  city: string,
  postal_code: string,
  zone_type: string
}
export interface B2CPriceEstimatesResponse {
  total?: number,
  currency?: string,
  weight_upper_bound?: string,
  chargable_weight?: string,
  total_taxes?: number,
  total_duties?: number,
  gst_amount?: number,
  shipping_charge?: string,
  duties_and_taxes?: number
}
export interface B2CPriceEstimates  extends B2CPriceEstimatesResponse{
  pickup_country: string,
  consignee_country: string,
  consignee_state: string,
  order_weight: number | null,
  order_height?: number | null,
  order_length?: number | null,
  order_width?: number | null,
  pickup_state?: string,
  currency?: string,
  service_type?: string,
  order_direction?: string,
  pickup_postal?: string,
  consignee_postal?: string,
  dropoff_vendor_name?: string,
  addresses: Array<PriceEstimatesAddress>
}
export const fetch_b2c_price_estimates = async (secret_key: string, params: any) => {
  let data = params;
  let response = await apib2c.post<B2CPriceEstimates>(`order/rates/?secret_key=${secret_key}`,
    data);
  return response.data;
}

// Superset security login
export const getSupersetDataLogin = async (params: any) => {
  let response = await apiSuperset.post('v1/security/login',
    params,
    // {
    //   headers: {
    //     'Authorization': `Token ${token}`
    //   }}
  );
  return response.data
};

export function getSupersetDataGuest(params: any, access_token: any): Promise<string> {
  return new Promise<string>((resolve) => {
    apiSuperset.post("/v1/security/guest_token/", params , {
      headers: {
        'Authorization': `Bearer ${access_token}`
      }
    }).then((response: any) => {
      resolve(response.data.token);
    }).catch((error: any) => {
      if(error.response.data) {
        message.error(error.response.data.message)
      }
      else {
        message.error('We are facing some Issues, Please try after sometime.')
      }
    });
  })
}
