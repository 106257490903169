import {
  Form,
  Button,
  Col,
  Row,
  Select, 
  Alert,
  message
} from 'antd';
import React, {useState} from "react";
import {countries, countryCodes, IndonesiaIdentificationTypes, SouthKoreaIdentificationTypes} from "../commons/utils/constants";
import {FormInput, FormSelect} from "../orders/components/Forms";
import {sortResponseAlphabatically} from "../commons/utils/utilizer" 
import {Formik, Form as FormikForm,FormikHelpers} from 'formik';
import * as Yup from 'yup';
import {validatePostalCode} from "../commons/utils/validator";
import {useQuery,useMutation} from "react-query";
import {getStates, addConsigneeLocation, getCities} from "../orders/services/privateApi";
import {useAuth} from "../auth/services/authContext";
import { useLanguage } from '../languages/Language';
import './styles/ConsigneeAddressForm.css';
import { LabelRadio } from '../ReUsableComponents/ReUsable'

const {Option} = Select;

interface CustomerProps {
  consignee_name?: string,
  consignee_number?: string,
  consignee_email?: string,
  consignee_address?: string,
  consignee_country?: string,
  consignee_state?: string,
  consignee_city?: string,
  consignee_postal?: string,
  consignee_id?: string,
  consignee_id_type?: string,
  name?: string
}

const validationSchema = (getText: any) => {
  return Yup.object().shape({
    consignee_name: Yup.string().required(getText('Please enter Full Name')),
    consignee_number: Yup.string().required(getText('Please enter phone number')),
    consignee_email: Yup.string().email(getText('Please enter a valid email')).required(getText('Please enter a valid email')),
    consignee_address: Yup.string().required(getText('Please enter Address')),
    consignee_state: Yup.string().required(getText('Please select State')),
    consignee_city: Yup.string().required(getText('Please enter City')),
    consignee_country: Yup.string().required(getText('Please select Destination Country')),
    consignee_postal: Yup.string().required(getText('Please enter Postal Code')).test(
      'postal-check',
      (val: any, context: any) => {
        if(!val){
          return context.createError({
            path: context.path,
            message: getText('Please enter Postal Code')
          })
        }
        else{
          const error = validatePostalCode(countryCodes[context.parent.consignee_country],val);
          if(error){
            return context.createError({
              path: context.path,
              message: error
            })
          }
        }
        return true
      }
    )
  })};

type ServerErrors = {[key: string]: Array<string>};
const combineFieldsErrors = (errors: ServerErrors) => {
  let combined_errors: any = {}
  let initial_touched: any = {}

  for(const [key, val] of Object.entries(errors)){
    combined_errors[key] = val.join(',')
    initial_touched[key] = true
  }

  return {
    combined_errors,
    initial_touched
  }
}

export const ConsigneeAddressFormForShopify = (props:any) =>{
  const auth = useAuth();
  const resetFieldValuesOnCountryChange = ['consignee_city','consignee_postal','consignee_state'];
  const { getText } = useLanguage();
  const [country, setCountry] = useState<string>(props.countryCode ? props.countryCode : '');
  const [state, setState] = useState<string>('');
  const [freeText, setfreeText] = useState<string>('dropdown');
  const [initialTouched, setInitialTouched] = useState({});
  const statesList = useQuery(['statesList',country], () => {
    if(country){
      return getStates(auth.user.authToken, country!)
    }
  });
  const cityList = useQuery(['cityList', state], () => {
    if(state){
      return getCities(auth.user.authToken, state)
    }
  });

  const formInitialValue: CustomerProps = {
    consignee_name: '',
    consignee_number: '',
    consignee_email: '',
    consignee_address: '',
    consignee_country: props.countryCode ? props.countryCode : '',
    consignee_state: '',
    consignee_city: '',
    consignee_postal: '',
    consignee_id: '',
    consignee_id_type: '',
    name:''
  }

  const formatErrors = (errors: any) => {
    let formattedError: CustomerProps = {};
    for(const key in errors){
      if(key === 'contact_number'){
        formattedError['consignee_number'] = errors[key]
      }else if(key === 'postal'){
        formattedError['consignee_postal'] = errors[key]
      }else if(key === 'address'){
        formattedError['consignee_address'] = errors[key]
      }else if(key === 'city'){
        formattedError['consignee_city'] = errors[key]
      }else if(key === 'contact_email'){
        formattedError['consignee_email'] = errors[key]
      }else if(key === 'contact_person'){
        formattedError['name'] = errors[key]
      }else if(key === 'country_code'){
        formattedError['consignee_country'] = errors[key]
      }else if(key === 'state'){
        formattedError['consignee_state'] = errors[key]
      }else if(key === 'name'){
        formattedError['consignee_name'] = errors[key]
      }else{
        message.error(`${key}: ${errors[key]}`)
      }
    }
    return formattedError
  }

  // Change add consignee address api
  const createLocation = useMutation(async (value: CustomerProps) => await addConsigneeLocation(value, auth.user.authToken))
  
  const onSubmit = async (value: CustomerProps, {setErrors}: FormikHelpers<CustomerProps>) => {
    try{
      // Call consignee address api
      let location:any = await createLocation.mutateAsync(value)
      if(props.onSuccess){
        props.onSuccess(location)
        message.success("Successfully updated the consignee address")
      }
    }
    catch(e){
      const {combined_errors, initial_touched} = combineFieldsErrors(e.response.data)
      message.error("Failed to update the consignee address")
      const formatted_errors = formatErrors(combined_errors)
      setErrors(formatted_errors)
      setInitialTouched(initial_touched)
    }
  };

  const setFreeTextCallback = (val:string) => {
    setfreeText(val);
  };
  return (
    <Formik
      initialValues={formInitialValue}
      validationSchema={validationSchema(getText)}
      initialTouched={initialTouched}
      enableReinitialize= {true}
      onSubmit={onSubmit}
      validateOnChange={false}>
      { formik => (
        <div className={"consignee-address-form-container ant-form-vertical"}>
          <div style={{marginBottom:20,marginTop:-20}}>
            <Alert
              message={<p style={{margin:0}}> {getText("Please provide your")} <strong> {"customer's identification number if customer is from Indonesia"} </strong> {getText("to ensure a smooth delivery")}</p>}
              type="info"
              showIcon
            />
          </div>
          <FormikForm>
            <Row gutter={14}>
              <Col span={12}>
                <FormInput label={`${props.addressType.charAt(0).toUpperCase() + props.addressType.slice(1)} ${getText('Address Label')}`} name="name" wrapperClass={"no-margin-item"} />

                <Form.Item label={getText('Name will be displayed under address selection')} className={'form-item-info'}>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={14}>
              <Col span={12}>
                <FormInput required label={getText('Full Name')} name="consignee_name" />
              </Col>
              <Col span={12}>
                <FormInput required label={getText('Phone Number')} name="consignee_number"/>
              </Col>
            </Row>
            {countries[props.countryCode] === 'Indonesia' && <Row gutter={14}>
              <Col span={12}>
                <FormSelect label={getText('Identification Type')} name="consignee_id_type" onChangeCallback={(value: any) => {formik.setFieldValue("consignee_id", '')}}>
                  {
                    Object.entries(IndonesiaIdentificationTypes).map((type:any) => {
                      return  <Option key={type[0]} value={type[0]}>{type[1]}</Option>
                    })
                  }
                </FormSelect>
              </Col>
              {formik.values.consignee_id_type ?
                <Col span={12}>
                  <FormInput label={`${IndonesiaIdentificationTypes[formik.values.consignee_id_type]}`} name="consignee_id" />
                </Col> : ''
              }
            </Row>}
            {countries[props.countryCode] === 'South Korea' && <Row gutter={14}>
              <Col span={12}>
                <FormSelect label={getText('Identification Type')} name="consignee_id_type" onChangeCallback={(value: any) => {formik.setFieldValue("consignee_id", '')}}>
                  {
                    Object.entries(SouthKoreaIdentificationTypes).map((type:any) => {
                      return  <Option key={type[0]} value={type[0]}>{type[1]}</Option>
                    })
                  }
                </FormSelect>
              </Col>
              {formik.values.consignee_id_type ?
                <Col span={12}>
                  <FormInput label={`${SouthKoreaIdentificationTypes[formik.values.consignee_id_type]}`} name="consignee_id" />
                </Col> : ''
              }
            </Row>}
            <FormInput required label={getText('Email')} name="consignee_email" />
            <FormInput required label={getText('Address')} name="consignee_address" />

            <Row gutter={14}>
              <Col span={12}>
                <FormSelect required label={getText('Country')} name="consignee_country" onChangeCallback={(country_code:string) => {setCountry(country_code); resetFieldValuesOnCountryChange.forEach(field => formik.setFieldValue(field, ''))}}>
                  {
                    Object.entries(countries)
                      .filter(([key]) => props.countryCode ? key === props.countryCode : true)
                      .map(([key]) => (
                        <Option key={key} value={key}>{countries[key]}</Option>
                      ))
                  }
                </FormSelect>
              </Col>
              <Col span={12}>
                <FormSelect required label={getText('State')} name="consignee_state" onChangeCallback={(state:string) => {
                  setState(state);
                  formik.setFieldValue('consignee_city', '');
                  // setfreeText(false)
                }} loading={statesList.isLoading}>
                  {
                    (sortResponseAlphabatically(statesList?.data, 'state_name') || []).map((item: {id: number, state_name:string} ) => {
                      return  <Option key={item.id} value={item.state_name}>{item.state_name}</Option>
                    })
                  }
                </FormSelect>
                {statesList.isError && <p style={{color:'red', marginBottom:0}}>{getText("Failed to fetch states, try later")}</p>}
              </Col>
            </Row>
            <Row gutter={14}>
              <Col span={12}>
                {
                  formik.values.consignee_country === 'US' ?
                    freeText === 'dropdown' ? 
                      <>
                        <FormSelect label={<LabelRadio setFreeTextCallback={setFreeTextCallback} freeText={freeText} />}
                          notFoundContent={<p style={{marginBottom: '0px', color: '#525050'}}>City not found. Please select Other</p>} name="consignee_city" loading={cityList.isLoading}
                          placeholder={'Select a city'}>
                          {
                            (sortResponseAlphabatically(cityList?.data, 'city_name') || [])
                              .map((item: {id:number, city_name: string} ) => (
                                <Option key={item.id} value={item.city_name}>{item.city_name}</Option>
                              ))
                          }
                        </FormSelect>
                        {cityList.isError && <p style={{color:'red', marginBottom:0}}>Failed to fetch cities, try later</p>}
                      </>
                      : 
                      <FormInput placeholder='Please type in the city' label={<LabelRadio setFreeTextCallback={setFreeTextCallback} freeText={freeText} />} name="consignee_city" /> : 
                    <FormSelect label={getText('City')} name="consignee_city" loading={cityList.isLoading}>
                      {
                        (sortResponseAlphabatically(cityList?.data, 'city_name') || [])
                          .map((item: {id:number, city_name: string} ) => (
                            <Option key={item.id} value={item.city_name}>{item.city_name}</Option>
                          ))
                      }
                    </FormSelect>
                }
                
              </Col>
              <Col span={6} style={{marginTop: formik.values.consignee_country === 'US' ? '35px' : '0px'}}>
                <FormInput required label={getText('Postal code')} name="consignee_postal" />
              </Col>
            </Row>
            <Row gutter={14}>
              <Col>
                <Button onClick={props.onCancel}>Cancel</Button>
                <Button type="primary" htmlType="submit" className={"blue-button"} style={{marginLeft: '10px'}}>
                  {'Add customer'}
                </Button>
              </Col>
            </Row>
          </FormikForm>
        </div>
      )}
    </Formik>
  )
};
