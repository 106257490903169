import { Modal } from 'antd'
import React from 'react'

const ItemProductUrlModal = ({isVisible, setIsVisible, onSubmit}:{isVisible: boolean, setIsVisible: any, onSubmit: any}) => {
  return (
    <Modal 
      title={'Item Product URL is recommended when shipping to Vietnam to prevent shipment delay. Are you sure you want to leave it blank?'}
      visible={isVisible}
      onCancel={() => setIsVisible(false)}
      onOk={() =>{setIsVisible(false); onSubmit()}}
      okText={'Yes, I’m aware of the risk'}
      cancelText={'Back'}
    />
  )
}

export default ItemProductUrlModal