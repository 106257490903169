import { Button,Input,message} from 'antd';
import './styles/PromoCodeInput.css';
import {useState} from "react";
import {useMutation} from "react-query";
import {getDiscount,DiscountRequest} from "../orders/services/privateApi"
import {useAuth} from "../auth/services/authContext";

interface PromoProps {
 currency?:string,
 orderCharges: any,
 setDiscountAmountCallback: (amount: number,promoCode:any) => void
}

export const PromoCodeInput = (props:PromoProps) => {
  const auth = useAuth();
  const [promoCode, setPromoCode] = useState('');
  const [discountResponse, setDiscountResponse] = useState<any>();
  const [error, setError] = useState("");
  let discountData:DiscountRequest = {
    discount_code: promoCode,
    order_charges: props.orderCharges
  }
  const getDiscountAmount = useMutation(async (value:any) => {
    return getDiscount(auth.user.authToken,discountData)
  })
  const onApply = () => {
    getDiscountAmount.mutate({},{
      onSuccess: (response:any) => {
        setError("")
        props.setDiscountAmountCallback(response.total_discount,promoCode)
        setDiscountResponse(response)
      },
      onError: (response:any) => {
        let errorResponse = response.response.data;
        if(errorResponse.discount){
          if(errorResponse.discount.length>1){
            let errorList = <ul style={{textAlign: 'left'}}>
              {
                errorResponse.discount.map((err:any) =>
                {
                  return <li key={err['order_id']} style={{fontSize: '12px',color: '#4f4a4a', fontWeight: 600}}>{err['order_id']}: {err['error']}</li>
                }
                )
              }
            </ul>
            message.error(
              <span>{errorList}</span>,
              5
            )}
          else{
            if(errorResponse.discount[0].order_id){
              setError(errorResponse.discount[0].error)
            }
            else{
              setError(errorResponse.discount)
            }
          }
        }
        else if(errorResponse.discount_code){
          setError(errorResponse.discount_code)
        }
        else{
          setError("Sorry. This promo code is not valid")
        }
      }
    })
  }
  const onCancel = () => {
    setPromoCode("")
    props.setDiscountAmountCallback(0,undefined)
    setDiscountResponse(undefined);
  }
  return (
    <div>
      {(discountResponse && props.currency) &&
      <div className="title-block price-breakup">
        <span>Discount*({promoCode})</span>
        <span style={{color: '#008000'}}>-{props.currency} {parseFloat(discountResponse.total_discount).toFixed(2)}</span>
      </div>
      }
      <div className="promotion">
        <div className="promo-input">
          <Input value={promoCode} placeholder="Promo code" onChange={(event:any) => {setPromoCode(event.target.value);setError("")}} disabled={discountResponse ? true: false}></Input>
          {discountResponse && <p style={{color:'#008000', marginBottom:0}}>Successfully applied promo code</p>}
          {error!=="" && <p style={{color:'red', marginBottom:0}}>{error}</p>}
        </div>
        <div className="promo-apply-button">
          {!discountResponse? <Button style={{ background: "white", borderColor: "#050593", color: "#050593" }} onClick={onApply} loading={getDiscountAmount.isLoading}>Apply Code</Button> :
            <Button style={{ background: "white", borderColor: "#050593", color: "#050593" }} onClick={onCancel}>Cancel</Button>}
        </div>
      </div>
      {discountResponse &&
      <div className="title-block price-breakup">
        <span>*Discount will not be applied on duties & taxes charges</span>
      </div>
      }
    </div>
  )
};


