import {useState, useEffect} from 'react';
import {Row, Col, Card, Tag, Button, Modal, Table, Popconfirm, message, Spin, Tabs, Switch} from 'antd'
import {useAuth} from "../auth/services/authContext";
import { useLanguage } from '../languages/Language';
import './styles/shipmentRules.css'
import {Link, useHistory} from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { getShipmentRuleListDataFromB2C, updateShipmentRuleDatainB2C, deleteShipmentRuleInB2C, swapShipmentRuleSequenceInB2C } from "../orders/services/privateApi";
import {getDateInReadableFormat} from '../commons/utils/utilizer'
import { MenuOutlined } from '@ant-design/icons';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

const { TabPane } = Tabs;

// interface RuleListInterface {
//   action_type: string
//   apply_on_existing_order: boolean
//   created_on: string
//   id: number
//   is_active: boolean
//   rule_name: string
//   sequence_no: number
//   updated_on: string
// }
const callbackTabSelection = (key: string) => {
  localStorage.setItem('activeRuleTabKey', key)
}

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const SortableItem = SortableElement((props: any) => {
  return <tr {...props} />
});

const SortableWrapper = SortableContainer((props: any) => {
  return <tbody {...props} />
});

export const ShipmentRulesListing = (props: any) => {
  let { getText } = useLanguage();
  const history = useHistory()
  const auth = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeKey, setActiveKey] = useState<any>('item')

  const getShipmentRuleListData = useQuery(['getShipmentRuleListData'], async () => {
    return getShipmentRuleListDataFromB2C(auth.user.secret_key)
  }, {cacheTime: 0})
  
  useEffect(() => {
    if('activeRuleTabKey' in localStorage) {
      if(history.action === 'PUSH') {
        localStorage.removeItem('activeRuleTabKey')
      }

      let activeKeyinLocal = localStorage.getItem('activeRuleTabKey')
      setActiveKey(activeKeyinLocal)
    }
  }, [getShipmentRuleListData.data?.results.length, history.action])

  const deleteRule = useMutation(async (id: number) => {
    return deleteShipmentRuleInB2C(auth.user.secret_key, id);
  },  {
    onSuccess: (val) => {
      if('activeRuleTabKey' in localStorage) {
        let activeKeyinLocal = localStorage.getItem('activeRuleTabKey')
        setActiveKey(activeKeyinLocal)
      }
      
      message.success(getText('Rule deleted successfully'));
      getShipmentRuleListData.refetch()
    },
    onError: (error: any) => {
      let errorResponse = error.response.data;
      message.error(`${errorResponse.error}. Failed to delete the rule.`);
    },
  });

  const updateRuleStatus = useMutation(async (params: any) => {
    return updateShipmentRuleDatainB2C(auth.user.secret_key, params, params.id);
  },  {
    onSuccess: (val) => {
      if('activeRuleTabKey' in localStorage) {
        let activeKeyinLocal = localStorage.getItem('activeRuleTabKey')
        setActiveKey(activeKeyinLocal)
      }
      
      message.success(getText('Rule Updated successfully'));
      getShipmentRuleListData.refetch()
    },
    onError: (error: any) => {
      let errorResponse = error.response.data;
      message.error(`${errorResponse.error}. Failed to delete the rule.`);
    },
  });

  const swapSequence = useMutation(async (params: {moveUpId: number, moveDownId: number}) => {
    return swapShipmentRuleSequenceInB2C(auth.user.secret_key, params.moveUpId, params.moveDownId);
  },  {
    onSuccess: (val) => {
      if('activeRuleTabKey' in localStorage) {
        let activeKeyinLocal = localStorage.getItem('activeRuleTabKey')
        setActiveKey(activeKeyinLocal)
      }
      message.success('Rule prioritisation completed successfully');
      getShipmentRuleListData.refetch()
    },
    onError: (error: any) => {
      let errorResponse = error.response.data;
      message.error(`${errorResponse.error}. Failed to swap the sequence.`);
    },
  });
  
  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 60,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'ID',
      dataIndex: 'id',
      width: 90,
    },
    {
      title: 'Rule Name',
      dataIndex: 'rule_name',
      key: 'rule_name',
    },
    {
      title: 'Date Created',
      dataIndex: 'created_on',
      key: 'created_on',
      render: (created_on:any, fullData: any) => {
        return (
          <span>{getDateInReadableFormat(fullData.created_on)}</span>
        )
      }
    },
    {
      title: 'Rule Type',
      dataIndex: 'action_type',
      key: 'action_type',
      render: (action_type:any, fullData:any) => {
        if(fullData.action_type === 'SERVICE_TYPE') {
          return <Tag color="default">Service Rule</Tag>
        }
        if(fullData.action_type === 'DIMENSIONS') {
          return <Tag color="default">Dimension Rule</Tag>
        }
        return <Tag color="default">Item Category Rule</Tag>
      }
    },
    
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      width: 90,
      render: (is_active:any, fullData:any) => {
        if(fullData.is_active) {
          return (
            <Tag color="success">Active</Tag>
          );
        } else {
          return (
            <Tag color="error">Inactive</Tag>
          );
        }
      }
    },
    {
      title: 'Switch',
      dataIndex: 'switch',
      width: 90,
      render: (text: string, record: any)=>(
        <Switch key={record.id} defaultChecked={record.is_active} onChange={(value, event)=>{
          let new_record = {...record}
          new_record.is_active = value
          return updateRuleStatus.mutate(new_record, record.id)
        }} />
      )
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'view',
      fixed: 'right' as 'right',
      render: (action:any, fullData:any)=> {
        let ruleTypeRedirection = () => {
          if(fullData.action_type === 'DIMENSIONS') {
            return 'parcel_dimension'
          }
          if(fullData.action_type === 'ITEM_CATEGORY') {
            return 'item_category'
          }
          return 'service'
        }
        return (
          <>
            <Row gutter={16}>
              <Col>
                <Link to={`/shipment_rules/edit/${ruleTypeRedirection()}/${fullData.id}`}>Edit</Link>
              </Col>
              <Col>
                <Popconfirm
                  title={<div className="delete-card">{'Are you sure to delete this rule?'}</div>}
                  onConfirm={() => confirm(fullData.id)}
                  onCancel={cancel}
                  okText={getText('Confirm')}
                  cancelText={getText('Cancel')}
                  placement="left"
                >
                  <a href="#"  style={{color: 'red'}}>{getText('Delete')}</a>
                </Popconfirm>
              </Col>
            </Row>
          </>
        )
      }
    }
  ];

  const confirm = (id: number) => {
    deleteRule.mutate(id);
  }
  
  const cancel = (e: any) => {
    return
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  if(getShipmentRuleListData.isFetching) {
    return (
      <div className="spinner-centre">
        <Spin />
      </div>
    )
  }

  const shipmentRuleListTabData = () => {
    let itemCategoryRuleArray: any[] = []
    let serviceTypeRuleArray: any[] = []
    let parcelDimensionRuleArray: any[] = []

    getShipmentRuleListData.data?.results.filter((value: any, index: number) => {
      if(value.action_type === 'DIMENSIONS') {
        parcelDimensionRuleArray.push(Object.assign({index: index}, value))
      }
      if(value.action_type === 'SERVICE_TYPE') {
        serviceTypeRuleArray.push(Object.assign({index: index}, value))
      }
      if(value.action_type === 'ITEM_CATEGORY') {
        itemCategoryRuleArray.push(Object.assign({index: index}, value))
      }
      return null
    })
    return [itemCategoryRuleArray, serviceTypeRuleArray, parcelDimensionRuleArray]
  }
  
  const segregateDataArrWithIndex = shipmentRuleListTabData()


  const onSortEnd = ({ oldIndex, newIndex, nodes }: {oldIndex: number, newIndex: number, nodes: any}) => {
    let checkType = nodes[0].node.attributes[2].value

    let dataSource: any = []
    
    if(checkType === 'ITEM_CATEGORY') {
      dataSource = segregateDataArrWithIndex[0]
      if (oldIndex !== newIndex) {
        const newData = arrayMoveImmutable([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
        let params = {
          moveUpId: (newData[newIndex] as any).id,
          moveDownId: (dataSource[newIndex] as any).id
        }
        swapSequence.mutate(params)
      }
    } else if(checkType === 'SERVICE_TYPE') {
      dataSource = segregateDataArrWithIndex[1]
      if (oldIndex !== newIndex) {
        const newData = arrayMoveImmutable([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
        let params = {
          moveUpId: (newData[newIndex] as any).id,
          moveDownId: (dataSource[newIndex] as any).id
        }
        swapSequence.mutate(params) 
      }
    } else if(checkType === 'DIMENSIONS') {
      dataSource = segregateDataArrWithIndex[2]
      if (oldIndex !== newIndex) {
        const newData = arrayMoveImmutable([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
        let params = {
          moveUpId: (newData[newIndex] as any).id,
          moveDownId: (dataSource[newIndex] as any).id
        }
        swapSequence.mutate(params) 
      }
    }
  };

  const DraggableContainer = (props: any) => {
    return (
      <SortableWrapper
        useDragHandle
        disableAutoscroll
        helperClass="row-dragging"
        onSortEnd={onSortEnd}
        {...props}
      />
    )
  };

  const DraggableBodyRowZero = ({ ...restProps }) => {
    const dataSource = segregateDataArrWithIndex[0];
    const index = dataSource.findIndex((x:any) => {
      return x.index === restProps['data-row-key']
    })
    const ruleType = 'ITEM_CATEGORY'
    return <SortableItem index={index} {...restProps} value={ruleType} />;
  };

  const DraggableBodyRowOne = ({ ...restProps }) => {
    const dataSource = segregateDataArrWithIndex[1];
    const index = dataSource.findIndex((x:any) => {
      return x.index === restProps['data-row-key']
    })
    const ruleType = 'SERVICE_TYPE'
    return <SortableItem index={index} {...restProps} value={ruleType} />;
  };

  const DraggableBodyRowTwo = ({ ...restProps }) => {
    const dataSource = segregateDataArrWithIndex[2];
    const index = dataSource.findIndex((x:any) => {
      return x.index === restProps['data-row-key']
    })
    const ruleType = 'DIMENSIONS'
    return <SortableItem index={index} {...restProps} value={ruleType} />;
  };
  
  return (
    <>
      <h1 className="ant-page-header-heading-title page-title">
        {getText("Shipment Rules")}
      </h1>
      <div style={{position: 'relative'}}>
        <Row justify="end" style={{position: 'absolute', right: 0, bottom: 25}} gutter={8}>
          <Col>
            <Button
              type="primary"
              className="outline-btn"
              style={{minWidth: '150px'}}
            >
              <a href={"https://janio-asia.notion.site/Creating-rule-for-your-Janio-Integration-Orders-9c24a9a9d217414bb06403c214b84748 "} target="_blank" rel="noreferrer" style={{display: 'block'}}>{"View Tutorial"}</a>
            </Button>
          </Col>
          
          <Col>
            <Button type="primary" onClick={showModal} style={{minWidth: '150px'}}> {getText('Add Shipment Rule')} </Button>
          </Col>
        </Row>
      </div>
      
      
      <div className={'main-rules-container'}>
        
        <div>
          <Tabs defaultActiveKey={activeKey} onChange={callbackTabSelection} className="tabs-wrapper" animated>
            <TabPane tab={getText('Item Category')} key="item">
              <Col span={24}>
                <Card>
                  <Table
                    rowClassName='table_body'
                    scroll={{ x: 1100 }}
                    rowKey={'index'}
                    dataSource={segregateDataArrWithIndex[0].length > 0 ? segregateDataArrWithIndex[0] : []}
                    columns={columns}
                    loading={getShipmentRuleListData.isLoading}
                    pagination={{
                      pageSize: 25
                    }}
                    components={{
                      body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRowZero,
                      },
                    }}
                  />
                </Card>
              </Col>
            </TabPane>
            <TabPane tab={getText('Service Type')} key="service">
              <Col span={24}>
                <Card>
                  <Table
                    rowClassName='table_body'
                    scroll={{ x: 1100 }}
                    rowKey={'index'}
                    dataSource={segregateDataArrWithIndex[1].length > 0 ? segregateDataArrWithIndex[1] : []}
                    columns={columns}
                    loading={getShipmentRuleListData.isLoading}
                    pagination={{
                      pageSize: 25
                    }}
                    components={{
                      body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRowOne,
                      },
                    }}
                  />
                </Card>
              </Col>
            </TabPane>
            <TabPane tab={getText('Parcel Dimension')} key="parcel">
              <Col span={24}>
                <Card>
                  <Table
                    rowClassName='table_body'
                    scroll={{ x: 1100 }}
                    rowKey={'index'}
                    dataSource={segregateDataArrWithIndex[2].length > 0 ? segregateDataArrWithIndex[2] : []}
                    columns={columns}
                    loading={getShipmentRuleListData.isLoading}
                    pagination={{
                      pageSize: 25
                    }}
                    components={{
                      body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRowTwo,
                      },
                    }}
                  />
                </Card>
              </Col>
            </TabPane>
          </Tabs>
        </div>
        <Modal title={getText('Choose a shipment Rule')} visible={isModalVisible} onCancel={handleCancel} footer={null} width={800}>
          <Row gutter={12}>
            <Col span={7} className="select-rule-btn">
              <Link to={"/shipment_rules/item_category"}>
                <Button type="link"  onClick={showModal}> {getText('Item Category')} </Button>
                <p>
                  {getText('Determine item category based on SKUs or item title.')}
                </p>
              </Link>
            </Col>
            <Col span={7} className="select-rule-btn">
              <Link to={"/shipment_rules/service"}>
                <Button type="link"  onClick={showModal}> {getText('Service Type')} </Button>
                <p>
                  {getText('Determine service type based on pickup and destination country.')}
                </p>
              </Link>
            </Col>
            <Col span={7} className="select-rule-btn">
              <Link to={"/shipment_rules/parcel_dimension"}>
                <Button type="link"  onClick={showModal}> {getText('Parcel Dimension')} </Button>
                <p>{getText('Determine parcel dimension based on item weight or item quantity.')}</p>
              </Link>
            </Col>
          </Row>
        </Modal>
      </div>
    </>
  )
}
