import {Popover,Button} from 'antd';
import React, {ReactNode} from 'react';
import {
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import "./Tips.css";

type placement = "left" | "top" | "right" | "bottom" | "topLeft" | "topRight" | "bottomLeft" | "bottomRight" | "leftTop" | "leftBottom" | "rightTop" | "rightBottom" | undefined

interface PropTypes {
  children: ReactNode,
  placement: placement,
  visible: boolean,
  step: string,
  contentMessage: string,
  nextText?: string,
  hidePreviousText:boolean,
  onExitTour: (val:boolean) => any,
  onNextClick?: () => any,
  onPrevClick?: () => any,
  mixpanelEvent: () => any
}

export const Tips = (props:PropTypes) => {
  const onTourExit = () => {
    let getParentPopup:any = document.getElementById("ant-popover-container-id");
    document.body.removeChild(getParentPopup);
    document.body.style.removeProperty("overflow");
  }
  return (
    <>
      <Popover
        title={<div style={{fontWeight:'bold'}}>{`Tip ${props.step}/4`}</div>}
        getPopupContainer={()=>{
          props.mixpanelEvent();
          let previousTipParent:any = document.getElementById("ant-popover-container-id");
          if(previousTipParent){
            document.body.removeChild(previousTipParent!);
          }
          let tag = document.createElement('div');
          tag.className = "ant-popover-container";
          tag.id = "ant-popover-container-id";
          document.body.appendChild(tag);
          document.body.style.overflow = "hidden"
          return document.getElementById("ant-popover-container-id") || document.body;
        }}
        content={
          <div>
            <p>{props.contentMessage}</p>
            <div className="tip-footer">
              <div className={"tips-left-button"}>
                <Button onClick={() => {props.onExitTour(false);onTourExit()}} type={"text"}>Exit Tour</Button>
              </div>
              <div className="tip-right-buttons">
                {!props.hidePreviousText?
                  <Button style={{fontWeight:'bold'}} onClick={props.onPrevClick} type={"text"}><><LeftOutlined style={{fontSize:8}}/> Previous</></Button>
                  :<></>}
                <Button style={{color:'#050593',fontWeight:'bold'}} onClick={props.onNextClick} type={"text"}>
                  {props.nextText?props.nextText: <>Next <RightOutlined style={{fontSize:8}}/></>
                  }
                </Button>
              </div>
            </div>
          </div>
        }
        placement={props.placement}
        visible={props.visible}
      >
        {props.children}
      </Popover>
    </>
  )
}