import {Button,Modal,message, List} from 'antd';
import {useState, useEffect } from "react";
import {useMutation} from "react-query";
// import { useHistory } from 'react-router-dom';
import { useAuth } from '../../auth/services/authContext';
import { useLanguage } from '../../languages/Language';
import {OrderDetailData} from '../../ManageShipments/services';
import {createMonthlyBillingShopifyOrder_in_b2c} from "../../orders/services/privateApi";
import {UserViewedOrderSuccessPage} from "../../commons/mixpanel"
interface Props {
  selectedOrders: Array<OrderDetailData>
  selectedRowKeys: Array<number>
  walletRechargeStatus: any
  propsFetchQuery:boolean 
  setPropsReftechQuery: (val:boolean) => void
  setSelectedRowArray: (val:[]) => void
  setSelectedOrders: (val:[]) => void
}

let preSelectedData = JSON.parse(localStorage.getItem('bulkPaymentOperationItems')!)
// const { Panel } = Collapse;

const PayBulkOrderHeader = (props:Props) => {
  
  let { getText } = useLanguage();
  const auth = useAuth();
  // let history = useHistory();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [successText, setSuccessText] = useState<{success_count: number, failed_count: number}>({success_count: 0, failed_count: 0})

  const closeModal = () => {
    setShowModal(false)
    localStorage.removeItem('bulkPaymentOperationItems')
  };

  const getOrderIds = () => {
    let orderIds = props.selectedOrders.map((order:any) => {
      return order?.id;
    })
    return orderIds
  }

  const createMonthlyBillingOrders:any = useMutation(async (orderIds:any) => {
    let context = {
      draft_order_ids:orderIds,
      secret_key: auth.user.secret_key
    }
    return createMonthlyBillingShopifyOrder_in_b2c(context)
  },  {
    onSuccess: (val) => {
      setShowModal(false)
      setSuccessText(val)
      UserViewedOrderSuccessPage()
      setShowSuccessModal(true)
      // history.push('/orders/success')
    },
    onError: (error: { response: { data: { error: string } } }) => {
      setShowModal(false)
      let errorResponse: any = error.response.data.error
      if(error && typeof(errorResponse) === 'object') {
        message.error(<span>Failed to create shipment. {JSON.stringify(errorResponse)}</span>, 10);
      } else {
        message.error(<span> Failed to create shipment.</span>);
      }
    }
  });
  const handlePayment = (val?: boolean) => {
    if(val) {
      let orderIds=getOrderIds();
      createMonthlyBillingOrders.mutate(orderIds)
    }
    // if(getExpiredOrderChargeIds().length > 0 && !val) {
    //   setShowExpiredPickupDateModal(true)
    // }
  }

  const onClick = () => {
    setShowModal(true);
  }

  useEffect(() => {
    if(preSelectedData && preSelectedData.length > 0) {
      setShowModal(true);
    }
  },[props.selectedOrders,props.walletRechargeStatus])

  
  const constructLane = (origin: string, destination: string) => `${origin}-${destination}`
  const encodeSelectedOrders = () => {
    const lanesCounter = {} as any;
    props.selectedOrders.forEach((order: any) => {
      const lane = constructLane(order.pickup_country, order.consignee_country)
      if(lane in lanesCounter){
        lanesCounter[lane]++
      }else{
        lanesCounter[lane] = 1
      }
    });

    const payload = {
      'lanesCounter': lanesCounter
    }

    return payload
    // return btoa(JSON.stringify(payload))
  }

  const OrderSummary = ({lanesCounter}: any) => {
    return (
      <List
        style={{marginBottom: '6px'}}
        bordered
        header={<span style={{fontSize: '1.2em', fontWeight: 'bold'}}>Summary</span>}
        dataSource={Object.entries(lanesCounter)}
        renderItem={([lane, count]) => {
          const [origin, dest] = lane.split('-')
          return (<List.Item>{origin} to {dest} - {count} shipments</List.Item>)
        }}
      />
    )
  }

  const PaymentSummaryModal = () => {
    return(
      <>
        <Modal title={`Order Summary (Creating ${props.selectedOrders.length} ${props.selectedOrders.length > 1 ? 'Orders' : 'Order'})`}
          width={700}
          visible={showModal}
          onCancel={closeModal}
          className={'modal-right-footer'}
          footer={[
            <Button onClick={closeModal}>{getText("Cancel")}</Button>,
            <Button onClick={() => handlePayment(true)} loading={createMonthlyBillingOrders.isLoading} type="primary">{'Ship with Janio'} </Button>
          ]}>
          <div style={{marginBottom: 0}}>
            <OrderSummary lanesCounter={encodeSelectedOrders().lanesCounter}/>
            <p style={{marginTop: '30px'}}>By proceeding, you agree to pay <b>additional courier charges</b> in case shipment details (weight, dimensions, item category, pickup and destination address) are found to be inaccurate</p>
          </div>
        </Modal>
      </>
    )
  };

  const OrderSummaryAfterSubmit = () => {
    return(
      <>
        <Modal title={`Order Summary`}
          width={700}
          visible={showSuccessModal}
          onCancel={() => setShowSuccessModal(false)}
          className={'modal-right-footer'}
          footer={[
            // <Button onClick={() => setShowSuccessModal(false)}>{getText("Cancel")}</Button>,
            <Button onClick={() => {
              return (
                setShowSuccessModal(false),
                setSuccessText({success_count: 0, failed_count: 0}),
                props.setPropsReftechQuery(true),
                props.setSelectedRowArray([]),
                props.setSelectedOrders([])
                // window.location.reload()
              )
            }} loading={createMonthlyBillingOrders.isLoading} type="primary">{'Ok'} </Button>
          ]}>
          <div style={{marginBottom: 0}}>
            <p style={{marginTop: '0px'}}>
              <span style={{display: 'block'}}>Order Success Count - {successText.success_count}</span>
              <span style={{display: 'block'}}>Order Failed Count - {successText.failed_count}</span>
            </p>
            
            {successText.failed_count > 0 && <p>You can find the failed shipments inside <strong> To Ship</strong> tab, Please fill out the missing information.</p>}
          </div>
        </Modal>
      </>
    )
  };
  
  return (
    <>
      {(props.selectedOrders?.length > 0) ?
        <div className="main-print-container" style={{top: '51px'}}>
          <div className="print-content" style={{justifyContent: 'unset', marginRight: '-50px'}}>
            <div>
              {props.selectedOrders.length} {getText("Shipments selected")}
            </div>
            <div>
              <Button className="button-label" type="text" onClick={onClick} style={{color: '#050593', background: '#fff', marginLeft: '35px', fontWeight: 600}}>{`Ship with Janio`}</Button>
              {showModal && <PaymentSummaryModal/>}
              {showSuccessModal && <OrderSummaryAfterSubmit />}
            </div>
          </div>
          <div className="arrow-down"></div>
        </div>
        : <></>
      }
    </>
  )
};

export default PayBulkOrderHeader