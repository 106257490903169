import {
  Form,
  Button,
  Col,
  Row,
  Select,
} from 'antd';

import {addLocation, AddressPropsForShopify, getStates, getCities} from "../orders/services/privateApi";
import {countries, countryCodes} from "../commons/utils/constants";
import {sortResponseAlphabatically} from "../commons/utils/utilizer" 
import {useAuth} from "../auth/services/authContext";
import * as Yup from 'yup';
import {Formik, Form as FormikForm, FormikHelpers} from 'formik';
import {FormInput, FormSelect} from "../orders/components/Forms";
import {useState} from 'react';
import {useMutation, useQuery} from 'react-query';
import {validatePostalCode} from "../commons/utils/validator";
import { useLanguage } from '../languages/Language';
import { LabelRadio } from '../ReUsableComponents/ReUsable'

const {Option} = Select;
const validationSchema = (getText: any) => {
  return Yup.object().shape({
    address: Yup.string().required(getText('Address is required')),
    city: Yup.string().required(getText('Please enter City')).max(50, 'Maximum 50 characters are allowed'),
    contact_email: Yup.string().required(getText('Email is required')).email(getText("Please enter a valid email")).max(50, 'Maximum 50 characters are allowed'),
    contact_number: Yup.string().required(getText('Phone Number is required')).max(50, 'Maximum 50 characters are allowed'),
    contact_person: Yup.string().required(getText('Full Name is required')).max(50, 'Maximum 50 characters are allowed'),
    country_code: Yup.string().required(getText('Please select Country')),
    type: Yup.string().required(getText('Please select pickup location type')),
    name: Yup.string().required(getText('Address Label is required')).max(50, 'Maximum 50 characters are allowed'),
    state: Yup.string().required(getText('Please select State')),
    postal: Yup.string().required(getText('Please enter Postal Code')).test(
      'postal-check',
      (val: any, context: any) => {
        if(!val){
          return context.createError({
            path: context.path,
            message: getText('Please enter Postal code')
          })
        }
        else{
          const error = validatePostalCode(countryCodes[context.parent.country_code],val);
          if(error){
            return context.createError({
              path: context.path,
              message: error
            })
          }
        }
        return true
      }
    )
  })};


type ServerErrors = {[key: string]: Array<string>};
const combineFieldsErrors = (errors: ServerErrors) => {
  let combined_errors: any = {}
  let initial_touched: any = {}

  for(const [key, val] of Object.entries(errors)){
    combined_errors[key] = val.join(',')
    initial_touched[key] = true
  }

  return {
    combined_errors,
    initial_touched
  }
}

export interface AddressError {
  name?: string,
  contact_person?: string,
  contact_number?: string,
  contact_email?: string,
  address?: string,
  country_code?: string,
  city?: string,
  state?: string,
  postal?: string,
}


interface PropsType {
  countryCode?: string
  formId?: string
  onSuccess?: (id:string) => any
  onCancel: () => any
  addressType: string
  onSuccessForReturns?: (location:any) => any
}

export const PickUpAddressFormForShopify = (props:PropsType) =>{
  const auth = useAuth();
  const [country, setCountry] = useState<string>(props.countryCode ? props.countryCode : '');
  const [state, setState] = useState<string>('');
  const [freeText, setfreeText] = useState<string>('dropdown');
  const resetFieldValuesOnCountryChange = ['state', 'city'];
  const { getText } = useLanguage();
  const statesList = useQuery(['statesList', country], () => {
    if(country){
      return getStates(auth.user.authToken, country)
    }
  });
  const cityList = useQuery(['cityList', state], () => {
    if(state){
      return getCities(auth.user.authToken, state)
    }
  });
  const formInitialValue: (AddressPropsForShopify) = {
    address:'',
    city:'',
    contact_email:'',
    contact_number:'',
    contact_person:'',
    country_code: props.countryCode ? props.countryCode : '',
    name:'',
    state:'',
    postal:'',
    type: props.addressType === 'pickup' ? 'FORWARD' : 'RETURN'
  };

  const [initialTouched, setInitialTouched] = useState({});
  const createLocation = useMutation(async (value: AddressPropsForShopify) => await addLocation(value, auth.user.authToken))

  const onFormSubmit = async (value: AddressPropsForShopify, {setErrors}: FormikHelpers<AddressPropsForShopify>) => {
    try{
      let location:any = await createLocation.mutateAsync(value)
      if(props.onSuccess){
        props.onSuccess(location.id)
      }
      if(props.onSuccessForReturns){
        props.onSuccessForReturns(location)
      }
    }
    catch(e: any){
      const {combined_errors, initial_touched} = combineFieldsErrors(e.response.data)
      setErrors(combined_errors)
      setInitialTouched(initial_touched)
    }
  };

  const setFreeTextCallback = (val:string) => {
    setfreeText(val);
  };

  return (
    <Formik
      initialValues={formInitialValue}
      initialTouched={initialTouched}
      validationSchema={validationSchema(getText)}
      enableReinitialize={true}
      onSubmit={onFormSubmit}
      validateOnChange={false}
    >
      { formik => (
        <div className={"ant-form-vertical"}>
          <FormikForm>
            <Row gutter={14}>
              <Col span={12}>
                <FormInput label={`${props.addressType.charAt(0).toUpperCase() + props.addressType.slice(1)} ${getText('Address Label')}`} name="name" wrapperClass={"no-margin-item"} />
                
                <Form.Item label={getText('Name will be displayed under address selection')} className={'form-item-info'}>
                </Form.Item>
              </Col>

              <Col span={12}>
                <FormSelect label={getText('Type')} name="type" disabled={true}>
                  <Option key={'FORWARD'} value={'FORWARD'}>{'FORWARD'}</Option>
                  <Option key={'RETURN'} value={'RETURN'}>{'RETURN'}</Option>
                </FormSelect>
              </Col>
            </Row>
            <Row gutter={14}>
              <Col span={12}>
                <FormInput label={getText('Full Name')} name="contact_person" />
              </Col>
              <Col span={12}>
                <FormInput label={getText('Phone Number')} name="contact_number" />
              </Col>
            </Row>
            <FormInput label={getText('Email')} name="contact_email" />
            <FormInput label={getText('Address')} name="address" />
            <Row gutter={14}>
              <Col span={12}>
                <FormSelect label={getText('Country')} name="country_code" onChangeCallback={(country_code:string) => {setCountry(country_code); resetFieldValuesOnCountryChange.forEach(field => formik.setFieldValue(field, ''))}}>
                  {
                    Object.entries(countries)
                      .filter(([key]) => props.countryCode ? key === props.countryCode : true)
                      .map(([key]) => (
                        <Option key={key} value={key}>{countries[key]}</Option>
                      ))
                  }
                </FormSelect>
              </Col>
              <Col span={12}>
                <FormSelect label={getText('State')} name="state" loading={statesList.isLoading} onChangeCallback={(state:string) => {
                  setState(state);
                  formik.setFieldValue('city', '');
                }}>
                  {
                    (sortResponseAlphabatically(statesList?.data, 'state_name') || [])
                      .filter((item: {id:number, state_name: string}) => (
                        country === 'TH'?
                          (item.state_name === 'Nonthaburi' || item.state_name === 'Bangkok' || item.state_name === 'Samut Prakan')
                          : (item.id > 0)
                      ))
                      .map((item: {id:number, state_name: string} ) => (
                        <Option key={item.id} value={item.state_name}>{item.state_name}</Option>
                      ))
                  }
                </FormSelect>
                {statesList.isError && <p style={{color:'red', marginBottom:0}}>{getText("Failed to fetch states, try later")}</p>}
              </Col>
            </Row>
            <Row gutter={14}>
              <Col span={12}>
                {
                  formik.values.country_code === 'US' ?
                    freeText === 'dropdown' ? 
                      <>
                        <FormSelect label={<LabelRadio setFreeTextCallback={setFreeTextCallback} freeText={freeText} />}
                          notFoundContent={<p style={{marginBottom: '0px', color: '#525050'}}>City not found. Please select Other</p>} name="city" loading={cityList.isLoading}
                          placeholder={'Select a city'}>
                          {
                            (sortResponseAlphabatically(cityList?.data, 'city_name') || [])
                              .map((item: {id:number, city_name: string} ) => (
                                <Option key={item.id} value={item.city_name}>{item.city_name}</Option>
                              ))
                          }
                        </FormSelect>
                        {cityList.isError && <p style={{color:'red', marginBottom:0}}>Failed to fetch cities, try later</p>}
                      </>
                      : 
                      <FormInput placeholder='Please type in the city' label={<LabelRadio setFreeTextCallback={setFreeTextCallback} freeText={freeText} />} name="city" /> : 
                    <FormSelect label={getText('City')} name="city" loading={cityList.isLoading}>
                      {
                        (sortResponseAlphabatically(cityList?.data, 'city_name') || [])
                          .map((item: {id:number, city_name: string} ) => (
                            <Option key={item.id} value={item.city_name}>{item.city_name}</Option>
                          ))
                      }
                    </FormSelect>
                }
              </Col>
              <Col span={6} style={{marginTop: formik.values.country_code === 'US' ? '35px' : '0px'}}>
                <FormInput label={getText('Postal code')} name="postal" />
              </Col>
            </Row>
            <Row gutter={14}>
              <Col>
                <Button onClick={props.onCancel}>Cancel</Button>
                <Button type="primary" htmlType="submit" className={"blue-button"} loading={createLocation.isLoading} style={{marginLeft: '10px'}}>
                  {getText('Add address')}
                </Button>
              </Col>
            </Row>
          </FormikForm>
        </div>
      )}
    </Formik>
  );
};