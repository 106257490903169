import { Image, Button, Col, Row } from "antd";
import noAnalytics_icon from '../../assets/icons/noAnalytics_icon.svg'

export const NoAnalyticsAvailable = () =>{
  return (
    <>
      <Col>
        <Row justify="center">
          <Image preview={false} src={noAnalytics_icon}/>
        </Row>
        <Row justify="center">
          <p className="no-analytics-text">No analytics available yet!</p>
        </Row>
        <Row justify="center">
          <p className="no-analytics-text" style={{fontWeight: 400}}>You can view shipment analytics once a shipment is created and picked up.</p>    
        </Row>
        <Row justify="center">
          <Button key="create-shipment" type="primary" className={"blue-button"} style={{borderRadius: '8px', marginTop: '16px'}}>
            <a href={'/orders/create'}>{'Go to Create Shipments'}</a>
          </Button>
        </Row>
      </Col>
    </>
  )
};
