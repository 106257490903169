import { instance as api, instanceForb2c as apib2c } from '../../commons/api'

export const signup = async (name: string, email: string, password: string, phone_number_with_code: string, referral_code : string, purpose: string, billing_country: string) => {
  let payload:any = {
    full_name: name,
    email: email,
    password: password,
    referral_code: referral_code ? referral_code : '',
    phone_number: phone_number_with_code ? phone_number_with_code : '',
    billing_country: billing_country
  };
  if(purpose){
    payload= {...payload, extra_data: {registration_purpose: purpose}}
  }
  let response = await api.post('auth/users/', {
    ...payload
  });
  return response.data
};

export const reset_password = async (email:string) => {
  let response = await api.post('auth/users/reset_password/', {
    email: email
  });
  return response.data
};

export const resend_activation = async (email: string) => {
  let response = await api.post('auth/users/resend_activation/', {
    email: email
  });
  return response.data
};

export const activation = async (uid: string, token: string) => {
  let response = await api.post('auth/users/activation/ ', {
    uid: uid, token: token
  });

  return response.data
};

export const confirm_reset_password = async (uid: string, token: string, password: string, re_password: string) => {
  let response = await api.post('auth/users/reset_password_confirm/ ', {
    uid: uid, token: token, new_password: password, re_new_password: re_password
  });
  return response.data
};

export const update_user_profile = async (token: string, phone_number: string) => {
  let response = await api.put('auth/profile/update_user/ ', {
    phone_number: phone_number
  },
  {
    headers: {
      'Authorization': `Token ${token}`
    }
  }
  )
  return response.data
};

export const change_password = async (token: string, password: string, re_password: string, current_password: string) => {
  let response = await api.post('auth/users/set_password/ ', {
    new_password: password, re_new_password: re_password, current_password: current_password
  },
  {
    headers: {
      'Authorization': `Token ${token}`
    }
  })

  return response.data
};

export const change_notification_settings = async (token: string, sms_enabled: boolean, email_enabled: boolean) => {
  let response = await api.put('auth/profile/enable_notifications/ ', {
    sms_enabled: sms_enabled, email_enabled: email_enabled
  },
  {
    headers: {
      'Authorization': `Token ${token}`
    }
  })

  return response.data
};

export const migration = async (token: string) => {
  let response = await api.post('auth/exchange/ ', {
    token: token
  });
  return response.data
};

interface ShipperProfileB2C {
  allow_custom_tracking_no: boolean
}
export const shipperprofileFromB2C = async (secret_key: string) => {
  let response = await apib2c.get<ShipperProfileB2C>(`order/shipper-agent-app/?secret_key=${secret_key}`);
  return response.data
};

interface profileDataInB2c {
  agent_application_id?: number,
  privilege?: string,
  agent_application_secret_key?: "6BGEwnxSnAqefSdlvYvjZjzyppSc50Zq",
  agent_application_name?: string,
  agent_application_contact_person?: string,
  agent_application_number?: string,
  agent_application_email?: string,
  agent_application_group?: string | null,
  allow_order_submit?: boolean,
  label_logo_url?: string,
  label_preference?: string,
  is_active?: boolean,
  default_returns_consolidation_period?: string,
  send_notification_email?: boolean,
  send_notification_sms?: boolean,
  label_shipper_address?: boolean
}
export const getProfileData_from_B2C = async (secret_key: string) => {
  let response = await apib2c.get<profileDataInB2c>(`order/shipper-agent-app/?secret_key=${secret_key}`);
  return response.data
};

export const acceptTNC = async (token: string) => {
  let response = await api.put('auth/profile/accept_tnc/',{},{
    headers: {
      'Authorization': `Token ${token}`
    }
  });
  return response.data
};
export const updateLabelSize = async (token: string, labelType: string) => {
  let response = await api.put('/auth/profile/update_label_type/ ', {
    'label_type':labelType
  },
  {
    headers: {
      'Authorization': `Token ${token}`
    }
  });
  return response.data
};

export const change_notification_settings_in_b2c = async (agent_id: string, sms_enabled: boolean, email_enabled: boolean, secret_key: string) => {
  let response = await apib2c.put(`order/shipper-agent-app/${agent_id}/`, {
    send_notification_sms: sms_enabled, send_notification_email: email_enabled, secret_key: secret_key
  })

  return response.data
};

export const updateLabelSize_in_b2c = async (agent_id: string, labelType: string, secret_key: string) => {
  let response = await apib2c.put(`order/shipper-agent-app/${agent_id}/`, {
    'label_preference': labelType,
    'secret_key': secret_key
  });
  return response.data
};

export const updateLabelAddressPreference_in_b2c = async (agent_id: string, labelPreference: boolean, secret_key: string) => {
  let response = await apib2c.put(`order/shipper-agent-app/${agent_id}/`, {
    'label_shipper_address': labelPreference,
    'secret_key': secret_key
  });
  return response.data
};
